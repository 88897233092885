import React from "react"
import { colors, Flex, SmallText, Tag } from "@ikiru/talentis-fpc"
import { PersonalNoteBioItemStyled } from "./styles"
import { ShowMore } from "components/ShowMore"
import { generateNoteDate } from "components/NoteItem/helpers"
import { Note } from "components/Notes/types"
import { formatDateTime } from "utils/format-date"

type PersonNoteProps = {
  note: Note
}

export const PersonNote = ({ note }: PersonNoteProps) => {
  const {
    noteTitle,
    noteDescription,
    createdDate,
    updatedDate,
    createdBy,
    updatedBy,
    linkAssignment,
    linkCampaign
  } = generateNoteDate(note)

  const isNoteUpdated = updatedBy && updatedDate

  return (
    <PersonalNoteBioItemStyled pb={linkAssignment || linkCampaign ? "0" : "xs"}>
      <Flex flexDirection="column" alignItems="flex-start">
        <ShowMore
          visibleLines={1}
          isSmall
          title={noteTitle}
          description={noteDescription}
          hasBackground={false}
          color={colors.grey.dark}
        />

        <Flex>
          <SmallText
            mr="xs"
            as="span"
            mb="0"
            fontSize="11px"
            color={colors.grey.dark}
          >
            {isNoteUpdated
              ? `Edited ${formatDateTime(updatedDate)}`
              : formatDateTime(createdDate)}
          </SmallText>
          <SmallText as="span" mb="0" fontSize="11px" color={colors.grey.dark}>
            {isNoteUpdated ? updatedBy : createdBy}
          </SmallText>
        </Flex>

        {(linkAssignment || linkCampaign) && (
          <Flex mt="xxs">
            <Tag variant={linkAssignment ? "grey-dark" : "grey-standard"}>
              {linkAssignment?.name || linkCampaign?.name}
            </Tag>
          </Flex>
        )}
      </Flex>
    </PersonalNoteBioItemStyled>
  )
}
