import { UserManager, UserManagerSettings, User } from "oidc-client-ts"
import { AxiosInstance } from "axios"
import { SubscriptionType } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"

export enum AuthMethod {
  Redirect = "redirect",
  Popup = "popup"
}

export type AuthProps = {
  children?: React.ReactNode
  loader?: React.ReactNode
  config: UserManagerSettings
  method: AuthMethod
  api: AxiosInstance
}

export enum UserRoles {
  Owner = "owner",
  Admin = "admin",
  TeamMember = "teamMember"
}

export const UserRolePermissionLevels = {
  [UserRoles.Owner]: 600,
  [UserRoles.Admin]: 400,
  [UserRoles.TeamMember]: 200
}

export type FirstUsageParam = "searchUsed" | "testUsed" | "none" | "runAgain"

export interface TalentisUser extends User {
  userRole: UserRoles
  subscriptionExpired?: Date
  isSubscriptionExpired?: boolean
  passedInitialLoginForSearchFirm?: boolean
  planType?: SubscriptionType
  searchFirmId: string
  userFirstName: string
  userLastName: string
  userEmailAddress: string
  searchFirmName: string
  firstUsage: FirstUsageParam
}

export type AuthManager = UserManager | undefined

export type PossibleAuthActionSets = {
  signIn: "signinRedirect" | "signinPopup"
  signOut: "signoutRedirect" | "signoutPopup"
  signInCallback: "signinRedirectCallback" | "signinPopupCallback"
  signOutCallback: "signoutRedirectCallback" | "signoutPopupCallback"
}
