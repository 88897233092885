import { useCallback, useMemo } from "react"
import {
  candidateStages,
  candidateStatuses,
  InterviewProgressStage,
  InterviewProgressStatusOption
} from "../candidate-record/components/InterviewProgress/definitions"
import { CandidateFilter } from "views/assignments/assignment-module/assignment-module.types"
import uniq from "lodash/uniq"
import { InterviewProgressStatus } from "views/persons/components/person-assignments/components/InterviewProgress/constants/interview-progress-statuses"
import { useTeam } from "views/team/team/team-module.context"
import { useTaskManagement } from "../../../../../homepage/task-management-module/task-management-module.context"
import { useTaskFilterData } from "./useTaskFilterData"
import { contactStatuses } from "components/ProjectPersonRecord/campaign.definitions"

export const useTaskFilter = () => {
  const { appliedFilters, allAvailableFilters, updateFilters, isAssignment } =
    useTaskManagement()
  const { stageFilter, statusFilter, assignToFilter } = useTaskFilterData()

  const { teamMembers } = useTeam()

  /**
   * Check if a given filter is already in our list of applied filters
   */
  const filterIsApplied = useCallback(
    (candidateFilter: CandidateFilter) => {
      return (
        appliedFilters.findIndex(
          (filter: CandidateFilter) =>
            filter.candidateProperty === candidateFilter?.candidateProperty &&
            filter.filterKey === candidateFilter.filterKey
        ) !== -1
      )
    },
    [appliedFilters]
  )
  const checkFirstSelected = useCallback(
    (candidateProperty: string) => {
      return appliedFilters[0]?.candidateProperty !== candidateProperty
    },
    [appliedFilters]
  )

  /**
   * Return a list of status filters, including only those which at least one candidate has assigned
   */
  const interviewProgressStageFilters = useMemo<CandidateFilter[]>(() => {
    const currentStageArray = checkFirstSelected("interviewProgressState.stage")
      ? (stageFilter as InterviewProgressStage[])
      : allAvailableFilters.stage || []
    const stages: InterviewProgressStage[] = currentStageArray?.map(
      (stage) => stage || InterviewProgressStage.Identified
    )

    return candidateStages.map((stage) => {
      const filter = {
        ...stage,
        candidateProperty: "interviewProgressState.stage",
        value: {
          eq: stage.value
        },
        filterKey: `interviewStage${stage.value}`,
        disabled: !stages?.includes(stage.value)
      }
      return filter
    })
  }, [allAvailableFilters.stage, checkFirstSelected, stageFilter])

  /**
   * Return a list of status filters, including only those which at least one candidate has assigned
   */
  const interviewProgressStatusFilters = useMemo<CandidateFilter[]>(() => {
    const currentStatusArray = checkFirstSelected(
      "interviewProgressState.status"
    )
      ? statusFilter
      : allAvailableFilters.status || []

    const statuses: InterviewProgressStatus[] = (
      currentStatusArray as InterviewProgressStatus[]
    ).map((status) => status || InterviewProgressStatus.NoStatus)

    const statusesCommon = isAssignment ? candidateStatuses : contactStatuses
    return (statusesCommon as InterviewProgressStatusOption[]).map((status) => {
      const filter = {
        ...status,
        candidateProperty: "interviewProgressState.status",
        value: {
          eq: status.value
        },
        filterKey: `interviewStatus${status.value}`,
        disabled: !statuses?.includes(status.value)
      }
      return filter
    })
  }, [
    allAvailableFilters.status,
    checkFirstSelected,
    statusFilter,
    isAssignment
  ])

  /**
   * Return a list of "assigned to" filters, including only those which at least one candidate has assigned
   */
  const assignedToFilters = useMemo<CandidateFilter[]>(() => {
    const currentAssignedArray = checkFirstSelected("assignTo")
      ? assignToFilter
      : allAvailableFilters.assignedTo || []
    const teamMembersIds = currentAssignedArray.map((assignedId) =>
      assignedId ? assignedId : "notAssigned"
    )

    const unassigned = {
      candidateProperty: "assignTo",
      value: {
        eq: null
      },
      label: "Unassigned",
      filterKey: "assignToNull",
      disabled: !teamMembersIds.includes("notAssigned")
    }

    const filters = teamMembers.map((teamMember) => {
      const filter = {
        candidateProperty: "assignTo",
        value: {
          eq: teamMember?.id
        },
        label: `${teamMember.firstName} ${teamMember.lastName}`,
        filterKey: `assignTo${teamMember.id}`,
        disabled: !teamMembersIds.includes(teamMember.id)
      }
      return filter
    })

    return uniq([unassigned, ...filters])
  }, [
    teamMembers,
    allAvailableFilters.assignedTo,
    checkFirstSelected,
    assignToFilter
  ])

  /**
   * Add a candidate filter
   */
  const addFilter = useCallback(
    (candidateFilter: CandidateFilter) => {
      if (filterIsApplied(candidateFilter)) {
        return
      }

      updateFilters([...appliedFilters, candidateFilter], true)
    },
    [filterIsApplied, updateFilters, appliedFilters]
  )

  /**
   * Remove a candidate filter
   */
  const removeFilter = useCallback(
    (candidateFilter: CandidateFilter) => {
      if (!filterIsApplied(candidateFilter)) {
        return
      }

      updateFilters(
        appliedFilters.filter(
          (filter) =>
            filter.value !== candidateFilter.value &&
            filter.label !== candidateFilter.label
        ),
        true
      )
    },
    [filterIsApplied, updateFilters, appliedFilters]
  )

  return {
    assignedToFilters,
    interviewProgressStatusFilters,
    interviewProgressStageFilters,
    filterIsApplied,
    addFilter,
    removeFilter
  }
}
