import React from "react"
import { Flex, H5 } from "@ikiru/talentis-fpc"
import { ShowMore } from "components/ShowMore"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import DetailSection from "./DetailSection"
import moment from "moment"

const PersonDetailSectionView: React.FC = () => {
  const { profileDetails, person } = usePerson()

  return (
    <>
      <Flex alignItems="flex-start" justifyContent="space-evenly" width="100%">
        <DetailSection
          title={messages.person.personTabSection.displayName}
          data={person?.name}
        />
        <Flex width="100%"></Flex>
        <DetailSection
          title={messages.person.personTabSection.title}
          data={person?.title}
        />
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-evenly" width="100%">
        <DetailSection
          title={messages.person.personTabSection.firstName}
          data={person?.firstName}
        />
        <DetailSection
          title={messages.person.personTabSection.lastName}
          data={person?.lastName}
        />
        <DetailSection
          title={messages.person.personTabSection.middleName}
          data={person?.middleName}
        />
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-evenly" width="100%">
        <DetailSection
          title={messages.person.personTabSection.knownAs}
          data={person?.knownAs}
        />
        <DetailSection
          title={messages.person.personTabSection.nationality}
          data={person?.nationality}
        />
        {person?.dateOfBirth ? (
          <DetailSection
            title={messages.person.personTabSection.dateOfBirth}
            data={moment(person.dateOfBirth).format("DD-MM-YYYY")}
          />
        ) : (
          <DetailSection
            title={messages.person.personTabSection.dateOfBirth}
            data="Not set"
          />
        )}
      </Flex>

      {profileDetails.bio.value && (
        <Flex flexDirection="column" alignItems="flex-start" mt="m">
          <H5 m="0">{messages.person.personTabSection.biography}</H5>
          <ShowMore
            visibleLines={2}
            description={profileDetails.bio.value || ""}
            hasBackground={false}
          />
        </Flex>
      )}
    </>
  )
}

export default PersonDetailSectionView
