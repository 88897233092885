import styled from "styled-components"
import { Flex, theme } from "@ikiru/talentis-fpc"

type CreatePersonContainerProps = {
  isMobile: boolean
  isHorizontalView: boolean
}

export const CreatePersonContainer = styled(Flex)<CreatePersonContainerProps>`
  flex-direction: column;
  margin-bottom: ${theme.space.m}px;
  overflow-y: auto;
  justify-content: ${({ isHorizontalView }) =>
    isHorizontalView ? "flex-end" : "center"};
  align-items: center;
  ${({ isHorizontalView }) => !isHorizontalView && `flex: 1`};
  ${({ isMobile, isHorizontalView }) => {
    return isMobile
      ? !isHorizontalView &&
          `
        border-top: 3px solid ${theme.colors.grey.light};
        margin-top: ${theme.space.s}px;
       `
      : `  
      margin-left: ${theme.space.l}px; 
      border-left: 3px solid ${theme.colors.grey.light};
   `
  }}
`
