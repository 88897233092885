import React from "react"
import { Formik, FormikHelpers } from "formik"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import {
  Button,
  Div,
  Flex,
  H5,
  ModalSectionHeader,
  Span
} from "@ikiru/talentis-fpc"
import { LinkedInUrlField } from "views/assignments/components/assignment-client-details/linkedin-form/constants/definitions"
import { linkedInValidationSchema } from "views/assignments/components/assignment-client-details/linkedin-form/constants/schema"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import {
  callProfileEndpoint,
  linkPersonToDatapool,
  onAddLinkedInUrl
} from "../../person-details/form/actions"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { getNormalizedPersonProfileDetails } from "../../person-details/utils/get-person-details"
import { StyledForm } from "./styles"
import { ModalFormContainer } from "../../person-details-tab/components/person-details-section/form/style"

export const ParseSocialMedia = () => {
  const { personId, setProfileDetails } = usePerson()

  const { close } = useModal()

  const submitForm = async (
    values: { [LinkedInUrlField.LinkedInUrl]: { value: string } },
    action: FormikHelpers<{ [LinkedInUrlField.LinkedInUrl]: { value: string } }>
  ) => {
    const linkedInUrl = values.linkedInUrl.value

    let [error, response] = await onAddLinkedInUrl(personId)(
      linkedInUrl,
      action
    )

    if (
      error?.data?.title === "There is a conflict." &&
      error?.data?.status === 409
    ) {
      action.setFieldError(
        `${LinkedInUrlField.LinkedInUrl}.value`,
        messages.person.details.linkError
      )
    } else {
      try {
        await linkPersonToDatapool(personId)
        const [, profileResponse] = await callProfileEndpoint(personId)
        response = profileResponse
      } catch (e) {
        console.log(e)
      }

      if (response?.data.dataPoolPerson) {
        close(ModalNames.ParseSocialMedia)
      }
    }

    const mergedPersonData = new LocalPersonModel(response?.data)

    const profileDetails = getNormalizedPersonProfileDetails(mergedPersonData)

    setProfileDetails(profileDetails)
  }

  const closeParseSocialMedia = () => {
    close(ModalNames.ParseSocialMedia)
  }

  return (
    <ModalFormContainer defaultWidth={670}>
      <Formik
        initialValues={{
          ...{ [LinkedInUrlField.LinkedInUrl]: { value: "" } }
        }}
        validationSchema={linkedInValidationSchema}
        onSubmit={submitForm}
      >
        {(formik) => {
          return (
            <StyledForm>
              <ModalSectionHeader
                title="Parse from social media URL"
                size="xSmall"
              />
              <H5 width="100%" textAlign="left" mt="xs" mb="xs">
                {messages.assignment.addLinkedInExample}:
              </H5>
              <Span mb="xs">https://uk.linkedin.com/in/johndoe</Span>
              <Div mb="m">
                <FormikInput
                  id={LinkedInUrlField.LinkedInUrl}
                  name={`${LinkedInUrlField.LinkedInUrl}.value`}
                  label={messages.form.generic.linkedInUrl}
                />
              </Div>
              <Flex mb="m" justifyContent="flex-end">
                <Button
                  type="submit"
                  size="small"
                  mr="xs"
                  disabled={formik.isSubmitting}
                >
                  {messages.generic.save}
                </Button>
                <Button
                  mode="standard-white"
                  size="small"
                  disabled={formik.isSubmitting}
                  type="button"
                  onClick={() => {
                    closeParseSocialMedia()
                    formik.resetForm()
                  }}
                >
                  {messages.generic.cancel}
                </Button>
              </Flex>
            </StyledForm>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}
