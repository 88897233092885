import * as Yup from "yup"
import { BillingAddressFields } from "./BillingAddressForm.definitions"
import { messages } from "setup/messages/messages"

export const billingAddressValidationSchema = Yup.object({
  [BillingAddressFields.Email]: Yup.string()
    .email(messages.form.generic.errors.invalidEmail)
    .required(messages.generic.required),
  [BillingAddressFields.Address]: Yup.string().required(
    messages.generic.required
  ),
  [BillingAddressFields.City]: Yup.string().required(messages.generic.required),
  [BillingAddressFields.CountryCode]: Yup.string().required(
    messages.generic.required
  ),
  [BillingAddressFields.PostCode]: Yup.string().required(
    messages.generic.required
  ),
  [BillingAddressFields.VatNumber]: Yup.string()
})
