import React, { useCallback, useState } from "react"
import { messages } from "setup/messages/messages"
import { Button } from "@ikiru/talentis-fpc"
import { Tag } from "@ikiru/talentis-fpc"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { FilterPopup } from "./FilterPopup"
import { Flex } from "@ikiru/talentis-fpc"
import { tagColor } from "views/assignments/components/candidates-list/helpers"
import useEditCandidateFilter from "./hooks/useEditCandidateFilter"

export const CandidateFilters = () => {
  const { appliedFilters, candidates } = useAssignment()
  const { removeFilter } = useEditCandidateFilter()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onMenuOpen = useCallback(() => setIsMenuOpen(true), [setIsMenuOpen])

  const onMenuClose = useCallback(() => setIsMenuOpen(false), [setIsMenuOpen])

  return (
    <>
      <Flex alignItems="center" mt="-15px" flexWrap="wrap">
        {candidates.length > 0 && (
          <FilterPopup
            onOpen={onMenuOpen}
            onClose={onMenuClose}
            trigger={
              <Button
                size="small"
                borderColor={isMenuOpen ? "grey.dark" : "grey.standard"}
                borderWidth="2px"
                borderStyle="solid"
                mode={isMenuOpen ? "standard-grey" : "standard-white"}
                colorway={
                  isMenuOpen ? { group: "grey", variant: "dark" } : undefined
                }
                mr="xs"
                mt="xs"
              >
                + {messages.assignment.filters.filter}
              </Button>
            }
          />
        )}
        {appliedFilters.map((filter) => (
          <Tag
            variant={tagColor(filter?.variant)}
            height={24}
            mt="xs"
            mb="0"
            key={filter.label}
            onRemoveClick={() => removeFilter(filter)}
          >
            {filter.label}
          </Tag>
        ))}
      </Flex>
    </>
  )
}
