import styled, { css } from "styled-components"
import { hexToRGB } from "utils/hexToRGB"
import {
  DropdownArrowIcon as DropdownArrow,
  GridBox,
  Grid,
  Badge,
  fontFamilies,
  BodyText,
  Div,
  Flex,
  SmallText
} from "@ikiru/talentis-fpc"
import { WebSiteLink } from "components/WebSites/styles"
import { DOMAttributes } from "react"
import { OffLimits } from "components/FPCComponents/OffLimits"

type StyledBadgeProps = {
  count: number
}

type BodyTextClampedStyledProps = {
  expanded: boolean
  linesNumber: number
  ref?: React.MutableRefObject<HTMLParagraphElement | undefined>
}

export const PersonRecordBox = styled(Grid)<{
  isIgnored: boolean
  isOfflimits: boolean
}>`
  grid-gap: 0;
  grid-template-rows: auto;
  position: relative;
  width: 100%;
  border: ${({ isIgnored, theme }) =>
    !isIgnored ? `3px solid ${theme.colors.grey.light}` : ""};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  background: ${({ theme }) => theme.colors.white.standard};
  padding: 0;
  grid-template-rows: max-content;
  grid-template-columns: 1fr;
  grid-template-areas:
    "ifOfflimits"
    "details"
    "experience"
    "industryTags"
    "location"
    "assignment";
  ${({ isIgnored }) =>
    isIgnored &&
    css`
      ${ExperienceBox} {
        padding-top: ${({ theme }) => theme.space.xxs}px;
        padding-bottom: 0;
      }
      ${PersonDetailsBox} {
        padding-top: ${({ theme }) => theme.space.xs}px;
        padding-bottom: ${({ theme }) => theme.space.xxs}px;
      }
    `}

  ${({ theme, isOfflimits }) => `
    ${theme.mediaQueries.xs} {
      grid-template-columns: ${isOfflimits ? "20px" : ""} 150px 2fr;
      grid-template-areas:
        "${isOfflimits ? "ifOfflimits" : ""} details experience"
        "${isOfflimits ? "ifOfflimits" : ""} details location"
        "${isOfflimits ? "ifOfflimits" : ""} details assignment"
    }

    ${theme.mediaQueries.md} {
      grid-template-columns: ${isOfflimits ? "20px " : ""} 175px  7fr 4fr;
      grid-template-areas:
        "${isOfflimits ? "ifOfflimits" : ""}  details experience assignment"
        "${isOfflimits ? "ifOfflimits" : ""}  details location assignment"
        "${isOfflimits ? "ifOfflimits" : ""}  details industryTags assignment";
    }

    ${theme.mediaQueries.lg} {
      grid-template-columns: ${isOfflimits ? "20px " : ""} 200px 6fr 3fr;
    }

    ${theme.mediaQueries.xl} {
      grid-template-columns: ${isOfflimits ? "20px " : ""} 2fr 6fr 2fr;
    }

  `}
`

export const CandidateDetailsBox = styled(GridBox)`
  border-left: 1px solid ${({ theme }) => theme.colors.grey.light};
  padding: ${({ theme }) => theme.space.s}px;
  padding-bottom: ${({ theme }) => theme.space.xs}px;
  padding-top: 17px;
`

export const OffLimitsPersonBox = styled(GridBox)`
  grid-area: offLimits;
`

export const PersonDetailsBox = styled(GridBox)<{
  isIgnored?: boolean
  isOffLimits?: boolean
}>`
  grid-area: details;
  height: 100%;
  padding: ${({ theme }) => theme.space.s}px;
  ${({ isIgnored }) =>
    isIgnored &&
    css`
      padding-left: ${({ theme }) => theme.space.m}px;
    `}
  ${WebSiteLink} {
    ${({ isIgnored }) =>
      isIgnored &&
      css`
        svg {
          height: 24px;
          width: 24px;
        }
      `}
  }
`

export const ExperienceBox = styled(GridBox)`
  grid-area: experience;
  padding-bottom: ${({ theme }) => theme.space.xxs}px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  ${({ theme }) => `
    ${theme.mediaQueries.xs} {
        border-top: none;
    }
  `}
`

export const LocationBox = styled(GridBox)`
  grid-area: location;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  padding: ${({ theme }) => theme.space.xs}px
    ${({ theme }) => theme.space.xxs}px ${({ theme }) => theme.space.xs}px
    ${({ theme }) => theme.space.s}px;
`

export const IndustryTags = styled(GridBox)`
  grid-area: industryTags;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  padding: ${({ theme }) => theme.space.s}px;
`

export const AssignmentBox = styled(GridBox)<{ isIgnored: boolean }>`
  grid-area: assignment;
  border-left: 1px solid
    ${({ theme, isIgnored }) => {
      const opacityBorder = isIgnored ? 0.2 : 1
      return hexToRGB(theme.colors.grey.light, opacityBorder)
    }};
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  padding: ${({ theme }) => theme.space.s}px;
  ${({ theme }) => `
    ${theme.mediaQueries.md} {
        border-top: none;
    }
  `}

  ${({ theme }) => `
    ${theme.mediaQueries.xl} {
      grid-row: 1 / 5;
    }`};
`

export const StyledBadge = styled(Badge)<StyledBadgeProps>`
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme, count }) =>
    count === 0 ? theme.colors.white.standard : theme.colors.orange.darkest};
  background: ${({ theme, count }) =>
    count === 0 ? theme.colors.grey.standard : theme.colors.red.lightest};
  height: 16px;
  border-radius: 15%;
  margin-left: 5px;
  padding: 3px;
  font-family: ${fontFamilies.sourceSans};
`

export const BodyTextClampedStyled = styled(
  BodyText
)<BodyTextClampedStyledProps>`
  display: -webkit-box;
  flex-grow: 1;
  white-space: pre-line;
  ${({ expanded, linesNumber }) =>
    !expanded && `-webkit-line-clamp: ${linesNumber};`};
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 0;
`

export const DropdownIconStyled = styled(DropdownArrow)<
  { up: boolean } & DOMAttributes<SVGElement>
>`
  cursor: pointer;
  flex-shrink: 0;
  ${({ up }) => !up && `transform: rotate(180deg)`};
  path {
    fill: ${({ theme }) => theme.colors.grey.dark};
  }
`

export const PersonDetailsBlock = styled(Div)<{
  isOffLimits?: boolean
}>`
  background-color: ${({ theme, isOffLimits }) =>
    isOffLimits
      ? hexToRGB(theme.colors.red.lightest, 0.7)
      : `${theme.colors.transparent};`};
  border-right: 1px solid ${({ theme }) => theme.colors.grey.light};
  ${({ theme }) => `
    ${theme.mediaQueries.xs} {
      grid-row: 1 / 5;
    }`};
`

export const OffLimitsContainerBox = styled(OffLimits)`
  grid-area: ifOfflimits;
  width: 100%;
  height: 100%;
  ${({ theme }) => `
    ${theme.mediaQueries.xs} {
      grid-row: 1 / 5;
    }`};
`
export const StyledFlex = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  padding-left: ${({ theme }) => theme.space.s}px;
  padding-top: ${({ theme }) => theme.space.xxs}px;
`
export const StyledSmallText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.grey.dark};
`

export const StyledGT2 = styled(Div)`
  margin-left: ${({ theme }) => theme.space.xxs}px;
  cursor: pointer;
`
