import React, { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { useAuth } from "setup/auth/module/auth.context"
import { useEffect } from "react"
import { localStorageKeys } from "setup/storage/storage.definitions"
import {
  OIDCSignInRedirectRouteUrl,
  OIDCSignOutRedirectRouteUrl
} from "setup/auth/auth.config"
import { RouterUrl } from "setup/router/routes"

const routesToIgnore = [
  OIDCSignInRedirectRouteUrl,
  OIDCSignOutRedirectRouteUrl,
  RouterUrl.Index,
  RouterUrl.Logout
]

const isIgnoredRoute = (route: string) =>
  routesToIgnore.some((r) => {
    return route.indexOf(r) >= 0
  })

export const CacheBrowserHistory = (props: { children: React.ReactNode }) => {
  const { pathname, search } = useLocation()
  const { isLoggedIn } = useAuth()

  const shouldCacheRoute = useMemo(() => {
    return !isIgnoredRoute(pathname)
  }, [pathname])

  useEffect(() => {
    if (shouldCacheRoute) {
      localStorage.setItem(localStorageKeys.location, `${pathname}${search}`)
    }
  }, [isLoggedIn, pathname, search, shouldCacheRoute])

  return <>{props.children}</>
}
