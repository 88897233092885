import React, { useContext } from "react"
import { Button } from "@ikiru/talentis-fpc"
import { SidebarContext } from "components/visual/header/SidebarContext"
import { HeaderAction } from "components/navigation/constants/navigation.definitions"
import { preventDefault } from "utils/prevent-default"
import { ActionsContainer, ActionLink } from "./SidebarActions.styled"

type Props = {
  actions: HeaderAction[]
}

export const SidebarActions = React.memo(({ actions }: Props) => {
  const { isExpanded } = useContext(SidebarContext)

  return (
    <ActionsContainer>
      {actions.map(
        ({ label, icon, path, onClick, e2eTargets, disabled }, index) => (
          <ActionLink
            key={`${label}_${index}`}
            to={path || "#"}
            onClick={disabled ? preventDefault : onClick}
            data-e2e-target={e2eTargets?.e2eTarget}
            data-e2e-target-name={e2eTargets?.e2eTargetName}
          >
            <Button
              {...(isExpanded
                ? {
                    prefix: icon
                  }
                : {})}
              mode="standard-green"
              size={isExpanded ? "extra-small" : "action-small"}
              disabled={disabled}
            >
              {isExpanded ? label : icon}
            </Button>
          </ActionLink>
        )
      )}
    </ActionsContainer>
  )
})
