import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { callWithErrorMessages } from "utils/forms/forms"
import { Person } from "views/persons/person.types"
import {
  OnAddPersonNoteSubmitType,
  OnEditPersonNoteSubmitType
} from "views/persons/components/person-notes/form/constants/types"
import { PersonNote } from "../types"
import { expandEndpointByParams } from "setup/api/api.helpers"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

type GetOnAddNotesSubmitType = (
  personId: Person["id"],
  onSubmit: (note: PersonNote) => void,
  beforeSubmit: (personId: string) => Promise<string>
) => OnAddPersonNoteSubmitType

type GetOnEditNotesSubmitType = (
  personId: Person["id"],
  noteId: string,
  onSubmit: (note: PersonNote) => void
) => OnEditPersonNoteSubmitType

export const getOnAddPersonNoteSubmit: GetOnAddNotesSubmitType =
  (localPersonId, onSubmit, beforeSubmit) => async (values, helpers) => {
    const { setFieldError } = helpers
    let personId = await beforeSubmit(localPersonId)
    const [error, response] = await apiRequest.post({
      endpointParams: { id: personId },
      endpoint: PersonsEndpoints.Notes,
      config: {
        ...expandEndpointByParams({
          expand: ["createdByUser", "assignment", "updatedByUser"],
          header: {
            ...skipErrorHeader
          }
        })
      },
      // Need do that because formik sent empty string instad of null
      // also cant set null value for select
      data: {
        ...values,
        assignmentId: values?.assignmentId || null,
        campaignId: values.campaignId || null
      }
    })

    if (error) {
      callWithErrorMessages(setFieldError, error)
      helpers.setSubmitting(false)
    } else {
      onSubmit(response?.data)
    }
  }

export const getOnEditPersonNoteSubmit: GetOnEditNotesSubmitType =
  (personId, noteId, onSubmit) => async (values, helpers) => {
    const { setFieldError } = helpers

    const assignmentId = values?.linkAssignment?.id || values.assignmentId
    const campaignId = values?.linkCampaign?.id || values.campaignId

    const [error, response] = await apiRequest.put({
      endpointParams: { id: personId, noteId: noteId },
      endpoint: PersonsEndpoints.Notes,
      config: expandEndpointByParams({
        expand: ["createdByUser", "assignment", "campaign", "updatedByUser"],
        headers: {
          ...skipErrorHeader
        }
      }),
      // Need do that because formik sent empty string instad of null
      // also cant set null value for select
      data: {
        ...values,
        assignmentId: assignmentId || null,
        campaignId: campaignId || null
      }
    })

    if (error) {
      callWithErrorMessages(setFieldError, error)
      helpers.setSubmitting(false)
    } else {
      onSubmit(response?.data)
    }
  }
