import React, { useCallback, useMemo } from "react"
import {
  CardComponent,
  AuthorizeWith3dsAdditionalData
} from "@chargebee/chargebee-js-react-wrapper"
import { messages } from "setup/messages/messages"
import {
  Coupon,
  CouponType
} from "views/subscription/subscription-purchase-module/coupons"
import { useSubscriptionPurchase } from "views/subscription/subscription-purchase-module/subscription-purchase-module.context"
import { Frequency } from "views/subscription/components/plan-details/form/constants/plan-details.definitions"

export const usePayment = () => {
  const {
    purchaseData,
    billingDetails,
    fetchEstimate,
    fetchPaymentIntent,
    addCoupon,
    removeCoupon: removeCouponFromState,
    createSubscription
  } = useSubscriptionPurchase()

  const { totalUsers, appliedCouponCodes, planId, users } = useMemo(() => {
    const plan =
      purchaseData.planDetails?.frequency === Frequency.Year
        ? purchaseData.plan?.yearly
        : purchaseData.plan?.monthly || null

    return {
      totalUsers: purchaseData.planDetails?.totalUsersNumber || 0,
      appliedCouponCodes: purchaseData.couponsUsed?.map((c) => c.code) || [],
      planId: plan?.id || "",
      users: purchaseData.planDetails?.users || []
    }
  }, [purchaseData])

  const { email, addressLine1, city, countryCode, postcode, vatNumber } =
    useMemo(
      () => ({
        email: billingDetails.email || "",
        addressLine1: billingDetails.addressFirstLine || "",
        city: billingDetails.city || "",
        countryCode: billingDetails.countryCode || "US",
        postcode: billingDetails.postcode || "",
        vatNumber: billingDetails.vatNumber
      }),
      [billingDetails]
    )

  const applyCoupon = useCallback(
    async (couponCode: string) => {
      // fetchEstimate will throw if the coupon is invalid
      await fetchEstimate(
        planId,
        new Date().toISOString(),
        totalUsers,
        [...appliedCouponCodes, couponCode],
        countryCode,
        postcode,
        vatNumber
      )

      const newCoupon: Coupon = {
        type: CouponType.USER_COUPON,
        code: couponCode
      }

      addCoupon(newCoupon)
    },
    [
      fetchEstimate,
      totalUsers,
      appliedCouponCodes,
      countryCode,
      postcode,
      addCoupon,
      planId,
      vatNumber
    ]
  )

  const removeCoupon = useCallback(
    async (couponCode: string) => {
      const couponToRemove = purchaseData.couponsUsed?.find(
        (coupon) => coupon.code === couponCode
      )

      if (!couponToRemove) {
        return
      }

      // fetchEstimate will throw if the coupon is invalid
      await fetchEstimate(
        planId,
        new Date().toISOString(),
        totalUsers,
        [...appliedCouponCodes].filter((coupon) => coupon !== couponCode),
        countryCode,
        postcode,
        vatNumber
      )

      removeCouponFromState(couponToRemove)
    },
    [
      fetchEstimate,
      totalUsers,
      purchaseData,
      appliedCouponCodes,
      countryCode,
      postcode,
      removeCouponFromState,
      planId,
      vatNumber
    ]
  )

  const createSubscriptionZeroPayment = useCallback(async () => {
    await createSubscription("", planId, appliedCouponCodes, totalUsers, users)
  }, [planId, appliedCouponCodes, totalUsers, users, createSubscription])

  const doChargebee3dsPayment = useCallback(
    async (
      name: string,
      chargebeeCardComponentRef: React.MutableRefObject<CardComponent>
    ) => {
      const paymentIntent = await fetchPaymentIntent(
        planId,
        new Date().toISOString(),
        totalUsers,
        appliedCouponCodes,
        email,
        addressLine1,
        city,
        countryCode,
        postcode,
        vatNumber
      )

      if (!paymentIntent) {
        throw new Error(messages.subscription.paymentError.thereIsAnIssue)
      }

      const nameParts = name.split(" ")
      const lastName = nameParts.pop()
      const firstName = nameParts.join(" ")

      const paymentAdditionalData: AuthorizeWith3dsAdditionalData = {
        plan: planId,
        email,
        billingAddress: {
          firstName,
          lastName,
          addressLine1,
          city,
          countryCode,
          zip: postcode
        }
      }

      const authedPaymentIntent =
        await chargebeeCardComponentRef.current?.authorizeWith3ds(
          paymentIntent,
          paymentAdditionalData
        )

      if (!authedPaymentIntent || !authedPaymentIntent.id) {
        throw new Error(messages.subscription.paymentError.thereIsAnIssue)
      }

      await createSubscription(
        authedPaymentIntent.id,
        planId,
        appliedCouponCodes,
        totalUsers,
        users
      )
    },
    [
      addressLine1,
      appliedCouponCodes,
      city,
      countryCode,
      createSubscription,
      email,
      fetchPaymentIntent,
      postcode,
      totalUsers,
      planId,
      vatNumber,
      users
    ]
  )
  return {
    applyCoupon,
    removeCoupon,
    doChargebee3dsPayment,
    createSubscriptionZeroPayment
  }
}
