import React, { useMemo } from "react"
import { BodyText, Div, Flex, H5, Tag } from "@ikiru/talentis-fpc"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { ExistingData } from "../../../components/ExistingData"
import { ParsedData } from "../../../components/ParsedData"
import {
  DateWrapper,
  ExistingDateWrapper,
  ParsedWrapper,
  PersonKeywordsContainer,
  ToggleWrapper
} from "../../style"
import { List } from "views/persons/components/person-keyword/components/KeywordsList/style"
import { messages } from "setup/messages/messages"
import { Nullable } from "tsdef"

const ParseKeywords = () => {
  const { cvParsing, setCVParsing } = usePerson()

  const deleteKeyword = (
    changingKeywordId: Nullable<string>,
    listType: "existingKeywords" | "parsingKeywords",
    changingKeywordValue?: string
  ) => {
    const updatedKeywords = cvParsing.skills[listType].map((keyword) => {
      if (
        (changingKeywordId === null &&
          changingKeywordValue === keyword.value) ||
        (changingKeywordId !== null && changingKeywordId === keyword.id)
      ) {
        return {
          ...keyword,
          isDeleted: !keyword.isDeleted
        }
      }

      return keyword
    })

    setCVParsing({
      ...cvParsing,
      skills: {
        ...cvParsing.skills,
        [listType]: updatedKeywords
      }
    })
  }

  const updateAll = (disableALl: boolean) => {
    const updatedKeywords = cvParsing.skills.existingKeywords.map((keyword) => {
      keyword.isDeleted = disableALl
      return keyword
    })

    setCVParsing({
      ...cvParsing,
      skills: {
        ...cvParsing.skills,
        existingKeywords: updatedKeywords
      }
    })
  }
  const disableDeleteAll = useMemo(() => {
    if (Boolean(cvParsing.skills.existingKeywords.length)) {
      return Boolean(
        cvParsing.skills.existingKeywords.filter((keyword) => keyword.isDeleted)
          .length
      )
    }
    return false
  }, [cvParsing.skills.existingKeywords])

  return (
    <Div height="100%">
      <BodyText>{messages.person.cvparsing.keywordParse}</BodyText>
      <Flex justifyContent="flex-end" mr="m">
        <ExistingData
          deleteAll={() => {
            updateAll(!disableDeleteAll)
          }}
          buttonText={
            disableDeleteAll
              ? messages.person.cvparsing.restoreAll
              : messages.person.cvparsing.deleteAll
          }
        />
        <ParsedData />
      </Flex>
      <PersonKeywordsContainer>
        <DateWrapper>
          <H5 width="15.4%" m="none" p="xxs">
            {messages.person.cvparsing.keywords}
          </H5>
          <ExistingDateWrapper>
            <List style={{ padding: 5 }}>
              {Boolean(cvParsing?.skills?.existingKeywords) &&
                cvParsing.skills.existingKeywords.map((keyword) => {
                  return (
                    <Tag
                      key={JSON.stringify(keyword)}
                      as="li"
                      mr="xxs"
                      variant={keyword.isDeleted ? "red" : "grey"}
                      disabled
                      onRemoveClick={() =>
                        deleteKeyword(keyword.id, "existingKeywords")
                      }
                    >
                      {keyword.value}
                    </Tag>
                  )
                })}
            </List>
          </ExistingDateWrapper>

          <ParsedWrapper>
            <List style={{ padding: 10 }}>
              {Boolean(cvParsing?.skills?.parsingKeywords) &&
                cvParsing.skills.parsingKeywords.map((keyword) => {
                  return (
                    <Tag
                      key={JSON.stringify(keyword)}
                      as="li"
                      mr="xxs"
                      variant={keyword.isDeleted ? "red" : "grey"}
                      disabled
                      onRemoveClick={() =>
                        deleteKeyword(
                          keyword.id,
                          "parsingKeywords",
                          keyword.value
                        )
                      }
                    >
                      {keyword.value}
                    </Tag>
                  )
                })}
            </List>

            <ToggleWrapper />
          </ParsedWrapper>
        </DateWrapper>
      </PersonKeywordsContainer>
    </Div>
  )
}

export default ParseKeywords
