import React from "react"
import { messages } from "setup/messages/messages"
import { BodyText, colors, Button, Flex } from "@ikiru/talentis-fpc"
import { Form, Formik } from "formik"
import { SupportFormValues } from "./constants/support.types"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { SupportFormField } from "./constants/support.definitions"
import { supportValidationSchema } from "./constants/support.schema"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"

interface ISupportForm {
  initialValues: SupportFormValues
  onSubmit: (values: SupportFormValues) => void
}

export const SupportDetailsForm = ({
  initialValues,
  onSubmit
}: ISupportForm) => {
  return (
    <Formik
      {...{ initialValues, onSubmit }}
      validationSchema={supportValidationSchema}
    >
      {({ values }) => (
        <Form>
          <BodyText my="s" color={colors.grey.dark}>
            {messages.supportForm.info}
          </BodyText>
          <FormikInput
            disabled
            id={SupportFormField.Name}
            name={SupportFormField.Name}
            label={messages.supportForm.placeholders.name}
          />
          <FormikInput
            disabled
            id={SupportFormField.Company}
            name={SupportFormField.Company}
            label={messages.supportForm.placeholders.company}
          />
          <FormikInput
            id={SupportFormField.Email}
            name={SupportFormField.Email}
            label={messages.supportForm.placeholders.email}
          />
          <FormikInput
            id={SupportFormField.Phone}
            name={SupportFormField.Phone}
            label={messages.supportForm.placeholders.phone}
          />
          <FormikTextarea
            id={SupportFormField.Message}
            name={SupportFormField.Message}
            label={messages.supportForm.placeholders.message}
            style={{ height: "300px" }}
          />
          <Flex mt="m" height={50} justifyContent="center" alignItems="center">
            <Button type="submit">{messages.supportForm.send}</Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
