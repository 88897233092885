import styled from "styled-components"
import {
  Flex,
  Div,
  DropdownArrowIcon,
  Button,
  Badge,
  fontFamilies,
  SectionBody
} from "@ikiru/talentis-fpc"

const avatarBoxSize = "40px"

export const CompanyRecordWrapper = styled(Div)`
  position: relative;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  background: ${({ theme }) => theme.colors.white.standard};
  padding: 0;
  margin-bottom: 10px;
  margin-top: 10px;
`

export const RecordFooterBox = styled(Div)`
  padding: 10px 30px 10px 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.large}px;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.large}px;
  background-color: #f2f6f7;
  &:empty {
    padding: 0;
    border: none;
  }
`

export const RecordBox = styled(Flex)`
  width: 30%;
  padding: 10px;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.grey.light};
`

export const AvatarBox = styled(Div)`
  margin-right: 10px;
  width: ${avatarBoxSize};
  height: ${avatarBoxSize};
`

type DropDownIconStyledProps = {
  up?: boolean
}

export const DropDownIconStyled = styled(
  DropdownArrowIcon
)<DropDownIconStyledProps>`
  ${({ up }) => up && `transform: rotate(180deg)`};
`

export const ButtonStyled = styled(Button)`
  position: relative;
  bottom: -10%;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
`

export const StyledBadge = styled(Badge)`
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white.standard};
  background: ${({ theme }) => theme.colors.grey.standard};
  height: 16px;
  border-radius: 15%;
  margin-left: 5px;
  padding: 3px;
  font-family: ${fontFamilies.sourceSans};
`

export const SectionBodyStyled = styled(SectionBody)`
  padding-top: ${({ theme }) => theme.space.m}px;
  padding-bottom: ${({ theme }) => theme.space.m}px;
  color: ${({ theme }) => theme.colors.grey.standard};
`
