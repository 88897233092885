import { useCallback, useEffect, useState } from "react"
import { apiRequest } from "setup/api/api"
import {
  AssignmentsEndpoints,
  CampaignsEndpoints
} from "setup/api/endpoints/endpoints"
import { useSearch } from "views/search/SearchModule/context"

const useGetIgnorePersons = () => {
  const { selectedAssignment, isAssignmentSelect, selectedCampaign } =
    useSearch()
  const [ignoredPersonArray, setIgnoredPersonArray] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchIgnored = useCallback(async () => {
    if (
      (selectedAssignment && isAssignmentSelect) ||
      (selectedCampaign && !isAssignmentSelect)
    ) {
      setIsLoading(true)
      const [, response] = await apiRequest.get({
        endpoint: isAssignmentSelect
          ? AssignmentsEndpoints.IgnoredCandidates
          : CampaignsEndpoints.IgnoredContacts,
        endpointParams: isAssignmentSelect
          ? { assignmentId: selectedAssignment }
          : { campaignId: selectedCampaign }
      })
      setIgnoredPersonArray(response?.data.dataPoolPersonIds || [])
    } else setIgnoredPersonArray([])
    setIsLoading(false)
  }, [
    selectedAssignment,
    setIgnoredPersonArray,
    isAssignmentSelect,
    selectedCampaign
  ])

  useEffect(() => {
    fetchIgnored()
  }, [fetchIgnored])

  return { ignoredPersonArray, setIgnoredPersonArray, isLoading }
}

export default useGetIgnorePersons
