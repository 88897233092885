import { PAGESIZE } from "views/assignments/assignment-module/const"
import { RouterUrl } from "setup/router/routes"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { InterviewProgressStage } from "../person-assignments/components/InterviewProgress/constants/interview-progress-stages"
import { Contact } from "views/campaigns/components/contacts/type"
import { Candidate } from "views/candidates/candidates.types"

export const currentPersonIndex = (
  personsIdArray: string[],
  selectedPersonId: string,
  pageNumber: number
) => {
  const candidateIndex = personsIdArray.indexOf(selectedPersonId)
  if (candidateIndex === -1) return candidateIndex
  const addPageSize = (pageNumber - 1) * PAGESIZE + (candidateIndex + 1)
  return addPageSize
}

export const getCurrentCandidateIndex = (
  items: { personId: string }[],
  selectedRecordId: string,
  pageNumber: number
) => {
  const candidateIds = items.map((task) => task.personId)
  return currentPersonIndex(candidateIds, selectedRecordId, pageNumber)
}

export const windowOpenProfileDetails = (
  localPersonId: string,
  project?: string
) => {
  const url = project
    ? `${RouterUrl.ProfileDetails}/${localPersonId}?project=${project}`
    : `${RouterUrl.ProfileDetails}/${localPersonId}`
  window.open(url, "_blank", "noreferrer")
}

export const getUpdatedPhotoAndCallBack = async (
  personId: string,
  photoUpdated: boolean,
  callback: (photo: string) => void
) => {
  if (photoUpdated) {
    const [, response] = await apiRequest.get({
      endpoint: PersonsEndpoints.Photo,
      endpointParams: personId
    })

    if (response) {
      const { photo } = response?.data
      callback(photo?.url || "")
    }
  } else {
    callback("")
  }
}

export const getProjectCounts = (
  contacts: Record<string, Contact>,
  candidates: Record<string, Candidate>
) => {
  let placedCount = 0
  let shortListCount = 0
  let assignmentCount = 0
  let campaignsCount = Object.values(contacts).length
  Object.values(candidates).forEach((candidate) => {
    ++assignmentCount
    if (
      candidate.interviewProgressState?.stage === InterviewProgressStage.Placed
    ) {
      ++placedCount
    }
    if (
      candidate.interviewProgressState?.stage ===
      InterviewProgressStage.Shortlist
    ) {
      ++shortListCount
    }
  })

  const counts = {
    assignments: assignmentCount,
    shortListed: shortListCount,
    placed: placedCount,
    campaigns: campaignsCount
  }
  return counts
}
