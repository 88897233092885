import { Button, H6 } from "@ikiru/talentis-fpc"
import styled from "styled-components"

type Props = {
  isSelected?: boolean
  noPadding?: boolean
}

export const SwitchLabel = styled(H6)<Props>`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.grey.dark : theme.colors?.grey.standard};
  margin-left: ${({ theme }) => theme.space?.xxs}px;
  margin-top: 0px;
  margin-bottom: ${({ theme }) => theme.space?.xxs}px;
`

export const ProjectButton = styled(Button)`
  width: 125px;
  min-height: 30px;
  margin-top: -5px;
`

export const ProjectButtonLabel = styled(H6)`
  color: ${({ theme }) => theme?.colors?.white.standard};
  margin: 0px;
  margin-left: -10px;
`
