import React from "react"
import { Flex, Div, Switch } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import AssignmentSelector from "./AssignmentSelector"
import CampaignSelector from "./CampaignSelector"
import ModalTemplate from "./Components/AddNewModal/ModalTemplate"
import { SwitchLabel, StyledFlex } from "./style"
import { Assignment } from "views/assignments/assignment.types"
import { Campaign } from "views/campaigns/campaign.types"

export type ProjectParams = {
  typeOfProject?: string
  projectId?: string
}

export type ProjectSelectorProps = {
  fetchAssignments: () => void
  fetchCampaigns: () => void
  setIsAssignmentSelect: (value: boolean) => void
  isAssignmentSelect: boolean | null
  isLoading: boolean
  activeAssignments: Assignment[] | undefined
  selectedAssignment: string
  setSelectedAssignment: (value: string) => void
  activeCampaigns: Campaign[] | undefined
  selectedCampaign: string
  setSelectedCampaign: (value: string) => void
}

const ProjectSelector = ({
  fetchAssignments,
  fetchCampaigns,
  setIsAssignmentSelect,
  isAssignmentSelect,
  isLoading,
  activeAssignments,
  selectedAssignment,
  setSelectedAssignment,
  activeCampaigns,
  selectedCampaign,
  setSelectedCampaign
}: ProjectSelectorProps) => {
  const { open: openModal } = useModal()

  const addNew = () =>
    openModal(
      ModalNames.AddNewCampaignOrAssignment,
      <ModalTemplate
        isAssignmentSelect={isAssignmentSelect || true}
        fetchAssignments={fetchAssignments}
        fetchCampaigns={fetchCampaigns}
      />,
      true
    )

  if (isAssignmentSelect === null) {
    return null
  }

  return (
    <StyledFlex justifyContent="space-evenly" alignItems="center" height={30}>
      <Flex mx={["0", "xs"]} my={["xs"]}>
        <SwitchLabel isSelected={isAssignmentSelect}>
          {messages.talentGraphSearch.projectSelector.projectSwitch.assignment}
        </SwitchLabel>
        <Switch
          colorVariant="secondary"
          label={""}
          checked={!isAssignmentSelect}
          onChange={() => setIsAssignmentSelect(!isAssignmentSelect)}
        />
        <SwitchLabel isSelected={!isAssignmentSelect}>
          {messages.talentGraphSearch.projectSelector.projectSwitch.campaign}
        </SwitchLabel>
      </Flex>
      <Div height="m" width="150px">
        {isAssignmentSelect ? (
          <AssignmentSelector
            add={addNew}
            isLoading={isLoading}
            activeAssignments={activeAssignments}
            selectedAssignment={selectedAssignment}
            setSelectedAssignment={setSelectedAssignment}
            isAssignmentSelect={isAssignmentSelect}
            fetchCampaigns={fetchCampaigns}
            fetchAssignments={fetchAssignments}
          />
        ) : (
          <CampaignSelector
            add={addNew}
            isLoading={isLoading}
            activeCampaigns={activeCampaigns}
            selectedCampaign={selectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
            isAssignmentSelect={isAssignmentSelect}
            fetchCampaigns={fetchCampaigns}
            fetchAssignments={fetchAssignments}
          />
        )}
      </Div>
    </StyledFlex>
  )
}

export default ProjectSelector
