import React from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { personAvatarE2ETargets } from "views/persons/components/person-avatar/constants/person-avatar.definitions"
import { H3 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

type DeleteAvatarViewProps = {
  onCancelClick: () => void
  onConfirmClick: () => void
  actionsPending: boolean
}
export const DeleteAvatarView = (props: DeleteAvatarViewProps) => {
  const { onCancelClick, onConfirmClick, actionsPending } = props
  return (
    <Div>
      <H3 mb="s">{messages.person.avatar.confirmDeletionMessage}</H3>
      <Flex width="100%" flexDirection="column" alignItems="center">
        <Button
          type="button"
          mode="primary"
          onClick={onConfirmClick}
          mb="xs"
          disabled={actionsPending}
          data-e2e-target={personAvatarE2ETargets.deleteConfirmationButton}
        >
          {messages.person.avatar.deletePhoto}
        </Button>
        <Button
          type="button"
          mode="secondary"
          size="small"
          onClick={onCancelClick}
          disabled={actionsPending}
          data-e2e-target={personAvatarE2ETargets.deleteCancelButton}
        >
          {messages.generic.cancel}
        </Button>
      </Flex>
    </Div>
  )
}
