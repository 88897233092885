import React, { useRef, useCallback, useEffect, useMemo } from "react"
import { CardComponent } from "@chargebee/chargebee-js-react-wrapper"
import { messages } from "setup/messages/messages"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { useSubscriptionPurchase } from "views/subscription/subscription-purchase-module/subscription-purchase-module.context"
import {
  CardDetailsFields,
  CardDetailsType
} from "views/subscription/components/billing-card-details/components/CardDetailsForm/CardDetailsForm.definition"
import { usePayment } from "views/subscription/components/billing-card-details/hooks"
import { BillingError } from "views/subscription/components/billing-error"
import { CardDetailsForm } from "./components/CardDetailsForm"
import { OrderSummarywrapper } from "./components/OrderSummaryWrapper"
import {
  defaultCurrency,
  getPlanTitle
} from "views/subscription/components/plan-selection/utils"
import { Frequency } from "views/subscription/components/plan-details/form/constants/plan-details.definitions"

export const CardDetails = () => {
  const {
    paymentData,
    purchaseData,
    paymentEstimate,
    setIsLoading,
    setPaymentError,
    setPaymentComplete,
    setStepTitle
  } = useSubscriptionPurchase()

  let disableForm = false

  const { doChargebee3dsPayment, createSubscriptionZeroPayment } = usePayment()

  const chargebeeCardRef = useRef<CardComponent>(null!)

  const { paymentError } = paymentData

  const { planName, planCurrency, planTotalUsers, planFrequency } = useMemo(
    () => ({
      planName: purchaseData.plan ? getPlanTitle(purchaseData.plan) : "",
      planCurrency: purchaseData.planDetails?.currency || defaultCurrency,
      planTotalUsers: purchaseData.planDetails?.totalUsersNumber || 0,
      planFrequency: purchaseData.planDetails?.frequency || Frequency.Year
    }),
    [purchaseData]
  )

  const { total, subtotal, discount, taxAmount } = useMemo(
    () => ({
      total: paymentEstimate.total || 0,
      subtotal: paymentEstimate.amount || 0,
      discount: paymentEstimate.discount || 0,
      taxAmount: paymentEstimate.taxAmount || 0
    }),
    [paymentEstimate]
  )
  disableForm = useMemo(() => paymentEstimate.total === 0, [paymentEstimate])

  useEffect(
    () =>
      setStepTitle(
        disableForm
          ? messages.subscription.purchase.confirmPlan
          : messages.subscription.purchase.payByCard
      ),
    [disableForm, setStepTitle]
  )

  const onSubmit = useCallback(
    async (values: CardDetailsType) => {
      setPaymentError("")
      setIsLoading(
        true,
        total !== 0
          ? messages.subscription.paymentDetails.loading
          : messages.subscription.paymentDetails.loadingPlanUpdate
      )

      try {
        if (disableForm) {
          await createSubscriptionZeroPayment()
        } else {
          await doChargebee3dsPayment(
            values[CardDetailsFields.Name],
            chargebeeCardRef
          )
        }

        setPaymentComplete(true)
      } catch (error) {
        setPaymentError(error.message)
      } finally {
        setIsLoading(false)
      }
    },
    [
      chargebeeCardRef,
      setIsLoading,
      setPaymentError,
      setPaymentComplete,
      doChargebee3dsPayment,
      createSubscriptionZeroPayment,
      disableForm,
      total
    ]
  )

  // Unset any errors when we leave this step
  useEffect(
    () => () => {
      setPaymentError("")
    },
    [setPaymentError]
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      <Div flexGrow={1} flexBasis={0}></Div>
      <Div width="360px" mx="s" mb={["xxl", "m", "m"]}>
        {paymentError && (
          <BillingError
            mt="s"
            title={messages.subscription.paymentError.paymentFailed}
            message={paymentError}
          />
        )}
        <CardDetailsForm
          onSubmit={onSubmit}
          chargebeeCardComponentRef={chargebeeCardRef as any}
          disableForm={disableForm}
        />
      </Div>
      <Div flexGrow={1} flexBasis={0}>
        <OrderSummarywrapper
          currencyCode={planCurrency}
          totalPrice={total}
          subtotal={subtotal}
          taxAmount={taxAmount}
          discount={discount}
          numberOfUsers={planTotalUsers}
          frequency={planFrequency}
          planName={planName}
          appliedCoupons={purchaseData.couponsUsed || []}
        />
      </Div>
    </Flex>
  )
}
