import React from "react"
import { LinkProps } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { e2eTargets } from "views/assignments/components/assignment-client-details/constants/definitions"
import { Nullable } from "tsdef"
import { CompanyLinkStyled, StyledH4 } from "./CompanyLink.styled"

type CompanyLinkProps = LinkProps & {
  companyId?: Nullable<string>
  companyName?: Nullable<string>
  isDots?: boolean
}

export const CompanyLink = ({
  companyId,
  companyName,
  isDots = false,
  ...extraProps
}: CompanyLinkProps) => {
  return companyId &&
    companyId !== "00000000-0000-0000-0000-000000000000" &&
    !companyId.includes("gen-uuid") ? (
    <CompanyLinkStyled
      underline={false}
      underlineHover={true}
      variant="green-dark"
      href={`${RouterUrl.CompanyDetails}/${companyId}`}
      target="_blank"
      size={null}
      isDots={isDots}
      {...extraProps}
    >
      {companyName}
    </CompanyLinkStyled>
  ) : companyName ? (
    <StyledH4
      isDots={isDots}
      display="inline-block"
      data-e2e-target-name={e2eTargets.companyName}
      my={0}
      {...extraProps}
    >
      {companyName}
    </StyledH4>
  ) : null
}
