import React, { useState } from "react"
import {
  Flex,
  DropdownArrowIcon as DropdownArrow,
  theme,
  StyledSystemProps,
  PopupProps
} from "@ikiru/talentis-fpc"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"
import { messages } from "setup/messages/messages"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"
import ActionButton from "../ActionButton"
import RotateAction from "../RotateActionIcon"

type ProjectActionPopUpProps = {
  options?: HeaderOption[]
  alternativeButton?: React.JSX.Element
  getIfActive?: React.Dispatch<React.SetStateAction<boolean>>
  config?: {
    PopupProps?: Partial<PopupProps> | undefined
    ContentProps?: StyledSystemProps | undefined
  }
}
const ProjectActionPopUp = ({
  getIfActive,
  alternativeButton,
  options,
  config
}: ProjectActionPopUpProps) => {
  const [popupOpen, setPopupOpen] = useState(false)
  return (
    <OptionsPopup
      alignSelf="center"
      display="flex"
      ml="s"
      config={{
        PopupProps: {
          ...config?.PopupProps,
          nested: true,
          position: "bottom right",
          onOpen: () => (getIfActive ? getIfActive(true) : setPopupOpen(true)),
          onClose: () =>
            getIfActive ? getIfActive(false) : setPopupOpen(false)
        },
        ContentProps: {
          ...config?.ContentProps
        }
      }}
      trigger={
        alternativeButton ? (
          alternativeButton
        ) : (
          <Flex p={3}>
            <ActionButton
              size="extra-small"
              pt="xxs"
              pb="xxs"
              label={messages.assignment.actions}
              suffix={
                <RotateAction isActive={popupOpen}>
                  <DropdownArrow
                    fill={theme.colors.white.standard}
                    width={13}
                    height={13}
                  />
                </RotateAction>
              }
            />
          </Flex>
        )
      }
      options={options || []}
    />
  )
}

export default ProjectActionPopUp
