import { StyledSystemProps, Flex, Div } from "@ikiru/talentis-fpc"
import styled, { css } from "styled-components"

export const StepWrapper = styled(Flex)<
  {
    active?: boolean
    completed?: boolean
  } & StyledSystemProps
>`
  width: 100%;
  height: 29px;
  background-color: ${({ theme }) => theme.colors.grey.light};
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.green.light};
    `}

  ${({ completed, active }) =>
    completed &&
    !active &&
    css`
      background-color: ${({ theme }) => theme.colors.green.lightest};
    `}
`

export const ContentContainer = styled(Div)`
  max-width: "100%";
  margin-bottom: ${({ theme }) => theme.space.m}px;
  padding-left: ${({ theme }) => theme.space.s}px;
  padding-right: ${({ theme }) => theme.space.s}px;
  overflow: hidden;
  @media (min-height: 576px) {
    height: 70vh;
  }
  @media (min-height: 768px) {
    height: 74vh;
  }
`
