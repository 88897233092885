import { useState } from "react"
import { apiRequest } from "setup/api/api"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"

const useAssignmentTemplates = () => {
  const { assignmentId, setAssignmentTemplates } = useAssignment()
  const [isLoading, setIsLoading] = useState(false)

  const getAiTemplates = async () => {
    const [, response] = await apiRequest.get({
      endpoint: AssignmentsEndpoints.AssignmentAiTemplate,
      endpointParams: assignmentId
    })

    setAssignmentTemplates(response?.data)
  }

  const generateAITemplates = async (
    format: string,
    language: string,
    coreMessage: string
  ) => {
    setIsLoading(true)
    const [, response] = await apiRequest.post({
      endpoint: AssignmentsEndpoints.AssignmentAiTemplate,
      endpointParams: assignmentId,
      data: {
        format,
        language,
        coreMessage
      }
    })

    let newAITemplates: any = response?.data

    setAssignmentTemplates(newAITemplates)
    setIsLoading(false)
  }

  const updateAiTemplate = async (data: any) => {
    setIsLoading(true)
    const [, response] = await apiRequest.put({
      endpoint: AssignmentsEndpoints.AssignmentAiTemplate,
      endpointParams: assignmentId,
      data: data
    })

    let newAITemplates: any = response?.data

    setAssignmentTemplates(newAITemplates)
    setIsLoading(false)
  }

  return { getAiTemplates, generateAITemplates, updateAiTemplate, isLoading }
}

export default useAssignmentTemplates
