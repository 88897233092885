import { Campaign } from "views/campaigns/campaign.types"

export enum CreateCampaignField {
  CampaignName = "name",
  Description = "description"
}

export type CampaignPrimaryData = Pick<Campaign, "name" | "description">

export const createCampaignInitialValues: Omit<
  Campaign,
  "id" | "status" | "startDate" | "tags"
> = {
  [CreateCampaignField.CampaignName]: "",
  [CreateCampaignField.Description]: ""
}

export const e2eTargets = {
  submitButton: "submit-form",
  modifyCampaignDetailsButton: "modify-Campaign-details",
  editButton: "edit-Campaign",
  cancelButton: "cancel-Campaign-edit"
}

export type CreateCampaignValues = typeof createCampaignInitialValues
