import styled from "styled-components"
import {
  Div,
  Flex,
  SectionBody,
  colors,
  spacing,
  fontFamilies,
  Link,
  H5
} from "@ikiru/talentis-fpc"

export const LinkContainerStyled = styled(Flex)`
  width: 100% !important;
  margin-bottom: 2px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
  background: ${colors.green.lightest};
  position: relative;
  flex-direction: column;
`
export const StyledSectionBody = styled(SectionBody)`
  border: 0.5px solid;
  border-color: ${({ theme }) => theme.colors.grey.light};
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${({ theme }) => theme.colors.green.lightest};
  h5 {
    font-size: 15px;
    font-family: ${fontFamilies.gibson};
  }
`

export const StatusDiv = styled(Div)`
  display: flex;
  flex-direction: row;
  padding-top: ${spacing.xxs}px;
  ::before {
    content: "";
    margin-left: ${spacing.xxs}px;
    margin-right: ${spacing.xxs}px;
    margin-top: 4px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.green.standard};
  }
  h6 {
    margin-top: 1px;
    margin-bottom: ${spacing.xxs}px;
  }
  @media (max-width: 768px) {
    padding-top: 0;
  }
`
export const DateFlex = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  margin-left: ${spacing.xs}px;
  @media (max-width: 768px) {
    padding-top: 0;
    align-items: baseline;
  }
`
export const MobileFlex = styled(Flex)`
  @media (max-width: 768px) {
    flex-direction: column;
    h4 {
      margin-top: 0;
    }
  }
`
export const TGFlex = styled(Flex)`
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
  margin-left: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    margin-top: 5px;
    margin-left: 0;
  }
`

export const AvatarDiv = styled(Div)`
  width: 80px;
  height: 80px;
  margin-right: 15px;
  @media (max-width: 768px) {
    margin-right: 10px;
    min-width: 60px;
    max-width: 60px;
    max-height: 60px;
  }
`

export const StyledDiv = styled(Div)`
  overflow: scroll;
`

export const DateLabel = styled(H5)`
  word-break: keep-all;
  white-space: nowrap;
  margin: 0;
`

export const LinkStyled = styled(Link)`
  background: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  line-height: 0px;
`
