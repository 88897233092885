import React from "react"
import { messages } from "setup/messages/messages"
import { Helmet } from "react-helmet"
import { colors, Flex, H2 } from "@ikiru/talentis-fpc"
import SearchCriteria from "./components/SearchCriteria"
import SearchResults from "./components/SearchResults"
import { SearchModule } from "./SearchModule"
import { OpenCloseUserGuidingModal } from "./UserGuiding/open-close-userguiding-modal"

export const Search = () => {
  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.seo.search)}</title>
      </Helmet>
      <SearchModule>
        <H2 color={colors.green.standard} mt="0">
          {messages.talentGraphSearch.title}
        </H2>
        <OpenCloseUserGuidingModal />
        <Flex flexWrap={["wrap", "wrap", "nowrap"]}>
          <Flex
            flexShrink={0}
            alignItems="flex-start"
            width={["100%", "100%", "250px", "300px"]}
            mr={["none", "none", "m"]}
            mb={["xs", "none"]}
          >
            <SearchCriteria />
          </Flex>
          <Flex width="100%">
            <SearchResults />
          </Flex>
        </Flex>
      </SearchModule>
    </>
  )
}
