import styled from "styled-components"

type Props = {
  isSidebar?: boolean
  noPadding?: boolean
  maxWidth?: number
}

export const LayoutWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  position: relative;
  overflow: hidden;
`

export const LayoutMain = styled.main<Props>`
  height: 100%;
  width: 100%;
  ${({ theme, noPadding }) =>
    noPadding ? `padding: 0;` : `padding: ${theme.space.m}px;`}
  ${({ theme, noPadding }) => `
  ${theme.mediaQueries.md} {
    padding: ${noPadding ? 0 : theme.space.xl}px;
    padding-top: ${noPadding ? 0 : theme.space.s}px;
  }`}
`

export const MainWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: ${({ isSidebar }) => (isSidebar ? "60px" : "0")};
  top: ${({ isSidebar }) => (!isSidebar ? "50px" : "0")};
  right: 0;
  bottom: 0;
  overflow: auto;
  bottom: 0;

  @media (max-height: 394px) {
    top: ${({ isSidebar }) => (isSidebar ? "50px" : "50px")};
    left: ${({ isSidebar }) => (isSidebar ? "0" : "0")};
  }
  @media only screen and (min-height: 394px) and (max-height: 695px) {
    left: ${({ isSidebar }) => (isSidebar ? "50px" : "0")};
  }
`

export const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ maxWidth }: Props) => (maxWidth ? `${maxWidth}px` : "auto")};
`

export const NavigationWrapper = styled.div<Props>`
  position: absolute;
  height: ${({ isSidebar }) => (isSidebar ? "100%" : "auto")};
  width: ${({ isSidebar }) => (isSidebar ? "auto" : "100%")};
  z-index: 15;

  @media (max-height: 394px) {
    height: ${({ isSidebar }) => (isSidebar ? "auto" : "100%")};
    width: 100%;
  }
`

export const PageContentWrapper = styled.div<Props>`
  width: 100%;
  ${({ theme, noPadding }) =>
    noPadding
      ? `
      height: 100%;
      padding-bottom: 0;
    `
      : `
      padding-bottom: ${theme.space.xxl}px;
      height: auto;
    `}
`
