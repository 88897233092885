export enum RegisterUserField {
  Email = "emailAddress",
  FirstName = "firstName",
  LastName = "lastName",
  JobTitle = "jobTitle",
  Password = "password",
  UserId = "userId",
  SearchFirmId = "searchFirmId"
}

export const registerUserInitialValues = {
  [RegisterUserField.Email]: "",
  [RegisterUserField.FirstName]: "",
  [RegisterUserField.LastName]: "",
  [RegisterUserField.JobTitle]: "",
  [RegisterUserField.Password]: "",
  [RegisterUserField.UserId]: "",
  [RegisterUserField.SearchFirmId]: ""
}

export const registerUsersAutocompleteKeys = {
  [RegisterUserField.FirstName]: "first-name",
  [RegisterUserField.LastName]: "last-name",
  [RegisterUserField.JobTitle]: "job-title",
  [RegisterUserField.Password]: "new-password"
}
