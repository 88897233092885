import React, { useCallback } from "react"
import { pick } from "lodash"
import {
  Button,
  PencilIcon,
  PlusIcon,
  Section,
  colors
} from "@ikiru/talentis-fpc"
import { Assignment } from "views/assignments/assignment.types"
import { patchAssignment } from "views/assignments/actions"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import {
  AssignmentFeeData,
  AssignmentFeeDataField
} from "../../constants/definitions"
import { EditFeeData } from "../AssignmentDataModal/EditFeeData"
import { AssignmentFee } from "./AssignmentFee"

type PackageDataProps = Assignment & {
  onAssignmentUpdated: (assignment: Assignment) => void
}

export const FeeData = ({ onAssignmentUpdated, ...data }: PackageDataProps) => {
  const assignmentFeeData: AssignmentFeeData = pick(
    data,
    Object.values(AssignmentFeeDataField)
  )
  const { open } = useModal()

  const onSubmit = patchAssignment<AssignmentFeeData>(data, (assignment) => {
    onAssignmentUpdated(assignment)
  })

  const packageModal = useCallback(() => {
    open(
      ModalNames.EditFeeData,
      <EditFeeData initialValues={assignmentFeeData} onSubmit={onSubmit} />
    )
  }, [assignmentFeeData, onSubmit, open])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.assignment.assignmentFinancials,
        actions: (
          <>
            {assignmentFeeData.feeComment ||
            assignmentFeeData.feeFrom ||
            assignmentFeeData.finalFee ||
            assignmentFeeData.feeTo ? (
              <Button
                mode="standard-white"
                size="action-medium"
                onClick={packageModal}
              >
                <PencilIcon />
              </Button>
            ) : (
              <Button
                mode="standard-green"
                size="small"
                onClick={packageModal}
                prefix={
                  <PlusIcon
                    width={12}
                    height={12}
                    fill={colors.white.standard}
                  />
                }
              >
                {messages.assignment.assignmentFinancial}
              </Button>
            )}
          </>
        )
      }}
    >
      <AssignmentFee {...assignmentFeeData} />
    </Section>
  )
}
