import React from "react"
import { ButtonProps, PlusIcon, theme } from "@ikiru/talentis-fpc"
import ActionButton from "components/ProjectActions/ActionButton"

type PersonSectionButtonProps = {
  label: string
  disabled: boolean
  onClick: () => void
} & ButtonProps

const PersonSectionButton = (props: PersonSectionButtonProps) => {
  const { label, disabled, onClick, ...other } = props
  return (
    <ActionButton
      disabled={disabled}
      label={label}
      onClick={onClick}
      {...other}
      prefix={
        <PlusIcon width={12} height={12} fill={theme.colors.white.standard} />
      }
    />
  )
}

export default PersonSectionButton
