import React, { useEffect, useRef, useState } from "react"
import { Overlay } from "@ikiru/talentis-fpc"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { PersonOverlayContainer, PersonContent } from "./style"
import { candidateStages } from "../../../assignments/components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { useChangeItem } from "./hook/useChangeItem"
import PersonOverlay from "./index"
import OverlayNavigation from "./OverlayNavigation"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { OnPageChangeProp } from "../../../assignments/components/candidates-list"
import { Candidate } from "views/candidates/candidates.types"
import { getCurrentCandidateIndex, getUpdatedPhotoAndCallBack } from "./utils"

const AssignmentPersonOverlay = ({ handlePageChange }: OnPageChangeProp) => {
  const [candidateStage, setCandidateStage] = useState("")
  const [candidatePosition, setCandidatePosition] = useState<number>(1)
  const {
    setSelectedCandidatedId,
    selectedCandidatedId,
    candidates,
    pageNumber,
    pageSize,
    isCandidatesLoading,
    assignmentId,
    totalItems,
    filterTotalNumber,
    totalPages,
    updateCandidate
  } = useAssignment()

  const { directionNext, directionPrevious, isLastElement, isFirstElement } =
    useChangeItem(
      { handlePageChange },
      pageNumber,
      pageSize,
      totalItems,
      candidates,
      selectedCandidatedId,
      setSelectedCandidatedId,
      totalPages
    )

  const {
    getProfileData,
    person,
    setPersonOverlayUpdated,
    personOverlayUpdated,
    candidates: updatedPersons,
    personOverlayPhotoUpdated,
    profileDetails
  } = usePerson()

  useEffect(() => {
    const candidate = candidates.find(
      (candidate: Candidate) => candidate.personId === selectedCandidatedId
    )

    const tempAssignmentCandidate = Object.values(updatedPersons).find(
      (person) => person.assignmentId === assignmentId
    )

    const findStage = candidateStages.find(
      (stage) =>
        tempAssignmentCandidate?.interviewProgressState?.stage?.toLowerCase() ===
        stage.value.toLowerCase()
    )

    if (candidate) {
      setCandidateStage(findStage?.label || "")
    } else if (isLastElement) {
      setSelectedCandidatedId(candidates[0].personId)
      getProfileData(candidates[0].personId)
    } else if (isFirstElement) {
      setSelectedCandidatedId(candidates[24].personId)
      getProfileData(candidates[24].personId)
    }
  }, [
    candidates,
    selectedCandidatedId,
    directionNext,
    directionPrevious,
    isFirstElement,
    isLastElement,
    getProfileData,
    setSelectedCandidatedId,
    updatedPersons,
    assignmentId
  ])

  const overLayRef = useRef<HTMLDivElement>()

  const closeOverlay = () => {
    setSelectedCandidatedId("")
  }

  useEffect(() => {
    if (personOverlayUpdated) {
      const candidate = Object.values(updatedPersons).find(
        (candidate) => candidate.assignmentId === assignmentId
      )!

      getUpdatedPhotoAndCallBack(
        candidate.personId,
        personOverlayPhotoUpdated,
        (photoUrl) => {
          const newPersonData = person
          newPersonData!.isOfflimits = profileDetails.isOfflimits
          candidate.normalizedPersonData = newPersonData

          if (photoUrl) {
            candidate.photo = photoUrl
          }

          updateCandidate(candidate)

          setPersonOverlayUpdated(false)
        }
      )
    }
  }, [
    assignmentId,
    candidates,
    person,
    personOverlayPhotoUpdated,
    personOverlayUpdated,
    profileDetails,
    setPersonOverlayUpdated,
    updateCandidate,
    updatedPersons
  ])

  const closeOverlayFromBackground = (e: any) => {
    if (!overLayRef?.current?.contains(e.target)) {
      closeOverlay()
    }
  }

  useEffect(() => {
    const candidateNumber = getCurrentCandidateIndex(
      candidates,
      selectedCandidatedId,
      pageNumber
    )

    if (!isCandidatesLoading && candidateNumber !== -1) {
      setCandidatePosition(candidateNumber)
    }
  }, [selectedCandidatedId, pageNumber, isCandidatesLoading, candidates])

  return (
    <PersonOverlayContainer onClick={closeOverlayFromBackground}>
      <PersonContent ref={overLayRef}>
        <Overlay visible>
          <OverlayNavigation
            directionNext={directionNext}
            directionPrevious={directionPrevious}
            closeOverlay={closeOverlay}
            candidateStage={candidateStage}
            disableNav={isCandidatesLoading}
            totalItems={filterTotalNumber}
            currentCandidateNumber={candidatePosition}
          />
          <PersonOverlay
            personId={selectedCandidatedId}
            isLoadingCandidates={isCandidatesLoading}
            project="assignment"
          />
        </Overlay>
      </PersonContent>
    </PersonOverlayContainer>
  )
}

export default AssignmentPersonOverlay
