import styled from "styled-components"
import { LeftArrow, Span, spacing, SectionHeader, Flex } from "@ikiru/talentis-fpc"

export const UpArrow = styled(LeftArrow)`
  transform: rotate(180deg) !important;
  width: 15px;
  height: 15px;
  line-height: 20px;
  margin-right: ${spacing.xxs}px;
  margin-bottom: ${spacing.xxs}px;
`
export const ReturnToTop = styled(Span)`
    position: absolute;
    top: ${spacing.xxs}px;
    left: 0;
    display: flex;
    align-items: center;
    font-weight: 600;
`
export const StyledSectionHeader = styled(SectionHeader) `
  display: inline-block;
`
export const StyledFlex = styled(Flex) `
  position: sticky;
  top: 0;
  z-index: 3;
`