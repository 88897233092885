import { GDPRLawfulBasis } from "./gdpr-lawful-basis"

export enum PersonConsentsField {
  LawfulBasis = "gdprLawfulBasisOption",
  Status = "gdprLawfulBasisOptionsStatus",
  DataOrigin = "gdprDataOrigin",
  GdprExpiration = "gdprExpiration",
  GdprComment = "gdprComment"
}

export const initialDefaultPersonConsentState = {
  [PersonConsentsField.LawfulBasis]: GDPRLawfulBasis.None,
  [PersonConsentsField.DataOrigin]: "",
  [PersonConsentsField.GdprExpiration]: new Date(),
  [PersonConsentsField.GdprComment]: ""
}
