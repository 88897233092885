import React, { useCallback, useState, useEffect } from "react"
import {
  Button,
  H6,
  Flex,
  H5,
  Popup,
  Div,
  Toggle,
  PlusIcon,
  colors
} from "@ikiru/talentis-fpc"
import { navigationSelectors } from "components/visual/layout/definitions"
import { StyledSpan } from "./style"
import { Tag as TagType } from "components/Tags/tags.types"
import { PersonRecordTag } from "./PersonRecordTag"
import { messages } from "setup/messages/messages"
import { useTagsCandidates } from "views/assignments/components/candidates-list/components/candidate-record/components/Tags/useTagsCandidates"
import { useTagsContacts } from "views/campaigns/components/contacts/components/contact-record/Tags/useTagsContacts"

type PersonRecordTagProps = {
  personRecordTags: number[]
  tags: TagType[]
  personRecordId: string
  usePersonRecordTags: typeof useTagsCandidates | typeof useTagsContacts
  name: string
  showAddIcon?: boolean
  showOnlyAddButton?: boolean
  outputOnlyLabel?: boolean
  label?: string
}

export const Tags = ({
  personRecordTags,
  tags,
  personRecordId,
  usePersonRecordTags,
  name,
  showAddIcon = true,
  showOnlyAddButton = false,
  outputOnlyLabel = false,
  label = ""
}: PersonRecordTagProps) => {
  const [selectedTags, setSelectedTags] = useState<Array<TagType>>([])
  const [candidateTags, setCandidateTags] = useState<Array<number>>([])

  const { onTagsSelect } = usePersonRecordTags()

  const newConfig = {
    PopupProps: {
      arrow: false,
      keepTooltipInside: true
    },
    ContentProps: {
      boxShadow: "0 0 4px rgba(75, 106, 136, 0.8)"
    }
  }

  useEffect(() => {
    setSelectedTags(
      tags.filter((tag: TagType) => candidateTags?.includes(tag.id))
    )
  }, [tags, candidateTags])

  useEffect(() => {
    setCandidateTags(personRecordTags)
  }, [personRecordTags])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let tempTags = candidateTags
      const changedTag = parseInt(event.target.value, 10)

      if (event.target.checked) {
        tempTags.push(changedTag)
      } else {
        tempTags = tempTags.filter((tag) => tag !== changedTag)
      }
      setCandidateTags(tempTags)
      onTagsSelect(tempTags, personRecordId)
    },
    [candidateTags, onTagsSelect, personRecordId]
  )

  return (
    <Flex flexWrap="wrap" alignItems="center">
      {!showOnlyAddButton &&
        selectedTags?.map((tag: TagType) => (
          <PersonRecordTag
            tag={tag}
            personRecordTags={candidateTags}
            key={tag.id}
            personRecordId={personRecordId}
            usePersonRecordTags={usePersonRecordTags}
          />
        ))}
      {(outputOnlyLabel || candidateTags?.length !== tags?.length) && (
        <Popup
          config={newConfig}
          closeOnScrollSelector={`#${navigationSelectors.mainContainer}`}
          trigger={
            <>
              {" "}
              {candidateTags?.length && !showOnlyAddButton ? (
                showAddIcon && (
                  <Button size="action-small" mode="standard-green" mt="xs">
                    <PlusIcon fill={colors.white.standard} />
                  </Button>
                )
              ) : outputOnlyLabel ? (
                <span id="tags-label">{label}</span>
              ) : (
                <Button
                  size="extra-small"
                  mode="standard-green"
                  mt="s"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <span>{messages.assignment.addCandidateTags}</span>
                </Button>
              )}
            </>
          }
        >
          <Div bg="white.standard" flex={1}>
            <Div backgroundColor="grey.light" p="xs" pl="s">
              <H5 color="grey.dark" mt="0" mb="xxs">
                {messages.assignment.assignmentTags}
              </H5>
              <H6 my="0" fontSize={12} color={colors.grey.dark}>
                {name}
              </H6>
            </Div>
            <Div px="s" py="xs">
              {tags.map((tag: TagType) => (
                <Div key={tag.id}>
                  <Toggle
                    hideUnderline
                    small
                    type="checkbox"
                    label={
                      <H6 m="0" color="grey.dark">
                        <StyledSpan background={tag.background} />
                        {tag.name}
                      </H6>
                    }
                    value={tag.id}
                    checked={candidateTags?.includes(tag.id)}
                    onChange={onChange}
                  />
                </Div>
              ))}
            </Div>
          </Div>
        </Popup>
      )}
    </Flex>
  )
}
