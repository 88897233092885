import React, { useCallback, useEffect, useState } from "react"
import { Flex, Div } from "@ikiru/talentis-fpc"
import { H6 } from "@ikiru/talentis-fpc"
import { SmallText } from "@ikiru/talentis-fpc"
import { colors } from "@ikiru/talentis-fpc"
import { Loader } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { rocketReachTeasersE2ETargets } from "./constants/ContactLookUpProps.definitions"
import { noop } from "lodash"
import useContactLookUp from "./hook/useContactLookUp"
import { apiRequest } from "setup/api/api"
import { SearchFirmEndpoints } from "setup/api/endpoints/endpoints"

type ContactLookUpProps = {
  personId: string
  onClose: () => void
}

const ContactLookUp = ({ personId, onClose }: ContactLookUpProps) => {
  const [isLoadingTokens, setIsLoadingTokens] = useState(false)
  const [totalTokens, setTotalTokens] = useState(0)
  const {
    hasEmail,
    hasPhoneNumbers,
    isFetching,
    fetchContact,
    foundEmails,
    foundPhoneNumbers
  } = useContactLookUp({
    personId,
    onClose
  })

  const getFirmTokens = useCallback(async () => {
    setIsLoadingTokens(true)
    const [, response] = await apiRequest.get({
      endpoint: SearchFirmEndpoints.FirmToken
    })
    setTotalTokens(response?.data.total)
    setIsLoadingTokens(false)
  }, [])

  useEffect(() => {
    getFirmTokens()
  }, [getFirmTokens])

  const getContactButtonProps = isFetching
    ? {
        onClick: noop,
        prefix: <Loader visible={isFetching} size="small" />
      }
    : {
        onClick: () => fetchContact()
      }

  const resultsFetched = Boolean(foundEmails.length || foundPhoneNumbers.length)
  const areTeasers = !isFetching && (hasEmail || hasPhoneNumbers)

  return (
    <Div
      width={[1]}
      backgroundColor="#fff"
      borderRadius={10}
      mt={12}
      px={10}
      py={12}
      mb="m"
    >
      {isFetching || isLoadingTokens ? (
        <Loader />
      ) : !totalTokens ? (
        <Flex>
          <H6
            mt={0}
            mb={0}
            color={colors.grey.dark}
            data-e2e-target={rocketReachTeasersE2ETargets.notFound}
          >
            {messages.person.teasers.outOfCredits}
          </H6>
        </Flex>
      ) : (
        <Flex alignItems="center" style={{ whiteSpace: "break-spaces" }}>
          {resultsFetched ? (
            <Div textAlign="left">
              <H6 mt={0} color={colors.grey.dark} mb="xxs">
                {messages.person.teasers.savedDetails}
              </H6>
              <Flex flexDirection="column" mr="xxs" justifyContent="flex-start">
                {foundEmails.map((email, index) => {
                  return (
                    <SmallText m="0" key={index}>
                      {email.trim()}
                    </SmallText>
                  )
                })}
              </Flex>
              <Flex flexDirection="column" mr="xxs">
                {foundPhoneNumbers.map((phone, index) => {
                  return (
                    <SmallText key={index} m="0">
                      {phone}
                    </SmallText>
                  )
                })}
              </Flex>
            </Div>
          ) : areTeasers ? (
            <Div width="100%" textAlign="left">
              <H6 mt={0} color={colors.grey.dark} mb="xxs">
                {messages.person.teasers.title}
              </H6>
              <Flex flexDirection="column">
                <SmallText
                  m="0"
                  data-e2e-target={rocketReachTeasersE2ETargets.listing}
                >
                  {hasEmail
                    ? messages.person.teasers.foundDetails.emailFound
                    : messages.person.teasers.notFoundDetails.emailFound}
                </SmallText>
                <SmallText
                  data-e2e-target={rocketReachTeasersE2ETargets.listing}
                >
                  {hasPhoneNumbers
                    ? messages.person.teasers.foundDetails.phoneFound
                    : messages.person.teasers.notFoundDetails.phoneFound}
                </SmallText>
              </Flex>
            </Div>
          ) : (
            <H6
              mt={0}
              mb={0}
              color={colors.grey.dark}
              data-e2e-target={rocketReachTeasersE2ETargets.notFound}
            >
              {messages.person.teasers.contactDetailsNotFound}
            </H6>
          )}
          {!resultsFetched && areTeasers && (
            <Flex
              width="auto"
              mx="xs"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                {...getContactButtonProps}
                type="button"
                size="small"
                mode="standard-grey-light"
              >
                {isFetching
                  ? messages.person.teasers.fetchingContactDetails
                  : messages.person.teasers.getContactDetails}
              </Button>
            </Flex>
          )}
        </Flex>
      )}
    </Div>
  )
}

export default React.memo(ContactLookUp)
