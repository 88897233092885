import React, { useMemo, useState } from "react"
import {
  LatestNoteSection,
  NoteTimeStamp,
  NoteTitle,
  StyledBodyText,
  NoteHyphen
} from "./style"
import { messages } from "setup/messages/messages"
import { ShowMore } from "components/ShowMore"
import { PersonNote } from "views/persons/components/person-notes/types"
import {
  Div,
  Flex,
  Tag,
  TagVariants,
  theme,
  BodyText,
  getMinWidthMediaQuery
} from "@ikiru/talentis-fpc"
import moment from "moment"
import { ButtonStyled, DropDownIconStyled } from "components/ShowMore/style"
import { Link } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import { useMediaQuery } from "utils/hooks/use-media-query"

type LatestCompanyNotesProps = {
  note: PersonNote
}

type ProjectTagType = {
  projectUrl: string
  tagColorVariant: TagVariants | undefined
  projectTitle: string
}

const LatestCompanyNotes = ({ note }: LatestCompanyNotesProps) => {
  const [noteIsExpended, setNoteIsExpended] = useState(false)
  const {
    noteTitle,
    noteDescription,
    createdDate,
    linkCreatedByUser,
    linkAssignment,
    linkCampaign,
    assignmentId,
    campaignId
  } = note

  const projectTag: ProjectTagType | undefined = useMemo(() => {
    if (assignmentId || campaignId) {
      return {
        projectUrl: assignmentId
          ? `${RouterUrl.AssignmentDetails}/${linkAssignment?.id}`
          : `${RouterUrl.CampaignDetails}/${linkCampaign?.id}`,
        tagColorVariant: assignmentId ? "grey-dark" : "grey-standard",
        projectTitle:
          (assignmentId ? linkAssignment?.name : linkCampaign?.name) || ""
      }
    }
    return undefined
  }, [assignmentId, campaignId, linkAssignment, linkCampaign])

  const mediaQuery = getMinWidthMediaQuery("sm")
  const isLarge = useMediaQuery(mediaQuery)

  const showTag = useMemo(
    () => !isLarge || noteIsExpended,
    [isLarge, noteIsExpended]
  )

  return (
    <LatestNoteSection noteIsExpended={noteIsExpended} width="100%">
      <Flex
        flexDirection={isLarge ? "row" : "column"}
        justifyContent="flex-start"
        alignItems="baseline"
        mb="8px"
        minWidth="max-content"
      >
        <Flex>
          <StyledBodyText>{messages.person.note.latestNote}:</StyledBodyText>
          <Div maxWidth={!isLarge || noteIsExpended ? "100%" : "200px"}>
            <NoteTitle>{noteTitle}</NoteTitle>
          </Div>
        </Flex>
        {!noteIsExpended && isLarge && <NoteHyphen>-</NoteHyphen>}
        {showTag && projectTag && (
          <Tag
            variant={projectTag.tagColorVariant}
            m="0px"
            ml={isLarge ? "xs" : "none"}
            style={{ whiteSpace: "normal" }}
          >
            <Link to={projectTag.projectUrl}>{projectTag.projectTitle}</Link>
          </Tag>
        )}
      </Flex>
      <Div width="100%">
        <ShowMore
          visibleLines={1}
          description={
            <BodyText
              m="none"
              pr="m"
              whiteSpace="normal"
              color={theme.colors.grey.dark}
            >
              {noteDescription?.trim()}
            </BodyText>
          }
          descriptionContainerProps={{
            width: "100%",
            flexDirection: noteIsExpended ? "column" : "row",
            justifyContent: "space-between"
          }}
          hasCustomDropDownIcon
          extraInfo={(setIsExpanded, isExpanded, isShowedButton) => {
            return (
              <Flex
                justifyContent="space-between"
                mt={noteIsExpended ? "7px" : theme.space.xxs}
                mb="2px"
                flex="100%"
              >
                <NoteTimeStamp mt="0px" mb="7px">
                  {moment(createdDate).format("DD/MM/YYYY") +
                    " " +
                    linkCreatedByUser?.firstName +
                    " " +
                    linkCreatedByUser?.lastName}
                </NoteTimeStamp>
                {(isShowedButton || (projectTag && isLarge)) && (
                  <ButtonStyled
                    mode="standard-white"
                    size="action-small"
                    onClick={() => {
                      setNoteIsExpended(!isExpanded)
                      setIsExpanded(!isExpanded)
                    }}
                    hasBackground={false}
                    mt="-7px"
                    ml="xxs"
                    mr="3px"
                  >
                    <DropDownIconStyled
                      onClick={() => {
                        setNoteIsExpended(!isExpanded)
                        setIsExpanded(!isExpanded)
                      }}
                      up={isExpanded}
                    />
                  </ButtonStyled>
                )}
              </Flex>
            )
          }}
        />
      </Div>
    </LatestNoteSection>
  )
}

export default LatestCompanyNotes
