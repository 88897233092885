import React, { SyntheticEvent, useEffect, useMemo, useState } from "react"
import { Nullable } from "tsdef"
import { spacing, Flex, BodyText, ConnectedIcon } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { WebSite } from "views/persons/person.types"
import { OverlayLoader } from "components/Loader/OverlayLoader"
import { e2eTargets, Location } from "./consts/definitions"
import { WebSites } from "components/WebSites/index"
import { Tags } from "components/PersonRecords/Tags/index"
import { StageDetailsBox, WebsitesRight, WebsitesBottom } from "./styles"
import {
  RecordBox,
  NameDetailsBox,
  CompanyDetailsBox,
  AvatarBox,
  RecordBoxWrapper,
  DetailsBox,
  RecordFooterBox,
  RecordFooterTabs,
  RecordFooterTabItem,
  OffLimitsBox,
  EmptyBox
} from "views/persons/styles"
import { Candidate } from "views/candidates/candidates.types"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { useTagsCandidates } from "./components/Tags/useTagsCandidates"
import { PersonRecordNotes } from "components/PersonRecords/Notes/index"
import { usePersonRecordNotes } from "components/PersonRecords/Notes/hooks/usePersonRecordNotes"
import { usePersonRecordCommentary } from "components/PersonRecords/Commentary/hooks/usePersonRecordCommentary"
import { usePersonRecordBiography } from "components/PersonRecords/Bio/hooks/usePersonRecordBiography"
import { PersonAvatarOverlay } from "components/PersonRecords/OverlayOpenner/PersonAvatar"
import { PersonLink } from "components/PersonRecords/OverlayOpenner/PersonLink"
import { InterviewProgressStage } from "./components/InterviewProgress/definitions"
import { Bio } from "./components/Bio/Bio"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"
import ProjectActionPopUp from "components/ProjectActions/ProjectActionPopUp"
import { RouterUrl } from "setup/router/routes"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"
import { OffLimitsBlock } from "components/OffLimits/OffLimitsBlock"

type CandidateRecordLayoutProps = {
  borderless?: boolean
  personId?: Nullable<string>
  name?: Nullable<string>
  company?: Nullable<string>
  companyId?: Nullable<string>
  jobTitle?: Nullable<string>
  photoUrl?: Nullable<string>
  bio?: Nullable<string>
  linkedInProfileUrl?: Nullable<string>
  webSites?: WebSite[]
  notes?: string[]
  isUpdating: boolean
  StageComponent?: React.ComponentType<React.PropsWithChildren<unknown>>
  isConnected?: boolean
  isLink?: boolean
  location?: Location
  candidateUrl?: string
  candidate?: Candidate
  isOfflimits?: boolean | undefined
}

export type menuTabsEnum = "notes" | "commentary" | "bio"

export const CandidateRecordLayout = React.memo(
  ({
    borderless = false,
    name,
    personId,
    company,
    companyId,
    jobTitle,
    linkedInProfileUrl,
    webSites,
    isUpdating,
    StageComponent,
    isConnected,
    location,
    candidate,
    photoUrl,
    isOfflimits
  }: CandidateRecordLayoutProps) => {
    const {
      setSelectedCandidatedId,
      assignmentDetails,
      assignmentId,
      selectedTab
    } = useAssignment()

    const personIdTest = candidate?.personId || personId || ""
    const candidateId = candidate?.id as string
    const bio = candidate?.bio?.data

    const {
      note,
      onCreateNoteSubmit,
      onEditNoteSubmit,
      openNoteModalCallback
    } = usePersonRecordNotes(personIdTest, assignmentDetails.id, "assignment")

    const personNote = useMemo(
      () => (selectedTab === "companyCandidate" ? candidate?.note : note),
      [note, candidate?.note, selectedTab]
    )

    const {
      onCreateCommentarySubmit,
      onEditCommentarySubmit,
      prepareNoteView,
      openCommentaryModal
    } = usePersonRecordCommentary(candidateId)
    const { openBiographyModal } = usePersonRecordBiography(
      candidate as Candidate
    )

    const [activeTabName, setActiveTab] = useState<menuTabsEnum>()
    const [clickedTabName, setClickedTabName] = useState<menuTabsEnum>()

    useEffect(() => {
      const tabsList: menuTabsEnum[] = []
      if (personNote) {
        tabsList.push("notes")
      }
      if (candidate?.commentary) {
        tabsList.push("commentary")
      }
      if (bio) {
        tabsList.push("bio")
      }
      const clickedTab =
        tabsList[tabsList.findIndex((val) => val === clickedTabName)] ||
        tabsList[0]
      setActiveTab(clickedTabName ? clickedTab : tabsList[0])
    }, [personNote, candidate, clickedTabName, bio])

    const options: HeaderOption[] = [
      {
        label: messages.person.note.title,
        onClick: () => {
          openNoteModalCallback(
            name,
            assignmentDetails.name,
            messages.assignment.addNew
          )()
        }
      },
      {
        label: messages.person.commentary.title,
        onClick: ((e: MouseEvent) => {
          if (candidate) {
            openCommentaryModal(
              name as string,
              candidate
            )(candidate?.commentary && prepareNoteView(candidate))
          }
        }) as VoidFunction
      },
      {
        label: candidate ? (
          <Tags
            personRecordTags={candidate.tags}
            tags={assignmentDetails.tags}
            personRecordId={candidate.id}
            usePersonRecordTags={useTagsCandidates}
            name={assignmentDetails.name}
            showOnlyAddButton={true}
            outputOnlyLabel={true}
            label={messages.assignment.assignmentTags}
          />
        ) : (
          ""
        ),
        onClick: ((e: SyntheticEvent) => {
          const label = (e.target as HTMLElement)?.querySelector(
            "#tags-label"
          ) as HTMLElement
          label?.click()
          e.preventDefault()
          e.stopPropagation()
          e.nativeEvent.stopImmediatePropagation()
        }) as VoidFunction,
        disableClose: true
      },
      {
        label: messages.person.bio.title,
        onClick: ((e: MouseEvent) => {
          if (candidate) {
            openBiographyModal(name as string)()
          }
        }) as VoidFunction
      },
      {
        label: messages.assignment.similarPeople.findSimilarPeople,
        disabled:
          candidate?.linkPerson?.dataPoolPerson?.dataPoolId ===
            "00000000-0000-0000-0000-000000000000" ||
          !candidate?.linkPerson?.dataPoolPerson?.dataPoolId,
        onClick: (() => {
          window.open(
            `${window.location.protocol + "//" + window.location.host}${
              RouterUrl.Search
            }/${assignmentId}/assignment
              ?personId=${
                candidate?.linkPerson.dataPoolPerson?.dataPoolId
              }&name=${name}`,
            "_blank"
          )
        }) as VoidFunction
      }
    ]

    const isFooterHasContent = () => {
      return personNote || candidate?.commentary || bio
    }

    return (
      <RecordBoxWrapper
        borderless={borderless}
        isNotArchive={
          candidate?.interviewProgressState?.stage !==
          InterviewProgressStage.Archive
        }
      >
        <RecordBox isOfflimits={isOfflimits}>
          {isOfflimits && (
            <OffLimitsBox>
              <OffLimitsBlock
                isSmall
                isLeftCircle={!!isFooterHasContent}
                isCircle={!isFooterHasContent}
              />
            </OffLimitsBox>
          )}
          <OverlayLoader
            data-e2e-target-name={e2eTargets.candidateLoader}
            isLoading={isUpdating}
          >
            {messages.assignment.details.candidates.updating}
          </OverlayLoader>
          <AvatarBox isOfflimits={isOfflimits}>
            <PersonAvatarOverlay
              name={name}
              photoUrl={candidate?.photo || photoUrl}
              personId={candidate?.personId}
              setPersonId={setSelectedCandidatedId}
              companyId={candidate?.companyId || undefined}
            />
          </AvatarBox>
          <DetailsBox isOfflimits={isOfflimits}>
            <NameDetailsBox>
              <Flex alignItems="center">
                <PersonLink
                  personId={candidate?.personId}
                  underline
                  setPersonId={setSelectedCandidatedId}
                  companyId={candidate?.companyId || undefined}
                >
                  {name}
                </PersonLink>
                {isConnected && (
                  <ConnectedIcon
                    style={{ minWidth: spacing.m }}
                    width={spacing.m}
                  />
                )}
                <WebsitesRight ml="s" height="24px">
                  <WebSites
                    linkedInProfileUrl={linkedInProfileUrl || undefined}
                    webSites={webSites || []}
                    iconSize={24}
                    marginRight="7px"
                  />
                </WebsitesRight>
              </Flex>
            </NameDetailsBox>
            <CompanyDetailsBox>
              <CompanyLink
                companyName={company}
                companyId={companyId}
                fontSize={[15, 15, 16]}
                data-e2e-target={`candidate-${name}`}
                data-e2e-target-name={e2eTargets.candidateCompany}
                my={0}
              />
              <BodyText
                data-e2e-target={`candidate-${name}`}
                data-e2e-target-name={e2eTargets.candidateJob}
                my={0}
              >
                {jobTitle}
              </BodyText>
              {location && (
                <BodyText
                  fontSize="13px"
                  {...(personNote ||
                  (candidate && Boolean(candidate.tags.length))
                    ? { mt: 0, mb: spacing.xxs }
                    : { mt: spacing.xxs })}
                >
                  {location.addressLine || (
                    <>
                      {location.cityName || ""}
                      {location.cityName && location.countryName ? ", " : ""}
                      {location.countryName || ""}
                    </>
                  )}
                </BodyText>
              )}
            </CompanyDetailsBox>
            <WebsitesBottom pl="xs" mb={spacing.xxs} height="24px">
              <WebSites
                linkedInProfileUrl={linkedInProfileUrl || undefined}
                webSites={webSites || []}
                iconSize={24}
                marginRight="7px"
              />
            </WebsitesBottom>
            {!isOfflimits && <EmptyBox />}
          </DetailsBox>
          {StageComponent && (
            <StageDetailsBox
              flexDirection={["column", "column", "row"]}
              alignItems={["flex-end", "flex-end", "flex-start"]}
            >
              <StageComponent />
              {candidate && (
                <Flex flexDirection="column" alignItems="flex-end" pl="m">
                  <ProjectActionPopUp options={options} />
                </Flex>
              )}
            </StageDetailsBox>
          )}
        </RecordBox>
        <RecordFooterBox>
          {isFooterHasContent() && (
            <RecordFooterTabs>
              {personNote && (
                <RecordFooterTabItem
                  onClick={() => {
                    setActiveTab("notes")
                    setClickedTabName("notes")
                  }}
                  active={activeTabName === "notes"}
                >
                  {messages.person.note.latestNote}
                </RecordFooterTabItem>
              )}
              {candidate?.commentary && (
                <RecordFooterTabItem
                  onClick={() => {
                    setActiveTab("commentary")
                    setClickedTabName("commentary")
                  }}
                  active={activeTabName === "commentary"}
                >
                  {messages.person.commentary.title}
                </RecordFooterTabItem>
              )}
              {bio && (
                <RecordFooterTabItem
                  onClick={() => {
                    setActiveTab("bio")
                    setClickedTabName("bio")
                  }}
                  active={activeTabName === "bio"}
                >
                  {messages.person.bio.title}
                </RecordFooterTabItem>
              )}
            </RecordFooterTabs>
          )}
          {bio && activeTabName === "bio" && (
            <Bio candidate={candidate as Candidate} description={bio} />
          )}
          {personNote && activeTabName === "notes" && (
            <PersonRecordNotes
              note={personNote}
              personName={name}
              name={assignmentDetails.name}
              onEdit={onEditNoteSubmit}
              onCreate={onCreateNoteSubmit}
              typeTitle={messages.assignment.addNew}
              showAddIcon={false}
              showMostRecent={false}
            />
          )}
          {candidate?.commentary && activeTabName === "commentary" && (
            <PersonRecordNotes
              note={candidate?.commentary && prepareNoteView(candidate)}
              personName={name}
              name={assignmentDetails.name}
              onEdit={onEditCommentarySubmit}
              onCreate={onCreateCommentarySubmit}
              typeTitle={messages.assignment.addNew}
              showAddIcon={false}
              showMostRecent={false}
              openModal={
                openCommentaryModal(name as string, candidate) as () => {}
              }
            />
          )}
          {candidate && Boolean(candidate.tags.length) && (
            <Tags
              personRecordTags={candidate.tags}
              tags={assignmentDetails.tags}
              personRecordId={candidate.id}
              usePersonRecordTags={useTagsCandidates}
              name={assignmentDetails.name}
              showAddIcon={false}
            />
          )}
        </RecordFooterBox>
      </RecordBoxWrapper>
    )
  }
)
