import React from "react"
import { H5, BodyText, Div, Flex } from "@ikiru/talentis-fpc"
import {
  CampaignPrimaryData,
  campaignStatusMap,
  e2eTargets
} from "views/campaigns/components/campaign-data/constants/definitions"
import { messages } from "setup/messages/messages"
import { formatDate } from "utils/format-date"
import { ProjectTeamMembers } from "views/campaigns/campaign.types"

export const CampaignDataView = React.memo(
  ({
    name,
    startDate,
    status,
    description
  }: CampaignPrimaryData & {
    campaignId: string
    projectTeamMembers?: ProjectTeamMembers[]
  }) => {
    return (
      <>
        <Flex>
          <Div pt="xxs">
            <H5 my="0">{messages.campaign.campaignName}</H5>
            <BodyText data-e2e-target-name={e2eTargets.name} my={0}>
              {name}
            </BodyText>
          </Div>
          <Div pt="xxs" ml={20} pl={20}>
            <H5 my="0">{messages.campaign.campaignStatus}</H5>
            <BodyText data-e2e-target-name={e2eTargets.status} my={0}>
              {campaignStatusMap.get(status)}
            </BodyText>
          </Div>
        </Flex>
        <Div pt="xs" mt={10}>
          <H5 my="0">{messages.campaign.startDate}</H5>
          <BodyText data-e2e-target-name={e2eTargets.startDate} my={0}>
            {formatDate(startDate)}
          </BodyText>
        </Div>
        <Div pt="xs" mt={10}>
          <H5 my="0">{messages.campaign.campaignDescription}</H5>
          <BodyText mt="xxs">{description}</BodyText>
        </Div>
      </>
    )
  }
)
