import { createContext, useContext } from "react"
import { SubscriptionPurchaseContextValues } from "./subscription-purchase-module.types"
import { initialSubscriptionPurchaseContextValues } from "./subscription-purchase-module.reducer"

export const SubscriptionPurchaseContext =
  createContext<SubscriptionPurchaseContextValues>(
    initialSubscriptionPurchaseContextValues
  )

export const useSubscriptionPurchase = () =>
  useContext(SubscriptionPurchaseContext)
