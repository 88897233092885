export enum PersonAssignments {
  Assignments = "assignments"
}

export type PersonAssignmentsValues = {
  [PersonAssignments.Assignments]: string
}

const fieldTargets = {
  assignmentsSelect: "assignments-select"
}

const elementTargets = {
  submitButton: "add-assignment",
  assignment: "assignment",
  assignments: "assignments"
}

export const personAssignmentsE2ETargets = {
  ...fieldTargets,
  ...elementTargets
}

export const getPersonAssignmentsInitialValues = (id?: string) => ({
  [PersonAssignments.Assignments]: id || ""
})
