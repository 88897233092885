import React, { useMemo } from "react"
import { Button, Div, Flex, PlusIcon } from "@ikiru/talentis-fpc"
import FilterWrapper from "../FilterWrapper"
import { SearchSectionBox } from "./SearchSectionBox"
import { useAddSectionBox } from "views/internal-search/hooks"
import { InternalFilterType } from "views/internal-search/InternalSearchModule/types"
import { LogicalConnection } from "../LogicalConnection"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { getLocalSearchResult } from "views/internal-search/actions"
import { SECTION_BOXES_LIMIT } from "./consts"

export const SearchSection = () => {
  const { boxes, boxesCount, addNewBox } = useAddSectionBox(
    InternalFilterType.Sections
  )

  const {
    filters,
    setInternalPersons,
    setIsLoadingSearchResults,
    setInternalSearchPage,
    setIsLookup
  } = useInternalSearch()

  const runSearch = async () => {
    setIsLookup(false)
    setIsLoadingSearchResults(true)
    const { pages, personsWithCampaignIdsAssignmentIds } =
      await getLocalSearchResult(filters)

    setInternalSearchPage(pages)
    setInternalPersons(personsWithCampaignIdsAssignmentIds)
  }

  const hasValidField = useMemo(() => {
    if (!filters.sections || !Array.isArray(filters.sections)) {
      return false
    }

    return filters.sections.some((section) => {
      if (!section.fields || !Array.isArray(section.fields)) {
        return false
      }

      return section.fields.some((field) => {
        if (!field) {
          return false
        }

        if (field.id === "dob" && field.condition === "between") {
          return (
            Array.isArray(field.values) &&
            field.values.length === 2 &&
            field.values.every((value: string) => value !== "")
          )
        }

        if (
          field.id === "dob" &&
          ["after", "before"].includes(field.condition)
        ) {
          return (
            Array.isArray(field.values) &&
            field.values.length > 0 &&
            field.values[0] !== ""
          )
        }

        if (
          !["notBlank", "blank"].includes(field.condition) &&
          !(field.id === "dob")
        ) {
          return (
            field.values &&
            field.values.length > 0 &&
            field.values.every((value: string) => value.trim() !== "")
          )
        }

        return true
      })
    })
  }, [filters])

  return (
    <Flex flexDirection="column" width="100%" height="auto">
      {boxes.map((box, index) => (
        <React.Fragment key={box.idBox}>
          <FilterWrapper>
            <SearchSectionBox index={index} boxesCount={boxesCount} />
          </FilterWrapper>
          {boxesCount > 1 && index !== boxesCount - 1 && (
            <Div my="10px">
              <LogicalConnection />
            </Div>
          )}
          {index === boxesCount - 1 && boxesCount < SECTION_BOXES_LIMIT && (
            <Flex my="m">
              <Button
                mode="standard-white"
                size="small"
                mr="s"
                //@ts-ignore
                onClick={addNewBox}
                prefix={<PlusIcon width={12} height={12} />}
              >
                Add another set of search criteria
              </Button>
              <Div>
                <Button
                  type="submit"
                  mode="primary"
                  size="small"
                  onClick={runSearch}
                  disabled={!hasValidField}
                >
                  Run search
                </Button>
              </Div>
            </Flex>
          )}
        </React.Fragment>
      ))}
    </Flex>
  )
}
