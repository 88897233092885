import { Div, Flex, H5, Loader, Section, Span } from "@ikiru/talentis-fpc"
import _ from "lodash"
import moment from "moment"
import React, { useMemo } from "react"
import { messages } from "setup/messages/messages"
import { FirmTokenDetails } from "../../../definitions"

type ContactCreditsProps = {
  firmToken: FirmTokenDetails | undefined
  isLoading: boolean
}

const ContactCredits = (props: ContactCreditsProps) => {
  const { isLoading, firmToken } = props

  const groupedPlans = useMemo(() => {
    const sorted = firmToken?.details?.sort((a, b) =>
      moment(a.expiredAt).diff(moment(b.expiredAt), "days")
    )
    return _.groupBy(sorted, function (e) {
      return moment(e.expiredAt).format("DD/MM/YYYY")
    })
  }, [firmToken])

  const totalTokens = useMemo(() => firmToken?.total || 0, [firmToken])
  const expiring = useMemo(() => {
    const data: Array<{ tokens: number; label: string }> = []

    _.map(groupedPlans, function (plans, key) {
      let tCount = 0
      plans.forEach((p) => (tCount += p.tokens))
      data.push({
        tokens: tCount,
        label: key
      })
    }).slice(0, 3)
    return data
  }, [groupedPlans])

  return (
    <Section
      size="xSmall"
      header={{
        title: messages.billing.contactCredits.title
      }}
    >
      <Div width="100%" pt="xs" pb="s">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <H5 mt="xs" mb="xs">
              {messages.billing.contactCredits.remaining}: {totalTokens}
            </H5>
            {!_.isEmpty(groupedPlans) && (
              <>
                <Flex mt="xs" mb="s" flexDirection="row">
                  <H5 m={0}>{messages.billing.contactCredits.expiring}</H5>
                  &nbsp;
                  <Span>{messages.billing.contactCredits.expiringInfo}</Span>
                </Flex>
                <Flex flexDirection="column">
                  {expiring.map((entry, i) => (
                    <Span
                      key={i}
                      fontSize={15}
                    >{`${entry.tokens} ${messages.billing.contactCredits.validUntil} ${entry.label}`}</Span>
                  ))}
                </Flex>
              </>
            )}
          </>
        )}
      </Div>
    </Section>
  )
}
export default ContactCredits
