import styled from "styled-components"
import { H4, H5 } from "@ikiru/talentis-fpc"
import { SpaceProps, space } from "styled-system"

export const PersonSectionHeaders = styled(H4)`
  font-size: 18px;
  margin: 0px;
  color: ${({ theme }) => theme.colors.grey.dark};
`
export const SectionTitle = styled(H5)<SpaceProps>`
  margin: 0px;
  color: ${({ theme }) => theme.colors.grey.darkest};
  ${space};
`
