import styled from "styled-components"
import { Div } from "@ikiru/talentis-fpc"
import { hexToRGB } from "utils/hexToRGB"
import get from "lodash/get"
import { spanTagColor } from "views/assignments/components/candidates-list/helpers"

type StyledSpanProps = {
  background: string
}

export const StyledSpan = styled.span<StyledSpanProps>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: -2px;
  background-color: ${({ theme, background }) =>
    get(theme.colors, spanTagColor(background))};
`

export const CandidateFilterContainer = styled(Div)`
  padding: ${({ theme }) => theme.space.s}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;
  background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.5)};
`
