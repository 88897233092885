import { messages } from "setup/messages/messages"
import { Campaign, CampaignStatus } from "views/campaigns/campaign.types"

export enum CampaignDataField {
  Name = "name",
  StartDate = "startDate",
  Status = "status",
  Description = "description"
}

export type CampaignPrimaryData = Pick<
  Campaign,
  "name" | "startDate" | "status" | "description"
>

export type CampaignStatusOption = {
  label: string
  value: CampaignStatus
}

export const campaignPrimaryDataInitialValues: CampaignPrimaryData = {
  [CampaignDataField.Name]: "",
  [CampaignDataField.Status]: CampaignStatus.Active,
  [CampaignDataField.StartDate]: new Date(),
  [CampaignDataField.Description]: ""
}

export const campaignStatusMap = new Map([
  [CampaignStatus.Active, messages.campaign.statuses.active],
  [CampaignStatus.Closed, messages.campaign.statuses.closed]
])

const active: CampaignStatusOption = {
  value: CampaignStatus.Active,
  label: messages.campaign.statuses.active
}

const closed: CampaignStatusOption = {
  value: CampaignStatus.Closed,
  label: messages.campaign.statuses.closed
}

export const campaignStatuses = [active, closed]

export const e2eTargets = {
  primaryDetails: "primary-details",
  name: "name",
  startDate: "start-date",
  status: "status"
}
