import { OffLimitsType } from "../type"

export enum EditOffLimitsField {
  EndDate = "endDate",
  Comment = "comment"
}

export const editOffLimitsFieldInitialValues = {
  [EditOffLimitsField.EndDate]: null,
  [EditOffLimitsField.Comment]: ""
}

export type OffLimitsData = Pick<OffLimitsType, "endDate" | "comment">
