import React from "react"
import { Popup } from "@ikiru/talentis-fpc"
import { navigationSelectors } from "components/visual/layout/definitions"
import { Div } from "@ikiru/talentis-fpc"
import { GeneralFilters } from "./filters/GeneralFilters"
import { messages } from "../../../../../../setup/messages/messages"
import { useCandidateFilter } from "./hooks"
import useEditCandidateFilter from "./hooks/useEditCandidateFilter"
import { FilterFlex } from "./style"

interface FilterPopupProps {
  trigger: React.ReactElement
  onOpen?: () => any
  onClose?: () => any
}

export const FilterPopup = (props: FilterPopupProps) => {
  const { trigger, onOpen, onClose } = props
  const {
    interviewProgressStatusFilters,
    assignedToFilters,
    dueDateFilters,
    tagsCandidatesFilters,
    filterIsApplied,
    addFilter
  } = useCandidateFilter()

  const { removeFilter } = useEditCandidateFilter()

  const newConfig = {
    PopupProps: {
      arrow: false,
      keepTooltipInside: true,
      onOpen,
      onClose
    },
    ContentProps: {
      boxShadow: "0 0 4px rgba(75, 106, 136, 0.8)"
    }
  }

  return (
    <Popup
      trigger={trigger}
      config={newConfig}
      closeOnScrollSelector={`#${navigationSelectors.mainContainer}`}
    >
      <FilterFlex
        flexWrap={["wrap", "nowrap"]}
        bg="grey.lightest"
        maxHeight={500}
      >
        <Div minWidth={["auto", "170px"]} bg="white.standard" flex={1}>
          <GeneralFilters
            translatedString={messages.assignment.filters.status}
            filtersData={interviewProgressStatusFilters}
            {...{ filterIsApplied, addFilter, removeFilter }}
          />
        </Div>
        <Div minWidth={["auto", "170px"]} ml="xxs" bg="white.standard" flex={1}>
          <GeneralFilters
            translatedString={messages.assignment.filters.assignedTo}
            filtersData={assignedToFilters}
            {...{ filterIsApplied, addFilter, removeFilter }}
          />
        </Div>
        <Div minWidth={["auto", "170px"]} ml="xxs" bg="white.standard" flex={1}>
          <GeneralFilters
            translatedString={messages.assignment.filters.dueDate}
            filtersData={dueDateFilters}
            {...{ filterIsApplied, addFilter, removeFilter }}
          />
        </Div>
        <Div minWidth={["auto", "195px"]} ml="xxs" bg="white.standard" flex={1}>
          <GeneralFilters
            translatedString={messages.assignment.filters.tagsCandidates}
            filtersData={tagsCandidatesFilters}
            {...{ filterIsApplied, addFilter, removeFilter }}
          />
        </Div>
      </FilterFlex>
    </Popup>
  )
}
