import React, { useState } from "react"
import {
  DropdownArrowIcon as DropdownArrow,
  Flex,
  theme
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { RouterUrl } from "setup/router/routes"
import useAssignmentActions from "../../hooks/useAssignmentActions"
import { useNavigate } from "react-router-dom"
import ProjectActionPopUp from "components/ProjectActions/ProjectActionPopUp"
import ActionButton from "components/ProjectActions/ActionButton"
import RotateAction from "components/ProjectActions/RotateActionIcon"

type CandidatesListActionsProps = {
  disabled: boolean
}

export const CandidatesListActions = React.memo(
  ({ disabled }: CandidatesListActionsProps) => {
    const [popupOpen, setPopupOpen] = useState(false)

    const { assignmentId, assignmentDetails, candidates } = useAssignment()
    const { searchAI } = useAssignmentActions()
    const navigate = useNavigate()
    const options = [
      {
        label: messages.assignment.details.actions.quickSearch,
        onClick: () => {
          navigate(
            `${RouterUrl.ProfileNewContact}?assignmentId=${assignmentId}`
          )
        }
      },
      {
        label: messages.assignment.details.candidates.advanceSearch,
        disabled: disabled || candidates.length === 0,
        onClick: () => {
          navigate(RouterUrl.Search + `/${assignmentId}/assignment`)
        }
      },
      {
        label: messages.assignment.details.candidates.searchAI,
        onClick: () => searchAI(),
        disabled:
          candidates.length === 0 ||
          !assignmentDetails.isCompanyLinkedToDataPool
      },
      {
        label: messages.assignment.details.actions.searchLocalDatabase,
        onClick: () => {
          navigate(`${RouterUrl.InternalSearch}?assignmentId=${assignmentId}`)
        }
      }
    ]

    return (
      <Flex flexDirection="column">
        <Flex ml="auto">
          {candidates.length > 0 && (
            <Flex py="xs">
              <ProjectActionPopUp
                options={options}
                getIfActive={setPopupOpen}
                config={{
                  ContentProps: {
                    marginRight: 0,
                    marginTop: 5
                  }
                }}
                alternativeButton={
                  <ActionButton
                    size="small"
                    mode="secondary"
                    pt="xxs"
                    pb="xxs"
                    mr="xs"
                    label={messages.assignment.details.actions.addCandidate}
                    suffix={
                      <RotateAction isActive={popupOpen}>
                        <DropdownArrow
                          fill={theme.colors.green.dark}
                          width={13}
                          height={13}
                        />
                      </RotateAction>
                    }
                  />
                }
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  }
)
