import React from "react"
import { NoteField } from "./definitions"
import { messages } from "setup/messages/messages"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { StyledFormikTextarea } from "../style"

const NoteFormLayout = () => (
  <>
    <FormikInput
      id={NoteField.NoteTitle}
      name={NoteField.NoteTitle}
      label={messages.person.note.form.noteTitle}
    />
    <StyledFormikTextarea
      rows={4}
      id={NoteField.NoteDescription}
      name={NoteField.NoteDescription}
      label={messages.person.note.form.noteDescription}
      isMicrophone
    />
  </>
)

export default React.memo(NoteFormLayout)
