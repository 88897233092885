import React, { useEffect, useState } from "react"
import { messages } from "setup/messages/messages"
import {
  PersonalNoteBioItemStyled,
  RecordFooterBox,
  RecordFooterTabItem,
  RecordFooterTabs
} from "./styles"
import { ShowMore } from "components/ShowMore"
import { Note } from "components/Notes/types"
import { PersonNote } from "./PersonNote"
import { colors } from "@ikiru/talentis-fpc"

type PersonFooterProps = {
  bio: string
  note?: Note
}

export enum MenuTabsEnum {
  Notes = "notes",
  Bio = "bio"
}

export const PersonFooter = ({ bio, note }: PersonFooterProps) => {
  const [activeTabName, setActiveTab] = useState<MenuTabsEnum>()
  const [clickedTabName, setClickedTabName] = useState<MenuTabsEnum>()

  useEffect(() => {
    const tabsList: MenuTabsEnum[] = []

    if (bio) tabsList.push(MenuTabsEnum.Bio)
    if (note) tabsList.push(MenuTabsEnum.Notes)

    const selectedTab = clickedTabName || tabsList[0]
    setActiveTab(selectedTab)
  }, [note, bio, clickedTabName])

  const hasContent = bio || note

  const renderTabItem = (tab: MenuTabsEnum, label: string) => (
    <RecordFooterTabItem
      key={tab}
      onClick={() => {
        setActiveTab(tab)
        setClickedTabName(tab)
      }}
      active={activeTabName === tab}
    >
      {label}
    </RecordFooterTabItem>
  )

  return (
    <RecordFooterBox>
      {hasContent && (
        <RecordFooterTabs>
          {bio && renderTabItem(MenuTabsEnum.Bio, messages.person.bio.title)}
          {note &&
            renderTabItem(MenuTabsEnum.Notes, messages.person.note.latestNote)}
        </RecordFooterTabs>
      )}

      {bio && activeTabName === MenuTabsEnum.Bio && (
        <PersonalNoteBioItemStyled pb="xs">
          <ShowMore
            isSmall
            visibleLines={3}
            description={bio}
            color={colors.grey.dark}
          />
        </PersonalNoteBioItemStyled>
      )}

      {note && activeTabName === MenuTabsEnum.Notes && (
        <PersonNote note={note} />
      )}
    </RecordFooterBox>
  )
}
