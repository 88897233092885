import React from "react"
import { Div, H5, BodyText, Flex, colors } from "@ikiru/talentis-fpc"
import { AssignmentFeeData } from "views/assignments/components/assignment-data/constants/definitions"
import { messages } from "setup/messages/messages"
import { ShowMore } from "components/ShowMore"
import { AssignmentDataBlock } from "../AssignmentDataBlock"
import { generateCurrency } from "views/assignments/utils"
import { Nullable } from "tsdef"

const generateEstimate = (
  feeFrom: Nullable<number>,
  feeTo: Nullable<number>,
  feeCurrency: string
) => {
  const feeFromTemp = generateCurrency(feeFrom, feeCurrency)
  const feeToTemp = generateCurrency(feeTo, feeCurrency)

  if (feeFromTemp && feeToTemp) {
    return `${feeFromTemp} - ${feeToTemp}`
  } else {
    return feeFromTemp || feeToTemp
  }
}

export const AssignmentFee = ({
  feeComment,
  feeCurrency,
  feeTo,
  feeFrom,
  finalFee
}: AssignmentFeeData) => {
  const hasFee = feeComment || feeTo || feeFrom || finalFee

  return (
    <Div py="m">
      {!hasFee && (
        <BodyText my="0" color={colors.grey.standard}>
          {messages.assignment.assignmentNoFee}
        </BodyText>
      )}
      <Flex>
        {Boolean(feeFrom || feeTo) && (
          <Div pr="xxs" minWidth="190px">
            <H5 my="0">{messages.assignment.assignmentFeeEstimate}</H5>
            <BodyText my="0">
              {generateEstimate(feeFrom, feeTo, feeCurrency)}
            </BodyText>
          </Div>
        )}
        {Boolean(finalFee) && (
          <AssignmentDataBlock
            title={messages.assignment.assignmentFinalFee}
            value={finalFee}
            currency={feeCurrency}
          />
        )}
      </Flex>
      {feeComment && (
        <Div mt="m">
          <H5 m="0">{messages.assignment.assignmentFeeComment}</H5>
          <ShowMore visibleLines={3} description={feeComment} />
        </Div>
      )}
    </Div>
  )
}
