import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { SpaceProps } from "styled-system"
import { Nullable } from "tsdef"
import { messages } from "setup/messages/messages"
import { Flex } from "@ikiru/talentis-fpc"
import { Input } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { Loader } from "@ikiru/talentis-fpc"
import { Error } from "@ikiru/talentis-fpc"
import { TickBadge } from "@ikiru/talentis-fpc"
import { PromoAddedText, RemoveLink } from "./style"
import { usePayment } from "views/subscription/components/billing-card-details/hooks"
import { useSubscriptionPurchase } from "views/subscription/subscription-purchase-module/subscription-purchase-module.context"
import { CouponType } from "views/subscription/subscription-purchase-module/coupons"

enum PromoState {
  Button = "button",
  Input = "input",
  Added = "added"
}

export const PromoCode = (props: SpaceProps) => {
  const { applyCoupon, removeCoupon } = usePayment()
  const { purchaseData } = useSubscriptionPurchase()
  const [state, setState] = useState<PromoState>(PromoState.Button)
  const [code, setCode] = useState<string>("")
  const [error, setError] = useState<Nullable<string>>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>()

  const initialCode = useMemo(() => {
    const userCoupons =
      purchaseData.couponsUsed?.filter(
        (coupon) => coupon.type === CouponType.USER_COUPON
      ) || []

    if (userCoupons?.length === 0) {
      return null
    }

    return userCoupons[0].code
  }, [purchaseData])

  const { ...spaceProps } = props

  // Set the code if we already have one
  useEffect(() => {
    if (initialCode) {
      setCode(initialCode)
      setState(PromoState.Added)
    }
  }, [setCode, setState, initialCode])

  // Display the input
  const onButtonClick = useCallback(() => {
    setState(PromoState.Input)
  }, [setState])

  // Attempt to add the code
  const onAddClick = useCallback(async () => {
    const code = inputRef.current?.value
    setError(null)

    if (!code) {
      setState(PromoState.Button)
      return
    }

    setIsLoading(true)

    try {
      await applyCoupon(code)
      setCode(code)
      setState(PromoState.Added)
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [setCode, setState, setIsLoading, setError, applyCoupon])

  // Remove a code
  const onRemoveClick = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault()

      setIsLoading(true)
      try {
        await removeCoupon(code)
        setCode("")
        setState(PromoState.Button)
      } catch (error) {
        setError(error.message)
      } finally {
        setIsLoading(false)
      }
    },
    [setCode, setState, removeCoupon, setIsLoading, setError, code]
  )

  // Display the button again if a blank input was blurred
  const onInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!event.target.value) {
        setState(PromoState.Button)
      }
    },
    [setState]
  )

  // Try to add the code if enter was pressed on the input
  const onInputKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        onAddClick()
      }
    },
    [onAddClick]
  )

  return (
    <Flex {...spaceProps} flexDirection="row" alignItems="center" width="100%">
      {state === PromoState.Button && (
        <Button
          size="extra-small"
          mode="standard-grey-light"
          onClick={onButtonClick}
        >
          + {messages.subscription.promoCode.promoCode}
        </Button>
      )}

      {state === PromoState.Input && (
        <Flex flexDirection="column">
          <Flex flexDirection="row" alignItems="center">
            <Input
              autoFocus
              disabled={isLoading}
              ref={inputRef}
              onBlur={onInputBlur}
              onKeyPress={
                onInputKeyPress as (
                  e: React.KeyboardEvent<HTMLInputElement>
                ) => void
              }
              label={messages.subscription.promoCode.promoCode}
            />
            <Button
              disabled={isLoading}
              ml="s"
              size="small"
              mode="standard-green"
              onClick={onAddClick}
            >
              {isLoading ? <Loader size="small" /> : messages.generic.add}
            </Button>
          </Flex>
          {error && <Error>{error}</Error>}
        </Flex>
      )}

      {state === PromoState.Added && (
        <Flex
          p="s"
          height="50px"
          flexDirection="row"
          alignItems="center"
          bg="#fff"
          width="100%"
        >
          <TickBadge mr="xs" />
          <Flex flexWrap="wrap">
            <PromoAddedText color="grey.dark" mr="xxs">
              {messages.subscription.promoCode.promoCodeAdded}
            </PromoAddedText>
            <PromoAddedText>{code}</PromoAddedText>
          </Flex>
          <RemoveLink size="small" onClick={onRemoveClick}>
            {messages.generic.remove}
          </RemoveLink>
        </Flex>
      )}
    </Flex>
  )
}
