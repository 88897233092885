import React, { useCallback, useState } from "react"
import { PersonKeywordsFields, personKeywordsInitialValues } from "./definition"
import { Form, Formik } from "formik"
import { KeywordsList } from "../components/KeywordsList"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import { Div } from "@ikiru/talentis-fpc"
import { ModalFormContainer } from "../../person-details-tab/components/person-details-section/form/style"
import FormActionButtons from "../../person-details-tab/components/FormActionButtons"
import { ModalNames } from "setup/modal/modal.definitions"
import { Keyword } from "views/persons/person-module/person-module.types"
import { KeywordsBlock } from "../styles"

type PersonKeywordEditProps = {
  onSubmit: (value: Keyword[]) => void
  currentKeywords: Keyword[]
}

const PersonKeywordEdit = ({
  onSubmit,
  currentKeywords
}: PersonKeywordEditProps) => {
  const [keywords, setKeywords] = useState(currentKeywords)
  const addKeywords = (keyword: string) => {
    const keywordNew = { value: keyword, id: null, keywordCategoryId: null }
    setKeywords([...keywords, keywordNew])
  }

  const removeKeyWords = useCallback(
    (index: number) => {
      const updateKeyWords = [...keywords]
      updateKeyWords.splice(index, 1)
      setKeywords(updateKeyWords)
    },
    [keywords]
  )

  const onKeyDown = (keyEvent: any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const submit = async () => {
    onSubmit(keywords)
  }

  return (
    <ModalFormContainer defaultWidth={600}>
      <Formik initialValues={personKeywordsInitialValues} onSubmit={submit}>
        {(formik) => {
          return (
            <Form onKeyDown={onKeyDown}>
              <FormActionButtons
                title={messages.person.personTabSection.form.attributesEditMode}
                ModalName={ModalNames.EditKeywords}
              />
              <Div mt="s" width="100%">
                <KeywordsBlock>
                  <KeywordsList
                    keywords={keywords}
                    onKeywordRemove={removeKeyWords}
                  />
                </KeywordsBlock>

                <FormikInput
                  name={PersonKeywordsFields.AddKeyword}
                  label={messages.person.keywords.form.addKeywordPlaceholder}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault()
                      Boolean(e.currentTarget.value) &&
                        addKeywords(e.currentTarget.value)
                      formik.setFieldValue(PersonKeywordsFields.AddKeyword, "")
                      formik.setSubmitting(false)
                    }
                  }}
                />
              </Div>
            </Form>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}

export default PersonKeywordEdit
