import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { useAddSectionField } from "views/internal-search/hooks"
import { Field } from "views/internal-search/InternalSearchModule/types"
import { SearchFieldBox } from "./SearchFieldBox"

type SearchFieldProps = {
  indexSection: number
  fields: Field[]
}

export const SearchField = ({ indexSection, fields }: SearchFieldProps) => {
  const { boxes, boxesCount } = useAddSectionField(indexSection)

  return (
    <Flex flexDirection="column" width="fit-content" height="auto">
      {boxes.map((box: any, index: number) => (
        <React.Fragment key={box.idBox}>
          <SearchFieldBox
            index={index}
            indexSection={indexSection}
            fields={fields}
            boxesCount={boxesCount}
          />
        </React.Fragment>
      ))}
    </Flex>
  )
}
