import { useMemo, useState } from "react"
import { SupportFormField } from "./form/constants/support.definitions"
import { SupportFormValues } from "./form/constants/support.types"
import { useAuth } from "setup/auth/module/auth.context"
import { apiRequest } from "setup/api/api"
import { UsersEndpoints } from "setup/api/endpoints/endpoints"

export const useSupport = () => {
  const { user } = useAuth()
  const [showSentMsg, setShowSentMsg] = useState(false)
  const supportInitialValues = useMemo(
    () => ({
      [SupportFormField.Name]:
        String(user?.userFirstName) + " " + String(user?.userLastName) + "",
      [SupportFormField.Company]: user?.searchFirmName + "",
      [SupportFormField.Email]: user?.userEmailAddress || "",
      [SupportFormField.Phone]: "",
      [SupportFormField.Message]: ""
    }),
    [user]
  )

  const onSubmit = async (values: SupportFormValues) => {
    await apiRequest.post({
      endpoint: UsersEndpoints.SendSupportEmail,
      data: {
        site: document.location.href,
        emailAddress: values.email,
        phoneNumber: values.phone,
        message: values.message
      }
    })
    setShowSentMsg(true)
    setTimeout(() => setShowSentMsg(false), 2000)
  }
  return {
    initialValues: supportInitialValues,
    onSubmit,
    showSentMsg
  }
}
