import React from "react"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import {
  PeopleWhiteIcon as PeopleIcon,
  AssignmentsWhiteIcon as AssignmentIcon,
  SupportWhite,
  CampaignWhiteIcon,
  ExternalLinkIcon,
  TeamWhiteIcon as TeamIcon,
  StarIcon,
  LogoutWhiteIcon as LogoutIcon,
  BillingWhiteIcon as BillingIcon,
  ReportIcon,
  EmailWhiteIcon,
  CompanyIcon
} from "@ikiru/talentis-fpc"
import {
  HeaderHelper,
  HeaderLink,
  HeaderOption,
  HeaderSetting,
  navigationE2ETargets
} from "./constants/navigation.definitions"
import {
  UserRolePermissionLevels,
  UserRoles
} from "setup/auth/module/constants/auth.types"
import { ReactComponent as GlobalSearchIcon } from "assets/global-search2.svg"
import { ReactComponent as CRMSearchIcon } from "assets/search_CRM_database.svg"

type GenerateParams = {
  isLoggedIn: boolean
  name?: string
  photo?: string
  login?: VoidFunction
  inviteUser?: VoidFunction
  signup?: VoidFunction
  expired?: boolean
  isTrial?: boolean
  userRole?: UserRoles
}

export const generateLinks = ({
  isLoggedIn,
  expired
}: GenerateParams): HeaderLink[] =>
  [
    {
      protected: true,
      path: RouterUrl.Search,
      label: messages.navigation.links.search,
      icon: <GlobalSearchIcon width="30px" height="30px" />,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.search
      },
      disabled: !!expired
    },
    {
      protected: true,
      path: RouterUrl.InternalSearch,
      label: messages.navigation.links.listContacts,
      icon: <CRMSearchIcon width="20px" />,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.profileSearch
      },
      disabled: !!expired
    },
    {
      protected: true,
      path: RouterUrl.CompaniesSearch,
      label: messages.navigation.links.companies,
      icon: <CompanyIcon width="20.5px" />,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.companiesSearch
      },
      disabled: !!expired
    },
    {
      protected: true,
      path: RouterUrl.AssignmentList,
      label: messages.navigation.links.listAssignment,
      icon: <AssignmentIcon />,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.assignmentList
      },
      disabled: !!expired
    },
    {
      protected: true,
      path: RouterUrl.CampaignList,
      label: messages.navigation.links.listCampaign,
      icon: <CampaignWhiteIcon />,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.campaignList
      },
      disabled: !!expired
    },
    {
      protected: true,
      path: RouterUrl.Reports,
      label: messages.navigation.links.reports,
      icon: <ReportIcon />,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.reports
      },
      disabled: !!expired
    },
    {
      protected: true,
      path: RouterUrl.Outlook,
      label: messages.navigation.links.outlook,
      icon: <EmailWhiteIcon />,
      activeLinkClassCustom: "outlook-link-active",
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.outlook
      },
      disabled: !!expired
    }
  ].filter((link: HeaderLink) =>
    isLoggedIn ? link.protected : link.guestOnly || !link.protected
  )

export const generateSettings = ({
  isLoggedIn,
  login,
  signup,
  userRole
}: GenerateParams): HeaderSetting[] =>
  [
    {
      protected: true,
      label: messages.manageTeam.team,
      icon: <TeamIcon />,
      path: RouterUrl.Team,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.team
      },
      hasPermission:
        UserRolePermissionLevels[userRole as UserRoles] >=
        UserRolePermissionLevels[UserRoles.Admin]
    },
    {
      protected: true,
      label: messages.navigation.actions.billing,
      icon: <BillingIcon />,
      path: RouterUrl.Billing,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.billing
      },
      hasPermission: true
    },
    {
      protected: false,
      guestOnly: true,
      label: messages.auth.login,
      icon: <ExternalLinkIcon />,
      onClick: login,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.signIn
      },
      hasPermission: true
    },
    {
      protected: false,
      guestOnly: true,
      label: messages.auth.signup,
      icon: <PeopleIcon />,
      onClick: signup,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.signUp
      },
      hasPermission: true
    },
    {
      protected: true,
      label: messages.navigation.links.support,
      icon: <SupportWhite />,
      path: RouterUrl.Support,
      activeLinkClassCustom: "support-link-active",
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.support
      },
      hasPermission: true
    },
    {
      protected: true,
      label: messages.auth.logout,
      icon: <LogoutIcon />,
      path: RouterUrl.Logout,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.signOut
      },
      hasPermission: true
    }
  ]
    .filter((setting: HeaderSetting) => {
      if (isLoggedIn) {
        return setting.protected
      }
      return setting.guestOnly || !setting.protected
    })
    .filter((setting: HeaderSetting) => setting.hasPermission)

export const generateHelpers = ({
  isLoggedIn,
  isTrial
}: GenerateParams): HeaderHelper[] =>
  [
    {
      protected: true,
      trialOnly: true,
      icon: <StarIcon />,
      label: messages.navigation.helpers.upgrade,
      path: RouterUrl.Subscription,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.upgradePlan
      }
    }
  ]
    .filter((helper: HeaderHelper) =>
      isLoggedIn ? helper.protected : !helper.protected
    )
    .filter((helper: HeaderHelper) =>
      helper.trialOnly ? isTrial : !helper.trialOnly
    )

export const generateMobileOptions = ({
  isLoggedIn,
  isTrial,
  login,
  inviteUser,
  signup,
  userRole,
  expired
}: GenerateParams): HeaderOption[] =>
  [
    {
      protected: true,
      trialOnly: true,
      label: messages.navigation.helpers.upgrade,
      path: RouterUrl.Subscription,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.upgradePlan
      }
    },
    {
      protected: true,
      label: messages.manageTeam.team,
      icon: <TeamIcon />,
      path: RouterUrl.Team,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.team
      },
      hasPermission:
        UserRolePermissionLevels[userRole as UserRoles] >=
        UserRolePermissionLevels[UserRoles.Admin]
    },
    {
      protected: true,
      label: messages.navigation.actions.billing,
      path: RouterUrl.Billing,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.billing
      }
    },
    {
      protected: true,
      label: messages.navigation.links.support,
      path: RouterUrl.Support,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.support
      }
    },
    {
      protected: true,
      label: messages.auth.logout,
      path: RouterUrl.Logout,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.signOut
      }
    },
    {
      protected: false,
      guestOnly: true,
      label: messages.auth.login,
      onClick: login,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.signIn
      }
    },
    {
      protected: false,
      guestOnly: true,
      label: messages.auth.signup,
      onClick: signup,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.signUp
      }
    }
  ]
    .filter((setting: HeaderOption) =>
      isLoggedIn ? setting.protected : setting.guestOnly || !setting.protected
    )
    .filter((setting: HeaderOption) =>
      setting.trialOnly ? isTrial : !setting.trialOnly
    )
