import React, { useCallback, useState } from "react"
import { Button, colors, Flex, getItem, setItem } from "@ikiru/talentis-fpc"
import { Formik } from "formik"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useAssignmentSubmit } from "utils/hooks/use-assignment-submit"
import { useModal } from "utils/hooks/use-modal"
import { Location } from "views/assignments/assignment.types"
import { createAssignmentInitialValues } from "views/assignments/components/assignment-create-form/constants/assignment-details-form.definitions"
import { createAssignmentValidationSchema } from "views/assignments/components/assignment-create-form/constants/assignment-details-form.schema"
import ModalProjectContainer from "../ModelContainer"
import { OnCreateAssignmentSubmit } from "../../../views/assignments/actions"
import { AssignmentDetailsFields } from "views/assignments/pages/manage-assignment/assignment-details.types"
import { localStorageKeys } from "setup/storage/storage.definitions"
import { AssignmentStatus } from "views/assignments/constants/assignment-statuses"
import AssignmentCreateModal from "components/ProjectSelector/Components/AddNewModal/AssignmentCreateModal"

type AddAssignmentPopUpType = {
  companyId: string
  companyName: string
}

const AddAssignmentPopUp = (props: AddAssignmentPopUpType) => {
  const { companyId, companyName } = props
  const { close } = useModal()
  const [locationValue, setLocationValue] = useState<Location>({
    label: createAssignmentInitialValues.location,
    value: createAssignmentInitialValues.geoLocation
  })

  const onSubmitFn = useAssignmentSubmit()
  const onSubmit: OnCreateAssignmentSubmit = useCallback(
    (values, helpers) => {
      setItem(localStorageKeys.assignmentStatusInput, values.status)
      if (locationValue.label !== "") {
        values.location = locationValue.label.trim()
        values.geoLocation = locationValue?.value
      }

      values.companyId = companyId
      values.companyName = companyName

      return onSubmitFn(values, helpers).then(() =>
        close(ModalNames.NewAssignment)
      )
    },
    [
      onSubmitFn,
      close,
      companyId,
      companyName,
      locationValue.label,
      locationValue?.value
    ]
  )

  return (
    <ModalProjectContainer
      typeOfProject="assignment"
      modalName={ModalNames.NewAssignment}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          ...createAssignmentInitialValues,
          [AssignmentDetailsFields.Status]:
            (getItem(
              localStorageKeys.assignmentStatusInput,
              false
            ) as AssignmentStatus) || AssignmentStatus.Active
        }}
        validationSchema={createAssignmentValidationSchema}
      >
        {(props) => {
          const { handleSubmit } = props
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <AssignmentCreateModal
                {...props}
                setLocationValue={setLocationValue}
              />
              <Flex
                height={60}
                justifyContent="center"
                alignItems="center"
                backgroundColor={colors.grey.light}
              >
                <Button type="submit">{messages.generic.save}</Button>
              </Flex>
            </form>
          )
        }}
      </Formik>
    </ModalProjectContainer>
  )
}

export default AddAssignmentPopUp
