import { css } from "styled-components"
import { breakpoints } from "@ikiru/talentis-fpc"

export const PersonNoteModalWrapperCss = css`
  background: ${({ theme }) => theme.colors.grey.lightest};
  flex-direction: column;

  ${({ theme }) => `
    ${theme.mediaQueries.xl} {
      width: 800px;
    }
    @media screen and (max-width: ${breakpoints.xl}) {
      width: 600px;
    }
    @media screen and (max-width: ${breakpoints.md}) {
      width: 500px;
    }
    @media screen and (max-width: ${breakpoints.xs}) {
      width: 100svw;
    }
  `}
`
