import React, { useEffect } from "react"
import { useAuth } from "setup/auth/module/auth.context"
import { useNavigate } from "react-router-dom"
import { RouterUrl } from "./routes"

export const unauthenticatedRoute = (Component: () => JSX.Element) => {
  return () => {
    const { isLoggedIn } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
      isLoggedIn && navigate(RouterUrl.Homepage)
    }, [isLoggedIn, navigate])

    return isLoggedIn ? null : <Component />
  }
}
