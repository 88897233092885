import { Button, Flex, PositiveIcon } from "@ikiru/talentis-fpc"
import React from "react"
import { messages } from "setup/messages/messages"
import { BoldText, CustomBodyText, FinishedSearchContainer } from "./style"
import GPTInput from "../GPTInput"
import { HeaderContainer, BetaIcon, GPTHeader } from "../GPTHomeScreen/style"

type FinishedSearchProps = {
  resetSearch: () => void
  gptSearch: string
  setGPTSearch: React.Dispatch<React.SetStateAction<string>>
  onSubmit: () => Promise<void>
  isError: boolean
}

const FinishedSearch = ({
  resetSearch,
  gptSearch,
  setGPTSearch,
  isError,
  onSubmit
}: FinishedSearchProps) => {
  return (
    <FinishedSearchContainer>
      <HeaderContainer>
        <GPTHeader>{messages.search.chatGPT.buildYourSearch}</GPTHeader>
        <BetaIcon>{messages.search.chatGPT.beta}</BetaIcon>
      </HeaderContainer>
      <PositiveIcon width={36} height={36} />
      <CustomBodyText>
        {messages.search.chatGPT.searchIsReady} <br />
        {messages.search.chatGPT.reviewAndMakeChanges}
        <BoldText> "{messages.search.chatGPT.runAgain}" </BoldText>
        {messages.search.chatGPT.toSeeCandidates}
      </CustomBodyText>
      <GPTInput
        isError={isError}
        gptSearch={gptSearch}
        setGPTSearch={setGPTSearch}
        onSubmit={onSubmit}
        label={messages.search.chatGPT.whatAreYouLookingFor}
        placeHolder={messages.search.chatGPT.exampleSearch}
        explainedLabel={messages.search.chatGPT.searchExplaination}
      />
      <Flex mt="m">
        <Button mr="xs" mode="standard-white" onClick={onSubmit} size="small">
          {messages.search.chatGPT.notHappy}
        </Button>
        <Button mode="standard-green" onClick={resetSearch} size="small">
          {messages.search.chatGPT.startAgain}
        </Button>
      </Flex>
    </FinishedSearchContainer>
  )
}

export default FinishedSearch
