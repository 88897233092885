import { capitalizeFirstLetter } from "views/search/SearchModule/utils"
import { CampaignsTabs, CampaignStatuses } from "./type"

export const getTotalCount = (campaignStatuses: CampaignStatuses[] = []) => {
  return campaignStatuses?.reduce(
    (previousValue: number, currentValue: CampaignStatuses) =>
      previousValue + currentValue.count,
    0
  )
}

export const tabsFormer = (
  campaignStatuses: CampaignStatuses[] = [],
  status?: string
) => {
  const tabsTemp: CampaignsTabs[] = []
  if (campaignStatuses.length > 1) {
    tabsTemp.push({
      label: "All",
      count: getTotalCount(campaignStatuses),
      isSelected: status ? status === "all" || status === "" : true
    })

    campaignStatuses.forEach((campaignStatus: CampaignStatuses) => {
      tabsTemp.push({
        label: capitalizeFirstLetter(campaignStatus.data),
        count: campaignStatus.count,
        isSelected: status ? campaignStatus.data === status : false
      })
    })
  }

  if (campaignStatuses.length === 1) {
    tabsTemp.push({
      label: capitalizeFirstLetter(campaignStatuses[0].data),
      count: campaignStatuses[0].count,
      isSelected: true
    })
  }

  if (campaignStatuses.length === 0) {
    tabsTemp.push({
      label: "All",
      count: 0,
      isSelected: true
    })
  }

  return tabsTemp
}

export const tabsFormerSearch = (
  campaignStatuses: CampaignStatuses[] = [],
  status: string,
  allTabs: CampaignsTabs[] = []
) => {
  allTabs.forEach((tab: CampaignsTabs) => {
    const tempLabel = tab.label.toLocaleLowerCase()
    if (tempLabel === "all") {
      tab.count = getTotalCount(campaignStatuses)
      tab.isSelected = status === "all"
    } else {
      const temp = campaignStatuses.find(
        (status: CampaignStatuses) => status.data === tempLabel
      )

      if (temp) {
        tab.count = temp.count
        tab.isSelected = status === tempLabel
      } else {
        tab.count = 0
        tab.isSelected = status === tempLabel
      }
    }
  })
  return allTabs
}
