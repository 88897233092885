import React from "react"

type SidebarContextProps = {
  isExpanded: boolean
}

const defaultContextValue = {
  isExpanded: false
}

export const SidebarContext =
  React.createContext<SidebarContextProps>(defaultContextValue)
