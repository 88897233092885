import React, { useState } from "react"
import { Form, Formik, FormikHelpers } from "formik"
import { OnPersonDetailsSubmitType } from "views/persons/components/person-details/form/actions"
import { createPersonDetailValidationSchema } from "./constants/schema"
import { messages } from "setup/messages/messages"
import { Flex } from "@ikiru/talentis-fpc"
import { ModalFormContainer } from "./style"
import { getChangedValuesWithArray } from "views/assignments/utils"
import {
  CreatePersonValues,
  createPersonInitialValues,
  createTrimmedValues
} from "views/persons/components/person-details/form/constants/definitions"
import FormActionButtons from "../../FormActionButtons"
import { Step } from "components/FPCComponents/Stepper/Step"
import PersonDetailsForm from "./PersonDetailsForm"
import PersonBioForm from "./PersonBioForm"

type EditPersonDetailsProps = {
  initialValues: Partial<CreatePersonValues>
  onSubmit: OnPersonDetailsSubmitType
}

const EditPersonDetails = (props: EditPersonDetailsProps) => {
  const [activeTab, setActiveTab] = useState("PersonDetails")
  const { onSubmit, initialValues } = props

  const submitForm = (
    values: CreatePersonValues,
    action: FormikHelpers<CreatePersonValues>
  ) => {
    const trimmedValues = createTrimmedValues(values)

    const changedValues = initialValues
      ? getChangedValuesWithArray(trimmedValues, initialValues)
      : trimmedValues

    onSubmit(changedValues, action)
  }

  return (
    <ModalFormContainer defaultWidth={755}>
      <Formik
        initialValues={{ ...createPersonInitialValues, ...initialValues }}
        //@ts-ignore
        onSubmit={submitForm}
        validationSchema={createPersonDetailValidationSchema}
      >
        {(formik) => {
          const { values } = formik
          return (
            <Form>
              <FormActionButtons
                title={
                  messages.person.personTabSection.form.personDetailsFormTitle
                }
              />

              <Flex pt="xxs" width="100%" justifyContent="space-between">
                <Step
                  title={
                    messages.person.personTabSection.tabHeaders.genericDetails
                  }
                  active={"PersonDetails" === activeTab}
                  handleStep={() => setActiveTab("PersonDetails")}
                />
                <Step
                  title={messages.person.personTabSection.tabHeaders.biography}
                  noMargin
                  active={"PersonBio" === activeTab}
                  handleStep={() => setActiveTab("PersonBio")}
                />
              </Flex>
              {"PersonDetails" === activeTab ? (
                <PersonDetailsForm values={values} />
              ) : (
                <PersonBioForm values={values} formik={formik} />
              )}
            </Form>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}

export default EditPersonDetails
