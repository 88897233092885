import React, { useMemo } from "react"
import {
  Avatar,
  Div,
  Flex,
  Link,
  OffLimits,
  getMinWidthMediaQuery
} from "@ikiru/talentis-fpc"
import { ProjectTeam } from "components/ProjectTeamMembers/ProjectTeam"
import { messages } from "setup/messages/messages"
import LazyLoadContainer from "components/LazyContainer/LazyContainer"
import { YearsExperience } from "./YearsExperience"
import { WebSites } from "components/WebSites"
import { InternalPersonType } from "views/internal-search/InternalSearchModule/types"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { RouterUrl } from "setup/router/routes"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { StyledLocation } from "./styles"

type PersonInfoProps = {
  person: InternalPersonType
}

export const PersonInfo = ({ person }: PersonInfoProps) => {
  const { setSelectedInternalSearchPersonId } = useInternalSearch()

  const navigate = useNavigate()

  const personUrl = useMemo(
    () => `${RouterUrl.ProfileDetails}/${person.id}`,
    [person.id]
  )

  const mediaQuery = getMinWidthMediaQuery("md")
  const isLarge = useMediaQuery(mediaQuery)

  const onClickPerson = () => {
    isLarge ? setSelectedInternalSearchPersonId(person.id) : navigate(personUrl)
  }

  return (
    <>
      {person?.isOfflimits && (
        <OffLimits isSmall isLeftCircle={true} height="auto">
          {messages.offLimits.offLimits}
        </OffLimits>
      )}
      <Div width={65} height={69} mt="xxs" ml="xxs" onClick={onClickPerson}>
        <LazyLoadContainer
          children={
            <Div width={59} height={59}>
              <Avatar
                photo={{ url: person?.photo?.url }}
                name={person.name}
                disableHoverOpacity
              />
            </Div>
          }
          placeHolder={
            <Div width={59} height={59}>
              <Avatar name={person.name} disableHoverOpacity />
            </Div>
          }
        />
      </Div>
      <Flex flexDirection="column">
        {Boolean(person.name) && (
          <Div mt="xs">
            <Link fontWeight={600} onClick={onClickPerson}>
              {person.name}
            </Link>
          </Div>
        )}
        <StyledLocation>{person.location}</StyledLocation>
        <Flex>
          <Div mr="10px">
            <WebSites
              iconSize={24}
              linkedInIconSize={{ height: 24, width: 28 }}
              marginLeft="-2px"
              webSites={[...person.webSites]?.slice(0, 3) || []}
            />
          </Div>

          <ProjectTeam
            title={messages.person.personTeam.title}
            projectTeamMembers={person.teamMembers || []}
            isList
            overridePopupConfig={{
              PopupProps: {
                closeOnDocumentClick: true,
                position: "bottom left",
                arrow: false
              }
            }}
          />
        </Flex>
        <YearsExperience allExperienceDates={person.allExperienceDates} />
      </Flex>
    </>
  )
}
