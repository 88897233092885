import React from "react"
import { messages } from "setup/messages/messages"
import { CampaignDetailsForm } from "views/campaigns/components/campaign-create-form/CampaignDetailsForm"
import { Div, Button } from "@ikiru/talentis-fpc"
import { useCampaignSubmit } from "../../hooks/useCampaignSubmit"

export const CreateCampaign = () => {
  const onSubmit = useCampaignSubmit()

  return (
    <Div width={[1, 1, 1, 1 / 2, 1 / 3]}>
      <CampaignDetailsForm
        onSubmit={onSubmit}
        formActionsComponent={({ isSubmitting }) => (
          <Button type="submit" disabled={isSubmitting}>
            {messages.form.generic.save}
          </Button>
        )}
      />
    </Div>
  )
}
