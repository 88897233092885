import { messages } from "setup/messages/messages"

export const generateTotalUsersNumberOption = (
  index: number,
  membersCount: number
) =>
  `${index} ${
    Boolean(index === membersCount)
      ? ` (${messages.subscription.planDetails.form.currentNumberOfUsers})`
      : ""
  }`
