import styled from "styled-components"
import { Flex } from "@ikiru/talentis-fpc"
import { ErrorText } from "@ikiru/talentis-fpc"

export const ErrorContainer = styled(Flex)`
  padding: ${({ theme }) => theme.space.xs}px ${({ theme }) => theme.space.s}px;
  align-items: center;
  background: ${({ theme }) => theme.colors.red.lightest};
  border-left: 3px solid ${({ theme }) => theme.colors.red.darkest};
`

export const ErrorMessageTitle = styled.span`
  ${ErrorText}
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
`

export const ErrorMessageDescription = styled.span`
  ${ErrorText}
`
