import React, { useCallback, useMemo } from "react"
import { Button, Flex, H4, colors, spacing } from "@ikiru/talentis-fpc"
import { Form, Formik } from "formik"
import { e2eTargets } from "../FindPerson/definitions"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import {
  NewContactFormFields,
  newContactFormValidationSchema,
  initialValues
} from "./definitions"
import { CreatePersonContainer } from "./style"

interface CreatePersonProps {
  name: string
  title: string
  onSubmit: (name: string) => void
  isMobile?: boolean
  isHorizontalView?: boolean
  tempName?: string
  setTempName?: React.Dispatch<React.SetStateAction<string>>
}

const CreatePerson = ({
  name,
  title,
  tempName,
  setTempName,
  onSubmit,
  isMobile = false,
  isHorizontalView = false
}: CreatePersonProps) => {
  const handleSubmit = useCallback(
    (values: any) => {
      onSubmit(values[NewContactFormFields.PersonName])
    },
    [onSubmit]
  )
  const formikContextValue = useMemo(
    () => ({
      initialValues: {
        ...initialValues,
        [NewContactFormFields.PersonName]: name
      },
      validationSchema: newContactFormValidationSchema,
      onSubmit: handleSubmit,
      isInitialValid: !!name
    }),
    [handleSubmit, name]
  )

  const onChangeValue = (e: any) => {
    const value: any = e.target.value
    setTempName && setTempName(value || "")
  }

  return (
    <CreatePersonContainer
      isMobile={isMobile}
      isHorizontalView={isHorizontalView}
    >
      <H4
        mb="xs"
        mt={isHorizontalView ? "0px" : isMobile ? spacing.m : "-50px"}
        color={colors.green.dark}
      >
        {title}
      </H4>
      <Formik {...formikContextValue}>
        {({ isValid }) => (
          <Form>
            <Flex
              width="100%"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <FormikInput
                data-e2e-target-name={e2eTargets.personNameInput}
                id={NewContactFormFields.PersonName}
                name={NewContactFormFields.PersonName}
                label={`${messages.form.generic.name}`}
                {...(tempName && {
                  value: tempName,
                  onChange: onChangeValue
                })}
              />

              <Button
                type="submit"
                size="small"
                mode="standard-green"
                mt="m"
                disabled={!isValid}
              >
                {messages.person.newContact.createNewContact}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </CreatePersonContainer>
  )
}

export default CreatePerson
