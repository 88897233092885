import React, { useCallback } from "react"
import { pick } from "lodash"
import {
  Button,
  PencilIcon,
  PlusIcon,
  Section,
  colors
} from "@ikiru/talentis-fpc"
import { Assignment } from "views/assignments/assignment.types"
import { patchAssignment } from "views/assignments/actions"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import {
  AssignmentPackageDataField,
  AssignmentPackageData
} from "../../constants/definitions"
import { EditPackageData } from "../AssignmentDataModal/EditPackageData"
import { AssignmentSalaryPackage } from "./AssignmentSalaryPackage"

type PackageDataProps = Assignment & {
  onAssignmentUpdated: (assignment: Assignment) => void
}

export const PackageData = ({
  onAssignmentUpdated,
  ...data
}: PackageDataProps) => {
  const assignmentPackageData: AssignmentPackageData = pick(
    data,
    Object.values(AssignmentPackageDataField)
  )
  const { open } = useModal()

  const onSubmit = patchAssignment<AssignmentPackageData>(
    data,
    (assignment) => {
      onAssignmentUpdated(assignment)
    }
  )

  const packageModal = useCallback(() => {
    open(
      ModalNames.EditPackageData,
      <EditPackageData
        initialValues={assignmentPackageData}
        onSubmit={onSubmit}
      />
    )
  }, [assignmentPackageData, onSubmit, open])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.assignment.assignmentPackage,
        // @ts-ignore
        actionsProps: { height: "25px" },
        actions: (
          <>
            {assignmentPackageData.salaryFrom ||
            assignmentPackageData.salaryTo ||
            assignmentPackageData.packageComment ? (
              <Button
                mode="standard-white"
                size="action-medium"
                onClick={packageModal}
              >
                <PencilIcon />
              </Button>
            ) : (
              <Button
                mode="standard-green"
                size="small"
                onClick={packageModal}
                prefix={
                  <PlusIcon
                    width={12}
                    height={12}
                    fill={colors.white.standard}
                  />
                }
              >
                Package
              </Button>
            )}
          </>
        )
      }}
    >
      <AssignmentSalaryPackage {...assignmentPackageData} />
    </Section>
  )
}
