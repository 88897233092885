import React from "react"
import { VisibilityContext } from "react-horizontal-scrolling-menu"
import ScrollButton from "./ScrollButton"

type Iprop = {
  isNext: boolean
  isRowTab?: boolean
}
const NavigationArrow = ({ isNext, isRowTab = false }: Iprop) => {
  const { scrollPrev, scrollNext, isLastItemVisible, isFirstItemVisible } =
    React.useContext(VisibilityContext)

  return (
    <ScrollButton
      onClick={() => (isNext ? scrollNext() : scrollPrev())}
      isNext={isNext}
      isRowTab={isRowTab}
      disabled={isNext ? isLastItemVisible : isFirstItemVisible}
    />
  )
}

export default NavigationArrow
