import React, { useMemo } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import { Flex } from "@ikiru/talentis-fpc"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { AutoSave } from "components/functional/formik/formik-autosave/AutoSave"
import {
  e2eTargets,
  InterviewProgressField,
  interviewProgressInitialTouched,
  interviewProgressInitialValues,
  contactStatuses
} from "views/campaigns/components/contacts/components/contact-record/components/InterviewProgress/definitions"
import { useTeam } from "views/team/team/team-module.context"
import { FormikCheckedDatapicker } from "components/functional/formik/formik-checked-datapicker/FormikCheckedDatepicker"
import { messages } from "setup/messages/messages"
import { getChangedValues } from "views/assignments/utils"
import { Contact } from "views/campaigns/components/contacts/type"
import { getOnChangeInterviewProgress } from "./interview-progress.actions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { useTaskManagement } from "views/homepage/task-management-module/task-management-module.context"
import { useLocation } from "react-router-dom"

type InterviewProgressProps = {
  contact: Contact
}

export const InterviewProgress = ({ contact }: InterviewProgressProps) => {
  const { teamMembers } = useTeam()
  const { updateContactStage, setPersonOverlayUpdated } = usePerson()
  const { campaignId } = useCampaign()
  const { isAssignment } = useTaskManagement()
  const location = useLocation()
  const membersOptions = useMemo(
    () =>
      teamMembers.map((member) => ({
        label: `${member.firstName} ${member.lastName}`,
        value: member.id
      })),
    [teamMembers]
  )

  const initialValues = useMemo(
    () => ({
      ...interviewProgressInitialValues,
      status: contact.status,
      assignTo: contact.assignTo,
      dueDate: contact.dueDate
    }),
    [contact.status, contact.assignTo, contact.dueDate]
  )

  const onSubmit = getOnChangeInterviewProgress(contact.id, (data) => {
    const isHomeScreen = location.pathname === "/"
    if (campaignId === contact.campaignId || (!isAssignment && isHomeScreen)) {
      setPersonOverlayUpdated(true)
    }
    updateContactStage(contact.id, data)
  })

  const submitForm = (values: any, actions: FormikHelpers<any>) => {
    const formattedInitialValues = {
      assignTo: initialValues.assignTo,
      dueDate: initialValues.dueDate,
      status: initialValues.status
    }

    const formattedValues = {
      assignTo: values.assignTo,
      dueDate: values.dueDate,
      status: values.status
    }

    const changeValues = getChangedValues(
      formattedValues,
      formattedInitialValues
    )
    const newValues: any = { ...changeValues }
    onSubmit(newValues, actions)
  }

  const layoutProps = {
    position: "relative",
    zIndex: 2,
    mb: "xxs"
  }

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={interviewProgressInitialTouched}
      onSubmit={submitForm}
      enableReinitialize={true}
    >
      {() => {
        return (
          <AutoSave debounceMs={0}>
            <Form>
              <Flex
                alignItems="start"
                justifyContent={["flex-start", "center"]}
              >
                <Flex width="100%" flexDirection="column" mr="xs">
                  <FormikSelect
                    layout={{ ...layoutProps }}
                    variant="small"
                    options={contactStatuses}
                    id={InterviewProgressField.Status}
                    name={InterviewProgressField.Status}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    status="default"
                    data-e2e-target-name={`contact-${
                      contact.normalizedPersonData?.name
                    }-${e2eTargets[InterviewProgressField.Status]}`}
                  />
                  <FormikSelect
                    layout={{ ...layoutProps, mt: ["none", "xxs"] }}
                    variant="small"
                    firstOption={{
                      label: messages.form.generic.assignTo,
                      value: ""
                    }}
                    options={membersOptions}
                    id={InterviewProgressField.AssignTo}
                    name={InterviewProgressField.AssignTo}
                    data-e2e-target-name={`contact-${
                      contact.normalizedPersonData?.name
                    }-${e2eTargets[InterviewProgressField.AssignTo]}`}
                  />
                </Flex>
                <Flex width="100%" flexDirection="column">
                  <FormikCheckedDatapicker
                    variant="small"
                    layout={{
                      ...layoutProps,
                      mt: ["none"],
                      position: "static",
                      zIndex: 3
                    }}
                    id={InterviewProgressField.DueDate}
                    name={InterviewProgressField.DueDate}
                    label={messages.person.contact.dueDate}
                  />
                </Flex>
              </Flex>
            </Form>
          </AutoSave>
        )
      }}
    </Formik>
  )
}
