import { Div } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const DragItemContainer = styled(Div)`
  width: 100%;
  cursor: move;
  padding-right: ${({ theme }) => theme.space.xxs}px;
  padding-left: ${({ theme }) => theme.space.xxs}px;
  & :hover {
    border: none;
  }
`
