export enum CompanyFields {
  Name = "name",
  About = "about",
  EmailAddresses = "taggedEmails",
  Location = "location",
  Phones = "phoneNumbers",
  WebSites = "webSites"
}

export const e2eTargets = {
  section: "person-details",
  addPhoneNumberButton: "phone-numbers-add",
  addWebSitesButton: "web-sites-add"
}

export const companyDetailsE2ETargets = {
  [CompanyFields.Name]: "name",
  [CompanyFields.Location]: "location",
  [CompanyFields.Phones]: "phone-numbers",
  [CompanyFields.WebSites]: "web-sites"
}
