export type EmailGt2 = {
  emailAddress: string
  type: string
}

export type TelephoneGt2 = {
  number: string
  type: string
}

export enum MemberVerified {
  NotSet = "notSet",
  Unverified = "unverified",
  Verified = "verified",
  VerifiedPrivate = "verifiedPrivate"
}
