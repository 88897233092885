import { useMemo } from "react"
import { messages } from "setup/messages/messages"
import get from "lodash/get"
import moment from "moment"
import { centsToDollars, formatPrice } from "utils/format-price"
import { defaultDateFormat } from "utils/moment"
import { PeriodUnit } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"
import { PaidSubscriptionDetails } from "../../definitions"

export const usePaidPlanDetails = (
  paidPlanDetails: PaidSubscriptionDetails
) => {
  const {
    planType,
    planQuantity: totalUsers,
    periodUnit,
    nextBillingAt,
    valueBeforeTax,
    currencyCode
  } = paidPlanDetails

  const totalPrice = useMemo(
    () => centsToDollars(valueBeforeTax),
    [valueBeforeTax]
  )

  const planName = useMemo(
    () => get(messages.subscription.plans, planType || "", planType || ""),
    [planType]
  )

  const planPeriod = useMemo(
    () => get(messages.billing.currentPlan.billed, periodUnit, periodUnit),
    [periodUnit]
  )

  const renewalDate = useMemo(
    () => moment.utc(nextBillingAt).format(defaultDateFormat),
    [nextBillingAt]
  )

  const renewalCost = useMemo(
    () => formatPrice(totalPrice, currencyCode),
    [totalPrice, currencyCode]
  )

  const renewalCostPerUser = useMemo(() => {
    const monthlyPrice =
      periodUnit === PeriodUnit.MONTH ? totalPrice : totalPrice / 12
    return formatPrice(monthlyPrice / totalUsers, currencyCode)
  }, [periodUnit, totalUsers, totalPrice, currencyCode])

  return {
    planName,
    planPeriod,
    totalUsers,
    renewalDate,
    renewalCost,
    renewalCostPerUser
  }
}
