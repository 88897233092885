import { colors } from "@ikiru/talentis-fpc"

const greyDarkestRgb = "26, 37, 55"

//get rid of this once FPC implemented
export const tempWrapperStyles: React.CSSProperties = {
  position: "fixed",
  background: `rgba(${greyDarkestRgb}, 0.8)`,
  width: "100svw",
  height: "100svh",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 50
}

export const tempInnerModalStyles: React.CSSProperties = {
  background: "white",
  padding: "40px",
  width: "540px",
  margin: "auto",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  borderRadius: "20px"
}
export const tempInnerGreyModalStyles: React.CSSProperties = {
  background: colors.grey.light,
  padding: "20px",
  width: "390px",
  margin: "auto",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch"
}
