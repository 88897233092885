import React from "react"
import { BodyText, Flex, colors } from "@ikiru/talentis-fpc"

type PersonNoDataScreenProps = {
  message: string
}
const PersonNoDataScreen = ({ message }: PersonNoDataScreenProps) => {
  return (
    <Flex justifyContent="center" alignItems="center" width="100%" my="xxl">
      <BodyText color={colors.grey.standard} my="m">
        {message}
      </BodyText>
    </Flex>
  )
}

export default PersonNoDataScreen
