import React from "react"
import { FlexItemStyled, PersonalInfoWrapper } from "./styles"
import { ExperienceSection } from "./ExperienceSection"
import { PersonContacts } from "./PersonContacts"
import { PersonActivities } from "./PersonActivities"
import { PersonFooter } from "./PersonFooter"
import { PersonInfo } from "./PersonInfo"
import { InternalPersonType } from "views/internal-search/InternalSearchModule/types"
import { usePersonRecordNotes } from "components/PersonRecords/Notes/hooks/usePersonRecordNotes"
import { LinkPersonProject } from "../LinkPersonProject"

type PersonRecordProps = {
  person: InternalPersonType
}

const PersonRecord = ({ person }: PersonRecordProps) => {
  const { note } = usePersonRecordNotes(person.id)
  return (
    <>
      <PersonalInfoWrapper>
        <FlexItemStyled isOfflimits={person?.isOfflimits}>
          <PersonInfo person={person} />
        </FlexItemStyled>
        <FlexItemStyled>
          {person.latestExperience && (
            <ExperienceSection experience={person.latestExperience} />
          )}
        </FlexItemStyled>

        <FlexItemStyled>
          <PersonContacts
            taggedEmails={person?.taggedEmails || []}
            phones={person?.phones || []}
          />
        </FlexItemStyled>

        <FlexItemStyled>
          <PersonActivities
            assignmentCount={person.assignmentIds?.length}
            campaignCount={person.campaignIds?.length}
          />
        </FlexItemStyled>

        <FlexItemStyled>
          <LinkPersonProject
            personId={person.id}
            assignmentIds={person.assignmentIds}
            campaignIds={person.campaignIds}
          />
        </FlexItemStyled>
      </PersonalInfoWrapper>

      <PersonFooter bio={person.bio} note={note} />
    </>
  )
}

export default PersonRecord
