import { Div, Flex } from "@ikiru/talentis-fpc"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import React from "react"
import { messages } from "setup/messages/messages"

import { LogicalOperator } from "views/search/SearchModule/types"
import { EXCLUDEDALL } from "./definitions"

type KeyWordLogicOptionsProps = {
  fieldName: string
  keywordLogic: string
  flexDirection?: "column" | "row"
  marginRightForEach?: "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl" | 0
}

const KeyWordLogicOptions = ({
  fieldName,
  keywordLogic,
  flexDirection = "row",
  marginRightForEach = 0
}: KeyWordLogicOptionsProps) => {
  return (
    <Flex flexDirection={flexDirection} flexWrap="wrap" width="100%" mt="xxs">
      <Div mr={marginRightForEach}>
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          value={LogicalOperator.OR}
          checked={keywordLogic === LogicalOperator.OR}
          name={fieldName}
          labelProps={{ ml: "xxs" }}
          label={messages.talentGraphSearch.keywordLogicSelector.atLeastOne}
        />
      </Div>
      <Div mr={marginRightForEach}>
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          value={LogicalOperator.AND}
          checked={keywordLogic === LogicalOperator.AND}
          name={fieldName}
          labelProps={{ ml: "xxs" }}
          label={messages.talentGraphSearch.keywordLogicSelector.all}
        />
      </Div>
      <Div mr={marginRightForEach}>
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          value={EXCLUDEDALL}
          checked={keywordLogic === EXCLUDEDALL}
          name={fieldName}
          labelProps={{ ml: "xxs" }}
          label={messages.talentGraphSearch.keywordLogicSelector.excludeAll}
        />
      </Div>
    </Flex>
  )
}

export default KeyWordLogicOptions
