import React from "react"
import { Form, Formik } from "formik"
import { Div } from "@ikiru/talentis-fpc"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikSelect } from "components/functional/formik/formik-select/FormikSelect"
import { Button } from "@ikiru/talentis-fpc"
import {
  InviteUserField,
  inviteUserInitialValues
} from "views/team/invite-user/constants/invite-user.definitions"
import { inviteUserValidationSchema } from "views/team/invite-user/constants/invite-user.schema"
import { onInviteUserSubmit } from "views/team/invite-user/invite-user.actions"
import { initialFormState } from "utils/forms/form-state"
import { messages } from "setup/messages/messages"
import { useTeam } from "views/team/team/team-module.context"
import { CreatePersonValues } from "views/team/team/components/TeamMembers/definitions"
import { Line } from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { LicensesWarningModal } from "views/team/invite-user/components/licenses-warning-modal"
import { useModal } from "utils/hooks/use-modal"

type InviteFormProps = {
  onInviteUser: (user: CreatePersonValues) => void
}

export const InviteForm = React.memo(({ onInviteUser }: InviteFormProps) => {
  const { open } = useModal()

  const { possibleRoles } = useTeam()

  const callback = (values: CreatePersonValues) => {
    onInviteUser(values)
  }

  const callbackError = () => {
    open(ModalNames.Licenses, <LicensesWarningModal />)
  }

  return (
    <Formik
      initialValues={inviteUserInitialValues}
      validationSchema={inviteUserValidationSchema}
      onSubmit={onInviteUserSubmit(callbackError, callback)}
      initialStatus={initialFormState}
    >
      {(formik) => {
        const { isSubmitting, values, errors } = formik

        return (
          <Form>
            <Div display="flex" alignItems="flex-start" pt="xxs">
              <Div
                style={{
                  width: "calc(100% - 250px)",
                  maxWidth: 475,
                  marginLeft: 8
                }}
              >
                <FormikInput
                  id={InviteUserField.Email}
                  name={InviteUserField.Email}
                  label={messages.form.generic.emailAddress}
                />
              </Div>
              <Div display="flex" alignItems="center">
                <FormikSelect
                  variant="default"
                  firstOption={{
                    label: messages.form.generic.role,
                    value: "",
                    disabled: true
                  }}
                  type="submit"
                  options={possibleRoles}
                  id={InviteUserField.Role}
                  name={InviteUserField.Role}
                  spaceProps={{
                    ml: 20
                  }}
                />
                <Button
                  type="submit"
                  mode="standard-green"
                  size="standard"
                  ml={40}
                  disabled={
                    !values ||
                    !values.userEmailAddress ||
                    !values.userRole ||
                    !!errors.userEmailAddress ||
                    isSubmitting
                  }
                >
                  {messages.manageTeam.sendInvite}
                </Button>
              </Div>
            </Div>
            <Line variant="thin" mt="s" />
          </Form>
        )
      }}
    </Formik>
  )
})
