import React from "react"
import { get, sortBy, findIndex } from "lodash"
import { BodyText, SmallText, Link, Badge, Flex } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { RouterLink } from "components/RouterLink/RouterLink"
import { Assignment } from "views/assignments/assignment.types"
import {
  candidateStages,
  InterviewProgressStage
} from "views/assignments/components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { e2eTargets } from "./consts/definitions"
import {
  AssignmentRecordBox,
  AssignmentDataBox,
  AssignmentRecordFooter,
  AssignmentStartDate,
  AssignmentTeamStyled,
  FlexStyled
} from "./styles"
import { formatDate } from "utils/format-date"
import { generateLabel } from "components/AzureLocation/helper"
import { ProjectTeam } from "components/ProjectTeamMembers/ProjectTeam"

export const AssignmentRecord = React.memo(
  ({
    id,
    name,
    startDate,
    companyName,
    companyId,
    jobTitle,
    geoLocation,
    candidateStageCount = {},
    teamMembers
  }: Assignment) => {
    const countPerStage =
      candidateStageCount &&
      Object.entries(candidateStageCount).map(([key, value]) => ({
        value,
        key: key as InterviewProgressStage
      }))

    const geoLocationString = generateLabel(geoLocation)
    const countPerStageSorted = sortBy(countPerStage, ({ key }) =>
      findIndex(
        candidateStages,
        ({ value }) => key.toLowerCase() === value.toLowerCase()
      )
    ).filter(({ value }) => Boolean(value))

    return (
      <AssignmentRecordBox
        href={`${RouterUrl.AssignmentDetails}/${id}`}
        data-e2e-target-name={e2eTargets.assignmentRecord}
      >
        <Flex
          py={["s", "", "none"]}
          px={["xs", "", "none"]}
          flexDirection={["column", "column", "row"]}
        >
          <AssignmentDataBox>
            <RouterLink
              my={0}
              data-e2e-target={`assignment-${name}`}
              data-e2e-target-name={e2eTargets.assignmentName}
              to={`${RouterUrl.AssignmentDetails}/${id}`}
              fontWeight={600}
            >
              {name}
            </RouterLink>
          </AssignmentDataBox>
          <AssignmentDataBox>
            {companyId !== "00000000-0000-0000-0000-000000000000" ? (
              <Link
                fontFamily="canada-type-gibson"
                fontWeight={500}
                fontSize="16px"
                underline={false}
                underlineHover={true}
                variant="green-dark"
                href={`${RouterUrl.CompanyDetails}/${companyId}`}
                target="_blank"
                size={null}
              >
                {companyName}
              </Link>
            ) : (
              <BodyText
                my={0}
                fontWeight={600}
                data-e2e-target-name={e2eTargets.assignmentCompany}
              >
                {companyName}
              </BodyText>
            )}
          </AssignmentDataBox>
          <AssignmentDataBox>
            <BodyText
              my={0}
              data-e2e-target-name={e2eTargets.assignmentJobTitle}
            >
              {jobTitle}
            </BodyText>
          </AssignmentDataBox>
          <AssignmentDataBox>
            <BodyText
              my={0}
              data-e2e-target-name={e2eTargets.assignmentLocation}
            >
              {geoLocationString}
            </BodyText>
          </AssignmentDataBox>
        </Flex>
        <AssignmentRecordFooter>
          <AssignmentTeamStyled>
            <ProjectTeam
              title={messages.assignment.assignmentTeam}
              projectTeamMembers={teamMembers}
              isList
            />
          </AssignmentTeamStyled>
          <FlexStyled>
            <AssignmentStartDate
              data-e2e-target-name={e2eTargets.assignmentStartDate}
            >
              {messages.assignment.details.started}: {formatDate(startDate)}
            </AssignmentStartDate>
            <Flex
              justifyContent={["flex-start", "", "flex-end"]}
              alignItems="center"
              flexWrap="wrap"
              mb={["xs", "", "none"]}
              width={["100%", "", "60%"]}
            >
              {countPerStageSorted.map(({ key, value }) => (
                <Flex my={2} mx="xxs" key={key} alignItems="center">
                  <Badge bg="grey.standard" color="white.standard" height={20}>
                    {value}
                  </Badge>
                  <SmallText ml="xxs" my={0}>
                    {get(
                      messages,
                      `assignment.interviewStage[${key.firstCharToLowerCase()}]`
                    )}
                  </SmallText>
                </Flex>
              ))}
            </Flex>
          </FlexStyled>
        </AssignmentRecordFooter>
      </AssignmentRecordBox>
    )
  }
)
