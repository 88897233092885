import React, { useEffect, useRef } from "react"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import { Div } from "@ikiru/talentis-fpc"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { contactLookupInputName } from "./definitions"

export const SearchInput = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { setLookupSearchString, lookupSearchString, isResetFilters } =
    useInternalSearch()

  useEffect(() => {
    if (isResetFilters) {
      setLookupSearchString({ quickSearchValue: "" })
    }
  }, [isResetFilters, setLookupSearchString])

  const handleInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setLookupSearchString({ quickSearchValue: e.target.value })
  }

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  return (
    <Div minWidth={["100%", "400px"]} mr="s">
      <FormikInput
        ref={inputRef}
        variant="middle"
        name={contactLookupInputName}
        id={contactLookupInputName}
        label={messages.form.generic.lookUpPersonName}
        defaultValue={lookupSearchString.quickSearchValue}
        onChange={(e: React.FocusEvent<HTMLInputElement>) =>
          handleInputChange(e)
        }
        onFocus={handleFocus}
      />
    </Div>
  )
}
