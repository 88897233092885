import countries from "utils/countries.json"

type NameCodeJSON = {
  Name: string
  Code: string
}

export const transformNameCodeJSON = (json: NameCodeJSON[]) =>
  json.map((item) => ({
    label: item.Name,
    value: item.Code
  }))

export const transformedCountries = transformNameCodeJSON(countries)

export const formatToOption = <T>(item: T, keys: [string, string]) => ({
  label: item[keys[0] as keyof T],
  value: item[keys[1] as keyof T]
})

export const camelCaseErrors = (
  errors: { [key: string]: string | string[] } = {}
) =>
  Object.keys(errors).reduce((result, item) => {
    const key = item
      .split(".")
      .map((str) => str.firstCharToLowerCase())
      .join(".")
    return {
      ...result,
      [key]: Array.isArray(errors[item])
        ? (errors[item] as string[]).join(" ")
        : errors[item]
    }
  }, {})
