import { Tag } from "@ikiru/talentis-fpc"
import { Flex } from "@ikiru/talentis-fpc"
import { H4, H6 } from "@ikiru/talentis-fpc"
import React from "react"
import { messages } from "setup/messages/messages"

interface IRadioLabelComponent {
  label: string
  oldPrice?: string
}

export const RadioLabelComponent = ({
  label,
  oldPrice
}: IRadioLabelComponent) => {
  return (
    <Flex alignItems="flex-end" justifyContent="space-between" flexWrap="wrap">
      <Flex flexWrap="nowrap" alignItems="flex-end" mr="xxs">
        <H4 m={0}>{label}* </H4>
        <H6 m={0}>{messages.subscription.planDetails.form.perUserPerMonth}</H6>
      </Flex>
      {oldPrice && (
        <Tag m={0} variant="green">
          {messages.subscription.planDetails.form.wasAmount.format(oldPrice)}
        </Tag>
      )}
    </Flex>
  )
}
