import { Campaign } from "views/campaigns/campaign.types"
import { CampaignNote } from "views/campaigns/components/campaign-notes/types"
import { Contact } from "views/campaigns/components/contacts/type"
import { ProjectRecordFilter } from "components/ProjectRecordsFilters/types"
import { Nullable } from "tsdef"
import { FilterArrayType } from "components/ProjectRecordsFilters/types"
import { AITemplatesType } from "components/ProjectActions/AITemplates/constants/definitions"

export type Pagination = {
  pageNumber?: number
  totalItemCount?: number
  pageSize?: number
  totalFilteredCount?: number
}

export type CampaignState = {
  isLoading: boolean
  campaignDetails: Campaign
  paginationContact: Pagination
  notes: CampaignNote[]
  contacts: Contact[]
  isContactsLoading: boolean
  contactPhotos: Record<string, Nullable<string>>
  selectedContactId: string
  appliedFilters: ProjectRecordFilter[]
  allAvailableFilters: FilterArrayType
  campaignAITemplates: AITemplatesType
}

export enum CampaignActionTypes {
  SetIsLoading = "setIsLoading",
  SetCampaignDetails = "setCampaignDetails",
  SetCampaignPaginationContact = "setCampaignPaginationContact",
  GetCampaignData = "getCampaignData",
  SetNotes = "setNotes",
  SetContacts = "setContacts",
  GetCampaignContacts = "getCampaignContacts",
  SetIsContactsLoading = "setIsContactsLoading",
  UpdatePhoto = "updatePhoto",
  SetSelectedContactId = "setSelectedContactId",
  ApplyFilters = "applyFilters",
  UpdateFilters = "updateFilters",
  SetAllAvailableFilters = "setAllAvailableFilters",
  SetCampaignAITemplates = "setCampaignAITemplates"
}

export type CampaignContextValues = CampaignState & {
  campaignId: string
  setCampaignDetails: SetCampaignDetails
  setCampaignPaginationContact: SetCampaignPaginationContact
  getCampaignData: GetCampaignData
  setNotes: SetNotes
  setContacts: SetContacts
  getCampaignContacts: GetCampaignContacts
  updateContactStage: UpdateContactStage
  setSelectedContactId: SetSelectedContactId
  updateFilters: UpdateFilters
  setIsContactsLoading: SetIsContactsLoading
  setCampaignAITemplates: SetCampaignAITemplates
}

export type SetCampaignDetails = (data: Campaign) => void
export type SetCampaignPaginationContact = (data: Pagination) => void
export type SetIsLoading = (data: boolean) => void
export type GetCampaignData = (campaignId: string) => void
export type SetNotes = (notes: CampaignNote[]) => void
export type SetContacts = (contacts: Contact[]) => void
export type GetCampaignContacts = (
  contactId: string,
  params?: URLSearchParams
) => void
export type SetIsContactsLoading = (data: boolean) => void
export type UpdatePhoto = (id: string, photoUrl: string) => void
export type UpdateContactStage = (
  contactId: string,
  newContact: Contact,
  value?: any
) => void
export type SetSelectedContactId = (contactId: string) => void
export type ApplyFilters = (contactFilters: any) => void
export type UpdateFilters = (
  recordProperty: ProjectRecordFilter[],
  removing?: boolean
) => any
export type SetAllAvailableFilters = (data: FilterArrayType) => void
export type SetCampaignAITemplates = (data: AITemplatesType) => void
