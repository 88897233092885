import React from "react"
import { messages } from "setup/messages/messages"
import {
  //Button,
  Flex,
  H4,
  Link,
  SmallText,
  colors,
  RefreshWhiteIcon as RefreshIcon
} from "@ikiru/talentis-fpc"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"

export const BuildSearch = () => {
  const { setIsResetFilters } = useInternalSearch()
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={colors.grey.dark}
      p="xs"
    >
      <Flex>
        <H4 m="0" color={colors.white.standard} mr="l">
          {messages.internalSearch.buildYourSearch}
        </H4>
        <Link onClick={() => setIsResetFilters(true)} my="none" mr="15px">
          <SmallText fontWeight="600" color="white.standard" my="none">
            <RefreshIcon fontWeight={600} />
            &nbsp;
            {messages.internalSearch.startNewSearch}
          </SmallText>
        </Link>
      </Flex>

      {/* <Flex alignItems="center">
        <Button mode="standard-grey-light" size="small" mr="s">
          {messages.internalSearch.loadSavedSearch}
        </Button>
        <Button mode="standard-grey-light" size="small">
          {messages.internalSearch.saveSearch}
        </Button>
      </Flex> */}
    </Flex>
  )
}
