import * as Yup from "yup"
import { messages } from "setup/messages/messages"

const FILE_SIZE = 2e6
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"]

export const personAvatarValidationSchema = Yup.object().shape({
  file: Yup.mixed()
    .required("A file is required")
    .test(
      "fileSize",
      messages.person.avatar.fileSizeMust,
      (value) => value && value.size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      messages.person.avatar.fileFormatMust,
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    )
})
