import React from "react"
import { H6, colors } from "@ikiru/talentis-fpc"
import { PersonTabWrapper } from "./style"

type PersonTabProps = {
  title: string
  handleStep: () => void
  active?: boolean
}

export const PersonTab = ({
  title,
  active = false,
  handleStep
}: PersonTabProps) => {
  return (
    <PersonTabWrapper active={active} onClick={handleStep}>
      <H6
        m="0"
        ml="2px"
        color={active ? colors.grey.darkest : colors.grey.standard}
      >
        {title}
      </H6>
    </PersonTabWrapper>
  )
}
