import { Nullable } from "tsdef"
import { Candidate } from "views/candidates/candidates.types"
import { Assignment } from "views/assignments/assignment.types"
import { InterviewProgressStage } from "views/persons/components/person-assignments/components/InterviewProgress/constants/interview-progress-stages"
import { AssignmentNote } from "../components/assignment-notes/types"
import { Filter } from "../components/candidates-list/components/candidate-filters/filters/definitions"
import { PaginationProps } from "components/functional/pagination/Pagination"
import { InterviewProgressStatus } from "../components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { AxiosResponse } from "axios"
import { AITemplatesType } from "components/ProjectActions/AITemplates/constants/definitions"
import { PersonNote } from "views/persons/components/person-notes/types"
import { OffLimitsType } from "components/OffLimits/type"

export type GroupedCandidates = {
  [key in InterviewProgressStage | "all"]: Candidate[]
}

export type CandidateFilter = {
  candidateProperty: string
  label: string
  value: Filter
  filterKey: string
  disabled: boolean
  span?: string
  variant?: string
}

export type ActiveStagesType = {
  total: number
  label: InterviewProgressStage
}

export type FilterArrayType = {
  status: InterviewProgressStatus[]
  dueDates: Nullable<Date[]>
  assignedTo: Nullable<string[]>
  tags: Nullable<number[]>
}

export type CompanyCandidate = {
  candidates: Candidate[]
  page: SetCompanyPageParams
}

export type CompanyCandidatesType = {
  [key: string]: CompanyCandidate
}

export type AssignmentState = {
  isLoading: boolean
  isCandidatesLoading: boolean
  candidates: Candidate[]
  selectedCandidatedId: string
  assignmentDetails: Assignment
  groupedCandidates: Nullable<GroupedCandidates>
  appliedFilters: CandidateFilter[]
  filteredCandidates: Nullable<Candidate[]>
  notes: AssignmentNote[]
  candidatePhotos: Record<string, Nullable<string>>
  pageNumber: number
  pageSize: number
  totalItems: number
  totalPages: number
  filterTotalNumber: number
  activeStages: Record<InterviewProgressStage | string, number>
  selectedStage: string
  allAvailableFilters: FilterArrayType
  assignmentTemplates: AITemplatesType
  companies: any
  companiesCount: any
  companiesCandidates: Nullable<CompanyCandidatesType>
  companyPage: SetCompanyPageParams
  selectedTab: string
  selectedCompany: string
}

export enum AssignmentActionTypes {
  SetIsLoading = "setIsLoading",
  SetAssignmentDetails = "setAssignmentDetails",
  SetIsCandidatesLoading = "setIsCandidatesLoading",
  SetCandidates = "setCandidates",
  SetSelectedCandidatedId = "setSelectedCandidatedId",
  AddCandidate = "addCandidate",
  UpdateCandidate = "updateCandidate",
  ApplyFilters = "applyFilters",
  SetHasStoredFilters = "SetHasStoredFilters",
  SetNotes = "SetNotes",
  UpdatePhoto = "UpdatePhoto",
  SetPageSize = "setPageSize",
  SetPageNumber = "setPageNumber",
  SetRecordsCount = "setRecordsCount",
  SetActiveStages = "setActiveStages",
  SetSelectedStage = "setSelectedStage",
  SetAllAvailable = "setAllAvailable",
  SetAssignmentTemplates = "setAssignmentTemplates",
  SetCompanies = "setCompanies",
  SetCompanyPage = "setCompanyPage",
  SetCompaniesCandidates = "setCompaniesCandidates",
  SetSelectedTab = "setSelectedTab",
  UpdateCompanyCandidate = "updateCompanyCandidate",
  UpdateCompanyCandidateNote = "updateCompanyCandidateNote",
  UpdateCompaniesStage = "updateCompaniesStage",
  SetSelectedCompany = "setSelectedCompany"
}

export type AssignmentContextValues = AssignmentState & {
  paginationProps: Omit<PaginationProps, "onPageChange">
  assignmentId: string
  setAssignmentDetails: SetAssignmentDetails
  setSelectedCandidatedId: SetSelectedCandidatedId
  getAssignmentData: GetAssignmentData
  patchAssignmentData: PatchAssignmentData
  getAssignmentCandidates: GetAssignmentCandidates
  updateCandidateStage: UpdateCandidateStage
  addCandidate: AddCandidate
  updateCandidate: UpdateCandidate
  updateFilters: UpdateFilters
  loadPreviousCandidatesFilters: LoadPreviousCandidatesFilters
  setNotes: SetNotes
  onPageChange: SetPage
  setSelectedStage: SetSelectedStage
  setActiveStage: SetActiveStages
  updateStageCount: UpdateStageCount
  setRecordsCount: SetRecordsCount
  setAssignmentTemplates: SetAssignmentTemplates
  setCompanies: SetCompanies
  setCompanyPage: SetCompanyPage
  setCompaniesCandidates: SetCompaniesCandidates
  setSelectedTab: SetSelectedTab
  updateCompanyCandidate: UpdateCompanyCandidate
  updateCompanyCandidateNote: UpdateCompanyCandidateNote
  updateCompaniesStage: UpdateCompaniesStage
  setSelectedCompany: SetSelectedCompany
}

type SetCandidatesParams = {
  candidates: Candidate[]
  groupedCandidates: GroupedCandidates
  filteredCandidates?: Candidate[]
}

type SetCompaniesParams = {
  companies: any
  companiesCount: any
}

export type SetCompanyPageParams = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  maxPages: number
  pageCount: number
  pageNumber: number
  pageSize: number
  totalItemCount: number
}

export type StageItem = {
  data: string
  count: number
}

export type CompanyStages = {
  companyId: string
  stages: StageItem[]
}

export type AssignmentCompany = {
  id: string
  name: string
  logoUrl: string
  offLimits: OffLimitsType
  candidates: Candidate[]
  stages: StageItem[]
}

export type SetAssignmentDetails = (data: Assignment) => void
export type SetIsLoading = (data: boolean) => void
export type SetIsCandidatesLoading = (data: boolean) => void
export type SetCandidates = (data: SetCandidatesParams) => void
export type SetSelectedCandidatedId = (candidateId: string) => void
export type GetAssignmentData = (assignmentId: string) => void
export type PatchAssignmentData = (
  assignmentId: string,
  patch: Partial<Assignment>
) => Promise<(AxiosResponse<any> | undefined)[]>
export type GetAssignmentCandidates = (
  assignemntId: string,
  selectedStage?: string,
  params?: URLSearchParams,
  preSetStage?: boolean
) => any
export type UpdateCandidateStage = (
  candidateId: string,
  newCandidate: Candidate
) => void
export type AddCandidate = (candidate: Candidate) => void
export type UpdateCandidate = (candidate: Candidate) => void
export type ApplyFilters = (candidateFilters: CandidateFilter[]) => void
export type UpdateFilters = (
  candidateFilters: CandidateFilter[],
  removing?: boolean
) => any
export type LoadPreviousCandidatesFilters = () => void
export type SetNotes = (notes: AssignmentNote[]) => void
export type UpdatePhoto = (id: string, photoUrl: string) => void
export type SetPage = (Pagenumber: number) => void
export type SetRecordsCount = (
  totalItems: number,
  totalPages: number,
  filterTotalNumber: number
) => void
export type SetActiveStages = (
  stages: Record<InterviewProgressStage | string, number>
) => void
export type SetSelectedStage = (stage: string) => void
export type SetAllAvailable = (data: FilterArrayType) => void

export type UpdateStageCount = (
  params: URLSearchParams,
  selectedStage: string
) => Promise<{
  allTabCount: any
  stages: any
}>
export type SetAssignmentTemplates = (data: AITemplatesType) => void
export type SetCompanies = (data: SetCompaniesParams) => void
export type SetCompanyPage = (page: SetCompanyPageParams) => void
export type SetCompaniesCandidates = (
  companyId: string,
  data: Candidate[],
  page: SetCompanyPageParams
) => void
export type SetSelectedTab = (tab: string) => void
export type UpdateCompanyCandidate = (candidate: Candidate) => void
export type UpdateCompanyCandidateNote = (
  candidateId: string,
  note: PersonNote
) => void
export type UpdateCompaniesStage = (
  oldStage: string,
  candidateNew: Candidate
) => void
export type SetSelectedCompany = (company: string) => void
