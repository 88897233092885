import React from "react"
import { OffLimitsData } from "./OffLimitsData"
import { useCompany } from "views/companies/company-module/company-module.context"
import { patchOffLimits } from "./actions"
import { parseMomentDate } from "utils/moment"
import { CompanyDetailsData } from "./CompanyDetailsData"

export const CompanyData = () => {
  const { setOffLimits, offLimits, companyId } = useCompany()

  const onSubmit = async (newOffLimits: any) => {
    const response = await patchOffLimits(companyId, newOffLimits)

    const endDate = parseMomentDate(response?.offlimits.endDate)
    setOffLimits({ ...response?.offlimits, endDate })
  }

  return (
    <>
      <CompanyDetailsData />
      <OffLimitsData offLimits={offLimits} onSubmit={onSubmit} />
    </>
  )
}
