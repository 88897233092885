import { ReactNode, useCallback, useContext } from "react"
import { AlertContext } from "./Context"
import { AlertOptions } from "./Provider"

type PartialOptions = Omit<AlertOptions, "type">

export const useAlert = () => {
  const { show, close, closeAll } = useContext(AlertContext)

  const success = useCallback(
    (message: ReactNode, options: PartialOptions = {}) =>
      show(message, { ...options, type: "success" }),
    [show]
  )
  const error = useCallback(
    (message: ReactNode, options: PartialOptions = {}) =>
      show(message, { ...options, type: "error" }),
    [show]
  )
  const warning = useCallback(
    (message: ReactNode, options: PartialOptions = {}) =>
      show(message, { ...options, type: "warning" }),
    [show]
  )
  const info = useCallback(
    (message: ReactNode, options: PartialOptions = {}) =>
      show(message, { ...options, type: "info" }),
    [show]
  )

  return { success, error, warning, info, close, closeAll }
}
