import styled from "styled-components"
import { Form } from "formik"
import { Button } from "@ikiru/talentis-fpc"

export const GoogleSearchContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors?.grey?.lightest};
  padding: ${({ theme }) => `${theme.space?.xs}px ${theme.space?.m}px 0`};
  border-bottom: 3px solid;
  border-color: ${({ theme }) => theme.colors?.grey?.light};
`

export const InputWrapper = styled.div`
  max-width: 420px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space?.xs}px;
`

export const TypeSelectorWrapper = styled.div`
  margin-left: ${({ theme }) => theme.space?.xs}px;
  margin-bottom: ${({ theme }) => theme.space?.xs}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`
export const GoogleSearchButton = styled(Button)`
  align-self: center;
  padding: 0 ${({ theme }) => theme.space?.s}px;
  margin-left: ${({ theme }) => theme.space?.xs}px;
  margin-bottom: ${({ theme }) => theme.space?.xs}px;
`
