import React, { useMemo } from "react"
import { TabContainer } from "./style"
import { colors, SmallText, Badge } from "@ikiru/talentis-fpc"
import { StatusTabDetails } from "."
import { messages } from "setup/messages/messages"
import { get } from "lodash"
export type tabCardProps = (data: string) => void

type StatusCardProps = {
  itemId: string
  status: StatusTabDetails
  key: string
  onSelect: tabCardProps
  selectedStatus: string
}
const StatusCard = ({ status, onSelect, selectedStatus }: StatusCardProps) => {
  const isSelected =
    selectedStatus?.toLowerCase() === status.data?.toLowerCase()

  const label = useMemo(
    () => get(messages, `assignment.statusLabels.${status.data}`),
    [status.data]
  )

  return (
    <TabContainer onClick={() => onSelect(status.data)} isSelected={isSelected}>
      <Badge bg={colors.grey.standard} height={15}>
        <SmallText
          fontSize={13}
          fontWeight="bold"
          color={colors.white.standard}
          m={0}
        >
          {status.count}
        </SmallText>
      </Badge>
      <SmallText
        fontSize={14}
        color={colors.grey.dark}
        fontWeight="500"
        fontFamily="canada-type-gibson"
      >
        {label}
      </SmallText>
    </TabContainer>
  )
}

export default StatusCard
