import styled from "styled-components"

export const LogoWrapper = styled.div<{ disabled?: boolean }>`
  padding-left: ${({ theme }) => theme.space.xxs}px;
  margin-bottom: ${({ theme }) => theme.space.m}px;

  ${({ disabled }) => disabled && `opacity: 0.3;`}
  @media only screen and (min-height: 394px) and (max-height: 695px) {
    padding-left: 0px;
    text-align: center;
  }
`
