import React, { useState } from "react"
import { BodyText, Flex, H5, Toggle } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import CVInput from "../CVInputs/CVInput"
import { ExistingData } from "../components/ExistingData"
import { ParsedData } from "../components/ParsedData"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { cloneDeep, get } from "lodash"
import {
  DateWrapper,
  ExistingDateWrapper,
  ParsedWrapper,
  PersonDetailsContainer,
  ToggleWrapper
} from "./style"
import { CVDatePicker } from "../CVInputs/CVDatePicker"
import { Nullable } from "tsdef"
import moment from "moment"

export const ParsePersonDetails = () => {
  const [isUpdateAll, setIsUpdateAll] = useState(false)
  const { cvParsing, setCVParsing } = usePerson()

  const checkedParseField = (key: string) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.personDetails[key].isChecked =
      !tempCVParsing.personDetails[key].isChecked
    setCVParsing({ ...tempCVParsing })
  }

  const onChangeParsed = (text: string | Nullable<Date>, key: string) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.personDetails[key].parsed = text
    tempCVParsing.personDetails[key].isChecked = true

    if (tempCVParsing.personDetails[key].hasOwnProperty("tempValue")) {
      delete tempCVParsing.personDetails[key].tempValue
    }

    setCVParsing({ ...tempCVParsing })
  }

  const updateAll = () => {
    setIsUpdateAll((prevState) => !prevState)
    const tempCVParsing = cloneDeep(cvParsing)
    Object.values(tempCVParsing.personDetails).forEach(
      (element) => (element.isChecked = !isUpdateAll)
    )
    setCVParsing({ ...tempCVParsing })
  }

  const saveTempValue = (text: string | Nullable<Date>, key: string) => {
    const tempCVParsing = cloneDeep(cvParsing)
    tempCVParsing.personDetails[key].tempValue = text
    setCVParsing({ ...tempCVParsing })
  }

  return (
    <>
      <BodyText>{messages.person.cvparsing.contentParse}</BodyText>
      <Flex justifyContent="flex-end" mr="m">
        <ExistingData />
        <ParsedData isAllChecked={isUpdateAll} checkedAll={updateAll} />
      </Flex>
      <PersonDetailsContainer>
        {cvParsing?.personDetails &&
          Object.entries(cvParsing.personDetails).map(([key, value]) => (
            <DateWrapper key={key}>
              <H5 width="15.4%" m="none" p="xxs">
                {get(messages, `person.cvparsing.personDetails.${key}`)}
              </H5>
              <ExistingDateWrapper>
                {value.existing
                  ? key === "dateOfBirth"
                    ? moment.utc(value.existing).format("DD/MM/YYYY")
                    : value.existing
                  : "-"}
              </ExistingDateWrapper>
              <ParsedWrapper>
                {key === "dateOfBirth" ? (
                  <CVDatePicker
                    value={value.parsed}
                    setValue={(text) => onChangeParsed(text, key)}
                    name="DateOfB"
                    saveTempValue={(text) => saveTempValue(text, key)}
                  />
                ) : (
                  <CVInput
                    text={value.parsed || "-"}
                    onChangeInput={(text) => onChangeParsed(text, key)}
                    saveTempValue={(text) => saveTempValue(text, key)}
                  />
                )}
                <ToggleWrapper>
                  <Toggle
                    wrapperProps={{ ml: "27px" }}
                    hideUnderline
                    small
                    type="checkbox"
                    checked={value.isChecked}
                    onChange={() => checkedParseField(key)}
                  />
                </ToggleWrapper>
              </ParsedWrapper>
            </DateWrapper>
          ))}
      </PersonDetailsContainer>
    </>
  )
}
