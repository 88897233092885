import React, { useCallback } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import {
  Flex,
  CrossIcon,
  Button,
  Tag,
  H5,
  ModalSectionHeader
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import {
  NoteField,
  initialDefaultNoteState,
  NoteForm
} from "components/Notes/components/forms/definitions"
import { addNoteValidationSchema } from "views/persons/components/person-notes/form/constants/schema"
import { PersonNoteModalWrapper } from "./style"
import { NoteAddValues } from "../types"
import { FormAction } from "utils/forms/form.types"
import { NoteAttachments, PersonNoteDocument } from "./NoteAttachments"

export type OnNoteSubmitType = FormAction<NoteAddValues>
interface NoteModalProps {
  onSubmit: OnNoteSubmitType
  name: string
  initialValues?: NoteForm
  title: string
  typeTitle: string
  documents?: PersonNoteDocument[]
  noteId?: string
}

export const NoteModal = ({
  onSubmit,
  name,
  initialValues,
  title,
  typeTitle,
  documents,
  noteId
}: NoteModalProps) => {
  const { close } = useModal()

  const onFormSubmit = useCallback(
    (data: NoteForm, action: FormikHelpers<NoteAddValues>) => {
      close(ModalNames.PersonRecordNoteModal)
      onSubmit(data, action)
    },
    [close, onSubmit]
  )

  const formikContextValue = {
    initialValues: { ...initialDefaultNoteState, ...initialValues },
    validationSchema: addNoteValidationSchema,
    onSubmit: onFormSubmit,
    isInitialValid: false
  }

  return (
    <PersonNoteModalWrapper>
      <ModalSectionHeader
        title={title}
        size="xSmall"
        actions={
          <>
            <Button
              onClick={() => close(ModalNames.PersonRecordNoteModal)}
              mode="standard-white"
              size="action-medium"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          </>
        }
      />

      <Formik {...formikContextValue}>
        {({ isValid }) => (
          <Form>
            <Flex p="m" flexDirection="column">
              <FormikInput
                autoFocus
                id={NoteField.NoteTitle}
                name={NoteField.NoteTitle}
                label={`${messages.person.note.form.noteTitle}*`}
              />
              <FormikTextarea
                rows={10}
                id={NoteField.NoteDescription}
                name={NoteField.NoteDescription}
                label={messages.person.note.form.noteText}
                resize="none"
                isMicrophone
              />
              {typeTitle && (
                <H5 pl="5px" m="none" mt="s" textAlign="left" color="grey.dark">
                  {typeTitle}
                </H5>
              )}
              {name && (
                <Tag pt="5px" pl="5px" m="none" disabled>
                  {name}
                </Tag>
              )}
              {!!documents?.length && noteId && (
                <NoteAttachments documents={documents} />
              )}
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              backgroundColor="grey.light"
              p="xs"
              mt="xl"
            >
              <Button type="submit" disabled={!isValid}>
                {messages.person.note.saveNote}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </PersonNoteModalWrapper>
  )
}
