import React from "react"
import { FacebookIcon as Facebook } from "@ikiru/talentis-fpc"
import { YouTubeTempIcon as YouTube } from "@ikiru/talentis-fpc"
import { LinkedInIcon as LinkedIn } from "@ikiru/talentis-fpc"
import { XingIcon as Xing } from "@ikiru/talentis-fpc"
import { CrunchbaseIcon as Crunchbase } from "@ikiru/talentis-fpc"
import { ReutersIcon as Reuters } from "@ikiru/talentis-fpc"
import { BloombergIcon as Bloomberg } from "@ikiru/talentis-fpc"
import { ZoomInfoIcon as ZoomInfo } from "@ikiru/talentis-fpc"
import { TwitterIcon as Twitter } from "@ikiru/talentis-fpc"
import { OwlerIcon as Owler } from "@ikiru/talentis-fpc"
import { CompaniesHouseIcon as CompaniesHouse } from "@ikiru/talentis-fpc"

type WebSiteType =
  | "linkedIn"
  | "xing"
  | "crunchbase"
  | "reuters"
  | "bloomberg"
  | "facebook"
  | "zoomInfo"
  | "twitter"
  | "owler"
  | "companiesHouse"
  | "youTube"
  | "other"

export type WebSite = {
  type?: WebSiteType
  url: string
}

type KnownWebSiteType =
  | "linkedIn"
  | "xing"
  | "crunchbase"
  | "reuters"
  | "bloomberg"
  | "facebook"
  | "zoomInfo"
  | "twitter"
  | "owler"
  | "companiesHouse"
  | "youTube"

export type KnownWebSite = WebSite & {
  type: KnownWebSiteType
}

export const knownWebSitesIcons: Record<
  KnownWebSiteType,
  React.ComponentType<React.PropsWithChildren<unknown>>
> = {
  linkedIn: LinkedIn,
  xing: Xing,
  crunchbase: Crunchbase,
  reuters: Reuters,
  bloomberg: Bloomberg,
  facebook: Facebook,
  zoomInfo: ZoomInfo,
  twitter: Twitter,
  owler: Owler,
  companiesHouse: CompaniesHouse,
  youTube: YouTube
}
