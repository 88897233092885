import React from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { currenciesOptions } from "views/assignments/components/assignment-data/constants/definitions"
import { EditPersonSalaryPackageField } from "../../../person.types"

export const EditPersonSalaryPackage = () => (
  <Div alignItems="center" justifyContent="stretch" position="relative">
    <Flex mb="xs" flexWrap="wrap" width="100%">
      <Div mr="xs" width="49%">
        <FormikSelect
          height={400}
          options={currenciesOptions()}
          id={`${EditPersonSalaryPackageField.Salary}.currency`}
          name={`${EditPersonSalaryPackageField.Salary}.currency`}
          label={messages.person.experience.currency}
        />
      </Div>
      <Div width="49%">
        <FormikInput
          id={`${EditPersonSalaryPackageField.Salary}.value`}
          name={`${EditPersonSalaryPackageField.Salary}.value`}
          label={messages.person.experience.salaryTitle}
        />
      </Div>
    </Flex>
    <Flex mb="xs" flexWrap="wrap" width="100%">
      <Div mr="xs" width="49%">
        <FormikSelect
          height={400}
          options={currenciesOptions()}
          id={`${EditPersonSalaryPackageField.Package}.currency`}
          name={`${EditPersonSalaryPackageField.Package}.currency`}
          label={messages.person.experience.currency}
        />
      </Div>
      <Div width="49%">
        <FormikInput
          id={`${EditPersonSalaryPackageField.Package}.value`}
          name={`${EditPersonSalaryPackageField.Package}.value`}
          label={messages.person.experience.package}
        />
      </Div>
    </Flex>
    <Div mt="xs">
      <FormikTextarea
        rows={3}
        id={EditPersonSalaryPackageField.Comment}
        name={EditPersonSalaryPackageField.Comment}
        label={messages.person.experience.comment}
        resize="none"
      />
    </Div>
  </Div>
)
