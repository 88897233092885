import { Div, Textarea, fontFamilies } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const OutReachTemplateTextArea = styled(Textarea)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: ${fontFamilies.sourceSans};
  color: ${({ theme }) => theme.colors.grey.darkest};
  margin-top: ${({ theme }) => theme.space.xs}px;
  border: none;
  cursor: context-menu;

  &::-webkit-scrollbar {
    width: 25px;
    height: 100%;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-left: 17px white solid;
    background: ${({ theme }) => theme.colors.grey.light};
    border-radius: 4px;
  }
`
export const TemplateModalWrapper = styled(Div)`
  background-color: ${({ theme }) => theme.colors?.grey?.lightest};
  width: 775px;
`
