import { useCallback, useEffect, useMemo } from "react"
import { useFormikContext } from "formik"
import { useSearch } from "views/search/SearchModule/context"
import get from "lodash/get"
import { FilterType } from "views/search/SearchModule/types"

const useMultiBoxes = (
  filterType: string,
  bundle: FilterType.KeywordBundle | FilterType.JobTitleBundle
) => {
  const { filters } = useSearch()
  const { values, setFieldValue } = useFormikContext()

  const boxes = get(values, filterType)

  const boxesCount = useMemo(
    () => Math.max(filters?.[bundle]?.length || 1, boxes?.length || 1),
    [filters, boxes, bundle]
  )

  useEffect(() => {
    if (boxes?.length < boxesCount) {
      const newObjects = [...Array(boxesCount - boxes.length)].map(() => ({}))

      setFieldValue(filterType, [...boxes, ...newObjects])
    }
  }, [boxes, boxesCount, setFieldValue, filterType])

  const addNewBox = useCallback(() => {
    setFieldValue(filterType, [...boxes, {}])
  }, [boxes, setFieldValue, filterType])

  const removeBox = useCallback(
    (index: any) => {
      const keywordBoxesCopy = [...boxes]
      keywordBoxesCopy.splice(index, 1)
      setFieldValue(filterType, keywordBoxesCopy)
    },
    [boxes, setFieldValue, filterType]
  )

  return { boxesCount, addNewBox, removeBox }
}

export { useMultiBoxes }
