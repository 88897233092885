import React, { useRef, useState } from "react"
import { messages } from "setup/messages/messages"
import {
  GPTTextarea,
  IconContainer,
  InputSection,
  NotificationBodyText,
  NotificationContainer,
  NotificationIcon,
  StyledArrowIcon
} from "./style"
import { theme } from "@ikiru/talentis-fpc"
import { useMediaQuery } from "utils/hooks/use-media-query"

type GPTInputProps = {
  gptSearch: string
  setGPTSearch: any
  onSubmit: () => Promise<void>
  isError: boolean
  label: string
  customErrorMessage?: string
  placeHolder: string
  explainedLabel?: string
}

const GPTInput = (props: GPTInputProps) => {
  const {
    gptSearch,
    setGPTSearch,
    onSubmit,
    isError,
    label,
    placeHolder,
    explainedLabel,
    customErrorMessage
  } = props
  const [isFocused, setIsFocused] = useState(false)
  const textareaRef = useRef(null)

  const handleInputChange = (e: any) => {
    const textarea: HTMLTextAreaElement = textareaRef.current!

    const value = e.target.value
    setGPTSearch(value)

    textarea.style.height = "auto"
    if (textarea.scrollHeight < 400) {
      textarea.style.height = `${textarea.scrollHeight}px`
      textarea.style.overflowY = "hidden"
    } else {
      textarea.style.height = "400px"
      textarea.style.overflowY = "scroll"
    }
  }

  const isSmaller = !useMediaQuery("(min-width: 1172px)")

  const isMobile = !useMediaQuery("(min-width: 456px)")

  const onKeyDownHandler = (e: any) => {
    if (e.keyCode === 13 && gptSearch.trim()) {
      onSubmit()
    }
  }

  return (
    <InputSection>
      {isError && (
        <NotificationContainer>
          <NotificationIcon />
          <NotificationBodyText>
            {customErrorMessage
              ? customErrorMessage
              : messages.search.chatGPT.errorMessage}
          </NotificationBodyText>
        </NotificationContainer>
      )}
      <GPTTextarea
        label={
          label +
          (!isFocused && !Boolean(gptSearch.trim()) && explainedLabel
            ? " - " + explainedLabel
            : "")
        }
        onFocus={(e) => {
          e.preventDefault()
          setIsFocused(true)
        }}
        onBlur={() => setIsFocused(false)}
        variant="searchBox"
        style={{ height: isMobile ? (isFocused ? 34 : 63) : 34 }}
        labelSpace={{
          mt: isSmaller ? 0 : theme.space.xs,
          pr: theme.space.xl,
          pl: theme.space.xs
        }}
        textareaSpace={{ pr: 36, pl: theme.space.m }}
        rows={1}
        ref={textareaRef}
        value={gptSearch}
        onChange={handleInputChange}
        onKeyDown={onKeyDownHandler}
        placeholder={isFocused ? placeHolder : ""}
      />
      <IconContainer>
        <StyledArrowIcon
          width="18px"
          fill={theme.colors.white.standard}
          onClick={onSubmit}
        />
      </IconContainer>
    </InputSection>
  )
}

export default GPTInput
