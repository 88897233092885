import React, { useCallback, useEffect } from "react"
import { Input, InputProps, StyledSystemElement } from "@ikiru/talentis-fpc"
import { Error } from "@ikiru/talentis-fpc"
import { useField } from "formik"
import { useStatus } from "components/functional/formik/hooks/use-status"
import { Div } from "@ikiru/talentis-fpc"
import { useShouldDisplayError } from "components/functional/formik/hooks/use-display-error"

type FormikInputProps = InputProps & {
  layout?: StyledSystemElement<HTMLDivElement>
  label?: React.ReactChild
  name: Pick<InputProps, "name">
  hideErrorMessage?: boolean
  clearable?: boolean
  disableDefaultOnBlur?: boolean
  onClear?: VoidFunction
}

const e2eTarget = "form-input"

export const FormikInput = React.memo(
  React.forwardRef((props: FormikInputProps, ref) => {
    const {
      layout = {},
      label,
      name,
      onBlur: explicitOnBlur,
      hideErrorMessage,
      clearable,
      disableDefaultOnBlur,
      onClear,
      variant = "default",
      ...otherExplicitProps
    } = props
    const [formikProps, meta, helpers] = useField(name as string)
    const {
      onBlur: formikDefaultOnBlur,
      value,
      ...otherFormikProps
    } = formikProps

    const { setValue } = helpers

    const handleOnClear = useCallback(() => {
      setValue(props.defaultValue || "")

      if (onClear) {
        onClear()
      }
    }, [setValue, onClear, props.defaultValue])

    const status = useStatus(meta)
    const shouldDisplayError = useShouldDisplayError(meta) && !hideErrorMessage

    useEffect(() => {
      //For lastpass overlap bug - should remove once better solution is found
      const observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          const lastpass = document.querySelector("[data-lastpass-icon-root]")
          if (lastpass) {
            lastpass.remove()
          }
        })
      })

      observer.observe(document.body, { childList: true, subtree: true })

      return () => observer.disconnect()
    }, [])

    return (
      <Div {...layout}>
        <Input
          ref={ref}
          label={label}
          aria-label={label}
          data-e2e-target={e2eTarget}
          status={status}
          onBlur={(event) => {
            //TODO: handle it in a better way
            !disableDefaultOnBlur && formikDefaultOnBlur(event)
            explicitOnBlur?.(event)
          }}
          value={value || props.defaultValue || ""}
          clearable={clearable}
          onClear={handleOnClear}
          {...otherFormikProps}
          {...otherExplicitProps}
          {...{ variant }}
        />
        {shouldDisplayError && <Error>{meta.error}</Error>}
      </Div>
    )
  })
)
