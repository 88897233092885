import { messages } from "setup/messages/messages"
import { formatDate } from "utils/format-date"
import { Employment } from "views/persons/person.types"

export const transformJobs = (jobs?: Employment[], currentJob?: Employment) => {
  if (!jobs && !currentJob) return []

  const currentJobs = jobs || ([] as Employment[])
  const mergedJobs = currentJob ? [currentJob, ...currentJobs] : currentJobs

  return mergedJobs
    .sort(
      (job1, job2) =>
        new Date(job2.startDate || 0).getTime() -
        new Date(job1.startDate || 0).getTime()
    )
    .sort((job1, job2) => (job2.endDate ? 0 : 1) - (job1.endDate ? 0 : 1))
    .map((job) => ({
      title: `${job.position}${
        job.companyName ? ` at ${job.companyName}` : ""
      }`,
      startDate: !!job.startDate ? formatDate(job.startDate) : "",
      endDate: !!job.endDate
        ? formatDate(job.endDate)
        : messages.talentGraphSearch.present
    }))
}
