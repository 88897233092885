import styled from "styled-components"
import { Div, StyledSystemProps } from "@ikiru/talentis-fpc"

export const InputWrapper = styled(Div)<StyledSystemProps>`
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`
