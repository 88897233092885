import { Div } from "@ikiru/talentis-fpc"
import styled from "styled-components"
import { hexToRGB } from "utils/hexToRGB"
import { ScrollMenu } from "react-horizontal-scrolling-menu"

type navStyle = {
  disabled?: boolean
}

export const StageTab = styled(Div)`
  min-width: 100%;
  height: 38px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors?.white?.standard};

  .react-horizontal-scrolling-menu--wrapper {
    height: 38px !important;
  }
`
export const ScrollMenuContainer = styled(ScrollMenu)`
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`
export const NavigationArrowContainer = styled(Div)<navStyle>`
  display: ${({ disabled }) => (disabled ? "none" : "flex")};
  padding-top: 15px;
  height: 45px;
  width: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.5)};
`
