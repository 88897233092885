import styled from "styled-components"
import {
  Flex,
  H6,
  spacing,
  StyledSystemProps,
  Div,
  SmallText,
  BodyText,
  Grid
} from "@ikiru/talentis-fpc"
import { backgroundOffLimits } from "components/OffLimits/style"

type LatestNoteSectionProp = {
  noteIsExpended: boolean
}
export const CompanyPersonRecordBox = styled(Flex)`
  cursor: pointer;
  width: 100%;
  margin-bottom: ${spacing.xs}px;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  flex-direction: column;
  &:hover {
    border-color: ${({ theme }) => theme.colors.grey.dark};
  }

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      flex-direction: row;
    }
  `}
`

export const CompanyPersonData = styled(Flex)<
  StyledSystemProps & { justifyContent?: "flex-start" } & {
    alignItems?: "flex-start"
  }
>`
  flex: 1;
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  margin-bottom: ${({ theme }) => theme.space.xxs}px;
  padding: 0px ${({ theme }) => theme.space.xs}px;

  &:first-child {
    margin-top: 10px;
  }
  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      margin-bottom: ${theme.space.none}px;
      padding: ${theme.space.xs}px ${theme.space.xs}px;
      border-left:  1px solid ${theme.colors.grey.light};
      &:first-child {
        margin-top: 0px;
      }
    }
  `}

  &:last-child {
    border-right: 0px;
  }
`

export const PersonInfoWrapper = styled(Flex)<{ isOfflimits?: boolean }>`
  flex: 1;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey.light}`};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  margin-bottom: ${({ theme }) => theme.space.xxs}px;
  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      border-top-right-radius: 0px;
      border-bottom: none;
      margin-bottom: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  `}

  ${backgroundOffLimits}
`

export const CompanyRecordContainer = styled(Grid)`
  padding: 0px;
  grid-auto-rows: repeat(4, 1fr);
  grid-template-columns: 1fr;
  grid-gap: 0px;
  ${({ theme }) => `
  ${theme.mediaQueries.sm} {
    grid-template-columns: repeat(3, minmax(140px,280px)) 1fr;
  }

`}
`

export const FormerCompanyPersonData = styled(
  CompanyPersonData
)<StyledSystemProps>`
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const CurrentPersonData = styled(CompanyPersonData)<StyledSystemProps>`
  flex: 3;
`

export const FormerCompanyCurrentData = styled(
  CompanyPersonData
)<StyledSystemProps>`
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      flex-direction: row;
      align-items: flex-start;
    }
  `}
`

export const SwitchLabel = styled(H6)<StyledSystemProps>`
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
`

export const LatestNoteDiv = styled(Div)`
  margin: 0px;

  padding: 3px ${({ theme }) => theme.space.xxs}px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.grey.light}`};

  ${({ theme }) => `
  ${theme.mediaQueries.sm} {
    border-left: 1px solid ${theme.colors.grey.light};
  }
 
`}
`

export const LatestNoteSection = styled(Flex)<LatestNoteSectionProp>`
  flex-direction: column;
  ${({ theme, noteIsExpended }) => `
  ${theme.mediaQueries.sm} {
    flex-direction: ${noteIsExpended ? "column" : "row"}
  }
 
`}

  min-height: 35px;
  margin: 0px;
  background: ${({ theme }) => theme.colors.grey.lightest};
  padding: 7px ${({ theme }) => theme.space.xxs}px 0px 7px;
`

export const StyledBodyText = styled(BodyText)<StyledSystemProps>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey.dark};
  margin: 0px;
  white-space: nowrap;
`

export const NoteTitle = styled(BodyText)`
  font-weight: 600;
  margin: 0px;
  white-space: pre-wrap;
  margin-left: ${({ theme }) => theme.space.xxs}px;
  color: ${({ theme }) => theme.colors.grey.dark};
`

export const NoteTimeStamp = styled(SmallText)`
  font-size: 11px;
  line-height: 13px;
  margin: 0px;
  white-space: nowrap;
`

export const NoteHyphen = styled.span`
  margin-right: ${({ theme }) => theme.space.xxs}px;
  margin-left: ${({ theme }) => theme.space.xxs}px;
  color: ${({ theme }) => theme.colors.grey.dark};
  font-weight: 600;
  margin-top: -1px;
`
