import React, { useCallback, useEffect, useRef, useState } from "react"
import { Overlay } from "@ikiru/talentis-fpc"
import { PersonOverlayContainer, PersonContent } from "./style"
import { contactStatuses } from "views/campaigns/components/contacts/components/contact-record/components/InterviewProgress/definitions"
import { useChangeContact } from "./hook/useChangeContact"
import PersonOverlay from "./index"
import OverlayNavigation from "./OverlayNavigation"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { Contact } from "views/campaigns/components/contacts/type"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { currentPersonIndex, getUpdatedPhotoAndCallBack } from "./utils"
import { messages } from "setup/messages/messages"

const CampaignPersonOverlay = () => {
  const [selectedStatus, setSelectedStatus] = useState("")
  const [contactPosition, setContactPosition] = useState(1)
  const {
    campaignId,
    setSelectedContactId,
    selectedContactId,
    contacts,
    isContactsLoading,
    paginationContact: { totalFilteredCount = 0, pageNumber = 1 },
    getCampaignContacts,
    appliedFilters,
    setContacts
  } = useCampaign()

  const { directionNext, directionPrevious, isLastElement, isFirstElement } =
    useChangeContact()

  const {
    getProfileData,
    person,
    personOverlayUpdated,
    setPersonOverlayUpdated,
    contacts: updatedPerson,
    personOverlayPhotoUpdated,
    profileDetails
  } = usePerson()

  useEffect(() => {
    const contact = contacts.find(
      (contact: Contact) => contact.personId === selectedContactId
    )

    const tempCampaign = Object.values(updatedPerson).find(
      (person) => person.campaignId === campaignId
    )
    const findStatus = contactStatuses.find(
      (status) =>
        tempCampaign?.status.toLowerCase() === status.value.toLowerCase()
    )

    if (contact) {
      if (
        findStatus?.label ===
        messages.person.assignments.interviewProgress.statuses.noStatus
      ) {
        setSelectedStatus(
          messages.person.assignments.interviewProgress
            .noStatusWithOutParenthesis
        )
      } else {
        setSelectedStatus(findStatus?.label || "")
      }
    } else if (isLastElement) {
      setSelectedContactId(contacts[0].personId)
      getProfileData(contacts[0].personId)
    } else if (isFirstElement) {
      setSelectedContactId(contacts[24].personId)
      getProfileData(contacts[24].personId)
    }
  }, [
    contacts,
    getProfileData,
    isFirstElement,
    isLastElement,
    selectedContactId,
    setSelectedContactId,
    updatedPerson,
    campaignId
  ])

  let overLayRef = useRef<HTMLDivElement>()

  const closeOverlay = () => {
    setSelectedContactId("")
  }

  useEffect(() => {
    if (personOverlayUpdated) {
      const newContactData = Object.values(updatedPerson).find(
        (contact) => contact.campaignId === campaignId
      )

      if (newContactData) {
        getUpdatedPhotoAndCallBack(
          newContactData.personId,
          personOverlayPhotoUpdated,
          (photoUrl) => {
            const newPersonData = person
            newPersonData!.isOfflimits = profileDetails?.isOfflimits
            newContactData.normalizedPersonData = newPersonData
            if (photoUrl) {
              newContactData.photo = photoUrl
            }
            const updatedContacts = contacts.map((contact) => {
              if (contact.personId === newContactData.personId) {
                return newContactData
              }
              return contact
            })
            setContacts(updatedContacts)
            setPersonOverlayUpdated(false)
          }
        )
      }
    }
  }, [
    appliedFilters,
    campaignId,
    contacts,
    getCampaignContacts,
    person,
    personOverlayPhotoUpdated,
    personOverlayUpdated,
    profileDetails,
    setContacts,
    setPersonOverlayUpdated,
    updatedPerson
  ])

  const closeOverlayFromBackground = (e: any) => {
    if (!overLayRef?.current?.contains(e.target)) {
      closeOverlay()
    }
  }

  const currentContactIndex = useCallback(() => {
    const contactIds = contacts.map((candidate: Contact) => candidate.personId)
    return currentPersonIndex(contactIds, selectedContactId, pageNumber)
  }, [contacts, selectedContactId, pageNumber])

  useEffect(() => {
    const contactNumber = currentContactIndex()
    if (!isContactsLoading) {
      setContactPosition(contactNumber)
    }
  }, [currentContactIndex, isContactsLoading])

  //Needs filtered count when ready
  return (
    <PersonOverlayContainer onClick={closeOverlayFromBackground}>
      <PersonContent ref={overLayRef}>
        <Overlay visible>
          <OverlayNavigation
            directionNext={directionNext}
            directionPrevious={directionPrevious}
            closeOverlay={closeOverlay}
            candidateStage={selectedStatus}
            disableNav={isContactsLoading}
            totalItems={totalFilteredCount}
            currentCandidateNumber={contactPosition}
          />
          <PersonOverlay
            personId={selectedContactId}
            isLoadingCandidates={isContactsLoading}
            project="campaign"
          />
        </Overlay>
      </PersonContent>
    </PersonOverlayContainer>
  )
}

export default CampaignPersonOverlay
