export enum InterviewProgressStage {
  Identified = "identified",
  Source = "source",
  Screening = "screening",
  InternalInterview = "internalInterview",
  Shortlist = "shortList",
  FirstClientInterview = "firstClientInterview",
  SecondClientInterview = "secondClientInterview",
  ThirdClientInterview = "thirdClientInterview",
  Offer = "offer",
  Placed = "placed",
  Archive = "archive"
}
