import styled from "styled-components"
import { Flex } from "@ikiru/talentis-fpc"

export const AssignmentWrapper = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  background: ${({ theme }) => theme.colors.white.standard};
  padding: ${({ theme }) => theme.space.xs}px;
`
