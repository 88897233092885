export {}

// eslint-disable-next-line no-extend-native
String.prototype.firstCharToUpperCase =
  String.prototype.firstCharToUpperCase ||
  function (this: string) {
    return this.charAt(0).toUpperCase() + this.slice(1)
  }

// eslint-disable-next-line no-extend-native
String.prototype.firstCharToLowerCase =
  String.prototype.firstCharToLowerCase ||
  function (this: string) {
    return this.charAt(0).toLowerCase() + this.slice(1)
  }

// eslint-disable-next-line no-extend-native
String.prototype.format = function (
  this: string,
  ...formatValues: Array<number | string>
) {
  return this.replace(/{(\d+)}/g, (match, index) =>
    formatValues[index] !== undefined &&
    formatValues[index] !== null &&
    formatValues[index].toString
      ? formatValues[index].toString()
      : ""
  )
}
