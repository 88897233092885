import {
  AxiosRequestConfig,
  AxiosInstance,
  AxiosError,
  AxiosResponse
} from "axios"
import { endpoints } from "setup/api/endpoints/endpoints"
import { EndpointName } from "setup/api/api.types"

export type NormalizedReturnTuple<T = any> = [
  AxiosError["response"] | undefined,
  undefined | AxiosResponse<T>
]

type SimpleRequestParams = {
  endpoint: EndpointName
  config?: AxiosRequestConfig
  endpointParams?: any
}

export const simpleRequestMethod =
  (instance: AxiosInstance, method: "get" | "delete") =>
  async <T = any>({
    endpoint,
    endpointParams,
    config
  }: SimpleRequestParams): Promise<NormalizedReturnTuple<T>> => {
    const url = endpoints[endpoint](endpointParams)
    try {
      const resp = await instance[method](url, config)
      return [undefined, resp]
    } catch (e) {
      const error: any = e
      return [error.response, undefined]
    }
  }

type ComplexRequestParams<T> = {
  endpoint: EndpointName
  endpointParams?: any
  data: T
  config?: AxiosRequestConfig
}

export const complexRequestMethod =
  (instance: AxiosInstance, method: "post" | "put" | "patch") =>
  async <T = any, Y = {}>({
    endpoint,
    endpointParams,
    data,
    config
  }: ComplexRequestParams<Y>): Promise<NormalizedReturnTuple<T>> => {
    const url = endpoints[endpoint](endpointParams)

    try {
      const resp = await instance[method](url, data, config)

      return [undefined, resp]
    } catch (e) {
      const error: any = e
      return [config?.cancelToken ? error : error.response, undefined]
    }
  }
