import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { useFilterData } from "./hooks/useFilterData"
import { useInterviewProgressStatus } from "components/ProjectRecordsFilters/hooks/useInterviewProgressStatus"
import { useAssignedTo } from "components/ProjectRecordsFilters/hooks/useAssignedTo"
import { useDueDate } from "components/ProjectRecordsFilters/hooks/useDueDate"
import { useTags } from "components/ProjectRecordsFilters/hooks/useTags"

export const useContactFilter = () => {
  const { appliedFilters, campaignDetails, allAvailableFilters } = useCampaign()

  const { statusFilter, assignToFilter, tagIdsFilter, dueDateFilter } =
    useFilterData()

  const { interviewProgressStatusFilters } = useInterviewProgressStatus(
    statusFilter,
    allAvailableFilters,
    appliedFilters
  )
  const { assignedToFilters } = useAssignedTo(
    assignToFilter,
    allAvailableFilters,
    appliedFilters
  )
  const { dueDateFilters } = useDueDate(
    dueDateFilter,
    allAvailableFilters,
    appliedFilters
  )
  const { tagsFilters } = useTags(tagIdsFilter, campaignDetails.tags)

  return {
    interviewProgressStatusFilters,
    assignedToFilters,
    dueDateFilters,
    tagsFilters
  }
}
