import { useCallback, useEffect, useMemo, useState } from "react"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { candidateStatuses } from "../candidate-record/components/InterviewProgress/definitions"
import { CandidateFilter } from "views/assignments/assignment-module/assignment-module.types"
import uniq from "lodash/uniq"
import { InterviewProgressStatus } from "views/persons/components/person-assignments/components/InterviewProgress/constants/interview-progress-statuses"
import { useTeam } from "views/team/team/team-module.context"
import { messages } from "setup/messages/messages"
import { getLTEDate, getGTEDate } from "./filters/DueDateFilters/consts"
import {
  getIsDueDateLast7Days,
  getIsDueDateNext14Days,
  getIsDueDateNext30Days,
  getIsDueDateNext7Days,
  getIsDueDateOverdue,
  getIsDueDateToday,
  getIsDueDateTommorow,
  getIsDueDateYesterday
} from "./filters/DueDateFilters/utils"
import { DueDateType } from "./filters/DueDateFilters/definitions"
import { useTelemetry } from "utils/hooks/use-telemetry"

import { getAppliedFiltersParams } from "./helper"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { excludeArchive } from "views/assignments/utils"
import useEditCandidateFilter from "./hooks/useEditCandidateFilter"

type filterResponseType = {
  data: number | string | Date
  count: number
}
export const useCandidateFilter = () => {
  const {
    appliedFilters,
    updateFilters,
    selectedStage,
    allAvailableFilters,
    assignmentId,
    assignmentDetails
  } = useAssignment()

  const { teamMembers } = useTeam()
  const { trackAssignmentFilterEnabled } = useTelemetry()
  const { filterIsApplied } = useEditCandidateFilter()

  const [statusArray, setStatusArray] = useState<InterviewProgressStatus[]>([])
  const [assignToArray, setAssignToArray] = useState<string[]>([])
  const [tag, setTag] = useState<number[]>([])
  const [dueDate, setDueDate] = useState<Date[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchFilters = useCallback(async () => {
    let params = getAppliedFiltersParams(appliedFilters)
    const filterStage = selectedStage !== "all" ? selectedStage : ""
    params.append("assignmentId", assignmentId)
    if (filterStage) {
      params.append("stages", filterStage)
    }

    params = excludeArchive(selectedStage, params)
    const [, response] = await apiRequest.get({
      endpoint: CandidatesEndpoints.AvailableFilters,
      config: {
        params: params,
        headers: {
          ...skipErrorHeader
        }
      }
    })

    const filterData = response?.data || {}

    const { statuses, assignedsTo, dueDates, tags } = filterData
    const statusLabels = statuses?.map(
      (status: filterResponseType) => status.data
    )
    const assignTo = assignedsTo?.map(
      (assignedId: filterResponseType) => assignedId.data
    )
    const tagIds = tags?.map((tag: filterResponseType) => tag.data)
    const dueDateArray = dueDates?.map(
      (dueDates: filterResponseType) => dueDates.data
    )

    return { statusLabels, assignTo, tagIds, dueDateArray }
  }, [appliedFilters, assignmentId, selectedStage])

  useEffect(() => {
    let filtersLoading = true
    setIsLoading(true)
    fetchFilters().then(({ statusLabels, assignTo, tagIds, dueDateArray }) => {
      if (filtersLoading) {
        setStatusArray(statusLabels)
        setDueDate(dueDateArray)
        setAssignToArray(assignTo)
        setTag(tagIds)
      }
      setIsLoading(false)
    })

    return () => {
      filtersLoading = false
    }
  }, [fetchFilters, assignmentId, allAvailableFilters.tags])

  const checkFirstSelected = useCallback(
    (candidateProperty: string) => {
      return appliedFilters[0]?.candidateProperty !== candidateProperty
    },
    [appliedFilters]
  )

  /**
   * Return a list of status filters, including only those which at least one candidate has assigned
   */
  const interviewProgressStatusFilters = useMemo<CandidateFilter[]>(() => {
    const currentStatusArray = checkFirstSelected(
      "interviewProgressState.status"
    )
      ? statusArray
      : allAvailableFilters.status || []
    const statuses: InterviewProgressStatus[] = currentStatusArray?.map(
      (status) => status || InterviewProgressStatus.NoStatus
    )

    return candidateStatuses.map((status) => {
      const filter = {
        ...status,
        candidateProperty: "interviewProgressState.status",
        value: {
          eq: status.value
        },
        filterKey: `interviewStatus${status.value}`,
        disabled: !statuses?.includes(status.value)
      }
      return filter
    })
  }, [statusArray, checkFirstSelected, allAvailableFilters])

  /**
   * Return a list of "assigned to" filters, including only those which at least one candidate has assigned
   */
  const assignedToFilters = useMemo<CandidateFilter[]>(() => {
    const currentAssignedArray = checkFirstSelected("assignTo")
      ? assignToArray
      : allAvailableFilters.assignedTo || []
    const teamMembersIds = currentAssignedArray.map((assignedId) =>
      assignedId ? assignedId : "notAssigned"
    )

    const unassigned = {
      candidateProperty: "assignTo",
      value: {
        eq: null
      },
      label: "Unassigned",
      filterKey: "assignToNull",
      disabled: !teamMembersIds.includes("notAssigned")
    }

    const filters = teamMembers.map((teamMember) => {
      const filter = {
        candidateProperty: "assignTo",
        value: {
          eq: teamMember?.id
        },
        label: `${teamMember.firstName} ${teamMember.lastName}`,
        filterKey: `assignTo${teamMember.id}`,
        disabled: !teamMembersIds.includes(teamMember.id)
      }
      return filter
    })

    return uniq([unassigned, ...filters])
  }, [
    teamMembers,
    assignToArray,
    allAvailableFilters.assignedTo,
    checkFirstSelected
  ])

  /**
   * Return a list of "due date" filters, including only those which at least one candidate has assigned
   */

  const dueDateFilters = useMemo<CandidateFilter[]>(() => {
    const currentDueDatesArray = checkFirstSelected("dueDate")
      ? dueDate
      : allAvailableFilters.dueDates || []
    const teamMembersDueDates = currentDueDatesArray?.reduce((result, date) => {
      const isOverDue =
        date && getIsDueDateOverdue(date) ? "dueDateOverdue" : null
      const isLast7Days =
        date && getIsDueDateLast7Days(date) ? "dueDateLast7Days" : null
      const isYesterday =
        date && getIsDueDateYesterday(date) ? "dueDateYesterday" : null
      const isToday = date && getIsDueDateToday(date) ? "dueDateToday" : null
      const isTommorow =
        date && getIsDueDateTommorow(date) ? "dueDateTomorrow" : null
      const isNext7Days =
        date && getIsDueDateNext7Days(date) ? "dueDateNext7Days" : null
      const isNext14Days =
        date && getIsDueDateNext14Days(date) ? "dueDateNext14Days" : null
      const isNext30Days =
        date && getIsDueDateNext30Days(date) ? "dueDateNext30Days" : null
      const isNoDueDate = !date ? "dueDateNoDueDate" : null
      const isAnyDueDate = date ? "dueDateAnyDate" : null

      const currentDateRequirements = [
        isOverDue,
        isLast7Days,
        isYesterday,
        isToday,
        isTommorow,
        isNext7Days,
        isNext14Days,
        isNext30Days,
        isNoDueDate,
        isAnyDueDate
      ].filter((item) => item) as DueDateType[]

      return [...result, ...currentDateRequirements]
    }, [] as DueDateType[])

    const uniqDueDates = uniq(teamMembersDueDates)

    return [
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.overdue,
        value: {
          lte: getLTEDate(-1).toDate()
        },
        filterKey: "dueDateOverdue",
        disabled: !uniqDueDates.includes("dueDateOverdue")
      },
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.last7Days,
        value: {
          lte: getLTEDate(-1).toDate(),
          gte: getGTEDate(-7).toDate()
        },
        filterKey: "dueDateLast7Days",
        disabled: !uniqDueDates.includes("dueDateLast7Days")
      },
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.yesterday,
        value: {
          lte: getLTEDate(-1).toDate(),
          gte: getGTEDate(-1).toDate()
        },
        filterKey: "dueDateYesterday",
        disabled: !uniqDueDates.includes("dueDateYesterday")
      },
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.today,
        value: {
          lte: getLTEDate().toDate(),
          gte: getGTEDate().toDate()
        },
        filterKey: "dueDateToday",
        disabled: !uniqDueDates.includes("dueDateToday")
      },
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.tomorrow,
        value: {
          lte: getLTEDate(1).toDate(),
          gte: getGTEDate(1).toDate()
        },
        filterKey: "dueDateTomorrow",
        disabled: !uniqDueDates.includes("dueDateTomorrow")
      },
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.next7Days,
        value: {
          lte: getLTEDate(7).toDate(),
          gte: getGTEDate(1).toDate()
        },
        filterKey: "dueDateNext7Days",
        disabled: !uniqDueDates.includes("dueDateNext7Days")
      },
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.next14Days,
        value: {
          lte: getLTEDate(14).toDate(),
          gte: getGTEDate(1).toDate()
        },
        filterKey: "dueDateNext14Days",
        disabled: !uniqDueDates.includes("dueDateNext14Days")
      },
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.next30Days,
        value: {
          lte: getLTEDate(30).toDate(),
          gte: getGTEDate(1).toDate()
        },
        filterKey: "dueDateNext30Days",
        disabled: !uniqDueDates.includes("dueDateNext30Days")
      },
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.anyDate,
        value: {
          exists: true
        },
        filterKey: "dueDateAnyDate",
        disabled: !uniqDueDates.includes("dueDateAnyDate")
      },
      {
        candidateProperty: `dueDate`,
        label: messages.assignment.filters.dueDateOptions.noDueDate,
        value: {
          exists: false
        },
        filterKey: "dueDateNoDueDate",
        disabled: !uniqDueDates.includes("dueDateNoDueDate")
      }
    ]
  }, [dueDate, checkFirstSelected, allAvailableFilters.dueDates])

  /**
   * Return a list of tags filters
   */
  const tagsCandidatesFilters = useMemo<CandidateFilter[]>(() => {
    const candidateTags = tag.map((tag) => (tag || tag === 0 ? tag : "noTag"))
    const candidateTagsIds = candidateTags
    const noTags = {
      candidateProperty: "tags",
      value: {
        noTags: []
      },
      label: messages.person.assignments.noTags,
      filterKey: "noTags",
      disabled: !candidateTagsIds.includes("noTag")
    }

    const filters =
      assignmentDetails?.tags?.map((tag) => {
        const filter = {
          candidateProperty: "tags",
          value: {
            tags: tag.id
          },
          label: tag.name,
          filterKey: `tags${tag.name}`,
          disabled: !candidateTagsIds.includes(tag.id),
          span: tag.background,
          variant: tag.background
        }

        return filter
      }) || []
    return uniq([noTags, ...filters])
  }, [assignmentDetails, tag])

  /**
   * Add a candidate filter
   */
  const addFilter = useCallback(
    (candidateFilter: CandidateFilter) => {
      if (filterIsApplied(candidateFilter)) {
        return
      }

      updateFilters([...appliedFilters, candidateFilter], true)
      trackAssignmentFilterEnabled({ assignmentId })
    },
    [
      filterIsApplied,
      updateFilters,
      appliedFilters,
      trackAssignmentFilterEnabled,
      assignmentId
    ]
  )

  return {
    assignedToFilters,
    interviewProgressStatusFilters,
    dueDateFilters,
    tagsCandidatesFilters,
    filterIsApplied,
    addFilter,
    isLoading
  }
}
