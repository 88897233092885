import React from "react"
import { BodyText, Div, Flex, SectionBody } from "@ikiru/talentis-fpc"
import { Loader } from "components/Loader/Loader"
import { ProjectSearch } from "components/ProjectActions/ProjectSearch/ProjectSearch"
import { messages } from "setup/messages/messages"
import StatusGroupTabs from "views/assignments/components/assignments-group/StatusGroupTabs"
import { assignmentListE2ETargets } from "views/assignments/pages/list-assignment/list-assignment.definitions"
import { AssignmentsGroup } from "views/assignments/components/assignments-group"
import { Pagination } from "components/functional/pagination/Pagination"
import { useAssignments } from "views/assignments/pages/list-assignment/hooks"
import { CompanyModuleLocationParams } from "../../../company-module/CompanyModule"
import { useParams } from "react-router-dom"

export const CompanyAssignments = () => {
  const { companyId = "" } = useParams<CompanyModuleLocationParams>()
  const {
    findAssignmentNames,
    onChangeSearch,
    searchedAssignmentChanged,
    statusArray,
    onSelectStatus,
    selectedStatus,
    isLoading,
    totalItems,
    assignments,
    totalPages,
    paginationAssignmentList,
    handlePageChange,
    setShowMyAssignmentsOnly,
    showMyAssignmentsOnly
  } = useAssignments(companyId)
  return (
    <Flex flexDirection="column" width="100%">
      <ProjectSearch
        projectNames={findAssignmentNames}
        onChanged={onChangeSearch}
        onItemClicked={searchedAssignmentChanged}
        buttonTitle={messages.assignment.searchButton}
        searchInput={messages.assignment.searchLable}
        showAddAssignmentButton
        setShowMyAssignmentsOnly={setShowMyAssignmentsOnly}
        showMyAssignmentsOnly={showMyAssignmentsOnly}
      />
      {statusArray?.length > 0 && (
        <StatusGroupTabs
          onSelect={onSelectStatus}
          selectedStatus={selectedStatus}
          assignmentStatus={statusArray}
        />
      )}
      {isLoading ? (
        <Div mt="100px">
          <Loader />
        </Div>
      ) : (
        <>
          <Flex
            mt={0}
            width="100%"
            flexDirection="column"
            data-e2e-target-name={assignmentListE2ETargets.list}
          >
            <SectionBody px="m">
              {totalItems > 0 ? (
                <AssignmentsGroup assignments={assignments || []} />
              ) : (
                <BodyText color="grey.standard" p="15px" pt="none">
                  {messages.assignment.noResultFound}
                </BodyText>
              )}
              {totalPages > 1 && (
                <Flex width="100%" justifyContent="center" mb={20}>
                  <Pagination
                    {...paginationAssignmentList}
                    onPageChange={handlePageChange}
                  />
                </Flex>
              )}
            </SectionBody>
          </Flex>
        </>
      )}
    </Flex>
  )
}
