import * as Yup from "yup"

Yup.addMethod(Yup.object, "atLeastOneOf", function (list: string[]) {
  return this.test({
    name: "atLeastOneOf",
    message: ({ path, keys }) =>
      `${path} must have at least one of these keys: ${keys}`,
    exclusive: true,
    params: { keys: list.join(", ") },
    test: (value) => value !== null && list.some((key) => Boolean(value[key]))
  })
})

Yup.addMethod(Yup.string, "stripEmptyString", function () {
  return this.transform((value) => (value === "" ? undefined : value))
})

Yup.addMethod(Yup.object, "uniqueProperty", function (propertyName, message) {
  return this.test("unique", message, function (value) {
    if (!value || !value[propertyName]) {
      return true
    }

    const { path } = this
    const options = [...this.parent]
    const currentIndex = options.indexOf(value)

    const subOptions = options.slice(0, currentIndex)

    if (
      subOptions.some((option) => option[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message
      })
    }

    return true
  })
})
