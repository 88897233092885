import React from "react"
import {
  Button,
  Div,
  Flex,
  PencilIcon,
  SectionBody,
  SectionHeader
} from "@ikiru/talentis-fpc"

type ViewableSectionProps = {
  view: React.ReactNode
  showEditIcon?: boolean
  editFunction?: () => void
  title?: React.ReactNode
  actions?: React.ReactNode
}

const ViewableSection = (props: ViewableSectionProps) => {
  const {
    title,
    actions,
    view,
    editFunction = () => {},
    showEditIcon = true
  } = props
  return (
    <Div width="100%">
      <SectionHeader
        title={title}
        size="xxSmall"
        actions={
          <Flex>
            <Div>{actions}</Div>
            {showEditIcon && (
              <Button
                mode="standard-white"
                size="action-medium"
                onClick={() => {
                  editFunction()
                }}
                mx="xs"
              >
                <PencilIcon color="red" />
              </Button>
            )}
          </Flex>
        }
      />
      <SectionBody>{view}</SectionBody>
    </Div>
  )
}

export default ViewableSection
