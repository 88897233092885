import { useMemo } from "react"
import { FieldMetaProps } from "formik"

export const getStatus = <T = any>(
  touched?: boolean,
  error?: boolean,
  value?: T
) => {
  if (touched && error) {
    return "invalid"
  }
  if ((touched && value) || (!error && value)) {
    return "valid"
  }
  return "default"
}

export const useStatus = <V = any>(meta: FieldMetaProps<V>) =>
  useMemo(
    () => getStatus(meta.touched, Boolean(meta.error), meta.value),
    [meta.error, meta.touched, meta.value]
  )
