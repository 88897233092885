import React from "react"
import { PlusIcon } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { StyledLink, StyledPopupOption } from "./style"

const AddTeamMemberOption: React.FC<{ onClick?: () => void }> = ({
  onClick
}) => (
  <StyledPopupOption onClick={onClick}>
    <StyledLink size="small" href="#">
      <PlusIcon width={12} height={12} />
      &nbsp;
      {messages.assignment.addTeamMember}
    </StyledLink>
  </StyledPopupOption>
)

export default AddTeamMemberOption
