import React, { useState, useRef, useEffect, useMemo } from "react"
import {
  Flex,
  H5,
  BodyText,
  colors,
  StyledSystemElement
} from "@ikiru/talentis-fpc"
import {
  BodyTextStyled,
  SpanStyled,
  DropDownIconStyled,
  ButtonStyled,
  ShowMoreContainer
} from "./style"
import { useWindowSize } from "utils/hooks/use-window-size"

export type showMoreInfo = {
  title: string
  info?: string | number
}

type ShowMoreProps = {
  visibleLines: number
  moreInfo?: boolean
  toDisplayInfo?: showMoreInfo[]
  description?: JSX.Element | string
  isLongDescription?: boolean
  title?: string
  children?: (isExpanded: boolean) => JSX.Element | boolean
  isButton?: boolean
  hasBackground?: boolean
  dropDownBottomPosition?: number
  hasCustomDropDownIcon?: boolean
  descriptionContainerProps?: StyledSystemElement<HTMLDivElement> &
    React.RefAttributes<unknown>
  showMoreContainerProps?: StyledSystemElement<HTMLDivElement> &
    React.RefAttributes<unknown>
  extraInfo?: (
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>,
    isExpanded: boolean,
    isShowedButton: boolean
  ) => JSX.Element
  color?: string
  isSmall?: boolean
}

export const ShowMore = (props: ShowMoreProps) => {
  const {
    description,
    visibleLines,
    title,
    moreInfo = false,
    toDisplayInfo = [],
    isLongDescription = true,
    children = false,
    isButton = false,
    dropDownBottomPosition = 4,
    hasBackground = false,
    hasCustomDropDownIcon = false,
    descriptionContainerProps,
    showMoreContainerProps,
    extraInfo,
    color = colors.grey.darkest,
    isSmall = false
  } = props

  const [isExpanded, setIsExpanded] = useState(false)
  const [isShowedButton, setIsShowedButton] = useState(isButton)

  const descRef = useRef(undefined)
  const size = useWindowSize()
  const heightBound = useMemo(() => 22 * visibleLines, [visibleLines])

  useEffect(() => {
    if (isLongDescription) {
      const { scrollHeight } = descRef?.current!
      setIsShowedButton(heightBound < scrollHeight)
    } else {
      setIsShowedButton(moreInfo)
    }
  }, [size, heightBound, isLongDescription, moreInfo])

  return (
    <>
      <ShowMoreContainer {...{ ...showMoreContainerProps, isLongDescription }}>
        <Flex {...descriptionContainerProps}>
          <React.Fragment>
            {isLongDescription ? (
              <BodyTextStyled
                visibleLines={visibleLines}
                expanded={isExpanded}
                ref={descRef}
                noExtraInfo={!extraInfo}
                color={color}
                isSmall={isSmall}
              >
                {title && <SpanStyled isSmall={isSmall}>{title} - </SpanStyled>}{" "}
                {description}
              </BodyTextStyled>
            ) : (
              toDisplayInfo.map((info) => {
                return (
                  Boolean(info.info) && (
                    <Flex alignItems="baseline" key={info.title} mr="25px">
                      <H5 color={color} m="0" mr="8px">
                        {info.title}
                      </H5>
                      <BodyText m="0" color={color}>
                        {info.info}
                      </BodyText>
                    </Flex>
                  )
                )
              })
            )}
          </React.Fragment>

          {extraInfo?.(setIsExpanded, isExpanded, isShowedButton)}
        </Flex>
        {isShowedButton && !hasCustomDropDownIcon && (
          <ButtonStyled
            mt={isLongDescription ? null : "3px"}
            mode="standard-white"
            size="action-small"
            dropDownBottomPosition={dropDownBottomPosition}
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
            hasBackground={hasBackground}
          >
            <DropDownIconStyled up={isExpanded} />
          </ButtonStyled>
        )}
      </ShowMoreContainer>

      {children && children(isExpanded)}
    </>
  )
}
