import React from "react"
import { Form, Formik } from "formik"
import { Div } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikSelect } from "components/functional/formik/formik-select/FormikSelect"
import { transformedCountries } from "utils/transformer"
import { messages } from "setup/messages/messages"
import { BillingDetails } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"
import {
  BillingAddressFields,
  BillingAddressType,
  initialBillingAddressValues
} from "./BillingAddressForm.definitions"
import { billingAddressValidationSchema } from "./BillingAddressForm.schema"

interface BillingDetailsFormProps {
  onSubmit: (values: BillingAddressType) => Promise<void>
  initialValues?: BillingDetails
}

export const BillingAddressForm = (props: BillingDetailsFormProps) => {
  const { onSubmit, initialValues } = props

  const populatedValues = { ...initialBillingAddressValues, ...initialValues }
  const populatedValuesAreValid =
    billingAddressValidationSchema.isValidSync(populatedValues)

  const formikContextValue = {
    initialValues: populatedValues,
    validationSchema: billingAddressValidationSchema,
    onSubmit,
    isInitialValid: populatedValuesAreValid
  }

  return (
    <Formik {...formikContextValue}>
      {({ isValid }) => (
        <Form>
          <FormikInput
            id={BillingAddressFields.Email}
            name={BillingAddressFields.Email}
            label={messages.form.generic.emailAddress}
          />
          <FormikInput
            id={BillingAddressFields.Address}
            name={BillingAddressFields.Address}
            label={messages.subscription.paymentDetails.addressFirstLine}
          />
          <FormikInput
            id={BillingAddressFields.City}
            name={BillingAddressFields.City}
            label={messages.subscription.paymentDetails.addressCity}
          />
          <FormikSelect
            id={BillingAddressFields.CountryCode}
            name={BillingAddressFields.CountryCode}
            label={messages.form.generic.country}
            options={transformedCountries}
            hideLabelIfEmpty
            firstOption={{
              label: messages.form.generic.country,
              value: ""
            }}
          />
          <FormikInput
            id={BillingAddressFields.PostCode}
            name={BillingAddressFields.PostCode}
            label={messages.subscription.paymentDetails.addressPostalCode}
          />
          <FormikInput
            id={BillingAddressFields.Phone}
            name={BillingAddressFields.Phone}
            label={messages.subscription.paymentDetails.phone}
          />
          <Div mt="s">
            <FormikInput
              id={BillingAddressFields.VatNumber}
              name={BillingAddressFields.VatNumber}
              label={messages.subscription.paymentDetails.vatNumber}
            />
          </Div>

          <Button type="submit" mt="m" disabled={!isValid}>
            {messages.subscription.planDetails.form.continueToPayment}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
