import React from "react"
import { Div, Flex, LinkedInIcon } from "@ikiru/talentis-fpc"
import { WebSiteLink } from "./styles"
import { WebSite, KnownWebSite, knownWebSitesIcons } from "./definitions"

type WebSitesProps = {
  webSites: WebSite[]
  linkedInProfileUrl?: string
  marginRight?: string
  marginLeft?: string
  iconSize?: number
  linkedInIconSize?: { height: number; width: number }
}

export const WebSites = ({
  webSites,
  linkedInProfileUrl,
  linkedInIconSize,
  marginRight,
  marginLeft,
  iconSize
}: WebSitesProps) => {
  const knownWebsites = webSites
    .filter(({ type }) => Boolean(type) && type !== "other")
    .map((website) => website as KnownWebSite)

  return (
    <Flex
      as="ul"
      flexWrap="nowrap"
      p={0}
      m={0}
      ml={marginLeft || "none"}
      style={{ listStyleType: "none" }}
    >
      {linkedInProfileUrl && (
        <Div as="li" mr={marginRight || "xxs"}>
          <WebSiteLink
            onClick={(e) => e.stopPropagation()}
            href={linkedInProfileUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon
              {...(linkedInIconSize
                ? linkedInIconSize
                : iconSize
                ? { height: iconSize, width: iconSize }
                : {})}
            />
          </WebSiteLink>
        </Div>
      )}
      {knownWebsites.map((item, index) => {
        const Icon = knownWebSitesIcons[item.type]
        return (
          <Div
            as="li"
            key={`${item}-${index}`}
            mr={knownWebsites.length - 1 !== index ? marginRight || "xxs" : 0}
          >
            <WebSiteLink
              onClick={item.onClick ? item.onClick : (e) => e.stopPropagation()}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {Icon && (
                <Icon
                  {...(linkedInIconSize && item.type === "linkedIn"
                    ? linkedInIconSize
                    : iconSize
                    ? { height: iconSize, width: iconSize }
                    : {})}
                />
              )}
            </WebSiteLink>
          </Div>
        )
      })}
    </Flex>
  )
}
