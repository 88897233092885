import React from "react"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import {
  AssignmentFeeDataField,
  currenciesOptions
} from "views/assignments/components/assignment-data/constants/definitions"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"

export const AssignmentFeeFormLayout = () => {
  return (
    <Div alignItems="center" justifyContent="stretch" position="relative">
      <Flex mb="xs" flexWrap="wrap">
        <Div minWidth="184px" mr="xs">
          <FormikSelect
            height={400}
            options={currenciesOptions()}
            id={AssignmentFeeDataField.FeeCurrency}
            name={AssignmentFeeDataField.FeeCurrency}
            label={messages.assignment.assignmentCurrenry}
          />
        </Div>
        <Div minWidth="184px" mr="xs">
          <FormikInput
            id={AssignmentFeeDataField.FeeFrom}
            name={AssignmentFeeDataField.FeeFrom}
            label={messages.assignment.assignmentFeeFrom}
          />
        </Div>
        <Div minWidth="184px" mr="xs">
          <FormikInput
            id={AssignmentFeeDataField.FeeTo}
            name={AssignmentFeeDataField.FeeTo}
            label={messages.assignment.assignmentFeeTo}
          />
        </Div>
        <Div minWidth="184px">
          <FormikInput
            id={AssignmentFeeDataField.FinalFee}
            name={AssignmentFeeDataField.FinalFee}
            label={messages.assignment.assignmentFinalFee}
          />
        </Div>
      </Flex>
      <Div mt="xs">
        <FormikTextarea
          rows={3}
          id={AssignmentFeeDataField.FeeComment}
          name={AssignmentFeeDataField.FeeComment}
          label={messages.assignment.assignmentFeeComment}
          resize="none"
        />
      </Div>
    </Div>
  )
}
