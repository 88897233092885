import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useSearch } from "views/search/SearchModule/context"
import { Nullable } from "tsdef"

export const usePersonDataPoolLinkage = () => {
  const {
    linkageDataPoolPerson,
    selectedSearchDataPoolId,
    setSelectedSearchPersonId,
    selectedSearchPersonId,
    persons = [],
    updatePerson
  } = useSearch()
  const { setCreatedDate, getProfileData } = usePerson()

  const createLocalPerson = async (
    personId?: Nullable<string>,
    dataPoolIdParam?: string,
    updateSelectedSearchPersonId = true
  ): Promise<string> => {
    const dataPoolIdLocal = dataPoolIdParam || selectedSearchDataPoolId
    let personIdLocal = personId
    if (!personIdLocal && !selectedSearchPersonId) {
      personIdLocal = await linkageDataPoolPerson(dataPoolIdLocal)
      const newPerson = persons.map((person) => {
        if (person.person.dataPoolId === dataPoolIdLocal) {
          person.person.personId = personIdLocal!
          person.person.createdDate = new Date()
        }
        return person
      })
      setCreatedDate(new Date())
      updatePerson(newPerson)
      if (updateSelectedSearchPersonId) {
        getProfileData(personIdLocal!)
        setSelectedSearchPersonId(personIdLocal!)
      }
    }
    return personIdLocal as string
  }
  return { createLocalPerson }
}
