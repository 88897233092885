import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import { GenerateBioWrapper } from "./style"
import GenerateBioForm from "./GenerateBioForm"
import { saveNewBio } from "./action"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { getNormalizedPersonProfileDetails } from "../../utils/get-person-details"
import { useModal } from "utils/hooks/use-modal"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { ModalNames } from "setup/modal/modal.definitions"
import { loadAIBioSettings } from "./utils"

const GenerateBioModal = () => {
  const { close } = useModal()
  const { personId, setProfileDetails } = usePerson()

  const onSave = async (generatedBio: string) => {
    const data = await saveNewBio(generatedBio, personId)
    const mergedPersonData = new LocalPersonModel(data)
    const profileDetails = getNormalizedPersonProfileDetails(mergedPersonData)
    setProfileDetails(profileDetails)
    close(ModalNames.GenerateBioAI)
  }

  return (
    <GenerateBioWrapper>
      <Div width="100%" p="m">
        <GenerateBioForm onSaveFun={onSave} bioSettings={loadAIBioSettings()} />
      </Div>
    </GenerateBioWrapper>
  )
}

export default GenerateBioModal
