import React, { useRef, useState } from "react"
import {
  Button,
  colors,
  SectionBody,
  SectionHeader,
  ExplicitSectionHeaderProps,
  PencilIcon,
  getMinWidthMediaQuery
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { EditableDetails } from "components/EditableDetails/EditableDetails"
import { EditableDetailsPayload } from "components/EditableDetails/editable-details.types"
import { FormikProps } from "formik"
import { SubmitEditableSectionButton } from "components/EditableSection/SubmitEditableSectionButton"
import { editableSectionE2eTargets } from "components/EditableSection/definitions"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { Nullable } from "tsdef"
import { hexToRGB } from "utils/hexToRGB"

type HeaderVisibility = {
  viewMode?: boolean
  editMode?: boolean
}

type EditableSectionProps = {
  edit: (editableControls: EditableDetailsPayload) => React.ReactNode
  view: (editableControls: EditableDetailsPayload) => React.ReactNode
  actions?: (editableControls: EditableDetailsPayload) => React.ReactElement
  footer?: (
    editableControls: EditableDetailsPayload
  ) => React.ReactElement | null
  header?: Omit<ExplicitSectionHeaderProps, "actions">
  targetName?: string
  isHidden?: boolean
  isExpandable?: boolean
  headerVisibility?: HeaderVisibility
  isDefaultExpanded?: boolean
  isShowedExpanded?: boolean
  defaultEditMode?: Nullable<boolean>
  size?: "small" | "default" | "xSmall" | "xxSmall" | undefined
  noBottomSpace?: boolean
  isOffLimits?: boolean
}

export const EditableSection = (props: EditableSectionProps) => {
  const {
    targetName,
    view,
    edit,
    header,
    actions,
    footer,
    isHidden = false,
    isExpandable = true,
    headerVisibility = {
      viewMode: true,
      editMode: true
    },
    isDefaultExpanded = false,
    defaultEditMode = false,
    size,
    noBottomSpace = false,
    isOffLimits = false
  } = props
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded)
  const submitButtonRef = useRef<HTMLButtonElement>(null!)
  const mediaQuery = getMinWidthMediaQuery("md")
  const isLarge = useMediaQuery(mediaQuery)

  const containerStyle =
    Boolean(!isExpanded || isHidden) && Boolean(isExpandable)
      ? { height: 0, padding: 0, overflow: "hidden" }
      : {}

  const {
    viewMode: viewModeHeaderVisible = true,
    editMode: editModeHeaderVisible = true
  } = headerVisibility

  return (
    <EditableDetails
      noBottomSpace={noBottomSpace}
      defaultEditMode={defaultEditMode}
      actions={({ setEditMode, isInEditMode, isInViewMode, setViewMode }) => {
        const isInViewModeHidden = isInViewMode && !viewModeHeaderVisible
        const isInEditModeHidden = isInEditMode && !editModeHeaderVisible

        const isHeaderHidden = isInViewModeHidden || isInEditModeHidden

        if (isHeaderHidden) return null

        return actions ? (
          actions({ setEditMode, isInEditMode, isInViewMode, setViewMode })
        ) : (
          <SectionHeader
            {...{
              ...header,
              size: size ? size : isLarge ? "default" : "small"
            }}
            actions={
              <>
                {isInEditMode && (
                  <>
                    <Button
                      data-e2e-target-name={targetName}
                      data-e2e-target={editableSectionE2eTargets.saveButton}
                      size="small"
                      type="button"
                      onClick={() => submitButtonRef?.current?.click()}
                      mr="xs"
                    >
                      {messages.form.generic.save}
                    </Button>
                    <Button
                      data-e2e-target={editableSectionE2eTargets.cancelEdition}
                      data-e2e-target-name={targetName}
                      size="small"
                      mode="standard-white"
                      onClick={() => {
                        setViewMode()
                      }}
                    >
                      {messages.generic.cancel}
                    </Button>
                  </>
                )}
                {isInViewMode && (
                  <>
                    <Button
                      mode="standard-white"
                      size="action-medium"
                      onClick={() => {
                        setIsExpanded(true)
                        setEditMode()
                      }}
                      data-e2e-target={editableSectionE2eTargets.editButton}
                      data-e2e-target-name={targetName}
                      mr="xs"
                    >
                      <PencilIcon color="red" />
                    </Button>
                  </>
                )}
              </>
            }
          />
        )
      }}
      view={(editableControls) => (
        <SectionBody
          backgroundColor={
            isOffLimits
              ? hexToRGB(colors.red.lightest, 0.7)
              : colors.grey.lightest
          }
          {...(!editableControls.isInEditMode ? containerStyle : {})}
        >
          {view(editableControls)}
        </SectionBody>
      )}
      edit={(editableControls) => {
        const editComponent = edit(editableControls) as React.ReactElement
        const editWithBtn = React.cloneElement(editComponent, {
          formActionsComponent: (formik: FormikProps<any>) => (
            <SubmitEditableSectionButton
              innerRef={submitButtonRef}
              disabled={formik.isSubmitting}
            />
          )
        })
        return (
          <SectionBody
            data-e2e="section-body"
            maxHeight="450px"
            overflowY="auto"
            {...(!editableControls.isInEditMode ? containerStyle : {})}
          >
            {editWithBtn}
          </SectionBody>
        )
      }}
      footer={(editableControls) =>
        footer ? footer?.(editableControls) : null
      }
    />
  )
}
