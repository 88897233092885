import React, { useMemo } from "react"
import {
  PersonRecordBox,
  AvatarBox,
  AvatarWrapper,
  NameDetailsBox,
  CompanyDetailsBox,
  JobTitleDetailsBox,
  WebsitesDetailsBox,
  LocationDetailsBox,
  OffLimitsContainerBox,
  EmptyBox
} from "./style"
import { PersonSearchResult } from "views/persons/pages/search-person/search-person/search-person.definitions"
import { Flex, getMinWidthMediaQuery } from "@ikiru/talentis-fpc"
import { BodyText } from "@ikiru/talentis-fpc"
import { Avatar } from "@ikiru/talentis-fpc"
import { ConnectedIcon } from "@ikiru/talentis-fpc"
import { Link } from "@ikiru/talentis-fpc"
import { spacing } from "@ikiru/talentis-fpc"
import { PersonWebSites } from "./PersonWebSites"
import { e2eTargets } from "./consts/definitions"
import { useSearch as useContacts } from "../../../../contacts/SearchModule/context"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"
import { useNavigate } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { OffLimitsBlock } from "components/OffLimits/OffLimitsBlock"
import { messages } from "setup/messages/messages"
import { ProjectTeam } from "components/ProjectTeamMembers/ProjectTeam"
import { useSaveTeam } from "../../../components/person-details-tab/components/person-team/hooks/SaveTeam"
import { sortExperience } from "views/persons/components/person-experience/utils"
import LazyLoadContainer from "components/LazyContainer/LazyContainer"

export const PersonRecord = React.memo(
  ({
    id,

    name,
    experience,
    location,
    linkedInProfileUrl,
    webSites = [],
    isOfflimits,
    teamMembers,
    photo
  }: PersonSearchResult) => {
    const { setSelectedSearchPersonId: setSelectedContactId } = useContacts()
    const navigate = useNavigate()
    const { sortPersonTeam } = useSaveTeam()

    const personUrl = useMemo(() => `${RouterUrl.ProfileDetails}/${id}`, [id])

    const sortedExperience = sortExperience(experience)[0]

    const mediaQuery = getMinWidthMediaQuery("md")
    const isLarge = useMediaQuery(mediaQuery)

    const onClickPerson = () => {
      isLarge ? setSelectedContactId(id) : navigate(personUrl)
    }

    return (
      <Flex my="0">
        <PersonRecordBox onClick={onClickPerson} isOfflimits={isOfflimits}>
          {isOfflimits && (
            <OffLimitsContainerBox>
              <OffLimitsBlock isSmall isCircle />
            </OffLimitsContainerBox>
          )}
          <AvatarBox isOfflimits={isOfflimits}>
            <LazyLoadContainer
              children={
                <AvatarWrapper>
                  <Avatar
                    data-e2e-target-name={`person-${name}-avatar`}
                    photo={{ url: photo?.url }}
                    name={name}
                    disableHoverOpacity
                  />
                </AvatarWrapper>
              }
              placeHolder={
                <AvatarWrapper>
                  <Avatar name={name} disableHoverOpacity />
                </AvatarWrapper>
              }
            />
          </AvatarBox>
          <NameDetailsBox isOfflimits={isOfflimits}>
            <Flex alignItems="center">
              <Link
                mb={0}
                fontWeight={600}
                mr="xxs"
                data-e2e-target-name={e2eTargets.personName}
              >
                {name}
              </Link>
              <ConnectedIcon width={spacing.m} />
            </Flex>
          </NameDetailsBox>
          <CompanyDetailsBox
            onClick={(e) => e.stopPropagation()}
            isOfflimits={isOfflimits}
          >
            <CompanyLink
              fontSize={[15, 15, 16]}
              companyName={sortedExperience?.company}
              companyId={sortedExperience?.companyId}
            />
          </CompanyDetailsBox>
          <JobTitleDetailsBox isOfflimits={isOfflimits}>
            <BodyText data-e2e-target-name={e2eTargets.personJob} my={0}>
              {sortedExperience?.title}
            </BodyText>
          </JobTitleDetailsBox>
          <WebsitesDetailsBox isOfflimits={isOfflimits}>
            <PersonWebSites
              linkedInProfileUrl={linkedInProfileUrl || undefined}
              webSites={webSites || []}
            />
          </WebsitesDetailsBox>
          {!isOfflimits && <EmptyBox />}
          <LocationDetailsBox isOfflimits={isOfflimits}>
            <BodyText
              data-e2e-target-name={e2eTargets.personLocation}
              mt={0}
              mb="6px"
            >
              {location}
            </BodyText>
            <ProjectTeam
              title={messages.person.personTeam.title}
              projectTeamMembers={teamMembers}
              isList
              overridePopupConfig={{
                PopupProps: {
                  closeOnDocumentClick: true,
                  position: "bottom right",
                  arrow: false
                }
              }}
              sortingFn={sortPersonTeam}
            />
          </LocationDetailsBox>
        </PersonRecordBox>
      </Flex>
    )
  }
)
