import { Div, Grid, theme } from "@ikiru/talentis-fpc"
import styled, { css } from "styled-components"

type PaginationWrapperProps = {
  isFirstPage?: boolean
  isLastPage?: boolean
}

type FormContainerProps = {
  isMobile: boolean
}

const scrollBar = css`
  &::-webkit-scrollbar {
    width: 6px;
    height: 100%;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.grey.light};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey.dark};
    border-radius: 4px;
  }
`

export const FoundPeopleContainer = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 7px;
  padding: 10px;
  width: 100%;
  @media (max-height: 700px) {
    max-height: 43vh;
  }
  max-height: 54vh;
  overflow-y: auto;

  ${scrollBar}
`

export const FormContainer = styled(Div)<FormContainerProps>`
  min-width: 35%;
  padding: ${theme.space.m}px;
  background-color: ${theme.colors.white.standard};
  overflow-y: hidden;
  min-height: 100%;
  ${({ isMobile }) => isMobile && `max-height: 50vh `}
`

export const FormInputContainer = styled(Div)<FormContainerProps>`
  overflow-y: auto;

  @media (max-height: 768px) {
    ${({ isMobile }) =>
      !isMobile ? ` max-height: 73svh` : `max-height: 30vh `};
  }
  @media (min-height: 768px) {
    ${({ isMobile }) =>
      !isMobile ? ` max-height: 80svh` : `max-height: 33vh `};
  }
  ${scrollBar}
`
export const PaginationWrapper = styled(Div)<PaginationWrapperProps>`
  margin-top: ${theme.space.xxs}px;
  margin-bottom: -10px;
  & > div > button:has(svg):last-of-type {
    ${({ isLastPage }) => isLastPage && `display:none;`}
  }

  & > div > button:has(svg):nth-child(1) {
    ${({ isFirstPage }) => isFirstPage && `display:none`}
  }
`

export const TooltipContainer = styled(Div)`
  position: relative;

  overflow: visible;
`

export const TooltipInfoContainer = styled(Div)`
  visibility: hidden;
  display: none;
  min-width: fit-content;
  background-color: ${theme.colors.grey.lightest};
  border: 2px solid ${theme.colors.grey.dark};
  border-radius: 6px;
  position: absolute;
  z-index: 999;
  top: -10%;
  left: 40%;
  margin-left: -40px;
  opacity: 0;
  transition: opacity 0.3s;

  ${TooltipContainer}:hover & {
    display: flex;
    visibility: visible;
    opacity: 1;
  }
`
