import { storageKey } from "setup/storage/storage.utils"

export const localStorageKeys = {
  assignmentStatusInput: storageKey("assignmentStatusInput"),
  location: storageKey("location")
}

export const sessionStorageKeys = {
  previousSearch: storageKey("previousSearch"),
  previousCandidatesFilters: storageKey("previousCandidatesFilters"),
  previousContactsFilters: storageKey("previousContactsFilters"),
  previousTasksFilters: storageKey("previousTasksFilters"),
  aIFilters: storageKey("aIFilters")
}
