import React, { useState } from "react"
import { Form, Formik } from "formik"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"

import { TeamMember } from "views/team/team/team-module.types"
import { ProjectTeamForm } from "components/ProjectTeamMembers/form/ProjectTeamForm"
import FormActionButtons from "../../../FormActionButtons"
import { ModalFormContainer } from "../../../person-details-section/form/style"
import {
  Membership,
  ProjectTeamUpdate
} from "components/ProjectTeamMembers/types"
import { PersonTeamMember } from "views/persons/person.types"

type AssignmentDataFormProps = {
  personTeamMembers: PersonTeamMember[]
  teamMembers: TeamMember[]
  onSubmit: any
}

export const EditPersonTeam = ({
  onSubmit,
  personTeamMembers,
  teamMembers
}: AssignmentDataFormProps) => {
  const [selectedMembers, setSelectedMembers] = useState<ProjectTeamUpdate[]>(
    personTeamMembers.map((member) => ({
      userId: member.userId,
      membership: member.membership
    }))
  )
  const submitForm = () => {
    onSubmit({
      teamMembers: selectedMembers
    })
  }
  return (
    <ModalFormContainer defaultWidth={460}>
      <Formik onSubmit={submitForm} initialValues={[]}>
        <Form>
          <FormActionButtons
            title={messages.person.personTeam.editMode}
            ModalName={ModalNames.PersonTeamEdit}
          />
          <ProjectTeamForm
            projectTeamMembers={personTeamMembers}
            setSelectedMembers={setSelectedMembers}
            teamMembers={teamMembers}
            title={messages.person.personTeam.editMode}
            roles={[
              {
                label: messages.person.personTeam.knownTo,
                value: Membership.KnownTo
              },
              {
                label: messages.person.personTeam.member,
                value: Membership.Member
              }
            ]}
          />
        </Form>
      </Formik>
    </ModalFormContainer>
  )
}
