import React from "react"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import {
  Button,
  Flex,
  ModalSectionHeader,
  SelectOptionProps
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { PersonNote } from "views/persons/components/person-notes/types"
import { PersonNotesList } from "views/persons/components/person-notes/components/PersonNotesList"
import { Loader } from "components/Loader/Loader"
import { PersonNotesAddForm } from "./form/PersonNotesAddForm"
import { OnAddPersonNoteSubmitType } from "views/persons/components/person-notes/form/constants/types"
import { getOnAddPersonNoteSubmit } from "views/persons/components/person-notes/form/actions"
import { e2eTargets } from "./definitions"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import PersonSections from "../person-components/PersonSections"
import PersonSectionButton from "../person-components/PersonSectionButton"
import { usePersonDataPoolLinkage } from "views/search/components/SearchResults/hook/usePersonDataPoolLinkage"

type PersonNotesProps = {
  isNotesLoading: boolean
  assignmentNoteOptions: SelectOptionProps[]
  campaignNoteOptions: SelectOptionProps[]
}

export const PersonNotes = React.memo(
  ({
    isNotesLoading,
    campaignNoteOptions: campaignsOptions,
    assignmentNoteOptions: assignmentsOptions
  }: PersonNotesProps) => {
    const {
      notes = [],
      setNotes,
      personId,
      setPersonOverlayNoteUpdated
    } = usePerson()
    const { createLocalPerson } = usePersonDataPoolLinkage()
    const addPersonNote = (note: PersonNote) => {
      setNotes([note, ...notes])
    }

    const editPersonNote = (note: PersonNote) => {
      setPersonOverlayNoteUpdated(true)
      const newNotes = notes.map((item) => (item.id === note.id ? note : item))
      setNotes(newNotes)
    }

    const { open: openModal, close } = useModal()

    const onSuccessfulSubmit = (note: PersonNote) => {
      setPersonOverlayNoteUpdated(true)
      addPersonNote(note)
      close(ModalNames.AddOrEditPersonNote)
    }

    const onSubmit: OnAddPersonNoteSubmitType = getOnAddPersonNoteSubmit(
      personId,
      onSuccessfulSubmit,
      createLocalPerson
    )

    const addNewNote = () => {
      openModal(
        ModalNames.AddOrEditPersonNote,
        <PersonNotesAddForm
          onSubmit={onSubmit}
          assignmentOptions={assignmentsOptions}
          campaignOptions={campaignsOptions}
          formActionsComponent={(formik) => {
            const { isSubmitting } = formik
            return (
              <ModalSectionHeader
                title={messages.person.note.form.addNote}
                size="xSmall"
                actions={
                  <>
                    <Button
                      type="submit"
                      size="small"
                      mr="xs"
                      disabled={isSubmitting}
                      data-e2e-target={e2eTargets.saveNoteButton}
                    >
                      {messages.generic.save}
                    </Button>
                    <Button
                      data-e2e-target={e2eTargets.cancelNoteButton}
                      mode="standard-white"
                      size="small"
                      disabled={isSubmitting}
                      type="button"
                      onClick={() => {
                        close(ModalNames.AddOrEditPersonNote)
                      }}
                    >
                      {messages.generic.cancel}
                    </Button>
                  </>
                }
              />
            )
          }}
        />
      )
    }

    return (
      <PersonSections
        title={messages.person.note.title}
        sectionCount={notes.length}
        actions={
          <PersonSectionButton
            disabled={isNotesLoading}
            label={messages.person.note.newNote}
            onClick={addNewNote}
          />
        }
      >
        <Flex width="100%" alignItems="center" justifyContent="center">
          {isNotesLoading ? (
            <Loader />
          ) : (
            <PersonNotesList
              personId={personId}
              notes={notes}
              editPersonNote={editPersonNote}
              assignmentOptions={assignmentsOptions}
              campaignOptions={campaignsOptions}
            />
          )}
        </Flex>
      </PersonSections>
    )
  }
)
