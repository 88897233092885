import React, { useReducer, useCallback, useEffect } from "react"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { getNormalizedPersonProfileDetails } from "views/persons/components/person-details/utils/get-person-details"
import { useNavigate, useParams } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import { personReducer, initialPersonState } from "./person-module.reducer"
import {
  SetProfileData,
  SetProfileDetails,
  SetIsLoading,
  PersonActionTypes,
  SetKeywords,
  SetProfilePrivacy,
  GetProfileData,
  SetDocuments,
  SetPhoto,
  SetCandidate,
  SetCandidates,
  SetNotes,
  SetExperience,
  GetProfileAvatar,
  SetProfileAvatar,
  SetContacts,
  SetOffLimits,
  SetTeamMembers,
  SetCVParsing,
  SetActiveTab,
  SetCompletedCVSteps,
  SetTempCvParsingData,
  SetCurrentHoveredItem,
  SetDragItems,
  SetPreviousButtonClicked
} from "./person-module.types"
import { PersonContext } from "./candiate-module.context"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { PersonPhoto } from "../components/person-avatar/constants/person-avatar.definitions"
import { useTeam } from "views/team/team/team-module.context"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { useSearch as useContact } from "views/contacts/SearchModule/context"
import { Candidate } from "views/candidates/candidates.types"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { Contact } from "views/campaigns/components/contacts/type"
import { useTaskManagement } from "../../homepage/task-management-module/task-management-module.context"
import {
  callProfileEndpoint,
  getPersonKeywords
} from "../components/person-details/form/actions"
import { DefaultEditedField } from "../../../components/EditableDetails/editable-details.types"
import { useCompany } from "views/companies/company-module/company-module.context"
import { Experience } from "../person.types"
import { useSearch } from "views/search/SearchModule/context"
import { usePersonDataPoolLinkage } from "views/search/components/SearchResults/hook/usePersonDataPoolLinkage"
import { parseMomentDate } from "utils/moment"
import { ItemDataType } from "components/FPCComponents/DragAndDrop/constants/types"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"

type PersonModuleProps = {
  children: React.ReactNode
}

type PersonModuleLocationParams = {
  contactId?: string
}

export const PersonModule = ({ children }: PersonModuleProps) => {
  const { fetchTeamMembers } = useTeam()
  const navigate = useNavigate()
  const { createLocalPerson } = usePersonDataPoolLinkage()
  const { contactId } = useParams<PersonModuleLocationParams>()
  const { selectedCandidatedId } = useAssignment()
  const { selectedSearchPersonId } = useSearch()
  const { selectedContactId } = useCampaign()
  const { selectedRecordId } = useTaskManagement()
  const { selectedKnownEmployeeId } = useCompany()
  const { selectedInternalSearchPersonId } = useInternalSearch()

  const {
    selectedSearchPersonId: selectedSearchContactId,
    setSelectedSearchPersonId: setSelectedSearchContactId
  } = useContact()
  const [state, dispatch] = useReducer(personReducer, initialPersonState)

  const personId: string =
    contactId ||
    selectedSearchPersonId ||
    selectedCandidatedId ||
    selectedRecordId ||
    selectedContactId ||
    selectedSearchContactId ||
    selectedKnownEmployeeId ||
    selectedInternalSearchPersonId

  useEffect(() => {
    fetchTeamMembers()
  }, [fetchTeamMembers])

  const setProfileAvatar: SetProfileAvatar = (photo) => {
    dispatch({ type: PersonActionTypes.SetProfileAvatar, payload: photo })
  }

  const setProfileData: SetProfileData = (data) => {
    dispatch({ type: PersonActionTypes.SetProfileData, payload: data })
  }

  const setProfileDetails: SetProfileDetails = (data) => {
    dispatch({ type: PersonActionTypes.SetProfileDetails, payload: data })
  }

  const setIsLoading: SetIsLoading = (data) => {
    dispatch({ type: PersonActionTypes.SetIsLoading, payload: data })
  }

  const setKeywords: SetKeywords = (data) => {
    dispatch({ type: PersonActionTypes.SetKeywords, payload: data })
  }

  const setProfilePrivacy: SetProfilePrivacy = (data) => {
    dispatch({ type: PersonActionTypes.SetProfilePrivacy, payload: data })
  }

  const setDocuments: SetDocuments = useCallback((data) => {
    dispatch({ type: PersonActionTypes.SetDocuments, payload: data })
  }, [])

  const setCandidate: SetCandidate = useCallback((data) => {
    dispatch({ type: PersonActionTypes.SetCandidate, payload: data })
  }, [])

  const setCandidates: SetCandidates = useCallback((data) => {
    dispatch({ type: PersonActionTypes.SetCandidates, payload: data })
  }, [])

  const setPhoto: SetPhoto = (data) => {
    dispatch({ type: PersonActionTypes.SetPhoto, payload: data })
  }

  const setExperience: SetExperience = useCallback((data) => {
    dispatch({ type: PersonActionTypes.SetExperience, payload: data })
  }, [])

  const setNotes: SetNotes = useCallback((data) => {
    dispatch({ type: PersonActionTypes.SetNotes, payload: data })
  }, [])

  const setContacts: SetContacts = useCallback((data) => {
    dispatch({ type: PersonActionTypes.SetContacts, payload: data })
  }, [])

  const setContact = useCallback((data: any) => {
    dispatch({ type: PersonActionTypes.SetContact, payload: data })
  }, [])

  const setPersonOverlayUpdated = useCallback((data: any) => {
    dispatch({ type: PersonActionTypes.SetPersonOverlayUpdated, payload: data })
  }, [])

  const setPersonOverlayNoteUpdated = useCallback((data: any) => {
    dispatch({
      type: PersonActionTypes.SetPersonOverlayNoteUpdated,
      payload: data
    })
  }, [])

  const setPersonOverlayPhotoUpdated = useCallback((data: any) => {
    dispatch({
      type: PersonActionTypes.SetPersonOverlayPhotoUpdated,
      payload: data
    })
  }, [])

  const setDefaultFocusedField = useCallback((data: DefaultEditedField) => {
    dispatch({ type: PersonActionTypes.setDefaultFocusedField, payload: data })
  }, [])

  const setLinkedinEditMode = useCallback((data: boolean) => {
    dispatch({ type: PersonActionTypes.setLinkedinEditMode, payload: data })
  }, [])

  const setCreatedDate = useCallback((data: Date) => {
    dispatch({ type: PersonActionTypes.SetCreatedDate, payload: data })
  }, [])

  const setOfflimits: SetOffLimits = (data) => {
    dispatch({
      type: PersonActionTypes.SetOffLimits,
      payload: data
    })
  }

  const setTeamMembers: SetTeamMembers = (data) => {
    dispatch({
      type: PersonActionTypes.SetTeamMembers,
      payload: data
    })
  }

  const setCVParsing: SetCVParsing = (data) => {
    dispatch({
      type: PersonActionTypes.SetCVParsing,
      payload: data
    })
  }

  const setTempCvParsingData: SetTempCvParsingData = (data) => {
    dispatch({
      type: PersonActionTypes.SetTempCvParsingData,
      payload: data
    })
  }

  const setActiveTab: SetActiveTab = (data) => {
    dispatch({
      type: PersonActionTypes.SetActiveTab,
      payload: data
    })
  }

  const setCompletedCVSteps: SetCompletedCVSteps = (data) => {
    dispatch({
      type: PersonActionTypes.SetCompletedCVSteps,
      payload: data
    })
  }

  const setCurrentHoveredItem: SetCurrentHoveredItem = (data: string) => {
    dispatch({
      type: PersonActionTypes.SetCurrentHoveredItem,
      payload: data
    })
  }

  const setDragItems: SetDragItems = (dragItems: ItemDataType[]) => {
    dispatch({
      type: PersonActionTypes.SetDragItems,
      payload: dragItems
    })
  }

  const setPreviousButtonClicked: SetPreviousButtonClicked = (
    data: string | number
  ) => {
    dispatch({
      type: PersonActionTypes.SetPreviousButtonClicked,
      payload: data
    })
  }

  const redirectToSearch = useCallback(
    () => navigate(RouterUrl.ProfileSearch),
    [navigate]
  )

  const getProfileAvatar: GetProfileAvatar = useCallback(async (personId) => {
    const [, response] = await apiRequest.get({
      endpoint: PersonsEndpoints.Photo,
      endpointParams: personId
    })

    if (response) {
      const { photo } = response?.data
      setProfileAvatar(photo)
    }

    setIsLoading(false)
  }, [])

  const deleteExperience = useCallback(
    async (localPersonId: string, experienceId: string) => {
      let personId = await createLocalPerson(localPersonId)
      return await apiRequest.delete({
        endpoint: PersonsEndpoints.Experience,
        endpointParams: { personId, experienceId }
      })
    },
    [createLocalPerson]
  )

  const editExperience = useCallback(
    async (
      localPersonId: string,
      experienceId: string,
      data: Partial<Experience>
    ) => {
      let personId = await createLocalPerson(localPersonId)
      return apiRequest.patch({
        endpoint: PersonsEndpoints.Experience,
        endpointParams: { personId, experienceId },
        data
      })
    },
    [createLocalPerson]
  )

  const createExperience = useCallback(
    async (localPersonId: string, data: Partial<Experience>) => {
      let personId = await createLocalPerson(localPersonId)
      return apiRequest.post({
        endpoint: PersonsEndpoints.Experience,
        endpointParams: { personId, experienceId: "" },
        data
      })
    },
    [createLocalPerson]
  )

  const getProfileData: GetProfileData = useCallback(
    async (personID) => {
      setIsLoading(true)
      const [error, response] = await callProfileEndpoint(personID)
      if (error) {
        if (selectedSearchContactId) {
          setSelectedSearchContactId("")
        } else {
          redirectToSearch()
        }
      } else {
        const normalized = new LocalPersonModel(response?.data)
        const {
          photo,
          gdprLawfulBasisState,
          createdDate,
          offlimits,
          teamMembers,
          ...data
        } = normalized

        const endDate = parseMomentDate(offlimits?.endDate)
        // @ts-ignore
        setOfflimits({ ...offlimits, endDate })
        setTeamMembers(teamMembers)

        const profileDetails = getNormalizedPersonProfileDetails(data)
        setProfileData({
          profileDetails,
          profilePrivacy: gdprLawfulBasisState,
          createdDate,
          person: response?.data.localPerson
        })

        setPhoto(photo as PersonPhoto)

        const [error, resp] = await getPersonKeywords(personID)
        setKeywords(error ? [] : resp?.data.keywords)
      }
      setIsLoading(false)
    },
    [redirectToSearch, selectedSearchContactId, setSelectedSearchContactId]
  )

  const { candidates, contacts } = state

  const updateCandidateStage = useCallback(
    (candidateId: string, newCandidate: Candidate) => {
      const newCandidateTemp = candidates[candidateId]
      newCandidateTemp.tags = newCandidate.tags

      setCandidate(newCandidateTemp)
    },
    [candidates, setCandidate]
  )

  const updateContactStage = useCallback(
    (contactId: string, newContact: Contact) => {
      const newContactTemp = contacts[contactId]
      newContactTemp.tags = newContact.tags
      newContactTemp.status = newContact.status
      newContactTemp.assignTo = newContact.assignTo
      newContactTemp.dueDate = newContact.dueDate
      setContact(newContactTemp)
    },
    [contacts, setContact]
  )

  return (
    <PersonContext.Provider
      value={{
        ...state,
        personId,
        createExperience,
        editExperience,
        deleteExperience,
        setProfileDetails: setProfileDetails,
        setProfilePrivacy: setProfilePrivacy,
        setKeywords: setKeywords,
        getProfileData: getProfileData,
        setDocuments: setDocuments,
        setPhoto: setPhoto,
        setPersonOverlayNoteUpdated,
        setPersonOverlayPhotoUpdated,
        setIsLoading,
        setCandidate,
        setCandidates,
        setNotes,
        setExperience,
        getProfileAvatar,
        updateCandidateStage,
        setContacts,
        setContact,
        updateContactStage,
        setPersonOverlayUpdated,
        setDefaultFocusedField,
        setLinkedinEditMode,
        setCreatedDate,
        setOffLimits: setOfflimits,
        setTeamMembers: setTeamMembers,
        setCVParsing: setCVParsing,
        setTempCvParsingData,
        setActiveTab: setActiveTab,
        setCompletedCVSteps: setCompletedCVSteps,
        setCurrentHoveredItem: setCurrentHoveredItem,
        setDragItems,
        setPreviousButtonClicked: setPreviousButtonClicked
      }}
    >
      {children}
    </PersonContext.Provider>
  )
}
