import { Flex, H1 } from "@ikiru/talentis-fpc"
import React from "react"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"
import { CurrentPlan } from "./components/CurrentPlan"
import ContactCredits from "./components/CurrentPlan/components/PaidPlanDetails/ContactCredits"
import { useCurrentPlan } from "./components/CurrentPlan/hooks"
import UpdateBillingCTABox from "./components/UpdateBillingCTABox"

export const Billing = () => {
  const { isLoading, firmToken, currentPlanDetails } = useCurrentPlan()

  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.billing.title)}</title>
      </Helmet>
      <H1 m="0" color="green.standard">
        {messages.billing.title}
      </H1>
      <Flex
        mt="s"
        alignItems={["center", "center", "flex-start"]}
        flexDirection={["column", "column", "row"]}
      >
        <Flex
          width={["100%", "100%", "50%"]}
          maxWidth={["100%", "100%", "500px"]}
          my={["s", "s", "none"]}
        >
          <Flex flexDirection="column">
            <CurrentPlan
              isLoading={isLoading}
              currentPlanDetails={currentPlanDetails}
            />
            {!!currentPlanDetails?.paidSubscriptionDetails && (
              <Flex mt="m">
                <UpdateBillingCTABox />
              </Flex>
            )}
          </Flex>
        </Flex>
        {!!currentPlanDetails?.paidSubscriptionDetails && (
          <Flex
            width={["100%", "100%", "50%"]}
            maxWidth={["100%", "100%", "500px"]}
            mx="m"
          >
            <Flex flex={1}>
              <ContactCredits isLoading={isLoading} firmToken={firmToken} />
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  )
}
