import React, { useCallback, useEffect } from "react"
import { BodyText, colors, theme } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { DragAndDrop } from "components/FPCComponents/DragAndDrop"
import { DeleteCVActionButton } from "./components/DragAndDropItems/ActionButton"
import DragItemExistingData from "./components/DragAndDropItems/DragItemExistingData"
import DragItemParsedData from "./components/DragAndDropItems/DragItemParsedData"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { formatExpForDnD, sortDragAndDropItems } from "../utils"
import { DragAndDropContainer } from "./style"
import { DragType } from "./constants/definition"
import { CurrentItemType } from "components/FPCComponents/DragAndDrop/Items"
import { createCvExistingColumn } from "../../person-details/utils/get-cv-experience"
import { CVDataType } from "views/persons/person.types"
import useHandleDndItem from "./hooks/useHandleDndItem"
import { ItemDataType } from "components/FPCComponents/DragAndDrop/constants/types"
import { AddAllCVActionButton } from "./components/DragAndDropItems/ActionButton/AddAlllCVActionButton "

export const ParseProfessionalExperience = () => {
  const { cvParsing, person } = usePerson()
  const {
    setHasDeletedAll,
    hasDeletedAll,
    items,
    onAddAll,
    onDeleteAll,
    disableEditAll,
    setItems,
    undoAddAll,
    disableAddAll,
    disableRestoreAll,
    updateTempPersonData
  } = useHandleDndItem({ dragType: "experience" })

  const getCvParsedExperience = useCallback(() => {
    const existingData: ItemDataType[] =
      cvParsing.professionalExperience.existing.map((exp) => {
        return formatExpForDnD(exp, exp.id, exp.isMovedToParsed ? "2" : "1")
      }) || []

    const parsedData: ItemDataType[] =
      cvParsing.professionalExperience.parsed.map((exp) => {
        return formatExpForDnD(exp, exp.id, exp.isMovedToExisting ? "1" : "2")
      }) || []

    return [...existingData, ...parsedData]
  }, [
    cvParsing.professionalExperience.existing,
    cvParsing.professionalExperience.parsed
  ])

  useEffect(() => {
    const newState = getCvParsedExperience()
    const sortedItems = sortDragAndDropItems(newState)
    setItems(sortedItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeItemColumn = (currentItem: CurrentItemType, columnId: string) => {
    const newState = items.map((e) => {
      if (e.id === currentItem.itemId) {
        if (e.itemComponentProps.type === CVDataType.Parsed) {
          e.itemComponentProps.isMovedToExisting =
            !e.itemComponentProps.isMovedToExisting
        } else {
          e.itemComponentProps.isMovedToParsed =
            !e.itemComponentProps.isMovedToParsed
        }
      }
      return {
        ...e,
        columnId: e.id === currentItem.itemId ? columnId : e.columnId
      }
    })
    const sortedItems = sortDragAndDropItems(newState)
    updateTempPersonData(sortedItems)
    setItems(sortedItems)
  }

  const onRestoreAll = () => {
    setHasDeletedAll(false)

    let existing =
      person?.experience.map((exp) =>
        createCvExistingColumn(exp, CVDataType.Existing, false)
      ) || []
    const newState =
      existing.map((exp) => {
        return formatExpForDnD(exp, exp.id, exp.isMovedToParsed ? "2" : "1")
      }) || []

    const sortedItems = sortDragAndDropItems([...items, ...newState])
    updateTempPersonData(sortedItems)
    setItems(sortedItems)
  }

  return (
    <>
      <BodyText>{messages.person.cvparsing.dragAndDropContent}</BodyText>
      <DragAndDropContainer>
        <DragAndDrop
          explainMessage={{
            explainMessage:
              messages.person.cvparsing.experience.explainMessageDnD,
            hoverColor: colors.green.light,
            excludeList: ["2"]
          }}
          columns={[
            {
              id: "1",
              title: messages.person.cvparsing.experience.existingTitle,
              acceptType: DragType.ExpItem,
              DragComponent: DragItemExistingData,
              titleColor: theme.colors.white.standard,
              columnColor: theme.colors.grey.standard,
              marginRight: 24,
              borderStyle: `1px dashed ${theme.colors.green.light}`,
              minHeight: "53vh",
              maxHeight: "63vh",
              actionComponent: () => (
                <DeleteCVActionButton
                  onRestoreAll={onRestoreAll}
                  onEditAll={onDeleteAll}
                  disableEditAll={hasDeletedAll && disableEditAll}
                  editAllLabel={messages.person.cvparsing.deleteAll}
                  restoreAllLabel={messages.person.cvparsing.restoreAll}
                />
              )
            },

            {
              id: "2",
              title: messages.person.cvparsing.experience.parsedTitle,
              acceptType: DragType.ExpItem,
              DragComponent: DragItemParsedData,
              titleColor: theme.colors.grey.darkest,
              columnColor: theme.colors.green.lightest,
              borderStyle: `1px solid ${theme.colors.grey.light}`,
              minHeight: "53vh",
              maxHeight: "63vh",
              actionComponent: () => (
                <AddAllCVActionButton
                  onRestoreAll={undoAddAll}
                  onEditAll={onAddAll}
                  disableRestore={disableRestoreAll}
                  disableEditAll={disableAddAll}
                  editAllLabel={messages.person.cvparsing.addAll}
                  restoreAllLabel={messages.person.cvparsing.restoreAll}
                />
              )
            }
          ]}
          itemsData={items}
          setItems={changeItemColumn}
        />
      </DragAndDropContainer>
    </>
  )
}
