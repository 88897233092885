import React, { useCallback } from "react"
import { Div, H4, colors } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { Flex, Span } from "@ikiru/talentis-fpc"
import { SmallText } from "@ikiru/talentis-fpc"
import { WarningIcon } from "@ikiru/talentis-fpc"
import { CrossIcon } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { CloseButton } from "../../style"
import { useNewContactModal } from "../../hooks"
import {
  PossibleDuplicatesWrapper,
  DuplicatesListWrapper,
  DuplicatesList,
  DuplicatesFoundLabel,
  SaveRecordBox
} from "./style"
import { usePossibleDuplicates } from "./hooks"
import { PersonFoundRecord } from "./components/PersonFoundRecord"
import { Loader } from "@ikiru/talentis-fpc"
import useAddPersonToProject from "../AddPeople/hooks/useAddPersonToProject"

type PossibleDuplicatesProps = {
  name: string
  assignmentId: string
  campaignId: string
}

export const PossibleDuplicates = ({
  name,
  assignmentId,
  campaignId
}: PossibleDuplicatesProps) => {
  const {
    isLoading,
    duplicates = [],
    setIsLoading
  } = usePossibleDuplicates(name, assignmentId, campaignId)
  const { close, createContact } = useNewContactModal()
  const {
    closeAndRedirectToAssignment,
    closeAndRedirectToCampaign,
    addAssignmentToPerson,
    addCampaignToPerson
  } = useAddPersonToProject()

  const onClickDuplicatedPerson = async (id: string) => {
    setIsLoading(true)

    if (assignmentId) {
      await addAssignmentToPerson(assignmentId, id)
      closeAndRedirectToAssignment(assignmentId)
    }
    if (campaignId) {
      await addCampaignToPerson(campaignId, id)
      closeAndRedirectToCampaign(campaignId)
    }
  }

  const handleClick = useCallback(() => {
    if (assignmentId) {
      closeAndRedirectToAssignment(assignmentId)
    } else if (campaignId) {
      closeAndRedirectToCampaign(campaignId)
    } else {
      close()
    }
  }, [
    assignmentId,
    campaignId,
    closeAndRedirectToAssignment,
    closeAndRedirectToCampaign,
    close
  ])

  return (
    <PossibleDuplicatesWrapper>
      {isLoading ? (
        <Div px="200px">
          <Loader py="xl" />
        </Div>
      ) : (
        <>
          <DuplicatesListWrapper>
            <DuplicatesFoundLabel>
              <WarningIcon fill={colors.orange.standard} />
              <Span color={colors.red.darkest} ml="xxs" fontSize="20px">
                {messages.person.newContact.potentialDuplicatesFound.format(
                  duplicates.length
                )}
              </Span>
              <CloseButton
                onClick={() => handleClick()}
                mode="action"
                size="action-standard"
                mt="-50px"
              >
                <CrossIcon fill={colors.grey.dark} />
              </CloseButton>
            </DuplicatesFoundLabel>
            <DuplicatesList>
              {duplicates.map((item) => (
                <PersonFoundRecord
                  key={item.id}
                  {...item}
                  {...(assignmentId || campaignId
                    ? {
                        onClickCallBack: () => onClickDuplicatedPerson(item.id)
                      }
                    : {})}
                />
              ))}
            </DuplicatesList>
          </DuplicatesListWrapper>
          <SaveRecordBox>
            <H4 mt="none" mb="xs" color="grey.dark">
              {messages.person.newContact.saveNewRecordQuestion}
            </H4>
            <Button
              size="small"
              mode="standard-green"
              onClick={() => {
                setIsLoading(true)
                createContact(name, assignmentId, campaignId)
              }}
            >
              {messages.person.newContact.saveNewRecord}
            </Button>
            <Flex flexDirection="column" alignItems="center" mt="xs">
              <SmallText color="grey.darkest">
                {messages.person.newContact.personAddedToCompanyData}
              </SmallText>
              <SmallText color="grey.darkest">
                {messages.person.newContact.notAutoUpdates}
              </SmallText>
            </Flex>
          </SaveRecordBox>
        </>
      )}
    </PossibleDuplicatesWrapper>
  )
}
