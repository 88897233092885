import React from "react"
import {
  Flex,
  PeopleIcon,
  BodyText,
  Div,
  PolygonIcon,
  H6
} from "@ikiru/talentis-fpc"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"
import { EditableDetailsPayload } from "components/EditableDetails/editable-details.types"
import { StyledPopupTrigger } from "./style"
import { CompanyPhones as CompanyPhonesType } from "views/companies/company-module/company-module.types"

type CompanyPhonesProps = {
  phoneNumbers: CompanyPhonesType[]
  editableControls: EditableDetailsPayload
  addPhoneNumberButtonRef?: any
}

type Option = {
  label: string | JSX.Element
  onClick?: () => void
}

export const CompanyPhones = ({ phoneNumbers }: CompanyPhonesProps) => {
  // const { setEditMode } = editableControls

  const options: Option[] = [
    ...phoneNumbers.map((number: CompanyPhonesType) => ({
      label: number.name || ""
    }))
    //   TODO: wil be added later
    // {
    //   label: (
    //     <Link size="small" href="#" standalone m="0">
    //       <PlusIcon height="12px" />
    //       {messages.form.generic.addPhoneNumber}
    //     </Link>
    //   )
    //   onClick: async () => {
    //     await setEditMode()
    //     addPhoneNumberButtonRef?.current?.click()
    //   }
    //}
  ]

  return (
    <Div>
      <Flex alignItems="center" mb="xxs" mt="12px">
        <Flex>
          <PeopleIcon width="18px" height="18px" viewBox="0 0 20 20" />
          <BodyText m="0" ml="4px" mr="5px">
            {phoneNumbers[0].name}
          </BodyText>
        </Flex>
        {phoneNumbers.length > 0 && (
          <OptionsPopup
            alignSelf="center"
            display="flex"
            config={{
              PopupProps: {
                position: "bottom right"
              }
            }}
            trigger={
              <StyledPopupTrigger>
                {phoneNumbers.length > 1 && (
                  <H6 m="none" mr="2px">
                    {`+${phoneNumbers.length - 1}`}
                  </H6>
                )}
                <PolygonIcon />
              </StyledPopupTrigger>
            }
            options={options}
          ></OptionsPopup>
        )}
      </Flex>
    </Div>
  )
}
