import React, { useReducer } from "react"
import { Log } from "oidc-client-ts"
import { AuthContext } from "setup/auth/module/auth.context"
import { AuthProps } from "setup/auth/module/constants/auth.types"
import { authInitialState } from "setup/auth/module/constants/auth.definitions"
import { authReducer } from "setup/auth/module/constants/auth.reducer"
import {
  useOidcSignInOut,
  useActions,
  useSetUser,
  useApiInterceptors,
  useCreateManager
} from "setup/auth/module/auth.hooks"

if (process.env.NODE_ENV === "development") {
  Log.setLogger(console)
  Log.setLevel(process.env.REACT_APP_OIDC_LOG_LEVEL || Log.DEBUG)
}

export const Auth = (props: AuthProps) => {
  const { config, method, api, loader } = props

  const [{ user, isLoggedIn, managerReady, manager }, dispatch] = useReducer(
    authReducer,
    authInitialState
  )

  const actions = useActions(method)
  const setUser = useSetUser(dispatch)

  const [login, logout] = useOidcSignInOut({
    manager,
    actions,
    setUser,
    method
  })

  useCreateManager({ config, dispatch })

  const authHeaderAdded = useApiInterceptors({ manager, api, logout, user })

  const readyForLoggedIn = managerReady && isLoggedIn && authHeaderAdded && user
  const readyForLoggedOut = managerReady && !isLoggedIn

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        isLoggedIn,
        manager,
        actions,
        setUser,
        method
      }}
    >
      {readyForLoggedIn || readyForLoggedOut ? props.children : loader || null}
    </AuthContext.Provider>
  )
}
