import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import ViewableSection from "components/ViewableSection"
import { PersonSectionHeaders } from "../../style"
import { messages } from "setup/messages/messages"
import PersonTeamView from "./components/PersonTeamView"
import { useSaveTeam } from "./hooks/SaveTeam"

const PersonTeam = () => {
  const { editPersonTeam, personTeamMembers } = useSaveTeam()

  return (
    <Div width="100%" mb="s">
      <ViewableSection
        view={<PersonTeamView personTeamMembers={personTeamMembers} />}
        title={
          <PersonSectionHeaders>
            {messages.person.personTeam.title}
          </PersonSectionHeaders>
        }
        editFunction={editPersonTeam}
      />
    </Div>
  )
}

export default PersonTeam
