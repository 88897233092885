import { colors, Flex } from "@ikiru/talentis-fpc"
import styled from "styled-components"
import { ReactComponent as PlusIcon } from "assets/plus-internal-search.svg"
import { ReactComponent as MinusIcon } from "assets/minus-internal-search.svg"

export const ButtonsFlex = styled(Flex)`
  margin-left: auto;
  margin-bottom: 5px;
  gap: 10px;
  flex-shrink: 0;
`

const iconStyles = `
  width: 25px !important;
  height: 25px !important;
  
  path {
    fill: ${colors.grey.dark};
  }
`

export const StyledPlusIcon = styled(PlusIcon)`
  ${iconStyles}
`

export const StyledMinusIcon = styled(MinusIcon)`
  ${iconStyles}
`
