import React, { useCallback, useRef, useState } from "react"
import { useAlert } from "setup/alert/hooks"
import { useAuth } from "setup/auth/module/auth.context"
import { browserExtensionId, isBrowserExtensionSupported } from "./helpers"
import { ChromeExternalMessageTypes, VersionMessage } from "./types"
import { RedirectToBExtAlert } from "./RedirectToBExtAlert"
import { getMinWidthMediaQuery } from "@ikiru/talentis-fpc"
import { useMediaQuery } from "utils/hooks/use-media-query"

type UseVersionCheckerParams = {
  notifyUser?: boolean
}

export const useVersionChecker = ({
  notifyUser = true
}: UseVersionCheckerParams = {}) => {
  const [version, setVersion] = useState<string>()
  const alert = useAlert()
  const alertId = useRef<string | null>(null)
  const { isLoggedIn } = useAuth()
  const mediaQuery = getMinWidthMediaQuery("sm")
  const isLarge = useMediaQuery(mediaQuery)

  const displayInfoAlert = useCallback(
    (component: JSX.Element) => {
      const id = alert.info(component, {
        closable: true,
        onClose: () => {
          alertId.current = null
        }
      })

      alertId.current = id
    },
    [alert]
  )

  const getExtensionVersion = useCallback(() => {
    if (!isLoggedIn) return

    chrome.runtime.sendMessage(
      browserExtensionId,
      { type: ChromeExternalMessageTypes.Version },
      (response: VersionMessage) => {
        if (
          (!response || response?.error) &&
          notifyUser &&
          alertId.current === null
        ) {
          displayInfoAlert(<RedirectToBExtAlert />)
        }

        if (response?.payload?.version && alertId.current !== null) {
          alert.close(alertId.current)
          alertId.current = null
        }

        setVersion(response?.payload?.version)
      }
    )
  }, [isLoggedIn, notifyUser, displayInfoAlert, alert])

  const checkIfExtensionInstalled = useCallback(() => {
    if (!isBrowserExtensionSupported()) {
      console.warn("Browser is not supporting extensions.")

      if (alertId.current === null && isLarge)
        displayInfoAlert(<RedirectToBExtAlert />)

      return
    }

    if (!browserExtensionId) {
      console.warn("Browser extension ID has not been provided")
      return
    }

    getExtensionVersion()
  }, [displayInfoAlert, getExtensionVersion, isLarge])

  return { version, checkIfExtensionInstalled }
}

export const useSignInExtension = () => {
  const silentSignInExtension = useCallback(() => {
    chrome.runtime.sendMessage(browserExtensionId, {
      type: ChromeExternalMessageTypes.SilentSignIn
    })
  }, [])

  const silentLogoutExtension = useCallback(() => {
    chrome.runtime.sendMessage(browserExtensionId, {
      type: ChromeExternalMessageTypes.SilentLogout
    })
  }, [])

  return { silentSignInExtension, silentLogoutExtension }
}

export const useRefetchExtensionUser = () => {
  const tellExtensionToRefetchUser = useCallback(() => {
    chrome.runtime.sendMessage(browserExtensionId, {
      type: ChromeExternalMessageTypes.RefetchUser
    })
  }, [])

  return { tellExtensionToRefetchUser }
}
