import { useState, useEffect } from "react"
import { apiRequest } from "setup/api/api"
import {
  ContactsEndpoints,
  CampaignsEndpoints
} from "setup/api/endpoints/endpoints"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { PersonState } from "views/persons/person-module/person-module.types"
import { Contact } from "views/campaigns/components/contacts/type"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { Campaign } from "views/campaigns/campaign.types"
import { PersonCampaignOption } from "../types"
import { formatCampaignToOption } from "../utils/format-campaign-to-option"

export const useOnCampaignLoad = () => {
  const { personId, setContacts, contacts } = usePerson()
  const [allCampaigns, setAllCampaigns] = useState<Campaign[]>([])
  const [options, setOptions] = useState<PersonCampaignOption[]>([])
  const [loading, setLoading] = useState({
    campaigns: false,
    contacts: false
  })

  useEffect(() => {
    const setAvailableCampaigns = async () => {
      const [, response] = await apiRequest.get({
        endpoint: CampaignsEndpoints.SimpleListCampaign,
        config: {
          params: { totalItemCount: 300, status: "active" },
          headers: {
            ...skipErrorHeader
          }
        }
      })

      setLoading((loading) => ({ ...loading, campaigns: false }))

      response && setAllCampaigns(response.data.simpleActiveCampaigns || [])
    }

    const setLinkedContacts = async () => {
      if (personId) {
        const [, response] = await apiRequest.get({
          endpoint: ContactsEndpoints.Root,
          config: { params: { personId, expandValues: "campaign" } }
        })

        setLoading((loading) => ({ ...loading, contacts: false }))

        if (response?.data) {
          const mappedContacts = response.data.reduce(
            (cache: PersonState["contacts"], contact: Contact) => ({
              ...cache,
              [contact.id]: contact
            }),
            {}
          )

          setContacts(mappedContacts)
        }
      } else {
        setLoading((loading) => ({ ...loading, contacts: false }))
        setContacts({})
      }
    }

    setLoading({ campaigns: true, contacts: true })

    setLinkedContacts()
    setAvailableCampaigns()
  }, [personId, setContacts])

  useEffect(() => {
    const disabledCampaignIds = Object.values(contacts).reduce(
      (cache, concat) => {
        const id = concat?.linkCampaign?.id
        return id ? [...cache, id] : cache
      },
      [] as string[]
    )

    const formatAssignmentsFunction =
      formatCampaignToOption(disabledCampaignIds)
    const formattedAssignmentList = allCampaigns.map(formatAssignmentsFunction)
    setOptions(formattedAssignmentList)
  }, [allCampaigns, contacts])

  return { loading, options }
}
