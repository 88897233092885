import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { OnAvatarDeleteType } from "views/persons/components/person-avatar/person-avatar.actions"

import { ManageExistingAvatar } from "./ManageExistingAvatar"
import { ManageFirstAvatar } from "./ManageFirstAvatar"

type ManagePersonAvatarProps = {
  actionsPending: boolean
  /** A photo has staged for upload */
  hasAvatarPreview: boolean
  /** Photo has been uploaded to the API and is attached to the person entity */
  hasSavedPhoto: boolean
  onAvatarDelete: OnAvatarDeleteType
  onUploadButtonClick: (event?: React.MouseEvent) => void
}

export const ManagePersonAvatar = (props: ManagePersonAvatarProps) => {
  const {
    hasSavedPhoto,
    hasAvatarPreview,
    onAvatarDelete,
    onUploadButtonClick,
    actionsPending
  } = props

  const hasExistingAvatar = hasSavedPhoto || hasAvatarPreview

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      {hasExistingAvatar ? (
        <ManageExistingAvatar
          actionsPending={actionsPending}
          hasSavedPhoto={hasSavedPhoto}
          hasAvatarPreview={hasAvatarPreview}
          onUploadButtonClick={onUploadButtonClick}
          onAvatarDelete={onAvatarDelete}
        />
      ) : (
        <ManageFirstAvatar onUploadButtonClick={onUploadButtonClick} />
      )}
    </Flex>
  )
}
