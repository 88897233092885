import { useCallback, useMemo } from "react"
import { useCompany } from "../company-module/company-module.context"
import { useModal } from "utils/hooks/use-modal"
import {
  CompanyDataField,
  CompanyPrimaryData,
  SectorField
} from "../components/company-details/components/constants/definitions"
import {
  patchCompanyDetails,
  postIndustries,
  putIndustries
} from "../components/company-details/components/CompanyData/actions"
import { isEmpty, omit, pick } from "lodash"
import { EditCompanyData } from "../components/company-details/components/CompanyDataModal/EditCompanyData"
import { ModalNames } from "setup/modal/modal.definitions"
import React from "react"
import { useTeam } from "views/team/team/team-module.context"

const useSubmitCompanyDetails = () => {
  const { companyDetails, setCompanyDetails, companyId } = useCompany()
  const { teamMembers } = useTeam()
  const { open, close } = useModal()
  const { speciality } = companyDetails

  const companyData = useMemo(() => {
    const pickedData = pick(companyDetails, Object.values(CompanyDataField))
    const sector = companyDetails.industries[0]?.name || ""

    return {
      ...pickedData,
      sector
    }
  }, [companyDetails])

  const onSubmitCompanyDetails = useCallback(
    async (values: CompanyPrimaryData) => {
      if (values.sector && companyDetails.industries[0]?.id) {
        const response = await putIndustries(
          companyId,
          companyDetails.industries[0].id,
          values.sector
        )

        setCompanyDetails({
          ...companyDetails,
          industries: response.industries
        })
      }

      if (values.sector && !companyDetails.industries[0]?.id) {
        const response = await postIndustries(companyId, values.sector)
        setCompanyDetails({
          ...companyDetails,
          industries: response.industries
        })
      }

      const excludeSector = omit(values, SectorField.Sector)

      if (!isEmpty(excludeSector)) {
        const response = await patchCompanyDetails(companyId, excludeSector)
        setCompanyDetails({ ...response })
      }
    },
    [companyDetails, companyId, setCompanyDetails]
  )

  const submitForm = useCallback(
    (values: CompanyPrimaryData) => {
      onSubmitCompanyDetails(values)
      close(ModalNames.EditCompanyDetails)
    },
    [onSubmitCompanyDetails, close]
  )

  const companyOpenModal = useCallback(() => {
    open(
      ModalNames.EditCompanyDetails,
      <EditCompanyData
        initialValues={companyData}
        onSubmit={submitForm}
        speciality={speciality}
        projectTeamMembers={companyDetails.teamMembers}
        teamMembers={teamMembers}
      />,
      true
    )
  }, [
    open,
    companyData,
    submitForm,
    speciality,
    companyDetails.teamMembers,
    teamMembers
  ])

  return { companyOpenModal, onSubmitCompanyDetails }
}

export default useSubmitCompanyDetails
