import styled, { css } from "styled-components"
import { styledLabelFocusStyles, StyledField } from "@ikiru/talentis-fpc"
import { H4Style as H4, TagText } from "@ikiru/talentis-fpc"
import styledCss from "@styled-system/css"

export const multiselectClassnamePrefix = "multiselect"

const wrapperClassname = `.${multiselectClassnamePrefix}`

export const StyledMultiselectInput = styled(StyledField)<{ small?: boolean }>`
  ${({ small, theme }) => small && `padding-top: ${theme.space?.xxs}px;`}
`

export const StyledMultiselectLabel = styled.span`
  ${styledCss(H4)};
  z-index: 2;
  margin: 0;

  bottom: 0;
  position: absolute;

  display: flex;
  align-items: flex-end;

  width: 100%;
  box-sizing: border-box;

  color: ${({ theme }) => theme.colors?.grey?.standard};

  padding-left: ${({ theme }) => theme.space?.xxs}px;
  padding-right: ${({ theme }) => theme.space?.s}px;
  padding-bottom: ${({ theme }) => theme.space?.xxs}px;

  margin-bottom: 3px;
`

const clearBg = css`
  &,
  &:hover {
    background: none;
  }
`

const multiValue = css`
  &__multi-value {
    ${clearBg}

    &__remove {
      padding: 0;
      display: flex;
      align-items: stretch;
      ${clearBg}
    }
  }
`

const indicators = css`
  &__indicators {
    display: none;
  }
`

const input = css`
  &__input {
    & input {
      ${styledCss(H4)};
      margin: 0;
    }
  }
`

const control = css`
  &__control {
    &--is-focused ${StyledMultiselectInput} {
      border-color: ${({ theme }) => theme.colors?.grey?.standard};
    }
    &,
    &--is-focused,
    &--menu-is-open {
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      box-shadow: none;
      ${clearBg}
    }
  }
`

const valueContainer = css`
  &__value-container {
    padding: 0;
    display: flex;
  }
`

const menu = css`
  &__menu {
    box-shadow: none;
    margin-top: 0;
    border-radius: 0;
    background: ${({ theme }) => theme.colors?.white?.standard};
  }
`

// number + half of the next
const itemsToDisplay = 7

const menuList = css`
  &__menu-list {
    padding: ${({ theme }) => theme.space?.xxs}px;

    --tag-height: ${({ theme }) =>
      `calc(${TagText.lineHeight} + ${theme.space?.xxs}px * 2)`};
    --space-between-tags: ${({ theme }) => theme.space?.xxs}px;

    max-height: calc(
      (var(--space-between-tags) + var(--tag-height)) * ${itemsToDisplay + 0.5}
    );
  }
`

const option = css`
  &__option {
    ${clearBg}
    padding: 0;
  }
`

export const StyledMultiselectWrapper = styled.label<{
  stickyLabel?: boolean
}>`
  ${wrapperClassname} {
    ${control}
    ${multiValue}
    ${indicators}
    ${input}
    ${menuList}
    ${valueContainer}
    ${menu}
    ${option}
  }

  position: relative;
  display: block;
  background: ${({ theme }) => theme.colors?.white?.standard};

  ${({ stickyLabel, theme }) =>
    stickyLabel &&
    css`
      padding-top: ${theme.space?.m}px;

      ${StyledMultiselectLabel} {
        ${styledLabelFocusStyles}
      }

      &:not(:focus-within) ${StyledMultiselectInput} {
        padding: 0;
        height: 0;
        margin-top: ${theme.space?.xxs}px;
      }
    `}

  &:focus-within {
    ${StyledMultiselectLabel} {
      ${styledLabelFocusStyles}
    }
  }
`
