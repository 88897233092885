import React from "react"
import { RouterUrl } from "setup/router/routes"
import { RouterLink } from "components/RouterLink/RouterLink"
import { Assignment } from "views/assignments/assignment.types"
import { H5, SmallText } from "@ikiru/talentis-fpc"
import { AssignmentWrapper } from "./styles"
import { Link } from "@ikiru/talentis-fpc"

const AssignmentLayout = ({
  name,
  jobTitle,
  companyName,
  companyId,
  location,
  id
}: Assignment) => (
  <RouterLink to={`${RouterUrl.AssignmentDetails}/${id}`}>
    <AssignmentWrapper>
      <Link mb="xxs" as="span">
        <H5 fontWeight="600" my="none" color="grey.dark" textAlign="left">
          {name}
        </H5>
      </Link>

      {companyId !== "00000000-0000-0000-0000-000000000000" ? (
        <Link
          onClick={(event) => event.stopPropagation()}
          fontFamily="source-sans-pro"
          fontWeight={600}
          fontSize="13px"
          underline={false}
          underlineHover={true}
          variant="green-dark"
          href={`${RouterUrl.CompanyDetails}/${companyId}`}
          target="_blank"
          size={null}
        >
          {companyName}
        </Link>
      ) : (
        <SmallText
          my="xxs"
          color="grey.darkest"
          fontWeight="600"
          textAlign="left"
        >
          {companyName}
        </SmallText>
      )}
      {location && (
        <SmallText my="none" color="grey.darkest" textAlign="left">
          {location}
        </SmallText>
      )}
    </AssignmentWrapper>
  </RouterLink>
)

export default React.memo(AssignmentLayout)
