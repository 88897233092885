import { useState, useEffect } from "react"
import { apiRequest } from "setup/api/api"
import { ProjectDocumentType } from "../types"
import { ProjectType, ProjectValues } from "../form/constants/consts"

type OnProjectDocumentsLoadProps = {
  projectId: string
  projectType: ProjectType
}
export const useOnProjectDocumentsLoad = ({
  projectId,
  projectType
}: OnProjectDocumentsLoadProps) => {
  const [isLoadingDocuments, setIsLoading] = useState(true)
  const [documents, setDocuments] = useState<ProjectDocumentType>([])

  useEffect(() => {
    const populateDocuments = async () => {
      if (projectId) {
        const [, response] = await apiRequest.get({
          endpoint: ProjectValues[projectType].rootEndpoint,
          endpointParams: projectId
        })

        setDocuments(response?.data?.files)
        setIsLoading(false)
      } else {
        setDocuments([])
        setIsLoading(false)
      }
    }
    populateDocuments()
  }, [projectId, projectType, setDocuments])

  return {
    isLoadingDocuments,
    documents,
    setDocuments
  }
}
