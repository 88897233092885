import React, { useCallback, useMemo, useEffect, useState } from "react"
import { messages } from "setup/messages/messages"
import { colors, Flex } from "@ikiru/talentis-fpc"
import { useParams } from "react-router-dom"
import CustomSelect from "../Components/CustomSelect"
import { ProjectButton, ProjectButtonLabel } from "../style"
import { Plus } from "../Components/CustomSelect/styles"
import { ProjectParams } from ".."
import { OptionsType } from "../CampaignSelector"
import { Assignment } from "views/assignments/assignment.types"

export type AssignmentSelectorProps = {
  add: Function
  isLoading: boolean
  activeAssignments: Assignment[] | undefined
  selectedAssignment: string
  setSelectedAssignment: (value: string) => void
  isAssignmentSelect: boolean | null
  fetchCampaigns: (isAdding: boolean) => void
  fetchAssignments: (isAdding: boolean) => void
}

const AssignmentSelector = ({
  add,
  isLoading,
  activeAssignments,
  selectedAssignment,
  setSelectedAssignment,
  isAssignmentSelect,
  fetchCampaigns,
  fetchAssignments
}: AssignmentSelectorProps) => {
  const { projectId: assignmentId = "" } = useParams<ProjectParams>()
  const [options, setOptions] = useState<OptionsType[]>([])

  const handleChange = useCallback(
    (event: any) => {
      setSelectedAssignment(event?.value)
      localStorage.setItem("searchAssignment", event?.value)
    },
    [setSelectedAssignment]
  )

  useEffect(() => {
    setOptions([
      {
        label: messages.talentGraphSearch.selectAssignment,
        value: "",
        disabled: true
      },
      ...(activeAssignments?.map((assignment) => ({
        label: assignment.name,
        value: assignment.id
      })) || [])
    ])
  }, [activeAssignments])

  useEffect(() => {
    if (
      assignmentId &&
      !selectedAssignment &&
      activeAssignments?.find((assignment) => assignment.id === assignmentId)
    ) {
      localStorage.setItem("searchAssignment", assignmentId)
      setSelectedAssignment(assignmentId)
    }
  }, [
    assignmentId,
    activeAssignments,
    setSelectedAssignment,
    selectedAssignment
  ])

  const selectedOption = useMemo(
    () => options.find((option) => option.value === selectedAssignment),
    [options, selectedAssignment]
  )

  return (
    <>
      {!activeAssignments?.length ? (
        <ProjectButton
          mode="standard-green"
          onClick={() => add()}
          prefix={<Plus width={15} height={15} fill={colors.white.standard} />}
        >
          <ProjectButtonLabel>
            {
              messages.talentGraphSearch.projectSelector.projectSwitch
                .assignment
            }
          </ProjectButtonLabel>
        </ProjectButton>
      ) : (
        <Flex width="100%" maxWidth="200px">
          <CustomSelect
            label={messages.talentGraphSearch.selectAssignment}
            disabled={isLoading}
            options={options}
            defaultValue={options[0]}
            value={selectedOption}
            onChange={handleChange}
            isSearchable={false}
            isAssignmentSelect={isAssignmentSelect}
            fetchCampaigns={fetchCampaigns}
            fetchAssignments={fetchAssignments}
          />
        </Flex>
      )}
    </>
  )
}

export default React.memo(AssignmentSelector)
