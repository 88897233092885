import React, { useRef } from "react"
import { PersonEmails } from "./PersonEmails"
import { messages } from "setup/messages/messages"
import { Link } from "@ikiru/talentis-fpc"
import { PersonValue } from "models/LocalPerson/localPerson.definitions"
import { TaggedEmail } from "views/persons/person.types"
import PersonContactEdit from "views/persons/components/person-details-tab/components/person-contact-info/form/PersonContactEdit"
import { useModal } from "utils/hooks/use-modal"
import useSubmitPerson from "views/persons/components/person-details-tab/hook/useSubmitPerson"
import { CreatePersonField } from "../../form/constants/definitions"
import {
  emailEmptyEntry,
  phoneNumberEmptyEntry
} from "views/persons/components/person-details-tab/components/person-contact-info/form/constants/definitions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { ModalNames } from "setup/modal/modal.definitions"

type PersonEmailListProps = {
  personId: string
  taggedEmails: PersonValue<TaggedEmail>[]
}

const PersonEmailList = ({ personId, taggedEmails }: PersonEmailListProps) => {
  const { profileDetails } = usePerson()
  const { open: openModal } = useModal()
  const { onPersonSubmit } = useSubmitPerson()
  const addPhoneNumberButtonRef = useRef<HTMLButtonElement>(null!)
  const addEmailAddressButtonRef = useRef<HTMLButtonElement>(null!)

  const buttonRefs = {
    addPhoneNumberButtonRef,
    addEmailAddressButtonRef
  }

  const editPersonEmail = (addNewEntry: boolean) => {
    const initialValues = {
      [CreatePersonField.PhoneNumbers]: profileDetails.phones.length
        ? profileDetails.phones
        : [phoneNumberEmptyEntry],
      [CreatePersonField.EmailAddresses]: profileDetails.taggedEmails.length
        ? profileDetails.taggedEmails
        : [emailEmptyEntry]
    }

    if (addNewEntry) {
      initialValues.phones.push(phoneNumberEmptyEntry)
    }

    openModal(
      ModalNames.EditPersonDetails,
      <PersonContactEdit
        onSubmit={onPersonSubmit}
        initialValues={initialValues}
        buttonRefs={buttonRefs}
        defaultEditedField={CreatePersonField.EmailAddresses}
      />
    )
  }
  return (
    <>
      {!!taggedEmails.length ? (
        <PersonEmails
          personId={personId}
          taggedEmails={taggedEmails}
          editPersonEmail={editPersonEmail}
        />
      ) : (
        <Link
          mt="xs"
          mb="none"
          rel="noopener noreferrer"
          onClick={(e) => editPersonEmail(false)}
        >
          + {messages.form.generic.addMissingNewEmail}
        </Link>
      )}
    </>
  )
}

export default PersonEmailList
