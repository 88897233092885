import styled from "styled-components"
import {
  StyledSystemProps,
  H5,
  Flex,
  spacing,
  colors
} from "@ikiru/talentis-fpc"

type TabProps = {
  isActive?: boolean
  onClick?: () => void
  index?: number
  title?: string
  disabled?: boolean
}

export const TabsContainerStyled = styled(Flex)<StyledSystemProps>`
  width: 100%;
  margin-bottom: ${spacing.xs}px;
  justify-content: flex-start;
  scroll-behavior: smooth;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`
export const TabLabel = styled(H5)<StyledSystemProps & TabProps>`
  cursor: pointer;
  margin: 0;
  width: max-content;
  white-space: nowrap;
  font-size: 16px;
  border-bottom: ${({ theme, isActive }) =>
    isActive ? `2px solid ${theme.colors.green.standard};` : `none`};
  color: ${({ theme, isActive }) =>
    isActive
      ? `${theme.colors.grey.darkest};`
      : `${theme.colors.grey.standard};`};
  ${({ title }) => (title ? `` : `flex-grow: 1; cursor: unset;`)};
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: -2px;
  &:first-child {
    padding-left: 0;
  }
`

export const TabsInnerContainer = styled(Flex)`
  border-bottom: 2px solid ${colors.grey.light};
  width: 100%;
`
