import React from "react"
import { colors, SmallText, Badge } from "@ikiru/talentis-fpc"
import { CampaignTabContainer } from "./styles"
interface CampaignTabProps {
  label: string
  count: number
  isSelected: boolean
  onClick: (label: string) => void
}

const CampaignTab = ({
  label,
  count,
  isSelected,
  onClick
}: CampaignTabProps) => {
  return (
    <CampaignTabContainer
      onClick={() => onClick(label)}
      isSelected={isSelected}
      minWidth="191px"
    >
      <Badge bg={colors.grey.standard} height={15}>
        <SmallText
          m={0}
          fontSize={13}
          fontWeight="700"
          color={colors.white.standard}
        >
          {count}
        </SmallText>
      </Badge>
      <SmallText
        fontSize={14}
        mt={5}
        color={colors.grey.dark}
        fontWeight="500"
        fontFamily="canada-type-gibson"
      >
        {label}
      </SmallText>
    </CampaignTabContainer>
  )
}

export default CampaignTab
