import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { callWithErrorMessages } from "utils/forms/forms"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { PersonConsentsFormFields, PersonConsentsHelpers } from "./form/types"

export const getOnPersonConsentsSubmit =
  (
    personId: string,
    onConsentUpdate: (consent: PersonConsentsFormFields) => void,
    beforeSubmit: (personId: string) => Promise<string>
  ) =>
  async (values: PersonConsentsFormFields, helpers: PersonConsentsHelpers) => {
    const { setFieldError, setSubmitting } = helpers
    setSubmitting(true)
    personId = await beforeSubmit(personId)
    const [error, response] = await apiRequest.put({
      endpoint: PersonsEndpoints.GDPRLawfulBasisV2,
      endpointParams: personId,
      data: {
        ...values
      },
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    setSubmitting(false)

    callWithErrorMessages(setFieldError, error)
    !error && onConsentUpdate(response?.data)
  }
