import React from "react"
import get from "lodash/get"
import { Div, Flex } from "@ikiru/talentis-fpc"
import {
  FilterType,
  CurrentPastEitherLogic
} from "views/search/SearchModule/types"
import { useSearch } from "views/search/SearchModule/context"
import { SearchCriteriaField } from "../definitions"
import { useRadioField } from "../hooks"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import { messages } from "setup/messages/messages"

type IProps = {
  filterType: FilterType | string
  fieldName: SearchCriteriaField | string
}

export const CurrentPastBothToggle = ({ filterType, fieldName }: IProps) => {
  const onChange = useRadioField(filterType)
  const { filters } = useSearch()

  const selected: any = get(filters, filterType, CurrentPastEitherLogic.Current)
  return (
    <Flex flexDirection="row" width="100%" mt="xxs">
      <Div mr="s">
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          name={fieldName}
          onChange={onChange}
          labelProps={{ ml: "xxs" }}
          value={CurrentPastEitherLogic.Current}
          checked={selected === CurrentPastEitherLogic.Current}
          label={messages.talentGraphSearch.searchLogic.current}
        />
      </Div>
      <Div mr="s">
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          name={fieldName}
          onChange={onChange}
          labelProps={{ ml: "xxs" }}
          value={CurrentPastEitherLogic.Past}
          checked={selected === CurrentPastEitherLogic.Past}
          label={messages.talentGraphSearch.searchLogic.past}
        />
      </Div>
      <Div>
        <FormikToggle
          small
          type="radio"
          hideUnderline
          notCheckedFade
          name={fieldName}
          onChange={onChange}
          labelProps={{ ml: "xxs" }}
          value={CurrentPastEitherLogic.Either}
          checked={selected === CurrentPastEitherLogic.Either}
          label={messages.talentGraphSearch.searchLogic.either}
        />
      </Div>
    </Flex>
  )
}
