import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import { TagDataField, TagsDataField } from "./definitions"

export const TagsPrimaryValidationSchema = Yup.object().shape({
  [TagsDataField.Tags]: Yup.array().of(
    Yup.object({
      [TagDataField.Name]: Yup.string()
        .max(20, messages.form.generic.errors.mustBeNoLongerThan.format(20))
        .required(messages.generic.required)
    })
  )
})
