import React, { useEffect } from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { SectionBody } from "@ikiru/talentis-fpc"
import { Loader } from "components/Loader/Loader"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { CandidatesListActions } from "./components/candidates-list-actions"
import InterviewStageCandidates from "./components/interview-stage-candidates"
import { CandidatesEmptyList } from "./components/candidates-empty-list"
import { CandidateFilters } from "./components/candidate-filters/CandidateFilters"
import { CandidateFilterContainer } from "./style"
import StageTabSection from "./components/interview-stage-tab/StageTabSection"
import { Pagination } from "components/functional/pagination/Pagination"

export type OnPageChangeProp = {
  handlePageChange: (data: number) => void
}
export const CandidatesList = React.memo(
  ({ handlePageChange }: OnPageChangeProp) => {
    const {
      isCandidatesLoading,
      paginationProps,
      selectedStage,
      totalItems,
      setSelectedTab
    } = useAssignment()

    useEffect(() => {
      setSelectedTab("candidate")
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Div
        backgroundColor="grey.lightest"
        minHeight={isCandidatesLoading ? "100%" : "50%"}
      >
        {Boolean(totalItems) && (
          <>
            <StageTabSection />
            <CandidateFilterContainer
              display="flex"
              justifyContent="space-between"
            >
              <CandidateFilters />
              <Div mt="-15px">
                <CandidatesListActions disabled={!Boolean(totalItems)} />
              </Div>
            </CandidateFilterContainer>
          </>
        )}
        {isCandidatesLoading ? (
          <Div py="m">
            <Loader />
          </Div>
        ) : Boolean(totalItems) ? (
          <>
            <Flex width="100%" flexDirection="column">
              <Div key={selectedStage}>
                <InterviewStageCandidates />
              </Div>
              {paginationProps.pageCount > 0 && (
                <Flex width="100%" justifyContent="center" mb={20}>
                  <Pagination
                    {...paginationProps}
                    onPageChange={handlePageChange}
                  />
                </Flex>
              )}
            </Flex>
          </>
        ) : (
          <SectionBody>
            <CandidatesEmptyList />
          </SectionBody>
        )}
      </Div>
    )
  }
)
