import {
  Div,
  H5,
  StyledSystemProps,
  colors,
  Flex,
  spacing,
  hexToRGB
} from "@ikiru/talentis-fpc"
import styled, { css } from "styled-components"

export type OfflimitsProps = {
  isOfflimits?: boolean
}

export const backgroundOffLimits = ({ isOfflimits = false }) => {
  if (isOfflimits) {
    return css`
      background-color: ${hexToRGB(colors.red.lightest, 0.7)};
    `
  }
}

export const StyledDiv = styled(Div)`
  background-color: ${({ theme }) => theme.colors?.grey?.lightest};
  padding: ${({ theme }) => theme.space.l}px;
  width: 775px;
`

export const OffLimitsStyled = styled(H5)<{
  isSmall: boolean
  isRotatedFlatOnSmall: boolean
}>`
  ${({ theme }) => `
  ${theme.mediaQueries.xs} {
    transform: rotate(270deg);
    transform-origin: 35px 36px;
  }
  `}

  ${({ isRotatedFlatOnSmall }) => `
  {
    transform: ${isRotatedFlatOnSmall ? "" : "rotate(270deg)"};
    transform-origin: ${isRotatedFlatOnSmall ? "" : "35px 36px"};
  }
  `}

  white-space: nowrap;
  width: 20px;
  height: 20px;
  color: ${colors.white.standard};
  margin: 0px;
  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 12px;
      padding-left: ${spacing.s}px;
    `};
`

export const StyledOfflimits = styled(Div)<{
  isCircle?: boolean
  isLeftCircle?: boolean
  isFlex?: boolean
  isRotatedFlatOnSmall?: boolean
}>`
  ${({ isFlex }) =>
    !isFlex &&
    css`
      height: 100%;
    `};

  background-color: ${({ theme }) => theme.colors?.red.standard};

  ${({ isCircle }) =>
    isCircle &&
    css`
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    `}

  ${({ isLeftCircle }) =>
    isLeftCircle &&
    css`
      border-top-left-radius: 8px;
      border-bottom-left-radius: 0px;
    `}

    ${({ isRotatedFlatOnSmall }) =>
    isRotatedFlatOnSmall &&
    css`
      @media (max-width: 576px) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0px;
      }
    `}
`

export const InheritedOffLimitsBlockStyled = styled(Flex)<StyledSystemProps>`
  background-color: ${colors.white.standard};
  border-radius: 10px;
  border: 1px solid ${colors.grey.light};
  justify-content: space-between;
  margin-bottom: ${spacing.s}px;
`

export const CompanyDiv = styled(Div)<StyledSystemProps>`
  border-right: 1px solid ${colors.grey.light};
  width: 70%;
  padding: ${spacing.xs}px;
`
