import React from "react"
import { Flex, H6, TickIcon, colors } from "@ikiru/talentis-fpc"
import { StepWrapper } from "./style"

type StepProps = {
  title: string
  handleStep: () => void
  active?: boolean
  completed?: boolean
  noMargin?: boolean
}

export const Step = ({
  title,
  active = false,
  completed = false,
  handleStep,
  noMargin
}: StepProps) => {
  return (
    <Flex minWidth="25%" width="100%" mr={noMargin ? "-5px" : 0}>
      <StepWrapper active={active} completed={completed} onClick={handleStep}>
        {completed && !active && (
          <TickIcon fill={colors.green.standard} width="13px" height="13px" />
        )}
        <H6
          m="0"
          ml="2px"
          color={
            active || completed ? colors.grey.darkest : colors.grey.standard
          }
        >
          {title}
        </H6>
      </StepWrapper>
    </Flex>
  )
}
