import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import { CreateCampaignField } from "views/campaigns/components/campaign-create-form/constants/campaign-details-form.definitions"

export const createCampaignValidationSchema = Yup.object({
  [CreateCampaignField.CampaignName]: Yup.string()
    .max(120, messages.form.generic.errors.mustBeNoLongerThan.format(120))
    .required(messages.campaign.campaingFormValidation),
  [CreateCampaignField.Description]: Yup.string().max(
    200,
    messages.form.generic.errors.mustBeNoLongerThan.format(200)
  )
})
