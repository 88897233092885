import React from "react"
import { messages } from "setup/messages/messages"
import { CreatePerson } from "views/persons/pages/create-person/CreatePerson"
import { Route, useResolvedPath } from "react-router-dom"
import { ModalOpener } from "setup/modal/ModalOpener"
import { ModalNames } from "setup/modal/modal.definitions"
import { RouterSubUrl, RouterUrl } from "setup/router/routes"
import { SearchPerson } from "./pages/search-person/SearchPerson"
import { PersonProfileWrapper } from "./pages/manage-person/PersonProfileWrapper"
import { ContactAddModal } from "views/persons/components/contact-add-modal"
import { H1 } from "@ikiru/talentis-fpc"
import { Helmet } from "react-helmet"
import { PersonProfile } from "./pages/manage-person/PersonProfile"
import { CustomSwitch } from "setup/router/CustomSwitch"
import { SearchModule } from "../contacts/SearchModule"

export const Persons = () => {
  const path = useResolvedPath("").pathname
  return (
    <>
      <CustomSwitch>
        <Route path={path} element={<H1>{messages.person.title}</H1>} />

        <Route path={RouterSubUrl.ProfileCreate} element={<CreatePerson />} />

        <Route
          path={RouterSubUrl.ProfileSearch}
          element={
            <>
              <Helmet>
                <title>
                  {messages.seo.title.format(messages.seo.contacts)}
                </title>
              </Helmet>
              <SearchModule>
                <SearchPerson />
              </SearchModule>
            </>
          }
        />

        <Route
          path={`${RouterSubUrl.ProfileDetails}/:contactId`}
          element={
            <PersonProfileWrapper>
              <PersonProfile />
            </PersonProfileWrapper>
          }
        />
        <Route
          path={RouterSubUrl.ProfileNewContact}
          element={
            <>
              <Helmet>
                <title>
                  {messages.seo.title.format(messages.seo.addNewContact)}
                </title>
              </Helmet>
              <ModalOpener
                name={ModalNames.NewContact}
                modal={<ContactAddModal />}
                returnRoute={RouterUrl.ProfileSearch}
              />
            </>
          }
        />
      </CustomSwitch>
    </>
  )
}
