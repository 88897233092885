import React from "react"
import { HeaderLink } from "components/navigation/constants/navigation.definitions"
import { LinksContainer } from "./SidebarLinks.styled"
import { SidebarLink } from "./SidebarLink"
import { Line } from "@ikiru/talentis-fpc"

type Props = {
  links: HeaderLink[]
}

export const SidebarLinks = React.memo(({ links }: Props) => (
  <LinksContainer>
    {links.map((link) => {
      return link.path === "/reports" ? (
        <>
          <Line mt="s" mb="s" />
          <SidebarLink
            key={link.path}
            {...link}
            activeLinkClassCustom="reports-link-active"
          />
        </>
      ) : (
        <SidebarLink key={link.path} {...link} />
      )
    })}
  </LinksContainer>
))
