import { tempInnerGreyModalStyles } from "setup/modal/modal.helpers"
import styled from "styled-components"
import styledCss from "@styled-system/css"
import { Flex, Div, SectionBody } from "@ikiru/talentis-fpc"

export const LoadedSearchesModalWrapper = styled.div`
  ${styledCss(tempInnerGreyModalStyles)}
  background: ${({ theme }) => theme.colors.grey.lightest};
  ${({ theme }) => `
  ${theme.mediaQueries.xs} {
    width: 100%;
  }
  ${theme.mediaQueries.sm} {
    width: 500px;
  }
  ${theme.mediaQueries.md} { 
    width: 1000px;
  }
`}
`

export const SavedSearchBox = styled(Flex)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey.dark};
  }
  margin-bottom: ${({ theme }) => theme.space.xs}px;
`

export const SaveSearchText = styled(Div)`
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.xxs}px;

  &:last-child {
    margin-bottom: ${({ theme }) => theme.space.none}px;
  }

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      margin-bottom: ${theme.space.none}px;
      padding: ${theme.space.s}px ${theme.space.xs}px;
      width: 20%;
      border-right: 1px solid ${theme.colors.grey.light};
    }
  `}

  &:last-child {
    border-right: 0px;
  }
`

export const SaveSearchDescription = styled(SaveSearchText)`
  width: 74%;
`

export const SavedSearchCreateDate = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.grey.lightTranslucent};
  padding: ${({ theme }) => theme.space.xs}px ${({ theme }) => theme.space.xs}px;
  color: ${({ theme }) => theme.colors.grey.dark};
`

export const IconContainer = styled(Div)`
  position: relative;
  bottom: -1px;
`

export const SavedSearchInfo = styled(Flex)`
  align-items: center;
  color: ${({ theme }) => theme.colors.grey.standard};
`

export const SaveSearchSectionBody = styled(SectionBody)`
  margin-top: 20px;
  max-height: 450px;
  overflow: auto;
  width: 100%;
`
