import { FieldInputProps } from "formik"

export enum UploadProjectDocumentField {
  File = "file"
}

export type UploadProjectDocumentValues = {
  [UploadProjectDocumentField.File]: FieldInputProps<File> | string
}

export const UploadProjectDocumentInitialValues: UploadProjectDocumentValues = {
  [UploadProjectDocumentField.File]: ""
}
