import React, { useCallback, useMemo, useState } from "react"
import {
  Flex,
  Button,
  colors,
  HelpIcon,
  SmallText,
  theme
} from "@ikiru/talentis-fpc"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Formik, Form } from "formik"
import {
  e2eTargets,
  FindContactFormFields,
  initialValues,
  NewContactForm
} from "./definitions"
import {
  ContactInputContainer,
  HelpIconContainer,
  HelpPopUpContainer
} from "./styles"
import { messages } from "setup/messages/messages"
import { PersonDetailsType } from "../.."
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { countries } from "utils/countries"
import {
  DEFAULT_NEW_CONTACT_COUNTRY,
  LOCALSTORAGE_DEFAULT_COUNTRY_KEY
} from "./const"
import PersonFromToggles from "./PersonFormToggles"
import MultiPersonInput from "./MultiPersonInput"
import KeyWordLogicOptions from "./keywordLogicOptions"
import { Link } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import { useNewContactModal } from "../../hooks"

type FindPersonFormProps = {
  personSearchDetails?: PersonDetailsType
  onSubmit: (data: PersonDetailsType) => void
  isEditSearchPage?: boolean
}

const FindPersonForm = ({
  onSubmit,
  personSearchDetails,
  isEditSearchPage = false
}: FindPersonFormProps) => {
  const [showTip, setShowTip] = useState(false)
  const [keywords, setKeywords] = useState<string[]>(
    personSearchDetails?.keywords || []
  )
  const [companies, setCompanies] = useState<string[]>(
    personSearchDetails?.companies || []
  )
  const [location, setLocation] = useState<string[]>(
    personSearchDetails?.location || []
  )

  const { close } = useNewContactModal()

  const handleSubmit = useCallback(
    (values: NewContactForm) => {
      const personDetails: PersonDetailsType = {
        firstName: values[FindContactFormFields.PersonFirstName],
        lastName: values[FindContactFormFields.PersonLastName],
        jobTitle: values[FindContactFormFields.PersonJobTitle],
        jobTitleLogic:
          values[FindContactFormFields.PersonJobTitleLogic] || "current",
        companies: companies,
        companyLogic:
          values[FindContactFormFields.PersonCompanyLogic] || "current",
        country: values[FindContactFormFields.PersonCountry],
        keywords: keywords,
        keywordLogic: values[FindContactFormFields.PersonKeywordLogic] || "or",
        location: location
      }

      if (values[FindContactFormFields.PersonCountry] !== "notSelected") {
        localStorage.setItem(
          LOCALSTORAGE_DEFAULT_COUNTRY_KEY,
          values[FindContactFormFields.PersonCountry]
        )
      }

      onSubmit(personDetails)
    },
    [companies, keywords, location, onSubmit]
  )

  const formikContextValue = useMemo(() => {
    const defaultLocalStorageCountry = localStorage.getItem(
      LOCALSTORAGE_DEFAULT_COUNTRY_KEY
    )

    const isLandingPage = personSearchDetails?.country
      ? personSearchDetails?.country
      : defaultLocalStorageCountry
      ? defaultLocalStorageCountry
      : DEFAULT_NEW_CONTACT_COUNTRY

    const isSearchPage = personSearchDetails?.country
      ? personSearchDetails?.country
      : "notSelected"

    const defaultCountry = isEditSearchPage ? isSearchPage : isLandingPage

    const defaultInitialValues = {
      ...initialValues,
      [FindContactFormFields.PersonFirstName]:
        personSearchDetails?.firstName || "",
      [FindContactFormFields.PersonLastName]:
        personSearchDetails?.lastName || "",
      [FindContactFormFields.PersonJobTitle]:
        personSearchDetails?.jobTitle || "",
      [FindContactFormFields.PersonJobTitleLogic]:
        personSearchDetails?.jobTitleLogic || "current",
      [FindContactFormFields.PersonCountry]: defaultCountry,
      [FindContactFormFields.PersonCompanyLogic]:
        personSearchDetails?.companyLogic || "current",
      [FindContactFormFields.PersonKeywordLogic]:
        personSearchDetails?.keywordLogic || "or"
    }

    return {
      initialValues: defaultInitialValues,
      onSubmit: handleSubmit
    }
  }, [handleSubmit, isEditSearchPage, personSearchDetails])

  const isValidForm = (values: NewContactForm) => {
    let isNameValid = 0
    if (
      values[FindContactFormFields.PersonFirstName] ||
      values[FindContactFormFields.PersonLastName]
    ) {
      isNameValid = 1
    }

    const singularValues = Object.entries(values).filter(([key, value]) => {
      if (
        key === FindContactFormFields.PersonFirstName ||
        key === FindContactFormFields.PersonLastName ||
        key === FindContactFormFields.PersonCountry ||
        key === FindContactFormFields.PersonCompanyLogic ||
        key === FindContactFormFields.PersonJobTitleLogic ||
        key === FindContactFormFields.PersonKeywordLogic ||
        key === FindContactFormFields.PersonCompany
      ) {
        return false
      }
      return true
    })
    let isValid =
      singularValues.filter(([key, value]) => Boolean(value)).length || 0

    return isValid + isNameValid + keywords.length + companies.length >= 1
  }

  return (
    <Formik {...formikContextValue}>
      {({ errors, values, setFieldValue }) => {
        const onChangeToggle = (field: string, newValue: string) => {
          setFieldValue(field, newValue)
        }
        return (
          <Form>
            <Flex
              alignItems="flex-start"
              flexWrap="wrap"
              width="100%"
              mt="xxs"
              justifyContent="flex-start"
            >
              <ContactInputContainer isEditSearchPage={isEditSearchPage}>
                <FormikInput
                  data-e2e-target-name={e2eTargets.personNameInput}
                  id={FindContactFormFields.PersonFirstName}
                  name={FindContactFormFields.PersonFirstName}
                  label={`${messages.form.generic.firstName}`}
                  hideErrorMessage
                  clearable
                />
              </ContactInputContainer>
              <ContactInputContainer isEditSearchPage={isEditSearchPage}>
                <FormikInput
                  data-e2e-target-name={e2eTargets.personNameInput}
                  id={FindContactFormFields.PersonLastName}
                  name={FindContactFormFields.PersonLastName}
                  label={`${messages.form.generic.lastName}`}
                  hideErrorMessage
                  clearable
                />
              </ContactInputContainer>

              <ContactInputContainer isEditSearchPage={isEditSearchPage}>
                <FormikInput
                  data-e2e-target-name={e2eTargets.personNameInput}
                  id={FindContactFormFields.PersonJobTitle}
                  name={FindContactFormFields.PersonJobTitle}
                  label={`${messages.form.generic.jobTitle}`}
                  hideErrorMessage
                  clearable
                />
                <PersonFromToggles
                  currentValue={
                    values[FindContactFormFields.PersonJobTitleLogic]
                  }
                  fieldName={FindContactFormFields.PersonJobTitleLogic}
                  onChangeToggle={onChangeToggle}
                />
              </ContactInputContainer>
              <ContactInputContainer isEditSearchPage={isEditSearchPage}>
                <MultiPersonInput
                  name={FindContactFormFields.PersonCompany}
                  label={`${messages.form.generic.company}`}
                  inputList={companies}
                  onChange={(value) => {
                    setCompanies(value)
                    setFieldValue(FindContactFormFields.PersonCompany, "")
                  }}
                />
                <PersonFromToggles
                  currentValue={
                    values[FindContactFormFields.PersonCompanyLogic]
                  }
                  fieldName={FindContactFormFields.PersonCompanyLogic}
                  onChangeToggle={onChangeToggle}
                />
              </ContactInputContainer>
              <ContactInputContainer
                isFullLength
                isEditSearchPage={isEditSearchPage}
              >
                <MultiPersonInput
                  name={FindContactFormFields.PersonKeywords}
                  label={messages.talentGraphSearch.filters.keywords}
                  placeholder={messages.talentGraphSearch.placeholders.keywords}
                  inputList={keywords}
                  onChange={(value) => {
                    setKeywords(value)
                    setFieldValue(FindContactFormFields.PersonKeywords, "")
                  }}
                />
                <KeyWordLogicOptions
                  fieldName={FindContactFormFields.PersonKeywordLogic}
                  keywordLogic={
                    values[FindContactFormFields.PersonKeywordLogic]
                  }
                  flexDirection="row"
                  marginRightForEach="m"
                />
              </ContactInputContainer>
              <Flex flexDirection="column" width="100%" mt="s">
                <Flex width="100%" justifyContent="space-between">
                  <SmallText color={colors.grey.dark} fontWeight="500">
                    {messages.person.contact.findPeople.mayHaveLinks}
                  </SmallText>
                  <HelpIconContainer
                    onClick={() => setShowTip((state) => !state)}
                  >
                    <HelpIcon fill={theme.colors.yellow.light} />

                    {showTip && (
                      <HelpPopUpContainer>
                        <SmallText>
                          {messages.person.contact.findPeople
                            .locationHelpTipStart + " "}
                          <span>
                            <Link to={RouterUrl.Search} onClick={close}>
                              {
                                messages.person.contact.findPeople
                                  .advancedSearch
                              }
                            </Link>
                          </span>
                          {" " +
                            messages.person.contact.findPeople
                              .locationHelpTipEnd}
                        </SmallText>
                      </HelpPopUpContainer>
                    )}
                  </HelpIconContainer>
                </Flex>
                <Flex
                  width="100%"
                  flexWrap="wrap"
                  alignItems="flex-start"
                  mt={isEditSearchPage ? "-18px" : "-15px"}
                >
                  <ContactInputContainer isEditSearchPage={isEditSearchPage}>
                    <FormikSelect
                      data-e2e-target-name={e2eTargets.personNameInput}
                      id={FindContactFormFields.PersonCountry}
                      name={FindContactFormFields.PersonCountry}
                      label={`${messages.form.generic.country}`}
                      options={countries}
                    />
                  </ContactInputContainer>

                  <ContactInputContainer isEditSearchPage={isEditSearchPage}>
                    <MultiPersonInput
                      name={FindContactFormFields.PersonLocation}
                      label={
                        messages.person.contact.findPeople.cityStateCountry
                      }
                      inputList={location}
                      onChange={(value) => {
                        setLocation(value)
                        setFieldValue(FindContactFormFields.PersonLocation, "")
                      }}
                    />
                  </ContactInputContainer>
                </Flex>
              </Flex>
            </Flex>
            <Button
              data-e2e-target-name={e2eTargets.submitButton}
              disabled={!isValidForm(values)}
              onClick={() => {
                handleSubmit(values)
              }}
              type="submit"
              size="standard"
              mx="auto"
              my="m"
              width="200px"
            >
              {messages.person.contact.form.findPerson}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default FindPersonForm
