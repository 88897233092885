import React from "react"
import { messages } from "setup/messages/messages"
import { LargeText, Flex, BodyText } from "@ikiru/talentis-fpc"

interface NoTasksProps {
  date: string
}

export const NoTasks = ({ date }: NoTasksProps) => {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      mt="xs"
      textAlign="left"
      pl="14px"
    >
      <LargeText color="grey.dark">{messages.taskManagement.noTask}</LargeText>
      <BodyText mt="xxs" color="grey.standard">
        {messages.taskManagement.noTaskText.format(date)}
      </BodyText>
    </Flex>
  )
}
