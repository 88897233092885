import noop from "lodash/noop"
import { createContext, useContext } from "react"
import { ClientPortalConfigurationContextValues } from "./types"

const initialValues = {
  photos: {},
  candidates: [],
  assignment: null,
  groupedCandidates: null,
  isAssignmentLoading: false,
  isCandidatesLoading: false,
  updateCandidateNote: noop,
  toggleCandidatesInClientView: noop,
  toggleCandidatesShowCommentaryInClientView: noop
}

export const ClientPortalConfigurationContext =
  createContext<ClientPortalConfigurationContextValues>(initialValues)

export const useClientPortalConfiguration = () =>
  useContext(ClientPortalConfigurationContext)
