import React, { useMemo } from "react"
import { Flex, H4, Line, Loader } from "@ikiru/talentis-fpc"
import TopBar from "./components/TopBar"
import SubBar from "./components/SubBar"
import StageCandidatesSection from "./components/StageCandidatesSection"
import { messages } from "setup/messages/messages"
import { useClientPortalConfiguration } from "./module/context"
import { getActiveInterviewStage } from "../utils"
import { InterviewProgressStage } from "../components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { StyledSection } from "./style"

const ClientPortalConfiguration = () => {
  const { isCandidatesLoading, candidates } = useClientPortalConfiguration()
  const selectedCandidatesCount = useMemo(
    () => candidates.filter((candidate) => candidate.showInClientView).length,
    [candidates]
  )

  return (
    <Flex style={{ overflowY: "hidden", flex: 1 }} flexDirection="column">
      <TopBar />
      <SubBar />
      <Line />
      <Flex style={{ overflowY: "auto" }} px={0} flexDirection="column">
        <StyledSection
          header={{
            size: "xxSmall",
            title: messages.clientPortal.candidates,
            itemsCount: messages.clientPortal.selectedCandidates.format(
              selectedCandidatesCount,
              candidates.length
            ),
            titleProps: {
              as: H4,
              my: 0,
              pt: 0
            }
          }}
          // @ts-ignore
          expandable
          bodyProps={{
            px: "none",
            pt: "xxs",
            pb: "none"
          }}
        >
          {isCandidatesLoading ? (
            <Loader />
          ) : (
            getActiveInterviewStage(candidates).map(
              (stage: InterviewProgressStage) => (
                <StageCandidatesSection key={stage} {...{ stage }} />
              )
            )
          )}
        </StyledSection>
      </Flex>
    </Flex>
  )
}

export default ClientPortalConfiguration
