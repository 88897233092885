import React, { useState } from "react"
import { OnAvatarDeleteType } from "views/persons/components/person-avatar/person-avatar.actions"

import { DeleteAvatarView } from "./DeleteAvatarView"
import { EditAvatarView } from "./EditAvatarView"

type ManageExistingAvatarProps = {
  actionsPending: boolean
  /** A photo has staged for upload */
  hasAvatarPreview?: boolean
  /** photo has been uploaded to the API and is attached to the person entity */
  hasSavedPhoto: boolean
  onUploadButtonClick: (event?: React.MouseEvent) => void
  onAvatarDelete: OnAvatarDeleteType
}

export const ManageExistingAvatar = (props: ManageExistingAvatarProps) => {
  const {
    actionsPending,
    hasAvatarPreview,
    onUploadButtonClick,
    onAvatarDelete,
    hasSavedPhoto
  } = props

  const [deletePressed, setDeletePressed] = useState(false)

  return deletePressed ? (
    <DeleteAvatarView
      actionsPending={actionsPending}
      onCancelClick={() => setDeletePressed(false)}
      onConfirmClick={onAvatarDelete}
    />
  ) : (
    <EditAvatarView
      actionsPending={actionsPending}
      onUploadButtonClick={onUploadButtonClick}
      onDeleteButtonClick={() => setDeletePressed(true)}
      hasAvatarPreview={hasAvatarPreview}
      hasSavedPhoto={hasSavedPhoto}
    />
  )
}
