import React from "react"
import { colors, SmallText, Badge, Div } from "@ikiru/talentis-fpc"
import { TabContainer, TabContainerRow } from "./style"
import { capitalizeFirstLetter } from "views/search/SearchModule/utils"
import { hexToRGB } from "utils/hexToRGB"

interface TabCardProps {
  handleClick: (label: string) => void
  isSelected: boolean
  count: number
  label: string
  width?: string
  itemId: string
  isRowTab?: boolean
  isNotArchive?: boolean
}

const TabCard = ({
  handleClick,
  isSelected,
  count,
  label,
  width = "142px",
  isRowTab = false,
  isNotArchive = true
}: TabCardProps) => {
  const Tabs = isRowTab ? TabContainerRow : TabContainer
  return (
    <Tabs
      onClick={() => handleClick(label)}
      isSelected={isSelected}
      width={width}
    >
      {isNotArchive ? (
        <Badge bg={colors.grey.standard} height={15}>
          <SmallText
            m={0}
            fontSize={isRowTab ? 13 : 10}
            fontWeight={isRowTab ? "700" : "600"}
            color={colors.white.standard}
          >
            {count}
          </SmallText>
        </Badge>
      ) : (
        <Div height={15} mb={5}></Div>
      )}

      <SmallText
        fontSize={isRowTab ? 16 : 14}
        my={isNotArchive ? 5 : 15}
        mx={isRowTab ? "6px" : "none"}
        color={hexToRGB(colors.grey.dark, isNotArchive ? 1 : 0.5)}
        fontWeight="500"
        fontFamily="canada-type-gibson"
      >
        {capitalizeFirstLetter(label)}
      </SmallText>
    </Tabs>
  )
}

export default TabCard
