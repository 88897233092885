import React from "react"
import { Flex, LargeText } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { SearchCard } from "components/SearchCard"
import Dataentry from "assets/dataentry.svg"
import ParseCV from "assets/parse_CV.svg"
import Socialmedia from "assets/socialmedia.svg"
import ParseTextImg from "assets/parse_text.svg"
import { ModalNames } from "setup/modal/modal.definitions"
import { CVParsingModal } from "../../cv-parsing/CVParsingModal"
import { useModal } from "utils/hooks/use-modal"
import { ParseSocialMedia } from "./ParseSocialMedia"
import { ParseText } from "./ParseText"

type EmptyExperienceProps = {
  isLinkToTalentGraph: boolean
  addManually: () => void
}
export const EmptyExperience = ({
  isLinkToTalentGraph,
  addManually
}: EmptyExperienceProps) => {
  const { openInPortal } = useModal()

  const openCV = () => {
    openInPortal(ModalNames.CVParsing, <CVParsingModal />)
  }

  const openParseSocialMedia = () => {
    openInPortal(ModalNames.ParseSocialMedia, <ParseSocialMedia />)
  }

  const openParseText = () => {
    openInPortal(ModalNames.ParseText, <ParseText />)
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      maxWidth="60%"
      alignItems="center"
      m="auto"
      textAlign="center"
      py="xl"
    >
      <LargeText mt="none" mb="s" color="grey.dark" fontWeight={600}>
        {messages.person.experience.titleEmptyRecord}
      </LargeText>
      <Flex>
        <SearchCard
          cardImg={ParseCV}
          title={messages.person.experience.fillByParseCVTitle}
          description={messages.person.experience.fillByParseCVDescription}
          onSelectCard={openCV}
        />
        <SearchCard
          cardImg={ParseTextImg}
          title={messages.person.experience.parseTextTitle}
          description={messages.person.experience.parseTextDescription}
          onSelectCard={openParseText}
        />
        {!isLinkToTalentGraph && (
          <SearchCard
            cardImg={Socialmedia}
            title={messages.person.experience.fillBySocialMediaTitle}
            description={
              messages.person.experience.fillBySocialMediaDescription
            }
            onSelectCard={openParseSocialMedia}
          />
        )}
        <SearchCard
          cardImg={Dataentry}
          title={messages.person.experience.fillManuallyTitle}
          description={messages.person.experience.fillManuallyDescription}
          onSelectCard={addManually}
        />
      </Flex>
    </Flex>
  )
}
