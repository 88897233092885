import React from "react"
import { Flex, SmallText, colors, fontFamilies } from "@ikiru/talentis-fpc"
import { StyledBadge } from "../styles"

type StagesDetailsProps = {
  count: number
  name: string
}

export const StagesDetails = ({ name, count }: StagesDetailsProps) => {
  return (
    <Flex pr="s" alignItems="center" lineHeight={0}>
      <SmallText
        mb="none"
        color={colors.green.dark}
        fontFamily={fontFamilies.gibson}
        fontWeight={500}
      >
        {name}
      </SmallText>
      <StyledBadge>{count}</StyledBadge>
    </Flex>
  )
}
