import React from "react"
import {
  NestedInputContainer,
  SubHeaderText,
  ToggleCheckBoxStyle
} from "./styles"
import { Div, Select } from "@ikiru/talentis-fpc"
import { FormikArrayField } from "components/functional/formik/formik-array-field/FormikArrayField"
import { CreatePersonField } from "./constants/definitions"
import { DefaultEditedField } from "components/EditableDetails/editable-details.types"
import { messages } from "setup/messages/messages"

type SelectProp = {
  nestedKey: string
  ComponentProp:
    | Record<string, any>
    | ((name: string, index: number) => Record<string, any>)
}

type InputProp = {
  nestedKey: string
  onChange?: (
    event: React.SyntheticEvent,
    fieldName: string,
    index: number
  ) => void
  ComponentProp:
    | Record<string, any>
    | ((name: string, index: number) => Record<string, any>)
}

type ToggleProps = {
  nestedKey: string
  ComponentProp:
    | Record<string, any>
    | ((name: string, index: number) => Record<string, any>)
}

type NestedInputsProps = {
  label: string
  e2eTargets: any
  noPrefferedHeader?: boolean
  inputName: CreatePersonField
  selectProps: SelectProp
  inputProps: InputProp
  toggleProps: ToggleProps
  defaultEditedField?: DefaultEditedField
  inputInitialValue: any
}

const NestedInputs = (props: NestedInputsProps) => {
  const {
    label,
    e2eTargets,
    inputName,
    selectProps,
    inputProps,
    toggleProps,
    defaultEditedField = "",
    inputInitialValue,
    noPrefferedHeader = false
  } = props
  return (
    <NestedInputContainer>
      <Div>
        <FormikArrayField
          id={inputName}
          name={inputName}
          e2eTarget={e2eTargets}
          label={label}
          displayAddMoreBtn={false}
          displayActionBtn={false}
          nestedKey={selectProps.nestedKey}
          component={Select}
          ComponentProps={selectProps.ComponentProp}
        />
      </Div>
      <Div>
        <FormikArrayField
          autoFocusFirstElement={defaultEditedField === inputName}
          id={inputName}
          name={inputName}
          initialValue={inputInitialValue}
          e2eTarget={e2eTargets}
          onChange={inputProps.onChange}
          displayAddMoreBtn={false}
          displayActionBtn={false}
          nestedKey={inputProps.nestedKey}
          ComponentProps={inputProps.ComponentProp}
        />
      </Div>
      <Div mt={noPrefferedHeader ? 0 : -25} position="relative">
        <FormikArrayField
          id={inputName}
          name={inputName}
          initialValue={inputInitialValue}
          e2eTarget={e2eTargets}
          displayAddMoreBtn={false}
          displayActionBtn={false}
          nestedKey={toggleProps.nestedKey}
          SubHeader={() =>
            !noPrefferedHeader ? (
              <SubHeaderText>{messages.form.generic.preferred}</SubHeaderText>
            ) : (
              <></>
            )
          }
          component={ToggleCheckBoxStyle}
          inputContainerStyle={{
            pl: "25px",
            minHeight: "50px",
            justifyContent: "center",
            alignItems: "center"
          }}
          ComponentProps={toggleProps.ComponentProp}
        />
      </Div>
    </NestedInputContainer>
  )
}

export default NestedInputs
