import { AxiosError } from "axios"

type CallWithErrorMessages = (
  callback: (field: string, message: string) => void,
  errorResponse: AxiosError["response"] | undefined
) => void

export const callWithErrorMessages: CallWithErrorMessages = (
  callback,
  error
) => {
  error &&
    error?.data?.errors &&
    Object.entries(error?.data?.errors).forEach((entry) => {
      const [key, value] = entry as [string, string[]]
      callback(key.firstCharToLowerCase(), value.join(" "))
    })
}

export const serializeAllErrorMessages = (
  error: AxiosError["response"] | undefined,
  joinBy = "\\n"
) => {
  const errorsList = error?.data.errors as { [key: string]: string[] }
  const message = Object.values(errorsList)
    .map((entry) => entry.join(joinBy))
    .join(joinBy)
  return message
}
