import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { CountsDetails } from "../../types"
import { CompanyPersonProjectDetails } from "./CompanyPersonProjectDetails"

type ProjectsDetailsProps = {
  counts: CountsDetails[]
  onClick?: () => void
}

export const ProjectsDetails = ({ counts, onClick }: ProjectsDetailsProps) => {
  return (
    <Flex justifyContent="stretch" flexWrap="wrap" onClick={onClick}>
      {counts?.map(({ name, count }) => (
        <CompanyPersonProjectDetails key={name} name={name} count={count} />
      ))}
    </Flex>
  )
}
