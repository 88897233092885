import { useState, useCallback } from "react"
import { saveAs } from "file-saver"
import { apiRequest } from "setup/api/api"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import { messages } from "setup/messages/messages"
import { formatDate } from "utils/format-date"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

type UseExportCandidatesParams = {
  assignmentId: string
  assignmentName: string
  totalItems: number
}

export const useExportCandidates = ({
  assignmentId,
  assignmentName,
  totalItems
}: UseExportCandidatesParams) => {
  const [isExporting, setIsExporting] = useState(false)
  const [isError, setIsError] = useState(false)

  const exportCandidates = useCallback(
    async (candidateIds?: any) => {
      setIsExporting(true)
      setIsError(false)

      const requestData = {
        endpoint: AssignmentsEndpoints.ExportCandidates,
        endpointParams: {
          id: assignmentId
        },
        data: candidateIds || undefined,
        config: {
          params: { pageSize: totalItems },
          responseType: "blob" as "blob",
          headers: {
            ...skipErrorHeader
          }
        }
      }

      const [error, response] = await (candidateIds
        ? apiRequest.post(requestData)
        : apiRequest.get(requestData))

      if (Boolean(error)) {
        setIsError(true)
        setIsExporting(false)
        return
      }

      const today = new Date()
      const fileName = `${
        messages.assignment.details.candidates.exportPrefix
      }_${assignmentName}_${formatDate(today)}.csv`

      saveAs(response?.data, fileName)
      setIsExporting(false)
    },
    [assignmentId, assignmentName, totalItems]
  )

  return {
    isExporting,
    isError,
    exportCandidates,
    setIsExporting
  }
}
