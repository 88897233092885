import React from "react"
import { Route } from "react-router-dom"
import { CustomSwitch } from "setup/router/CustomSwitch"
import { SubscriptionPurchase } from "./pages/purchase"
import { SubscriptionPurchaseModule } from "./subscription-purchase-module/SubscriptionPurchaseModule"

export const Subscription = () => {
  return (
    <>
      <CustomSwitch>
        <Route
          path="/"
          element={
            <SubscriptionPurchaseModule>
              <SubscriptionPurchase />
            </SubscriptionPurchaseModule>
          }
        />
      </CustomSwitch>
    </>
  )
}
