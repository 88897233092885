import { messages } from "setup/messages/messages"
import { Company } from "views/companies/company-module/company-module.types"

export enum CompanyDataField {
  Name = "name",
  Size = "size",
  YearFounded = "yearFounded"
}

export enum SectorField {
  Sector = "sector"
}

export enum SpecialityField {
  Speciality = "speciality"
}

export type CompanyPrimaryData = Pick<
  Company,
  "name" | "yearFounded" | "size"
> & {
  sector?: Company["industries"][0]["name"]
}

export type CompanySizeOption = {
  label: string
  value: CompanySize
}

export enum CompanySize {
  VerySmall = "verySmall",
  Small = "small",
  Medium = "medium",
  Large = "large",
  VeryLarge = "veryLarge",
  Unknown = "unknown"
}

export const companyPrimaryDataInitialValues: CompanyPrimaryData = {
  [CompanyDataField.Name]: "",
  [CompanyDataField.Size]: CompanySize.Unknown,
  [CompanyDataField.YearFounded]: 2000,
  [SectorField.Sector]: ""
}

const verySmall: CompanySizeOption = {
  value: CompanySize.VerySmall,
  label: messages.companies.companyInfo.size[CompanySize.VerySmall]
}

const small: CompanySizeOption = {
  value: CompanySize.Small,
  label: messages.companies.companyInfo.size[CompanySize.Small]
}
const medium: CompanySizeOption = {
  value: CompanySize.Medium,
  label: messages.companies.companyInfo.size[CompanySize.Medium]
}
const large: CompanySizeOption = {
  value: CompanySize.Large,
  label: messages.companies.companyInfo.size[CompanySize.Large]
}

const veryLarge: CompanySizeOption = {
  value: CompanySize.VeryLarge,
  label: messages.companies.companyInfo.size[CompanySize.VeryLarge]
}

const unknown: CompanySizeOption = {
  value: CompanySize.Unknown,
  label: messages.companies.companyInfo.size[CompanySize.Unknown]
}

export const companySize = [unknown, verySmall, small, medium, large, veryLarge]
