import { useMemo, useState } from "react"
import { Task } from "views/homepage/task-management-module/task-management-module.types"
import { usePerson } from "views/persons/person-module/candiate-module.context"

type candidateOrder = {
  handlePageChange: (data: number) => void
}

export const useChangeItem = (
  { handlePageChange }: candidateOrder,
  pageNumber: number,
  pageSize: number,
  totalCount: number,
  items: Task[],
  selectedRecordId: string,
  setSelectedRecordId: (index: string) => void,
  totalPagesParam?: number
) => {
  const [isFirstElement, setIsFirstElement] = useState(false)
  const [isLastElement, setisLastElement] = useState(false)
  const [candidateLocationState, setCandidateLocationState] = useState(-1)
  const totalPages = totalPagesParam || Math.ceil(totalCount / pageSize)
  const { getProfileData } = usePerson()
  const directionNext = () => changeCandidate(1)
  const directionPrevious = () => changeCandidate(-1)

  const candidatePosition = useMemo(() => {
    const candidateIdList: string[] = items.map(
      (task: { personId?: string; id?: string }) =>
        task.personId || task.id || ""
    )
    const loc = candidateIdList.indexOf(selectedRecordId)

    if (loc > -1) setCandidateLocationState(loc)
    return loc
  }, [selectedRecordId, items])

  const changeCandidate = (direction: number) => {
    setIsFirstElement(false)
    setisLastElement(false)
    const candidateIdList: string[] = items.map(
      (task: { personId?: string; id?: string }) =>
        task.personId || task.id || ""
    )

    let candidateLocation = candidatePosition

    let newCandidateLocation
    if (candidateLocation === -1) {
      newCandidateLocation = candidateLocationState
    } else {
      newCandidateLocation = candidateLocation + direction
    }
    if (
      !(newCandidateLocation < 0) &&
      !(newCandidateLocation > candidateIdList.length - 1)
    ) {
      setSelectedRecordId(candidateIdList[newCandidateLocation])
      getProfileData(candidateIdList[newCandidateLocation])
    } else if (totalPages > 1) {
      if (pageNumber < totalPages && direction === 1) {
        setisLastElement(true)
        handlePageChange(pageNumber + 1)
      } else if (pageNumber > 1 && direction === -1) {
        setIsFirstElement(true)
        handlePageChange(pageNumber - 1)
      }
    }
  }

  return { directionNext, directionPrevious, isFirstElement, isLastElement }
}
