import React, { useMemo, useState } from "react"
import AsyncSelect from "react-select/async"
import {
  multiselectClassnamePrefix,
  StyledMultiselectWrapper,
  StyledMultiselectLabel
} from "components/Multiselect/styles"
import {
  updatedComponents,
  getPossiblyHiddenResultComponents
} from "./components"
import { FormFieldStatus } from "@ikiru/talentis-fpc"
import { TagVariants } from "@ikiru/talentis-fpc"
import { GroupBase, OnChangeValue } from "react-select"
import { SelectComponents } from "react-select/dist/declarations/src/components"

export type MultiselectValue = { label: string; value: string }

export type MultiselectProps<T> = {
  id?: string
  label?: React.ReactNode
  onChange?: (value: any) => void
  onBlur?: (event: React.SyntheticEvent) => void
  onFocus?: (event: React.SyntheticEvent) => void
  onKeyDown?: (event: React.SyntheticEvent) => void
  tabIndex?: number
  defaultValue?: OnChangeValue<T, true>
  value?: OnChangeValue<T, true>
  status?: FormFieldStatus
  ref?: any
  loadOptions: (inputValue: string) => Promise<T[]>
  validateRequest?: (value: string) => boolean
  tagVariant?: TagVariants
} & Omit<
  React.HTMLProps<HTMLInputElement>,
  "onChange" | "onBlur" | "tabIndex" | "value" | "defaultValue" | "onFocus"
>

export const Multiselect = React.forwardRef(
  <T extends any>(props: MultiselectProps<T>, ref: any) => {
    const {
      id,
      label,
      onChange: onChangeFromProps,
      value,
      defaultValue,
      validateRequest: validateInput = () => true,
      ...other
    } = props

    const [shouldHideResults, setShouldHideResults] = useState(true)
    const shouldMoveLabel = useMemo(
      () => Boolean(value || defaultValue),
      [value, defaultValue]
    )

    const labelId = `${id}-multiselect-label`
    const customStyles: any = {
      input: (provided: any) => ({
        ...provided,
        margin: "0px",
        padding: "0px"
      })
    }
    //if your going to add cacheOptions please set default value as false
    return (
      <StyledMultiselectWrapper stickyLabel={shouldMoveLabel}>
        <StyledMultiselectLabel id={labelId}>{label}</StyledMultiselectLabel>
        <AsyncSelect
          {...other}
          ref={ref}
          value={value}
          defaultValue={defaultValue}
          inputId={id}
          isMulti
          menuIsOpen={!shouldHideResults}
          classNamePrefix={multiselectClassnamePrefix}
          onChange={(value) => {
            onChangeFromProps?.(value)
          }}
          styles={customStyles}
          placeholder={false}
          components={
            {
              ...updatedComponents,
              ...getPossiblyHiddenResultComponents(shouldHideResults)
            } as Partial<SelectComponents<T, true, GroupBase<T>>>
          }
          onInputChange={(value: string) => {
            setShouldHideResults(validateInput(value))
          }}
          closeMenuOnSelect={false}
        />
      </StyledMultiselectWrapper>
    )
  }
)
