import React, { useCallback, useMemo, Dispatch } from "react"
import { messages } from "setup/messages/messages"
import {
  Flex,
  Button,
  HideIcon,
  ShowIcon,
  H6,
  Div,
  Link,
  PositiveIcon
} from "@ikiru/talentis-fpc"
import { useSearch } from "views/search/SearchModule/context"
import { SearchPerson } from "views/search/SearchModule/types"
import {
  AssignmentsEndpoints,
  CampaignsEndpoints
} from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import ErrorModal from "./errorModal"
import { IgnoreButton } from "./styles"
import { RouterUrl } from "setup/router/routes"

type IProps = {
  person?: SearchPerson
  assignmentIds: string[]
  campaignIds: string[]
  ignoredPersonArray: string[]
  setIgnoredPersonArray: Dispatch<string[]>
  isIgnored: boolean
}

const PersonAssignment = ({
  person,
  assignmentIds,
  ignoredPersonArray,
  setIgnoredPersonArray,
  isIgnored,
  campaignIds = []
}: IProps) => {
  const {
    selectedAssignment,
    activeAssignments,
    handleLocalPerson,
    handleDataPoolPerson,
    isAssignmentSelect,
    selectedCampaign,
    activeCampaigns
  } = useSearch()
  const { open: openModal } = useModal()

  const noSelectedAssignment = () => {
    return openModal(
      ModalNames.NoSelectedAssignmentOrCandidate,
      <ErrorModal isAssignmentSelected={isAssignmentSelect} />
    )
  }

  const handleClick = useCallback(() => {
    if (!person) return

    const { dataPoolId, personId } = person

    !!personId
      ? handleLocalPerson(personId, dataPoolId)
      : handleDataPoolPerson(dataPoolId)
  }, [handleDataPoolPerson, handleLocalPerson, person])

  const personLinkedToAssignment = useMemo(
    () => assignmentIds.includes(selectedAssignment),
    [assignmentIds, selectedAssignment]
  )

  const personLinkedToCampaign = useMemo(
    () => campaignIds.includes(selectedCampaign),
    [campaignIds, selectedCampaign]
  )

  const assignment = useMemo(
    () =>
      activeAssignments?.find(
        (assignment) => assignment.id === selectedAssignment
      ),
    [activeAssignments, selectedAssignment]
  )

  const campaign = useMemo(
    () => activeCampaigns?.find((campaign) => campaign.id === selectedCampaign),
    [activeCampaigns, selectedCampaign]
  )

  const ignoreCandidate = () => {
    const isIgnoredFromAssignment = ignoredPersonArray?.includes(
      person?.dataPoolId!
    )
    if (isAssignmentSelect) {
      if (!selectedAssignment) {
        return noSelectedAssignment()
      }
    } else {
      if (!selectedCampaign) {
        return noSelectedAssignment()
      }
    }
    apiRequest.put({
      endpoint: isAssignmentSelect
        ? AssignmentsEndpoints.IgnoredCandidates
        : CampaignsEndpoints.IgnoredContacts,
      endpointParams: {
        ...(isAssignmentSelect
          ? { assignmentId: selectedAssignment }
          : { campaignId: selectedCampaign }),
        personId: person?.dataPoolId
      },
      data: {
        ignorePerson: !isIgnoredFromAssignment
      }
    })
    if (isIgnoredFromAssignment) {
      setIgnoredPersonArray(
        ignoredPersonArray.filter(
          (personId: string) => personId !== person?.dataPoolId
        )
      )
    } else {
      setIgnoredPersonArray(ignoredPersonArray.concat([person?.dataPoolId!]))
    }
  }

  const personIsAssigned = () => {
    return isAssignmentSelect ? selectedAssignment : selectedCampaign
  }
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
    >
      <Flex flexDirection="column" alignItems="center">
        <Div opacity={isIgnored ? 0.2 : 1} mb="l">
          {!personIsAssigned() ? (
            <H6
              textAlign="center"
              my="none"
              fontWeight={500}
              color={isAssignmentSelect ? "green.dark" : "grey.standard"}
            >
              {isAssignmentSelect
                ? messages.talentGraphSearch.selectAssignmentToLink
                : messages.talentGraphSearch.selectCampaignToLink}
            </H6>
          ) : (
              isAssignmentSelect
                ? !personLinkedToAssignment
                : !personLinkedToCampaign
            ) ? (
            isIgnored ? (
              <> </>
            ) : (
              <Button
                size="small"
                onClick={handleClick}
                py="xs"
                mode="standard-green"
                colorway={
                  isAssignmentSelect
                    ? undefined
                    : { group: "grey", variant: "standard" }
                }
                hoverColorway={
                  isAssignmentSelect
                    ? undefined
                    : { group: "grey", variant: "dark" }
                }
              >
                {isAssignmentSelect
                  ? messages.talentGraphSearch.linkToAssignment
                  : messages.talentGraphSearch.linkToCampaign}
              </Button>
            )
          ) : (
            <Flex
              flexDirection={["row", "row", "row", "column"]}
              width="100%"
              alignItems="center"
            >
              <PositiveIcon width={30} height={30} />
              <H6
                mb="none"
                ml={["xs", "xs", "xs", "none"]}
                mt={["none", "none", "none", "xxs"]}
                textAlign="center"
                color="grey.dark"
              >
                {messages.talentGraphSearch.linkedTo.format(
                  isAssignmentSelect
                    ? assignment?.name || ""
                    : campaign?.name || ""
                )}
              </H6>
            </Flex>
          )}
        </Div>
        <IgnoreButton
          mode="standard-white"
          size="small"
          isIgnored={isIgnored}
          onClick={ignoreCandidate}
          border="none"
          colorway={{ group: "grey", variant: "light" }}
          hoverColorway={{ group: "grey", variant: "light" }}
          prefix={
            !isIgnored ? (
              <HideIcon width="15px" height="15px" />
            ) : (
              <ShowIcon width="15px" height="15px" />
            )
          }
        >
          {!isIgnored
            ? messages.searchPerson.ignoringPerson.ignore
            : messages.searchPerson.ignoringPerson.unignore}
        </IgnoreButton>
      </Flex>
      <Div>
        {person?.dataPoolId && (
          <Link
            href={`${window.location.protocol + "//" + window.location.host}${
              RouterUrl.Search
            }?personId=${person.dataPoolId}&name=${person.name}`}
            target="_blank"
          >
            <Button size="small" mode="standard-white">
              {messages.assignment.similarPeople.findSimilarPeople}
            </Button>
          </Link>
        )}
      </Div>
    </Flex>
  )
}

export default React.memo(PersonAssignment)
