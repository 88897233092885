import { Div, StyledSystemElement } from "@ikiru/talentis-fpc"
import React, { useRef, useEffect, useState, ReactNode } from "react"

type LazyLoadContainerProps = {
  children: ReactNode
  placeHolder: ReactNode
} & StyledSystemElement<HTMLDivElement> &
  React.RefAttributes<unknown>

const LazyLoadContainer = ({
  children,
  placeHolder,
  ...rest
}: LazyLoadContainerProps) => {
  const [isVisible, setVisible] = useState(false)
  const divRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisible(true)
            observer.unobserve(entry.target)
          }
        })
      },
      {
        rootMargin: "0px",
        threshold: 0.01
      }
    )
    const devRefCurrent = divRef.current
    if (devRefCurrent) {
      observer.observe(devRefCurrent)
    }

    return () => {
      if (observer && devRefCurrent) {
        observer.unobserve(devRefCurrent)
      }
      observer.disconnect()
    }
  }, [])

  return (
    <Div ref={divRef} {...rest}>
      {isVisible ? children : placeHolder}
    </Div>
  )
}

export default LazyLoadContainer
