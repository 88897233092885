import {
  OnAssignmentDetailsSubmit,
  onCreateAssignmentSubmit
} from "../../views/assignments/components/assignment-create-form/assignment-details-form.actions"
import { Assignment } from "../../views/assignments/assignment.types"
import { RouterUrl } from "../../setup/router/routes"
import { useNavigate } from "react-router-dom"
import { setItem } from "@ikiru/talentis-fpc"
import { localStorageKeys } from "../../setup/storage/storage.definitions"

export const useAssignmentSubmit = (noRedirect?: boolean) => {
  const navigate = useNavigate()
  const onSubmit: OnAssignmentDetailsSubmit = async (values, helpers) => {
    setItem(localStorageKeys.assignmentStatusInput, values.status)
    const assignmentValues = {
      ...values,
      name: `${values.jobTitle} @ ${values.companyName}`,
      startDate: new Date()
    }

    const [, response] = await onCreateAssignmentSubmit(
      assignmentValues,
      helpers
    )

    if (response && !noRedirect) {
      const assignment = response.data as Assignment
      navigate(RouterUrl.AssignmentDetails + "/" + assignment.id)
    }
  }
  return onSubmit
}
