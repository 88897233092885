import React, { useState, useCallback } from "react"
import {
  PersonNoteField,
  personNotesFormE2ETargets,
  LinkNote,
  LinkNoteField
} from "views/persons/components/person-notes/form/constants/definitions"
import { messages } from "setup/messages/messages"
import { FormikSelect } from "components/functional/formik/formik-select/FormikSelect"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import { Flex, BodyText, Div, SelectOptionProps } from "@ikiru/talentis-fpc"
import { useFormikContext } from "formik"

type LinkToNoteProps = {
  assignmentOptions: SelectOptionProps[]
  campaignOptions: SelectOptionProps[]
  selectedProject: LinkNote
}

export const LinkToNote = ({
  assignmentOptions,
  campaignOptions,
  selectedProject
}: LinkToNoteProps) => {
  const [linkNote, setLinkNote] = useState(selectedProject)
  const { setFieldValue } = useFormikContext()

  const handleChange = useCallback(
    (e: any) => {
      switch (e.currentTarget.value) {
        case LinkNote.Assignment:
          setLinkNote(LinkNote.Assignment)
          setFieldValue(PersonNoteField.CampaignId, "")
          break
        case LinkNote.Campaign:
          setLinkNote(LinkNote.Campaign)
          setFieldValue(PersonNoteField.AssignmentId, "")
          break
        default:
          setLinkNote(LinkNote.None)
          setFieldValue(PersonNoteField.CampaignId, "")
          setFieldValue(PersonNoteField.AssignmentId, "")
      }
    },
    [setFieldValue]
  )

  return (
    <>
      <Flex alignItems="center" m="m">
        <BodyText m="none" mr="xs">
          {messages.person.note.form.linkTo}:
        </BodyText>
        <Div mr="s">
          <FormikToggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            onChange={handleChange}
            value={LinkNote.None}
            checked={linkNote === LinkNote.None}
            name={LinkNoteField.LinkNote}
            labelProps={{ ml: "xxs" }}
            label={messages.person.note.form.none}
          />
        </Div>
        <Div mr="s">
          <FormikToggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            onChange={handleChange}
            value={LinkNote.Assignment}
            checked={linkNote === LinkNote.Assignment}
            name={LinkNoteField.LinkNote}
            labelProps={{ ml: "xxs" }}
            label={messages.person.note.form.assignment}
          />
        </Div>
        <Div mr="s">
          <FormikToggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            onChange={handleChange}
            value={LinkNote.Campaign}
            checked={linkNote === LinkNote.Campaign}
            name={LinkNoteField.LinkNote}
            labelProps={{ ml: "xxs" }}
            label={messages.person.note.form.campaign}
          />
        </Div>
      </Flex>

      {linkNote === LinkNote.Assignment && (
        <FormikSelect
          data-e2e-target-name={personNotesFormE2ETargets.noteAssignment}
          id={PersonNoteField.AssignmentId}
          name={PersonNoteField.AssignmentId}
          label={messages.form.generic.assignment}
          firstOption={{
            label: messages.person.note.form.options.none,
            value: ""
          }}
          options={assignmentOptions || []}
        />
      )}

      {linkNote === LinkNote.Campaign && (
        <FormikSelect
          data-e2e-target-name={personNotesFormE2ETargets.noteCampaign}
          id={PersonNoteField.CampaignId}
          name={PersonNoteField.CampaignId}
          label={messages.person.note.form.campaign}
          firstOption={{
            label: messages.person.note.form.options.none,
            value: ""
          }}
          options={campaignOptions || []}
        />
      )}
    </>
  )
}
