import React, { useMemo } from "react"
import { Formik, Form } from "formik"
import {
  initialDefaultPersonNoteState,
  personNotesFormE2ETargets,
  LinkNote
} from "views/persons/components/person-notes/form/constants/definitions"
import { addNoteValidationSchema } from "views/persons/components/person-notes/form/constants/schema"
import { PersonNoteAddFormProps } from "views/persons/components/person-notes/form/constants/types"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { LinkToNote } from "./LinkToNote"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { PersonNoteForm } from "./PersonNoteForm"
import { ModalFormContainer, StyledForm } from "../components/style"

export const PersonNotesAddForm = (props: PersonNoteAddFormProps) => {
  const { onSubmit, formActionsComponent, assignmentOptions, campaignOptions } =
    props

  const { assignmentId } = useAssignment()
  const { campaignId } = useCampaign()

  const selectedProject = useMemo(
    () =>
      assignmentId
        ? LinkNote.Assignment
        : campaignId
        ? LinkNote.Campaign
        : LinkNote.None,
    [assignmentId, campaignId]
  )

  return (
    <ModalFormContainer>
      <Formik
        initialValues={{
          ...initialDefaultPersonNoteState,
          assignmentId,
          campaignId
        }}
        validationSchema={addNoteValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form data-e2e-target-name={personNotesFormE2ETargets.noteForm}>
              {formActionsComponent?.(formik)}
              <StyledForm>
                <PersonNoteForm />
                <LinkToNote
                  assignmentOptions={assignmentOptions}
                  campaignOptions={campaignOptions}
                  selectedProject={selectedProject}
                />
              </StyledForm>
            </Form>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}
