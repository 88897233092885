import { Flex, H1, H3, BodyText, Div, Span } from "@ikiru/talentis-fpc"
import React from "react"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"
import Reports1 from "assets/Reports1.png"
import Reports2 from "assets/Reports2.png"
import Reports3 from "assets/Reports3.png"
import { ImageText, List, SmallImg } from "./styles"

export const Reports = () => {
  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.reports.title)}</title>
      </Helmet>
      <H1 m="0" color="green.standard">
        {messages.reports.title}
      </H1>
      <Flex
        mt="s"
        bg="grey.lightest"
        flexDirection="column"
        alignItems="center"
      >
        <Flex width="50%" flexDirection="column" alignItems="center">
          <H1 mt="xl" color="green.standard">
            {messages.reports.nextGeneration}
          </H1>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/763385602?h=2c34a77bf5"
            width="640"
            height="400"
            frameBorder="0"
            allowFullScreen
          />
          <BodyText mt="45px" color="grey.dark" textAlign="center">
            {messages.reports.install}
            <Div color="grey.dark" mt="s">
              {messages.reports.youCanGet}
            </Div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://appsource.microsoft.com/en-us/product/office/WA200004843?src=office&corrid=cb7e2d19-fe90-30b8-5148-fe22be91e87b&omexanonuid=&referralurl=&ClientSessionId=6943af23-a80a-47e0-bab0-d3b63ba42468"
            >
              {messages.reports.storeOnline}
            </a>{" "}
            {messages.reports.orFromWithin}
            <H3 mt="45px" color="grey.dark">
              {messages.reports.hereIsHow}
            </H3>
            <List>
              <li>
                {messages.reports.openWord}{" "}
                <Span fontWeight="bold">{messages.reports.insert}</Span>
                {messages.reports.tabAnd}{" "}
                <Span fontWeight="bold">{messages.reports.getAddins}</Span>. (
                <ImageText>{messages.reports.seeImage} 1</ImageText>)
              </li>
              <li>{messages.reports.willAppear}</li>
              <li>
                {messages.reports.inThe}{" "}
                <Span fontWeight="bold">{messages.reports.search}</Span>{" "}
                {messages.reports.boxSearchFor} {messages.reports.talentis}. (
                <ImageText>{messages.reports.seeImage} 2</ImageText>)
              </li>
              <li>
                {messages.reports.selectThe}{" "}
                <Span fontWeight="bold">
                  {messages.reports.talentisAddInWord}
                </Span>{" "}
                {messages.reports.andClick}{" "}
                <Span fontWeight="bold">{messages.reports.add}</Span>.
              </li>
              <li>
                {messages.reports.youWillSee}{" "}
                <Span fontWeight="bold">{messages.reports.talentisAddIn}</Span>{" "}
                {messages.reports.appearOnRibbon}. (
                <ImageText>{messages.reports.seeImage} 3</ImageText>){" "}
              </li>
            </List>
          </BodyText>
        </Flex>
        <Flex flexDirection="column">
          <SmallImg>{messages.reports.image} 1:</SmallImg>
          <img
            alt="Talantis candidates reports example"
            width={727}
            src={Reports1}
          />
          <SmallImg>{messages.reports.image} 2:</SmallImg>
          <img
            alt="Talantis candidates reports example"
            width={727}
            src={Reports2}
          />
          <SmallImg>{messages.reports.image} 3:</SmallImg>
          <img
            alt="Talantis candidates reports example"
            width={727}
            src={Reports3}
          />
        </Flex>
      </Flex>
    </>
  )
}
