import styled from "styled-components"
import { tempInnerGreyModalStyles } from "setup/modal/modal.helpers"
import styledCss from "@styled-system/css"
import { Button, spacing } from "@ikiru/talentis-fpc"

export const ModalContainer = styled.div`
  ${styledCss(tempInnerGreyModalStyles)}
  width: 320px;
  ${({ theme }) => `
    ${theme.mediaQueries.xs} {
      width: 520px;
    }
  `}
`

export const IgnoreButton = styled(Button)<{ isIgnored: boolean }>`
  margin-bottom: ${spacing.xs}px;
  opacity: ${({ isIgnored }) => (isIgnored ? 0.5 : 1)};
  &:hover {
    opacity: 1;
  }
`
