import React from "react"
import { SpaceProps } from "styled-system"
import { Flex } from "@ikiru/talentis-fpc"
import { ErrorIcon } from "@ikiru/talentis-fpc"
import {
  ErrorContainer,
  ErrorMessageDescription,
  ErrorMessageTitle
} from "./style"

interface BillingErrorProps {
  title: string
  message: string
}

export const BillingError = (props: BillingErrorProps & SpaceProps) => {
  const { title, message, ...spaceProps } = props

  return (
    <ErrorContainer {...spaceProps}>
      <ErrorIcon />
      <Flex flexDirection="column" ml="s">
        <ErrorMessageTitle>{title}</ErrorMessageTitle>
        <ErrorMessageDescription>{message}</ErrorMessageDescription>
      </Flex>
    </ErrorContainer>
  )
}
