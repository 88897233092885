import { OffLimitsType } from "components/OffLimits/type"
import { apiRequest } from "setup/api/api"
import { CompanyEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { CompanyPrimaryData } from "../constants/definitions"

export const patchOffLimits = async (
  companyId: string,
  offLimits: OffLimitsType
) => {
  const [, response] = await apiRequest.patch({
    endpoint: CompanyEndpoints.Root,
    data: { offLimits },
    endpointParams: companyId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const putIndustries = async (
  companyId: string,
  industryId: string,
  industryName: string
) => {
  const [, response] = await apiRequest.put({
    endpoint: CompanyEndpoints.Industries,
    data: { name: industryName },
    endpointParams: { companyId: companyId, id: industryId },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const postIndustries = async (
  companyId: string,
  industryName: string
) => {
  const [, response] = await apiRequest.post({
    endpoint: CompanyEndpoints.Industries,
    data: { name: industryName },
    endpointParams: { companyId: companyId },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const patchCompanyDetails = async (
  companyId: string,
  values: CompanyPrimaryData
) => {
  const [, response] = await apiRequest.patch({
    endpoint: CompanyEndpoints.Root,
    data: { ...values },
    endpointParams: companyId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}
