import React from "react"
import { messages } from "setup/messages/messages"
import { BodyText, H2, H4 } from "@ikiru/talentis-fpc"
import { FAQWrapper, FAQItem } from "./styles"

const faqItems = Object.entries(messages.subscription.faq.items)

export const SubscriptionFAQ = () => {
  return (
    <>
      <H2 mt="none" mb="m" color="grey.dark">
        {messages.subscription.faq.title}
      </H2>
      <FAQWrapper>
        {faqItems.map(([key, { question, answer }]) => (
          <FAQItem key={key}>
            <H4 mt="none" color="grey.dark">
              {question}
            </H4>
            <BodyText mt="none">{answer}</BodyText>
          </FAQItem>
        ))}
      </FAQWrapper>
    </>
  )
}
