import React, { useState } from "react"
import { Button, Flex, PencilIcon, colors } from "@ikiru/talentis-fpc"
import { StyledRemoveButton, StyledRemoveIcon } from "./style"

type OverviewInputFooterProps = {
  handleEditClick?: () => void
  handleDelete?: () => void
}

const OverviewInputFooter = ({
  handleEditClick,
  handleDelete
}: OverviewInputFooterProps) => {
  const [isButtonHover, setIsButtonHover] = useState(false)

  return (
    <Flex>
      <Button
        mode="standard-grey-light"
        size="action-small"
        onClick={handleEditClick}
        mr="xxs"
      >
        <PencilIcon color={colors.grey.dark} width={12} height={12} />
      </Button>
      <StyledRemoveButton
        onClick={handleDelete}
        onMouseEnter={() => setIsButtonHover(true)}
        onMouseLeave={() => setIsButtonHover(false)}
        color={colors.white.standard}
        size="action-small"
      >
        <StyledRemoveIcon isHover={isButtonHover} />
      </StyledRemoveButton>
    </Flex>
  )
}

export default OverviewInputFooter
