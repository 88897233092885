import { apiRequest } from "setup/api/api"
import { TasksEndpoints } from "setup/api/endpoints/endpoints"
import moment from "moment"
import { normalizedTaskPerson } from "components/ProjectPersonRecord/helpers"
import {
  getAppliedFiltersParams as getAppliedFiltersCampaignParams,
  getDataFilter
} from "components/ProjectRecordsFilters/helpers"
import { expandEndpointByParams } from "setup/api/api.helpers"
import { CandidateFilter } from "../../assignments/assignment-module/assignment-module.types"
import { getAppliedFiltersParams } from "../../assignments/components/candidates-list/components/candidate-filters/helper"
import { isEmpty, set, get } from "lodash"
import { sessionStorageKeys } from "setup/storage/storage.definitions"
import { Nullable } from "tsdef"

export const getTasksList = async (
  params = new URLSearchParams(),
  taskType = "assignment"
) => {
  const dateFormat = moment(Date()).format("YYYY-MM-DD HH:mm:ss")

  params.append("TaskTypes", taskType)
  params.append("BrowserDate", dateFormat)
  params.append("expand", `${taskType},person`)

  const [, response] = await apiRequest.get({
    endpoint: TasksEndpoints.Root,
    config: { params: params }
  })

  let tasks = normalizedTaskPerson(response?.data.tasks) || []

  const dueDayCount = response?.data.dueDayCount || {}
  const count = response?.data.count || 0
  const countFiltered = response?.data.countFiltered || 0

  return { tasks, dueDayCount, countFiltered, count }
}

export const getAllAvailableFilters = async (
  isAssignment: boolean,
  dueFilter: string
) => {
  const [, response] = await apiRequest.get({
    endpoint: TasksEndpoints.FilterData,
    config: expandEndpointByParams({
      taskTypes: isAssignment ? "assignment" : "campaign",
      dueFilter
    })
  })

  const {
    candidateStages,
    campaignContactStatuses,
    candidateStatuses,
    assignedsTo
  } = response?.data || {}
  const status = isAssignment
    ? getDataFilter(candidateStatuses)
    : getDataFilter(campaignContactStatuses)
  const assignedTo = getDataFilter(assignedsTo)
  const stage = isAssignment ? getDataFilter(candidateStages) : []

  return { status, assignedTo, stage }
}

const candidatePropToRecordProp = (candidateFilters: CandidateFilter[]) => {
  return candidateFilters.map((filter) => ({
    ...filter,
    recordProperty: filter.candidateProperty
  }))
}

export const getPreparedParams = (
  filters: CandidateFilter[],
  isAssignment: boolean
) => {
  return isAssignment
    ? getAppliedFiltersParams(filters)
    : getAppliedFiltersCampaignParams(candidatePropToRecordProp(filters))
}

export const saveTasksFiltersToSessionStorage = (
  filters: CandidateFilter[],
  isAssignment: boolean
) => {
  try {
    const allFilters = getTasksFiltersFromSessionStorage() || {}
    set(allFilters, [String(isAssignment)], filters)
    const filtersJson = JSON.stringify(allFilters)

    sessionStorage.setItem(sessionStorageKeys.previousTasksFilters, filtersJson)
  } catch (eror) {
    console.error("Could not save tasks filters")
  }
}

export const getTasksFiltersFromSessionStorage = (
  path?: string[]
): Nullable<CandidateFilter[]> => {
  const filtersJson = sessionStorage.getItem(
    sessionStorageKeys.previousTasksFilters
  )

  if (!filtersJson) {
    return null
  }

  let filters

  try {
    const filtersParsed = JSON.parse(filtersJson)
    filters = path ? get(filtersParsed, path) : filtersParsed
  } catch (error) {
    console.error("Could not load candidates filters")
  }

  return filters
}

export const hasTasksFiltersInSessionStorage = () => {
  const filters = getTasksFiltersFromSessionStorage()
  return filters && !isEmpty(filters)
}
