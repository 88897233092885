import { Div, Section } from "@ikiru/talentis-fpc"
import { Loader } from "components/Loader/Loader"
import { RouterButton } from "components/RouterLink/RouterLink"
import React, { useMemo } from "react"
import { messages } from "setup/messages/messages"
import { RouterUrl } from "setup/router/routes"
import { PaidPlanDetails, TrialPlanDetails } from "./components"
import NoPlanInfo from "./components/NoPlanInfo/NoPlanInfo"
import { CurrentSubscriptionDetails } from "./definitions"

type CurrentPlanProps = {
  currentPlanDetails: CurrentSubscriptionDetails | undefined
  isLoading: boolean
}

export const CurrentPlan = (props: CurrentPlanProps) => {
  const { isLoading, currentPlanDetails } = props

  const planDetails = useMemo(() => {
    if (currentPlanDetails?.paidSubscriptionDetails) {
      return (
        <PaidPlanDetails
          paidPlanDetails={currentPlanDetails.paidSubscriptionDetails}
        />
      )
    }

    if (currentPlanDetails?.trialDetails) {
      return (
        <TrialPlanDetails trialPlanDetails={currentPlanDetails.trialDetails} />
      )
    }

    return <NoPlanInfo />
  }, [currentPlanDetails])

  return (
    <Section
      size="xSmall"
      header={{
        title: messages.billing.currentPlan.title,
        actions:
          !isLoading && !currentPlanDetails?.paidSubscriptionDetails ? (
            <RouterButton
              to={RouterUrl.Subscription}
              mode="standard-green"
              size="small"
            >
              {messages.navigation.helpers.upgrade}
            </RouterButton>
          ) : null
      }}
    >
      <Div width="100%" pt="xs">
        {isLoading ? <Loader /> : <>{planDetails}</>}
      </Div>
    </Section>
  )
}
