import React, { useState } from "react"
import { messages } from "setup/messages/messages"
import CompanySearch from "views/assignments/components/assignment-company/CompanySearch"
import { AssignmentDataField } from "views/assignments/components/assignment-data/constants/definitions"
import { CompanySelected } from "views/assignments/components/assignment-company/types"
import { RouterUrl } from "setup/router/routes"
import { Button, Div, Flex, H6, colors } from "@ikiru/talentis-fpc"
import { createCompanyByName } from "./action"
import { useNavigate } from "react-router-dom"

const CompaniesSearchForm = () => {
  const navigate = useNavigate()
  const [currentInputtedName, setCurrentInputtedName] = useState<string>("")

  const handleCompanySelect = (company: CompanySelected) => {
    navigate(`${RouterUrl.CompanyDetails}/${company.id}`)
  }

  const createCompany = async () => {
    if (currentInputtedName) {
      const newCompany = await createCompanyByName(currentInputtedName)
      if (newCompany && newCompany.id) {
        navigate(`${RouterUrl.CompanyDetails}/${newCompany.id}`)
      }
    }
  }

  return (
    <Flex width="100%">
      <Flex width="100%">
        <Div minWidth="300px" width="400px">
          <CompanySearch
            id={AssignmentDataField.CompanyName}
            name={`${AssignmentDataField.CompanyName}.value`}
            label={messages.form.generic.enterCompany}
            onClickItem={handleCompanySelect}
            selectedCompany={(value: CompanySelected) => {
              setCurrentInputtedName(value.name)
            }}
          />
        </Div>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          ml="xs"
        >
          <H6 m="0" color={colors.grey.standard} textAlign="center">
            {messages.companiesSearch.cantFindCompany}
          </H6>
          <Button
            onClick={createCompany}
            mode="standard-green"
            size="small"
            disabled={!Boolean(currentInputtedName)}
          >
            {messages.companiesSearch.createNewCompany}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CompaniesSearchForm
