import { ToastOptions } from "react-toastify"

export const navigationSelectors = {
  mainContainer: "main-container"
}

export const errorToastConfig: ToastOptions = {
  toastId: "id",
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}
