import React, { useState } from "react"
import { Button, colors } from "@ikiru/talentis-fpc"
import { FaTrashAlt } from "react-icons/fa"

type PersonExperienceDeleteButtonType = {
  editCallback?: () => void
}

export const PersonExperienceDeleteButton = ({
  editCallback
}: PersonExperienceDeleteButtonType) => {
  const [hovered, setHovered] = useState<boolean>(false)

  return (
    <Button
      ml="xxs"
      mode="standard-white"
      hoverColorway={{ group: "red", variant: "lightest" }}
      size="action-medium"
      onMouseEnter={() => {
        setHovered(true)
      }}
      onMouseLeave={() => {
        setHovered(false)
      }}
      onClick={() => {
        editCallback?.()
      }}
    >
      <FaTrashAlt color={hovered ? colors.red.standard : colors.grey.dark} />
    </Button>
  )
}
