import React from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import { getChangedValues } from "views/assignments/utils"
import { Button, Flex, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { StyledDiv } from "./style"
import {
  CampaignPrimaryData,
  campaignPrimaryDataInitialValues
} from "../../constants/definitions"
import { CampaignDataFormLayout } from "../../form/layout"

type CampaignDataFormProps = {
  onSubmit: (
    values: CampaignPrimaryData,
    helpers: FormikHelpers<CampaignPrimaryData>
  ) => void
  initialValues?: CampaignPrimaryData
  formActionsComponent?: (
    formikProps: FormikProps<CampaignPrimaryData>
  ) => JSX.Element
}

export const EditCampaignData = ({
  onSubmit,
  initialValues,
  formActionsComponent
}: CampaignDataFormProps) => {
  const { close } = useModal()

  const submitForm = (
    values: CampaignPrimaryData,
    action: FormikHelpers<CampaignPrimaryData>
  ) => {
    const changeValues = getChangedValues(values, initialValues)
    onSubmit(changeValues, action)

    close(ModalNames.EditCampaignData)
  }

  return (
    <StyledDiv>
      <Formik
        initialValues={{
          ...campaignPrimaryDataInitialValues,
          ...initialValues
        }}
        onSubmit={submitForm}
      >
        {(formik) => (
          <Form>
            <Flex>
              <ModalSectionHeader
                title={messages.campaign.campaignEditTitle}
                size="xSmall"
                actions={
                  <>
                    <Button size="small" mx="xxs" my="0" type="submit">
                      {messages.form.generic.save}
                    </Button>
                    <Button
                      size="small"
                      mode="standard-white"
                      mx="xxs"
                      my="0"
                      onClick={() => close(ModalNames.EditCampaignData)}
                    >
                      {messages.generic.cancel}
                    </Button>
                  </>
                }
              />
            </Flex>
            <CampaignDataFormLayout />

            {formActionsComponent?.(formik)}
          </Form>
        )}
      </Formik>
    </StyledDiv>
  )
}
