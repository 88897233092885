import React, { useMemo, useState } from "react"
import { colors, Div, Flex, H5, H6, Toggle } from "@ikiru/talentis-fpc"
import { StyledFormOption, StyledFormWrapper } from "../style"
import { Membership, ProjectTeamMember, ProjectTeamUpdate } from "../types"
import { messages } from "setup/messages/messages"
import { TeamMember } from "views/team/team/team-module.types"

type ProjectTeamFormProps = {
  projectTeamMembers: ProjectTeamMember[]
  setSelectedMembers: (team: ProjectTeamUpdate[]) => void
  teamMembers: TeamMember[]
  title?: string
  roles?: { value: Membership; label: string }[]
}

export const ProjectTeamForm = ({
  projectTeamMembers,
  setSelectedMembers,
  teamMembers,
  title,
  roles = [
    { value: Membership.Owner, label: messages.assignment.assignmentOwner },
    { value: Membership.Member, label: messages.assignment.assignmentMember }
  ]
}: ProjectTeamFormProps) => {
  const [teamMembersTemp, setTeamMembersTemp] =
    useState<ProjectTeamMember[]>(projectTeamMembers)

  const teamTeam = teamMembers
    .map((team) => {
      return {
        userId: team.id,
        membership: null,
        firstName: team.firstName,
        lastName: team.lastName,
        isActive: !team.isDisabled
      } as ProjectTeamMember
    })
    .filter((team) => {
      return !teamMembersTemp?.find(
        (el: ProjectTeamMember) => el.userId === team.userId
      )
    })
    .sort((a: ProjectTeamMember, b: ProjectTeamMember) =>
      a.firstName.localeCompare(b.firstName)
    )

  const connectTeam = useMemo(
    () => [...teamMembersTemp, ...teamTeam],
    [teamTeam, teamMembersTemp]
  )

  const onToggleClick = (
    type: Membership | null,
    teamMemberSel: ProjectTeamMember
  ) => {
    const updatedTeamMembers: ProjectTeamMember[] = connectTeam.map(
      (teamMember: ProjectTeamMember) => {
        if (teamMember.userId === teamMemberSel.userId) {
          const membership =
            teamMember.membership === type ||
            (teamMember.membership !== type && !teamMember.isActive)
              ? null
              : type
          return {
            ...teamMember,
            membership: membership
          }
        }
        return teamMember
      }
    )

    setTeamMembersTemp(updatedTeamMembers)
    const tempSelectedMember: ProjectTeamUpdate[] = updatedTeamMembers
      .filter((team: ProjectTeamMember) => team.membership)
      .map((team: ProjectTeamMember) => {
        return { userId: team.userId, membership: team.membership }
      })
    setSelectedMembers(tempSelectedMember)
  }

  const options = [
    ...connectTeam.map((teamMember: ProjectTeamMember) => {
      return (
        <StyledFormOption>
          <H6
            m="0"
            minWidth="65%"
            color={teamMember.isActive ? colors.grey.dark : colors.grey.light}
          >{`${teamMember.firstName} ${teamMember.lastName}`}</H6>
          {roles.map((role) => (
            <Toggle
              width="25%"
              type="checkbox"
              disabled={
                !teamMember.isActive && teamMember.membership !== role.value
              }
              hideUnderline
              checked={teamMember.membership === role.value}
              onChange={() => onToggleClick(role.value, teamMember)}
              small
            />
          ))}
        </StyledFormOption>
      )
    })
  ]

  return (
    <StyledFormWrapper>
      <H5
        m="0"
        pl="s"
        py="8px"
        backgroundColor={colors.grey.light}
        color={colors.grey.dark}
      >
        {title || messages.assignment.assignmentTeam}
      </H5>
      <Flex mt="s">
        <Div width="60%" />
        {roles.map((role) => (
          <H6 m="0" color={colors.grey.standard} width="15%">
            {role.label}
          </H6>
        ))}
      </Flex>
      <Div overflow="auto">{options}</Div>
    </StyledFormWrapper>
  )
}
