import React, { useCallback, useEffect } from "react"
import { Flex, H4, H6, BodyText, Div } from "@ikiru/talentis-fpc"
import { Loader } from "components/Loader/Loader"
import { CampaignData } from "views/campaigns/components/campaign-data"
import { Tabs } from "views/companies/components/company-details/components/Tabs/Tabs"
import Tab from "views/companies/components/company-details/components/Tabs/Tab"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { Campaign } from "views/campaigns/campaign.types"
import { CampaignTags } from "views/campaigns/components/campaign-tags"
import CampaignNotes from "views/campaigns/components/campaign-notes"
import { ContactsList } from "views/campaigns/components/contacts"
import CampaignPersonOverlay from "views/persons/components/person-overlay/CampaignPersonOverlay"
import { DateFlex, FlexSectionBody, StyledHeader } from "./style"
import {
  DateLabel,
  StatusDiv
} from "views/assignments/pages/manage-assignment/style"
import { formatDate } from "utils/format-date"
import { campaignStatusMap } from "views/campaigns/components/campaign-data/constants/definitions"
import { useCampaignNotes } from "views/campaigns/components/campaign-notes/hooks"
import CampaignTemplate from "views/campaigns/components/campagin-ai-template"
import useCampaignTemplates from "views/campaigns/components/campagin-ai-template/hooks/useCampaignTemplate"
import CampaignActions from "views/campaigns/components/campaign-actions"
import { ProjectType } from "components/ProjectActions/ProjectDocumnets/form/constants/consts"
import { ProjectDocuments } from "components/ProjectActions/ProjectDocumnets"
import { useOnProjectDocumentsLoad } from "components/ProjectActions/ProjectDocumnets/hooks/on-project-documents-load"

export const CampaignDetails = () => {
  const {
    isLoading,
    campaignId,
    setCampaignDetails,
    campaignDetails,
    selectedContactId,
    notes,
    paginationContact
  } = useCampaign()

  const { getAiTemplates } = useCampaignTemplates()
  const { isLoading: isLoadingNotes } = useCampaignNotes()

  useEffect(() => {
    getAiTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCampaignUpdate = useCallback(
    (campaign: Campaign) => {
      setCampaignDetails(campaign)
    },
    [setCampaignDetails]
  )
  const documentProps = useOnProjectDocumentsLoad({
    projectId: campaignId,
    projectType: ProjectType.Campaign
  })

  return isLoading && !campaignDetails ? (
    <Loader />
  ) : (
    <>
      <FlexSectionBody>
        <Div my="xxs">
          <StyledHeader>
            <Flex>
              <H4 color="grey.dark" fontWeight="600px" pb={0} mb={0} mt="2px">
                {campaignDetails.name}
              </H4>
              <StatusDiv>
                {campaignDetails.status && (
                  <H6 color="grey.dark" mb={0}>
                    {campaignStatusMap.get(campaignDetails.status)}
                  </H6>
                )}
              </StatusDiv>
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <DateFlex>
                {campaignDetails.startDate && (
                  <>
                    <DateLabel size="xSmall">
                      {messages.campaign.details.started} :
                    </DateLabel>
                    <BodyText my={0}>
                      &nbsp;{formatDate(campaignDetails.startDate)}
                    </BodyText>
                  </>
                )}
              </DateFlex>
              <Div mt="xxs">
                <CampaignActions />
              </Div>
            </Flex>
          </StyledHeader>
          <Flex alignItems="center">
            <BodyText mt="xxs">{campaignDetails.description}</BodyText>
          </Flex>
        </Div>
      </FlexSectionBody>
      {/* <ProjectTeam projectTeamMembers={campaignDetails.teamMembers} /> */}
      <Helmet>
        <title>{messages.seo.title.format(campaignDetails.name)}</title>
      </Helmet>
      <Flex flexWrap="wrap">
        <Flex width="100%">
          <Tabs>
            <Tab title={messages.campaign.campaignDetails}>
              <CampaignData
                onCampaignUpdated={handleCampaignUpdate}
                {...campaignDetails}
              />
            </Tab>
            <Tab
              title={`${messages.campaign.campaignContacts} (${paginationContact.totalItemCount})`}
              defaultSelected
            >
              <ContactsList />
            </Tab>
            <Tab title={`${messages.campaign.campaignNotes} (${notes.length})`}>
              <CampaignNotes isLoading={isLoadingNotes} />
            </Tab>
            <Tab
              title={`${messages.campaign.documents} (${
                documentProps.documents?.length || 0
              })`}
            >
              <ProjectDocuments
                projectId={campaignId}
                projectType={ProjectType.Campaign}
                {...documentProps}
              />
            </Tab>
            <Tab title={messages.campaign.campaignTags}>
              <CampaignTags
                onCampaignUpdated={handleCampaignUpdate}
                {...campaignDetails}
              />
            </Tab>
            <Tab title={messages.project.projectTemplatesAI}>
              <CampaignTemplate />
            </Tab>
          </Tabs>
        </Flex>
        {selectedContactId && <CampaignPersonOverlay />}
      </Flex>
    </>
  )
}
