import { Div, H5, BodyText } from "@ikiru/talentis-fpc"
import React from "react"
import {
  AssignmentPrimaryData,
  e2eTargets
} from "views/assignments/components/assignment-data/constants/definitions"
import { formatDate } from "utils/format-date"
import { messages } from "setup/messages/messages"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"
import { ShowMore } from "components/ShowMore"
import { AssignmentTeamMember } from "views/assignments/assignment.types"
import { AssignmentDetailsContainer } from "./style"
import { ProjectTeamMembers } from "components/ProjectTeamMembers/ProjectTeamMembers"
import { assignmentStatusMap } from "views/assignments/constants/assignment-statuses"

export const ViewAssignmentData = React.memo(
  ({
    name,
    startDate,
    status,
    jobTitle,
    companyName,
    companyId,
    endDate,
    brief,
    location,
    assignmentTeamMembers
  }: AssignmentPrimaryData & {
    assignmentId: string
    assignmentTeamMembers?: AssignmentTeamMember[]
  }) => {
    return (
      <Div py="m">
        <AssignmentDetailsContainer>
          <Div>
            <H5 my="0">{messages.assignment.assignmentProfileName}</H5>
            <BodyText
              data-e2e-target-name={e2eTargets.name}
              my={0}
              whiteSpace={"nowrap"}
            >
              {name}
            </BodyText>
          </Div>

          <Div>
            <H5 my="0">{messages.assignment.assignmentProfileJobTitle}</H5>
            <BodyText data-e2e-target-name={e2eTargets.jobTitle} my={0}>
              {jobTitle}
            </BodyText>
          </Div>

          <Div>
            <H5 my="0">{messages.assignment.assignmentProfileCompany}</H5>
            <CompanyLink
              data-e2e-target-name={e2eTargets.companyName}
              fontSize="18px"
              companyId={companyId}
              companyName={companyName}
            />
          </Div>
          <Div>
            <H5 my="0">{messages.assignment.assignmentProfileStatus}</H5>
            <BodyText data-e2e-target-name={e2eTargets.status} my={0}>
              {assignmentStatusMap.get(status)}
            </BodyText>
          </Div>
          <Div gridColumn="1 / 3">
            <H5 m="0">{messages.form.generic.location}</H5>
            <BodyText my={0}>{location}</BodyText>
          </Div>

          <Div display={["none", "none", "block"]}>
            <H5 m="0">{messages.assignment.assignmentProfileStartDate}</H5>
            <BodyText my={0}>{formatDate(startDate)}</BodyText>
          </Div>
          <Div display={["none", "none", "block"]}>
            <H5 m="0">{messages.assignment.assignmentProfileEndDate}</H5>
            <BodyText my="0">{formatDate(endDate)}</BodyText>
          </Div>
        </AssignmentDetailsContainer>
        <Div mt="m">
          <H5 m="0">{messages.assignment.assignmentBrief}</H5>
          <ShowMore visibleLines={3} description={brief} />
        </Div>
        <ProjectTeamMembers teamMembers={assignmentTeamMembers} />
      </Div>
    )
  }
)
