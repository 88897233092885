import { useEffect, useCallback, useState } from "react"
import { apiRequest } from "setup/api/api"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"

const useAssignmentNotes = () => {
  const [isLoading, setLoading] = useState(false)
  const { assignmentId, setNotes } = useAssignment()

  const updateNotes = useCallback(async () => {
    if (!assignmentId) return

    setLoading(true)

    const [, response] = await apiRequest.get({
      endpoint: AssignmentsEndpoints.Notes,
      endpointParams: { id: assignmentId }
    })

    if (response && response.data) {
      const notes = response.data.map((item: any) => ({
        ...item.note,
        linkCreatedByUser: item.createdBy,
        linkUpdatedByUser: item.updatedBy
      }))
      setNotes(notes)
    } else {
      setNotes([])
    }

    setLoading(false)
  }, [assignmentId, setNotes])

  useEffect(() => {
    updateNotes()
  }, [updateNotes])

  return { isLoading }
}

export { useAssignmentNotes }
