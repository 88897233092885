import * as Yup from "yup"
import { CardDetailsFields } from "./CardDetailsForm.definition"
import { messages } from "setup/messages/messages"

export const cardDetailsValidationSchema = Yup.object({
  [CardDetailsFields.Name]: Yup.string().required(messages.generic.required),
  [CardDetailsFields.TermsAndConditionsAccepted]: Yup.boolean()
    .required(messages.subscription.paymentDetails.termsAndConditionsError)
    .oneOf([true], messages.subscription.paymentDetails.termsAndConditionsError)
})

export const disabledCardDetailsValidationSchema = Yup.object({
  [CardDetailsFields.TermsAndConditionsAccepted]: Yup.boolean()
    .required(messages.subscription.paymentDetails.termsAndConditionsError)
    .oneOf([true], messages.subscription.paymentDetails.termsAndConditionsError)
})
