import React, { useState } from "react"
import { Flex, PositiveIcon, BodyText } from "@ikiru/talentis-fpc"
import { LinkContainerStyled } from "../assignment-client-details/components/ViewAssignmentClientDetails/styles"
import { messages } from "setup/messages/messages"
import { Nullable } from "tsdef"
import LinkedInChecker from "./components/LinkedInChecker"
import AssignmentDetailView from "./components/AssignmentDetailsView"

export type LinkUsedCompanyType = {
  Name: string
  Id: string
  LogoUrl: string
}

type AssignmentDetailSectionProps = {
  editLinkedIn: boolean
  setEditLinkedIn: React.Dispatch<React.SetStateAction<boolean>>
  showLinkSuccess: boolean
  setShowLinkSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

const AssignmentDetailSection = (props: AssignmentDetailSectionProps) => {
  const { editLinkedIn, setEditLinkedIn, showLinkSuccess, setShowLinkSuccess } =
    props

  const [linkUsedCompany, setLinkUsedCompany] =
    useState<Nullable<LinkUsedCompanyType>>(null)

  return (
    <>
      <LinkedInChecker
        {...{
          editLinkedIn,
          setEditLinkedIn,
          linkUsedCompany,
          setLinkUsedCompany,
          setShowLinkSuccess
        }}
      />
      {showLinkSuccess && (
        <LinkContainerStyled pt="xs">
          <Flex flexDirection="row">
            <PositiveIcon width={30} height={30} />
            <BodyText
              ml="xs"
              my="none"
              mr="none"
              display="flex"
              alignItems="center"
            >
              {messages.assignment.linkCompanySuccess}
            </BodyText>
          </Flex>
        </LinkContainerStyled>
      )}
      <AssignmentDetailView />
    </>
  )
}

export default AssignmentDetailSection
