import React from "react"
import { BodyText, Div } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import FindPersonForm from "./FindPersonForm"
import { PersonDetailsType } from "../.."

type FindContactFormProps = {
  onSubmit: (data: PersonDetailsType) => Promise<void>
  isMobile: boolean
  personSearchDetails: PersonDetailsType
}

export const FindContactForm = ({
  onSubmit,
  isMobile,
  personSearchDetails
}: FindContactFormProps) => {
  return (
    <Div width={isMobile ? "100%" : "50%"}>
      <BodyText m="0">
        {messages.person.contact.AddPeople.LookingToAdd}
      </BodyText>
      <FindPersonForm
        {...{ onSubmit, isEditSearchPage: false, personSearchDetails }}
      />
    </Div>
  )
}
