import { Loader, LoaderProps } from "@ikiru/talentis-fpc"
import { Flex, Div, StyledSystemElement } from "@ikiru/talentis-fpc"
import React from "react"

type OverlayLoaderProps = LoaderProps & {
  isLoading: boolean
  children?: string
  overlayProps?: StyledSystemElement<HTMLDivElement>
}

export const OverlayLoader = ({
  isLoading,
  children,
  overlayProps,
  ...props
}: OverlayLoaderProps) =>
  isLoading ? (
    <Div
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={10}
      backgroundColor="rgba(255, 255, 255, 0.7)"
      {...overlayProps}
    >
      <Flex height="100%" justifyContent="center" alignItems="center">
        <Loader {...props}>{children}</Loader>
      </Flex>
    </Div>
  ) : null
