import React from "react"
import { Button, CrossIcon, Flex, H3, colors } from "@ikiru/talentis-fpc"
import { ModalContainer } from "views/search/components/PersonAssignment/styles"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"

type ModalProjectContainerType = {
  children: React.ReactNode
  typeOfProject: string
  modalName: ModalNames
}

const ModalProjectContainer = (props: ModalProjectContainerType) => {
  const { close } = useModal()
  const { typeOfProject, modalName } = props
  return (
    <ModalContainer style={{ padding: 0 }}>
      <Flex flexDirection="column" backgroundColor={colors.grey.lightest}>
        <Flex justifyContent="space-between" padding="s" marginBottom={-15}>
          <H3 color={colors.grey.dark}>
            {messages.talentGraphSearch.projectSelector.projectModal.create.format(
              typeOfProject
            )}
          </H3>
          <Flex justifyContent="flex-end">
            <Button
              onClick={() => close(modalName)}
              mode="standard-white"
              size="action-medium"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          </Flex>
        </Flex>
        {props.children}
      </Flex>
    </ModalContainer>
  )
}

export default ModalProjectContainer
