import {
  onCreateCampaignSubmit,
  OnCampaignDetailsSubmit
} from "views/campaigns/components/campaign-create-form/campaign-form.actions"
import { Campaign } from "views/campaigns/campaign.types"
import { RouterUrl } from "setup/router/routes"
import { useNavigate } from "react-router-dom"

export const useCampaignSubmit = () => {
  const navigate = useNavigate()
  const onSubmit: OnCampaignDetailsSubmit = async (values, helpers) => {
    const campaignValues = { ...values }
    const [, response] = await onCreateCampaignSubmit(campaignValues, helpers)

    if (response) {
      const campaign = response.data as Campaign
      navigate(RouterUrl.CampaignDetails + "/" + campaign.id)
    }
  }
  return onSubmit
}
