import React, { useEffect, useState } from "react"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Div, Flex, H5 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { KeywordsList } from "views/search/components/KeywordsList"
import { useFormikContext } from "formik"
import { SpecialityField } from "../constants/definitions"

type SpecialtiesFormProps = {
  speciality: string
  updateSpeciality: (specialityList: string) => void
}
export const SpecialtiesForm = ({
  speciality,
  updateSpeciality
}: SpecialtiesFormProps) => {
  const formik = useFormikContext()

  const [specialityList, setSpecialityList] = useState(
    speciality ? speciality.split(",") : []
  )

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      Boolean(e.currentTarget.value) &&
        setSpecialityList([...specialityList, e.currentTarget.value])
      formik.setFieldValue(SpecialityField.Speciality, "")
    }
  }

  const removeSpeciality = (value: string) => {
    const specialties = specialityList?.filter((entry) => entry !== value)
    setSpecialityList(specialties)
  }

  useEffect(() => {
    updateSpeciality(specialityList.join(","))
  }, [specialityList, updateSpeciality])

  return (
    <Flex flexDirection="column" width="100%">
      <H5 mt="5px" mb="none">
        {messages.companies.companyInfo.speciality}
      </H5>
      <KeywordsList
        variant="grey"
        keywords={specialityList}
        onKeywordRemove={(value) => removeSpeciality(value)}
      />
      <Div width={["100%", "100%", "49%"]} mt="s">
        <FormikInput
          name={SpecialityField.Speciality}
          label={messages.companies.companyAddSpeciality}
          placeholder={messages.companies.companyAddSpeciality}
          disableDefaultOnBlur
          {...{
            onKeyPress: onKeyPress as (
              e: React.KeyboardEvent<HTMLInputElement>
            ) => void
          }}
        />
      </Div>
    </Flex>
  )
}
