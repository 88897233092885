import React, { useCallback, useEffect } from "react"
import { UNSAFE_NavigationContext } from "react-router-dom"
import { useModal } from "utils/hooks/use-modal"
import { useCustomPrompt } from "utils/hooks/use-custom-prompt"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { ConfirmationModal } from "components/modals/ConfirmationModal"
import { ExitPromptProps } from "./types"
import type { History } from "history"

export const ExitSubscriptionPrompt: React.FC<
  React.PropsWithChildren<ExitPromptProps>
> = ({ when, onCancel, onConfirm }) => {
  const {
    closeModal,
    handleClosePress,
    handleConfirmNavigationClick,
    handleBlockedNavigation,
    modalVisible
  } = useCustomPrompt({ onCancel, onConfirm })

  const { open, close, onClose, removeOnCloseCallback } = useModal()
  const navigator = React.useContext(UNSAFE_NavigationContext)
    .navigator as History

  const closeCustomModal = useCallback(() => {
    removeOnCloseCallback(ModalNames.ExitPrompt, handleClosePress)
    close(ModalNames.ExitPrompt)
  }, [close, handleClosePress, removeOnCloseCallback])

  const handleCallbackWithCloseModal = useCallback(
    (callback: any) => () => {
      closeCustomModal()
      callback()
    },
    [closeCustomModal]
  )

  const openCustomModal = useCallback(() => {
    open(
      ModalNames.ExitPrompt,
      <ConfirmationModal
        title={messages.subscription.exitPrompt.title}
        subtitle={messages.subscription.exitPrompt.subtitle}
        cancelButtonLabel={messages.subscription.exitPrompt.continue}
        confirmButtonLabel={messages.subscription.exitPrompt.exit}
        onClose={handleCallbackWithCloseModal(handleClosePress)}
        onCancel={handleCallbackWithCloseModal(closeModal)}
        onConfirm={handleCallbackWithCloseModal(handleConfirmNavigationClick)}
      />
    )
    onClose(ModalNames.ExitPrompt, handleClosePress)
  }, [
    closeModal,
    handleCallbackWithCloseModal,
    handleClosePress,
    handleConfirmNavigationClick,
    onClose,
    open
  ])

  useEffect(() => {
    if (when && modalVisible) {
      openCustomModal()
    } else {
      closeCustomModal()
    }
  }, [closeCustomModal, modalVisible, openCustomModal, when])

  useEffect(() => {
    if (!when) {
      return
    }
    const unblock = navigator.block((transition) => {
      return handleBlockedNavigation(transition.location) as false
    })

    return () => {
      unblock()
    }
  }, [handleBlockedNavigation, navigator, when])

  return null
}
