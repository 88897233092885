import uniqBy from "lodash/uniqBy"
import { PersonState } from "views/persons/person-module/person-module.types"
import { SelectOptionProps } from "@ikiru/talentis-fpc"

export const transformAssignmentOptions = (
  candidates: PersonState["candidates"],
  assignments: SelectOptionProps[] = []
): SelectOptionProps[] => {
  const candidatesOptions = Object.values(candidates).map((item) => ({
    label: item?.linkAssignment?.name,
    value: item?.assignmentId
  }))
  return uniqBy([...candidatesOptions, ...assignments], "value")
}

export const transformCampaignOptions = (
  contacts: PersonState["contacts"],
  campaigns: SelectOptionProps[] = []
): SelectOptionProps[] => {
  const contactsOptions = Object.values(contacts).map((item) => ({
    label: item?.linkCampaign?.name,
    value: item?.campaignId
  }))
  return uniqBy([...contactsOptions, ...campaigns], "value")
}
