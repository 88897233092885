export const projectListE2ETargets = {
  list: "projects-list",
  linkToDetails: "project-link",
  title: "projects-list-title",
  submitButton: "submit-button"
}

export const searchInputName = "searchString"

export type SearchFormValues = {
  searchString: string
}

export const searchFormInitialValues = {
  searchString: ""
}
