import React, { useEffect, useState } from "react"
import { H1, H5, SmallText } from "@ikiru/talentis-fpc"
import { Flex, Span } from "@ikiru/talentis-fpc"
import { PlusIcon, Button } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { TeamMembers } from "./components/TeamMembers"
import { colors } from "@ikiru/talentis-fpc"
import { useTeam } from "./team-module.context"
import { roleDescriptions } from "./definitions"
import { useTalentisUser } from "setup/auth/module/auth.hooks"
import { UserRoles } from "setup/auth/module/constants/auth.types"
import { CreatePersonValues } from "views/team/team/components/TeamMembers/definitions"
import { TeamMemberStatus } from "views/team/team/team-module.types"
import { TeamMember } from "./team-module.types"

export type SetTeamMembers = (
  teamMembers: TeamMember[],
  invited: TeamMember[],
  inactiveMembers: TeamMember[]
) => void

export const Team = React.memo(() => {
  const {
    teamMembers,
    invited,
    inactiveMembers,
    invitationsResent,
    fetchTeamMembers,
    setTeamMembers
  } = useTeam()
  const { userHasPermission } = useTalentisUser()
  const [isInviteFormVisible, setIsInviteFormVisible] = useState(false)

  useEffect(() => {
    fetchTeamMembers()
  }, [fetchTeamMembers])

  const handleClickInviteButton = () => {
    setIsInviteFormVisible(true)
  }

  const handleInviteUser = (user: CreatePersonValues) => {
    const invitedMembers = [
      {
        id: user.id || "",
        firstName: "",
        lastName: "",
        status: TeamMemberStatus.Invited,
        userRole: user.userRole,
        jobTitle: "",
        emailAddress: user.userEmailAddress,
        newInvite: true
      },
      ...invited
    ]

    setIsInviteFormVisible(false)

    setTeamMembers(
      teamMembers,
      invitedMembers,
      inactiveMembers,
      invitationsResent
    )
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Flex mb="s" justifyContent="space-between">
        <H1 my="none" color="green.standard">
          {messages.manageTeam.team}
        </H1>
        {userHasPermission(UserRoles.Admin) ? (
          <Button
            type="button"
            mode="standard-green"
            size="small"
            disabled={isInviteFormVisible}
            prefix={
              <PlusIcon width={12} height={12} fill={colors.white.standard} />
            }
            onClick={handleClickInviteButton}
          >
            {messages.manageTeam.invite}
          </Button>
        ) : null}
      </Flex>
      <TeamMembers
        members={invited}
        label={messages.manageTeam.invited}
        isInviteFormVisible={isInviteFormVisible}
        onInviteUser={handleInviteUser}
      />
      <TeamMembers
        members={teamMembers}
        label={messages.manageTeam.currentTeam}
      />
      {inactiveMembers.length > 0 && (
        <TeamMembers
          members={inactiveMembers}
          label={messages.manageTeam.inactive}
        />
      )}

      <H5 mt="xs" mb="xxs" color="grey.dark">
        {messages.manageTeam.rolePermissions}
      </H5>
      {Object.entries(roleDescriptions).map(([role, permissions]) => (
        <SmallText color="grey.dark" key={role}>
          <Span fontWeight={600}>{role}</Span>
          <br />
          {permissions.map((permission, idx) => (
            <Span display="block" key={idx}>
              {permission}
            </Span>
          ))}
        </SmallText>
      ))}
    </Flex>
  )
})
