import React, { useCallback } from "react"
import { Step } from "./Step"
import { Flex, Link, colors } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { ContentContainer } from "./style"

type StepType = {
  title: string
  content: JSX.Element
}

type StepperProps = {
  steps: StepType[]
  activeTab: number
  changeTab: (activeTab: number) => void
  completedSteps: {
    [k: number]: boolean
  }
  handleComplete: (completedSteps: { [k: number]: boolean }) => void
  goToOverview: () => void
}

export const Stepper = ({
  steps,
  activeTab,
  changeTab,
  completedSteps,
  handleComplete,
  goToOverview
}: StepperProps) => {
  const completeStep = useCallback(() => {
    const newCompleted = completedSteps
    newCompleted[activeTab] = true
    handleComplete(newCompleted)
  }, [activeTab, completedSteps, handleComplete])

  const handleStep = (step: number) => {
    changeTab(step)
    completeStep()
  }

  const nextStep = useCallback(() => {
    completeStep()
    changeTab(activeTab + 1)
  }, [activeTab, changeTab, completeStep])

  const prevStep = useCallback(() => {
    completeStep()
    if (activeTab > 0) {
      changeTab(activeTab - 1)
    }
  }, [activeTab, changeTab, completeStep])

  return (
    <Flex flexDirection="column" width="100%">
      <Flex pt="m" width="100%" justifyContent="space-between">
        {steps.map((step, index) => (
          <Step
            key={step.title}
            title={step.title}
            completed={completedSteps[index]}
            active={index === activeTab}
            handleStep={() => handleStep(index)}
          />
        ))}
      </Flex>
      <ContentContainer>{steps[activeTab].content}</ContentContainer>

      <Flex justifyContent="space-between">
        <Link onClick={() => (activeTab === 0 ? null : prevStep())}>
          <Flex>
            <MdKeyboardArrowLeft color={colors.grey.dark} size="20" />
            <>{messages.form.generic.previous}</>
          </Flex>
        </Link>

        <Link
          onClick={() =>
            steps.length === activeTab + 1 ? goToOverview() : nextStep()
          }
        >
          <Flex>
            <>{messages.form.generic.next}</>
            <MdKeyboardArrowRight color={colors.grey.dark} size="20" />
          </Flex>
        </Link>
      </Flex>
    </Flex>
  )
}
