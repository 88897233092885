import { createContext, useContext } from "react"
import {
  AuthManager,
  AuthMethod,
  PossibleAuthActionSets,
  TalentisUser
} from "setup/auth/module/constants/auth.types"

type Context = {
  user: TalentisUser
  logout: () => void
  login: () => void
  manager: AuthManager
  method: AuthMethod
  isLoggedIn: boolean
  setUser: any
  actions: PossibleAuthActionSets
}

export const AuthContext = createContext<Partial<Context>>({})

export const useAuth = () => useContext(AuthContext)
