import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import { OIDCRedirectRouteAction } from "setup/auth/auth.config"
import { useAuth } from "setup/auth/module/auth.context"
import { useTalentisUser } from "setup/auth/module/auth.hooks"
import { setIsLoggedInToStorage } from "setup/auth/module/auth.utils"
import { AuthMethod } from "setup/auth/module/constants/auth.types"
import { localStorageKeys } from "setup/storage/storage.definitions"
import { useSignInExtension } from "setup/browser-extension/hooks"
import { ValueOf } from "tsdef"

const previousUrl = localStorage.getItem(localStorageKeys.location) as ValueOf<
  typeof RouterUrl
>

export const OIDCRedirectRoute = () => {
  const navigate = useNavigate()
  const { setUser, manager, method, actions } = useAuth()
  const { action } = useParams<{ action: OIDCRedirectRouteAction }>()
  const { getTalentisUser } = useTalentisUser()
  const { silentSignInExtension, silentLogoutExtension } = useSignInExtension()

  useEffect(() => {
    ;(async () => {
      try {
        if (action === OIDCRedirectRouteAction.SignIn) {
          actions && (await manager?.[actions?.signInCallback]?.())

          // Fetch the user from the Talentis API including additional info
          const retrievedUser = (await manager?.getUser()) || undefined
          const talentisUser =
            (await getTalentisUser(retrievedUser?.access_token)) || {}

          const user = { ...retrievedUser, ...talentisUser }
          setUser(user)

          method === AuthMethod.Popup && setIsLoggedInToStorage(true)
          silentSignInExtension()
        } else if (action === OIDCRedirectRouteAction.SignOut) {
          actions && (await manager?.[actions?.signOutCallback]?.())
          await manager?.removeUser()
          setUser()
          method === AuthMethod.Popup && setIsLoggedInToStorage(false)
          silentLogoutExtension()
        }
      } catch (e) {
        console.log(e)
        console.log(JSON.stringify(e))
      }
      // Redirect after all actions to prevent displaying empty page
      navigate(previousUrl || RouterUrl.Homepage)
    })()
  }, [
    setUser,
    manager,
    actions,
    navigate,
    action,
    method,
    silentSignInExtension,
    silentLogoutExtension,
    getTalentisUser
  ])
  return null
}
