import React from "react"
import { StyledSystemElement } from "@ikiru/talentis-fpc"
import { Div } from "@ikiru/talentis-fpc"
import { SmallText } from "@ikiru/talentis-fpc"

export const LegalSubtext = (
  props: { message: string } & StyledSystemElement<HTMLDivElement>
) => {
  const { message, ...divProps } = props
  return (
    <>
      {message && (
        <Div {...divProps}>
          <SmallText as="p" color="grey.dark" lineHeight="15px">
            *{message}
          </SmallText>
        </Div>
      )}
    </>
  )
}
