import React, { useEffect, useMemo, useRef, useState } from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { TabTitle } from "./TabTitle"
import { TabsInnerContainer, TabsContainerStyled } from "./style"
import ScrollButton from "components/HorizontalScroll/ScrollButton"
import useHorizantialScroll from "utils/hooks/use-horizantial-scroll"
import { Nullable } from "tsdef"

export interface TabsProps {
  children: JSX.Element[]
}

export const Tabs = ({ children }: TabsProps) => {
  const scrollElement = useRef<HTMLDivElement>(null)
  const { scrollLeft, isOverflow, disableNext, disablePrev } =
    useHorizantialScroll({ scrollElement })

  const defaultSelectedIndex = useMemo(() => {
    const foundIndex = children.findIndex((item) => item.props.defaultSelected)
    return foundIndex > -1 ? foundIndex : 0
  }, [children])

  const [selectedTab, setSelectedTab] =
    useState<Nullable<number>>(defaultSelectedIndex)

  useEffect(() => {
    const foundIndex = children.findIndex((item) => item.props.isActive)
    foundIndex > -1 && setSelectedTab(foundIndex)
  }, [children])

  return (
    <Div width="100%" mt="m">
      <Flex style={{ userSelect: "none" }}>
        <ScrollButton
          onClick={() => scrollLeft(false)}
          disabled={!isOverflow || disablePrev}
        />
        <TabsContainerStyled
          ref={scrollElement}
          pt={isOverflow ? "14px" : "0px"}
        >
          <TabsInnerContainer>
            {children.map((item, index) => (
              <TabTitle
                key={index}
                title={item.props.title}
                isActive={selectedTab === index}
                handleClick={setSelectedTab}
                index={index}
                disabled={item.props.disabled}
              />
            ))}
          </TabsInnerContainer>
        </TabsContainerStyled>
        <ScrollButton
          isNext
          onClick={scrollLeft}
          disabled={!isOverflow || disableNext}
        />
      </Flex>
      {children[selectedTab || 0]}
    </Div>
  )
}
