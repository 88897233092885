import styled from "styled-components"
import { Flex, colors, spacing } from "@ikiru/talentis-fpc"

export const StyledFlexTop = styled(Flex)`
  width: 100%;
  height: calc(60vh - 60px);
`

export const NoResultsStyled = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: ${colors.white.standard};
  margin: ${spacing.xl}px ${spacing.s}px;
  padding-top: ${spacing.l}px;
  padding-bottom: ${spacing.xxl}px;
`

export const SearchContainer = styled(Flex)<{
  isFaded: boolean
  isPerson: boolean
}>`
  flex-direction: column;
  width: 100%;
  padding-left: ${({ isPerson }) => (isPerson ? spacing.m : "0")}px;
  padding-right: ${({ isPerson }) => (isPerson ? spacing.m : "0")}px;
  padding-top: ${({ isPerson }) => (isPerson ? spacing.xl : spacing.xxs)}px;
  padding-bottom: ${({ isPerson }) => (isPerson ? spacing.xl : spacing.xxs)}px;
  opacity: ${({ isFaded }) => (isFaded ? "0.5" : "1")};
  transition: ${({ isFaded }) =>
    isFaded ? "opacity 0.2s 0.2s linear" : "opacity 0s 0s linear"};
`
