import { useCallback } from "react"
import { apiRequest } from "setup/api/api"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"

export const useTagsCandidates = () => {
  const { updateCandidateStage, setPersonOverlayUpdated } = usePerson()
  const updateAssignmentState = useAssignment()

  const onTagsSelect = useCallback(
    async (tags: number[], candidateId: any) => {
      const [error, response] = await apiRequest.patch({
        endpoint: CandidatesEndpoints.Root,
        endpointParams: candidateId,
        data: {
          tags
        }
      })

      if (response) {
        updateCandidateStage(candidateId, response.data)
        if (!updateAssignmentState.selectedCandidatedId) {
          updateAssignmentState.updateCandidateStage(candidateId, response.data)
        }
        setPersonOverlayUpdated(true)
      }
      return [error, response]
    },
    [updateCandidateStage, updateAssignmentState, setPersonOverlayUpdated]
  )

  return {
    onTagsSelect
  }
}
