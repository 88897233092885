import { Flex, H1, H3, BodyText, Div, Span } from "@ikiru/talentis-fpc"
import React from "react"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"
import { LiStyled, List } from "./styles"

export const Outlook = () => {
  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.outlook.title)}</title>
      </Helmet>
      <H1 m="0" color="green.standard">
        {messages.outlook.title}
      </H1>
      <Flex
        mt="s"
        bg="grey.lightest"
        flexDirection="column"
        alignItems="center"
      >
        <Flex width="50%" flexDirection="column" alignItems="center">
          <H1 mt="xl" color="green.standard" textAlign="center">
            {messages.outlook.description}
          </H1>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/787853136?h=2f9c0d9ae3"
            width="640"
            height="400"
            frameBorder="0"
            allowFullScreen
          />
          <BodyText mt="45px" color="grey.dark" textAlign="center">
            {messages.outlook.install}
            <Div color="grey.dark" mt="s">
              {messages.outlook.youCanGetPart1}

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://appsource.microsoft.com/en-us/product/office/WA200004909?src=office&tab=Overview"
              >
                {messages.outlook.link}
              </a>
              {messages.outlook.youCanGetPart2}
            </Div>
            <H3 mt="45px" color="grey.dark">
              {messages.outlook.list}
            </H3>
            <List>
              <LiStyled>
                {messages.outlook.in}{" "}
                <Span fontWeight="bold">{messages.outlook.outlook}</Span>
                {messages.outlook.goTo}{" "}
                <Span fontWeight="bold">{messages.outlook.home}</Span>
                {messages.outlook.tab}{" "}
                <Span fontWeight="bold">{messages.outlook.getAddIn}</Span>.
              </LiStyled>
              <LiStyled>{messages.outlook.addInDialog}</LiStyled>
              <LiStyled>
                {messages.outlook.inThe}{" "}
                <Span fontWeight="bold">{messages.outlook.search}</Span>
                {messages.outlook.box} {messages.outlook.talentis}.
              </LiStyled>
              <LiStyled>
                {messages.outlook.select}
                <Span fontWeight="bold">{messages.outlook.talentisAddIn}</Span>
                {messages.outlook.and}{" "}
                <Span fontWeight="bold">{messages.outlook.add}</Span>.
              </LiStyled>
              <LiStyled>
                {messages.outlook.see}
                <Span fontWeight="bold">{messages.outlook.talentisAdd}</Span>
                {messages.outlook.appear}
              </LiStyled>
              <LiStyled>{messages.outlook.goToEmail}</LiStyled>
            </List>
          </BodyText>
        </Flex>
      </Flex>
    </>
  )
}
