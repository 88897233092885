import styled from "styled-components"
import { Flex, WarningIcon } from "@ikiru/talentis-fpc"
import { PersonNoteModalWrapperCss } from "../../common/style"

export const PersonNoteModalWrapper = styled(Flex)`
  ${PersonNoteModalWrapperCss}
`

export const WarningIconStyled = styled(WarningIcon)`
  min-width: 30px;
`
