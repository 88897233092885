import React, { useRef, useEffect, useCallback } from "react"
import { Formik, Form, FormikHelpers, FormikProps } from "formik"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import {
  searchInputName,
  searchFormInitialValues,
  SearchFormValues
} from "./search.definitions"
import { RouterUrl } from "setup/router/routes"
import { CenteredContainer } from "components/visual/centeredContainer/centeredContainer.styled"
import { RouterLink } from "components/RouterLink/RouterLink"
import {
  Button,
  H5,
  Div,
  Flex,
  PlusIcon,
  Badge,
  LargeText,
  BodyText,
  H1,
  colors
} from "@ikiru/talentis-fpc"
import { Nullable } from "tsdef"
import {
  PaginationProps,
  Pagination
} from "components/functional/pagination/Pagination"
import { Loader } from "components/Loader/Loader"
import { PersonRecord } from "views/persons/pages/search-person/components/PersonRecord"
import {
  onSearchPersonSubmit,
  onFetchPersons
} from "./search-person/search-person.actions"
import { e2eTargets } from "./search.definitions"
import { useSearch } from "../../../contacts/SearchModule/context"
import { PersonProfileWrapper } from "../manage-person/PersonProfileWrapper"
import ContactsOverlay from "../../components/person-overlay/ContactsOverlay"
import { formatResults, getPersonsWithPhotos } from "./utils"
import ActionButton from "components/ProjectActions/ActionButton"

export const SearchPerson = () => {
  const {
    persons,
    setPersonsFound,
    isLoading,
    setIsLoading,
    paginationProps: pagination,
    setPagination,
    selectedSearchPersonId
  } = useSearch()
  const inputRef = useRef<Nullable<HTMLInputElement>>()
  const isMoreThanPageResults = pagination && pagination.pageCount > 1

  const populateInitialResults = useCallback(async () => {
    setIsLoading(true)
    const { results, pagination } = await onFetchPersons("*")
    const personsFound = formatResults(results)
    // @ts-ignore
    setPersonsFound(personsFound)
    setIsLoading(false)
    setPagination(pagination)
    const personWithPhotos = await getPersonsWithPhotos(personsFound)
    setPersonsFound(personWithPhotos)
  }, [setIsLoading, setPagination, setPersonsFound])

  useEffect(() => {
    populateInitialResults()
  }, [populateInitialResults])

  const populateResults = async (
    values: SearchFormValues,
    newPagination: PaginationProps,
    helpers: FormikHelpers<SearchFormValues>
  ) => {
    setIsLoading(true)
    const response = await onSearchPersonSubmit(values, helpers, newPagination)
    const personsFound = formatResults(response.results)
    // @ts-ignore
    setPersonsFound(personsFound)
    setPagination(response.pagination)
    const personWithPhotos = await getPersonsWithPhotos(personsFound)
    setPersonsFound(personWithPhotos)

    setIsLoading(false)
  }

  const onSubmit = async (
    values: SearchFormValues,
    helpers: FormikHelpers<SearchFormValues>
  ) => {
    inputRef?.current?.blur()

    populateResults(
      values,
      { ...pagination, pageNumber: 1 } as PaginationProps,
      helpers
    )
  }

  const onPageChange =
    (formik: FormikProps<SearchFormValues>) => (pageNumber: number) => {
      const { values, setFieldError, setSubmitting } = formik

      populateResults(
        values,
        { ...pagination, pageNumber: pageNumber } as PaginationProps,
        { setFieldError, setSubmitting } as FormikHelpers<SearchFormValues>
      )
    }

  return (
    <Formik initialValues={searchFormInitialValues} onSubmit={onSubmit}>
      {(formik) => {
        const { isSubmitting } = formik
        // @ts-ignore
        return (
          <Div>
            <Flex
              mb="s"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                <H1 m={0} color={colors.green.standard}>
                  {messages.searchPerson.title}
                </H1>
                {Boolean(pagination && pagination?.totalItemCount > 0) && (
                  <Badge
                    bg="grey.standard"
                    color="white.standard"
                    height={24}
                    ml="xxs"
                    mb="xxs"
                  >
                    <H5 color="white.standard" my="none">
                      {pagination?.totalItemCount}
                    </H5>
                  </Badge>
                )}
              </Flex>
              <Div>
                <RouterLink to={RouterUrl.ProfileNewContact}>
                  <ActionButton
                    type="button"
                    mode="standard-green"
                    prefix={
                      <PlusIcon
                        width={12}
                        height={12}
                        fill={colors.white.standard}
                      />
                    }
                    size="small"
                    label={messages.searchPerson.addContact}
                  />
                </RouterLink>
              </Div>
            </Flex>
            <Form>
              <Flex p="xs" bg={colors.grey.light} flexWrap="wrap">
                <Flex mr="xs" flexGrow={[1, 0]} alignItems="center">
                  <Div width="100%" minWidth={["100%", "400px"]}>
                    <FormikInput
                      onClear={populateInitialResults}
                      ref={inputRef}
                      name={searchInputName}
                      id={searchInputName}
                      label={messages.form.generic.enterAPearsonsName}
                    />
                  </Div>
                </Flex>
                <Button
                  my="xs"
                  data-e2e-target={e2eTargets.submitButton}
                  type="submit"
                  mode="standard-green"
                  size="small"
                  disabled={isSubmitting}
                >
                  {messages.form.generic.searchContacts}
                </Button>
              </Flex>
            </Form>
            <CenteredContainer>
              <Div>
                {!isLoading ? (
                  persons?.length ? (
                    <Flex width="100%" flexWrap="wrap">
                      <Div
                        data-e2e-target={e2eTargets.results}
                        data-e2e-target-name={e2eTargets.personResults}
                        px="s"
                        pb={!isMoreThanPageResults ? "xs" : 0}
                        bg={colors.grey.lightest}
                        width="100%"
                      >
                        {persons.map((item) => (
                          // @ts-ignore
                          <PersonRecord key={item.id} {...item} />
                        ))}
                        {Boolean(isMoreThanPageResults) ? (
                          <Flex
                            width="100%"
                            justifyContent="center"
                            mt="xs"
                            mb="xs"
                          >
                            <Pagination
                              {...(pagination as PaginationProps)}
                              onPageChange={onPageChange(formik)}
                            />
                          </Flex>
                        ) : null}
                      </Div>
                    </Flex>
                  ) : (
                    <Div bg="grey.lightest" p="m">
                      <Div width={["100%", "450px"]}>
                        <LargeText
                          m={0}
                          color="grey.dark"
                          data-e2e-target={e2eTargets.noResults}
                        >
                          {messages.search.noContactsFound}
                        </LargeText>
                        <BodyText color="grey.standard">
                          {messages.search.toCreateContacts}
                        </BodyText>
                        <BodyText color="grey.standard">
                          {messages.search.useTheSearch}
                        </BodyText>
                      </Div>
                    </Div>
                  )
                ) : (
                  <Div mt="xs">
                    <Loader />
                  </Div>
                )}
                {selectedSearchPersonId && (
                  <PersonProfileWrapper>
                    <ContactsOverlay onPageChange={onPageChange(formik)} />
                  </PersonProfileWrapper>
                )}
              </Div>
            </CenteredContainer>
          </Div>
        )
      }}
    </Formik>
  )
}
