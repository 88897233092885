import { useCallback, useEffect, useState } from "react"
import { apiRequest } from "setup/api/api"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import { Assignment } from "views/assignments/assignment.types"
import { geoLocationForLocation } from "views/assignments/utils"
import { GetSimpleAssignmentsResponse, ITEMS_TO_FETCH } from "./definitions"

const useActiveAssignments = () => {
  const [requestSent, setRequestSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasAssignments, setHasAssignments] = useState(false)
  const [activeAssignments, setActiveAssignments] = useState<Assignment[]>([])

  const fetchActiveAssignments = useCallback(async () => {
    setIsLoading(true)
    setRequestSent(true)

    const [, response] = await apiRequest.get<GetSimpleAssignmentsResponse>({
      endpoint: AssignmentsEndpoints.SimpleList,
      config: { params: { pageSize: ITEMS_TO_FETCH } }
    })

    const assignments = response?.data?.simpleActiveAssignments || []
    geoLocationForLocation(assignments)
    const hasAssignments =
      assignments.length > 0 || response?.data?.hasAssignments || false

    setActiveAssignments(assignments)
    setHasAssignments(hasAssignments)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchActiveAssignments()
  }, [fetchActiveAssignments])

  return {
    isLoading,
    hasAssignments,
    activeAssignments,
    requestSent
  }
}

export { useActiveAssignments }
