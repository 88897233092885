import { FilterType } from "views/search/SearchModule/types"

export const keywordsTemplateString = `${FilterType.ExcludeKeywordSearch}.keywords`
export const keywordsRolesLogicTemplateString = `${FilterType.ExcludeKeywordSearch}.keywordsSearchLogic`
export const keywordsRecordPersonTemplateString = `${FilterType.ExcludeKeywordSearch}.keywordsSearchRecordPerson`
export const keywordsRecordCompanyTemplateString = `${FilterType.ExcludeKeywordSearch}.keywordsSearchRecordCompany`

export const validFilter = [
  "industries",
  "jobTitleBundle",
  "azureLocations",
  "companyNames",
  "keywordBundle"
]
