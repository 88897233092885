import * as Yup from "yup"
import { CreatePersonField } from "views/persons/components/person-details/form/constants/definitions"
import { urlRegex } from "utils/regexes"
import { messages } from "setup/messages/messages"

export const createPersonValidationSchema = Yup.object({
  [CreatePersonField.Name]: Yup.object({
    value: Yup.string()
      .max(110, messages.form.generic.errors.mustBeNoLongerThan.format(110))
      .required(messages.generic.required)
  }),
  [CreatePersonField.EmailAddresses]: Yup.array().of(
    Yup.object({
      value: Yup.object({
        email: Yup.string()
          .max(255, messages.form.generic.errors.mustBeNoLongerThan.format(255))
          .email(messages.person.details.errors.invalidEmail),
        smtpValid: Yup.string().nullable()
      })
    })
  ),
  [CreatePersonField.PhoneNumbers]: Yup.array().of(
    Yup.object({
      value: Yup.object({
        phoneNumber: Yup.string().max(
          27,
          messages.form.generic.errors.mustBeNoLongerThan.format(27)
        )
      })
    })
  ),
  [CreatePersonField.Company]: Yup.string().max(
    110,
    messages.form.generic.errors.mustBeNoLongerThan.format(110)
  ),
  [CreatePersonField.LinkedInUrl]: Yup.object({
    value: Yup.string()
      .matches(urlRegex, messages.person.details.errors.invalidLink)
      .max(150, messages.form.generic.errors.mustBeNoLongerThan.format(150))
  }),
  [CreatePersonField.WebSites]: Yup.array().of(
    Yup.object({
      value: Yup.object({
        url: Yup.string().matches(
          urlRegex,
          messages.form.generic.errors.urlFormat
        ),
        type: Yup.string()
      })
    })
  )
})
