import React from "react"
import { Button } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { personAvatarE2ETargets } from "views/persons/components/person-avatar/constants/person-avatar.definitions"

type ManageFirstAvatarProps = {
  onUploadButtonClick: (event?: React.MouseEvent) => void
}

export const ManageFirstAvatar = (props: ManageFirstAvatarProps) => {
  const { onUploadButtonClick } = props
  return (
    <Button
      data-e2e-target={personAvatarE2ETargets.uploadButton}
      mode="primary"
      size="small"
      onClick={onUploadButtonClick}
      type="button"
    >
      {messages.person.avatar.uploadCta}
    </Button>
  )
}
