import styled from "styled-components"
import { Flex, theme } from "@ikiru/talentis-fpc"

export const ComingSoonWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${theme.colors.grey.light};
  height: fit-content;
  min-width: 240px;
  max-width: 260px;
  margin-left: ${theme.space.l}px;
  padding-bottom: ${theme.space.l}px;
  border-radius: 10px;
  margin-bottom: 30px;
`

export const GPTHeaderContainer = styled(Flex)`
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0px auto;
`
