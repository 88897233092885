import React from "react"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { messages } from "setup/messages/messages"
import PersonKeywordView from "./components/PersonKeywordView"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import PersonKeywordEdit from "./form/PersonKeywordEdit"
import { updateKeyword } from "./form/actions"
import PersonSections from "../person-components/PersonSections"
import PersonSectionButton from "../person-components/PersonSectionButton"
import { Keyword } from "views/persons/person-module/person-module.types"

const PersonKeywords = () => {
  const { keywords, personId, setKeywords, isLoading } = usePerson()
  const { open: openModal, close } = useModal()

  const onSubmit = async (newKeywords: Keyword[]) => {
    const resp = await updateKeyword(personId, newKeywords)

    setKeywords(resp?.keywords)
    close(ModalNames.EditKeywords)
  }

  const addKeyword = () => {
    openModal(
      ModalNames.EditKeywords,
      <PersonKeywordEdit onSubmit={onSubmit} currentKeywords={keywords} />
    )
  }

  return (
    <PersonSections
      title={messages.form.generic.keywords}
      actions={
        <PersonSectionButton
          label={messages.form.generic.keywords}
          onClick={addKeyword}
          disabled={isLoading}
        />
      }
    >
      <PersonKeywordView keywords={keywords} />
    </PersonSections>
  )
}

export default PersonKeywords
