import React, { useCallback } from "react"
import { Button, Flex, H4, colors } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { ParsingResumeModalWrapper } from "./style"
import { Stepper } from "components/FPCComponents/Stepper/Stepper"
import { ParsePersonDetails } from "./ParsePersonDetails"
import { ParseContactInfo } from "./ParseContactInfo"
import { ParseProfessionalExperience } from "./ParseProfessionalExperience"
import { ParseSkills } from "./ParseSkills"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { OverviewCVModal } from "../overview/OverviewCVModal"
import { CVParsingAlertModal } from "./CVParsingAlertModal"
import cloneDeep from "lodash/cloneDeep"
import {
  hasPersonDetailsTemp,
  hasTempValue,
  removeTempContactInfo,
  removeTempPersonDetails
} from "./helper"

export const ParsingResumeModal = () => {
  const {
    activeTab,
    setActiveTab,
    completedCVSteps,
    setCompletedCVSteps,
    cvParsing,
    setCVParsing,
    setPreviousButtonClicked
  } = usePerson()
  const { close, open } = useModal()

  const closeParseCV = () => {
    close(ModalNames.ParsingResume)
    setActiveTab(0)
    setCompletedCVSteps({})
    setPreviousButtonClicked(0)
  }

  const closeWithoutSaving = useCallback(() => {
    const tempCVParsing = cloneDeep(cvParsing)
    removeTempPersonDetails(tempCVParsing.personDetails)
    removeTempContactInfo(tempCVParsing.contactInfo)

    setCVParsing({ ...tempCVParsing })
  }, [setCVParsing, cvParsing])

  const checkUnsavedChanges = useCallback(() => {
    const unsavedChanges = hasPersonDetailsTemp(cvParsing.personDetails)

    const unsavedContactInfo = hasTempValue(cvParsing.contactInfo)

    return unsavedChanges || unsavedContactInfo
  }, [cvParsing.contactInfo, cvParsing.personDetails])

  const tempSelectedActiveTab = useCallback(
    (activeTab: number) => {
      setPreviousButtonClicked(activeTab)
      const unsavedChanges = checkUnsavedChanges()
      if (unsavedChanges) {
        open(
          ModalNames.CVParsingAlertModal,
          <CVParsingAlertModal closeWithoutSaving={closeWithoutSaving} />
        )
      } else {
        setActiveTab(activeTab)
      }
    },
    [
      setPreviousButtonClicked,
      open,
      setActiveTab,
      checkUnsavedChanges,
      closeWithoutSaving
    ]
  )

  const openOverview = useCallback(() => {
    setPreviousButtonClicked("overview")
    const unsavedChanges = checkUnsavedChanges()
    if (unsavedChanges) {
      open(
        ModalNames.CVParsingAlertModal,
        <CVParsingAlertModal closeWithoutSaving={closeWithoutSaving} />
      )
    } else {
      close(ModalNames.ParsingResume)
      open(ModalNames.OverviewCVModal, <OverviewCVModal />)
    }
  }, [
    open,
    close,
    checkUnsavedChanges,
    setPreviousButtonClicked,
    closeWithoutSaving
  ])

  const steps = [
    {
      title: messages.person.cvparsing.stepPersonDetails,
      content: <ParsePersonDetails />
    },
    {
      title: messages.person.cvparsing.stepContactInfo,
      content: <ParseContactInfo />
    },
    {
      title: messages.person.cvparsing.stepProfessionalExperience,
      content: <ParseProfessionalExperience />
    },
    {
      title: messages.person.cvparsing.stepSkills,
      content: <ParseSkills />
    }
  ]

  return (
    <ParsingResumeModalWrapper>
      <Flex justifyContent="space-between" width="100%">
        <H4 color={colors.grey.dark} mt="0">
          {messages.person.cvparsing.titleModal}
        </H4>

        <Flex>
          <Button
            onClick={() => openOverview()}
            mode="primary"
            size="small"
            mr="xs"
          >
            {messages.person.cvparsing.finishReview}
          </Button>

          <Button onClick={closeParseCV} mode="standard-white" size="small">
            {messages.generic.cancel}
          </Button>
        </Flex>
      </Flex>
      <Stepper
        steps={steps}
        activeTab={activeTab}
        changeTab={(e) => {
          tempSelectedActiveTab(e)
        }}
        completedSteps={completedCVSteps}
        handleComplete={setCompletedCVSteps}
        goToOverview={() => openOverview()}
      />
    </ParsingResumeModalWrapper>
  )
}
