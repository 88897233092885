import styled from "styled-components"
import styledCss from "@styled-system/css"
import { Div, Flex } from "@ikiru/talentis-fpc"
import {
  H1Style as H1,
  H4Style as H4,
  H6Style as H6
} from "@ikiru/talentis-fpc"

export const SummaryHeader = styled(Div)`
  ${styledCss(H6)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space.xxs}px;
`

export const SummaryPrice = styled(Flex)`
  ${styledCss(H1)}
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.green.standard};
  margin-bottom: ${({ theme }) => theme.space.xxs}px;
`

export const PlanName = styled(Div)`
  ${styledCss(H4)}
  width: 100%;
  margin: 0;
`
