import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { CandidateAddedFrom } from "setup/app-insights/definitions"
import { ModalNames } from "setup/modal/modal.definitions"
import { RouterUrl } from "setup/router/routes"
import { useModal } from "utils/hooks/use-modal"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { addPersonToAssignment } from "views/persons/components/person-assignments/form/actions"
import { ContactsEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"

const useAddPersonToProject = () => {
  const { trackAddCandidate } = useTelemetry()
  const { close, onClose } = useModal()
  const navigate = useNavigate()

  const addAssignmentToPerson = async (
    assignmentId: string,
    personId: string
  ) => {
    const [, response] = await addPersonToAssignment(assignmentId, personId)

    if (response) {
      trackAddCandidate({
        addedFrom: CandidateAddedFrom.MainSiteSearch,
        assignmentId: assignmentId,
        localPersonId: personId
      })
    }

    return response?.data?.assignmentId
  }

  const addCampaignToPerson = async (campaignId: string, personId: string) => {
    const [, response] = await apiRequest.post({
      endpoint: ContactsEndpoints.Root,
      data: {
        campaignId: campaignId,
        personId: personId
      }
    })
    return response?.data?.campaignId
  }

  const closeAndRedirectToAssignment = useCallback(
    (assignId: string) => {
      onClose(ModalNames.NewContact, () => {
        navigate(`${RouterUrl.AssignmentDetails}/${assignId}`)
      })

      close(ModalNames.NewContact)
    },
    [close, navigate, onClose]
  )

  const closeAndRedirectToCampaign = useCallback(
    (campaignId: string) => {
      onClose(ModalNames.NewContact, () => {
        navigate(`${RouterUrl.CampaignDetails}/${campaignId}`)
      })

      close(ModalNames.NewContact)
    },
    [close, navigate, onClose]
  )
  return {
    addAssignmentToPerson,
    closeAndRedirectToCampaign,
    closeAndRedirectToAssignment,
    addCampaignToPerson
  }
}

export default useAddPersonToProject
