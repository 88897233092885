import { Flex, H5, Loader } from "@ikiru/talentis-fpc"
import React from "react"
import { messages } from "setup/messages/messages"

const LoadingSearch = () => {
  return (
    <Flex flexDirection="column" mt="48px" mb="78px">
      <Loader />
      <H5 mt="25px">{messages.search.chatGPT.workingMagic}</H5>
    </Flex>
  )
}

export default LoadingSearch
