import React, { useCallback, useEffect } from "react"
import {
  Badge,
  Div,
  Flex,
  H5,
  Loader,
  SmallText,
  colors
} from "@ikiru/talentis-fpc"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { useAssignmentCompanies } from "./hooks"
import { CompanyRecord } from "./components/CompanyRecord"
import { Pagination } from "components/functional/pagination/Pagination"
import { CandidatesListActions } from "../candidates-list/components/candidates-list-actions"
import { messages } from "setup/messages/messages"
import { AssignmentCompany } from "views/assignments/assignment-module/assignment-module.types"
import { SectionBodyStyled } from "./components/styles"

export const AssignmentTargetCompanies = () => {
  const { isLoadingCompanies, getCompanies } = useAssignmentCompanies()
  const {
    companies,
    companiesCount,
    companyPage,
    setCompanyPage,
    setSelectedTab,
    totalItems
  } = useAssignment()

  const handlePageChange = useCallback(
    (page: number) => {
      setCompanyPage({ ...companyPage, pageNumber: page })
      getCompanies(page)
    },
    [companyPage, getCompanies, setCompanyPage]
  )

  useEffect(() => {
    getCompanies(1)
    setSelectedTab("companyCandidate")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Div backgroundColor="#f2f6f7">
      <Flex
        backgroundColor={colors.grey.light}
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Flex pl="xs" alignItems="baseline">
          <H5
            mt="xs"
            ml="xs"
            mr="xxs"
            color={colors.grey.dark}
            fontWeight="500"
          >
            {messages.companiesSearch.targetCompanies}
          </H5>
          <Badge bg={colors.grey.standard} height={15}>
            <SmallText
              m={0}
              fontSize={13}
              fontWeight={"700"}
              color={colors.white.standard}
            >
              {companiesCount}
            </SmallText>
          </Badge>
        </Flex>

        <Div>
          <CandidatesListActions disabled={!Boolean(totalItems)} />
        </Div>
      </Flex>

      <>
        {isLoadingCompanies ? (
          <Div mt="m">
            <Loader />
          </Div>
        ) : Boolean(companies.length) ? (
          <>
            <Div px="s">
              {companies?.map((company: AssignmentCompany) => (
                <CompanyRecord {...company} />
              ))}
            </Div>
            <Flex width="100%" flexDirection="column">
              {companyPage.pageCount > 1 && (
                <Flex width="100%" justifyContent="center" mb={20}>
                  <Pagination
                    {...companyPage}
                    onPageChange={handlePageChange}
                  />
                </Flex>
              )}
            </Flex>
          </>
        ) : (
          <SectionBodyStyled>
            {messages.companiesSearch.noTargetCompanies}
          </SectionBodyStyled>
        )}
      </>
    </Div>
  )
}
