import React, { useCallback, useMemo } from "react"
import ProjectActionPopUp from "components/ProjectActions/ProjectActionPopUp"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"
import { messages } from "setup/messages/messages"
import { RouterUrl } from "setup/router/routes"
import { useNavigate } from "react-router-dom"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { useExportProjectRecords } from "components/ProjectActions/ProjectExport/hooks/useExportProjectRecords"
import {
  CampaignsEndpoints,
  ContactsEndpoints
} from "setup/api/endpoints/endpoints"
import { getAppliedFiltersParams } from "components/ProjectRecordsFilters/helpers"
import { apiRequest } from "setup/api/api"
import { Contact } from "../contacts/type"

const CampaignActions = () => {
  const { campaignId, campaignDetails, appliedFilters, paginationContact } =
    useCampaign()
  const navigate = useNavigate()
  const isDisabled = useMemo(
    () =>
      paginationContact.totalItemCount
        ? paginationContact.totalItemCount > 0
        : false,
    [paginationContact.totalItemCount]
  )

  const { exportProjectRecords } = useExportProjectRecords({
    projectId: campaignId,
    projectName: campaignDetails?.name || "",
    projectRoute: CampaignsEndpoints.ExportCampaignContacts,
    exportPrefix: messages.campaign.details.exportPrefix,
    totalItems: paginationContact.totalItemCount
  })

  const filterExists = useMemo(
    () => appliedFilters.length !== 0,
    [appliedFilters]
  )

  const exportWithFilters = useCallback(async () => {
    const params = getAppliedFiltersParams(appliedFilters)
    params.append("expand", "person")
    params.append(
      "pageSize",
      paginationContact.totalFilteredCount?.toString() || ""
    )

    const [, response] = await apiRequest.get({
      endpoint: ContactsEndpoints.SearchById,
      endpointParams: { campaignId: campaignId },
      config: { params: params }
    })

    const contacts = response?.data?.data
    const contactIds = (contacts || []).map((contact: Contact) => contact.id)
    exportProjectRecords(contactIds)
  }, [paginationContact, appliedFilters, campaignId, exportProjectRecords])

  const options: HeaderOption[] = [
    {
      label:
        messages.campaign.details.action.addContact +
        " - " +
        messages.assignment.details.actions.quickSearch,
      onClick: () => {
        navigate(`${RouterUrl.ProfileNewContact}?campaignId=${campaignId}`)
      }
    },
    {
      label:
        messages.campaign.details.action.addContact +
        " - " +
        messages.assignment.details.candidates.advanceSearch,
      onClick: () => {
        navigate(RouterUrl.Search + `/${campaignId}/campaign`)
      }
    },
    {
      label:
        messages.assignment.details.actions.addCandidate +
        " - " +
        messages.assignment.details.actions.searchLocalDatabase,
      onClick: () => {
        navigate(`${RouterUrl.InternalSearch}?campaignId=${campaignId}`)
      }
    },
    {
      label:
        messages.assignment.details.candidates.export +
        " " +
        messages.assignment.details.candidates.exportAll,
      onClick: () => exportProjectRecords(null),
      disabled: !isDisabled
    },
    {
      label:
        messages.assignment.details.candidates.export +
        " " +
        messages.assignment.details.candidates.exportFilteredView,
      onClick: () => {
        exportWithFilters()
      },
      disabled: !filterExists
    }
  ]

  return (
    <>
      <ProjectActionPopUp options={options} />
    </>
  )
}

export default CampaignActions
