import React from "react"
import { NavigationArrowContainer } from "./style"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"

type ScrollButtonProps = {
  disabled: boolean
  onClick: () => void
  isNext?: boolean
  isRowTab?: boolean
}
const ScrollButton = ({
  disabled,
  isNext,
  isRowTab = false,
  onClick
}: ScrollButtonProps) => {
  return (
    <NavigationArrowContainer
      isRowTab={isRowTab}
      disabled={disabled}
      onClick={onClick}
    >
      {!isNext ? (
        <MdKeyboardArrowLeft size={20} />
      ) : (
        <MdKeyboardArrowRight size={20} />
      )}
    </NavigationArrowContainer>
  )
}

export default ScrollButton
