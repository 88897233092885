import { useCallback } from "react"
import { apiRequest } from "setup/api/api"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { useTelemetry } from "utils/hooks/use-telemetry"

export const useTagsCandidates = () => {
  const { updateCandidateStage, selectedTab, updateCompanyCandidate } =
    useAssignment()
  const { trackAddTagsToCandidate } = useTelemetry()

  const onTagsSelect = useCallback(
    async (tags: number[], candidateId: any) => {
      trackAddTagsToCandidate()

      const [error, response] = await apiRequest.patch({
        endpoint: CandidatesEndpoints.Root,
        endpointParams: candidateId,
        data: {
          tags
        }
      })

      if (selectedTab === "companyCandidate") {
        response && updateCompanyCandidate(response.data)
      }

      response && updateCandidateStage(candidateId, response.data)

      return [error, response]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateCandidateStage, trackAddTagsToCandidate]
  )

  return {
    onTagsSelect
  }
}
