import React from "react"
import { Route, Routes } from "react-router-dom"
import { RouterSubUrl } from "setup/router/routes"
import { CompanyDetailsWrapper } from "./pages/company-details/CompanyDetailsWrapper"

export const Companies = () => {
  return (
    <Routes>
      <Route
        path={`${RouterSubUrl.CompanyDetails}/:companyId`}
        element={<CompanyDetailsWrapper />}
      />
    </Routes>
  )
}
