import React from "react"
import { messages } from "setup/messages/messages"
import { Loader } from "components/Loader/Loader"
import { Flex } from "@ikiru/talentis-fpc"
import { BodyText, H3, H5, SmallText } from "@ikiru/talentis-fpc"
import { RouterLink } from "components/RouterLink/RouterLink"
import { RouterUrl } from "setup/router/routes"
import { Line } from "@ikiru/talentis-fpc"
import { PaidSubscriptionDetails } from "../../definitions"
import { useFirmUsers } from "../../hooks"
import { usePaidPlanDetails } from "./hooks"

interface PaidPlanDetailsProps {
  paidPlanDetails: PaidSubscriptionDetails
}

export const PaidPlanDetails = (props: PaidPlanDetailsProps) => {
  const { isLoading, currentUsersCount } = useFirmUsers()
  const {
    planName,
    planPeriod,
    totalUsers,
    renewalDate,
    renewalCost,
    renewalCostPerUser
  } = usePaidPlanDetails(props.paidPlanDetails)

  return (
    <>
      <H3 m="0" color="green.dark">
        {planName}
      </H3>
      <Flex alignItems="center">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <H3 m="0" mr="xs" color="green.dark">
              {messages.billing.currentPlan.numberOfUsers.format(totalUsers)}
            </H3>
            <SmallText fontWeight="600" mb="0" mr="xs">
              (
              {messages.billing.currentPlan.usedUsers.format(
                currentUsersCount || 1,
                totalUsers
              )}
              )
            </SmallText>
            <RouterLink
              mb="0"
              size="small"
              to={RouterUrl.Team}
              href={RouterUrl.Team}
            >
              {messages.billing.currentPlan.manageTeam}
            </RouterLink>
          </>
        )}
      </Flex>

      <Line variant="thin" mt="s" />

      <H5 mt="xs" mb="0">
        {planPeriod}
      </H5>

      <BodyText mt="0">
        {messages.billing.currentPlan.renewalDate.format(renewalDate)}
        <br />
        {messages.billing.currentPlan.renewalCost.format(
          renewalCost,
          renewalCostPerUser
        )}
      </BodyText>
    </>
  )
}
