import { FormikHelpers } from "formik"
import { apiRequest } from "setup/api/api"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import { FormAction } from "utils/forms/form.types"
import { ClientPortalShareValues } from "./constants/client-portal-share-form.definitions"
import { expandEndpointByParams } from "setup/api/api.helpers"

export type OnClientPortalShareCreateSubmit =
  FormAction<ClientPortalShareValues>

type ApiRequestType = (
  values: ClientPortalShareValues
) => Promise<NormalizedReturnTuple>

const onUpdate =
  (request: ApiRequestType) =>
  async (
    values: ClientPortalShareValues,
    helpers: FormikHelpers<ClientPortalShareValues>
  ) => {
    const { setFieldError, setSubmitting } = helpers
    setSubmitting(true)

    const [error, response] = await request(values)

    setSubmitting(false)
    callWithErrorMessages(setFieldError, error)
    return [error, response]
  }

export const onCreateClientPortalShareSubmit = (assignmentId: string) =>
  onUpdate((data) =>
    apiRequest.put({
      endpoint: AssignmentsEndpoints.Shares,
      data,
      endpointParams: { assignmentId }
    })
  )

export const onGetClientPortalShares = async (assignmentId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: AssignmentsEndpoints.Shares,
    endpointParams: { assignmentId }
  })

  return response?.data?.portalUsers || []
}

export const onDeleteClientPortalShares = async (
  assignmentId: string,
  email: string
) => {
  const [, response] = await apiRequest.delete({
    endpoint: AssignmentsEndpoints.Shares,
    endpointParams: { assignmentId },
    config: expandEndpointByParams({ email })
  })

  return response?.data?.portalUsers || []
}
