import styled, { css } from "styled-components"
import styledCss from "@styled-system/css"
import { Div, Span } from "@ikiru/talentis-fpc"
import { H4Style as H4, H6Style as H6 } from "@ikiru/talentis-fpc"
import { formFieldStatus, FormFieldStatus } from "@ikiru/talentis-fpc"

type ChargebeeInputWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  status: FormFieldStatus
  hasFocus: boolean
}

const borderWidth = "3px"

export const sharedInputLabelStyles = css`
  padding-left: ${({ theme }) => theme.space.xxs}px;
  padding-right: ${({ theme }) => theme.space.s}px;
  padding-bottom: ${({ theme }) => theme.space.xxs}px;
`

export const styledLabelFocusStyles = css`
  ${styledCss(H6)};
  top: 0;
  height: auto;
  bottom: initial;
  margin: 0;
  padding-top: ${({ theme }) => theme.space.xxs}px;
`

export const ChargebeeInputWrapper = styled(Div)<ChargebeeInputWrapperProps>`
  position: relative;
  background: white;
  color: ${({ theme }) => theme.colors.grey.dark};

  width: 100%;
  height: 50px;
  margin: 0;
  text-overflow: ellipsis;

  ${sharedInputLabelStyles}

  border: none;
  border-style: solid;
  border-width: 0 0 ${borderWidth} 0;
  border-radius: 0;

  ${formFieldStatus}

  padding-top: 23px;
  transition: border-color linear 0.2s;

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey.standard};
  }

  ${({ theme, hasFocus }) =>
    hasFocus && `border-color: ${theme.colors.grey.standard};`}

  iframe {
    min-height: 50px;
  }
`

export const ChargebeeInputLabel = styled(Span)`
  ${styledCss(H4)}
  pointer-events: none;

  margin: 0;

  bottom: 0;
  position: absolute;

  display: flex;
  align-items: flex-end;

  width: 100%;
  box-sizing: border-box;

  color: ${({ theme }) => theme.colors.grey.standard};

  margin-bottom: ${borderWidth};

  &.minimize-label {
    ${styledLabelFocusStyles}
  }
`
