import { colors, Div, H1, Flex, SectionHeader } from "@ikiru/talentis-fpc"
import React from "react"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"
import { SupportForm } from "./components/supportForm"
import { Tutorials } from "./Tutorials"
import { StyledDiv, SupportFlex } from "./style"

export const Support = () => {
  return (
    <Flex flexDirection={"column"}>
      <Helmet>
        <title>{messages.seo.title.format(messages.support.title)}</title>
      </Helmet>
      <H1 m="0" color="green.standard">
        {messages.support.title}
      </H1>
      <SupportFlex>
        <Div bg="grey.lightest" mt="m" mr="m">
          <SectionHeader
            color={colors.grey.dark}
            size="xSmall"
            title={messages.supportForm.tutorials}
          />
          <Tutorials />
        </Div>
        <StyledDiv>
          <SectionHeader
            color={colors.grey.dark}
            size="xSmall"
            title={messages.supportForm.title}
          />
          <SupportForm />
        </StyledDiv>
      </SupportFlex>
    </Flex>
  )
}
