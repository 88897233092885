import { Assignment } from "views/assignments/assignment.types"

export enum AssignmentDetailsFields {
  Name = "name",
  CompanyName = "companyName",
  CompanyId = "companyId",
  JobTitle = "jobTitle",
  Location = "location",
  StartDate = "startDate",
  Status = "status",
  GeoLocation = "geoLocation"
}

export type AssignmentDetails = Pick<
  Assignment,
  | "companyName"
  | "jobTitle"
  | "location"
  | "status"
  | "geoLocation"
  | "companyId"
>
