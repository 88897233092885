// @ts-nocheck

import { RouterUrl } from "setup/router/routes"

const loadUserguiding = (g, u, i, d, e, s) => {
  g[e] = g[e] || []
  var f = u.getElementsByTagName(i)[0]
  var k = u.createElement(i)
  k.async = true
  k.src =
    "https://static.userguiding.com/media/user-guiding-" + s + "-embedded.js"
  k.id = "userGuidingScript"
  f.parentNode.insertBefore(k, f)
  if (g[d]) return
  var ug = (g[d] = {
    q: []
  })
  ug.c = function (n) {
    return function () {
      ug.q.push([n, arguments])
    }
  }
  var m = [
    "previewGuide",
    "finishPreview",
    "track",
    "triggerNps",
    "hideChecklist",
    "launchChecklist"
  ]
  for (var j = 0; j < m.length; j += 1) {
    ug[m[j]] = ug.c(m[j])
  }
}

const setupUserGuiding = (location?: Location<unknown>) => {
  const currentLocation = location || window.location
  const isSignupPage =
    currentLocation.pathname.toLowerCase() === RouterUrl.Signup
  const isInitialized = !!document.getElementById("userGuidingScript")

  if (window?.fpConfig?.showUserGuiding && !isSignupPage && !isInitialized) {
    loadUserguiding(
      window,
      document,
      "script",
      "userGuiding",
      "userGuidingLayer",
      "881154067ID"
    )
  }

  if (isSignupPage) {
    removeUserGuiding()
  }
}

const removeUserGuiding = () => {
  const head = document.getElementsByTagName("head")[0]
  const script = document.getElementById("userGuidingScript")
  script && head.removeChild(script)
}

export { setupUserGuiding, removeUserGuiding }
