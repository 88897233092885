import React from "react"
import { Button, H5, colors } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ExistingDataColumnWrapper } from "./style"

type ExistingDataProps = {
  buttonText?: string
  deleteAll?: () => void
}

export const ExistingData = ({ buttonText, deleteAll }: ExistingDataProps) => {
  return (
    <ExistingDataColumnWrapper>
      <H5 m="0" color={colors.white}>
        {messages.person.cvparsing.existingData}
      </H5>
      {deleteAll && (
        <Button mode="standard-white" size="extra-small" onClick={deleteAll}>
          {buttonText || messages.person.cvparsing.updateAll}
        </Button>
      )}
    </ExistingDataColumnWrapper>
  )
}
