import React, { useRef } from "react"
import { ModifyCompanyDetails } from "views/companies/components/company-details/components/ModifyCompanyDetails"
import { ViewCompanyDetails } from "views/companies/components/company-details/components/ViewCompanyDetails"
import { Button, Flex } from "@ikiru/talentis-fpc"
import { EditableSection } from "components/EditableSection"
import { messages } from "setup/messages/messages"
import { editableSectionE2eTargets } from "components/EditableSection/definitions"
import { e2eTargets } from "./definitions"
import { CompanyDetailsWrapper } from "./style"
import { CompanyAssignments } from "./components/CompanyAssignments"
import { Tabs } from "./components/Tabs/Tabs"
import Tab from "./components/Tabs/Tab"
import { KnowEmployees } from "./components/KnowEmployees/KnowEmployees"
import CompanyPeopleOverlay from "views/persons/components/person-overlay/CompanyPeopleOverlay"
import { useCompany } from "views/companies/company-module/company-module.context"
import { CompanyData } from "./components/CompanyData/CompanyData"
import { isDateGreaterThanToday } from "utils/format-date"
import { useCompanyKnowEmployees } from "views/companies/hooks/useCompanyKnowEmployees"
import { useAssignments } from "views/assignments/pages/list-assignment/hooks"
import { ProjectType } from "components/ProjectActions/ProjectDocumnets/form/constants/consts"
import { useOnProjectDocumentsLoad } from "components/ProjectActions/ProjectDocumnets/hooks/on-project-documents-load"
import { ProjectDocuments } from "components/ProjectActions/ProjectDocumnets"

export const CompanyDetails = React.memo(() => {
  const { selectedKnownEmployeeId, offLimits, companyId, companyDetails } =
    useCompany()

  const { totalItems } = useAssignments(companyId)

  const submitButtonRef = useRef<HTMLButtonElement>(null!)
  const addPhoneNumberButtonRef = useRef<HTMLButtonElement>(null!)
  const addWebsiteButtonRef = useRef<HTMLButtonElement>(null!)
  const knowEmployees = useCompanyKnowEmployees(companyId)

  const documentProps = useOnProjectDocumentsLoad({
    projectId: companyId,
    projectType: ProjectType.Company
  })

  return (
    <>
      <CompanyDetailsWrapper>
        <EditableSection
          isOffLimits={isDateGreaterThanToday(offLimits.endDate)}
          noBottomSpace
          size="xSmall"
          isDefaultExpanded
          targetName={e2eTargets.section}
          actions={({ isInEditMode, isInViewMode, setViewMode }) => (
            <Flex
              px={isInViewMode ? 0 : "xs"}
              pt={isInViewMode ? 0 : "xs"}
              pb={isInViewMode ? 0 : "xs"}
              justifyContent="flex-end"
              width="100%"
            >
              {isInEditMode && (
                <>
                  <Button
                    data-e2e-target-name={e2eTargets.section}
                    data-e2e-target={editableSectionE2eTargets.saveButton}
                    size="small"
                    type="button"
                    onClick={() => submitButtonRef?.current?.click()}
                    mr="xs"
                  >
                    {messages.form.generic.save}
                  </Button>
                  <Button
                    data-e2e-target={editableSectionE2eTargets.cancelEdition}
                    data-e2e-target-name={e2eTargets.section}
                    size="small"
                    mode="standard-white"
                    onClick={() => {
                      setViewMode()
                    }}
                  >
                    {messages.generic.cancel}
                  </Button>
                </>
              )}
            </Flex>
          )}
          view={(editableControls) => {
            return (
              <ViewCompanyDetails
                editableControls={editableControls}
                addPhoneNumberButtonRef={addPhoneNumberButtonRef}
                addWebsiteButtonRef={addWebsiteButtonRef}
              />
            )
          }}
          edit={() => {
            return <ModifyCompanyDetails />
          }}
        />
      </CompanyDetailsWrapper>

      <Tabs>
        <Tab title={messages.companies.companyDetails}>
          <CompanyData />
        </Tab>
        <Tab
          title={`${messages.companies.companyInfo.knownEmployees} (${companyDetails.knownEmployees})`}
          defaultSelected
        >
          <KnowEmployees {...knowEmployees} />
        </Tab>
        <Tab
          title={`${messages.companies.companyInfo.assignments} (${totalItems})`}
        >
          <CompanyAssignments />
        </Tab>
        <Tab
          title={`${messages.companies.companyInfo.documents} (${
            documentProps.documents?.length || 0
          })`}
        >
          <ProjectDocuments
            projectId={companyId}
            projectType={ProjectType.Company}
            {...documentProps}
          />
        </Tab>
      </Tabs>
      {selectedKnownEmployeeId && <CompanyPeopleOverlay {...knowEmployees} />}
    </>
  )
})
