import React from "react"
import { messages } from "setup/messages/messages"
import { OffLimitsStyled, StyledOfflimits } from "./style"

type OffLimitsBlockProps = {
  isSmall?: boolean
  isCircle?: boolean
  isLeftCircle?: boolean
  isFlex?: boolean
  isRotatedFlatOnSmall?: boolean
}

export const OffLimitsBlock = ({
  isSmall = false,
  isCircle = false,
  isLeftCircle = false,
  isFlex = false,
  isRotatedFlatOnSmall = false
}: OffLimitsBlockProps) => {
  return (
    <StyledOfflimits
      isCircle={isCircle}
      isLeftCircle={isLeftCircle}
      isFlex={isFlex}
      isRotatedFlatOnSmall={isRotatedFlatOnSmall}
    >
      <OffLimitsStyled
        isSmall={isSmall}
        isRotatedFlatOnSmall={isRotatedFlatOnSmall}
      >
        {messages.offLimits.offLimits}
      </OffLimitsStyled>
    </StyledOfflimits>
  )
}
