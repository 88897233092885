import { PhoneTypeEnums } from "views/persons/components/person-details-tab/components/person-contact-info/form/constants/definitions"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"
import { PersonTeamMember } from "../../../../person.types"
import { messages } from "setup/messages/messages"

export enum CreatePersonField {
  Name = "name",
  FirstName = "firstName",
  LastName = "lastName",
  MiddleName = "middleName",
  KnownAs = "knownAs",
  DateOfBirth = "dateOfBirth",
  JobTitle = "jobTitle",
  EmailAddresses = "taggedEmails",
  Company = "company",
  Bio = "bio",
  Location = "location",
  PhoneNumbers = "phones",
  WebSites = "webSites",
  FirstPhoneNumber = "phoneNumbers[0].value",
  FirstWebsite = "webSites[0].value.url",
  FirstEmailAddress = "taggedEmails[0].value.email",
  LinkedInUrl = "linkedInProfileUrl",
  Title = "title",
  Nationality = "nationality",
  AddressLine1 = "addressLine1",
  AddressLine2 = "addressLine2",
  PostCode = "postcode",
  City = "city",
  Country = "country"
}

export type PersonTitleOptions = {
  label: string
  value: PersonTitle
}

export enum PersonTitle {
  Blank = "",
  Mr = "Mr.",
  Ms = "Ms.",
  Mrs = "Mrs.",
  Miss = "Miss.",
  Dr = "Dr.",
  Prof = "Prof."
}

export type CreatePersonValues = Partial<
  typeof createPersonInitialValues & {
    teamMembers: Partial<PersonTeamMember>[]
  }
>

export const createPersonInitialValues: Omit<
  PersonProfileDetails,
  | "company"
  | "jobTitle"
  | "companyId"
  | "experience"
  | "isOfflimits"
  | "addressLine3"
  | "addressLine4"
> = {
  [CreatePersonField.Name]: { value: "", isReadOnly: false },
  [CreatePersonField.FirstName]: "",
  [CreatePersonField.LastName]: "",
  [CreatePersonField.MiddleName]: "",
  [CreatePersonField.KnownAs]: "",
  [CreatePersonField.Bio]: { value: "", isReadOnly: false },
  [CreatePersonField.Nationality]: "",
  [CreatePersonField.City]: "",
  [CreatePersonField.Country]: "",
  [CreatePersonField.PostCode]: "",
  [CreatePersonField.AddressLine1]: "",
  [CreatePersonField.AddressLine2]: "",
  [CreatePersonField.Title]: PersonTitle.Blank,
  [CreatePersonField.DateOfBirth]: new Date(),
  [CreatePersonField.EmailAddresses]: [
    { value: { email: "", type: "", isPreferred: false }, isReadOnly: false }
  ],
  [CreatePersonField.Location]: { value: "", isReadOnly: false },
  [CreatePersonField.LinkedInUrl]: { value: "", isReadOnly: false },
  [CreatePersonField.PhoneNumbers]: [
    {
      value: {
        isPreferred: false,
        phoneDeviceType: "",
        phoneNumber: "",
        phoneUsageType: PhoneTypeEnums.Personal
      },
      isReadOnly: false
    }
  ],
  [CreatePersonField.WebSites]: [
    {
      value: {
        url: ""
      },
      isReadOnly: false
    }
  ]
}

export const personStatusMap = new Map([
  [PersonTitle.Blank, messages.form.generic.blank],
  [PersonTitle.Mr, messages.form.generic.mr],
  [PersonTitle.Ms, messages.form.generic.ms],
  [PersonTitle.Mrs, messages.form.generic.mrs],
  [PersonTitle.Miss, messages.form.generic.miss],
  [PersonTitle.Dr, messages.form.generic.dr],
  [PersonTitle.Prof, messages.form.generic.prof]
])

const blank: PersonTitleOptions = {
  value: PersonTitle.Blank,
  label: messages.form.generic.blank
}

const mr: PersonTitleOptions = {
  value: PersonTitle.Mr,
  label: messages.form.generic.mr
}

const ms: PersonTitleOptions = {
  value: PersonTitle.Ms,
  label: messages.form.generic.ms
}

const mrs: PersonTitleOptions = {
  value: PersonTitle.Mrs,
  label: messages.form.generic.mrs
}

const miss: PersonTitleOptions = {
  value: PersonTitle.Miss,
  label: messages.form.generic.miss
}

const dr: PersonTitleOptions = {
  value: PersonTitle.Dr,
  label: messages.form.generic.dr
}

const prof: PersonTitleOptions = {
  value: PersonTitle.Prof,
  label: messages.form.generic.prof
}

export const personTitle = [blank, mr, ms, mrs, miss, dr, prof]

export const createTrimmedValues = (values: Partial<CreatePersonValues>) => {
  const trimmedValues: Partial<CreatePersonValues> = {
    name: values.name,
    bio: values.bio,
    firstName: values.firstName,
    lastName: values.lastName,
    middleName: values.middleName,
    knownAs: values.knownAs,
    nationality: values.nationality,
    title: values.title,
    dateOfBirth: values.dateOfBirth
  }

  return trimmedValues
}

export const e2eTargets = {
  name: "name",
  firstName: "firstName",
  lastName: "lastname",
  middleName: "middleName",
  knownAs: "knownAs",
  nationality: "nationality",
  title: "title",
  dateOfBirth: "dateOfBirth"
}
