import { PaginationProps } from "components/functional//pagination/Pagination"

export const searchInputName = "searchString"

export const e2eTargets = {
  submitButton: "submit-button",
  results: "results",
  personResults: "person-results",
  noResults: "no-results"
}

export type SearchFormValues = {
  searchString: string
}

export type SearchData<T> = { pagination: PaginationProps; results: T[] }

export const searchFormInitialValues = {
  searchString: ""
}
