import React from "react"

type TabProps = {
  title?: string
  children?: React.ReactNode
  disabled?: boolean
  defaultSelected?: boolean
  isActive?: boolean
}

const Tab = ({ children }: TabProps) => {
  return <>{children}</>
}

export default Tab
