import React from "react"
import { components } from "react-select"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import ModalTemplate from "../AddNewModal/ModalTemplate"
import {
  CreateNewLink,
  CreateNewLinkContainer,
  LinkLable,
  MenuListContainer,
  Plus
} from "./styles"

type MenuComponentProps = {
  isAssignmentSelect: boolean | null
  fetchCampaigns: (isAdding: boolean) => void
  fetchAssignments: (isAdding: boolean) => void
  props: any
}

const MenuComponent = ({
  isAssignmentSelect,
  fetchAssignments,
  fetchCampaigns,
  props
}: MenuComponentProps) => {
  const { open: openModal } = useModal()
  const addNew = () => {
    openModal(
      ModalNames.AddNewCampaignOrAssignment,
      <ModalTemplate
        isAssignmentSelect={isAssignmentSelect}
        fetchAssignments={fetchAssignments}
        fetchCampaigns={fetchCampaigns}
      />,
      true
    )
  }
  const type = isAssignmentSelect ? "assignment" : "campaign"

  return (
    <components.MenuList {...props}>
      <MenuListContainer>{props.children}</MenuListContainer>
      <CreateNewLinkContainer>
        <CreateNewLink onClick={addNew}>
          <Plus height={10} width={10} />
          <LinkLable>
            {messages.talentGraphSearch.createNew.format(type)}
          </LinkLable>
        </CreateNewLink>
      </CreateNewLinkContainer>
    </components.MenuList>
  )
}

export default MenuComponent
