import { FormikHelpers } from "formik"
import { apiRequest } from "setup/api/api"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { CreateAssignmentValues } from "views/assignments/components/assignment-create-form/constants/assignment-details-form.definitions"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import { FormAction } from "utils/forms/form.types"

export type OnAssignmentDetailsSubmit = FormAction<CreateAssignmentValues>

type ApiRequestType = (
  values: CreateAssignmentValues
) => Promise<NormalizedReturnTuple>

const onUpdate =
  (request: ApiRequestType) =>
  async (
    values: CreateAssignmentValues,
    helpers: FormikHelpers<CreateAssignmentValues>
  ) => {
    const { setFieldError, setSubmitting } = helpers
    setSubmitting(true)

    const [error, response] = await request(values)

    setSubmitting(false)
    callWithErrorMessages(setFieldError, error)
    return [error, response]
  }

export const onCreateAssignmentSubmit = onUpdate((data) =>
  apiRequest.post({
    endpoint: AssignmentsEndpoints.Root,
    data
  })
)
