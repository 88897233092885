import React, { useMemo } from "react"
import { H5 } from "@ikiru/talentis-fpc"
import { DateWrapper } from "./style"
import { CVDatePicker } from "../CVInputs/CVDatePicker"
import CVInput from "../CVInputs/CVInput"
import { getTypeMessage } from "../parsing/helper"
import CVCustomInput from "../CVInputs/CVCustomInput"
import {
  emailTypeOptions,
  phoneTypeOptions
} from "../../person-details-tab/components/person-contact-info/form/constants/definitions"
import { ContactInfoObject } from "views/persons/person.types"
import CVInputExist from "../CVInputs/CVInputExist"

type OverviewRowProps = {
  value: string
  title?: string
  isDeleted?: boolean
  children?: JSX.Element
  bottomBorder?: boolean
  valueKey?: string
  onChangeParsed?: (text: string | ContactInfoObject) => void
  handleDelete?: () => void
  selectedValue?: string
  isPreferred?: boolean
  undoRemoving?: () => void
  saveTempValue?: (data: any) => void
}

export const OverviewRow = ({
  title,
  value,
  isDeleted = false,
  children,
  bottomBorder = false,
  valueKey = "",
  onChangeParsed,
  handleDelete,
  selectedValue = "",
  isPreferred = false,
  undoRemoving,
  saveTempValue
}: OverviewRowProps) => {
  const inputs = useMemo(() => {
    if (isDeleted) {
      return (
        <CVInputExist
          typeText={getTypeMessage(valueKey, selectedValue)}
          text={value}
          handleDelete={() => undoRemoving && undoRemoving()}
          isDeleted={isDeleted}
          isOverview
        />
      )
    }

    switch (valueKey) {
      case "dateOfBirth":
        return (
          <CVDatePicker
            //@ts-ignore
            value={value}
            //@ts-ignore
            setValue={(text) => onChangeParsed && onChangeParsed(text)}
            name="DateOfB"
            isOverview
            handleDelete={handleDelete}
            saveTempValue={(data) => saveTempValue && saveTempValue(data)}
          />
        )
      case "phones":
      case "taggedEmails":
        return (
          <CVCustomInput
            typeText={getTypeMessage(valueKey, selectedValue)}
            text={value}
            isPreferred={isPreferred}
            selectedValue={selectedValue}
            onChangeInput={(text) => onChangeParsed && onChangeParsed(text)}
            options={
              valueKey === "phones" ? phoneTypeOptions : emailTypeOptions
            }
            type={valueKey}
            isOverview
            handleDelete={handleDelete}
            saveTempValue={(data) => saveTempValue && saveTempValue(data)}
          />
        )

      default:
        return (
          <CVInput
            text={value}
            onChangeInput={(text) => onChangeParsed && onChangeParsed(text)}
            isOverview
            handleDelete={handleDelete}
            saveTempValue={(text) => saveTempValue && saveTempValue(text)}
          />
        )
    }
  }, [
    value,
    valueKey,
    isPreferred,
    selectedValue,
    onChangeParsed,
    handleDelete,
    isDeleted,
    undoRemoving,
    saveTempValue
  ])

  return (
    <DateWrapper alignItems="stretch" bottomBorder={bottomBorder}>
      {children ? (
        children
      ) : (
        <>
          <H5 minWidth="137px" m="none" p="xxs" fontWeight="500">
            {title}
          </H5>
          {inputs}
        </>
      )}
    </DateWrapper>
  )
}
