import styled from "styled-components"
import { Div, GridBox, StyledSystemProps } from "@ikiru/talentis-fpc"

export const StageDetailsBox = styled(GridBox)`
  display: flex;
  justify-content: space-between;
  grid-area: stage;
  padding: ${({ theme }) => theme.space.xs}px;
  padding-left: ${({ theme }) => theme.space.s}px;

  ${({ theme }) => `
    border-top: 1px solid ${theme.colors.grey.light};
    ${theme.mediaQueries.xs} {
      border-top: none;
      border-left: 1px solid ${theme.colors.grey.light};
    }
  `}
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
  }
`

export const WebsitesBottom = styled(Div)<StyledSystemProps>`
  ${({ theme }) => `
    display: block;
    ${theme.mediaQueries.xl} {
      display: none;
    }
  `}
`

export const WebsitesRight = styled(Div)<StyledSystemProps>`
  ${({ theme }) => `
    display: none;
    ${theme.mediaQueries.xl} {
      display: block;
    }
  `}
`
