import styled from "styled-components"
import { Div, Flex, H5 } from "@ikiru/talentis-fpc"

export const StyledCard = styled(Flex)<{ isDisabled: boolean }>`
  position: relative;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: ${({ theme }) => theme.space.s}px;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};

  &:hover {
    border-color: ${({ theme }) => theme.colors.green.standard};
  }
`

export const StyledH5 = styled(H5)`
  position: absolute;
  color: ${({ theme }) => theme.colors.green.standard};
  margin: 0;
  bottom: 10px;
`

export const IconStyled = styled(Flex)`
  position: absolute;
  top: -10px;
  left: -10px;
  height: 40px;
  width: 40px;
  margin-right: ${({ theme }) => theme.space.xxs}px;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.grey.dark} !important;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-radius: 50%;
  border: 2px solid;
  & svg {
    max-height: 100%;
    max-width: 100%;
    fill: ${({ theme }) => theme.colors.grey.dark};
  }
`
