import React from "react"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import {
  AssignmentDataField,
  assignmentStatuses,
  e2eTargets
} from "views/assignments/components/assignment-data/constants/definitions"
import { FormikDatepicker } from "components/functional/formik/formik-datepicker/FormikDatepicker"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import CompanySearch from "../../assignment-company/CompanySearch"
import { CompanySelected } from "../../assignment-company/types"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import LocationSearch from "../../location-select/LocationSearch"
import { Location } from "views/assignments/assignment.types"

type AssignmentDataFormLayoutProps = {
  companyValue: CompanySelected
  setCompanyValue: React.Dispatch<React.SetStateAction<CompanySelected>>
  setLocation: React.Dispatch<React.SetStateAction<Location>>
  locationValue: Location
}

export const AssignmentDataFormLayout = React.memo(
  ({
    companyValue,
    setCompanyValue,
    setLocation,
    locationValue
  }: AssignmentDataFormLayoutProps) => {
    return (
      <Div alignItems="center" justifyContent="stretch" position="relative">
        <Flex flexDirection={["column", "column", "row"]} marginBottom={"10px"}>
          <Div
            width={["100%", "100%", "30%"]}
            marginRight={["xs"]}
            marginBottom={["xs", "xs", "0"]}
          >
            <FormikSelect
              data-e2e-target-name={e2eTargets.status}
              options={assignmentStatuses}
              id={AssignmentDataField.Status}
              name={AssignmentDataField.Status}
              label={messages.form.generic.assignmentStatus}
            />
          </Div>
          <Div width={["100%", "100%", "70%"]}>
            <FormikInput
              data-e2e-target-name={e2eTargets.name}
              id={AssignmentDataField.Name}
              name={AssignmentDataField.Name}
              label={messages.form.generic.assignmentName}
            />
          </Div>
        </Flex>
        <Flex
          flexDirection={["column", "column", "row"]}
          marginBottom={["0", "0", "10px"]}
          justifyContent="space-between"
        >
          <Div
            width={["100%", "100%", "49%"]}
            marginBottom={["10px", "10px", "0"]}
          >
            <FormikInput
              data-e2e-target-name={e2eTargets.jobTitle}
              id={AssignmentDataField.JobTitle}
              name={AssignmentDataField.JobTitle}
              label={messages.form.generic.jobTitle}
            />
          </Div>
          <Div
            width={["100%", "100%", "49%"]}
            marginBottom={["10px", "10px", "0"]}
          >
            <CompanySearch
              id={AssignmentDataField.CompanyName}
              name={`${AssignmentDataField.CompanyName}.value`}
              label={messages.form.generic.company}
              selectedCompany={setCompanyValue}
              initialSearchedWord={companyValue.name}
            />
          </Div>
        </Flex>
        <Div width="100%" mb="xxs">
          <LocationSearch
            id={AssignmentDataField.Location}
            name={AssignmentDataField.Location}
            label={messages.form.generic.location}
            selectedLocation={setLocation}
            initialSearchedWord={locationValue.label}
          />
        </Div>
        <Flex
          flexDirection={["column", "column", "row"]}
          marginBottom={["0", "0", "10px"]}
          justifyContent="space-between"
        >
          <Div
            marginRight={["0", "0", "10px"]}
            zIndex={"99"}
            marginBottom={["10px", "10px", "0"]}
            flexGrow={"1"}
          >
            <FormikDatepicker
              data-e2e-target-name={e2eTargets.startDate}
              id={AssignmentDataField.StartDate}
              name={AssignmentDataField.StartDate}
              label={messages.form.generic.startDate}
            />
          </Div>
          <Div flexGrow="1">
            <FormikDatepicker
              data-e2e-target-name={e2eTargets.endDate}
              id={AssignmentDataField.EndDate}
              name={AssignmentDataField.EndDate}
              label={messages.form.generic.endDate}
            />
          </Div>
        </Flex>
        <Div mt="xs">
          <FormikTextarea
            rows={4}
            id={AssignmentDataField.Brief}
            name={AssignmentDataField.Brief}
            label={messages.assignment.assignmentBrief}
            resize="none"
          />
        </Div>
      </Div>
    )
  }
)
