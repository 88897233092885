import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import ParseKeywords from "./components/Skills/ParseKeywords"
import ParseEducation from "./components/Skills/ParseEducation"

export const ParseSkills = () => {
  return (
    <>
      <Div mb="s">
        <ParseKeywords />
      </Div>
      <ParseEducation />
    </>
  )
}
