import React from "react"
import {
  Link,
  Flex,
  H2,
  colors,
  getMinWidthMediaQuery,
  Badge,
  SmallText
} from "@ikiru/talentis-fpc"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import {
  BannerLabelContent,
  CrossIcon,
  BannerContainer,
  BannerNavContent,
  NavLabel,
  CrossContainer
} from "./style"
import { messages } from "setup/messages/messages"
import { useMediaQuery } from "utils/hooks/use-media-query"

interface OverlayNavigationProps {
  directionNext: () => void
  directionPrevious: () => void
  closeOverlay: () => void
  candidateStage?: string
  oneContent?: boolean
  disableNav?: boolean
  totalItems?: number
  currentCandidateNumber?: number
}

const OverlayNavigation = ({
  directionNext,
  directionPrevious,
  closeOverlay,
  candidateStage,
  oneContent = false,
  disableNav = false,
  totalItems = 0,
  currentCandidateNumber = 0
}: OverlayNavigationProps) => {
  const mediaQuery = getMinWidthMediaQuery("md")
  const isLarge = useMediaQuery(mediaQuery)

  return (
    <BannerContainer oneContent={oneContent}>
      {!oneContent && (
        <BannerLabelContent isLarge={isLarge}>
          <H2 color={colors.grey.dark} my={15}>
            {candidateStage}
          </H2>
          {totalItems !== 0 && (
            <Badge bg={colors.grey.standard} height={17} ml="xxs" mt="-5px">
              <SmallText
                m={0}
                fontSize={13}
                fontWeight="700"
                color={colors.white.standard}
              >
                {messages.assignment.details.candidates.filteredCount.format(
                  currentCandidateNumber,
                  totalItems
                )}
              </SmallText>
            </Badge>
          )}
        </BannerLabelContent>
      )}
      <BannerNavContent>
        <Flex alignItems="baseline">
          <Link onClick={() => (disableNav ? null : directionPrevious())}>
            <Flex alignItems="center">
              <MdKeyboardArrowLeft
                color={colors.grey.dark}
                viewBox="0 0 20 20 "
                width={20}
                height={20}
                style={{ marginRight: "5px", marginLeft: "-7px" }}
              />
              <NavLabel>{messages.form.generic.previous}</NavLabel>
            </Flex>
          </Link>
        </Flex>
        <>
          <Link onClick={() => (disableNav ? null : directionNext())}>
            <Flex alignItems="center">
              <NavLabel>{messages.form.generic.next}</NavLabel>{" "}
              <MdKeyboardArrowRight
                color={colors.grey.dark}
                viewBox="0 0 20 20 "
                width={20}
                height={20}
                style={{ marginRight: "-2px" }}
              />
            </Flex>
          </Link>
        </>
        <CrossContainer>
          <CrossIcon onClick={closeOverlay} fill={colors.grey.dark} />
        </CrossContainer>
      </BannerNavContent>
    </BannerContainer>
  )
}

export default OverlayNavigation
