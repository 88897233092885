import { useMemo } from "react"
import {
  ProjectRecordFilter,
  FilterArrayType
} from "components/ProjectRecordsFilters/types"
import { checkFirstSelected } from "components/ProjectRecordsFilters/helpers"
import { useTeam } from "views/team/team/team-module.context"
import uniq from "lodash/uniq"

/**
 * Return a list of "assigned to" filters, including only those which at least one record has assigned
 */
export const useAssignedTo = (
  assignToFilter: string[],
  allAvailableFilters: FilterArrayType,
  appliedFilters: ProjectRecordFilter[]
) => {
  const { teamMembers } = useTeam()

  const assignedToFilters = useMemo<ProjectRecordFilter[]>(() => {
    const currentAssignedArray = checkFirstSelected("assignTo", appliedFilters)
      ? assignToFilter
      : allAvailableFilters.assignedTo || []

    const teamMembersIds = currentAssignedArray.map((assignedId) =>
      assignedId ? assignedId : "notAssigned"
    )

    const unassigned = {
      recordProperty: "assignTo",
      value: {
        eq: null
      },
      label: "Unassigned",
      filterKey: "assignToNull",
      disabled: !teamMembersIds.includes("notAssigned")
    }

    const filters = teamMembers.map((teamMember) => {
      const filter = {
        recordProperty: "assignTo",
        value: {
          eq: teamMember?.id
        },
        label: `${teamMember.firstName} ${teamMember.lastName}`,
        filterKey: `assignTo${teamMember.id}`,
        disabled: !teamMembersIds.includes(teamMember.id)
      }
      return filter
    })

    return uniq([unassigned, ...filters])
  }, [
    teamMembers,
    assignToFilter,
    allAvailableFilters.assignedTo,
    appliedFilters
  ])

  return {
    assignedToFilters
  }
}
