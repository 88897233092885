import React from "react"
import NoteAddForm from "components/Notes/components/forms/NoteAddForm"
import { messages } from "setup/messages/messages"
import { Button, Flex, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { OnEditNoteSubmitType } from "../../types"
import { ModalNames } from "setup/modal/modal.definitions"

type NotesEditModeProps = {
  setViewMode?: () => void
  onSubmit: () => OnEditNoteSubmitType
  close?: (modalName: ModalNames) => void
}

const NotesEditMode = ({
  setViewMode,
  onSubmit,
  close
}: NotesEditModeProps) => {
  return (
    <NoteAddForm
      onSubmit={onSubmit()}
      formActionsComponent={(formik) => {
        const { isSubmitting, resetForm } = formik

        return (
          <Flex>
            <ModalSectionHeader
              title={messages.campaign.campaignNotes}
              size="xSmall"
              actions={
                <>
                  <Button
                    type="submit"
                    size="small"
                    mr="xs"
                    disabled={isSubmitting}
                  >
                    {messages.generic.save}
                  </Button>
                  <Button
                    mode="standard-white"
                    size="small"
                    type="button"
                    onClick={() => {
                      resetForm()
                      setViewMode && setViewMode()
                      close && close(ModalNames.EditNoteData)
                    }}
                  >
                    {messages.generic.cancel}
                  </Button>
                </>
              }
            />
          </Flex>
        )
      }}
    />
  )
}

export default React.memo(NotesEditMode)
