import { useCallback, useEffect, useState } from "react"
import { Note } from "components/NoteItem/types"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { expandEndpointByParams } from "setup/api/api.helpers"
import { Nullable } from "tsdef"
import { Candidate } from "views/candidates/candidates.types"
import { NoteAddValues } from "./NoteForm/types"
import { FormAction } from "utils/forms/form.types"
import {
  getOnAddPersonNoteSubmit,
  getOnEditPersonNoteSubmit
} from "./NoteForm/actions"

const useCandidateNotes = (candidate: Candidate) => {
  const [isLoading, setIsLoading] = useState(false)
  const [notes, setNotes] = useState<Note[]>([])
  const [isNewNoteMode, setNewNoteMode] = useState(false)
  const [selectedNote, setSelectedNote] = useState<Nullable<Note>>(
    candidate?.sharedNoteId
      ? {
          ...candidate.linkSharedNote!,
          id: candidate.sharedNoteId
        }
      : null
  )

  const fetchNotes = useCallback(
    async (personId: string) => {
      setIsLoading(true)
      const [, response] = await apiRequest.get({
        endpoint: PersonsEndpoints.Notes,
        endpointParams: { id: personId },
        config: expandEndpointByParams({
          expand: ["createdByUser", "assignment", "campaign", "updatedByUser"]
        })
      })

      setNotes(
        (response?.data?.notes || [])?.filter((note: Note) => {
          return (
            note.assignmentId === candidate.assignmentId || !note.assignmentId
          )
        })
      )
      setIsLoading(false)
    },
    [candidate.assignmentId]
  )

  useEffect(() => {
    if (candidate.personId) fetchNotes(candidate.personId)
  }, [candidate.personId, fetchNotes])

  const onSelectNote = useCallback((note?: Nullable<Note>) => {
    setSelectedNote(note || null)
  }, [])

  const onUpdateNote = useCallback(
    async (note: Note) => {
      const index = notes.findIndex((n) => n.id === note?.id)
      if (index > -1) {
        setNotes([...notes.slice(0, index), note, ...notes.slice(index + 1)])
      } else {
        setNotes([note, ...notes])
      }
    },
    [notes]
  )

  const onRefreshNotes = useCallback(
    async () => await fetchNotes(candidate.personId),
    [candidate.personId, fetchNotes]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCreateNoteSubmit: FormAction<NoteAddValues> = useCallback(
    getOnAddPersonNoteSubmit(
      candidate.personId,
      candidate.assignmentId,
      (note) => {
        onUpdateNote?.(note)
        setNewNoteMode(false)
      }
    ),
    [candidate.personId, candidate.assignmentId, onUpdateNote]
  )

  return {
    notes,
    isLoading,
    selectedNote,
    isNewNoteMode,
    fetchNotes,
    onSelectNote,
    onUpdateNote,
    setNewNoteMode,
    onCreateNoteSubmit,
    onRefreshNotes
  }
}

const useNoteBox = (
  note: Note,
  personId: string,
  onUpdateNote?: (note: Note) => void
) => {
  const [isEditMode, setEditMode] = useState(false)

  const onSubmit = useCallback(
    (noteId: string): FormAction<NoteAddValues> =>
      getOnEditPersonNoteSubmit(personId, noteId, (note) => {
        onUpdateNote?.(note)
        setEditMode(false)
      }),
    [onUpdateNote, personId]
  )

  return { isEditMode, setEditMode, onSubmit }
}

export { useCandidateNotes, useNoteBox }
