export enum InsightsEventName {
  SubscriptionExpired = "subscriptionExpired",
  CandidateAdded = "candidateAdded",
  TurnOnAnonymousSearch = "turnOnAnonymousSearch",
  CandidateAssignToSet = "candidateAssignToSet",
  CandidateDueDateSet = "candidateDueDateSet",
  CandidateCompletedOrRemoveSet = "candidateCompletedOrRemoveSet",
  AssignmentView = "assignmentView",
  AssignemntFilterEnabled = "assignmentFilterEnabled",
  OnboardingAssignmentShown = "OnboardingAssignmentShown",
  OnboardingAssignmentCompleted = "OnboardingAssignmentCompleted",
  ExportDisabled = "exportDisabled",
  SavedSearches = "savedSearches",
  RocketReachTeaserRequest = "rocketReachTeaserRequest",
  AssignmentTagsChanged = "assignmentTagsChanged",
  AddTagsToCandidate = "addTagsToCandidate",
  SearchInXray = "xraysearch",
  GPTAISearch = "gptAISearch",
  QuickSearchErrorN360GetID = "quickSearchErrorN360GeID "
}

export enum CandidateAddedFrom {
  MainSiteSearch = "MainSite-Search",
  MainSitePerson = "MainSite-Person",
  MainSiteSimilarPeople = "MainSite-SimilarPeople"
}

export interface CandidateAddedEventData {
  addedFrom: CandidateAddedFrom
  assignmentId: string
  localPersonId: string
}

export interface CandidateAssignemntEventData {
  assignmentId: string
}
