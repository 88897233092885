import { BodyText, colors, Div, Span } from "@ikiru/talentis-fpc"
import React from "react"
import { messages } from "setup/messages/messages"
import { SectionTitle } from "../../../style"
import { PersonTeamMember } from "views/persons/person.types"
import { Membership } from "components/ProjectTeamMembers/types"

const PersonTeamView = ({
  personTeamMembers = []
}: {
  personTeamMembers: PersonTeamMember[]
}) => {
  const knownTos = personTeamMembers.filter(
    (person) => person.membership === Membership.KnownTo
  )
  const members = personTeamMembers.filter(
    (person) => person.membership === Membership.Member
  )
  return (
    <Div py="xs">
      {!personTeamMembers.length && (
        <Span color="grey.standard">
          {messages.person.personTeam.noTeamMembers}
        </Span>
      )}
      {Boolean(knownTos.length) && (
        <>
          <SectionTitle>{messages.person.personTeam.knownTo}</SectionTitle>
          <Div mb={`${members.length ? "m" : "0"}`}>
            {knownTos.map((guy, key, array) => (
              <BodyText
                m="0px"
                display="inline"
                color={colors.grey.darkest}
              >{`${guy.firstName} ${guy.lastName}${
                key === array.length - 1 ? "" : ", "
              }`}</BodyText>
            ))}
          </Div>
        </>
      )}
      {Boolean(members.length) && (
        <Div mb="m">
          <SectionTitle>{messages.person.personTeam.members}</SectionTitle>

          {members.map((guy, key, array) => (
            <BodyText m="0px" display="inline" color={colors.grey.darkest}>{`${
              guy.firstName
            } ${guy.lastName}${
              key === array.length - 1 ? "" : ", "
            }`}</BodyText>
          ))}
        </Div>
      )}
    </Div>
  )
}

export default PersonTeamView
