import { BodyText, Div, Flex, GridBox, SmallText } from "@ikiru/talentis-fpc"
import styled from "styled-components"

const avatarBoxSize = "67px"

export const ProjectBoxWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  background: ${({ theme }) => theme.colors.white.standard};
  padding: 0;
  margin-bottom: 10px;
`

export const ProjectBox = styled.div`
  text-decoration: none;
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto;
  position: relative;
  width: 100%;

  grid-template-rows: 1fr;
  grid-template-columns: ${avatarBoxSize} 1fr;
  grid-template-areas:
    "avatar details"
    "stage stage";

  ${({ theme }) => `
    ${theme.mediaQueries.md} {
      grid-template-columns: ${avatarBoxSize} 300px 2fr;
      grid-template-areas:
        "avatar details stage"
    }

  `}
`

export const ProjectDetailsBox = styled(GridBox)`
  min-width: 250px;
  max-width: 260px;
  grid-area: details;
  margin: 0px 40px 15px 10px;
`
export const ProjectName = styled(BodyText)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey.darkest};
  margin: ${({ theme }) => theme.space.s}px 0px 0px 0px;
`
export const ProjectTitle = styled(BodyText)`
  margin: 0px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;
`

export const StatusContainer = styled(Flex)`
  align-items: baseline;
  justify-content: flex-start;
`
export const StatusCircle = styled(Div)`
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.green.light};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.space.xxs}px;
`
export const StatusTexts = styled(SmallText)`
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.1px;
`

export const InterviewProgressDetailsBox = styled(GridBox)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  grid-area: stage;
  padding: ${({ theme }) => theme.space.xs}px;
  padding-left: ${({ theme }) => theme.space.s}px;

  ${({ theme }) => `
    border-top: 1px solid ${theme.colors.grey.light};
    ${theme.mediaQueries.md} {
      border-top: none;
      border-left: 1px solid ${theme.colors.grey.light};
    }
  `}
`
export const ProjectFooterBox = styled.div`
  padding: 0 12px 10px 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  &:empty {
    padding: 0;
    border: none;
  }
`
