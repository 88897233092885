import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { TagText } from "@ikiru/talentis-fpc"

export const SidebarHelpersContainer = styled.div`
  padding-left: ${({ theme }) => theme.space.m}px;
  padding-bottom: ${({ theme }) => theme.space.xs}px;  
  @media only screen and (min-height: 394px) and (max-height: 695px) {
    padding-left: 15px;
  }
`

export const SidebarHelper = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.s}px;
  }
`

export const SidebarHelperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.grey.standard};
  border-radius: 50%;
`

export const SidebarHelperText = styled.div`
  ${TagText}
  color: ${({ theme }) => theme.colors.white.standard};
  background: ${({ theme }) => theme.colors.grey.standard};
  padding: ${({ theme }) => theme.space.xxs}px;
  margin-bottom: 0px;
  margin-left: ${({ theme }) => theme.space.xs}px;
  border-radius: 3px;
`

export const SidebarHelperLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none !important;

  // Specify the height to prevent icons jumping
  height: 25px;

  &:hover {
    & ${SidebarHelperIcon}, & ${SidebarHelperText} {
      background-color: ${({ theme }) => theme.colors.grey.dark};
    }
  }
`
