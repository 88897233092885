import React from "react"
import { Flex, Button, colors, PlusIcon } from "@ikiru/talentis-fpc"
import { KeywordsBox } from "./KeywordsBox"
import { LogicalConnection } from "../components/LogicalConnection"
import { KEYWORDS_BOXES_LIMIT } from "./consts"
import { messages } from "setup/messages/messages"
import { useMultiBoxes } from "../components/hooks"
import FilterWrapper from "../../FilterWrapper"
import { SearchCriteriaField } from "../definitions"
import { FilterType } from "views/search/SearchModule/types"

export const KeywordsFilter = () => {
  const { boxesCount, addNewBox } = useMultiBoxes(
    SearchCriteriaField.KeywordBundle,
    FilterType.KeywordBundle
  )

  const onClick = (e: Event) => {
    addNewBox()
    ;(e.target as HTMLElement)?.scrollIntoView()
  }

  return (
    <Flex flexDirection="column" width="100%">
      {[...Array(boxesCount).keys()].map((_, index: number, array) => (
        <React.Fragment key={index}>
          <FilterWrapper>
            <Flex flexDirection="column" width="100%">
              <KeywordsBox {...{ index, boxesCount }} />
              {index + 1 < boxesCount && (
                <Flex
                  my="none"
                  style={{ position: "relative", bottom: "-27px" }}
                  zIndex={1}
                >
                  <LogicalConnection
                    filterType={FilterType.KeywordsBundleSearchUsingORLogic}
                  />
                </Flex>
              )}
              {index === array.length - 1 && (
                <Flex my="xs">
                  {boxesCount < KEYWORDS_BOXES_LIMIT && (
                    <Button
                      prefix={
                        <PlusIcon
                          width={12}
                          height={12}
                          fill={colors.white.standard}
                        />
                      }
                      size="small"
                      mode="standard-green"
                      //@ts-ignore
                      onClick={onClick}
                    >
                      {messages.talentGraphSearch.addKeywordField}
                    </Button>
                  )}
                </Flex>
              )}
            </Flex>
          </FilterWrapper>
        </React.Fragment>
      ))}
    </Flex>
  )
}
