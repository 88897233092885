import React from "react"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { PersonAvatarModal } from "./components/PersonAvatarModal"
import {
  getOnUploadPersonPhotoAvatarSubmit,
  getOnAvatarDelete
} from "./person-avatar.actions"
import { Flex } from "@ikiru/talentis-fpc"
import { Avatar } from "@ikiru/talentis-fpc"
import { personAvatarE2ETargets } from "views/persons/components/person-avatar/constants/person-avatar.definitions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { PersonProfileFields } from "views/persons/pages/manage-person/person-profile.types"
import { usePersonDataPoolLinkage } from "views/search/components/SearchResults/hook/usePersonDataPoolLinkage"

export const PersonAvatar = React.memo(() => {
  const {
    personId,
    profileDetails,
    photo,
    getProfileAvatar,
    setPhoto,
    setPersonOverlayPhotoUpdated
  } = usePerson()

  const name = profileDetails[PersonProfileFields.Name].value
  const { open, close } = useModal()
  const { createLocalPerson } = usePersonDataPoolLinkage()

  const onAvatarDelete = getOnAvatarDelete(
    personId,
    () => {
      setPhoto(null)
      close(ModalNames.PersonAvatar)
      setPersonOverlayPhotoUpdated(true)
    },
    createLocalPerson
  )

  const onAvatarUpload = getOnUploadPersonPhotoAvatarSubmit(
    personId,
    (newPersonId) => {
      close(ModalNames.PersonAvatar)
      getProfileAvatar(newPersonId)
      setPersonOverlayPhotoUpdated(true)
    },
    createLocalPerson
  )

  const onAvatarClick = () => {
    open(
      ModalNames.PersonAvatar,
      <PersonAvatarModal
        {...{ name, photo }}
        onAvatarUpload={onAvatarUpload}
        onAvatarDelete={onAvatarDelete}
      />
    )
  }

  return (
    <Flex flexShrink={0} mr="s" width={80} height={80}>
      <Avatar
        {...{ name, photo }}
        data-e2e-target-name={personAvatarE2ETargets.containerImage}
        onAvatarClick={onAvatarClick}
      />
    </Flex>
  )
})
