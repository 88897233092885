import styled from "styled-components"
import { Button } from "@ikiru/talentis-fpc"
import { Div } from "@ikiru/talentis-fpc"

export const SmallSummaryWrapper = styled(Div)`
  position: fixed;
  width: 100svw;
  left: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white.standard};
  box-shadow: 0px -2px 6px 0px rgba(34, 65, 96, 0.11);
`

export const LargeSummaryWrapper = styled(Div)`
  position: sticky;
  top: ${({ theme }) => theme.space.m}px;
  margin: ${({ theme }) => `0 ${theme.space.m}px 0 ${theme.space.xxl}px;`};
`

export const SummaryExpandButton = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`
