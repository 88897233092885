import React from "react"
import { personAvatarE2ETargets } from "views/persons/components/person-avatar/constants/person-avatar.definitions"
import { Button } from "@ikiru/talentis-fpc"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

type EditAvatarViewProps = {
  actionsPending: boolean
  /** A photo has staged for upload */
  hasAvatarPreview?: boolean
  /** photo has been uploaded to the API and is attached to the person entity */
  hasSavedPhoto: boolean
  onUploadButtonClick: () => void
  onDeleteButtonClick: () => void
}

export const EditAvatarView = (props: EditAvatarViewProps) => {
  const {
    actionsPending,
    hasAvatarPreview,
    hasSavedPhoto,
    onUploadButtonClick,
    onDeleteButtonClick
  } = props
  return (
    <>
      <Div mb="s">
        <Button
          data-e2e-target={personAvatarE2ETargets.saveButton}
          type="submit"
          mode="primary"
          size="small"
          disabled={actionsPending || !hasAvatarPreview}
        >
          {messages.person.avatar.savePhoto}
        </Button>
      </Div>
      <Flex>
        <Button
          data-e2e-target={personAvatarE2ETargets.uploadButton}
          type="button"
          mode="secondary"
          size="small"
          mr="xs"
          onClick={onUploadButtonClick}
          disabled={actionsPending}
        >
          {messages.person.avatar.changePhoto}
        </Button>
        <Button
          data-e2e-target={personAvatarE2ETargets.deleteButton}
          type="button"
          mode="secondary"
          size="small"
          disabled={actionsPending || !hasSavedPhoto}
          onClick={onDeleteButtonClick}
        >
          {messages.person.avatar.deletePhoto}
        </Button>
      </Flex>
    </>
  )
}
