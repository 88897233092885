import React, { useRef, useEffect, useCallback, useMemo } from "react"
import { Overlay } from "@ikiru/talentis-fpc"
import { PersonOverlayContainer, PersonContent } from "./style"
import { useChangeSearchPerson } from "./hook/useChangeSearchPerson"
import { useSearch } from "views/search/SearchModule/context"
import PersonOverlay from "./index"
import OverlayNavigation from "./OverlayNavigation"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { InterviewProgressStage } from "../person-assignments/components/InterviewProgress/constants/interview-progress-stages"
import { SearchPersonWithAssignment } from "views/search/SearchModule/types"
import { callProfileEndpoint } from "../person-details/form/actions"
import { getUpdatedPhotoAndCallBack } from "./utils"

const SearchPersonOverlay = () => {
  const {
    personOverlayUpdated,
    setPersonOverlayUpdated,
    setPersonOverlayNoteUpdated,
    candidates,
    contacts,
    notes,
    person: personData,
    profileDetails,
    getProfileData,
    personOverlayNoteUpdated,
    personOverlayPhotoUpdated
  } = usePerson()
  const {
    setSelectedSearchDataPoolId,
    selectedSearchDataPoolId,
    setSelectedSearchPersonId,
    selectedSearchPersonId,
    isLoading,
    persons,
    updatePerson
  } = useSearch()

  const { directionNext, directionPrevious } = useChangeSearchPerson()

  let overLayRef = useRef<HTMLDivElement>()

  const closeOverlay = (e: any) => {
    if (!overLayRef?.current?.contains(e.target)) {
      setSelectedSearchDataPoolId("")
      setSelectedSearchPersonId("")
    }
  }

  const lastNoteDate = useMemo(
    () => (notes?.length ? notes[0].createdDate : null),
    [notes]
  )

  const fetchAndUpdatePerson = useCallback(
    (newPerson: SearchPersonWithAssignment) => {
      getUpdatedPhotoAndCallBack(
        selectedSearchPersonId,
        personOverlayPhotoUpdated,
        async (photoUrl) => {
          newPerson.person.personId = selectedSearchPersonId
          if (photoUrl) {
            newPerson.person.photo = { url: photoUrl }
          }
          const [error, response] = await callProfileEndpoint(
            selectedSearchPersonId
          )
          if (!error) newPerson.person = response?.data.localPerson
          newPerson.person.isOfflimits = profileDetails?.isOfflimits
          newPerson.person.recentNote = {
            byFirstName: "",
            byLastName: "",
            createdOrUpdated: lastNoteDate,
            noteTitle: ""
          }
          const newPersons = persons?.map((person) => {
            if (person.person.dataPoolId === selectedSearchDataPoolId) {
              return newPerson
            }
            return person
          })!

          updatePerson(newPersons)
        }
      )
    },
    [
      selectedSearchPersonId,
      personOverlayPhotoUpdated,
      profileDetails,
      lastNoteDate,
      persons,
      updatePerson,
      selectedSearchDataPoolId
    ]
  )

  useEffect(() => {
    if (personOverlayUpdated || personOverlayNoteUpdated) {
      let shortedListed = 0
      let placed = 0

      const assignmentIds = Object.values(candidates).map((candidate) => {
        if (
          candidate.interviewProgressState?.stage ===
          InterviewProgressStage.Shortlist
        ) {
          ++shortedListed
        }
        if (
          candidate.interviewProgressState?.stage ===
          InterviewProgressStage.Placed
        ) {
          ++placed
        }
        return candidate.assignmentId
      })

      const campaignIds = Object.values(contacts).map(
        (contact) => contact.campaignId
      )

      if (persons) {
        const newPerson = persons.find(
          (person) => person.person.dataPoolId === selectedSearchDataPoolId
        )
        if (newPerson) {
          newPerson.assignmentIds = assignmentIds
          newPerson.campaignIds = campaignIds
          newPerson.stages = [
            { name: InterviewProgressStage.Shortlist, count: shortedListed },
            { name: InterviewProgressStage.Placed, count: placed }
          ]

          newPerson.person.dataPoolPersonId = personData?.dataPoolId || ""
          fetchAndUpdatePerson(newPerson)
        }
      }
      setPersonOverlayNoteUpdated(false)
      setPersonOverlayUpdated(false)
    }
  }, [
    candidates,
    contacts,
    fetchAndUpdatePerson,
    getProfileData,
    personData,
    personOverlayNoteUpdated,
    personOverlayUpdated,
    persons,
    profileDetails,
    selectedSearchDataPoolId,
    selectedSearchPersonId,
    setPersonOverlayNoteUpdated,
    setPersonOverlayUpdated,
    updatePerson
  ])

  return (
    <PersonOverlayContainer onClick={closeOverlay}>
      <PersonContent ref={overLayRef}>
        <Overlay visible>
          <OverlayNavigation
            directionNext={directionNext}
            directionPrevious={directionPrevious}
            closeOverlay={() => {
              setSelectedSearchDataPoolId("")
              setSelectedSearchPersonId("")
            }}
            oneContent
          />
          <PersonOverlay
            datapoolId={selectedSearchDataPoolId}
            isLoadingCandidates={isLoading}
            project="search"
          />
        </Overlay>
      </PersonContent>
    </PersonOverlayContainer>
  )
}

export default SearchPersonOverlay
