import { useEffect, useCallback, useState, useMemo } from "react"
import { Assignment } from "../../assignment.types"
import { StatusTabDetails } from "../../components/assignments-group"
import { VISIBLE_PAGES } from "../../../search/SearchModule/consts"
import { pageSize } from "../../constants/definitions"
import { scrollToTop } from "../../../search/SearchModule/utils"
import { getAssignments } from "./actions"

const useAssignments = (companyId?: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [assignments, setAssignments] = useState<Assignment[]>([])
  const [searchWord, setSearchWord] = useState("")
  const [pageNumber, setPageNumber] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(
    companyId ? "all" : "active"
  )
  const [totalCount, setTotalCount] = useState(0)
  const [statusArray, setStatusArray] = useState<StatusTabDetails[]>([])
  const [defaultStatusArray, setDefaultStatusArray] = useState<
    StatusTabDetails[]
  >([])
  const [findAssignmentNames, setFindAssignmentNames] = useState<string[]>([])
  const [showMyAssignmentsOnly, setShowMyAssignmentsOnly] = useState(false)

  const paginationAssignmentList = useMemo(
    () => ({
      hasNextPage: pageNumber < totalPages,
      hasPreviousPage: pageNumber > 1,
      maxPages: VISIBLE_PAGES,
      pageCount: totalPages,
      pageNumber: pageNumber,
      pageSize: pageSize,
      totalItemCount: totalItems
    }),
    [pageNumber, totalItems, totalPages]
  )

  const setRecordCount = (totalItems: number, totalPages: number) => {
    setTotalItems(totalItems)
    setTotalPages(totalPages)
  }

  const handlePageChange = useCallback((page: any) => {
    scrollToTop()
    setPageNumber(page)
  }, [])

  const fetchAssignments = useCallback(
    async (companyId?: string) => {
      setIsLoading(true)

      const {
        assignments,
        currentCount,
        pageCount,
        totalCount: count,
        statusDetailsArray
      } = await getAssignments(
        pageNumber,
        selectedStatus,
        defaultStatusArray,
        searchWord,
        undefined,
        companyId,
        showMyAssignmentsOnly
      )
      return {
        assignments,
        currentCount,
        pageCount,
        totalCount: count,
        statusDetailsArray
      }
    },
    [
      pageNumber,
      searchWord,
      selectedStatus,
      defaultStatusArray,
      showMyAssignmentsOnly
    ]
  )

  useEffect(() => {
    let isLoadingAssignments = true

    fetchAssignments(companyId).then(
      ({
        assignments,
        currentCount,
        pageCount,
        totalCount: count,
        statusDetailsArray
      }) => {
        if (isLoadingAssignments) {
          if (!defaultStatusArray.length) {
            setDefaultStatusArray(statusDetailsArray)
          }
          if (isLoadingAssignments) {
            setRecordCount(currentCount, pageCount)
            if (!Boolean(totalCount)) {
              setTotalCount(count)
            }
            setStatusArray(statusDetailsArray)
            setAssignments(assignments)
            setIsLoading(false)
          }
        }
      }
    )

    return () => {
      isLoadingAssignments = false
    }
  }, [
    fetchAssignments,
    selectedStatus,
    defaultStatusArray.length,
    totalCount,
    companyId,
    showMyAssignmentsOnly
  ])

  const searchedAssignmentChanged = (searchWord: string) => {
    setPageNumber(1)
    if (searchWord !== "") {
      setSelectedStatus("all")
    } else {
      setSelectedStatus("active")
    }
    setSearchWord(searchWord)
  }

  const onChangeSearch = useCallback(
    async (searchedWord: string) => {
      const { assignmentsNames } = await getAssignments(
        1,
        "",
        [],
        searchedWord,
        totalCount,
        companyId,
        showMyAssignmentsOnly
      )
      setFindAssignmentNames(assignmentsNames)
    },
    [totalCount, companyId, showMyAssignmentsOnly]
  )

  const onSelectStatus = (status: string) => {
    scrollToTop()
    setPageNumber(1)
    setSelectedStatus(status)
  }

  return {
    totalCount,
    findAssignmentNames,
    onChangeSearch,
    searchedAssignmentChanged,
    statusArray,
    onSelectStatus,
    selectedStatus,
    isLoading,
    totalItems,
    assignments,
    totalPages,
    paginationAssignmentList,
    handlePageChange,
    setShowMyAssignmentsOnly,
    showMyAssignmentsOnly
  }
}

export { useAssignments }
