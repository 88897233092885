import { searchGuideId } from "./consts"
import { peopleSearchPages } from "utils/google-search/google-search.definitions"

export const buildSearchUrl = (query: string): string => {
  const sitesQuery = peopleSearchPages
    .map((site) => `site:${site}`)
    .join(" OR ")
  const xrayQuery = `${query} (${sitesQuery})`

  return `https://www.google.com/search?q=${xrayQuery}&talentisKeywords=${query}`
}

export const onWatchGuideClick = (guideId: number) => {
  window.userGuiding?.previewGuide(guideId || searchGuideId)
}

export const finishUserGuiding = (guideId: number) => {
  window.userGuiding?.finishPreview(guideId || searchGuideId)
}
