import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { browserHistory } from "setup/router/setup"
import { Nullable } from "tsdef"

let reactPlugin: Nullable<ReactPlugin> = null
let appInsights: ApplicationInsights | null = null

const createTelemetryService = () => {
  const initialize = (instrumentationKey: string) => {
    if (!browserHistory) {
      throw new Error("Could not initialize Telemetry Service")
    }
    if (!instrumentationKey) {
      throw new Error(
        "Instrumentation key not provided in ./src/telemetry-provider.jsx"
      )
    }

    reactPlugin = new ReactPlugin()

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory
          }
        }
      }
    })

    appInsights.loadAppInsights()
  }

  return { reactPlugin, appInsights, initialize }
}

export const ai = createTelemetryService()
export const getAppInsights = () => appInsights
