import React from "react"
import { ActionButtonStyled } from "./style"

export type CVActionButtonProps = {
  onEditAll: () => void
  onRestoreAll: () => void
  disableEditAll: boolean
  editAllLabel: string
  restoreAllLabel: string
  disableRestore?: boolean
}
export const DeleteCVActionButton = ({
  onEditAll,
  onRestoreAll,
  disableEditAll,
  editAllLabel,
  restoreAllLabel
}: CVActionButtonProps) => {
  const onClick = () => {
    if (disableEditAll) {
      onRestoreAll()
    } else {
      onEditAll()
    }
  }
  return (
    <ActionButtonStyled
      mode="standard-white"
      size="extra-small"
      onClick={onClick}
    >
      {disableEditAll ? restoreAllLabel : editAllLabel}
    </ActionButtonStyled>
  )
}
