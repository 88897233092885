import React from "react"
import { tempInnerModalStyles } from "setup/modal/modal.helpers"
import { Formik, Form } from "formik"
import { FormikDropzone } from "components/functional/formik/formik-dropzone/FormikDropzone"
import { Nullable } from "tsdef"
import { Button } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import {
  PersonAvatarInitialValues,
  PersonAvatarField,
  personAvatarE2ETargets
} from "views/persons/components/person-avatar/constants/person-avatar.definitions"
import { initialFormState } from "utils/forms/form-state"
import { personAvatarValidationSchema } from "views/persons/components/person-avatar/constants/person-avatar.schema"
import { ManagePersonAvatarWrapper } from "views/persons/components/person-avatar/components/ManagePersonAvatarWrapper"
import { PersonPhoto } from "views/persons/components/person-avatar/constants/person-avatar.definitions"
import { Div } from "@ikiru/talentis-fpc"
import {
  OnAvatarDeleteType,
  OnUploadPersonPhotoAvatarSubmitType
} from "views/persons/components/person-avatar/person-avatar.actions"
import { H2 } from "@ikiru/talentis-fpc"

type PersonAvatarProps = {
  photo: Nullable<PersonPhoto>
  name: string
  onAvatarDelete: OnAvatarDeleteType
  onAvatarUpload: OnUploadPersonPhotoAvatarSubmitType
}

export const PersonAvatarModal = (props: PersonAvatarProps) => {
  const { photo, name = "", onAvatarDelete, onAvatarUpload } = props
  const { close } = useModal()

  return (
    <div style={tempInnerModalStyles}>
      {!photo?.url && <H2 mt="0">{messages.person.avatar.uploadAvatar}</H2>}
      <Formik
        initialValues={PersonAvatarInitialValues}
        onSubmit={onAvatarUpload}
        initialStatus={initialFormState}
        validationSchema={personAvatarValidationSchema}
        validateOnChange={true}
      >
        {(formik) => {
          const {
            resetForm,
            status: { formState },
            isSubmitting
          } = formik

          return (
            <Div mb="s">
              <Form>
                <FormikDropzone
                  id={PersonAvatarField.File}
                  name={PersonAvatarField.File}
                  data-e2e-target-name={personAvatarE2ETargets.input}
                  renderDropzoneComponent={(props) => (
                    <ManagePersonAvatarWrapper
                      {...props}
                      onAvatarDelete={onAvatarDelete}
                      resetForm={resetForm}
                      formState={formState}
                      isSubmitting={isSubmitting}
                      photo={photo}
                      name={name}
                    />
                  )}
                />
              </Form>
            </Div>
          )
        }}
      </Formik>
      <div>
        <Button
          mode="secondary"
          size="standard"
          onClick={() => {
            close(ModalNames.PersonAvatar)
          }}
        >
          {messages.generic.cancel}
        </Button>
      </div>
    </div>
  )
}
