import styled from "styled-components"

export const ClientPortalShareSettingsModalWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grey.lightest};
  width: 100svw;
  height: 100svh;
  position: relative;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;

  ${({ theme }) => `
    ${theme.mediaQueries.sm} {
      width: 500px;
      height: auto;
      min-height: 320px;
    }
  `}
`

export const InviteColleaguesDetailsSection = styled.div`
  max-height: 50vh;
  overflow-y: auto !important;
`
