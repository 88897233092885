import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import { AssignmentDataField } from "../../assignment-data/constants/definitions"

export const createAssignmentValidationSchema = Yup.object({
  [AssignmentDataField.CompanyName]: Yup.string().max(
    110,
    messages.form.generic.errors.mustBeNoLongerThan.format(110)
  ),
  [AssignmentDataField.JobTitle]: Yup.string()
    .max(120, messages.form.generic.errors.mustBeNoLongerThan.format(120))
    .required(messages.generic.required),
  [AssignmentDataField.Location]: Yup.string().max(
    255,
    messages.form.generic.errors.mustBeNoLongerThan.format(255)
  )
})
