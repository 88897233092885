export type AITemplatesType = {
  connectionRequest?: string
  consentNotification?: string
  coreMessage?: string
  introductoryEmail?: string
  liNotification?: string
  linkedinMessage?: string
}

export enum AiTemplatesName {
  ConnectionRequest = "connectionRequest",
  ConsentNotification = "consentNotification",
  CoreMessage = "coreMessage",
  IntroductoryEmail = "introductoryEmail",
  LiNotification = "liNotification",
  LinkedinMessage = "linkedinMessage"
}

export enum TemplatePageHeader {
  GDPR = "gdpr",
  AITemplates = "aiTemplates"
}
