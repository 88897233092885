import * as Yup from "yup"
import { RegisterUserField } from "views/users/register-user/constants/register-user.definitions"

export const registerUserUserValidationSchema = Yup.object({
  [RegisterUserField.FirstName]: Yup.string()
    .required("First name is a required field.")
    .max(255, "Must be 255 characters or less"),
  [RegisterUserField.LastName]: Yup.string()
    .required("Last name is a required field.")
    .max(255, "Must be 255 characters or less"),
  [RegisterUserField.JobTitle]: Yup.string().max(
    255,
    "Must be 255 characters or less"
  ),
  [RegisterUserField.Password]: Yup.string()
    .required("Password is a required field.")
    .min(8, "Password is too short - should be 8 chars minimum.")
})
