import styled from "styled-components"
import { Div, Flex, H5, breakpoints, spacing, theme } from "@ikiru/talentis-fpc"

export const TutorialTitles = styled(H5)`
  width: 230px;
  margin-top: 0;
  margin-right: ${spacing.xs}px;
  @media screen and (max-width: ${breakpoints.lg}) {
    width: 150px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 200px;
  }
`
export const StyledFlex = styled(Flex)`
  cursor: pointer;
  margin-top: ${spacing.m}px;
  ${theme.mediaQueries.sm} {
    margin-top: 0;
  }
`
export const OuterFlex = styled(Flex)`
  flex-direction: column;
  padding-top: 0;
  margin-left: 0;
  ${theme.mediaQueries.sm} {
    flex-direction: row;
    padding-top: ${spacing.m}px;
    margin-left: ${spacing.xxs}px;
    margin-right: ${spacing.xxs}px;
  }
`
export const StyledDiv = styled(Div)`
  display: inline-block;
  background: ${({ theme }) => theme.colors.grey.lightest};
  margin-top: ${spacing.m}px;
  margin-right: 22px;
  width: 465px;
  height: fit-content;
`
export const SupportFlex = styled(Flex)`
  flex-direction: column;
  ${theme.mediaQueries.md} {
    @media screen and (max-width: ${breakpoints.xl}) {
      flex-direction: column;
    }
    @media screen and (min-width: ${breakpoints.xl}) {
      flex-direction: row;
    }
  }
`
