import styled from "styled-components"
import { BodyText } from "@ikiru/talentis-fpc"

export const ErrorLink = styled(BodyText)<{ onClick: () => void }>`
  font-weight: bold;
  color: inherit;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
`
