import React, { useState, useEffect } from "react"
import { Div, Input, Error } from "@ikiru/talentis-fpc"
import { ListLocationSearch } from "./ListLocation"
import { Location } from "views/assignments/assignment.types"
import { messages } from "setup/messages/messages"
import useAzureMapsTypeAhead from "./hooks/useAzureMapsTypeAhead"
import { useComponentVisible } from "utils/hooks/use-component-visable"

type LocationInputValues = {
  name: string
  id: string
  label: string
  selectedLocation: (value: Location) => void
  isEdit?: boolean
  initialSearchedWord?: string
}

const LocationSearch = (props: LocationInputValues) => {
  const [selected, setSelected] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const {
    label,
    selectedLocation,
    isEdit = false,
    initialSearchedWord = ""
  } = props
  const {
    setLocations,
    locations,
    showAzureMapList,
    searchedWord,
    setSearchedWord,
    setShowAzureMapList
  } = useAzureMapsTypeAhead()

  const { divRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const onChanged = (e: any) => {
    setSearchedWord(e.target.value)
    setError(false)
    if (!showAzureMapList) {
      setShowAzureMapList(true)
    }
  }

  const onClick = (data: Location) => {
    setSearchedWord(data.label.trim())
    selectedLocation(data)
    setShowAzureMapList(false)
    setSelected(true)
  }

  const setInitialResults = () => {
    setSearchedWord("")
    setSelected(false)
    setLocations([])
  }

  useEffect(() => {
    if (
      searchedWord?.length > 3 &&
      locations?.length === 0 &&
      showAzureMapList &&
      searchedWord !== initialSearchedWord
    ) {
      setError(true)
    } else {
      setError(false)
    }
  }, [searchedWord, locations, showAzureMapList, initialSearchedWord])

  const invalid = (e: any) => {
    e.preventDefault()
    setError(true)
  }

  useEffect(() => {
    setSearchedWord(initialSearchedWord)
  }, [initialSearchedWord, setSearchedWord])

  return (
    <Div width="100%" ref={divRef}>
      <Input
        clearable
        maxLength={255}
        readOnly={selected}
        onClear={setInitialResults}
        label={label}
        onChange={onChanged}
        value={searchedWord}
        required
        minLength={3}
        onInvalid={invalid}
        onFocus={() => setIsComponentVisible(true)}
      />
      {error && <Error>{messages.form.generic.errors.locationDropDown}</Error>}

      {showAzureMapList && isComponentVisible && (
        <Div {...(!isEdit && { position: "relative" })}>
          <ListLocationSearch
            locations={locations}
            onItemClicked={onClick}
            editStyle={isEdit}
          />
        </Div>
      )}
    </Div>
  )
}

export default LocationSearch
