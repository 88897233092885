import React, { useEffect, useState } from "react"
import {
  Div,
  Flex,
  Line,
  Loader,
  Section,
  SmallText,
  colors,
  getMinWidthMediaQuery
} from "@ikiru/talentis-fpc"
import AITextarea from "./components/AITextarea"
import {
  AiTemplatesName,
  AITemplatesType,
  TemplatePageHeader
} from "./constants/definitions"
import { messages } from "setup/messages/messages"
import { ProjectFooterBox } from "views/persons/components/person-assignments/components/AssignmentRecord/style"
import { RecordFooterTabItem, RecordFooterTabs } from "views/persons/styles"
import { FormikHelpers } from "formik"
import { ValuesType } from "./components/AITextarea/TemplateEditModal"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import CoreMessageInput from "./components/GPTTemplateInput/CoreMessageInput"
import ComingSoonOutlook from "./components/GPTTemplateInput/ComingSoonMessage"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { useTalentisUser } from "setup/auth/module/auth.hooks"
import { replaceAllOccurrences } from "utils/helpers"

type AITemplatesProps = {
  setTemplateValues: any
  templateValues: AITemplatesType
  defaultSearchPlaceHolder: string
  onGenerateTemplate: (
    format: string,
    language: string,
    coreMessage: string
  ) => void
  updateTemplate: (data: any) => Promise<void>
  isLoading: boolean
}

const AITemplates = (props: AITemplatesProps) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("AIlanguage") || "English"
  )
  const [format, setFormat] = useState("formal")
  const [isLoadingDefaults, setIsLoadingDefaults] = useState(false)
  const [activeTab, setActiveTab] = useState(TemplatePageHeader.AITemplates)
  const {
    onGenerateTemplate,
    templateValues,
    setTemplateValues,
    updateTemplate,
    isLoading,
    defaultSearchPlaceHolder
  } = props

  const { getTalentisUser } = useTalentisUser()

  const { close } = useModal()

  const onChangeCoreTemplate = (name: AiTemplatesName, newValue: string) => {
    templateValues[name] = newValue
    setTemplateValues(templateValues)
  }

  const onSave = (
    dataKey: string,
    value: ValuesType,
    action: FormikHelpers<ValuesType>
  ) => {
    let newData = { ...templateValues, [dataKey]: value.templateText }
    updateTemplate(newData)
    close(ModalNames.TemplateEditModal)
  }

  const mediaQuery = getMinWidthMediaQuery("sm")
  const isMobile = !useMediaQuery(mediaQuery)

  useEffect(() => {
    const getUser = async () => {
      setIsLoadingDefaults(true)
      const user = await getTalentisUser()

      let newData = templateValues
      const searchString = /XYZ@agencyABC.com/gi
      const replaceWith = user.userEmailAddress

      if (!Boolean(templateValues?.liNotification)) {
        const defaultString = replaceAllOccurrences(
          messages.project.outReach.defaultLegitimateInterestTemplate,
          searchString,
          replaceWith
        )
        //   newData.liNotification = defaultString
      }

      if (!Boolean(templateValues?.consentNotification)) {
        const defaultString = replaceAllOccurrences(
          messages.project.outReach.defaultConsentTemplate,
          searchString,
          replaceWith
        )
        // newData.consentNotification = defaultString
      }

      updateTemplate(newData)
      setIsLoadingDefaults(false)
    }
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.project.projectTemplatesAI
      }}
    >
      <Div p="0" width="100%">
        <Flex alignItems="flex-start" mx="m" my="l">
          <CoreMessageInput
            format={format}
            setFormat={setFormat}
            language={language}
            setLanguage={setLanguage}
            onChangeCoreTemplate={onChangeCoreTemplate}
            onGenerateTemplate={onGenerateTemplate}
            templateValues={templateValues}
            defaultSearchPlaceHolder={defaultSearchPlaceHolder}
          />
          {!isMobile && <ComingSoonOutlook />}
        </Flex>

        <Div mx="-12px">
          <ProjectFooterBox style={{ border: "none" }}>
            <RecordFooterTabs>
              <RecordFooterTabItem
                onClick={() => {
                  setActiveTab(TemplatePageHeader.AITemplates)
                }}
                active={activeTab === TemplatePageHeader.AITemplates}
                style={{
                  caretColor: "transparent",
                  borderRadius: "3px",
                  background:
                    activeTab === TemplatePageHeader.AITemplates
                      ? colors.white.standard
                      : ""
                }}
              >
                {messages.project.outReach.aiTemplates}
              </RecordFooterTabItem>
              <RecordFooterTabItem
                onClick={() => {
                  setActiveTab(TemplatePageHeader.GDPR)
                }}
                active={activeTab === TemplatePageHeader.GDPR}
                style={{
                  caretColor: "transparent",
                  borderRadius: "3px",
                  background:
                    activeTab === TemplatePageHeader.GDPR
                      ? colors.white.standard
                      : ""
                }}
              >
                {messages.project.outReach.gdprTemplates}
              </RecordFooterTabItem>
            </RecordFooterTabs>

            <Line variant="thin" mt="s" />
            {isLoading || isLoadingDefaults ? (
              <Div my="xxl">
                <Loader />
              </Div>
            ) : (
              <Div mt="s">
                {activeTab === "aiTemplates" && (
                  <Div>
                    <AITextarea
                      label={
                        messages.project.outReach.templateNames
                          .connectionRequest
                      }
                      name={AiTemplatesName.ConnectionRequest}
                      value={templateValues?.connectionRequest || ""}
                      onSave={onSave}
                      lowerCaseTitle
                    />
                    <AITextarea
                      label={
                        messages.project.outReach.templateNames.linkedinMessage
                      }
                      name={AiTemplatesName.LinkedinMessage}
                      value={templateValues?.linkedinMessage || ""}
                      onSave={onSave}
                      lowerCaseTitle
                    />
                    <AITextarea
                      label={
                        messages.project.outReach.templateNames
                          .introductoryEmail
                      }
                      name={AiTemplatesName.IntroductoryEmail}
                      value={templateValues?.introductoryEmail || ""}
                      onSave={onSave}
                      lowerCaseTitle
                    />
                  </Div>
                )}
                {activeTab === "gdpr" && (
                  <Div>
                    <Div
                      p="xxs"
                      width="fit-content"
                      bg={colors.orange.lightest}
                    >
                      <SmallText>
                        {messages.project.outReach.gdprDisclaimer}
                      </SmallText>
                    </Div>
                    <Div mt="xs">
                      <AITextarea
                        label={
                          messages.project.outReach.templateNames.liNotification
                        }
                        name={AiTemplatesName.LiNotification}
                        value={templateValues?.liNotification || ""}
                        onSave={onSave}
                      />
                      <AITextarea
                        label={
                          messages.project.outReach.templateNames
                            .consentNotification
                        }
                        name={AiTemplatesName.ConsentNotification}
                        value={templateValues?.consentNotification || ""}
                        onSave={onSave}
                      />
                    </Div>
                  </Div>
                )}
              </Div>
            )}
          </ProjectFooterBox>
        </Div>
      </Div>
    </Section>
  )
}

export default AITemplates
