import { Experience } from "views/persons/person.types"
import { DragType } from "./parsing/constants/definition"
import { sortByStartDate } from "utils/sort"
import { ItemDataType } from "components/FPCComponents/DragAndDrop/constants/types"

export const extractPersonData = (sortedItems: ItemDataType[]) => {
  const updatedExpList = (arr: ItemDataType[]) =>
    arr.map((item) => {
      const exp = item.itemComponentProps

      return exp
    })

  const tempParsed = updatedExpList(
    sortedItems.filter((exp) => "parsed" === exp.itemComponentProps.type)
  )

  const tempExisting = updatedExpList(
    sortedItems.filter((exp) => "existing" === exp.itemComponentProps.type)
  )

  return { tempParsed, tempExisting }
}

export const formatExpForDnD = (
  exp: Experience,
  id: string,
  defaultColumn: string
) => {
  return {
    id: `${id}`,
    type: DragType.ExpItem,
    itemComponentProps: {
      ...exp
    },
    columnId: defaultColumn
  }
}

export const sortDragAndDropItems = (items: ItemDataType[]) => {
  const validDates = (exp: ItemDataType) => {
    const startDate =
      exp.itemComponentProps.changedValues.startDate ||
      exp.itemComponentProps.startDate
    const endDate =
      exp.itemComponentProps.changedValues.endDate ||
      exp.itemComponentProps.endDate

    return { startDate, endDate }
  }

  const currentExp = items
    .filter((exp) => {
      const { startDate, endDate } = validDates(exp)
      return Boolean(startDate) && !endDate
    })
    .sort((expA, expB) => {
      const validExpA = validDates(expA)
      const validExpB = validDates(expB)
      return sortByStartDate(validExpA.startDate, validExpB.startDate)
    })

  const prevExp = items
    .filter((exp) => {
      const { startDate, endDate } = validDates(exp)
      return Boolean(startDate) && Boolean(endDate)
    })
    .sort((expA, expB) => {
      const validExpA = validDates(expA)
      const validExpB = validDates(expB)
      return sortByStartDate(validExpA.startDate, validExpB.startDate)
    })

  const noDates = items.filter((exp) => {
    const { startDate } = validDates(exp)
    return !Boolean(startDate)
  })

  return [...currentExp, ...prevExp, ...noDates]
}
