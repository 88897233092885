import { getGrecaptcha } from "utils/get-grecaptcha"
import { parseBooleanLikeEnv } from "utils/helpers"
import { CaptchaEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { AxiosResponse } from "axios"

const recaptchaSiteKey =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY || window.fpConfig.recaptchaSiteKey

const skipCaptcha =
  parseBooleanLikeEnv(process.env.REACT_APP_SKIP_INFRASTRUCTURE) || true

export enum CaptchaActions {
  SignUp = "SignUp"
}

// 1.0 is very likely a good interaction, 0.0 is very likely a bot
const CaptchaThresholds = {
  [CaptchaActions.SignUp]: 0.3
}

interface RecaptchaVerifyResponse {
  success: boolean // whether this request was a valid reCAPTCHA token for your site
  score: number // the score for this request (0.0 - 1.0)
  action: string // the action name for this request (important to verify)
  challenge_ts: string // timestamp of the challenge load (ISO format yyyy-MM-dd'T'HH:mm:ssZZ)
  hostname: string // the hostname of the site where the reCAPTCHA was solved
  "error-codes"?: any[] // optional
}

type AxiosRecaptchaResponse = [
  any,
  AxiosResponse<RecaptchaVerifyResponse> | undefined
]

export const useCaptcha = () => {
  const grecaptcha = getGrecaptcha()

  const getCaptchaPassed = (action: CaptchaActions): Promise<boolean> => {
    return new Promise((resolve) => {
      if (skipCaptcha) {
        resolve(true)
        return
      }

      grecaptcha.ready(async () => {
        const token = await grecaptcha.execute(recaptchaSiteKey, { action })
        const [, response]: AxiosRecaptchaResponse = await apiRequest.post({
          endpoint: CaptchaEndpoints.Root,
          data: { token }
        })

        const passed =
          response?.data?.action === action &&
          response?.data?.score >= CaptchaThresholds[action]
        resolve(passed)
      })
    })
  }

  return { getCaptchaPassed }
}
