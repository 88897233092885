import { getGTEDate, getLTEDate } from "./consts"
import moment from "moment"

export const getIsDueDateOverdue = (date: Date) =>
  moment(date).isSameOrBefore(moment(getLTEDate(-1).toDate() as Date), "day")
export const getIsDueDateLast7Days = (date: Date) =>
  moment(date).isSameOrBefore(moment(getLTEDate(-1).toDate() as Date), "day") &&
  moment(date).isSameOrAfter(moment(getGTEDate(-7).toDate() as Date), "day")
export const getIsDueDateYesterday = (date: Date) =>
  moment(date).isSameOrBefore(moment(getLTEDate(-1).toDate() as Date), "day") &&
  moment(date).isSameOrAfter(moment(getGTEDate(-1).toDate() as Date), "day")
export const getIsDueDateToday = (date: Date) =>
  moment(date).isSameOrBefore(moment(getLTEDate().toDate() as Date), "day") &&
  moment(date).isSameOrAfter(moment(getGTEDate().toDate() as Date), "day")
export const getIsDueDateTommorow = (date: Date) =>
  moment(date).isSameOrBefore(moment(getLTEDate(1).toDate() as Date), "day") &&
  moment(date).isSameOrAfter(moment(getGTEDate(1).toDate() as Date), "day")
export const getIsDueDateNext7Days = (date: Date) =>
  moment(date).isSameOrBefore(moment(getLTEDate(7).toDate() as Date), "day") &&
  moment(date).isSameOrAfter(moment(getGTEDate(1).toDate() as Date), "day")
export const getIsDueDateNext14Days = (date: Date) =>
  moment(date).isSameOrBefore(moment(getLTEDate(14).toDate() as Date), "day") &&
  moment(date).isSameOrAfter(moment(getGTEDate(1).toDate() as Date), "day")
export const getIsDueDateNext30Days = (date: Date) =>
  moment(date).isSameOrBefore(moment(getLTEDate(30).toDate() as Date), "day") &&
  moment(date).isSameOrAfter(moment(getGTEDate(1).toDate() as Date), "day")
