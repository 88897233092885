import { messages } from "setup/messages/messages"

export type GDPRLawfulBasisOption = {
  label: string
  value: GDPRLawfulBasis
}

export enum GDPRLawfulBasis {
  None = "none",
  VerbalConsent = "verbalConsent",
  EmailConsent = "emailConsent",
  DigitalConsent = "digitalConsent",
  LegitimateInterest = "legitimateInterest",
  NotRequired = "notRequired"
}

const gdprBasisOptions = messages.person.consents.form.options.basis

const none: GDPRLawfulBasisOption = {
  label: gdprBasisOptions.none,
  value: GDPRLawfulBasis.None
}

const verbalConsent: GDPRLawfulBasisOption = {
  label: gdprBasisOptions.verbalConsent,
  value: GDPRLawfulBasis.VerbalConsent
}

const emailConsent: GDPRLawfulBasisOption = {
  label: gdprBasisOptions.emailConsent,
  value: GDPRLawfulBasis.EmailConsent
}

const digitalConsent: GDPRLawfulBasisOption = {
  label: gdprBasisOptions.digitalConsent,
  value: GDPRLawfulBasis.DigitalConsent
}

const legitimateInterest: GDPRLawfulBasisOption = {
  label: gdprBasisOptions.legitimateInterest,
  value: GDPRLawfulBasis.LegitimateInterest
}

const notRequired: GDPRLawfulBasisOption = {
  label: gdprBasisOptions.notRequired,
  value: GDPRLawfulBasis.NotRequired
}

export const gdprLawfulBasisOptions = [
  none,
  verbalConsent,
  emailConsent,
  digitalConsent,
  legitimateInterest,
  notRequired
]
