import { FormikHelpers } from "formik"
import { apiRequest } from "setup/api/api"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import { FormAction } from "utils/forms/form.types"
import { Assignment } from "./assignment.types"
import { parseMomentDate } from "utils/moment"
import { CreateAssignmentValues } from "./components/assignment-create-form/constants/assignment-details-form.definitions"

type AssignmentUpdateCallback = (data: Assignment) => void
type ApiRequestType = (
  values: Partial<Assignment>
) => Promise<NormalizedReturnTuple>

const onUpdate =
  <T>(request: ApiRequestType, callback?: AssignmentUpdateCallback) =>
  async (values: T, helpers: FormikHelpers<T>) => {
    const { setFieldError, setSubmitting } = helpers
    setSubmitting(true)

    const newAssignmentData = { ...values }
    const [error, response] = await request(newAssignmentData)

    setSubmitting(false)
    callWithErrorMessages(setFieldError, error)

    if (response) {
      const { data = {} } = response

      const startDate = parseMomentDate(data?.startDate)

      callback?.({ ...data, startDate })
    }

    return [error, response]
  }

export const patchAssignment = <T extends Partial<Assignment>>(
  assignment: Assignment,
  callback?: AssignmentUpdateCallback
) =>
  onUpdate<T>(
    (values) =>
      apiRequest.patch({
        endpoint: AssignmentsEndpoints.Root,
        data: values,
        endpointParams: assignment.id
      }),
    callback
  )
export type OnCreateAssignmentSubmit = FormAction<CreateAssignmentValues>
