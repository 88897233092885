import moment from "moment"
import { messages } from "setup/messages/messages"
import { sortByStartDate } from "utils/sort"
import { Experience } from "views/persons/person.types"
import { checkValue } from "views/search/components/PersonRecordLayout/helper"

export const sortExperience = (
  experience: Experience[] = [],
  companyId?: string,
  addDuration?: boolean
) => {
  let jobs = experience
  if (addDuration) {
    jobs = jobs
      .filter((job) => checkValue(job.title) || checkValue(job.company))
      .map((job) => {
        if (!job.startDate) return { ...job, duration: "" }
        const diff = moment.duration(
          moment(
            !job.endDate ? moment().format("MM/DD/YYYY") : job.endDate
          ).diff(moment(job.startDate))
        )
        const years = diff.years()
        let months = diff.asMonths() - years * 12
        if (months < 11) {
          months = Math.round(months)
        } else {
          months = Math.floor(months)
        }
        const yearsString =
          years > 0
            ? `${years} ${messages.person.personRecordBox.year}${
                years > 1 ? messages.person.personRecordBox.yearsNmonths : ""
              } `
            : ""
        const monthsString =
          months > 0
            ? `${months} ${messages.person.personRecordBox.month}${
                months > 1 ? messages.person.personRecordBox.yearsNmonths : ""
              } `
            : ""
        return {
          ...job,
          duration: `${yearsString} ${monthsString}`
        }
      })
  }

  if (!jobs.length) {
    return []
  }

  const sortedExperience = jobs.sort((expA, expB) =>
    sortByStartDate(expA.startDate, expB.startDate)
  )

  if (companyId) {
    return sortedExperience.filter((exp) => exp.companyId === companyId)
  }

  return sortedExperience
}
