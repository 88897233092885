import { useState, useCallback } from "react"
import { saveAs } from "file-saver"
import { apiRequest } from "setup/api/api"
import { formatDate } from "utils/format-date"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { EndpointName } from "setup/api/api.types"

type useExportProjectRecordsParams = {
  projectId: string
  projectName: string
  totalItems: number | undefined
  projectRoute: EndpointName
  exportPrefix: string
}

export const useExportProjectRecords = ({
  projectId,
  projectName,
  totalItems,
  projectRoute,
  exportPrefix
}: useExportProjectRecordsParams) => {
  const [isExporting, setIsExporting] = useState(false)
  const [isError, setIsError] = useState(false)

  const exportProjectRecords = useCallback(
    async (projectRecordIds: any) => {
      setIsExporting(true)
      setIsError(false)

      const requestData = {
        endpoint: projectRoute,
        endpointParams: {
          id: projectId
        },
        data: projectRecordIds || undefined,
        config: {
          params: { pageSize: totalItems },
          responseType: "blob" as "blob",
          headers: {
            ...skipErrorHeader
          }
        }
      }

      const [error, response] = await (projectRecordIds
        ? apiRequest.post(requestData)
        : apiRequest.get(requestData))

      if (Boolean(error)) {
        setIsError(true)
        setIsExporting(false)
        return
      }

      const today = new Date()
      const fileName = `${exportPrefix}_${projectName}_${formatDate(today)}.csv`

      saveAs(response?.data, fileName)
      setIsExporting(false)
    },
    [projectId, projectName, totalItems, exportPrefix, projectRoute]
  )

  return {
    isExporting,
    isError,
    exportProjectRecords
  }
}
