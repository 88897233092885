import React, { useMemo, useRef } from "react"
import { Div, PlusIcon } from "@ikiru/talentis-fpc"
import ViewableSection from "components/ViewableSection"
import { PersonSectionHeaders } from "../../style"
import { messages } from "setup/messages/messages"
import PersonWebLinksView from "./components/PersonWebLinksView"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import ActionButton from "components/ProjectActions/ActionButton"
import { useModal } from "utils/hooks/use-modal"
import useSubmitPerson from "../../hook/useSubmitPerson"
import { ModalNames } from "setup/modal/modal.definitions"
import PersonWebLinkEdit from "./form/PersonWebLinkEdit"
import { CreatePersonField } from "../../constant/definitions"

const PersonWebLink = () => {
  const { profileDetails, setLinkedinEditMode } = usePerson()
  const { open: openModal } = useModal()
  const { onPersonSubmit } = useSubmitPerson()

  const addWebsiteButtonRef = useRef<HTMLButtonElement>(null!)
  const buttonRefs = {
    addWebsiteButtonRef
  }
  const editPersonWebLinks = (addingNewEntry = false) => {
    const linkedInProfileUrl = profileDetails.linkedInProfileUrl
    const initialValue = {
      webSites: profileDetails.webSites.length
        ? profileDetails.webSites
        : [{ value: { url: "" }, isReadOnly: false }],
      linkedInProfileUrl: profileDetails.linkedInProfileUrl
    }

    if (addingNewEntry) {
      if (
        Boolean(
          initialValue.webSites[initialValue.webSites.length - 1].value.url
        )
      ) {
        initialValue.webSites.push({ value: { url: "" }, isReadOnly: false })
      }
    }

    let defaultEditedField: CreatePersonField
    if (Boolean(linkedInProfileUrl?.value) || addingNewEntry) {
      defaultEditedField = CreatePersonField.WebSites
    } else {
      defaultEditedField = CreatePersonField.LinkedInUrl
      setLinkedinEditMode(true)
    }

    openModal(
      ModalNames.EditPersonDetails,
      <PersonWebLinkEdit
        onSubmit={onPersonSubmit}
        initialValues={initialValue}
        buttonRefs={buttonRefs}
        defaultEditedField={defaultEditedField}
      />
    )
  }

  const webSites = useMemo(() => profileDetails.webSites, [profileDetails])

  return (
    <Div width="100%">
      <ViewableSection
        view={
          <PersonWebLinksView
            webSites={webSites}
            linkedInProfileUrl={profileDetails.linkedInProfileUrl}
          />
        }
        title={
          <PersonSectionHeaders>
            {messages.person.personTabSection.webLinks}
          </PersonSectionHeaders>
        }
        actions={
          <ActionButton
            onClick={() => editPersonWebLinks(true)}
            label={messages.person.personTabSection.webSite}
            prefix={<PlusIcon width={12} height={12} fill="currentColor" />}
          />
        }
        editFunction={editPersonWebLinks}
      />
    </Div>
  )
}

export default PersonWebLink
