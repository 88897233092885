import React, { useContext, useCallback } from "react"
import { matchPath, useLocation } from "react-router-dom"
import {
  HeaderSetting,
  HeaderOption,
  HeaderLink
} from "components/navigation/constants/navigation.definitions"
import { Flex } from "@ikiru/talentis-fpc"
import { preventDefault } from "utils/prevent-default"
import {
  SidebarFooterSettingsWrapper,
  SidebarSetting,
  SidebarSettingIcon,
  SidebarSettingLabel
} from "./SidebarFooterSettings.styled"
import { SidebarContext } from "components/visual/header/SidebarContext"
import { OptionsPopup } from "../OptionsPopup"
import { SidebarLink } from "../SidebarLinks/SidebarLink"

type Props = {
  settings: HeaderSetting[]
}

export const SidebarFooterSettings = React.memo(({ settings }: Props) => {
  const { isExpanded } = useContext(SidebarContext)
  const location = useLocation()

  const renderItem = useCallback(
    ({ icon, label, onClick, disabled, e2eTargets }: HeaderSetting) => (
      <Flex
        alignItems="center"
        onClick={disabled ? preventDefault : onClick}
        data-e2e-target={e2eTargets?.e2eTarget}
        data-e2e-target-name={e2eTargets?.e2eTargetName}
      >
        <SidebarSettingIcon>{icon}</SidebarSettingIcon>
        {isExpanded && <SidebarSettingLabel>{label}</SidebarSettingLabel>}
      </Flex>
    ),
    [isExpanded]
  )

  const renderPopupActions = useCallback(
    (setting: HeaderSetting) => (
      <OptionsPopup
        config={{
          PopupProps: {
            disabled: setting.disabled,
            position: "top left",
            offsetY: -20,
            offsetX: 20
          }
        }}
        trigger={renderItem(setting)}
        options={setting.options || []}
      />
    ),
    [renderItem]
  )

  return (
    <SidebarFooterSettingsWrapper>
      {settings.map((setting, index) => {
        const { options = [] } = setting || {}
        const possiblePaths = options
          .filter((opt: HeaderOption) => opt.path)
          .map((opt) => opt.path) as string[]

        const isSettingActive = possiblePaths.some((path) =>
          matchPath(location.pathname, path)
        )

        return (
          <SidebarSetting
            isSettingActive={isSettingActive}
            key={`${setting.label}_${index}`}
            disabled={setting.disabled}
          >
            {setting.path ? (
              <SidebarLink {...(setting as HeaderLink)} />
            ) : setting.onClick ? (
              renderItem(setting)
            ) : (
              renderPopupActions(setting)
            )}
          </SidebarSetting>
        )
      })}
    </SidebarFooterSettingsWrapper>
  )
})
