import React, { useEffect, useRef, useState } from "react"
import { Flex, H5, SmallText, theme } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { Experience } from "views/persons/person.types"
import { generateCurrency } from "views/assignments/utils"
import { useWindowSize } from "utils/hooks/use-window-size"
import {
  ButtonStyled,
  CommentWrapper,
  DescriptionWrapper,
  DropDownIconStyled
} from "./styles"
import { Nullable } from "tsdef"

type PersonExperienceDetailsProps = {
  jobHeader: React.ReactNode
  expStartDate: Nullable<string>
  expEndDate: Nullable<string>
  experience: Experience
  title?: string
}

export const PersonExperienceDetails = ({
  jobHeader,
  expStartDate,
  expEndDate,
  experience,
  title
}: PersonExperienceDetailsProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isShowedButton, setIsShowedButton] = useState(false)

  const commentRef = useRef(undefined)
  const descriptionRef = useRef(undefined)

  const size = useWindowSize()

  useEffect(() => {
    const heightCommentBound = 22 * 1
    const heightDescriptionBound = 22 * 2

    const { scrollHeight: descriptionScrollHeight } =
      descriptionRef?.current! || { scrollHeight: 0 }
    const { scrollHeight: commentScrollHeight } = commentRef?.current! || {
      scrollHeight: 0
    }

    setIsShowedButton(
      heightDescriptionBound < descriptionScrollHeight ||
        heightCommentBound < commentScrollHeight
    )
  }, [size])

  return (
    <>
      <Flex flexDirection="column" mb="xs" width="100%">
        <Flex>
          <Flex width="300px" minWidth="300px" flexDirection="column" mr="m">
            {jobHeader}
            <Flex>
              {expStartDate && (
                <SmallText
                  color={theme.colors.grey.dark}
                >{`${expStartDate} - ${expEndDate}`}</SmallText>
              )}
              <SmallText color="grey.dark" my={0} ml="xxs">
                {Boolean(experience.duration) && `  ( ${experience.duration})`}
              </SmallText>
            </Flex>
            <Flex>
              <SmallText width="50%">
                <SmallText fontWeight={600} mr="xxs">
                  {messages.person.experience.salary}:
                </SmallText>
                {experience.compensation?.salary?.value
                  ? generateCurrency(
                      experience.compensation.salary.value,
                      experience.compensation.salary.currency
                    )
                  : messages.person.experience.unspecified}
              </SmallText>
              <SmallText>
                <SmallText fontWeight={600} mr="xxs">
                  {messages.person.experience.package}:
                </SmallText>
                {experience.compensation?.package?.value
                  ? generateCurrency(
                      experience.compensation.package.value,
                      experience.compensation.package.currency
                    )
                  : messages.person.experience.unspecified}
              </SmallText>
            </Flex>

            <CommentWrapper
              expanded={isExpanded}
              visibleLines={1}
              ref={commentRef}
            >
              {experience.compensation?.comment}
            </CommentWrapper>
          </Flex>

          <Flex flexDirection="column">
            <H5 m="0">{title}</H5>
            <DescriptionWrapper
              expanded={isExpanded}
              visibleLines={2}
              ref={descriptionRef}
            >
              {experience.description}
            </DescriptionWrapper>
          </Flex>
        </Flex>
      </Flex>
      {isShowedButton && (
        <ButtonStyled
          mode="standard-white"
          size="action-small"
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          <DropDownIconStyled up={isExpanded} />
        </ButtonStyled>
      )}
    </>
  )
}
