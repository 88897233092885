import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { useState } from "react"
import { getAppliedFiltersParams } from "components/ProjectRecordsFilters/helpers"

export const useChangeContact = () => {
  const [isFirstElement, setIsFirstElement] = useState(false)
  const [isLastElement, setisLastElement] = useState(false)
  const {
    setSelectedContactId,
    selectedContactId,
    contacts,
    setCampaignPaginationContact,
    paginationContact: { pageNumber = 1, totalFilteredCount = 0, pageSize = 1 },
    getCampaignContacts,
    campaignId,
    appliedFilters
  } = useCampaign()

  const totalPages = Math.ceil(totalFilteredCount / pageSize)

  const { getProfileData } = usePerson()
  const directionNext = () => changeContact(1)

  const directionPrevious = () => changeContact(-1)

  const changeContact = (direction: number) => {
    setIsFirstElement(false)
    setisLastElement(false)
     
    const personsNew = contacts?.filter((contact) => contact.personId) || []

    const contactIdList = personsNew.map((contact) => contact.personId)

    const contatcLocation: number = contactIdList.indexOf(selectedContactId)

    const newContactLocation: number = contatcLocation + direction

    if (
      !(newContactLocation < 0) &&
      !(newContactLocation > contactIdList.length - 1)
    ) {
      setSelectedContactId(contactIdList[newContactLocation] || "")
      getProfileData(contactIdList[newContactLocation] || "")
    } else if (totalPages > 1) {
      if (pageNumber < totalPages && direction === 1) {
        setisLastElement(true)
        const newPageNumber = pageNumber + 1
        setCampaignPaginationContact({ pageNumber: newPageNumber })
        const params = getAppliedFiltersParams(appliedFilters)
        params.append("page", newPageNumber.toString())
        getCampaignContacts(campaignId,params)
      } else if (pageNumber > 1 && direction === -1) {
        setIsFirstElement(true)
        const newPageNumber = pageNumber - 1
        setCampaignPaginationContact({ pageNumber: newPageNumber })
        const params = getAppliedFiltersParams(appliedFilters)
        params.append("page", newPageNumber.toString())
        getCampaignContacts(campaignId,params)
      }
    }
  }

  return { directionNext, directionPrevious, isLastElement, isFirstElement }
}
