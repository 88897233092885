import {
  peopleSearchPages,
  GooglePersonSearchQueryParams
} from "utils/google-search/google-search.definitions"
import { GoogleSearchType } from "components/google-search/constants/GoogleSearch.definitions"

// This is the query used by the search bar in the header
export const prepareGoogleQuery = (
  query: string,
  type: GoogleSearchType
): string => {
  const sites = [...(Boolean(type.people) ? peopleSearchPages : [])]

  const sitesQuery = sites.map((site) => `site:${site}`).join(" OR ")

  return sitesQuery.length > 0 ? `( ${query} ) AND ( ${sitesQuery} )` : query
}

export const buildGoogleUrl = (query: string, keywords: string = ""): string =>
  `https://www.google.com/search?q=${query}&${GooglePersonSearchQueryParams.Keywords}=${keywords}`
