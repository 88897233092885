export enum GenerateAiBioFormFields {
  Language = "language",
  isShort = "isShort"
}

export type GenerateAiBioFormValues = {
  language: string
  isShort: boolean
}

export const bioSettingsInitialValues = {
  [GenerateAiBioFormFields.isShort]: true,
  [GenerateAiBioFormFields.Language]: "English"
}
