import React, { useCallback, useState } from "react"
import { Button, Flex, H3, colors } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { StyledDiv } from "../style"
import { Experience } from "views/persons/person.types"
import { InheritedOffLimitsBlock } from "./InheritedOffLimitsBlock"
import { Nullable } from "tsdef"
import { OverrideType } from "../type"

type InheritedOffLimitsDataProps = {
  onSubmit: (values: OverrideType[]) => void
  inheritedOffLimits: Experience[]
}

export const InheritedOffLimitsData = ({
  onSubmit,
  inheritedOffLimits
}: InheritedOffLimitsDataProps) => {
  const [inheritedOffLimitsTemp, setInheritedOffLimitsTemp] =
    useState<Experience[]>(inheritedOffLimits)
  const { close } = useModal()

  const submitForm = () => {
    const overriddenOffLimits = inheritedOffLimits.map((inheritedOffLimit) => {
      return {
        id: inheritedOffLimit.id,
        isOverridden: Boolean(
          inheritedOffLimit?.offlimitsOverride?.isOverridden
        )
      }
    })
    onSubmit(overriddenOffLimits)

    close(ModalNames.EditInheritedOffLimits)
  }

  const onOverrideClick = useCallback(
    (companyId: Nullable<string>, isOverridden: boolean) => {
      setInheritedOffLimitsTemp(
        inheritedOffLimitsTemp.map((inheritedOffLimit: Experience) => {
          if (inheritedOffLimit.companyId === companyId) {
            inheritedOffLimit.offlimitsOverride = {
              ...inheritedOffLimit.offlimitsOverride,
              isOverridden: isOverridden
            }
          }
          return inheritedOffLimit
        })
      )
    },
    [inheritedOffLimitsTemp]
  )

  return (
    <StyledDiv>
      <Flex justifyContent="space-between" alignItems="center" mb="s">
        <H3 color={colors.grey.dark} m="0" p="0">
          {messages.offLimits.inheritedCompanyEditMode}
        </H3>
        <Flex>
          <Button mx="xxs" my="0" onClick={submitForm} size="small">
            {messages.form.generic.save}
          </Button>
          <Button
            mode="standard-white"
            mx="xxs"
            my="0"
            size="small"
            onClick={() => close(ModalNames.EditInheritedOffLimits)}
          >
            {messages.generic.cancel}
          </Button>
        </Flex>
      </Flex>
      <Flex maxHeight="550px" overflowY="auto" flexDirection="column">
        {inheritedOffLimitsTemp
          .filter((value, index) => {
            const copyIndex = inheritedOffLimitsTemp.findIndex(
              (exp) => exp.companyId === value.companyId
            )
            return copyIndex === index
          })
          .map((experience: Experience) => (
            <InheritedOffLimitsBlock
              inheritedOffLimits={experience}
              onOverrideClick={onOverrideClick}
            />
          ))}
      </Flex>
    </StyledDiv>
  )
}
