export enum BillingAddressFields {
  Email = "email",
  Address = "addressFirstLine",
  City = "city",
  CountryCode = "countryCode",
  PostCode = "postcode",
  Phone = "phone",
  VatNumber = "vatNumber"
}

export type BillingAddressType = {
  [BillingAddressFields.Email]: string
  [BillingAddressFields.Address]: string
  [BillingAddressFields.City]: string
  [BillingAddressFields.CountryCode]: string
  [BillingAddressFields.PostCode]: string
  [BillingAddressFields.Phone]: string
  [BillingAddressFields.VatNumber]?: string
}

export const initialBillingAddressValues = {
  [BillingAddressFields.Email]: "",
  [BillingAddressFields.Address]: "",
  [BillingAddressFields.City]: "",
  [BillingAddressFields.CountryCode]: "",
  [BillingAddressFields.PostCode]: "",
  [BillingAddressFields.Phone]: "",
  [BillingAddressFields.VatNumber]: ""
}

export const BillingE2eTargets = {
  disabledCardNumber: "disabledCardNumber",
  disabledExpiryDate: "disabledExpiryDate",
  disabledSecurityCode: "disabledSecurityCode",
  nameOnCard: "nameOnCard"
}
