import React from "react"
import { Popup } from "@ikiru/talentis-fpc"
import { navigationSelectors } from "components/visual/layout/definitions"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { GeneralFilters } from "./filters/GeneralFilters"
import { messages } from "setup/messages/messages"
import { useTaskFilter } from "./useTaskFilter"
import { useTaskManagement } from "views/homepage/task-management-module/task-management-module.context"

interface FilterPopupProps {
  trigger: React.ReactElement
  onOpen?: () => any
  onClose?: () => any
}

export const TaskFilterPopup = (props: FilterPopupProps) => {
  const { trigger, onOpen, onClose } = props
  const {
    interviewProgressStatusFilters,
    interviewProgressStageFilters,
    assignedToFilters,
    filterIsApplied,
    addFilter,
    removeFilter
  } = useTaskFilter()
  const { isAssignment } = useTaskManagement()

  const newConfig = {
    PopupProps: {
      arrow: false,
      keepTooltipInside: true,
      onOpen,
      onClose
    },
    ContentProps: {
      boxShadow: "0 0 4px rgba(75, 106, 136, 0.8)"
    }
  }

  return (
    <Popup
      trigger={trigger}
      config={newConfig}
      closeOnScrollSelector={`#${navigationSelectors.mainContainer}`}
    >
      <Flex flexWrap={["wrap", "nowrap"]} bg="grey.lightest" maxHeight={500}>
        {isAssignment ? (
          <Div minWidth={["auto", "170px"]} bg="white.standard" flex={1}>
            <GeneralFilters
              translatedString={messages.assignment.filters.stage}
              filtersData={interviewProgressStageFilters}
              {...{ filterIsApplied, addFilter, removeFilter }}
            />
          </Div>
        ) : null}
        <Div minWidth={["auto", "170px"]} bg="white.standard" flex={1}>
          <GeneralFilters
            translatedString={messages.assignment.filters.status}
            filtersData={interviewProgressStatusFilters}
            {...{ filterIsApplied, addFilter, removeFilter }}
          />
        </Div>
        <Div minWidth={["auto", "170px"]} ml="xxs" bg="white.standard" flex={1}>
          <GeneralFilters
            translatedString={messages.assignment.filters.assignedTo}
            filtersData={assignedToFilters}
            {...{ filterIsApplied, addFilter, removeFilter }}
          />
        </Div>
      </Flex>
    </Popup>
  )
}
