import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import { colors } from "@ikiru/talentis-fpc"
import {
  CVInputWrapper,
  PencilIconStyle,
  StyledTextareaAutosize
} from "./style"
import InputFooter from "./InputFooter"
import OverviewInputFooter from "./OverviewInputFooter"

type CVInputProps = {
  text: string
  onChangeInput?: (text: string) => void
  saveTempValue?: (text: string) => void
  width?: string
  isDisabled?: boolean
  isOverview?: boolean
  handleDelete?: () => void
}

const CVInput = ({
  text,
  onChangeInput,
  saveTempValue,
  width,
  isDisabled = false,
  isOverview = false,
  handleDelete
}: CVInputProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [editedText, setEditedText] = useState(text)

  const textareaRef = useRef(null)

  const handleEditClick = () => {
    const tempText = text !== "-" ? text : ""
    setEditedText(tempText)
    setIsEditing(true)
  }

  useEffect(() => {
    if (isEditing) {
      //@ts-ignore
      textareaRef.current?.focus()
      //@ts-ignore
      const length = textareaRef.current?.value.length
      //@ts-ignore
      textareaRef.current?.setSelectionRange(length, length)
    }
  }, [isEditing])

  const handleSaveClick = () => {
    onChangeInput && onChangeInput(editedText)
    setIsEditing(false)
  }

  const handleCancelClick = () => {
    setEditedText(text || "-")
    onChangeInput && onChangeInput(text)
    setIsEditing(false)
  }

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    saveTempValue && saveTempValue(e.target.value)
    setEditedText(e.target.value)
  }

  return (
    <CVInputWrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      width={width || "100%"}
      isEditing={isEditing}
      isDisabled={isDisabled}
    >
      <StyledTextareaAutosize
        ref={textareaRef}
        minRows={1}
        value={editedText}
        onChange={handleInputChange}
        disabled={!isEditing || isDisabled}
      />
      {!isEditing && isHover && !isDisabled && (
        <>
          {isOverview ? (
            <OverviewInputFooter
              handleDelete={handleDelete}
              handleEditClick={handleEditClick}
            />
          ) : (
            <PencilIconStyle
              color={colors.grey.dark}
              onClick={handleEditClick}
              width={14}
              height={14}
            />
          )}
        </>
      )}

      {isEditing && (
        <InputFooter
          handleSaveClick={handleSaveClick}
          handleCancelClick={handleCancelClick}
        />
      )}
    </CVInputWrapper>
  )
}

export default CVInput
