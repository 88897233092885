import styled from "styled-components"
import { BodyText, H5 } from "@ikiru/talentis-fpc"

export const CustomBodyTexts = styled(BodyText)`
  padding-left: ${({ theme }) => theme.space.xxs}px;
  color: ${({ theme }) => theme.colors.grey.darkest};
  margin: 0px;
  line-height: 22px;
`
export const DataPrivacySubHeaders = styled(H5)`
  color: ${({ theme }) => theme.colors.grey.darkest};
  margin: 0px;
`
