import styled from "styled-components"
import { BookmarkIcon, Button } from "@ikiru/talentis-fpc"
import { hexToRGB } from "../../../../utils/hexToRGB"

export const BookmarkIconStyled = styled(BookmarkIcon)``

export const SavedSearchButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.white.standard};
  font-size: 12px;
  &[disabled] {
    border-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.12)};
    background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.12)};
    color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.7)};
    ${BookmarkIconStyled} {
      ${({ theme }) =>
        `
  path {
  stroke: ${hexToRGB(theme.colors.grey.light, 0.7)};
  }
  `};
    }
  }
  &:hover {
    ${BookmarkIconStyled} {
      ${({ theme }) =>
        `
  path {
  stroke: ${hexToRGB(theme.colors.white.standard)};
  }
  `};
    }
  }
`
