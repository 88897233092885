import React, { useEffect, useState } from "react"
import { ViewPersonDetails } from "views/persons/components/person-details/components/ViewPersonDetails"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { Error, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { PersonDetailsWrapper, OffLimitsContainerStyled } from "./style"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"
import { sortProfileDetails } from "./utlis"
import { LinkedInUrlField } from "views/assignments/components/assignment-client-details/linkedin-form/constants/definitions"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { getNormalizedPersonProfileDetails } from "./utils/get-person-details"
import {
  callProfileEndpoint,
  linkPersonToDatapool,
  onAddLinkedInUrl
} from "./form/actions"
import LinkToTalentGraph from "components/ProjectActions/LinkToTalantGraph/LinkToTalentGraph"
import { OffLimitsBlock } from "components/OffLimits/OffLimitsBlock"
import { PersonAssignmentProps } from "../person-assignments"
import { PersonCampaignProps } from "../person-campaigns"

type PersonDetailsProps = {
  personLinkAssignmentProps: PersonAssignmentProps
  personLinkCampaignProps: PersonCampaignProps
  project?: string
}

export const PersonDetails = React.memo(
  ({
    personLinkAssignmentProps,
    personLinkCampaignProps,
    project
  }: PersonDetailsProps) => {
    const {
      personId,
      profileDetails,
      setLinkedinEditMode,
      linkedinEditMode,
      setProfileDetails
    } = usePerson()

    const [editLinkedIn, setEditLinkedIn] = useState(false)
    const [personNotFound, setPersonNotFound] = useState(false)
    const [showLinkSuccess, setShowLinkSuccess] = useState(false)
    const [profile, setProfile] = useState<PersonProfileDetails>(profileDetails)

    const { dataPoolPersonId } = profileDetails

    useEffect(() => {
      if (linkedinEditMode && dataPoolPersonId) {
        setShowLinkSuccess(true)
        setTimeout(() => {
          setShowLinkSuccess(false)
          setLinkedinEditMode(false)
        }, 7000)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPoolPersonId, setLinkedinEditMode])

    useEffect(() => {
      const sortedProfileDetails = sortProfileDetails(profileDetails)
      setProfile(sortedProfileDetails)
    }, [profileDetails])

    return (
      profileDetails && (
        <Flex flexDirection="column" width="100%">
          <LinkToTalentGraph
            editLinkedIn={editLinkedIn}
            setEditLinkedIn={setEditLinkedIn}
            dataNotFound={personNotFound}
            showLinkSuccess={showLinkSuccess}
            isLinkedToDataPool={Boolean(profileDetails.dataPoolPersonId)}
            needToLinkMessage={messages.person.details.localOnly}
            notFoundMessage={<Error>{messages.assignment.linkCorrect}</Error>}
            successMessage={messages.person.details.linkSuccess}
            exampleText="https://uk.linkedin.com/in/johndoe"
            onCancel={() => {
              setEditLinkedIn(false)
              setPersonNotFound(false)
            }}
            onSubmit={async (values, action) => {
              const linkedInUrl = values.linkedInUrl.value

              let [error, response] = await onAddLinkedInUrl(personId)(
                linkedInUrl,
                action
              )

              if (
                error?.data?.title === "There is a conflict." &&
                error?.data?.status === 409
              ) {
                setPersonNotFound(false)
                action.setFieldError(
                  `${LinkedInUrlField.LinkedInUrl}.value`,
                  messages.person.details.linkError
                )
              } else {
                try {
                  await linkPersonToDatapool(personId)
                  const [, profileResponse] = await callProfileEndpoint(
                    personId
                  )
                  response = profileResponse
                } catch (e) {
                  console.log(e)
                }

                if (!response?.data.dataPoolPerson) {
                  setPersonNotFound(true)
                } else {
                  setTimeout(() => setShowLinkSuccess(false), 4000)
                  setShowLinkSuccess(true)
                }
              }

              const mergedPersonData = new LocalPersonModel(response?.data)

              const profileDetails =
                getNormalizedPersonProfileDetails(mergedPersonData)

              setProfileDetails(profileDetails)
            }}
          />
          <Flex width="100%">
            {profileDetails.isOfflimits && (
              <OffLimitsContainerStyled
                mt={
                  showLinkSuccess || !profileDetails.dataPoolPersonId
                    ? "0"
                    : "-10px"
                }
              >
                <OffLimitsBlock />
              </OffLimitsContainerStyled>
            )}
            <PersonDetailsWrapper isOffLimits={profileDetails.isOfflimits}>
              <ViewPersonDetails
                personId={personId}
                profile={profile}
                personLinkAssignmentProps={personLinkAssignmentProps}
                personLinkCampaignProps={personLinkCampaignProps}
                project={project}
              />
            </PersonDetailsWrapper>
          </Flex>
        </Flex>
      )
    )
  }
)
