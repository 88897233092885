import { Div, SectionBody, SectionHeader } from "@ikiru/talentis-fpc"
import React from "react"
import TabSectionHeader from "./TabSectionHeader"

type PersonSectionsProps = {
  children: React.ReactElement
  title: string
  sectionCount?: number
  actions?: React.ReactNode
}

const PersonSections = (props: PersonSectionsProps) => {
  const { title, sectionCount, actions } = props
  return (
    <Div>
      <SectionHeader
        title={<TabSectionHeader label={title} count={sectionCount} />}
        height="45px"
        actions={actions}
        px="m"
      />
      <SectionBody>{props.children}</SectionBody>
    </Div>
  )
}

export default PersonSections
