export enum PlanDetailsField {
  TotalUsersNumber = "totalUsersNumber",
  Currency = "currency",
  Frequency = "frequency"
}

export enum Frequency {
  Year = "year",
  Month = "month"
}
