import React, { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Loader } from "@ikiru/talentis-fpc"
import { Flex } from "@ikiru/talentis-fpc"
import { useAuth } from "setup/auth/module/auth.context"
import { messages } from "setup/messages/messages"
import { RouterUrl } from "setup/router/routes"
import { completeSearchFirmVerification } from "./verify-search-firm.actions"

export const VerifySearchFirm = () => {
  const { search } = useLocation()
  const { manager, isLoggedIn } = useAuth()
  const navigate = useNavigate()

  const redirectToHomePage = useCallback(() => {
    navigate(RouterUrl.Homepage)
  }, [navigate])

  const redirectToLoginPage = useCallback(
    (verificationSuccess: boolean = false) => {
      let extraQueryParams = {}
      if (verificationSuccess) {
        extraQueryParams = {
          verificationSuccess: true
        }
      }

      manager?.signinRedirect({
        extraQueryParams
      })
    },
    [manager]
  )

  const confirmMail = useCallback(async () => {
    if (!search) {
      redirectToHomePage()
      return
    }

    const confirmed = await completeSearchFirmVerification(search)

    if (confirmed) {
      redirectToLoginPage(true)
    } else {
      if (isLoggedIn) {
        redirectToHomePage()
      } else {
        redirectToLoginPage()
      }
    }
  }, [search, redirectToLoginPage, redirectToHomePage, isLoggedIn])

  React.useEffect(() => {
    confirmMail()
  }, [confirmMail])

  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Loader>{messages.verifySearchFirm.loading}</Loader>
    </Flex>
  )
}
