import React, { useEffect, useState } from "react"
import { Loader } from "components/Loader/Loader"
import { RouterButton, RouterLink } from "components/RouterLink/RouterLink"
import {
  Div,
  Flex,
  Section,
  LargeText,
  SmallText,
  PlusIcon
} from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { useActiveAssignments } from "./hooks"
import AssignmentLayout from "./components/Assignment"
import { useModal } from "utils/hooks/use-modal"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { useTalentisUser } from "setup/auth/module/auth.hooks"
import { TalentisUser } from "setup/auth/module/constants/auth.types"

const ActiveAssignments = () => {
  const { isLoading, hasAssignments, activeAssignments, requestSent } =
    useActiveAssignments()
  const { isOpen } = useModal()
  const { trackOnBoardingAssignmentFormShown } = useTelemetry()
  const { getTalentisUser } = useTalentisUser()
  const [talentisUserData, setTalentisUserData] = useState<TalentisUser | null>(
    null
  )
  useEffect(() => {
    getTalentisUser().then((dataLoc) => {
      setTalentisUserData(dataLoc)
    })
  }, [getTalentisUser])

  useEffect(() => {
    if (
      requestSent &&
      !isLoading &&
      !hasAssignments &&
      !isOpen &&
      talentisUserData?.passedInitialLoginForSearchFirm
    ) {
      trackOnBoardingAssignmentFormShown()
    }
  }, [
    isLoading,
    hasAssignments,
    requestSent,
    isOpen,
    trackOnBoardingAssignmentFormShown,
    talentisUserData
  ])

  return (
    <Section
      size="xSmall"
      hideHeader={isLoading && activeAssignments.length === 0}
      header={{
        title: messages.homepage.activeAssignments,
        actions: (
          <RouterButton
            to={RouterUrl.AssignmentCreate}
            mode="standard-green"
            size="action-medium"
          >
            <PlusIcon fill="currentColor" />
          </RouterButton>
        )
      }}
    >
      <Flex justifyContent="center" alignItems="center" width="100%" pt="xs">
        {isLoading ? (
          <Loader />
        ) : (
          <Flex
            width="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {activeAssignments.length > 0 ? (
              activeAssignments.map((assignment) => (
                <Div key={assignment.id} width="100%" mb="xs">
                  <AssignmentLayout {...assignment} />
                </Div>
              ))
            ) : (
              <>
                <LargeText my="none" color="grey.standard">
                  {messages.homepage.noActiveAssignments}
                </LargeText>
                <RouterButton to={RouterUrl.AssignmentCreate} mt="xs">
                  {messages.homepage.addAssignment}
                </RouterButton>
              </>
            )}
            {hasAssignments && (
              <RouterLink to={RouterUrl.AssignmentList} mt="-5px">
                <SmallText fontWeight={700} my="none">
                  {messages.homepage.viewAllAssignments}
                </SmallText>
              </RouterLink>
            )}
          </Flex>
        )}
      </Flex>
    </Section>
  )
}

export default React.memo(ActiveAssignments)
