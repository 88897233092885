import React, { useEffect } from "react"
import { Form, Formik, useFormikContext } from "formik"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import { messages } from "setup/messages/messages"
import { externalLinks } from "setup/externalLinks"
import { Button } from "@ikiru/talentis-fpc"
import { Link } from "@ikiru/talentis-fpc"
import { Flex } from "@ikiru/talentis-fpc"
import { H5, SmallText } from "@ikiru/talentis-fpc"
import { PromoCode } from "views/subscription/components/billing-card-details/components/PromoCode/PromoCode"
import { ChargebeeCardDetails } from "views/subscription/components/billing-card-details/components/ChargebeeCardDetails/ChargebeeCardDetails"
import {
  CardDetailsFields,
  CardDetailsType,
  initialCardDetailsValues
} from "./CardDetailsForm.definition"
import {
  cardDetailsValidationSchema,
  disabledCardDetailsValidationSchema
} from "./CardDetailsForm.schema"
import { Input } from "@ikiru/talentis-fpc"
import { BillingE2eTargets } from "views/subscription/components/billing-address/components/BillingAddressForm/BillingAddressForm.definitions"
import ChargebeeComponents from "@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup"

const AutoResetForm = (props: { disableForm: boolean }) => {
  const { resetForm } = useFormikContext()
  useEffect(() => {
    if (props.disableForm) {
      resetForm()
    }
  }, [props.disableForm, resetForm])
  return null
}

interface CardDetailsFormProps {
  chargebeeCardComponentRef: React.MutableRefObject<ChargebeeComponents>
  onSubmit: (values: CardDetailsType) => void
  disableForm: boolean
}

export const CardDetailsForm = (props: CardDetailsFormProps) => {
  const { onSubmit, chargebeeCardComponentRef, disableForm = false } = props
  const formikContextValue = {
    initialValues: initialCardDetailsValues,
    validationSchema: disableForm
      ? disabledCardDetailsValidationSchema
      : cardDetailsValidationSchema,
    onSubmit,
    isInitialValid: false
  }

  return (
    <Formik {...formikContextValue}>
      {({ isValid }) => (
        <Form>
          <H5 color="grey.dark" mt="xs">
            {messages.subscription.paymentDetails.cardDetails}
          </H5>

          <FormikInput
            id={CardDetailsFields.Name}
            name={CardDetailsFields.Name}
            disabled={disableForm}
            label={messages.subscription.paymentDetails.nameOnCard}
            data-e2e-target={BillingE2eTargets.nameOnCard}
          />

          {disableForm ? (
            <>
              <Input
                label={messages.subscription.paymentDetails.cardNumber}
                disabled={disableForm}
                data-e2e-target={BillingE2eTargets.disabledCardNumber}
              />
              <Input
                label={messages.subscription.paymentDetails.expiryDate}
                disabled={disableForm}
                data-e2e-target={BillingE2eTargets.disabledExpiryDate}
              />
              <Input
                label={messages.subscription.paymentDetails.securityCode}
                disabled={disableForm}
                data-e2e-target={BillingE2eTargets.disabledSecurityCode}
              />
            </>
          ) : (
            <ChargebeeCardDetails
              cardComponentRef={chargebeeCardComponentRef}
            />
          )}
          <PromoCode mt="m" />
          <Flex>
            <FormikToggle
              id={CardDetailsFields.TermsAndConditionsAccepted}
              name={CardDetailsFields.TermsAndConditionsAccepted}
              type="checkbox"
              hideUnderline={true}
              label={
                <SmallText color="grey.dark">
                  {messages.subscription.paymentDetails.agreeTo}{" "}
                  <Link
                    size="small"
                    href={externalLinks.termsAndConditions}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {messages.subscription.paymentDetails.termsAndConditions}
                  </Link>
                </SmallText>
              }
            />
          </Flex>

          <Button type="submit" mt="m" disabled={!isValid}>
            {disableForm
              ? messages.subscription.purchase.confirmPlan
              : messages.subscription.paymentDetails.purchase}
          </Button>
          <AutoResetForm disableForm={disableForm} />
        </Form>
      )}
    </Formik>
  )
}
