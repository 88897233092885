export enum PersonCampaigns {
  Campaigns = "campaigns"
}

export type PersonCampaignsValues = {
  [PersonCampaigns.Campaigns]: string
}

const fieldTargets = {
  campaignsSelect: "campaigns-select"
}

const elementTargets = {
  submitButton: "add-campaign",
  campaign: "campaign",
  campaigns: "campaigns"
}

export const personCampaignsE2ETargets = {
  ...fieldTargets,
  ...elementTargets
}

export const getPersonCampaignsInitialValues = (id?: string) => ({
  [PersonCampaigns.Campaigns]: id || ""
})
