import styled from "styled-components"
import { Div, Flex, spacing } from "@ikiru/talentis-fpc"

export const SearchDropdown = styled(Div)`
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.white.standard};
  border: 1px solid ${({ theme }) => theme.colors.grey.light};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
`

export const SearchDropdownItem = styled(Flex)`
  padding: ${spacing.xs}px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.light};
  }
`
export const StyledDiv = styled(Div)`
  width: 36px;
  height: 36px;
  margin-right: ${spacing.xxs}px;
  &:first-child {
    min-width: 36px;
    min-height: 36px;
  }
`