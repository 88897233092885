import { messages } from "setup/messages/messages"
import { Assignment } from "views/assignments/assignment.types"
import currencyCodesData from "currency-codes"
import { AzureLocationInitialValue } from "components/AzureLocation/definition"

const mainCurrency = ["USD", "EUR", "GBP", "AUD", "SGD", "JPY"]

export const defaultCurrency = "USD"

export enum AssignmentDataField {
  Name = "name",
  StartDate = "startDate",
  Status = "status",
  CompanyName = "companyName",
  JobTitle = "jobTitle",
  CompanyId = "companyId",
  EndDate = "endDate",
  Brief = "brief",
  Location = "location",
  GeoLocation = "geoLocation",
  TeamMembers = "teamMembers"
}

export type AssignmentPrimaryData = Pick<
  Assignment,
  | "name"
  | "startDate"
  | "status"
  | "companyName"
  | "jobTitle"
  | "companyId"
  | "endDate"
  | "brief"
  | "location"
  | "geoLocation"
  | "teamMembers"
>

export type AssignmentPackageData = Pick<
  Assignment,
  "currency" | "salaryFrom" | "salaryTo" | "packageComment"
>

export enum AssignmentPackageDataField {
  Currency = "currency",
  SalaryFrom = "salaryFrom",
  SalaryTo = "salaryTo",
  PackageComment = "packageComment"
}

export const assignmentPackageDataInitialValues: AssignmentPackageData = {
  [AssignmentPackageDataField.Currency]: "",
  [AssignmentPackageDataField.SalaryFrom]: 0,
  [AssignmentPackageDataField.SalaryTo]: 0,
  [AssignmentPackageDataField.PackageComment]: ""
}

export type AssignmentFeeData = Pick<
  Assignment,
  "feeCurrency" | "feeFrom" | "feeTo" | "finalFee" | "feeComment"
>

export enum AssignmentFeeDataField {
  FeeCurrency = "feeCurrency",
  FeeFrom = "feeFrom",
  FeeTo = "feeTo",
  FinalFee = "finalFee",
  FeeComment = "feeComment"
}

export const assignmentFeeDataInitialValues: AssignmentFeeData = {
  [AssignmentFeeDataField.FeeCurrency]: "",
  [AssignmentFeeDataField.FeeFrom]: 0,
  [AssignmentFeeDataField.FeeTo]: 0,
  [AssignmentFeeDataField.FinalFee]: 0,
  [AssignmentFeeDataField.FeeComment]: ""
}

export type AssignmentStatusOption = {
  label: string
  value: AssignmentStatus
}

export enum AssignmentStatus {
  Lead = "lead",
  Opportunity = "opportunity",
  Active = "active",
  OnHold = "onHold",
  Abandoned = "abandoned",
  Placed = "placed"
}

export const assignmentPrimaryDataInitialValues: Omit<
  AssignmentPrimaryData,
  "teamMembers"
> = {
  [AssignmentDataField.Name]: "",
  [AssignmentDataField.Status]: AssignmentStatus.Active,
  [AssignmentDataField.StartDate]: new Date(),
  [AssignmentDataField.CompanyName]: "",
  [AssignmentDataField.JobTitle]: "",
  [AssignmentDataField.CompanyId]: "",
  [AssignmentDataField.EndDate]: new Date(),
  [AssignmentDataField.Brief]: "",
  [AssignmentDataField.Location]: "",
  [AssignmentDataField.GeoLocation]: AzureLocationInitialValue
}

const lead: AssignmentStatusOption = {
  value: AssignmentStatus.Lead,
  label: messages.assignment.statuses.lead
}

const opportunity: AssignmentStatusOption = {
  value: AssignmentStatus.Opportunity,
  label: messages.assignment.statuses.opportunity
}

const active: AssignmentStatusOption = {
  value: AssignmentStatus.Active,
  label: messages.assignment.statuses.active
}

const onHold: AssignmentStatusOption = {
  value: AssignmentStatus.OnHold,
  label: messages.assignment.statuses.onHold
}

const abandoned: AssignmentStatusOption = {
  value: AssignmentStatus.Abandoned,
  label: messages.assignment.statuses.abandoned
}

const placed: AssignmentStatusOption = {
  value: AssignmentStatus.Placed,
  label: messages.assignment.statuses.placed
}

export const assignmentStatuses = [
  lead,
  opportunity,
  active,
  onHold,
  abandoned,
  placed
]

export const e2eTargets = {
  primaryDetails: "primary-details",
  name: "name",
  startDate: "start-date",
  status: "status",
  jobTitle: "job-title",
  companyName: "company-name",
  companyId: "company-id",
  endDate: "start-date"
}

export const currenciesOptions = () => {
  const currencyCodes = currencyCodesData.data.map((currency) => currency.code)
  const tempOptions = [
    ...mainCurrency,
    ...currencyCodes.filter((currency) => !mainCurrency.includes(currency))
  ]

  return tempOptions.map((currency) => ({
    value: currency,
    label: currency
  }))
}
