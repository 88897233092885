import React from "react"
import { FormikArrayField } from "components/functional/formik/formik-array-field/FormikArrayField"
import { Form, Formik, FormikHelpers } from "formik"
import { messages } from "setup/messages/messages"
import { getChangedValuesWithArray } from "views/assignments/utils"
import { OnPersonDetailsSubmitType } from "views/persons/components/person-details/form/actions"
import {
  CreatePersonField,
  CreatePersonValues,
  createPersonInitialValues
} from "views/persons/components/person-details/form/constants/definitions"
import { ModalFormContainer } from "../../person-details-section/form/style"
import FormActionButtons from "../../FormActionButtons"
import { Div } from "@ikiru/talentis-fpc"
import { EditableWebsiteSectionButton } from "../components/EditableWebsiteSectionButton"
import { e2eTargets } from "../../../constant/definitions"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { DefaultEditedField } from "components/EditableDetails/editable-details.types"
import { personWebSiteValidationSchema } from "./constant/schema"

type PersonWebLinkEditProps = {
  initialValues: Partial<CreatePersonValues>
  onSubmit: OnPersonDetailsSubmitType
  defaultEditedField?: DefaultEditedField
  buttonRefs: {
    [key in "addWebsiteButtonRef"]: React.MutableRefObject<HTMLButtonElement>
  }
}

const PersonWebLinkEdit = ({
  initialValues,
  onSubmit,
  buttonRefs,
  defaultEditedField
}: PersonWebLinkEditProps) => {
  const submitForm = (
    values: Partial<CreatePersonValues>,
    action: FormikHelpers<CreatePersonValues>
  ) => {
    const trimmedValues = {
      ...(values.webSites &&
        values.webSites.length > 0 && { webSites: values.webSites }),
      ...(values.linkedInProfileUrl && {
        linkedInProfileUrl: values.linkedInProfileUrl
      })
    }
    const changeValues = initialValues
      ? getChangedValuesWithArray(trimmedValues, initialValues)
      : trimmedValues

    onSubmit(changeValues, action)
  }

  const { ...otherButtonRefs } = buttonRefs

  return (
    <Div>
      <Formik
        initialValues={{ ...createPersonInitialValues, ...initialValues }}
        onSubmit={submitForm}
        validationSchema={personWebSiteValidationSchema}
      >
        {(formik) => {
          const { values } = formik
          return (
            <Div>
              <ModalFormContainer defaultWidth={755}>
                <Form>
                  <FormActionButtons
                    title={
                      messages.person.personTabSection.form.webLinksEditMode
                    }
                  />
                  <FormikInput
                    autoFocus={
                      defaultEditedField === CreatePersonField.LinkedInUrl
                    }
                    id={CreatePersonField.LinkedInUrl}
                    name={`${CreatePersonField.LinkedInUrl}.value`}
                    label={messages.form.generic.linkedInUrl}
                    disabled={values?.linkedInProfileUrl?.isReadOnly}
                  />
                  <FormikArrayField
                    autoFocusFirstElement={
                      defaultEditedField === CreatePersonField.WebSites
                    }
                    id={CreatePersonField.WebSites}
                    name={CreatePersonField.WebSites}
                    e2eTarget={e2eTargets.webSites}
                    label={messages.form.generic.webSite}
                    displayAddMoreBtn={false}
                    displayActionBtn={false}
                    nestedKey="value.url"
                    initialValue={{ value: { url: "" }, isReadOnly: false }}
                    ComponentProps={(name, index) => {
                      return {
                        disabled:
                          values?.[name as CreatePersonField.WebSites]?.[index]
                            ?.isReadOnly
                      }
                    }}
                  />
                </Form>
              </ModalFormContainer>
              <EditableWebsiteSectionButton
                innerRefs={otherButtonRefs}
                formikProps={formik}
              />
            </Div>
          )
        }}
      </Formik>
    </Div>
  )
}

export default PersonWebLinkEdit
