import React from "react"
import {
  Loader as FPCLoader,
  LoaderProps as FPCLoaderProps
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { Flex } from "@ikiru/talentis-fpc"

type LoaderProps = Omit<FPCLoaderProps, "children"> & { children?: never }

export const Loader = (props: LoaderProps) => {
  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <FPCLoader {...props}>{messages.generic.loading}</FPCLoader>
    </Flex>
  )
}
