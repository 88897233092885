import styled from "styled-components"

export const List = styled.ul`
  display: inline-block;
  list-style-type: none;
  padding-left: 0;
`

export const LiStyled = styled.li`
    text-align: left;
    padding: 0 0 10px 10px;
    ::before {
      content: "\\1F449";
      font-size: 25px;
      margin-right: 10px;
      position: relative;
      bottom: -5px;
  }
`
