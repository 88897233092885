import React from "react"
import {
  Link,
  Flex,
  BodyText,
  PolygonIcon,
  H6,
  PlusIcon
} from "@ikiru/talentis-fpc"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"
import { PersonValue } from "models/LocalPerson/localPerson.definitions"
import { TaggedEmail } from "views/persons/person.types"
import { personProfileDetailsE2ETargets as e2eTargets } from "../../definitions"
import { messages } from "setup/messages/messages"
import { StyledPopupTrigger } from "../style"
import ItemComponent from "../ItemComponent"
import { onToggleIsPreffered } from "../actions"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { getNormalizedPersonProfileDetails } from "../../utils/get-person-details"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import UsageIcon from "../UsageIcon"
import {
  EmailTypeEnums,
  emailTypeOptions
} from "../../../person-details-tab/components/person-contact-info/form/constants/definitions"

type Option = {
  label: string | JSX.Element
  onClick?: () => void
}

type PersonEmailsProps = {
  personId: string
  taggedEmails: PersonValue<TaggedEmail>[]
  editPersonEmail: (data: boolean) => void
}

export const PersonEmails = ({
  personId,
  taggedEmails,
  editPersonEmail
}: PersonEmailsProps) => {
  const { setProfileDetails } = usePerson()

  const onToggle = async (oldEmailIndex: number) => {
    const toggledEmailValue = taggedEmails.map((emailData, index) => {
      if (oldEmailIndex === index) {
        emailData.value.isPreferred = !emailData.value.isPreferred
      }
      return emailData.value
    })

    const data = await onToggleIsPreffered(
      { taggedEmails: toggledEmailValue },
      personId
    )
    const mergedPersonData = new LocalPersonModel(data)

    const profileDetails = getNormalizedPersonProfileDetails(mergedPersonData)

    setProfileDetails(profileDetails)
  }

  const options: Option[] = [
    ...taggedEmails.map((email, index) => {
      const usageLabel = emailTypeOptions.find(
        (emailType) => emailType.value === email.value.type
      )?.label
      return {
        label: (
          <ItemComponent
            isPreferred={email.value.isPreferred}
            onToggle={onToggle}
            index={index}
            usageType={email.value.type as EmailTypeEnums}
            usageLabel={usageLabel as string}
            itemValue={email.value.email}
            isItemEmail
            isEmail
          />
        ),
        disableClose: true
      }
    }),
    {
      label: (
        <Link size="small" href="#" standalone m="0">
          <PlusIcon height="12px" />
          {messages.form.generic.addNewEmail}
        </Link>
      ),
      onClick: async () => {
        editPersonEmail(true)
      }
    }
  ]
  return (
    <Flex alignItems="center" mb="0">
      <UsageIcon
        isEmail
        usageType={taggedEmails[0].value.type as EmailTypeEnums}
      />
      <BodyText m="0" ml="4px" mr="5px">
        <Link
          ml="none"
          data-e2e-target-name={`${e2eTargets.taggedEmails}-${0}`}
          href={`mailto:${taggedEmails?.[0].value?.email}`}
        >
          {taggedEmails?.[0].value?.email}
        </Link>
      </BodyText>
      {taggedEmails.length > 0 && (
        <OptionsPopup
          alignSelf="center"
          display="flex"
          config={{
            PopupProps: {
              position: "bottom right"
            }
          }}
          trigger={
            <StyledPopupTrigger>
              {taggedEmails.length > 1 && (
                <H6 m="none" mr="2px">
                  {`+${taggedEmails.length - 1}`}
                </H6>
              )}
              <PolygonIcon />
            </StyledPopupTrigger>
          }
          options={options}
        />
      )}
    </Flex>
  )
}
