import React, { useCallback } from "react"
import { colors, Div, Flex, H5, Loader } from "@ikiru/talentis-fpc"
import { PersonWrapper, StyledFlex } from "./styles"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import {
  getLocalContactLookupResult,
  getLocalSearchResult
} from "views/internal-search/actions"
import { Pagination } from "components/functional/pagination/Pagination"
import { InternalPersonType } from "views/internal-search/InternalSearchModule/types"
import PersonRecord from "./PersonRecord"
import { PersonProfileWrapper } from "views/persons/pages/manage-person/PersonProfileWrapper"
import InternalSearchPersonOverlay from "views/persons/components/person-overlay/InternalSearchPersonOverlay"
import { BuildSearch } from "../BuildSearch/BuildSearch"
import { NoResults } from "./NoResults"
import { messages } from "setup/messages/messages"
import ProjectSelector from "components/ProjectSelector"

const InternalSearchResults = () => {
  const {
    isLoadingSearchResults,
    internalPersons,
    internalSearchPage,
    setInternalPersons,
    setInternalSearchPage,
    filters,
    isLookup,
    lookupSearchString,
    selectedInternalSearchPersonId,
    setIsLoadingSearchResults,
    fetchAssignments,
    fetchCampaigns,
    selectedAssignment,
    selectedCampaign,
    activeAssignments,
    activeCampaigns,
    setIsAssignmentSelect,
    setSelectedAssignment,
    setSelectedCampaign,
    isAssignmentSelect,
    isLoading
  } = useInternalSearch()

  const handlePageChange = useCallback(
    async (page: number) => {
      setIsLoadingSearchResults(true)

      setInternalSearchPage({ ...internalSearchPage, pageNumber: page })
      let { pages, personsWithCampaignIdsAssignmentIds } = isLookup
        ? await getLocalContactLookupResult(lookupSearchString, page)
        : await getLocalSearchResult(filters, page)

      setInternalSearchPage(pages)

      setInternalPersons(personsWithCampaignIdsAssignmentIds)
    },

    [
      filters,
      internalSearchPage,
      lookupSearchString,
      isLookup,
      setInternalPersons,
      setInternalSearchPage,
      setIsLoadingSearchResults
    ]
  )

  return (
    <>
      <BuildSearch />
      {isLoadingSearchResults ? (
        <Div mt="l">
          <Loader />
        </Div>
      ) : internalPersons.length === 0 ? (
        <NoResults />
      ) : (
        <>
          <StyledFlex>
            <H5 m="0" color={colors.grey.dark} ml="xxs">
              {messages.internalSearch.foundResults.format(
                internalSearchPage.totalItemCount
              )}
            </H5>

            <Div>
              <ProjectSelector
                fetchAssignments={fetchAssignments}
                fetchCampaigns={fetchCampaigns}
                setIsAssignmentSelect={setIsAssignmentSelect}
                isAssignmentSelect={isAssignmentSelect}
                isLoading={isLoading}
                activeAssignments={activeAssignments}
                selectedAssignment={selectedAssignment}
                setSelectedAssignment={setSelectedAssignment}
                activeCampaigns={activeCampaigns}
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={setSelectedCampaign}
              />
            </Div>
          </StyledFlex>

          <Div backgroundColor={colors.grey.lightest} p="xs">
            {internalPersons?.map(
              (person: InternalPersonType, index: number) => (
                <PersonWrapper key={index}>
                  <PersonRecord person={person} />
                </PersonWrapper>
              )
            )}

            <Flex width="100%" flexDirection="column">
              {internalSearchPage.pageCount > 1 && (
                <Flex width="100%" justifyContent="center" mb={20}>
                  <Pagination
                    {...internalSearchPage}
                    onPageChange={handlePageChange}
                  />
                </Flex>
              )}
            </Flex>
          </Div>
        </>
      )}
      {selectedInternalSearchPersonId && (
        <PersonProfileWrapper>
          <InternalSearchPersonOverlay handlePageChange={handlePageChange} />
        </PersonProfileWrapper>
      )}
    </>
  )
}

export default InternalSearchResults
