import { H1, BodyText } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const FourZeroFourMessage = styled(H1)`
  margin-top: 300px;
  font-size: 250px;
  color: ${({ theme }) => theme.colors.green.standard};
  margin-right: 50px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 210px;
  }

  @media (max-width: 468px) {
    margin-right: 0px;
    font-size: 150px;
  }
`

export const ErrorMessage = styled(BodyText)`
  margin-top: 100px;
  font-size: 90px;
  color: ${({ theme }) => theme.colors.grey.light};

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 468px) {
    font-size: 30px;
  }
`
