import React from "react"
import { messages } from "setup/messages/messages"
import { StagesDetails } from "./StagesDetails"
import { Flex } from "@ikiru/talentis-fpc"
import { CompanyCandidateStages } from "views/assignments/assignment.types"

type CompanyCandidateStagesProps = {
  candidateStages: CompanyCandidateStages
}

export const CompanyCandidateStage = ({
  candidateStages
}: CompanyCandidateStagesProps) => {
  const clientInterviewCount =
    (candidateStages.firstClientInterview || 0) +
    (candidateStages.secondClientInterview || 0) +
    (candidateStages.thirdClientInterview || 0)

  const candidateStagesDetails = [
    {
      name: messages.assignment.interviewStage.identified,
      count: candidateStages.identified
    },
    {
      name: messages.assignment.interviewStage.clientInterview,
      count: clientInterviewCount
    },
    {
      name: messages.talentGraphSearch.candidateDetails.placed,
      count: candidateStages.placed
    },
    {
      name: messages.assignment.interviewStage.internalInterview,
      count: candidateStages.internalInterview
    },
    {
      name: messages.assignment.interviewStage.offer,
      count: candidateStages.offer
    },
    {
      name: messages.assignment.interviewStage.screening,
      count: candidateStages.screening
    },
    {
      name: messages.assignment.interviewStage.shortList,
      count: candidateStages.shortList
    },
    {
      name: messages.assignment.interviewStage.source,
      count: candidateStages.source
    }
  ].filter((item) => item.count > 0)

  return (
    <Flex justifyContent="stretch" flexWrap="wrap" ml="s">
      {candidateStagesDetails.map(({ name, count }) => (
        <StagesDetails key={name} name={name} count={count} />
      ))}
    </Flex>
  )
}
