import { CVContactInfo, CVPersonDetails } from "views/persons/person.types"

const emptyData = {
  existing: {},
  parsed: {
    text: "",
    selectedValue: "",
    isPreferred: false
  },
  isChecked: false,
  isDeleted: false
}

export const getNormalizedCVPersonDetails = (
  data: any,
  profileDetails: any,
  isEmptyProfile: boolean
) => {
  let cvPersonDetails: CVPersonDetails = {
    name: {},
    title: {},
    firstName: {},
    lastName: {},
    middleName: {},
    knownAs: {},
    nationality: {},
    dateOfBirth: {},
    bio: {},
    addressLine1: {},
    addressLine2: {},
    addressLine3: {},
    addressLine4: {},
    city: {},
    country: {},
    postcode: {},
    location: {}
  }

  const excludedKeys = ["firstName", "name", "lastName"]

  Object.keys(cvPersonDetails).forEach((key) => {
    cvPersonDetails[key] = {
      existing:
        profileDetails[key]?.value || profileDetails[key]?.value === ""
          ? profileDetails[key].value
          : profileDetails[key],
      parsed: data[key],
      isChecked: isEmptyProfile && !excludedKeys.includes(key)
    }
  })

  let cvContactDetails: CVContactInfo = {
    phones: [],
    webSites: [],
    linkedInProfileUrl: {},
    taggedEmails: []
  }

  // still thinking how can refactor this code
  profileDetails.taggedEmails.forEach((em: any) => {
    const { email, type, smtpValid, isPreferred } = em.value
    cvContactDetails.taggedEmails.push({
      existing: {
        text: email,
        selectedValue: type,
        isPreferred: isPreferred,
        smtpValid: smtpValid
      },
      parsed: {},
      isChecked: isEmptyProfile,
      isDeleted: false
    })
  })

  profileDetails.phones.forEach((em: any) => {
    const { phoneNumber, phoneUsageType, isPreferred, phoneDeviceType } =
      em.value
    if (phoneNumber) {
      cvContactDetails.phones.push({
        existing: {
          text: phoneNumber,
          selectedValue: phoneUsageType,
          isPreferred: isPreferred,
          phoneDeviceType: phoneDeviceType
        },
        parsed: {},
        isChecked: isEmptyProfile,
        isDeleted: false
      })
    }
  })

  profileDetails.webSites.forEach((em: any) => {
    const { url, type, dpWebsite } = em.value
    cvContactDetails.webSites.push({
      existing: { text: url, selectedValue: type, isPreferred: dpWebsite },
      parsed: {},
      isChecked: isEmptyProfile,
      isDeleted: false
    })
  })

  data.phones.forEach((em: any) => {
    const { phoneNumber, phoneUsageType, isPreferred, phoneDeviceType } = em
    cvContactDetails.phones.push({
      existing: {},
      parsed: {
        text: phoneNumber,
        selectedValue: phoneUsageType,
        isPreferred: isPreferred,
        phoneDeviceType: phoneDeviceType
      },
      isChecked: isEmptyProfile,
      isDeleted: false
    })
  })

  data.taggedEmails.forEach((em: any) => {
    const { email, type, smtpValid, isPreferred } = em
    cvContactDetails.taggedEmails.push({
      existing: {},
      parsed: {
        text: email,
        selectedValue: type,
        isPreferred: isPreferred,
        smtpValid: smtpValid
      },
      isChecked: isEmptyProfile,
      isDeleted: false
    })
  })

  cvContactDetails.linkedInProfileUrl = {
    existing: profileDetails.linkedInProfileUrl.value,
    parsed: data.linkedInProfileUrl,
    isChecked: false
  }

  data.webSites.forEach((em: any) => {
    const { url, type, dpWebsite } = em
    cvContactDetails.webSites.push({
      existing: {},
      parsed: { text: url, selectedValue: type, isPreferred: dpWebsite },
      isChecked: isEmptyProfile,
      isDeleted: false
    })
  })

  if (!Boolean(cvContactDetails.phones.length)) {
    cvContactDetails.phones.push(emptyData)
  }

  if (!Boolean(cvContactDetails.webSites.length)) {
    cvContactDetails.webSites.push(emptyData)
  }

  if (!Boolean(cvContactDetails.taggedEmails.length)) {
    cvContactDetails.taggedEmails.push(emptyData)
  }

  return { cvPersonDetails, cvContactDetails }
}
