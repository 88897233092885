import React, { useCallback, useMemo } from "react"
import { Toggle } from "@ikiru/talentis-fpc"
import { H6 } from "@ikiru/talentis-fpc"
import { CandidateFilter } from "views/assignments/assignment-module/assignment-module.types"
import { StyledSpan } from "../../style"
interface CandidateFilterProps {
  filter: CandidateFilter
  filterIsApplied: (candidateFilter: CandidateFilter) => boolean
  addFilter: (candidateFilter: CandidateFilter) => void
  removeFilter: (candidateFilter: CandidateFilter) => void
}

export const CandidateFilterToggle = (props: CandidateFilterProps) => {
  const { filter, filterIsApplied, addFilter, removeFilter } = props
  const { disabled, span } = filter

  const checked = useMemo(
    () => !disabled && filterIsApplied(filter),
    [filterIsApplied, filter, disabled]
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        addFilter(filter)
      } else {
        removeFilter(filter)
      }
    },
    [removeFilter, addFilter, filter]
  )

  return (
    <Toggle
      hideUnderline
      small
      type="checkbox"
      label={
        <H6 m="0" color="grey.dark">
          {span && <StyledSpan background={span} />}
          {filter.label}
        </H6>
      }
      value={JSON.stringify(filter.value)}
      checked={checked}
      onChange={onChange}
      {...{ disabled }}
    />
  )
}
