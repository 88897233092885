import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import AITemplates from "components/ProjectActions/AITemplates/AiTemplates"
import useCampaignTemplates from "./hooks/useCampaignTemplate"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { messages } from "setup/messages/messages"

const CampaignTemplate = () => {
  const { setCampaignAITemplates, campaignAITemplates } = useCampaign()
  const { generateAITemplates, updateAiTemplate, isLoading } =
    useCampaignTemplates()

  return (
    <Div>
      <AITemplates
        onGenerateTemplate={generateAITemplates}
        setTemplateValues={setCampaignAITemplates}
        templateValues={campaignAITemplates}
        updateTemplate={updateAiTemplate}
        isLoading={isLoading}
        defaultSearchPlaceHolder={
          messages.project.outReach.coreMessageCampaignPH
        }
      />
    </Div>
  )
}

export default CampaignTemplate
