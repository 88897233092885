import { UserRoles } from "setup/auth/module/constants/auth.types"

export enum InviteUserField {
  Email = "userEmailAddress",
  Role = "userRole"
}

export const inviteUserInitialValues = {
  [InviteUserField.Email]: "",
  [InviteUserField.Role]: UserRoles.TeamMember
}

export enum TestIds {
  SuccessMsg = "inviteUserSuccessMsg"
}
