import { GridBox } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const CampaignBox = styled.div`
  text-decoration: none;
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto;
  position: relative;
  width: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "details"
    "stage";

  ${({ theme }) => `
    ${theme.mediaQueries.xs} {
        grid-template-rows: max-content;
        grid-template-columns: 30% 1fr;
        grid-template-areas: "details stage";
    }
  `}
`

export const CampaignDetailsBox = styled(GridBox)`
  min-width: 150px;
  max-width: 200px;
  grid-area: details;
  margin: 0px 0px 15px 10px;
`
