import React, { useCallback, useMemo, useEffect, useState } from "react"
import { isEmpty, noop } from "lodash"
import { useIntl } from "react-intl"
import { Formik, Form } from "formik"
import { messages } from "setup/messages/messages"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { useSearch } from "views/search/SearchModule/context"
import {
  Button,
  Div,
  H4,
  Flex,
  SectionHeader,
  getMinWidthMediaQuery,
  Link,
  SmallText
} from "@ikiru/talentis-fpc"
import {
  searchCriteriaValidationSchema,
  initialValues
} from "./Filters/definitions"
import FilterWrapper from "./FilterWrapper"
import SearchCriteriaActions from "./Actions"
import { IndustryFilter } from "./Filters/Industry"
import { JobTitleFilter } from "./Filters/JobTitle"
import { KeywordsFilter } from "./Filters/Keywords"
import { LocationFilter } from "./Filters/Location"
import { BoardLevelExperienceFilter } from "./Filters/BoardLevelExperience"
// import { LikelyToMoveFilter } from "./Filters/LikelyToMove"
import { CompanyFilter } from "./Filters/Company"
import { CompanySizeFilter } from "./Filters/CompanySize"
import { ExcludeKeywordsFilter } from "./Filters/ExcludeKeywords/ExcludeKeywordsBox"
import { BoostFemaleFilter } from "./Filters/BoostFemale"
import { CommonExperienceFilter } from "./Filters/CommonExperience"
import { Filters, FilterType } from "../../SearchModule/types"
import { SearchUpHeader } from "../../SearchModule/style"
import { SaveSearches } from "../SaveSeaches"
import { SearchButton } from "./style"
import {
  getFiltersFromSessionStorage,
  scrollToTop
} from "../../SearchModule/utils"
import { Nullable } from "tsdef"
import { isEqual } from "lodash"

const SearchCriteria = () => {
  const { formatNumber } = useIntl()

  const {
    isCriteriaNotDefined,
    areFiltersExpanded,
    setFiltersExpanded,
    totalItems,
    searchTalentGraph,
    getPreparedFilters,
    loadPreviousSearch,
    onPageChange,
    filters,
    isLoading,
    persons
  } = useSearch()

  const [currentSearchFilters, setCurrentSearchFilters] =
    useState<Nullable<Partial<Filters>>>()
  const [disableSearchButton, setDisableSearchButton] = useState<boolean>(false)

  useEffect(() => {
    const newfilters = getPreparedFilters()
    if (isEqual(newfilters, currentSearchFilters)) {
      setDisableSearchButton(true)
    } else {
      if (newfilters) setDisableSearchButton(false)
    }
  }, [currentSearchFilters, getPreparedFilters, filters])

  const mediaQuery = getMinWidthMediaQuery("sm")
  const isMobile = !useMediaQuery(mediaQuery)

  const toggleFilters = useCallback(() => {
    setFiltersExpanded(!areFiltersExpanded)
  }, [areFiltersExpanded, setFiltersExpanded])

  const isSearchIndicatorVisbile = useMemo(
    () =>
      areFiltersExpanded && !isCriteriaNotDefined && isMobile && totalItems > 0,
    [areFiltersExpanded, isCriteriaNotDefined, isMobile, totalItems]
  )

  const runSearch = () => {
    const filters = getPreparedFilters()
    setCurrentSearchFilters(filters)
    onPageChange(1)
    searchTalentGraph()
    scrollToTop()
  }

  const disableSearch = useMemo(() => {
    if (!persons?.length && Boolean(getPreparedFilters()) && !isLoading) {
      return false
    } else {
      return !getPreparedFilters() || (disableSearchButton && isLoading)
    }
  }, [disableSearchButton, isLoading, getPreparedFilters, persons])

  return (
    <Flex height="100%" flexDirection="column" width="100%">
      <Formik
        initialValues={initialValues}
        validationSchema={searchCriteriaValidationSchema}
        onSubmit={noop}
        validateOnBlur={false}
      >
        <Form
          style={{ height: "100%" }}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.keyCode === 13) {
              e.preventDefault()
              return false
            }
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter" || e.keyCode === 13) {
              e.preventDefault()
              return false
            }
          }}
        >
          <Div
            zIndex="4"
            {...(isMobile
              ? {
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#fff"
                }
              : {
                  position: "sticky",
                  top: "0"
                })}
          >
            <SearchUpHeader
              {...(isMobile ? { position: "relative" } : {})}
              py={"xs"}
              px={["m", "", "", "m"]}
            >
              <SearchCriteriaActions />
              <SearchButton
                mode="primary"
                size="small"
                onClick={runSearch}
                disabled={disableSearch}
              >
                {messages.search.runSearch}
              </SearchButton>
            </SearchUpHeader>
            {isMobile && (
              <>
                <SearchUpHeader
                  {...(isMobile ? { position: "relative" } : {})}
                  mt="xxs"
                  py={"xs"}
                  px={["m", "", "", "m"]}
                >
                  {!isEmpty(getFiltersFromSessionStorage()) ? (
                    <Link onClick={loadPreviousSearch} my="none">
                      <SmallText
                        fontWeight="600"
                        color="white.standard"
                        my="none"
                      >
                        {messages.talentGraphSearch.reloadSearch}
                      </SmallText>
                    </Link>
                  ) : (
                    <Div />
                  )}
                  <SaveSearches />
                </SearchUpHeader>
              </>
            )}
            {!isMobile && (
              <SectionHeader
                size="xSmall"
                title={messages.talentGraphSearch.searchCriteria}
                width="100%"
                px={["xs", "", "", "m"]}
              />
            )}
          </Div>
          <Flex flexDirection="column" width="100%" height="auto">
            {isMobile && (
              <SectionHeader
                height="50px"
                title={messages.talentGraphSearch.searchCriteria}
                width="100%"
                px={["xs", "", "", "m"]}
              />
            )}
            {isSearchIndicatorVisbile && (
              <Flex
                p="xs"
                mb="xxs"
                width="100%"
                bg="grey.lightest"
                alignItems="center"
                justifyContent="space-between"
              >
                <H4 my="none" color="grey.dark">
                  {messages.talentGraphSearch.foundResults.format(
                    formatNumber(totalItems || 0)
                  )}
                </H4>
                <Button
                  onClick={toggleFilters}
                  mode="standard-green"
                  size="small"
                >
                  {messages.talentGraphSearch.viewResults}
                </Button>
              </Flex>
            )}
          </Flex>
          {areFiltersExpanded && (
            <>
              <JobTitleFilter />
              <FilterWrapper>
                <CommonExperienceFilter
                  componentLabel={messages.talentGraphSearch.yearsInCurrentPos}
                  filterMaxName={FilterType.CurrentJobExperienceYearsMax}
                  filterMinName={FilterType.CurrentJobExperienceYearsMin}
                />
              </FilterWrapper>
              <FilterWrapper>
                <CommonExperienceFilter
                  componentLabel={messages.talentGraphSearch.yearsTotal}
                  filterMaxName={FilterType.TotalJobExperienceYearsMax}
                  filterMinName={FilterType.TotalJobExperienceYearsMin}
                />
              </FilterWrapper>
              <FilterWrapper>
                <LocationFilter />
              </FilterWrapper>
              <KeywordsFilter />
              <ExcludeKeywordsFilter />
              <FilterWrapper>
                <BoardLevelExperienceFilter />
              </FilterWrapper>
              {/* hide may be more search */}
              {/* <FilterWrapper>
                <LikelyToMoveFilter />
              </FilterWrapper> */}
              {/* hide may be more search */}
              <FilterWrapper>
                <BoostFemaleFilter />
              </FilterWrapper>
              <FilterWrapper>
                <IndustryFilter />
              </FilterWrapper>
              <FilterWrapper>
                <CompanyFilter />
              </FilterWrapper>
              <FilterWrapper>
                <CompanySizeFilter />
              </FilterWrapper>
            </>
          )}
        </Form>
      </Formik>
    </Flex>
  )
}

export default React.memo(SearchCriteria)
