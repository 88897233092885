import React, { useMemo } from "react"
import { Div, Tag } from "@ikiru/talentis-fpc"
import { Loader } from "components/Loader/Loader"
import { PersonState } from "views/persons/person-module/person-module.types"
import { Contact } from "views/campaigns/components/contacts/type"
import CampaignRecord from "./CampaignRecord"
import PersonNoDataScreen from "../../person-no-data-message/PersonNoDataScreen"
import { messages } from "setup/messages/messages"

type ListPersonContactsProps = {
  contacts: PersonState["contacts"]
  isLoading: boolean
}

export const ListPersonContacts = ({
  contacts,
  isLoading
}: ListPersonContactsProps) => {
  const contactsList = useMemo(() => Object.values(contacts), [contacts])

  return (
    <Div width="100%">
      {isLoading ? (
        <Tag>
          <Loader size="small" />
        </Tag>
      ) : (
        <>
          {Boolean(contactsList.length) ? (
            <Div mt="xs">
              {contactsList.map((contact: Contact) => (
                <CampaignRecord contact={contact} />
              ))}
            </Div>
          ) : (
            <PersonNoDataScreen
              message={messages.person.personTabSection.noDataTexts.noCampaign}
            />
          )}
        </>
      )}
    </Div>
  )
}
