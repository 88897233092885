import { createContext, useContext } from "react"
import { Nullable } from "tsdef"
import { GetFormAction } from "utils/forms/form.types"
import { Candidate } from "views/candidates/candidates.types"
import { PersonNote } from "views/persons/components/person-notes/types"
import { InterviewProgressValues } from "./components/InterviewProgress/definitions"

export type OnChangeInterviewProgress = GetFormAction<InterviewProgressValues>

export type CandidateContextValues = {
  candidate: Candidate
  isUpdating: boolean
  photoUrl: Nullable<string>
  onChangeInterviewProgress: OnChangeInterviewProgress
}

export const initialCandidateContextValues = {
  candidate: null!,
  isUpdating: false,
  photoUrl: null!,
  onChangeInterviewProgress: null!
}

export type SetNotes = (notes: PersonNote[]) => void

export const CandidateContext = createContext<CandidateContextValues>(
  initialCandidateContextValues
)

export const useCandidate = () => useContext(CandidateContext)
