import React, { useMemo, KeyboardEvent, FocusEvent } from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { sectionFieldSearchValuesTemplateString } from "./consts"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { get } from "lodash"
import { PreferredList } from "../PreferredList"
import { useArrayFieldInputChange } from "views/internal-search/hooks"

type InputFieldProps = {
  index: number
  indexSection: number
}

export const InputField = ({ index, indexSection }: InputFieldProps) => {
  const { updateFilters, filters, removeArrayBasedInternalFilter } =
    useInternalSearch()

  const fieldSearchValuesName = sectionFieldSearchValuesTemplateString.format(
    indexSection,
    index
  )

  const currentFieldSearchValues = get(filters, fieldSearchValuesName, [])

  const handleInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
    updateFilters({
      [fieldSearchValuesName]: [e.currentTarget.value]
    })
  }

  const handleInputsChange = (
    e: React.FocusEvent<HTMLInputElement>,
    type: string
  ) => {
    const updatedValues: string[] = [...currentFieldSearchValues]

    if (updatedValues.length < 2) {
      updatedValues[0] = updatedValues[0] || ""
      updatedValues[1] = updatedValues[1] || ""
    }

    if (type === "to") {
      //@ts-ignore
      updatedValues[0] = e.currentTarget.value
    } else if (type === "from") {
      //@ts-ignore
      updatedValues[1] = e.currentTarget.value
    }

    updateFilters({
      [fieldSearchValuesName]: updatedValues
    })
  }

  const isValues = useMemo(() => {
    const condition =
      //@ts-ignore
      filters?.sections?.[indexSection]?.fields?.[index]?.condition
    return condition === "contain"
  }, [filters, indexSection, index])

  const isTwoInputs = useMemo(() => {
    const condition =
      //@ts-ignore
      filters?.sections?.[indexSection]?.fields?.[index]?.condition
    return ["between", "notBetween"].includes(condition)
  }, [filters, indexSection, index])

  const onKeyPress = useArrayFieldInputChange({
    field: fieldSearchValuesName
  })

  const onBlur = useArrayFieldInputChange({
    field: fieldSearchValuesName,
    isOnBlur: true
  })

  const onPressInput = (
    e: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
  ) => {
    if ((e as KeyboardEvent<HTMLInputElement>).key === "Enter") {
      e.preventDefault()
    }
  }

  return (
    <>
      {isValues ? (
        <>
          <Div minWidth="250px">
            <FormikInput
              name={fieldSearchValuesName}
              label="Search text"
              variant="middle"
              disableDefaultOnBlur
              placeholder="Search text"
              onBlur={onBlur as (e: React.FocusEvent<HTMLInputElement>) => void}
              onKeyPress={
                onKeyPress as (e: React.KeyboardEvent<HTMLInputElement>) => void
              }
            />
            <PreferredList
              keywords={currentFieldSearchValues}
              onKeywordRemove={(value) =>
                removeArrayBasedInternalFilter(fieldSearchValuesName, value)
              }
            />
          </Div>
        </>
      ) : isTwoInputs ? (
        <Flex>
          <Div mr="xs">
            <FormikInput
              name={`${fieldSearchValuesName}0`}
              label="Search text"
              variant="middle"
              disableDefaultOnBlur
              placeholder="Search text"
              onChange={(e: React.FocusEvent<HTMLInputElement>) =>
                handleInputsChange(e, "to")
              }
              defaultValue={currentFieldSearchValues[0]}
              onKeyPress={
                onPressInput as (
                  e: React.KeyboardEvent<HTMLInputElement>
                ) => void
              }
            />
          </Div>
          <Div>
            <FormikInput
              name={`${fieldSearchValuesName}1`}
              label="Search text"
              variant="middle"
              disableDefaultOnBlur
              placeholder="Search text"
              onChange={(e: React.FocusEvent<HTMLInputElement>) =>
                handleInputsChange(e, "from")
              }
              defaultValue={currentFieldSearchValues[1]}
              onKeyPress={
                onPressInput as (
                  e: React.KeyboardEvent<HTMLInputElement>
                ) => void
              }
            />
          </Div>
        </Flex>
      ) : (
        <Div minWidth="250px">
          <FormikInput
            name={fieldSearchValuesName}
            label="Search text"
            variant="middle"
            disableDefaultOnBlur
            placeholder="Search text"
            onChange={(e: React.FocusEvent<HTMLInputElement>) =>
              handleInputChange(e)
            }
            defaultValue={currentFieldSearchValues[0]}
            onKeyPress={
              onPressInput as (e: React.KeyboardEvent<HTMLInputElement>) => void
            }
          />
        </Div>
      )}
    </>
  )
}
