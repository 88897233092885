import { useEffect, useState } from "react"
import { CompanyEndpoints } from "setup/api/endpoints/endpoints"
import { expandEndpointByParams } from "setup/api/api.helpers"
import { apiRequest } from "setup/api/api"
import { Company } from "views/companies/company-module/company-module.types"
import { SearchCompany } from "../types"

const useCompaniesTypeAhead = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [companies, setCompanies] = useState<SearchCompany[]>([])
  const [isShowedCompanies, setIsShowedCompanies] = useState<boolean>(false)
  const [searchedWord, setSearchedWord] = useState<string>("")
  const [isCompanySelected, setCompanySelected] = useState<boolean>(false)

  useEffect(() => {
    let isCompaniesLoading = true

    const fetchCompanies = async () => {
      setIsLoading(true)
      const [, response] = await apiRequest.get({
        endpoint: CompanyEndpoints.GetCompaniesName,
        config: expandEndpointByParams({ searchString: searchedWord })
      })

      if (isCompaniesLoading && response?.data) {
        const tempCompanies = response?.data.map(
          ({ id, addresses, logoUrl, name }: Company) => ({
            id: id,
            location: addresses[0]?.freeformAddress || "",
            url: logoUrl,
            name: name
          })
        )
        tempCompanies.length && setIsShowedCompanies(true)
        setCompanies(tempCompanies)
        setIsLoading(false)
      }
    }

    if (searchedWord.length > 0 && !isCompanySelected) {
      fetchCompanies()
    } else {
      setIsShowedCompanies(false)
      setCompanies([])
    }

    return () => {
      isCompaniesLoading = false
    }
  }, [searchedWord, isCompanySelected])

  return {
    isLoading,
    companies,
    isShowedCompanies,
    searchedWord,
    setIsShowedCompanies,
    setSearchedWord,
    setCompanySelected
  }
}

export default useCompaniesTypeAhead
