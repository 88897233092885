import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import { EditOffLimitsField } from "./definitions"

export const EditOffLimitsValidationSchema = Yup.object({
  [EditOffLimitsField.EndDate]: Yup.date().transform(
    (_value, originalValue) => {
      if (originalValue instanceof Date) {
        return originalValue
      }
      const parsedDate = new Date(originalValue)
      return parsedDate ? undefined : parsedDate
    }
  ),
  [EditOffLimitsField.Comment]: Yup.string().max(
    1000,
    messages.form.generic.errors.mustBeNoLongerThan.format(1000)
  )
})
