import React from "react"
import { ComingSoonWrapper, StyledFlex } from "./style"
import { SearchSection } from "../SearchSection"
import { Form, Formik } from "formik"
import { initialValues } from "views/internal-search/definitions"
import { noop } from "lodash"
import { BuildSearch } from "../BuildSearch/BuildSearch"
import { BodyText, colors, H4 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

const InternalSearchCriteria = () => {
  return (
    <>
      <BuildSearch />
      <Formik
        initialValues={initialValues}
        onSubmit={noop}
        validateOnBlur={false}
      >
        <Form>
          <StyledFlex>
            <SearchSection />
          </StyledFlex>
        </Form>
      </Formik>
      <ComingSoonWrapper>
        <H4 color={colors.grey.standard} mt="xs" mb="xxs">
          {messages.project.outReach.comingSoon}
        </H4>
        <BodyText textAlign="center" m="0" color={colors.grey.standard} px="xs">
          {messages.internalSearch.manyFiltersComing}
        </BodyText>
      </ComingSoonWrapper>
    </>
  )
}

export default InternalSearchCriteria
