import { PersonValue } from "models/LocalPerson/localPerson.definitions"
import { CreatePersonValues } from "views/persons/components/person-details/form/constants/definitions"
import {
  PhoneUsageTypeNumExchanger,
  emailTypeNumExchanger
} from "views/persons/components/person-details/utlis"
import {
  EmailTypeEnums,
  PhoneTypeEnums
} from "./components/person-contact-info/form/constants/definitions"
import { FormikHelpers } from "formik"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"
import { messages } from "setup/messages/messages"

const prepareFilledArrayWithMinOneElement = (
  array: any[],
  nestedKey?: string,
  defaultValue = [""]
): any[] => {
  const filtered = array?.filter((item) =>
    nestedKey ? Boolean(item?.value?.[nestedKey]) : Boolean(item?.value)
  )
  return filtered?.length === 0 ? defaultValue : filtered
}

export const prepareRequestData = (
  values: CreatePersonValues,
  oldBio: PersonValue<string | null>
): CreatePersonValues => {
  const valuesArray: any = {}
  if (values.webSites) {
    const webSites = prepareFilledArrayWithMinOneElement(values.webSites, "url")
      .map((item) =>
        item?.value?.url?.startsWith("http://") ||
        item?.value?.url?.startsWith("https://")
          ? { isReadOnly: false, ...item }
          : item?.value?.url
          ? { isReadOnly: false, value: { url: `https://${item?.value?.url}` } }
          : ""
      )
      .filter(Boolean)

    valuesArray.webSites = webSites
  }

  if (values?.bio?.value === oldBio.value) {
    values.bio.value = null
  }

  if (values.phones) {
    values.phones = values.phones.map((phoneData) => {
      phoneData.value = PhoneUsageTypeNumExchanger(phoneData.value)
      return phoneData
    })

    const addedDefaultValues = values.phones.map((phoneData) => {
      if (typeof phoneData.value.phoneUsageType !== "number") {
        phoneData.value.phoneUsageType = PhoneTypeEnums.Personal
      }
      if (!phoneData.value.isPreferred) {
        phoneData.value.isPreferred = false
      }
      return phoneData
    })
    valuesArray.phones = addedDefaultValues
    //Delete after story 122776 BE is done.
    values.phoneNumbers = {
      value: values.phones.map((phoneData) => phoneData.value.phoneNumber),
      isReadOnly: false
    }
  }

  if (values.taggedEmails) {
    values.taggedEmails = values.taggedEmails.map((emailData) => {
      emailData.value = emailTypeNumExchanger(emailData.value)
      return emailData
    })

    const addedDefaultValues = values.taggedEmails.map((emailData) => {
      if (typeof emailData.value.type !== "number") {
        emailData.value.type = EmailTypeEnums.Personal
      }
      if (!emailData.value.isPreferred) {
        emailData.value.isPreferred = false
      }
      return emailData
    })

    valuesArray.taggedEmails = addedDefaultValues
  }

  if (values.dateOfBirth !== null) {
    valuesArray.dateOfBirth = {
      value: values.dateOfBirth
    }
  } else {
    valuesArray.dateOfBirth = {
      value: null
    }
  }

  return {
    ...values,
    ...valuesArray
  }
}

export const nestedKeyError = (
  key: string,
  errorMessage: string,
  formikHelpers: FormikHelpers<
    Omit<
      PersonProfileDetails,
      "company" | "companyId" | "experience" | "jobTitle"
    >
  >
) => {
  const splitName = key.split(".")
  if (splitName[1].includes("phones")) {
    const phoneErrorMsg =
      errorMessage === "The PhoneNumber field is not a valid phone number."
        ? messages.person.details.errors.invalidPhoneNumber
        : errorMessage
    formikHelpers.setFieldError(
      splitName[1] + ".value." + splitName[2],
      phoneErrorMsg
    )
    formikHelpers.setFieldError(splitName[1] + ".value.phoneUsageType", " ")
    formikHelpers.setFieldError(splitName[1] + ".value.isPreferred", " ")
  } else if (splitName[1].includes("taggedEmails")) {
    formikHelpers.setFieldError(
      splitName[1] + ".value." + splitName[2],
      errorMessage
    )
    formikHelpers.setFieldError(splitName[1] + ".value.type", " ")
    formikHelpers.setFieldError(splitName[1] + ".value.isPreferred", " ")
  } else {
    formikHelpers.setFieldError(key, errorMessage)
    formikHelpers.setFieldError(key + ".value", errorMessage)
  }
}
