import * as Yup from "yup"

export enum ParseTextField {
  ParseText = "parseText"
}

export const initialParseText = { [ParseTextField.ParseText]: "" }

export const e2eTargets = {
  addKeywordButton: "keywords-add",
  addCompanyButton: "company-add",
  addJobTitleButton: "job-title-add",
  jobTitle: "job-title",
  company: "company"
}

export const personExperienceE2ETargetNames = {
  option: "sector-option"
}

export const personExperienceE2ETargets = {
  section: "person-experience",
  ...personExperienceE2ETargetNames
}

export const parseTextValidationSchema = Yup.object({
  [ParseTextField.ParseText]: Yup.string().trim().required()
})
