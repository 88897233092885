import React, { useMemo } from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { Candidate } from "views/candidates/candidates.types"
import { Loader } from "components/Loader/Loader"
import { personAssignmentsE2ETargets } from "views/persons/components/person-assignments/form/constants/add-person-assignment.definitions"
import { PersonState } from "views/persons/person-module/person-module.types"
import AssignmentRecord from "./AssignmentRecord"
import PersonNoDataScreen from "../../person-no-data-message/PersonNoDataScreen"
import { messages } from "setup/messages/messages"
import { ListPersonCandidatesContainer } from "./style"

type ListPersonCandidatesProps = {
  candidates: PersonState["candidates"]
  isLoading: boolean
}

export const ListPersonCandidates = (props: ListPersonCandidatesProps) => {
  const { candidates, isLoading } = props

  const candidatesAssignmentList = useMemo(
    () => Object.values(candidates),
    [candidates]
  )

  return (
    <Div width="100%">
      {isLoading ? (
        <Flex>
          <Loader />
        </Flex>
      ) : candidatesAssignmentList.length ? (
        <ListPersonCandidatesContainer
          data-e2e-target-name={personAssignmentsE2ETargets.assignments}
        >
          {candidatesAssignmentList.map((candidate: Candidate) => {
            return <AssignmentRecord candidate={candidate} />
          })}
        </ListPersonCandidatesContainer>
      ) : (
        <PersonNoDataScreen
          message={messages.person.personTabSection.noDataTexts.noAssignment}
        />
      )}
    </Div>
  )
}
