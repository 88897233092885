import { fetchDocument } from "./helpers"

export const openNoteDoc = async (
  document: { fileName: string },
  documentId: string,
  notedId: string,
  textModal?: () => void
) => {
  if (document.fileName) {
    const fileExtension = document.fileName.split(".").pop()

    if (fileExtension) {
      const lowerCaseExtension = fileExtension.toLowerCase()

      if (lowerCaseExtension === "txt" && textModal) {
        textModal()
        return
      } else {
        const fileUrl = await fetchDocument(notedId, documentId)

        if (fileUrl) {
          window.open(fileUrl, "_blank")
        } else {
          console.error("Document URL is undefined or null.")
        }
      }
    } else {
      console.error("File extension is missing.")
    }
  } else {
    console.error("Document fileName is missing.")
  }
}
