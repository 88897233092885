import React, { useCallback, useMemo, useState, useEffect } from "react"
import { Header, Sidebar } from "components/visual/header"
import { RouterUrl } from "setup/router/routes"
import { useNavigate } from "react-router-dom"
import { useAuth } from "setup/auth/module/auth.context"
import {
  generateLinks,
  generateSettings,
  generateHelpers,
  generateMobileOptions
} from "components/navigation/links"
import { SubscriptionType } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"

type Props = {
  isLarge?: boolean
}

export const Navigation = ({ isLarge }: Props) => {
  const navigate = useNavigate()
  const { login, isLoggedIn, user } = useAuth()

  const isSubscriptionExpired = user?.isSubscriptionExpired === true
  const isTrial = user?.planType?.toLocaleLowerCase() === SubscriptionType.Trial

  const onSignupClick = useCallback(() => {
    navigate(RouterUrl.Signup)
  }, [navigate])

  const onInviteClick = useCallback(() => {
    navigate(RouterUrl.TeamInviteUser)
  }, [navigate])

  const params = useMemo(
    () => ({
      isLoggedIn: Boolean(isLoggedIn),
      login,
      inviteUser: onInviteClick,
      signup: onSignupClick,
      expired: !!isSubscriptionExpired,
      isTrial,
      userRole: user?.userRole
    }),
    [
      isLoggedIn,
      isSubscriptionExpired,
      login,
      onInviteClick,
      onSignupClick,
      isTrial,
      user
    ]
  )

  const linksToRender = useMemo(() => generateLinks(params), [params])

  const helpersToRender = useMemo(() => generateHelpers(params), [params])

  const settingsToRender = useMemo(() => generateSettings(params), [params])

  const headerValues = {
    menuItems: linksToRender,
    settings: settingsToRender,
    helpers: helpersToRender,
    homepageDisabled: !!isSubscriptionExpired
  }

  const mobileOptions = useMemo(() => generateMobileOptions(params), [params])

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const isMobile = windowSize.height < 394 || windowSize.width < 992

  return isMobile ? (
    <Header options={mobileOptions} {...headerValues} />
  ) : (
    <Sidebar {...headerValues} />
  )
}
