import styled from "styled-components"
import { Div, Flex, H6, Link, PlusIcon } from "@ikiru/talentis-fpc"

export const CreateNewLinkContainer = styled(Flex)`
  height: 50px;
  border-top: 2px solid ${({ theme }) => theme.colors.grey.light};
  position: sticky;
  margin-top: 5px;
`
export const CreateNewLink = styled(Link)`
  font-size: 13px;
  margin: 10px 10px 10px;
  background-position: left bottom 6px;
`

export const MenuListContainer = styled(Div)`
  max-height: 300px;
  position: relative;
  right: 0;
  width: fit-content;
  max-height: 200px;
  overflow-y: auto;
  background-size: 100% 100%;
  min-width: 170px;
`

export const LinkLable = styled(H6)`
  margin: 0px;
  margin-top: -18px;
  margin-left: 14px;
  width: 100%;
  color: ${({ theme }) => theme.colors.grey.dark};
`

export const Plus = styled(PlusIcon)`
  width: 12px;
  height: 12px;
  margin-bottom: -2px;
`
