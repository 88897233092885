import styled from "styled-components"
import { GridBox, Div } from "@ikiru/talentis-fpc"
import { backgroundOffLimits } from "components/OffLimits/style"

const avatarBoxSize = "70px"

export const RecordBoxWrapper = styled.div<{
  borderless: boolean
  isNotArchive?: boolean
}>`
  position: relative;
  width: 100%;
  border: ${({ borderless }) => (borderless ? 0 : 2)}px solid
    ${({ theme }) => theme.colors.grey.light};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  background: ${({ theme }) => theme.colors.white.standard};
  opacity: ${({ isNotArchive = true }) => (isNotArchive ? 1 : 0.5)};
  padding: 0;
`

export const RecordFooterBox = styled.div`
  padding: 0 12px 10px 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  &:empty {
    padding: 0;
    border: none;
  }
`

export const RecordFooterTabs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.space.xs}px;
`

export const RecordFooterTabItem = styled.div<{ active?: boolean }>`
  padding: ${({ theme }) => theme.space.xxs}px
    ${({ theme }) => theme.space.xs}px;
  background: ${({ theme, active }) =>
    active ? theme.colors.grey.lightest : "none"};
  border-radius: 3px 3px 0 0;
  color: ${({ theme, active }) =>
    active ? theme.colors.grey.dark : theme.colors.grey.standard};
  font-size: 13px;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  line-height: 15px;
  cursor: pointer;
`

export const RecordBox = styled.div<{ isOfflimits?: boolean }>`
  text-decoration: none;
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto;
  position: relative;
  width: 100%;

  grid-template-rows: max-content;
  grid-template-columns: ${({ isOfflimits }) => (isOfflimits ? "20px " : "")} ${avatarBoxSize} 1fr;
  grid-template-areas:
    "${({ isOfflimits }) => (isOfflimits ? "offlimits" : "")} avatar details"
    "${({ isOfflimits }) => (isOfflimits ? "stage" : "")} stage stage";

  ${({ theme, isOfflimits }) => `
    ${theme.mediaQueries.xs} {

      grid-template-columns: ${
        isOfflimits ? "20px " : ""
      } ${avatarBoxSize} repeat(2, 1fr);
      grid-template-areas:
      "${isOfflimits ? "offlimits" : ""} avatar details stage"
    }

    ${theme.mediaQueries.sm} {
      grid-template-columns: ${
        isOfflimits
          ? `20px ${avatarBoxSize} 0.8fr repeat(2, 1.1fr)`
          : `${avatarBoxSize} 0.8fr 20px repeat(2, 1.1fr)`
      };
      grid-template-areas: "${
        isOfflimits
          ? "offlimits avatar details stage stage"
          : "avatar details empty stage stage"
      }"
       
    }
  `}
`

export const DetailsBox = styled(GridBox)<{ isOfflimits?: boolean }>`
  grid-area: details;
  ${backgroundOffLimits}
`
export const AvatarBox = styled(GridBox)<{ isOfflimits?: boolean }>`
  grid-area: avatar;
  padding-top: ${({ theme }) => theme.space.xs}px;
  padding-left: ${({ theme }) => theme.space.xs}px;
  width: ${avatarBoxSize};
  height: ${({ isOfflimits }) => (isOfflimits ? "100%" : avatarBoxSize)};
  ${backgroundOffLimits}
`
export const NameDetailsBox = styled(Div)`
  padding: ${({ theme }) => theme.space.xs}px;
  padding-bottom: 0;
`
export const CompanyDetailsBox = styled(Div)`
  padding: ${({ theme }) => theme.space.xs}px;
  padding-top: 3px;
  padding-bottom: 0;
`

export const OffLimitsBox = styled(GridBox)`
  grid-area: offlimits;
`

export const EmptyBox = styled(GridBox)`
  grid-area: empty;
`
