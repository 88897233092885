import React, { useCallback } from "react"
import { FormAction } from "utils/forms/form.types"
import { getOnAddPersonCommentarySubmit } from "../actions"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { Candidate } from "views/candidates/candidates.types"
import { LinkedAssignment } from "@ikiru/talentis-fpc"
import { NoteAddValues } from "../types"
import { useModal } from "utils/hooks/use-modal"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { NoteModal } from "../NoteModal"
import { usePerson } from "views/persons/person-module/candiate-module.context"

const usePersonRecordCommentary = (personRecordId: string) => {
  const { updateCandidate, selectedTab, updateCompanyCandidate } =
    useAssignment()
  const { setCandidate } = usePerson()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCreateCommentarySubmit = useCallback(
    getOnAddPersonCommentarySubmit(personRecordId, (candidate) => {
      if (selectedTab === "companyCandidate") {
        updateCompanyCandidate(candidate)
      }
      updateCandidate(candidate)

      setCandidate(candidate)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateCandidate]
  )

  const onEditCommentarySubmit = useCallback(
    (): FormAction<NoteAddValues> =>
      getOnAddPersonCommentarySubmit(personRecordId, (candidate) => {
        if (selectedTab === "companyCandidate") {
          updateCompanyCandidate(candidate)
        }
        updateCandidate(candidate)

        setCandidate(candidate)
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [personRecordId, updateCandidate, setCandidate]
  )

  const prepareNoteView = (candidate: Candidate) => {
    return {
      noteTitle: "",
      noteDescription: candidate?.commentary.data,
      commentaryDescription: candidate?.commentary.data,
      commentaryShowToClient: candidate?.commentary.showToClient,
      createdDate: candidate?.commentary.created,
      updatedDate: candidate?.commentary.updated,
      createdByUserId: candidate?.commentary.createdBy,
      updatedByUserId: candidate?.commentary.updatedBy,
      linkCreatedByUser: {
        firstName: candidate?.commentary.createdBy?.firstName || "",
        lastName: candidate?.commentary.createdBy?.lastName || "",
        id: ""
      },
      linkUpdatedByUser: {
        firstName: candidate?.commentary.updatedBy?.firstName || "",
        lastName: candidate?.commentary.updatedBy?.lastName || "",
        id: ""
      },
      id: "",
      linkAssignment: {} as LinkedAssignment
    }
  }

  const { open } = useModal()

  const openCommentaryModal = useCallback(
    (name: string, candidate: Candidate) =>
      (note?: {
        commentaryDescription: string
        commentaryShowToClient: boolean
      }) => {
        const title = `${
          note
            ? messages.clientPortal.commentary.editCommentary
            : messages.clientPortal.commentary.addCommentary
        } ${Boolean(name) ? `${messages.clientPortal.notes.for} ${name}` : ""} `
        open(
          ModalNames.PersonRecordCommentaryModal,
          <NoteModal
            title={title}
            onSubmit={onCreateCommentarySubmit}
            initialValues={note}
            typeTitle=""
            candidate={candidate}
          />
        )
      },
    [open, onCreateCommentarySubmit]
  )

  return {
    onCreateCommentarySubmit,
    onEditCommentarySubmit,
    prepareNoteView,
    openCommentaryModal
  }
}

export { usePersonRecordCommentary }
