import React from "react"
import { AssignmentModule } from "views/assignments/assignment-module/AssignmentModule"
import { AssignmentDetails } from "./AssignmentDetails"
import { PersonProfileWrapper } from "views/persons/pages/manage-person/PersonProfileWrapper"

export const AssignmentDetailsWrapper = () => {
  return (
    <AssignmentModule>
      <PersonProfileWrapper>
        <AssignmentDetails />
      </PersonProfileWrapper>
    </AssignmentModule>
  )
}
