import { Div } from "@ikiru/talentis-fpc" // -  Flex, H1, LargeText, PositiveIcon  Flex, H1, LargeText, PositiveIcon - missing imports for invite collegue
import { FormikProps } from "formik"
import React, { useMemo } from "react"
// import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { InvitationPayload } from "views/homepage/components/Invitations/components/invitation-create-form/constants/invitation-details-form.definitions"
import { InvitationSkippedInfo } from "views/homepage/components/Invitations/components/invitation-create-form/InvitationSkippedInfo"
// import { InviteColleaguesDetailsForm } from "views/homepage/components/Invitations/components/invitation-create-form/InviteColleaguesDetailsForm"
import { ClientPortalShareSettingsModalWrapper } from "./styles"

type ClientPortalShareSettingsModalProps = {
  onClose?: VoidFunction
  formCreateInvitationComponent?: (
    formikProps: FormikProps<InvitationPayload>
  ) => JSX.Element
  formActionsComponent: (
    formikProps: FormikProps<InvitationPayload>
  ) => JSX.Element
}

export const InviteColleaguesModal = ({
  formActionsComponent,
  formCreateInvitationComponent
}: ClientPortalShareSettingsModalProps) => {
  const { modalSuccess } = useModal() // skipped, - missing import
  // const formSkipped = useMemo(() => skipped === ModalNames.InviteColleagues, [skipped])
  const inviteSent = useMemo(
    () => modalSuccess?.modalName === ModalNames.InviteColleagues,
    [modalSuccess]
  )

  return (
    <ClientPortalShareSettingsModalWrapper>
      {/* {!formSkipped ? <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb="none"
        bg="grey.light"
        px="l"
        pt="l"
        pb="xs"
      >
        {inviteSent && <PositiveIcon />}
        <H1 mt="none" mb="none" textAlign="center" color="green.standard">
          {!inviteSent ? messages.form.generic.inviteColleagues : messages.form.generic.inviteSent}
        </H1>
        {!inviteSent && <LargeText textAlign="center" mt="xs" mb="none" color="grey.dark">
          {messages.form.generic.workWithColleagues}
        </LargeText>}
      </Flex> :
        <LargeText textAlign="center" mt="md" mb="none" mx="m" color="grey.dark">
          {messages.form.generic.youCanAddOrRemove}
        </LargeText>} */}
      <Div>
        <>
          {/* {(!formSkipped && !inviteSent) ?
            <InviteColleaguesDetailsForm
              formCreateInvitationComponent={formCreateInvitationComponent}
              formActionsComponent={formActionsComponent}
            />
            : */}
          <InvitationSkippedInfo inviteSent={inviteSent} />
          {/* } */}
        </>
      </Div>
    </ClientPortalShareSettingsModalWrapper>
  )
}
