import React from "react"
import { Div } from "@ikiru/talentis-fpc"
import { H5 } from "@ikiru/talentis-fpc"
import { FilterPopupContainer, FilterPopupContent } from "../../style"
import { CandidateFilterToggle } from "../../CandidateFilterToggle"
import { CandidateFilter } from "views/assignments/assignment-module/assignment-module.types"

interface IGeneralFiltersProps {
  translatedString: string
  filtersData: CandidateFilter[]
  filterIsApplied: (candidateFilter: CandidateFilter) => boolean
  addFilter: (candidateFilter: CandidateFilter) => void
  removeFilter: (candidateFilter: CandidateFilter) => void
}

export const GeneralFilters = React.memo((props: IGeneralFiltersProps) => {
  const {
    filtersData,
    translatedString,
    filterIsApplied,
    addFilter,
    removeFilter
  } = props

  return (
    <FilterPopupContainer>
      <Div backgroundColor="grey.light" p="xs">
        <H5 color="grey.dark" m="0">
          {translatedString}
        </H5>
      </Div>
      <FilterPopupContent>
        {filtersData.map((filter) => (
          <Div key={JSON.stringify(filter.value)}>
            <CandidateFilterToggle
              filter={filter}
              {...{ filterIsApplied, addFilter, removeFilter }}
            />
          </Div>
        ))}
      </FilterPopupContent>
    </FilterPopupContainer>
  )
})
