import React from "react"
import { Div, Flex, H6, SmallText } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import moment from "moment"
import { generateCurrency } from "views/assignments/utils"
import { LatestExperienceType } from "views/internal-search/InternalSearchModule/types"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"

type ExperienceSectionProps = {
  experience: LatestExperienceType
}

export const ExperienceSection = ({ experience }: ExperienceSectionProps) => {
  const expStartDate = experience.startDate
    ? moment(experience.startDate).format("MMM YYYY")
    : null

  const expEndDate = experience.endDate
    ? moment(experience.endDate).format("MMM YYYY")
    : experience.startDate
    ? messages.talentGraphSearch.present
    : null

  return (
    <Flex flexDirection="column" width="100%" mt="xs" ml="xxs" flexWrap="wrap">
      <H6 m="0">{experience.title}</H6>
      <Div width="100%">
        <CompanyLink
          companyName={experience.companyName}
          companyId={experience.companyId}
          fontSize={[13]}
        />
      </Div>

      <SmallText>
        {expStartDate} - {expEndDate}
      </SmallText>

      <Flex width="100%" flexWrap="wrap">
        <SmallText width="50%">
          <SmallText fontWeight={600} mr="xxs">
            {messages.person.experience.salary}:
          </SmallText>
          {experience.compensation?.salary?.value
            ? generateCurrency(
                experience.compensation.salary.value,
                experience.compensation.salary.currency
              )
            : messages.person.experience.unspecified}
        </SmallText>
        <SmallText>
          <SmallText fontWeight={600} mr="xxs">
            {messages.person.experience.package}:
          </SmallText>
          {experience.compensation?.package?.value
            ? generateCurrency(
                experience.compensation.package.value,
                experience.compensation.package.currency
              )
            : messages.person.experience.unspecified}
        </SmallText>
      </Flex>

      <SmallText>{experience.compensation?.comment}</SmallText>
    </Flex>
  )
}
