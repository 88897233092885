import sortBy from "lodash/sortBy"
import { TeamMember } from "./team-module.types"

export const sortTeamMembers = (teamMembers: TeamMember[], userId: string) =>
  sortBy(
    teamMembers,
    (member) =>
      `${member.firstName?.toLowerCase()} ${member.lastName?.toLowerCase()}`,
    ["emailAddress"]
  ).sort((prev: TeamMember, next: TeamMember) =>
    prev.id === userId ? -1 : next.id === userId ? 1 : 0
  )
