import React, { useState, useCallback } from "react"
import { NewContactModalWrapper } from "./style"
import { useNewContactModal, useSearchQuery } from "./hooks"
import {
  Flex,
  H2,
  Button,
  CrossIcon,
  getMinWidthMediaQuery,
  Div
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useMediaQuery } from "utils/hooks/use-media-query"
import CreatePerson from "./components/CreatePerson"
import { FindContactForm } from "./components/FindPerson"
import { PossibleDuplicates } from "./components/PossibleDuplicates"
import AddPeople from "./components/AddPeople"
import { PersonSearchResult } from "views/persons/pages/search-person/search-person/search-person.definitions"
import { fetchFoundPeople } from "./components/AddPeople/action"
import useAddPersonToProject from "./components/AddPeople/hooks/useAddPersonToProject"
import { PaginationProps } from "components/functional/pagination/Pagination"

export type PersonDetailsType = {
  firstName?: string
  lastName?: string
  jobTitle?: string
  jobTitleLogic?: string
  companies?: string[]
  companyLogic?: string
  companyId?: string
  keywords?: string[]
  keywordLogic?: string
  country?: string
  location?: string[]
}

export const ContactAddModal = () => {
  const query = useSearchQuery()

  const nameParam = query.get("name") || ""
  let assignmentId = query.get("assignmentId") || ""
  let campaignId = query.get("campaignId") || ""
  let companyName = query.get("company") || ""
  let jobTitle = query.get("jobTitle") || ""
  // Add back after story 128262 - Add country location to assignment endpoint
  // let location = query.get("location") || ""

  const [name, setName] = useState<string>(nameParam || "")

  const [foundPeople, setFoundPeople] = useState<PersonSearchResult[]>([])
  const [isLoadingFind, setIsLoadingFind] = useState(false)
  const [personSearchDetails, setPersonSearchDetails] =
    useState<PersonDetailsType | null>()
  const [progressCreate, setProgressCreate] = useState(false)
  const [progressSearch, setProgressSearch] = useState(false)
  const [paginationProps, setPaginationProps] = useState<PaginationProps>()

  const { closeAndRedirectToAssignment, closeAndRedirectToCampaign } =
    useAddPersonToProject()
  const { close } = useNewContactModal()

  const mediaQuery = getMinWidthMediaQuery("sm")
  const isMobile = !useMediaQuery(mediaQuery)

  const onSubmitCreate = useCallback((name: string) => {
    setName(name)
    setProgressCreate(true)
  }, [])

  const onSubmitFind = async (data: PersonDetailsType, pageNumber = 1) => {
    setIsLoadingFind(true)
    setPersonSearchDetails(data)
    !progressSearch && setProgressSearch(true)
    const foundPeopleList = await fetchFoundPeople(data, pageNumber)

    const { results, ...rest } = foundPeopleList
    setPaginationProps(rest)
    const peopleList: PersonSearchResult[] = results.map((foundPerson: any) => {
      let experience = null
      if (foundPerson.experience) {
        const currentExperience = foundPerson.experience.find(
          (exp: any) => exp.current === "true"
        )
        if (experience) {
          experience = currentExperience
        } else {
          experience = foundPerson.experience[0]
        }
      }
      return {
        id: foundPerson.profileId,
        name: foundPerson?.fullName || "",
        jobTitle: experience?.jobTitle || "",
        company: experience?.companyName || "",
        location: foundPerson?.location || "",
        photo: { url: foundPerson?.profilePhoto || "" }
      }
    })

    setFoundPeople(peopleList)
    setIsLoadingFind(false)
  }

  const handleClick = useCallback(() => {
    if (assignmentId) {
      closeAndRedirectToAssignment(assignmentId)
    } else if (campaignId) {
      closeAndRedirectToCampaign(campaignId)
    } else {
      close()
    }
  }, [
    assignmentId,
    campaignId,
    closeAndRedirectToAssignment,
    closeAndRedirectToCampaign,
    close
  ])

  return (
    <NewContactModalWrapper>
      {!(progressSearch || progressCreate) ? (
        <Div p="m" height="fit-content" overflowY="auto">
          <Flex justifyContent="space-between" alignItems="center" pb="xxs">
            <H2 textAlign="center" color="grey.dark" mb="0">
              {messages.person.contact.form.title}
            </H2>
            <Button
              mt="-20px"
              onClick={() => handleClick()}
              mode="action"
              size="action-standard"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          </Flex>
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            width="100%"
            justifyContent="space-between"
            mt="xs"
            overflow="hidden"
          >
            <FindContactForm
              {...{
                onSubmit: onSubmitFind,
                isMobile,
                personSearchDetails: {
                  companies: companyName ? [companyName] : [],
                  jobTitle: jobTitle
                  // country: location
                }
              }}
            />
            <CreatePerson
              {...{
                name,
                title: messages.person.newContact.dontKnow,
                onSubmit: onSubmitCreate,
                isMobile
              }}
            />
          </Flex>
        </Div>
      ) : progressCreate ? (
        <PossibleDuplicates {...{ name, assignmentId, campaignId }} />
      ) : (
        personSearchDetails && (
          <AddPeople
            {...{
              foundPeople,
              isLoadingFind,
              personSearchDetails,
              onSubmit: onSubmitFind,
              onSubmitNewRecord: onSubmitCreate,
              assignmentId,
              campaignId,
              isMobile,
              paginationProps
            }}
          />
        )
      )}
    </NewContactModalWrapper>
  )
}
