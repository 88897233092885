import { Flex, SectionHeader } from "@ikiru/talentis-fpc"
import PersonEducationView from "./components/PersonEducationView"
import { messages } from "setup/messages/messages"
import PersonNoDataScreen from "../person-no-data-message/PersonNoDataScreen"
import { e2eTargets } from "../person-data-privacy/definitions"
import { EditableSection } from "components/EditableSection"
import React, { useMemo } from "react"
import { usePerson } from "views/persons/person-module/candiate-module.context"

const PersonEducation = () => {
  const { person } = usePerson()

  const education = useMemo(() => person?.education, [person?.education])
  return (
    <EditableSection
      size="xSmall"
      noBottomSpace
      isDefaultExpanded
      header={{ title: messages.person.details.education }}
      targetName={e2eTargets.section}
      actions={() => (
        <SectionHeader
          title={messages.person.details.education}
          size="xxSmall"
        />
      )}
      view={() => {
        return (
          <Flex justifyContent="center" width="100%">
            {education?.length ? (
              <PersonEducationView />
            ) : (
              <PersonNoDataScreen
                message={
                  messages.person.personTabSection.noDataTexts.noEducation
                }
              />
            )}
          </Flex>
        )
      }}
      edit={() => {
        return <PersonEducationView />
      }}
    />
  )
}

export default PersonEducation
