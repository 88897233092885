import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import {
  AssignmentDataField,
  AssignmentPackageDataField,
  AssignmentFeeDataField
} from "./definitions"

export const AssignmentPrimaryDataValidationSchema = Yup.object({
  [AssignmentDataField.Name]: Yup.string()
    .max(100, messages.form.generic.errors.mustBeNoLongerThan.format(100))
    .required(messages.generic.required),
  [AssignmentDataField.StartDate]: Yup.date()
    .transform((_value, originalValue) => {
      if (originalValue instanceof Date) {
        return originalValue
      }
      const parsedDate = new Date(originalValue)
      return parsedDate ? undefined : parsedDate
    })

    .required(messages.generic.required),
  [AssignmentDataField.Brief]: Yup.string()
    .nullable()
    .max(4000, messages.form.generic.errors.mustBeNoLongerThan.format(4000))
})

export const AssignmentPackageDataValidationSchema = Yup.object({
  [AssignmentPackageDataField.SalaryFrom]: Yup.number().typeError(
    messages.generic.number
  ),
  [AssignmentPackageDataField.SalaryTo]: Yup.number().typeError(
    messages.generic.number
  )
})

export const AssignmentFeeDataValidationSchema = Yup.object({
  [AssignmentFeeDataField.FeeFrom]: Yup.number().typeError(
    messages.generic.number
  ),
  [AssignmentFeeDataField.FeeTo]: Yup.number().typeError(
    messages.generic.number
  ),
  [AssignmentFeeDataField.FinalFee]: Yup.number().typeError(
    messages.generic.number
  )
})
