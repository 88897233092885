import React, { useCallback, useMemo, useState } from "react"
import {
  Flex,
  Div,
  BodyText,
  Avatar,
  ConnectedIcon,
  Link,
  spacing,
  getMinWidthMediaQuery,
  Span,
  SmallText,
  Button,
  OffLimits,
  DropdownArrowIcon as DropdownArrow
} from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { isNotNullOrZero } from "utils/notNull"
import {
  CompanyPersonRecordBox,
  CompanyPersonData,
  FormerCompanyPersonData,
  PersonInfoWrapper,
  FormerCompanyCurrentData
} from "./style"
import { useCompany } from "views/companies/company-module/company-module.context"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "utils/hooks/use-media-query"
import moment from "moment/moment"
import { CurrentPosition, FormerPosition } from "./CompanyPersonRecord"
import { messages } from "setup/messages/messages"

export type FormerCompanyPersonRecordProps = {
  personId: string
  dataPoolId?: string
  name: string
  location: string
  currentPositions: CurrentPosition[]
  formerPositions: FormerPosition[]
  photo?: string
  isOfflimits?: boolean
}

export const FormerCompanyPersonRecord = React.memo(
  ({
    personId,
    name,
    currentPositions,
    formerPositions,
    photo,
    location,
    isOfflimits = false
  }: FormerCompanyPersonRecordProps) => {
    const { setSelectedKnownEmployeeId } = useCompany()
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const handleToggleButton = useCallback(
      async (e: Event) => {
        setIsExpanded(!isExpanded)
        e.stopPropagation()
      },
      [isExpanded, setIsExpanded]
    )

    const navigate = useNavigate()

    const personUrl = useMemo(
      () => `${RouterUrl.ProfileDetails}/${personId}`,
      [personId]
    )

    const mediaQuery = getMinWidthMediaQuery("md")
    const isLarge = useMediaQuery(mediaQuery)

    const mediaQuerySmall = getMinWidthMediaQuery("sm")
    const isSmall = useMediaQuery(mediaQuerySmall)

    const onClickPerson = () => {
      isLarge ? setSelectedKnownEmployeeId(personId) : navigate(personUrl)
    }

    return (
      <CompanyPersonRecordBox onClick={onClickPerson}>
        <PersonInfoWrapper isOfflimits={isOfflimits}>
          {isOfflimits && (
            <OffLimits isSmall isCircle isLeftCircle={!isSmall}>
              {messages.offLimits.offLimits}
            </OffLimits>
          )}
          <Flex alignItems="center" pl="xxs" py="xs">
            <Div height="40px" width="40px" minHeight="40px" minWidth="40px">
              <Avatar
                photo={{ url: photo }}
                data-e2e-target-name={`person-${name}-avatar`}
                name={name}
                disableHoverOpacity
              />
            </Div>
            <Flex alignItems="center" ml="xxs">
              <Link
                mb="none"
                fontWeight={600}
                mr="xxs"
                onClick={onClickPerson}
                target="_blank"
              >
                {name}
              </Link>
              <ConnectedIcon width={spacing.m} />
            </Flex>
          </Flex>
        </PersonInfoWrapper>
        <CompanyPersonData>
          <BodyText my={0}>{location}</BodyText>
        </CompanyPersonData>
        <FormerCompanyPersonData>
          {formerPositions
            ?.slice(0, isExpanded ? formerPositions.length : 1)
            .map((position: FormerPosition) => (
              <BodyText my={0}>
                {position.title}&nbsp;
                {(position.startDate || position.endDate) && (
                  <SmallText>
                    (
                    {position.startDate &&
                      moment(position.startDate).format("MMM YYYY")}
                    -
                    {position.endDate &&
                      moment(position.endDate).format("MMM YYYY")}
                    )
                  </SmallText>
                )}
              </BodyText>
            ))}
        </FormerCompanyPersonData>
        <FormerCompanyCurrentData>
          {Boolean(currentPositions?.length) && (
            <Span my={0} fontWeight="bold">
              {messages.generic.current}:&nbsp;
            </Span>
          )}
          <Flex alignItems="flex-start">
            <Flex flexDirection="column">
              {currentPositions
                ?.slice(0, isExpanded ? currentPositions.length : 1)
                .filter((position) => position)
                .map((position: CurrentPosition, index: number) => (
                  <BodyText my={0}>
                    <Span>
                      {messages.assignment.roleAtCompany.format(position.title)}
                    </Span>
                    &nbsp;
                    {isNotNullOrZero(position.companyId) ? (
                      <Link
                        fontWeight={600}
                        underline={false}
                        underlineHover={true}
                        variant="green-dark"
                        href={`${RouterUrl.CompanyDetails}/${position.companyId}`}
                        target="_blank"
                        size={null}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        {position.companyName}
                      </Link>
                    ) : (
                      <Span>{position.companyName}</Span>
                    )}
                    &nbsp;
                    <SmallText>
                      (
                      {position.startDate &&
                        moment(position.startDate).format("MMM YYYY")}{" "}
                      - {messages.generic.present})
                    </SmallText>
                  </BodyText>
                ))}
            </Flex>
            {currentPositions?.length > 1 && (
              <Flex ml="xxs" mt="-4px">
                <Button
                  mode="standard-grey-light"
                  size="action-small"
                  onClick={handleToggleButton}
                >
                  <DropdownArrow
                    fill="currentColor"
                    width={12}
                    height={12}
                    style={{
                      transform: `rotate(${isExpanded ? 0 : "180deg"})`
                    }}
                  />
                </Button>
              </Flex>
            )}
          </Flex>
        </FormerCompanyCurrentData>
      </CompanyPersonRecordBox>
    )
  }
)
