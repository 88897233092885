import { useCallback, useState, useEffect } from "react"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { LocalPerson } from "models/LocalPerson/localPerson.definitions"
import { useNewContactModal } from "../../hooks"
import { PersonSearchResult } from "views/persons/pages/search-person/search-person/search-person.definitions"
import { fetchPersonPhotos } from "models/LocalPerson/localPerson.actions"

const formatResults = (results: LocalPerson[]) =>
  results?.map((person) => new LocalPersonModel(person).getPersonValues())

export const usePossibleDuplicates = (
  name: string,
  assignmentId: string,
  campaignId: string
) => {
  const { createContact } = useNewContactModal()
  const [isLoading, setIsLoading] = useState(true)
  const [duplicates, setDuplicates] = useState<PersonSearchResult[]>([])

  const fetchDuplicates = useCallback(async () => {
    setIsLoading(true)
    const [, response] = await apiRequest.get({
      endpoint: PersonsEndpoints.Search,
      config: {
        params: {
          searchString: name,
          page: 1,
          pageSize: 10
        }
      }
    })

    const persons = formatResults(response?.data?.persons || [])

    if (persons.length === 0) {
      createContact(name, assignmentId, campaignId)
    } else {
      setDuplicates(persons)
      setIsLoading(false)
      const peopleListId = persons.map((person) => person.id)
      const photoList = await fetchPersonPhotos(peopleListId)
      const personsWithPhoto = persons.map((person) => {
        const photo = photoList.find(
          (photo) => photo.personId === person.id
        )?.photo
        person.photo = { url: photo }
        return person
      })

      setDuplicates(personsWithPhoto)
    }
  }, [assignmentId, campaignId, createContact, name])

  useEffect(() => {
    fetchDuplicates()
  }, [fetchDuplicates])

  return { isLoading, duplicates, setIsLoading }
}
