import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { ThreeDotsIcon } from "@ikiru/talentis-fpc"
import { Flex } from "@ikiru/talentis-fpc"
import { InteractiveSignet } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import { colors, spacing } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { preventDefault } from "utils/prevent-default"
import {
  HeaderLink,
  HeaderAction,
  HeaderSetting,
  HeaderOption
} from "components/navigation/constants/navigation.definitions"
import {
  Header as StyledHeader,
  ActionWrapper,
  ActionIcon
} from "./Header.styled"
import { OptionsPopup } from "./components/OptionsPopup"

type HeaderProps = {
  menuItems: HeaderLink[]
  actions?: HeaderAction[]
  settings?: HeaderSetting[]
  options?: HeaderOption[]
  homepageDisabled?: boolean
  children?: never
}

export const Header = React.memo(
  ({
    homepageDisabled,
    menuItems,
    actions = [],
    options = []
  }: HeaderProps) => {
    const [isPopupOpen, setPopupOpen] = useState(false)

    return (
      <StyledHeader>
        <NavLink
          {...(homepageDisabled
            ? {
                onClick: preventDefault
              }
            : {})}
          to={RouterUrl.Homepage}
        >
          <InteractiveSignet opacity={homepageDisabled ? 0.3 : 1} height={30} />
        </NavLink>
        <Flex alignItems="center">
          <Flex>
            {menuItems.map(({ icon, path, e2eTargets, disabled, label }) => (
              <ActionWrapper
                label={label}
                key={path}
                as={NavLink}
                to={path}
                data-e2e-target={e2eTargets?.e2eTarget}
                data-e2e-target-name={e2eTargets?.e2eTargetName}
                disabled={disabled}
                {...(disabled
                  ? {
                      onClick: preventDefault
                    }
                  : {})}
              >
                <ActionIcon title={label} size={31}>
                  {icon}
                </ActionIcon>
              </ActionWrapper>
            ))}
          </Flex>
          <Flex>
            {actions.map(
              ({ label, icon, path, onClick, e2eTargets, disabled }, index) => (
                <ActionWrapper
                  key={`${label}_${index}`}
                  as={NavLink}
                  to={path || "#"}
                  onClick={disabled ? preventDefault : onClick}
                  data-e2e-target={e2eTargets?.e2eTarget}
                  data-e2e-target-name={e2eTargets?.e2eTargetName}
                >
                  <Button
                    disabled={disabled}
                    mode="standard-green"
                    size="action-small"
                  >
                    {icon}
                  </Button>
                </ActionWrapper>
              )
            )}
          </Flex>

          <OptionsPopup
            alignSelf="center"
            display="flex"
            ml="s"
            config={{
              PopupProps: {
                position: "bottom right",
                onOpen: () => setPopupOpen(true),
                onClose: () => setPopupOpen(false)
              }
            }}
            trigger={
              <Flex
                p={3}
                bg={isPopupOpen ? colors.grey.light : colors.transparent}
                borderRadius={spacing.xxs}
                style={{ cursor: "pointer" }}
                data-e2e-target-name="mobile-header-options"
              >
                <ThreeDotsIcon fill={colors.grey.dark} height={24} />
              </Flex>
            }
            options={options || []}
          />
        </Flex>
      </StyledHeader>
    )
  }
)
