import {
  TaskManagementState,
  TaskManagementActionTypes,
  TaskManagementContextValues
} from "./task-management-module.types"
import { CandidateFilter } from "../../assignments/assignment-module/assignment-module.types"

export const initialTaskManagementState: TaskManagementState = {
  isLoading: false,
  totalItemCount: 0,
  totalFilteredCount: 0,
  pageNumber: 1,
  pageSize: 25,
  appliedFilters: [],
  dueDates: {},
  isAssignment: true,
  selectedTab: "today",
  selectedRecordId: "",
  tasks: [],
  allAvailableFilters: {
    assignmentId: [],
    stage: [],
    status: [],
    assignedTo: null
  },
  filterTotalNumber: 0,
  filteredTasks: null,
  overlayTaskIndex: 0
}

export const taskManagementStateReducer = (
  state: TaskManagementState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action
  switch (type) {
    case TaskManagementActionTypes.SetIsLoading:
      return { ...state, isLoading: payload }
    case TaskManagementActionTypes.SetPageNumber: {
      return { ...state, pageNumber: payload }
    }
    case TaskManagementActionTypes.SetTasksCount:
      return {
        ...state,
        totalItemCount: payload?.totalItems || 0,
        totalFilteredCount: payload?.totalFilteredCount || 0
      }
    case TaskManagementActionTypes.SetAllAvailableFilters: {
      return { ...state, allAvailableFilters: payload }
    }
    case TaskManagementActionTypes.SetApplyFilters:
      const newFilters: CandidateFilter[] = payload.candidateFilters || []
      // const filteredCandidates = filterCandidates(state.candidates, newFilters)

      return {
        ...state,
        appliedFilters: newFilters
        // filteredCandidates: newFilters.length > 0 ? filteredCandidates : [],
        // groupedCandidates: groupCandidates(filteredCandidates)
      }
    case TaskManagementActionTypes.SetDueDates: {
      return { ...state, dueDates: payload }
    }
    case TaskManagementActionTypes.SetIsAssignment: {
      return { ...state, isAssignment: payload }
    }
    case TaskManagementActionTypes.SetSelectedTab: {
      return { ...state, selectedTab: payload }
    }
    case TaskManagementActionTypes.SetSelectedRecordId: {
      return { ...state, selectedRecordId: payload }
    }
    case TaskManagementActionTypes.SetTasks:
      return {
        ...state,
        tasks: payload || []
      }
    case TaskManagementActionTypes.SetOverlayTaskIndex:
      return {
        ...state,
        overlayTaskIndex: payload
      }
    default:
      return state
  }
}

export const initialTaskManagementContextValues: TaskManagementContextValues = {
  ...initialTaskManagementState,
  setPageNumber: () => {},
  setIsAssignment: () => {},
  setSelectedTab: () => {},
  updateTask: () => {},
  updateFilters: () => {},
  setSelectedRecordId: () => {},
  getTaskList: () => {},
  setTasks: () => {},
  setOverlayTaskIndex: () => {}
}
