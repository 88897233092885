import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useLocation } from "react-router-dom"
import { matchPath } from "react-router"
import { Navigation } from "components/navigation"
import { GoogleSearch } from "components/google-search"
import { Div, Flex, getMinWidthMediaQuery } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { useAuth } from "setup/auth/module/auth.context"
import { useMediaQuery } from "utils/hooks/use-media-query"
import * as Styled from "./layout.styled"
import { navigationSelectors, errorToastConfig } from "./definitions"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { GeneralErrorAlert } from "setup/alert/GeneralErrorAlert"
import { BsFillXCircleFill } from "react-icons/bs"
import { setupUserGuiding } from "setup/userGuiding"

type LayoutProps = {
  children: React.ReactNode
}

const pagesWithGoogleBox = [RouterUrl.ProfileSearch]

const pagesWithoutNavigation = [
  RouterUrl.Signup,
  RouterUrl.RegisterUser,
  RouterUrl.Logout,
  RouterUrl.AssignmentPortalConfig
]

const pagesWithoutPadding = [
  RouterUrl.Homepage,
  RouterUrl.VerifySearchFirm,
  RouterUrl.Logout
]

export const Layout = ({ children }: LayoutProps) => {
  const mediaQuery = getMinWidthMediaQuery("md")
  const isLarge = useMediaQuery(mediaQuery)
  const { isLoggedIn } = useAuth()
  const location = useLocation()
  const mainLayoutRef = useRef<HTMLDivElement>(null)
  const isGoogleBoxVisible = useMemo(
    () =>
      isLarge &&
      isLoggedIn &&
      pagesWithGoogleBox.some((path) => matchPath(location.pathname, path)),
    [location.pathname, isLoggedIn, isLarge]
  )

  const isNavigationVisible = useMemo(
    () =>
      !pagesWithoutNavigation.some((path) =>
        matchPath(location.pathname, path)
      ),
    [location.pathname]
  )

  const isPageWithoutPadding = useMemo(
    () => pagesWithoutPadding.some((path) => location.pathname === path),
    [location.pathname]
  )

  useEffect(() => {
    setupUserGuiding(location)
    mainLayoutRef.current?.scrollTo?.({ top: 0, behavior: "smooth" })
  }, [location, mainLayoutRef])

  const CloseButton = ({ closeToast }: any) => (
    <BsFillXCircleFill size={30} color="red" onClick={closeToast} />
  )

  const listener = useCallback((data: any) => {
    const hasError = data.detail as boolean
    hasError && toast(<GeneralErrorAlert />, errorToastConfig)
  }, [])

  useEffect(() => {
    document.addEventListener("generalError", listener)
    return () => {
      document.removeEventListener("generalError", listener)
    }
  }, [location, listener])

  return isNavigationVisible ? (
    <Styled.LayoutWrapper>
      <Styled.NavigationWrapper isSidebar={Boolean(isLarge)}>
        <Navigation isLarge={Boolean(isLarge)} />
      </Styled.NavigationWrapper>
      <Styled.MainWrapper
        id={navigationSelectors.mainContainer}
        ref={mainLayoutRef}
        isSidebar={Boolean(isLarge)}
      >
        {isGoogleBoxVisible && (
          <Div>
            <GoogleSearch />
          </Div>
        )}
        <Styled.LayoutMain noPadding={Boolean(isPageWithoutPadding)}>
          <Styled.PageContentWrapper noPadding={Boolean(isPageWithoutPadding)}>
            <ToastContainer
              style={{ width: "400px" }}
              closeButton={CloseButton}
            />
            {children}
          </Styled.PageContentWrapper>
        </Styled.LayoutMain>
      </Styled.MainWrapper>
    </Styled.LayoutWrapper>
  ) : (
    <Flex overflow="hidden" flexDirection="column">
      <ToastContainer style={{ width: "400px" }} closeButton={CloseButton} />
      {children}
    </Flex>
  )
}
