import React from "react"
import { Button, ButtonProps } from "@ikiru/talentis-fpc"

type ActionButtonProps = {
  label: string
  onClick?: () => void
  prefix?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
  suffix?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
} & ButtonProps

const ActionButton = ({
  label,
  onClick,
  suffix,
  prefix,
  ...other
}: ActionButtonProps) => {
  return (
    <Button
      size="small"
      mode="standard-green"
      paddingRight={Boolean(prefix) ? "s" : null}
      px={Boolean(suffix) ? "m" : null}
      suffix={suffix}
      prefix={prefix}
      onClick={onClick}
      {...other}
    >
      {label}
    </Button>
  )
}

export default ActionButton
