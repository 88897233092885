import * as Yup from "yup"

export enum SaveSearchFormFields {
  SearchName = "name",
  SearchDescription = "description",
  DefaultSearch = "defaultSearch",
  DefaultSearchChangers = "defaultSearchChangers"
}

export type SaveSearchForm = {
  [SaveSearchFormFields.SearchName]: string
  [SaveSearchFormFields.SearchDescription]: string
}

export const initialValues: SaveSearchForm = {
  [SaveSearchFormFields.SearchName]: "",
  [SaveSearchFormFields.SearchDescription]: ""
}

export const saveSearchFormValidationSchema = Yup.object({
  [SaveSearchFormFields.SearchName]: Yup.string()
    .trim()
    .required("Search name is a required field."),
  [SaveSearchFormFields.SearchDescription]: Yup.string().trim()
})
