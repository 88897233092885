import { colors, Flex } from "@ikiru/talentis-fpc"
import styled from "styled-components"

type LinkContainerStyledProps = {
  isForCompany: boolean
}
export const LinkContainerStyled = styled(Flex)<LinkContainerStyledProps>`
  padding: ${({ theme }) => theme.space.xs}px;
  background-color: ${colors.green.lightest};
  flex-direction: column;
  position: relative;

  ${({ isForCompany }) =>
    isForCompany
      ? `margin-top: -10px;
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100% + 40px);`
      : ``}
`
