import { messages } from "setup/messages/messages"
import { DistanceUnit } from "views/search/SearchModule/types"

export const minSearchQueryLength = 2

const distances = [0, 10, 20, 30, 40, 50, 75, 100, 200, 300, 400, 500, 1000]

export const distanceOptions = distances.map((value) => ({
  label: `${value}`,
  value
}))

export const unitOptions = [
  {
    value: DistanceUnit.KM,
    label: messages.talentGraphSearch.distanceUnits.KM
  },
  {
    value: DistanceUnit.Miles,
    label: messages.talentGraphSearch.distanceUnits.Miles
  }
]
