import React, { useMemo } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { Flex, H4 } from "@ikiru/talentis-fpc"
import { Button } from "@ikiru/talentis-fpc"
import {
  PersonAssignments,
  personAssignmentsE2ETargets,
  getPersonAssignmentsInitialValues
} from "views/persons/components/person-assignments/form/constants/add-person-assignment.definitions"
import { messages } from "setup/messages/messages"
import { OnAddPersonAssignment } from "views/persons/components/person-assignments/form/actions"
import { PersonAssignmentOption } from "views/persons/components/person-assignments/types"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { ModalFormContainer } from "../../person-details-tab/components/person-details-section/form/style"

type AddPersonAssignmentProps = {
  onSubmit: OnAddPersonAssignment
  assignments: PersonAssignmentOption[]
  isLoading: boolean
  project?: string
}

export const AddPersonAssignment = (props: AddPersonAssignmentProps) => {
  const { assignments, onSubmit, isLoading, project } = props

  const availableAssignments = assignments?.filter(
    (assignment) => !assignment.disabled
  )

  const projectStorageKey = useMemo(() => {
    if (project) return `${project}Assignment`
    const urlParams = new URLSearchParams(window.location.search)
    const projectUrl = urlParams.get("project")

    return `${projectUrl}Assignment`
  }, [project])

  const storedValue = localStorage.getItem(projectStorageKey)
  const projectValue =
    storedValue !== null && storedValue !== "null" ? storedValue : ""

  const initialValues = getPersonAssignmentsInitialValues(
    projectValue ||
      (availableAssignments.length === 1 ? availableAssignments[0].value : "")
  )

  const { close } = useModal()
  const closeModal = () => close(ModalNames.LinkAssignmentOrCampaign)

  const assignmentsExist = assignments.length

  const options = isLoading
    ? [{ label: messages.generic.loading }]
    : !assignmentsExist
    ? [{ label: messages.person.assignments.noAssignmentsMessage }]
    : availableAssignments.length > 1
    ? [
        {
          label: messages.person.assignments.selectAssignment,
          value: "",
          disabled: true
        },
        ...assignments
      ]
    : assignments

  const submitForm = (values: any, actions: FormikHelpers<any>) => {
    localStorage.setItem(projectStorageKey, values.assignments)
    onSubmit(values, actions)
  }

  return (
    <ModalFormContainer defaultWidth={480}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submitForm}
      >
        {({ isSubmitting, values }) => {
          const actionPending = isLoading || isSubmitting
          const disableButton =
            actionPending ||
            !assignmentsExist ||
            !availableAssignments?.length ||
            !values.assignments
          const disableSelect = actionPending || !assignmentsExist
          return (
            <Form>
              <Flex justifyContent="space-between" alignItems="center" mb="m">
                <H4 m="0">{messages.person.assignments.linkAssignment}</H4>
                <Flex>
                  <Button
                    disabled={disableButton}
                    size="small"
                    type="submit"
                    mt="xs"
                    mr="xxs"
                    data-e2e-target={personAssignmentsE2ETargets.submitButton}
                  >
                    {messages.person.assignments.linkToAssignment}
                  </Button>
                  <Button
                    mode="standard-white"
                    size="small"
                    type="button"
                    mt="xs"
                    onClick={closeModal}
                  >
                    {messages.generic.cancel}
                  </Button>
                </Flex>
              </Flex>
              <FormikSelect
                label={messages.form.generic.assignmentName}
                disabled={disableSelect}
                id={PersonAssignments.Assignments}
                name={PersonAssignments.Assignments}
                data-e2e-target-name={
                  personAssignmentsE2ETargets.assignmentsSelect
                }
                options={options}
              />
            </Form>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}
