import React from "react"
import {
  BodyText,
  Link,
  DatepickerPositiveIcon as PositiveIcon,
  Flex,
  SmallText,
  colors,
  fontFamilies
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import {
  SaveSearchText,
  SavedSearchBox,
  SaveSearchDescription,
  SavedSearchCreateDate,
  SavedSearchInfo,
  IconContainer
} from "./style"
import { formatDate } from "utils/format-date"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { SavedSearch } from "../../SearchModule/types"
import { DropdownArrowIcon as DropdownArrow } from "@ikiru/talentis-fpc"
import { OptionsPopup } from "components/visual/header/components/OptionsPopup"
import ActionButton from "components/ProjectActions/ActionButton"

interface SavedSearchRecordProps {
  showSavedSearch: (searchId: string) => void
  setDefaultSearch: (searchId: string, isDefault: boolean) => void
  remove: (searchId: string) => void
  search: SavedSearch
}

export const SavedSearchRecord = ({
  showSavedSearch,
  search,
  remove,
  setDefaultSearch
}: SavedSearchRecordProps) => {
  const { id, name, description, createdDate, isDefault } = search
  const { close } = useModal()

  const pressSaveSearches = () => {
    close(ModalNames.LoadSaveSearches)
    showSavedSearch(id)
  }

  const options = [
    {
      label: isDefault
        ? messages.talentGraphSearch.savedSearch.removeDefaultSearchScreen
        : messages.talentGraphSearch.savedSearch.setDefaultSearchScreen,
      onClick: () => {
        if (isDefault) {
          setDefaultSearch(id, false)
        } else {
          setDefaultSearch(id, true)
        }
      }
    },
    /*    {
      label: "Set as default for Job Changers Dashboard",
      onClick: () => {}
    },*/
    {
      label: "Delete search",
      onClick: () => {
        remove(id)
      }
    }
  ]
  return (
    <SavedSearchBox>
      <Flex
        py={["s", "", "none"]}
        px={["xs", "", "none"]}
        flexDirection={["column", "column", "row"]}
      >
        <SaveSearchText>
          <Link fontWeight={600} onClick={pressSaveSearches}>
            {name}
          </Link>
        </SaveSearchText>
        <SaveSearchDescription>
          <BodyText my={0}>{description}</BodyText>
        </SaveSearchDescription>
        <OptionsPopup
          alignSelf="center"
          display="flex"
          ml="s"
          mr="s"
          config={{
            PopupProps: {
              position: "bottom right"
            }
          }}
          trigger={
            <Flex>
              <ActionButton
                label={messages.assignment.actions}
                suffix={
                  <DropdownArrow fill="currentColor" width={12} height={12} />
                }
              />
            </Flex>
          }
          options={options || []}
        />
      </Flex>
      <SavedSearchCreateDate>
        <SmallText mb="none" display="flex" alignItems="center">
          {messages.talentGraphSearch.savedSearch.savedSearchData}{" "}
          {formatDate(createdDate)}
        </SmallText>
        <Flex>
          {isDefault && (
            <SavedSearchInfo>
              <IconContainer>
                <PositiveIcon />{" "}
              </IconContainer>
              <SmallText
                p="none"
                m="none"
                fontWeight="500"
                color={colors.grey.standard}
                fontFamily={fontFamilies.gibson}
              >
                {messages.talentGraphSearch.savedSearch.defaultSearchTitle}
              </SmallText>
            </SavedSearchInfo>
          )}
        </Flex>
      </SavedSearchCreateDate>
    </SavedSearchBox>
  )
}
