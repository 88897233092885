import React, { useCallback, useMemo } from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { DistanceUnit, FilterType } from "views/search/SearchModule/types"
import { useSearch } from "views/search/SearchModule/context"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { FormikMultiselect } from "components/functional/formik/formik-multiselect/FormikMultiselect"
import { distanceOptions, unitOptions } from "./consts"
import {
  generateLabel,
  isValidSearchValue,
  loadAzureLocationsOptions
} from "components/AzureLocation/helper"
import { initialValues, SearchCriteriaField } from "../definitions"
import { useSelectFieldChange, useTypeaheadFieldChange } from "../hooks"

export const LocationFilter = () => {
  const { filters, updateFilters } = useSearch()

  const onLocationChange = useCallback(
    (values: unknown[]) => {
      if (values.length !== 1) {
        updateFilters({
          [FilterType.Distance]:
            initialValues?.[SearchCriteriaField.LocationDistance],
          [FilterType.DistanceUnit]:
            initialValues?.[SearchCriteriaField.LocationDistanceUnit]
        })
      }
    },
    [updateFilters]
  )

  const onChange = useTypeaheadFieldChange(
    FilterType.Locations,
    onLocationChange
  )

  const onDistanceChange = useSelectFieldChange(FilterType.Distance)
  const onUnitChange = useSelectFieldChange(FilterType.DistanceUnit)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const locations = filters?.[FilterType.Locations] || []

  const selectedLocations = useMemo(
    () => locations.map((l) => ({ value: l, label: generateLabel(l) })),
    [locations]
  )

  const loadOptions = (inputValue: string) =>
    loadAzureLocationsOptions(inputValue, locations)

  const onKeyDown = (e: any) => {
    const options = document.querySelectorAll(
      '[data-e2e-target="multiselect-option"]'
    )

    if (e.keyCode === 13 && !options.length) e.preventDefault()
  }

  return (
    <Flex width="100%" flexWrap="wrap">
      <Div width="100%">
        <FormikMultiselect
          controlled
          tagVariant="grey-dark"
          id={SearchCriteriaField.Location}
          name={SearchCriteriaField.Location}
          label={messages.talentGraphSearch.filters.location}
          loadOptions={loadOptions}
          validateRequest={isValidSearchValue}
          onChange={onChange}
          value={selectedLocations}
          onKeyDown={onKeyDown}
        />
      </Div>
      {locations.length < 2 && (
        <Flex width="100%">
          <Div width="60%">
            <FormikSelect
              controlled
              disabled={locations.length === 0}
              name={SearchCriteriaField.LocationDistance}
              id={SearchCriteriaField.LocationDistance}
              label={messages.talentGraphSearch.filters.distance}
              options={distanceOptions}
              onChange={onDistanceChange}
              value={filters?.[FilterType.Distance] || 0}
            />
          </Div>
          <Div width="40%" ml="xxs">
            <FormikSelect
              controlled
              disabled={locations.length === 0}
              name={SearchCriteriaField.LocationDistanceUnit}
              id={SearchCriteriaField.LocationDistanceUnit}
              label={messages.talentGraphSearch.filters.unit}
              options={unitOptions}
              onChange={onUnitChange}
              value={filters?.[FilterType.DistanceUnit] || DistanceUnit.KM}
            />
          </Div>
        </Flex>
      )}
    </Flex>
  )
}
