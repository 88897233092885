import React, { useCallback, useState } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import {
  signupAutofillNames,
  signupE2ETargets
} from "views/users/signup/form/constants/signup.definitions"
import { signupValidationSchema } from "views/users/signup/form/constants/signup.schema"
import { messages } from "setup/messages/messages"
import { externalLinks } from "setup/externalLinks"
import {
  SignupField,
  SignupInitialValues
} from "views/users/signup/form/constants/signup.definitions"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { FormikPasswordInput } from "components/functional/formik/formik-password-input/FormikPasswordInput"
import { FormikSelect } from "components/functional/formik/formik-select/FormikSelect"
import { RecaptchaMessage } from "components/RecaptchaMessage/RecaptchaMessage"
import { onSignupSubmit } from "./signup.actions"
import { initialFormState, formState } from "utils/forms/form-state"
import { useCaptcha, CaptchaActions } from "utils/hooks/use-captcha"
import { transformedCountries } from "utils/transformer"
import { Button } from "@ikiru/talentis-fpc"
import { Error } from "@ikiru/talentis-fpc"
import { H1, LargeText, SmallText } from "@ikiru/talentis-fpc"
import { SmallTextStyle } from "@ikiru/talentis-fpc"
import { Div } from "@ikiru/talentis-fpc"
import { ErrorVerification } from "../components/ErrorVerification"
import { apiRequest } from "setup/api/api"
import { SearchFirmEndpoints } from "setup/api/endpoints/endpoints"
import { Link } from "@ikiru/talentis-fpc"
import { FormattedMessage } from "react-intl"

type SignupValues = typeof SignupInitialValues

type SignupFormProps = {
  onSuccess?: VoidFunction
}

export const Signup = ({ onSuccess }: SignupFormProps) => {
  const resendEmailVerification =
    (emailAddress: string) => async (callback: () => void) => {
      const [, response] = await apiRequest.put({
        endpoint: SearchFirmEndpoints.ResendConfirmation,
        data: {
          userEmailAddress: emailAddress
        }
      })
      response && callback()
    }
  const { getCaptchaPassed } = useCaptcha()
  const [isCaptchaErrorVisible, setIsCaptchaErrorVisible] =
    useState<boolean>(false)

  const onFormSubmit = useCallback(
    async (values: SignupValues, helpers: FormikHelpers<SignupValues>) => {
      const captchaPassed = await getCaptchaPassed(CaptchaActions.SignUp)
      if (captchaPassed) {
        onSignupSubmit(values, helpers, onSuccess)
      } else {
        setIsCaptchaErrorVisible(true)
      }
    },
    [getCaptchaPassed, onSuccess]
  )

  return (
    <>
      <Formik
        initialValues={SignupInitialValues}
        validationSchema={signupValidationSchema}
        onSubmit={onFormSubmit}
        initialStatus={initialFormState}
      >
        {(formik) => {
          const { isSubmitting, status, values, isValid, touched, errors } =
            formik

          // Formik parse boolean from response to string so it is the reason
          const isEmailNotVerified = Boolean(
            errors.emailConfirmed && errors.emailConfirmed === "false"
          )
          const isInitialUser = Boolean(errors.userType === "initialUser")
          const isInvitedUser = Boolean(errors.userType === "invitedUser")

          const isDisabled =
            isSubmitting ||
            !isValid ||
            isCaptchaErrorVisible ||
            Boolean(!Object.keys(touched).length)

          return !formState.isFinished(status) ? (
            <Div textAlign="center">
              <H1 mt="xs" mb="0" color="green.standard" fontWeight="500">
                <FormattedMessage
                  id="signupForTalentis"
                  defaultMessage={messages.signup.signupForTalentis}
                  values={{
                    freetrial: (
                      <>
                        <br />
                        <H1 color="green.standard" display="inline" m="0">
                          {messages.signup.freeTrial}
                        </H1>
                      </>
                    )
                  }}
                />
              </H1>

              <LargeText marginTop="0" marginBottom="s" color="grey.standard">
                {messages.signup.noCreditCardRequired}
              </LargeText>
              {isEmailNotVerified && (
                <ErrorVerification
                  isInitialUser={isInitialUser}
                  isInvitedUser={isInvitedUser}
                  onLinkClick={resendEmailVerification(values.userEmailAddress)}
                />
              )}
              <Div textAlign="left">
                <Form>
                  <FormikInput
                    autoComplete={
                      signupAutofillNames[SignupField.UserFirstName]
                    }
                    id={SignupField.UserFirstName}
                    name={SignupField.UserFirstName}
                    label={`${messages.form.generic.firstName}*`}
                  />
                  <FormikInput
                    autoComplete={signupAutofillNames[SignupField.UserLastName]}
                    id={SignupField.UserLastName}
                    name={SignupField.UserLastName}
                    label={`${messages.form.generic.lastName}*`}
                  />
                  <FormikInput
                    autoComplete={
                      signupAutofillNames[SignupField.SearchFirmName]
                    }
                    id={SignupField.SearchFirmName}
                    name={SignupField.SearchFirmName}
                    label={`${messages.form.generic.companyName}*`}
                  />
                  <FormikSelect
                    autoComplete={
                      signupAutofillNames[SignupField.SearchFirmCountryCode]
                    }
                    id={SignupField.SearchFirmCountryCode}
                    name={SignupField.SearchFirmCountryCode}
                    label={`${messages.form.generic.country}*`}
                    options={transformedCountries}
                    hideLabelIfEmpty
                    firstOption={{
                      label: `${messages.form.generic.country}*`,
                      value: ""
                    }}
                  />
                  <FormikInput
                    autoComplete={
                      signupAutofillNames[SignupField.UserEmailAddress]
                    }
                    id={SignupField.UserEmailAddress}
                    name={SignupField.UserEmailAddress}
                    label={`${messages.form.generic.emailAddress}*`}
                  />
                  <FormikPasswordInput
                    id={SignupField.UserPassword}
                    name={SignupField.UserPassword}
                    label={`${messages.form.generic.password}*`}
                    autoComplete="current-password"
                    labels={{
                      show: messages.form.generic.show,
                      hide: messages.form.generic.hide
                    }}
                  />
                  <RecaptchaMessage />

                  {isCaptchaErrorVisible && (
                    <>
                      <br />
                      <Error>{messages.form.generic.errors.captcha}</Error>
                    </>
                  )}
                  <Button
                    mt="xs"
                    width="100%"
                    type="submit"
                    disabled={isDisabled}
                  >
                    {messages.form.generic.signup}
                  </Button>
                  <Div
                    whiteSpace={["inherit", "nowrap"]}
                    textAlign="center"
                    mt="xs"
                  >
                    <SmallText>
                      {messages.signup.bySigningYouAgree}{" "}
                      <Link
                        href={externalLinks.termsAndConditions}
                        target="_blank"
                        style={{ ...SmallTextStyle, fontWeight: 600 }}
                      >
                        {messages.signup.termsOfUse}
                      </Link>{" "}
                      {messages.signup.and}{" "}
                      <Link
                        href={externalLinks.privacyPolicy}
                        target="_blank"
                        style={{ ...SmallTextStyle, fontWeight: 600 }}
                      >
                        {messages.signup.privacyPolicy}
                      </Link>
                    </SmallText>
                  </Div>
                </Form>
              </Div>
            </Div>
          ) : (
            <Div
              textAlign="center"
              data-e2e-target={signupE2ETargets.successMessage}
            >
              <H1 mt="xs" mb="l" color="green.standard">
                {messages.signup.verifyYourEmail}
              </H1>
              <LargeText color="grey.standard">
                {messages.signup.emailSentTo}{" "}
                {values[SignupField.UserEmailAddress]}.
              </LargeText>
            </Div>
          )
        }}
      </Formik>
    </>
  )
}
