import styled from "styled-components"
import { Div, SectionHeader, colors, fontFamilies } from "@ikiru/talentis-fpc"
import { hexToRGB } from "utils/hexToRGB"
import get from "lodash/get"
import { spanTagColor } from "./helpers"

type StyledSpanProps = {
  background: string
}

export const StyledSpan = styled.span<StyledSpanProps>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: -2px;
  background-color: ${({ theme, background }) =>
    get(theme.colors, spanTagColor(background))};
`

export const CandidateFilterContainer = styled(Div)`
  height: 50px;
  padding: ${({ theme }) => theme.space.s}px;
  background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.5)};
  position: relative;
`
export const StyledSectionHeader = styled(SectionHeader)`
  padding-left: 20px;
  h2 {
    font-size: 18px;
    color: ${colors.grey.dark};
    font-family: ${fontFamilies.gibson};
    font-weight: 500;
  }
`
