import { BodyText, Flex } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const FinishedSearchContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 0px ${({ theme }) => theme.space.xl}px 0px;
`
export const CustomBodyText = styled(BodyText)`
  margin: ${({ theme }) => theme.space.xs}px 0px 25px 0px;
  text-align: center;
  padding: 0px 55px;
`
export const BoldText = styled.span`
  font-weight: 600;
  margin: 0px;
`
