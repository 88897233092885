import { useCallback, useMemo } from "react"
import { useSubscriptionPurchase } from "views/subscription/subscription-purchase-module/subscription-purchase-module.context"
import { Frequency } from "views/subscription/components/plan-details/form/constants/plan-details.definitions"
import { apiRequest } from "setup/api/api"
import { PaymentEstimateResponse } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"
import { SubscriptionEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { messages } from "setup/messages/messages"

export const useEstimate = () => {
  const { purchaseData, fetchEstimate } = useSubscriptionPurchase()

  const { totalUsers, appliedCouponCodes, planId } = useMemo(() => {
    const plan =
      purchaseData.planDetails?.frequency === Frequency.Year
        ? purchaseData.plan?.yearly
        : purchaseData.plan?.monthly || null

    return {
      totalUsers: purchaseData.planDetails?.totalUsersNumber || 0,
      appliedCouponCodes: purchaseData.couponsUsed?.map((c) => c.code) || [],
      planId: plan?.id || ""
    }
  }, [purchaseData])

  const updateEstimate = useCallback(
    async (
      countryCode: string,
      postcode: string,
      vatNumber: string | undefined
    ) => {
      await fetchEstimate(
        planId,
        new Date().toISOString(),
        totalUsers,
        appliedCouponCodes,
        countryCode,
        postcode,
        vatNumber
      )
    },
    [fetchEstimate, totalUsers, appliedCouponCodes, planId]
  )

  const putAndValidateAddressAndVAT = async (
    billingAddressCountryCode: string,
    billingAddressZipOrPostCode: string,
    billingAddressLine1: string,
    billingAddressCity: string,
    billingAddressEmail: string,
    customerVatNumber?: string,
    billingAddressPhone?: string
  ) => {
    const [error, response] = await apiRequest.put<PaymentEstimateResponse>({
      endpoint: SubscriptionEndpoints.UpdateCustomer,
      data: {
        billingAddressCountryCode,
        billingAddressZipOrPostCode,
        billingAddressLine1,
        billingAddressCity,
        billingAddressEmail,
        customerVatNumber: customerVatNumber || "",
        billingAddressPhone: billingAddressPhone || ""
      },
      config: {
        headers: { ...skipErrorHeader }
      }
    })

    if (error) {
      if (error.status === 400) {
        throw new Error(
          Object.values(error.data.errors)
            .map((message) => {
              return (message as string).includes(
                "Cannot update customer VAT number"
              )
                ? messages.subscription.paymentError.invalidVAT
                : message
            })
            .join(" ")
        )
      }

      throw new Error(messages.generic.errorTryAgain)
    }

    if (!response) {
      throw new Error(messages.generic.errorTryAgain)
    }

    return response.data || null
  }

  return { updateEstimate, putAndValidateAddressAndVAT }
}
