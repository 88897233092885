import React from "react"
import { BodyText, Div, H5 } from "@ikiru/talentis-fpc"
import { DragItemIcon, ItemCardContainer, ItemCardWrapper } from "./style"
import { ShowMore } from "components/ShowMore"
import { OnDropProps } from "components/FPCComponents/DragAndDrop/CustomDnD/DropContentItem/DragContentItem"
import { Experience } from "views/persons/person.types"
import { DragType, DraggableDataName } from "../../constants/definition"
import ParsedDragItemWrapper from "./ParsedDragItemWrapper"
import { getEndDate, getStartDate } from "./utils"
import useHandleDndItem from "../../hooks/useHandleDndItem"
import { sortDragAndDropItems } from "../../../utils"

type DragItemProps = {
  isDragging?: boolean
  id: string
  description: string
  changedValues: Partial<Experience>
  setComponentHover: React.Dispatch<React.SetStateAction<string>>
} & Experience

const DragItemParsedData = ({
  isDragging = false,
  id,
  description,
  company,
  title,
  startDate,
  endDate,
  changedValues
}: DragItemProps) => {
  const {
    items,
    setItems: setDragItems,
    updateTempPersonData
  } = useHandleDndItem({
    dragType: "experience"
  })

  const setItems = (transferData: OnDropProps) => {
    const { locationId, movingId, locationName } = transferData

    const newData = items.map((item) => {
      const newContent = items.find((item) => item.id === movingId)
      if (item.id === locationId) {
        if (locationName === DraggableDataName.Date) {
          item.itemComponentProps.changedValues.startDate =
            newContent?.itemComponentProps.startDate
          item.itemComponentProps.changedValues.endDate =
            newContent?.itemComponentProps.endDate
        } else {
          item.itemComponentProps.changedValues[locationName] =
            newContent?.itemComponentProps[locationName]
        }
      }
      return item
    })

    const sortedItems = sortDragAndDropItems(newData)
    updateTempPersonData(sortedItems)

    setDragItems(sortedItems)
  }

  return (
    <ItemCardWrapper isDragging={isDragging} isExistingData={false}>
      <ItemCardContainer>
        <H5 m="none" px="xxs">
          {company}
        </H5>

        <ParsedDragItemWrapper
          id={id}
          setItems={setItems}
          componentName={DraggableDataName.Title}
          dragType={DragType.ParsedTitle}
          content={changedValues.title || title}
        >
          <H5 m="none">{changedValues.title || title}</H5>
        </ParsedDragItemWrapper>
        <ParsedDragItemWrapper
          id={id}
          setItems={setItems}
          componentName={DraggableDataName.Date}
          dragType={DragType.ParsedDate}
          content={`${getStartDate(
            changedValues.startDate || startDate
          )} - ${getEndDate(
            changedValues.endDate || endDate,
            changedValues.startDate || startDate
          )}`}
        >
          <BodyText m="none">
            {`${getStartDate(
              changedValues.startDate || startDate
            )} - ${getEndDate(
              changedValues.endDate || endDate,
              changedValues.startDate || startDate
            )}`}
          </BodyText>
        </ParsedDragItemWrapper>
        <ParsedDragItemWrapper
          id={id}
          setItems={setItems}
          componentName={DraggableDataName.Description}
          dragType={DragType.ParsedDescription}
          content={changedValues.description || description}
        >
          <ShowMore
            visibleLines={3}
            description={changedValues.description || description}
          />
        </ParsedDragItemWrapper>
      </ItemCardContainer>
      <Div pt="xs" pr="xs">
        <DragItemIcon />
      </Div>
    </ItemCardWrapper>
  )
}

export default DragItemParsedData
