import React, { useEffect, useRef } from "react"
import { Overlay } from "@ikiru/talentis-fpc"
import { PersonOverlayContainer, PersonContent } from "./style"
import { useChangeItem } from "./hook/useChangeItem"
import PersonOverlay from "./index"
import OverlayNavigation from "./OverlayNavigation"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { getUpdatedPhotoAndCallBack } from "./utils"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"

type InternalSearchPersonOverlayProps = {
  handlePageChange: (page: number) => void
}

const InternalSearchPersonOverlay = ({
  handlePageChange
}: InternalSearchPersonOverlayProps) => {
  const {
    getProfileData,
    personOverlayUpdated,
    setPersonOverlayUpdated,
    profileDetails,
    person,
    personOverlayPhotoUpdated
  } = usePerson()

  const {
    isLoadingSearchResults,
    internalPersons,
    internalSearchPage,
    setSelectedInternalSearchPersonId,
    selectedInternalSearchPersonId,
    updatePerson
  } = useInternalSearch()

  const { directionNext, directionPrevious, isLastElement, isFirstElement } =
    useChangeItem(
      { handlePageChange: handlePageChange },
      internalSearchPage.pageNumber,
      internalSearchPage.pageSize,
      internalSearchPage.totalItemCount,
      internalPersons || [],
      selectedInternalSearchPersonId,
      setSelectedInternalSearchPersonId,
      internalSearchPage.pageCount
    )

  useEffect(() => {
    if (isLoadingSearchResults) return
    if (isLastElement) {
      setSelectedInternalSearchPersonId(internalPersons[0].id)
      getProfileData(internalPersons[0].id)
    } else if (isFirstElement) {
      setSelectedInternalSearchPersonId(
        internalPersons[internalSearchPage.pageSize - 1].id
      )
      getProfileData(internalPersons[internalSearchPage.pageSize - 1].id)
    }
  }, [
    internalPersons,
    getProfileData,
    isFirstElement,
    isLastElement,
    selectedInternalSearchPersonId,
    setSelectedInternalSearchPersonId,
    internalSearchPage.pageSize,
    isLoadingSearchResults
  ])

  let overLayRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (personOverlayUpdated) {
      setPersonOverlayUpdated(false)

      getUpdatedPhotoAndCallBack(
        person?.personId || selectedInternalSearchPersonId,
        personOverlayPhotoUpdated,
        (photoUrl) => {
          let updatedPerson = person!
          //@ts-ignore
          updatePerson({
            ...updatedPerson,
            ...(photoUrl && { photo: { url: photoUrl } })
          })
          setPersonOverlayUpdated(false)
        }
      )
    }
  }, [
    person,
    personOverlayPhotoUpdated,
    personOverlayUpdated,
    profileDetails,
    selectedInternalSearchPersonId,
    setPersonOverlayUpdated,
    updatePerson
  ])

  const closeOverlay = (e: any) => {
    if (!overLayRef?.current?.contains(e.target)) {
      setSelectedInternalSearchPersonId("")
    }
  }

  return (
    <PersonOverlayContainer onClick={closeOverlay}>
      <PersonContent ref={overLayRef}>
        <Overlay visible>
          <OverlayNavigation
            directionNext={directionNext}
            directionPrevious={directionPrevious}
            closeOverlay={() => setSelectedInternalSearchPersonId("")}
            oneContent
          />
          <PersonOverlay personId={selectedInternalSearchPersonId} />
        </Overlay>
      </PersonContent>
    </PersonOverlayContainer>
  )
}

export default InternalSearchPersonOverlay
