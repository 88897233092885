import { useCallback, useState } from "react"
import { apiRequest } from "setup/api/api"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { Candidate } from "views/candidates/candidates.types"
import { interviewProgressInitialValues } from "../candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import sortBy from "lodash/sortBy"
import { fetchPhotosAndSetUpdatedEntities } from "models/LocalPerson/localPerson.actions"
import { v4 } from "uuid"

export type CompanyCandidateStatuses = {
  archive: number
  firstClientInterview: number
  identified: number
  internalInterview: number
  offer: number
  placed: number
  screening: number
  secondClientInterview: number
  shortList: number
  source: number
  thirdClientInterview: number
}

export function updateCompanyIds(data: any) {
  //@ts-ignore
  return data.map((company) => {
    if (company.id === "00000000-0000-0000-0000-000000000000") {
      company.id = `gen-uuid-${v4()}`
    }
    return company
  })
}

const useAssignmentCompanies = () => {
  const [isLoadingCompanies, setLoadingCompanies] = useState(false)
  const [isLoadingCandidates, setLoadingCandidates] = useState(false)
  const {
    setCompanies,
    setCompanyPage,
    assignmentId,
    companyPage,
    setCompaniesCandidates,
    companiesCandidates
  } = useAssignment()

  const getCompanies = useCallback(
    async (page: number, isStage?: boolean) => {
      setLoadingCompanies(true)
      let params = new URLSearchParams()

      params.append("assignmentId", assignmentId)
      const pageSize = companyPage.pageSize || 10
      params.append("pageSize", pageSize.toString())
      params.append("page", page.toString())

      const [, response] = await apiRequest.get({
        endpoint: CandidatesEndpoints.CompaniesForCandidates,
        config: { params: params }
      })

      if (response && response.data.companies) {
        const tempCompanies = updateCompanyIds(response.data.companies) || []

        if (isStage) {
        }
        setCompanies({
          companies: tempCompanies,
          companiesCount: response?.data.totalItemCount
        })
        setCompanyPage({
          hasNextPage: response.data.hasNextPage,
          hasPreviousPage: response.data.hasPreviousPage,
          pageCount: response.data.pageCount,
          pageNumber: response.data.pageNumber,
          pageSize: response.data.pageSize,
          totalItemCount: response.data.totalItemCount,
          maxPages: 5
        })
      } else {
        setCompanies({
          companies: [],
          companiesCount: 0
        })
        setCompanyPage({
          hasNextPage: false,
          hasPreviousPage: false,
          pageCount: 0,
          pageNumber: 0,
          pageSize: 0,
          totalItemCount: 0,
          maxPages: 0
        })
      }

      setTimeout(() => {
        setLoadingCompanies(false)
      }, 1000)
    },
    [assignmentId, companyPage, setCompanies, setCompanyPage]
  )

  const getCompaniesCandidates = useCallback(
    async (companyId: string, candidatesId?: string[]) => {
      setLoadingCandidates(true)

      let params = new URLSearchParams()

      params.append("assignmentId", assignmentId)
      params.append("expand", "person")

      const pageSize = companiesCandidates?.companyId?.page?.pageSize || 1000
      // const numberPage =
      //   pageNumber || companiesCandidates?.companyId?.page?.pageNumber || 1

      params.append("pageSize", pageSize.toString())
      params.append("page", "1")

      let tempCandidates: any = {}
      if (companyId.includes("gen-uuid")) {
        const [, response] = await apiRequest.post({
          endpoint: CandidatesEndpoints.Filtered,
          data: {
            candidateId: candidatesId,
            assignmentId: [assignmentId],
            page: 1,
            pageSize: 25,
            expand: ["person"]
          }
        })
        tempCandidates = response?.data
      } else {
        params.append("companyId", companyId)
        const [, response] = await apiRequest.get({
          endpoint: CandidatesEndpoints.Root,
          config: { params: params }
        })
        tempCandidates = response?.data
      }

      let candidates = tempCandidates.candidates.map(
        (candidate: Candidate) => ({
          ...candidate,
          companyId: companyId,
          interviewProgressState:
            candidate.interviewProgressState || interviewProgressInitialValues,
          normalizedPersonData: new LocalPersonModel(
            candidate?.linkPerson
          ).getPersonValues()
        })
      )

      candidates = sortBy(
        candidates,
        [(candidate) => candidate.normalizedPersonData?.name.toLowerCase()],
        ["desc"]
      )

      const page = {
        hasNextPage: tempCandidates.hasNextPage,
        hasPreviousPage: tempCandidates.hasPreviousPage,
        pageCount: tempCandidates.pageCount,
        pageNumber: tempCandidates.pageNumber,
        pageSize: tempCandidates.pageSize,
        totalItemCount: tempCandidates.totalItemCount,
        maxPages: 5
      }

      fetchPhotosAndSetUpdatedEntities(candidates, (candidates) => {
        setCompaniesCandidates(companyId, candidates, page)
      })

      setTimeout(() => {
        setLoadingCandidates(false)
      }, 2000)
    },
    [assignmentId, companiesCandidates, setCompaniesCandidates]
  )

  return {
    isLoadingCompanies,
    isLoadingCandidates,
    getCompanies,
    getCompaniesCandidates
  }
}

export { useAssignmentCompanies }
