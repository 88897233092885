import { UserManager } from "oidc-client-ts"
import { TalentisUser } from "setup/auth/module/constants/auth.types"

type AuthReducerState = {
  isLoggedIn: boolean
  managerReady: boolean
  user?: TalentisUser
  manager?: UserManager
}

export enum AuthReducerActionTypes {
  UpdateUser = "update-user",
  UpdateManager = "update-manager"
}

export type AuthReducerActions<T extends any> = {
  type: AuthReducerActionTypes
  payload?: T
}

const hasKeys = (a: object) => Boolean(Object.keys(a).length)

export const authReducer = <T>(
  state: AuthReducerState,
  action: AuthReducerActions<T>
) => {
  const { type, payload } = action
  switch (type) {
    case AuthReducerActionTypes.UpdateUser:
      const user = payload as TalentisUser | undefined
      return {
        ...state,
        user,
        isLoggedIn: user ? hasKeys(user) : false,
        managerReady: state.manager ? hasKeys(state.manager) : false
      }
    case AuthReducerActionTypes.UpdateManager:
      const manager = payload as UserManager | undefined
      return { ...state, manager }
    default:
      return state
  }
}
