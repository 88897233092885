import React, { useCallback, useEffect, useState, Dispatch } from "react"
import { useSearch } from "views/search/SearchModule/context"
import { Job, SearchPerson } from "views/search/SearchModule/types"
import { encodePerson } from "views/search/SearchModule/utils"
import PersonAssignment from "../PersonAssignment"
import { PersonRecordLayout } from "../PersonRecordLayout"
import { Stage } from "views/persons/person.types"

type PersonCardProps = {
  person: SearchPerson
  previousJobs: Job[]
  assignmentIds: string[]
  campaignIds: string[]
  stages: Stage[]
  isLoadingIgnoredPersons: boolean
  ignoredPersons: string[]
  setIgnoredPersonArray: Dispatch<string[]>
  isGtPerson: boolean
}

const PersonRecordCard = ({
  person,
  previousJobs,
  assignmentIds,
  campaignIds,
  stages,
  setIgnoredPersonArray,
  isLoadingIgnoredPersons,
  ignoredPersons,
  isGtPerson
}: PersonCardProps) => {
  const { updatingPersons, selectedAssignment, isAssignmentSelect } =
    useSearch()
  const [isIgnored, setIsIgnored] = useState<boolean>(false)

  const [photoUrl, setPhotoUrl] = useState(person?.photo?.url)

  const ignored = useCallback(() => {
    const data =
      ignoredPersons?.filter((id: string) => id === person?.dataPoolId) || []
    setIsIgnored(!!data.length)
  }, [ignoredPersons, person])

  useEffect(() => {
    !isLoadingIgnoredPersons && ignored()
  }, [ignored, selectedAssignment, isLoadingIgnoredPersons, isAssignmentSelect])

  useEffect(() => {
    setPhotoUrl(person?.photo?.url)
  }, [person])

  return (
    <PersonRecordLayout
      {...{
        ...person,
        previousJobs,
        assignmentIds,
        stages,
        campaignIds,
        isGtPerson
      }}
      isConnected={!!person?.personId}
      isIgnored={isIgnored}
      photoUrl={photoUrl}
      isUpdating={
        !!updatingPersons[encodePerson(person?.personId, person?.dataPoolId)]
      }
      isOfflimits={person?.isOfflimits || false}
      AssignmentComponent={() => (
        <PersonAssignment
          {...{
            person,
            assignmentIds,
            campaignIds,
            ignoredPersonArray: ignoredPersons,
            setIgnoredPersonArray,
            isIgnored
          }}
        />
      )}
    />
  )
}

export default PersonRecordCard
