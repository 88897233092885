import { messages } from "setup/messages/messages"
import { Nullable } from "tsdef"

export enum InterviewProgressField {
  Status = "status",
  AssignTo = "assignTo",
  DueDate = "dueDate"
}

export type InterviewProgressStatusOption = {
  label: string
  value: InterviewProgressStatus
}

export enum InterviewProgressStatus {
  NoStatus = "noStatus",
  NeedToContact = "needToContact",
  LeftMessage = "leftMessage",
  Contacted = "contacted",
  Interested = "interested",
  NotInterested = "notInterested",
  ArrangingMeeting = "arrangingMeeting",
  MeetingPlanned = "meetingPlanned",
  AwaitingFeedback = "awaitingFeedback",
  AwaitingReferences = "awaitingReferences",
  OnHold = "onHold",
  PitchingStage = "pitchingStage",
  AssignmentWon = "assignmentWon"
}

const noStatus: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.NoStatus,
  label: messages.person.contact.interviewProgress.statuses.noStatus
}

const needToContact: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.NeedToContact,
  label: messages.person.contact.interviewProgress.statuses.needToContact
}

const leftMessage: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.LeftMessage,
  label: messages.person.contact.interviewProgress.statuses.leftMessage
}

const contacted: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.Contacted,
  label: messages.person.contact.interviewProgress.statuses.contacted
}

const interested: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.Interested,
  label: messages.person.contact.interviewProgress.statuses.interested
}

const notInterested: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.NotInterested,
  label: messages.person.contact.interviewProgress.statuses.notInterested
}

const arrangingMeeting: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.ArrangingMeeting,
  label: messages.person.contact.interviewProgress.statuses.arrangingIMeeting
}

const meetingPlanned: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.MeetingPlanned,
  label: messages.person.contact.interviewProgress.statuses.meetingPlanned
}

const awaitingFeedback: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.AwaitingFeedback,
  label: messages.person.contact.interviewProgress.statuses.awaitingFeedback
}

const awaitingReferences: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.AwaitingReferences,
  label: messages.person.contact.interviewProgress.statuses.awaitingReferences
}

const onHold: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.OnHold,
  label: messages.person.assignments.interviewProgress.statuses.onHold
}

const pitchingStage: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.PitchingStage,
  label: messages.person.contact.interviewProgress.statuses.pitchingStage
}

const assignmentWon: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.AssignmentWon,
  label: messages.person.contact.interviewProgress.statuses.assignmentWon
}

export const contactStatuses = [
  noStatus,
  needToContact,
  leftMessage,
  contacted,
  interested,
  notInterested,
  arrangingMeeting,
  meetingPlanned,
  awaitingFeedback,
  awaitingReferences,
  onHold,
  pitchingStage,
  assignmentWon
]

export const interviewProgressInitialValues = {
  [InterviewProgressField.Status]: InterviewProgressStatus.NoStatus,
  [InterviewProgressField.DueDate]: null,
  [InterviewProgressField.AssignTo]: null
}

export const interviewProgressInitialTouched = {
  [InterviewProgressField.Status]: true,
  [InterviewProgressField.AssignTo]: true,
  [InterviewProgressField.DueDate]: true
}

export type InterviewProgressValues = {
  status?: InterviewProgressStatus
  assignTo?: Nullable<string>
  dueDate?: Nullable<Date>
}

export const e2eTargets = {
  [InterviewProgressField.Status]: "interview-progress-status",
  [InterviewProgressField.AssignTo]: "interview-progress-assign-to",
  [InterviewProgressField.DueDate]: "interview-progress-due-date"
}
