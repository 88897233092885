import {
  CompanyState,
  CompanyActionTypes,
  CompanyContextValues
} from "./company-module.types"

export const initialCompanyState: CompanyState = {
  isLoading: true,
  companyDetails: null!,
  selectedKnownEmployeeId: "",
  personRecordOverlayUpdated: false,
  offLimits: null!
}

export const companyReducer = (
  state: CompanyState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action
  switch (type) {
    case CompanyActionTypes.SetIsLoading:
      return { ...state, isLoading: payload }
    case CompanyActionTypes.SetCompanyDetails:
      return { ...state, companyDetails: payload }
    case CompanyActionTypes.SetSelectedKnownEmployeeId:
      return { ...state, selectedKnownEmployeeId: payload }
    case CompanyActionTypes.SetOffLimits:
      return { ...state, offLimits: payload }
    case CompanyActionTypes.SetPersonRecordOverlayUpdated:
      return { ...state, personRecordOverlayUpdated: payload }
    default:
      return state
  }
}

export const initialCompanyContextValues: CompanyContextValues = {
  ...initialCompanyState,
  companyId: "",
  setCompanyDetails: () => {},
  getCompanyData: () => {},
  setSelectedKnownEmployeeId: () => {},
  setOffLimits: () => {},
  setPersonRecordOverlayUpdated: () => {}
}
