import { useCallback, useEffect, useState } from "react"
import { apiRequest } from "setup/api/api"
import {
  SearchFirmEndpoints,
  SubscriptionEndpoints,
  UsersEndpoints
} from "setup/api/endpoints/endpoints"
import { TeamMember } from "views/team/team/team-module.types"
import { CurrentSubscriptionDetails, FirmTokenDetails } from "./definitions"

export const useCurrentPlan = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentPlanDetails, setCurrentPlanDetails] =
    useState<CurrentSubscriptionDetails>()
  const [firmToken, setFirmTokenDetails] = useState<FirmTokenDetails>()

  const fetchCurrentPlanDetails = useCallback(async () => {
    setIsLoading(true)
    const [, response] = await apiRequest.get({
      endpoint: SubscriptionEndpoints.Root
    })
    setCurrentPlanDetails(response?.data)
    setIsLoading(false)
  }, [setIsLoading, setCurrentPlanDetails])

  useEffect(() => {
    fetchCurrentPlanDetails()
  }, [fetchCurrentPlanDetails])

  const getFirmTokens = useCallback(async () => {
    setIsLoading(true)
    const [, response] = await apiRequest.get({
      endpoint: SearchFirmEndpoints.FirmToken
    })
    setFirmTokenDetails(response?.data)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!currentPlanDetails?.paidSubscriptionDetails) return
    getFirmTokens()
  }, [currentPlanDetails, getFirmTokens])

  return {
    isLoading,
    currentPlanDetails,
    firmToken
  }
}

export const useFirmUsers = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentUsersCount, setCurrentUsersCount] = useState()

  const fetchCurrentUsers = useCallback(async () => {
    setIsLoading(true)
    const [, response] = await apiRequest.get({
      endpoint: UsersEndpoints.Root
    })

    const activeUsers =
      response?.data?.users?.filter(
        (user: TeamMember) =>
          user.isDisabled === false || user.status === "invited"
      ) || []

    setCurrentUsersCount(activeUsers.length || 1)
    setIsLoading(false)
  }, [setCurrentUsersCount])

  useEffect(() => {
    fetchCurrentUsers()
  }, [fetchCurrentUsers])

  return {
    isLoading,
    currentUsersCount
  }
}
