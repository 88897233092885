import React from "react"
import { ModalFormContainer } from "../../person-details-section/form/style"
import { Form, Formik, FormikHelpers } from "formik"
import FormActionButtons from "../../FormActionButtons"
import { messages } from "setup/messages/messages"
import {
  CreatePersonField,
  CreatePersonValues,
  createPersonInitialValues
} from "views/persons/components/person-details/form/constants/definitions"
import { OnPersonDetailsSubmitType } from "views/persons/components/person-details/form/actions"
import { getChangedValuesWithArray } from "views/assignments/utils"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { Div, Flex } from "@ikiru/talentis-fpc"

type EditPersonDetailsProps = {
  initialValues: Partial<CreatePersonValues>
  onSubmit: OnPersonDetailsSubmitType
}

const EditPersonAddress = (props: EditPersonDetailsProps) => {
  const { initialValues, onSubmit } = props
  const submitForm = (
    values: Partial<CreatePersonValues>,
    action: FormikHelpers<CreatePersonValues>
  ) => {
    const trimmedValues = {
      location: values.location,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      postcode: values.postcode,
      city: values.city,
      country: values.country
    }

    const changedValues = initialValues
      ? getChangedValuesWithArray(trimmedValues, initialValues)
      : trimmedValues

    onSubmit(changedValues, action)
  }
  return (
    <ModalFormContainer defaultWidth={755}>
      <Formik
        initialValues={{ ...createPersonInitialValues, ...initialValues }}
        onSubmit={submitForm}
      >
        {(formik) => {
          const { values } = formik
          return (
            <Form>
              <FormActionButtons
                title={messages.person.personTabSection.form.addressEditMode}
              />
              <FormikInput
                id={CreatePersonField.Location}
                name={`${CreatePersonField.Location}.value`}
                label={messages.form.generic.location}
                disabled={values?.location?.isReadOnly}
              />
              <FormikInput
                id={CreatePersonField.AddressLine1}
                name={CreatePersonField.AddressLine1}
                label={messages.form.generic.addressLine1}
              />
              <FormikInput
                id={CreatePersonField.AddressLine2}
                name={CreatePersonField.AddressLine2}
                label={messages.form.generic.addressLine2}
              />
              <Flex>
                <Div p="xs" pl="0">
                  <FormikInput
                    id={CreatePersonField.City}
                    name={CreatePersonField.City}
                    label={messages.form.generic.city}
                  />
                </Div>
                <Div p="xs">
                  <FormikInput
                    id={CreatePersonField.Country}
                    name={CreatePersonField.Country}
                    label={messages.form.generic.country}
                  />
                </Div>
                <Div p="xs" pr="0">
                  <FormikInput
                    id={CreatePersonField.PostCode}
                    name={CreatePersonField.PostCode}
                    label={messages.form.generic.postcode}
                  />
                </Div>
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}

export default EditPersonAddress
