import * as Yup from "yup"

export enum GoogleSearchDefinition {
  Query = "query",
  Type = "type"
}

export enum GoogleSearchTypeDefinition {
  People = "people",
  Female = "female"
}

export type GoogleSearchType = {
  [GoogleSearchTypeDefinition.People]: boolean
}

export type GoogleSearchForm = {
  [GoogleSearchDefinition.Query]: string
  [GoogleSearchDefinition.Type]: GoogleSearchType
}

export const googleSearchValidationSchema = Yup.object({
  [GoogleSearchDefinition.Query]: Yup.string().trim().required()
})

export const initialValues: GoogleSearchForm = {
  [GoogleSearchDefinition.Query]: "",
  [GoogleSearchDefinition.Type]: {
    people: true
  }
}

export const e2eTargets = {
  searchButton: "google-search-button",
  queryInput: "query-input-field",
  femaleSwitch: "female-switch-field"
}
