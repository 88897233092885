import { Flex, H3 } from "@ikiru/talentis-fpc"
import React from "react"
import { messages } from "setup/messages/messages"

const NoPlanInfo = () => {
  return (
    <Flex alignItems="center" justifyContent="center" height={80}>
      <H3 color="green.dark">
        {messages.billing.currentPlan.expiredSubscription}
      </H3>
    </Flex>
  )
}

export default NoPlanInfo
