import React from "react"
import { useLocation } from "react-router-dom"
import { messages } from "setup/messages/messages"
import { Flex, H1 } from "@ikiru/talentis-fpc"
import { Helmet } from "react-helmet"
import ActiveAssignments from "./components/ActiveAssignments"
import Invitations from "./components/Invitations"
import { TaskManagementWrapper } from "./components/TaskManagement/TaskManagementWrapper"
import TrainingVideo from "assets/TLT_training_videos.png"
import { StyledLink } from "./styles"

type LocationState = {
  logout: Boolean
}

export const Homepage = () => {
  const { state } = useLocation()
  const fromLogout = (state as LocationState)?.logout

  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.seo.dashboard)}</title>
      </Helmet>
      <Flex
        textAlign="center"
        m="none"
        minHeight="100%"
        width="100%"
        bg="green.dark"
        flexDirection="column"
        alignItems="center"
      >
        <H1
          fontSize={["35px", "35px", "45px"]}
          lineHeight={["38px", "38px", "48px"]}
          color="white.standard"
        >
          {messages.homepage.welcome}
        </H1>
        <Flex
          width="100%"
          mb="xxs"
          my="xxs"
          px="xs"
          alignItems={[
            "center",
            "center",
            "center",
            "flex-start",
            "flex-start",
            "flex-start"
          ]}
          flexDirection={["column", "column", "column", "row", "row"]}
          justifyContent="center"
        >
          <Flex
            width={["100%"]}
            maxWidth={["664px", "664px", "664px", "664px", "850px", "1009px"]}
            ml={["s"]}
            mr={["s", "s"]}
            mb="s"
          >
            {!fromLogout && <TaskManagementWrapper />}
          </Flex>
          <Flex flexDirection={["column", "column", "column"]}>
            <Flex
              width="100%"
              maxWidth={["100%", "100%", "277px", "316px", "316px"]}
              mt={["s", "s", "none"]}
              mb="s"
            >
              {!fromLogout && <ActiveAssignments />}
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              width={["100%"]}
              maxWidth={["100%", "100%", "277px", "316px", "316px"]}
            >
              <StyledLink href="/support">
                <img
                  width="100%"
                  alt="Talantis training videos"
                  src={TrainingVideo}
                />
              </StyledLink>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Invitations />
    </>
  )
}

//To reactive invite colleague:
//head to these dir's and uncomment
//app\src\components\modals\InviteColleaguesModal\index.tsx
//app\src\views\homepage\components\Invitations\components\invitation-create-form\InvitationSkippedInfo.tsx
