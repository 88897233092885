import { messages } from "setup/messages/messages"
import { CreatePersonField } from "views/persons/components/person-details/form/constants/definitions"
import * as Yup from "yup"

export const createPersonDetailValidationSchema = Yup.object({
  [CreatePersonField.Name]: Yup.object({
    value: Yup.string()
      .max(110, messages.form.generic.errors.mustBeNoLongerThan.format(110))
      .required(messages.generic.required)
  })
})
