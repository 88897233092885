import React, { useCallback, useState } from "react"
import { useSearch } from "views/search/SearchModule/context"
import LoadingSearch from "./components/LoadingSearch"
import FinishedSearch from "./components/FinishedSearch"
import { AISearchContainer } from "./style"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import GPTHomeScreen from "./components/GPTHomeScreen"

type ChatGPTSearchBoxProps = {
  getAISearchCriteria: (
    dataFormat: string
  ) => Promise<NormalizedReturnTuple<any>>
  isLoading: boolean
  updateSearchCriteria: (
    returnData: NormalizedReturnTuple<any>,
    callback?: () => void
  ) => void
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
  isError: boolean
}

const ChatGPTSearchBox = (props: ChatGPTSearchBoxProps) => {
  const [gptSearch, setGPTSearch] = useState<string>("")
  const [hasSearched, setHasSearched] = useState(false)

  const { resetFilters, setHasResetFilters } = useSearch()

  const {
    getAISearchCriteria,
    isLoading,
    updateSearchCriteria,
    setIsError,
    isError
  } = props
  const { trackAISearchUse } = useTelemetry()

  const onSubmit = useCallback(async () => {
    const data = await getAISearchCriteria(gptSearch)

    updateSearchCriteria(data, () => {
      trackAISearchUse()
      setHasSearched(true)
    })
  }, [getAISearchCriteria, gptSearch, trackAISearchUse, updateSearchCriteria])

  const resetSearch = useCallback(() => {
    setIsError(false)
    resetFilters()
    setHasSearched(false)
    setGPTSearch("")
    setHasResetFilters(false)
  }, [resetFilters, setHasResetFilters, setIsError])

  return (
    <AISearchContainer>
      {isLoading ? (
        <LoadingSearch />
        )
        :
        hasSearched ? (
        <FinishedSearch
          isError={isError}
          gptSearch={gptSearch}
          setGPTSearch={setGPTSearch}
          onSubmit={onSubmit}
          resetSearch={resetSearch}
        />
      ) : (
        <GPTHomeScreen
          isError={isError}
          gptSearch={gptSearch}
          setGPTSearch={setGPTSearch}
          onSubmit={onSubmit}
        />
      )}
    </AISearchContainer>
  )
}

export default ChatGPTSearchBox
