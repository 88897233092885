import { useCallback, useEffect, useState } from "react"
import { Nullable } from "tsdef"
import sortBy from "lodash/sortBy"
import { apiRequest } from "setup/api/api"
import { expandEndpointByParams } from "setup/api/api.helpers"
import { Candidate } from "views/candidates/candidates.types"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { fetchPersonPhotos } from "models/LocalPerson/localPerson.actions"
import { interviewProgressInitialValues } from "../constants/definitions"
import { groupCandidates } from "../assignment-module/assignment-module.helpers"
import { InterviewProgressStage } from "../components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"

export type GroupedCandidates = {
  [key in InterviewProgressStage]: Candidate[]
}

type CandidatesData = {
  candidates: Candidate[]
  groupedCandidates: Nullable<GroupedCandidates>
}

type UseCandidatesParams = {
  assignmentId?: string
  onError?: VoidFunction
}

const useCandidates = ({ assignmentId, onError }: UseCandidatesParams) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<CandidatesData>({
    candidates: [],
    groupedCandidates: null
  })

  const [photos, setPhotos] = useState<Record<string, Nullable<string>>>({})

  const getAssignmentCandidates = useCallback(
    async (assignmentId: string) => {
      setIsLoading(true)

      const [error, response] = await apiRequest.get({
        endpoint: CandidatesEndpoints.Root,
        config: expandEndpointByParams({
          assignmentId: assignmentId,
          expand: ["person"]
        })
      })

      if (error) {
        onError?.()
      } else {
        let candidates = response?.data?.candidates || []

        candidates = candidates.map((candidate: Candidate) => ({
          ...candidate,
          interviewProgressState:
            candidate.interviewProgressState || interviewProgressInitialValues,
          normalizedPersonData: new LocalPersonModel(
            candidate?.linkPerson
          ).getPersonValues()
        }))

        candidates = sortBy(
          candidates,
          [(candidate) => candidate.normalizedPersonData?.name.toLowerCase()],
          ["desc"]
        )

        const groupedCandidates = groupCandidates(candidates)

        setData({
          candidates,
          groupedCandidates
        })

        const candidateIds = candidates.map(
          (candidate: Candidate) => candidate.personId
        )
        const candidatesWithPhotos = await fetchPersonPhotos(candidateIds)

        candidatesWithPhotos.forEach((photoData) => {
          setPhotos((prev) => ({
            ...prev,
            [photoData.personId]: photoData.photo
          }))
        })
      }

      setIsLoading(false)
    },
    [onError]
  )

  const updateCandidates = useCallback((data: CandidatesData) => {
    setData((prev) => ({ ...prev, ...data }))
  }, [])

  useEffect(() => {
    if (assignmentId) {
      getAssignmentCandidates(assignmentId)
    }
  }, [assignmentId, getAssignmentCandidates])

  return {
    photos,
    isLoading,
    candidates: data.candidates,
    groupedCandidates: data.groupedCandidates,
    updateCandidates
  }
}

export { useCandidates }
