import React, { useState, useEffect } from "react"
import { Div, Input, Error } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ListCompany } from "./ListCompany"
import useCompaniesTypeAhead from "./hooks/useCompaniesTypeAhead"
import { CompanySelected } from "./types"
import { useComponentVisible } from "utils/hooks/use-component-visable"

type CompanySearchProps = {
  name: string
  id: string
  label: string
  onClickItem?: (value: CompanySelected) => void
  selectedCompany?: (value: CompanySelected) => void
  isEdit?: boolean
  initialSearchedWord?: string
  hideErrorMessage?: boolean
}

const emptySelectedValue = {
  name: "",
  id: undefined,
  companyId: undefined
}

const CompanySearch = ({
  label,
  selectedCompany,
  onClickItem,
  isEdit = false,
  initialSearchedWord = "",
  hideErrorMessage = false
}: CompanySearchProps) => {
  const [isError, setIsError] = useState<boolean>(false)

  const {
    companies,
    isShowedCompanies,
    searchedWord,
    setSearchedWord,
    setIsShowedCompanies,
    setCompanySelected
  } = useCompaniesTypeAhead()

  const { divRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const onChanged = (e: any) => {
    setIsComponentVisible(true)
    setCompanySelected(false)
    setSearchedWord(e.target.value)
    selectedCompany && selectedCompany({ name: e.target.value, id: "" })
    setIsError(false)
  }

  const onClick = (data: CompanySelected) => {
    setCompanySelected(true)
    setSearchedWord(data.name)
    setIsShowedCompanies(false)
    selectedCompany && selectedCompany(data)
    onClickItem && onClickItem(data)
  }

  const setInitialResults = () => {
    setSearchedWord("")
    selectedCompany && selectedCompany(emptySelectedValue)
    setIsShowedCompanies(false)
  }

  const invalid = (e: any) => {
    e.preventDefault()
    setIsError(true)
  }

  useEffect(() => {
    setSearchedWord(initialSearchedWord)
  }, [initialSearchedWord, setSearchedWord])

  return (
    <Div width="100%" ref={divRef}>
      <Input
        clearable
        maxLength={255}
        onClear={setInitialResults}
        label={label}
        onChange={onChanged}
        value={searchedWord}
        required
        width="100%"
        onInvalid={invalid}
        onFocus={() => setIsComponentVisible(true)}
      />
      {!hideErrorMessage && isError && (
        <Error>{messages.form.generic.errors.companySelect}</Error>
      )}
      {isShowedCompanies && isComponentVisible && (
        <Div {...(!isEdit && { position: "relative" })}>
          <ListCompany
            companies={companies}
            onItemClicked={onClick}
            editStyle={isEdit}
          />
        </Div>
      )}
    </Div>
  )
}

export default CompanySearch
