import { noop } from "lodash"
import {
  SubscriptionPurchaseState,
  SubscriptionPurchaseAction,
  SubscriptionPurchaseContextValues,
  SubscriptionPurchaseStep,
  SubscriptionPurchaseActionTypes
} from "./subscription-purchase-module.types"

export const initialSubscriptionPurchaseState: SubscriptionPurchaseState = {
  step: SubscriptionPurchaseStep.PLAN_SELECTION,
  arePlansFetching: true,
  availablePlans: [],
  purchaseData: {},
  paymentData: {
    paymentComplete: false,
    paymentError: null,
    paymentIntent: null
  },
  paymentEstimate: {},
  billingDetails: {},
  usersCount: [],
  isLoading: false,
  loadingMessage: "",
  stepTitle: "Default title"
}

export const initialSubscriptionPurchaseContextValues: SubscriptionPurchaseContextValues =
  {
    ...initialSubscriptionPurchaseState,
    changeStep: noop,
    selectPlan: noop,
    setPlanDetails: noop,
    setPaymentComplete: noop,
    addCoupon: noop,
    removeCoupon: noop,
    setPaymentError: noop,
    setIsLoading: noop,
    setBillingDetails: noop,
    setPaymentEstimate: noop,
    setPaymentIntent: noop,
    fetchPaymentIntent: () => Promise.resolve(null),
    fetchEstimate: () => Promise.resolve(null),
    createSubscription: () => Promise.resolve(),
    fetchPlans: () => Promise.resolve([undefined, undefined]),
    setStepTitle: noop
  }

export const subscriptionReducer = (
  state: SubscriptionPurchaseState,
  action: SubscriptionPurchaseAction
) => {
  const { type, payload } = action

  switch (type) {
    case SubscriptionPurchaseActionTypes.ChangeStep:
      return {
        ...state,
        step: payload.step
      }
    case SubscriptionPurchaseActionTypes.ChangeStepTitle:
      return {
        ...state,
        stepTitle: payload
      }
    case SubscriptionPurchaseActionTypes.StartFetchingPlans:
      return {
        ...state,
        arePlansFetching: true
      }
    case SubscriptionPurchaseActionTypes.StopFetchingPlans:
      return {
        ...state,
        availablePlans: payload.plans,
        arePlansFetching: false
      }
    case SubscriptionPurchaseActionTypes.SelectSubscriptionPlan:
      return {
        ...state,
        purchaseData: {
          ...state.purchaseData,
          plan: payload.plan
        }
      }
    case SubscriptionPurchaseActionTypes.SetUsersCount:
      return {
        ...state,
        usersCount: payload
      }
    case SubscriptionPurchaseActionTypes.SetPlanDetails:
      return {
        ...state,
        purchaseData: {
          ...state.purchaseData,
          planDetails: payload
        }
      }
    case SubscriptionPurchaseActionTypes.SetPaymentComplete:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          paymentComplete: payload
        }
      }
    case SubscriptionPurchaseActionTypes.SetIsLoading:
      const { isLoading, loadingMessage } = payload

      return {
        ...state,
        isLoading,
        loadingMessage
      }
    case SubscriptionPurchaseActionTypes.SetPaymentEstimate:
      return {
        ...state,
        paymentEstimate: payload
      }
    case SubscriptionPurchaseActionTypes.SetPaymentIntent:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          paymentIntent: payload
        }
      }
    case SubscriptionPurchaseActionTypes.SetPaymentError:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          paymentError: payload
        }
      }
    case SubscriptionPurchaseActionTypes.AddCoupon:
      return {
        ...state,
        purchaseData: {
          ...state.purchaseData,
          couponsUsed: [...(state.purchaseData.couponsUsed || []), ...payload]
        }
      }
    case SubscriptionPurchaseActionTypes.RemoveCoupon:
      return {
        ...state,
        purchaseData: {
          ...state.purchaseData,
          couponsUsed: [...(state.purchaseData.couponsUsed || [])].filter(
            (coupon) => !payload.includes(coupon)
          )
        }
      }
    case SubscriptionPurchaseActionTypes.SetBillingDetails:
      return {
        ...state,
        billingDetails: payload
      }
    default:
      return state
  }
}
