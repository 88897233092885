import * as Yup from "yup"

export enum FindContactFormFields {
  PersonFirstName = "person-first-name",
  PersonLastName = "person-last-name",
  PersonCountry = "person-country",
  PersonJobTitle = "person-job-title",
  PersonJobTitleLogic = "person-job-title-logic",
  PersonCompany = "person-company",
  PersonKeywords = "person-keywords",
  PersonKeywordLogic = "person-keyword-logic",
  PersonCompanyLogic = "person-company-logic",
  PersonLocation = "person-location"
}

export const EXCLUDEDALL = "not"

export type NewContactForm = {
  [FindContactFormFields.PersonFirstName]: string
  [FindContactFormFields.PersonLastName]: string
  [FindContactFormFields.PersonCompany]: string
  [FindContactFormFields.PersonJobTitle]: string
  [FindContactFormFields.PersonJobTitleLogic]: string
  [FindContactFormFields.PersonKeywords]: string
  [FindContactFormFields.PersonKeywordLogic]: string
  [FindContactFormFields.PersonCountry]: string
  [FindContactFormFields.PersonCompanyLogic]: string
  [FindContactFormFields.PersonLocation]: string
}

export const initialValues: NewContactForm = {
  [FindContactFormFields.PersonFirstName]: "",
  [FindContactFormFields.PersonLastName]: "",
  [FindContactFormFields.PersonCompany]: "",
  [FindContactFormFields.PersonJobTitle]: "",
  [FindContactFormFields.PersonJobTitleLogic]: "current",
  [FindContactFormFields.PersonKeywords]: "",
  [FindContactFormFields.PersonKeywordLogic]: "",
  [FindContactFormFields.PersonCountry]: "",
  [FindContactFormFields.PersonCompanyLogic]: "current",
  [FindContactFormFields.PersonLocation]: ""
}

export const FindContactFormValidationSchema = Yup.object().shape({
  [FindContactFormFields.PersonFirstName]: Yup.string().trim().required(),
  [FindContactFormFields.PersonLastName]: Yup.string().trim().required(),
  [FindContactFormFields.PersonJobTitle]: Yup.string().trim().required(),
  [FindContactFormFields.PersonCountry]: Yup.string().trim().required(),
  [FindContactFormFields.PersonCompany]: Yup.string().trim().required()
})

export const e2eTargets = {
  personNameInput: "newContactModalPersonNameInput",
  modalContainer: "newContactModalContainer",
  modalCloseButton: "newContactModalCloseButton",
  submitButton: "newContactModalSubmitButton"
}
