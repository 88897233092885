import cloneDeep from "lodash/cloneDeep"
import { WebSite } from "views/persons/person.types"
import { PersonValue } from "./localPerson.definitions"

export const generateWithoutReadOnly = (data: any, omitKeys: string[] = []) =>
  Object.keys(data).reduce((result, key) => {
    const itemValue = data[key]
    if (itemValue?.isReadOnly === false || omitKeys.includes(key)) {
      return { ...result, [key]: itemValue?.value }
    } else if (
      Array.isArray(itemValue) &&
      itemValue.some((i) => i && i.isReadOnly === false)
    ) {
      return {
        ...result,
        [key]: itemValue
          .map((i) => (i.isReadOnly === false ? i.value : null))
          .filter(Boolean)
      }
    } else if (Array.isArray(itemValue)) {
      return {
        ...result,
        [key]: itemValue
      }
    } else if (typeof itemValue === "string" || itemValue === null) {
      return {
        ...result,
        [key]: itemValue
      }
    }
    return result
  }, {})

const websitesSortingArray = [
  "linkedIn",
  "xing",
  "crunchbase",
  "reuters",
  "bloomberg",
  "facebook",
  "zoomInfo",
  "twitter",
  "owler",
  "companiesHouse",
  "youTube",
  "other"
]

export const sortWebsitesByKnownType = (websites: PersonValue<WebSite>[]) =>
  cloneDeep(websites).sort(
    (prev, next) =>
      websitesSortingArray.indexOf(prev.value.type || "other") -
      websitesSortingArray.indexOf(next.value.type || "other")
  )
