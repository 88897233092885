import React from "react"
import { PasswordInput, PasswordInputProps } from "@ikiru/talentis-fpc"
import { Error } from "@ikiru/talentis-fpc"
import { useField } from "formik"
import { useStatus } from "components/functional/formik/hooks/use-status"
import { Div } from "@ikiru/talentis-fpc"
import { useShouldDisplayError } from "components/functional/formik/hooks/use-display-error"

type FormikInputProps = PasswordInputProps & {
  label?: React.ReactChild
  name: Pick<PasswordInputProps, "name">
  hideErrorMessage?: boolean
}

const e2eTarget = "form-input"

export const FormikPasswordInput = React.memo(
  React.forwardRef((props: FormikInputProps, ref) => {
    const {
      label,
      name,
      onBlur: explicitOnBlur,
      hideErrorMessage,
      ...otherExplicitProps
    } = props
    const [formikProps, meta] = useField(name as string)

    const {
      onBlur: formikDefaultOnBlur,
      value,
      ...otherFormikProps
    } = formikProps

    const status = useStatus(meta)
    const shouldDisplayError = useShouldDisplayError(meta) && !hideErrorMessage

    return (
      <Div>
        <PasswordInput
          ref={ref}
          label={label}
          aria-label={label}
          data-e2e-target={e2eTarget}
          status={status}
          onBlur={(event) => {
            formikDefaultOnBlur(event)
            explicitOnBlur?.(event)
          }}
          value={value || props.defaultValue || ""}
          {...otherFormikProps}
          {...otherExplicitProps}
        />
        {shouldDisplayError && <Error>{meta.error}</Error>}
      </Div>
    )
  })
)
