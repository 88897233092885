import { navigationSelectors } from "components/visual/layout/definitions"
import { Nullable } from "tsdef"
import { get, set, omit, isEmpty } from "lodash"
import omitEmpty from "omit-empty"
import { Filters } from "views/search/SearchModule/types"
import { sessionStorageKeys } from "setup/storage/storage.definitions"
import {
  DistanceUnit,
  FilterType,
  CurrentPastEitherLogic,
  LogicalOperator,
  KeywordBundle,
  JobTitleBundle
} from "./types"
import { optionalFields } from "./consts"

export const encodePerson = (
  localPersonId?: Nullable<string>,
  dataPoolPersonId?: Nullable<string>
) => `${localPersonId}::${dataPoolPersonId}`

export const decodePersonKey = (key: string) => key.split("::")

export const calculateKMs = (
  distance: number = 0,
  unit: DistanceUnit = DistanceUnit.KM
) =>
  unit === DistanceUnit.KM
    ? +distance
    : parseFloat((+distance * 1.6).toFixed(2))

export const scrollToTop = () => {
  const mainContainerRef = document.querySelector(
    `#${navigationSelectors.mainContainer}`
  )

  mainContainerRef?.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}

export const saveFiltersToSessionStorage = (filters: Filters) => {
  if (isEmpty(filters)) {
    sessionStorage.removeItem(sessionStorageKeys.previousSearch)
    return
  }

  try {
    const filtersJson = JSON.stringify(filters)
    sessionStorage.setItem(sessionStorageKeys.previousSearch, filtersJson)
  } catch (eror) {
    console.error("Could not save previous search")
  }
}

export const getFiltersFromSessionStorage = (): Filters => {
  const filtersJson = sessionStorage.getItem(sessionStorageKeys.previousSearch)

  if (!filtersJson) {
    return {}
  }

  let filters = {}

  try {
    filters = JSON.parse(filtersJson)
  } catch (error) {
    console.error("Could not load previous search")
  }

  return filters
}

export const hasFiltersInSessionStorage = () => {
  const filters = getFiltersFromSessionStorage()
  return filters && !isEmpty(filters)
}

export const prepareTalentGraphFilters = (
  filters: Filters
): Partial<Filters> | null => {
  const cleanedObject = omitEmpty(filters, {
    omitZero: true
  }) as Partial<Filters>
  const requiredData = omit<any>(cleanedObject, optionalFields)
  const companySizes = get(requiredData, FilterType.CompanySize)

  // Remove keyword bundles if it not contains any keywords
  if (requiredData.keywordBundle) {
    requiredData.keywordBundle = requiredData.keywordBundle?.filter(
      (bundle: KeywordBundle) => bundle.keywords && bundle.keywords.length !== 0
    )

    if (
      !requiredData?.keywordBundle ||
      requiredData?.keywordBundle?.length === 0
    ) {
      delete requiredData.keywordBundle
    }
  }

  if (requiredData.jobTitleBundle) {
    requiredData.jobTitleBundle = requiredData.jobTitleBundle?.filter(
      (bundle: JobTitleBundle) =>
        bundle.jobTitles && bundle.jobTitles.length !== 0
    )

    if (
      !requiredData?.jobTitleBundle ||
      requiredData?.jobTitleBundle?.length === 0
    ) {
      delete requiredData.jobTitleBundle
    }
  }

  if (isEmpty(requiredData)) return null

  if (get(requiredData, FilterType.Locations, []).length === 1) {
    requiredData[FilterType.Distance] = calculateKMs(
      cleanedObject?.[FilterType.Distance],
      cleanedObject?.[FilterType.DistanceUnit]
    )
  }

  if (get(requiredData, FilterType.CurrentJobExperienceYearsMin)) {
    set(
      requiredData,
      ["CurrentJobExperienceYears", "Min"],
      Number(get(requiredData, FilterType.CurrentJobExperienceYearsMin))
    )
    delete requiredData[FilterType.CurrentJobExperienceYearsMin]
  }

  if (get(requiredData, FilterType.CurrentJobExperienceYearsMax)) {
    set(
      requiredData,
      ["CurrentJobExperienceYears", "Max"],
      Number(get(requiredData, FilterType.CurrentJobExperienceYearsMax))
    )
    delete requiredData[FilterType.CurrentJobExperienceYearsMax]
  }

  if (get(requiredData, FilterType.TotalJobExperienceYearsMin)) {
    set(
      requiredData,
      ["TotalExperienceYears", "Min"],
      Number(get(requiredData, FilterType.TotalJobExperienceYearsMin))
    )
    delete requiredData[FilterType.TotalJobExperienceYearsMin]
  }

  if (get(requiredData, FilterType.TotalJobExperienceYearsMax)) {
    set(
      requiredData,
      ["TotalExperienceYears", "Max"],
      Number(get(requiredData, FilterType.TotalJobExperienceYearsMax))
    )
    delete requiredData[FilterType.TotalJobExperienceYearsMax]
  }

  if (get(requiredData, FilterType.JobTitleBundle, []).length > 0) {
    requiredData[FilterType.JobTitleBundle] = requiredData[
      FilterType.JobTitleBundle
    ]?.map((bundle: { jobTitles: string[]; searchJobTitleLogic: string }) => {
      return {
        ...bundle,
        searchJobTitleLogic:
          bundle?.searchJobTitleLogic || CurrentPastEitherLogic.Current
      }
    })

    const jobTitleSearchLogic =
      cleanedObject?.[FilterType.JobTitlesBundleSearchUsingANDLogic] ||
      LogicalOperator.AND

    requiredData[FilterType.JobTitlesBundleSearchUsingANDLogic] =
      jobTitleSearchLogic === LogicalOperator.AND
  }

  if (get(requiredData, FilterType.Industries, []).length > 0) {
    requiredData[FilterType.IndustriesLogic] =
      cleanedObject?.[FilterType.IndustriesLogic] ||
      CurrentPastEitherLogic.Current
  }

  if (get(requiredData, FilterType.Companies, []).length > 0) {
    requiredData[FilterType.CompanyLogic] =
      cleanedObject?.[FilterType.CompanyLogic] || CurrentPastEitherLogic.Current
  }

  if (
    get(requiredData, [FilterType.ExcludeKeywordSearch, "keywords"], [])
      .length > 0
  ) {
    ;[
      [FilterType.KeywordsSearchRecordCompany, true],
      [FilterType.KeywordsSearchRecordPerson, true],
      [FilterType.KeywordsSearchLogic, CurrentPastEitherLogic.Current]
    ].forEach((value) => {
      const searchRecordCompanyValue = get(
        cleanedObject,
        [FilterType.ExcludeKeywordSearch, value[0] as string],
        value[1]
      )
      set(
        requiredData,
        [FilterType.ExcludeKeywordSearch, value[0] as string],
        searchRecordCompanyValue
      )
    })
  }

  if (companySizes) {
    requiredData[FilterType.CompanySizeLogic] =
      cleanedObject?.[FilterType.CompanySizeLogic] ||
      CurrentPastEitherLogic.Current
    requiredData[FilterType.CompanySize] = Object.keys(companySizes).filter(
      (size) => companySizes[size]
    )
  }

  if (get(requiredData, FilterType.KeywordBundle, []).length > 0) {
    requiredData[FilterType.KeywordBundle] = requiredData[
      FilterType.KeywordBundle
    ]?.map((bundle: KeywordBundle) => {
      const keywordsSearchUsingORLogic =
        bundle?.keywordsSearchUsingORLogic || LogicalOperator.OR

      return {
        ...bundle,
        keywordsSearchUsingORLogic:
          keywordsSearchUsingORLogic === LogicalOperator.OR,
        keywordsSearchRecordPerson: bundle?.keywordsSearchRecordPerson ?? true,
        keywordsSearchRecordCompany:
          bundle?.keywordsSearchRecordCompany ?? true,
        keywordsSearchLogic:
          bundle?.keywordsSearchLogic || CurrentPastEitherLogic.Current
      }
    })

    const KeywordsBundleSearchUsingORLogic =
      cleanedObject?.[FilterType.KeywordsBundleSearchUsingORLogic] ||
      LogicalOperator.OR

    requiredData[FilterType.KeywordsBundleSearchUsingORLogic] =
      KeywordsBundleSearchUsingORLogic === LogicalOperator.OR
  }

  return requiredData
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
