import React from "react"
import { Flex } from "@ikiru/talentis-fpc"
import { CustomBodyTexts, DataPrivacySubHeaders } from "./style"
import { Nullable } from "tsdef"

type DisplayComponentProps = {
  label: Nullable<string>
  text?: Nullable<string>
}

const DisplayComponent = ({ label, text }: DisplayComponentProps) => {
  return (
    <Flex width="100%" mt="xs">
      <DataPrivacySubHeaders>{label}</DataPrivacySubHeaders>
      <CustomBodyTexts>{text}</CustomBodyTexts>
    </Flex>
  )
}

export default DisplayComponent
