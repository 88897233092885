import React from "react"
import {
  BodyText,
  Button,
  Flex,
  H3,
  colors,
  CirclePhone,
  Link,
  CVIcon,
  Div,
  CrossIcon
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { GT2ModalWrapper, StyledLink } from "./style"
import { useGT2Person } from "./useGT2Person"
import { Nullable } from "tsdef"
import { Loader } from "components/Loader/Loader"
import ClickablePhoneNumber from "views/persons/components/person-details/components/PersonPhoneList/ClickableNumber"
import { EmailGt2, MemberVerified, TelephoneGt2 } from "./types"

type GT2ModalModalProps = {
  dataPoolPersonId: string
  name?: Nullable<string>
}

export const GT2Modal = ({ dataPoolPersonId, name }: GT2ModalModalProps) => {
  const { gt2Person, seeCVDocs, isLoading } = useGT2Person({ dataPoolPersonId })

  const { close } = useModal()

  return (
    <GT2ModalWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <H3 color={colors.grey.dark} mt="0">
          {name}
          {messages.talentGraphSearch.gt2Talent.modalTitle}
        </H3>

        <Button
          mode="standard-white"
          size="action-standard"
          onClick={() => close(ModalNames.GT2Modal)}
        >
          <CrossIcon fill="grey.dark" />
        </Button>
      </Flex>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <BodyText>
            {gt2Person.verified === MemberVerified.NotSet ||
            gt2Person.verified === MemberVerified.Unverified
              ? messages.talentGraphSearch.gt2Talent.notVerified
              : messages.talentGraphSearch.gt2Talent.verified}
          </BodyText>
          <Flex alignItems="center" mb="m">
            <CVIcon width={24} height={24} />
            {gt2Person.hasCv && (
              <BodyText ml="s">
                {messages.talentGraphSearch.gt2Talent.availableResume}
                <Link onClick={seeCVDocs} ml="xxs">
                  {messages.talentGraphSearch.gt2Talent.clickHere}
                </Link>
                {messages.talentGraphSearch.gt2Talent.toView}
              </BodyText>
            )}
          </Flex>
          {!!(gt2Person.telephones.length || gt2Person.emails.length) && (
            <Flex>
              <CirclePhone width={24} height={24} />
              <Flex flexDirection="column" ml="s">
                <BodyText mt="xxs">
                  {messages.talentGraphSearch.gt2Talent.sharedInfo}
                </BodyText>

                {!!gt2Person.telephones.length && (
                  <Flex flexDirection="column">
                    {gt2Person.telephones.map(
                      (telephone: TelephoneGt2, index: number) => (
                        <Div width="fit-content" mb="xxs" ml="-4px">
                          <ClickablePhoneNumber
                            key={index}
                            phoneNumber={telephone.number}
                          />
                        </Div>
                      )
                    )}
                  </Flex>
                )}

                {!!gt2Person.emails.length && (
                  <Flex flexDirection="column" mt="s">
                    {gt2Person.emails.map((email: EmailGt2, index: number) => (
                      <StyledLink
                        key={index}
                        ml="none"
                        href={`mailto:${email.emailAddress}`}
                      >
                        {email.emailAddress}
                      </StyledLink>
                    ))}
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
        </>
      )}
    </GT2ModalWrapper>
  )
}
