import React, { lazy, Suspense } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import CandidateComponent from "./CandidateComponent"

const CandidateRecordWrapper = lazy(
  () =>
    import(
      "views/assignments/components/candidates-list/components/candidate-record/CandidateRecordWrapper"
    )
)

const InterviewStageCandidates = React.memo(() => {
  const { candidates } = useAssignment()
  const itemsCount = candidates.length

  return (
    <Div mb={-10} px="m" py={Boolean(itemsCount) ? "xs" : "none"}>
      {candidates.map((candidate) => (
        <Suspense fallback={<></>}>
          <AnimatePresence key={candidate.id}>
            <motion.div
              key={candidate.id}
              transition={{ duration: 1 }}
              initial={{ opacity: 0, width: "100%" }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Flex width="100%" mb="xs">
                <CandidateRecordWrapper
                  candidate={candidate}
                  personPhoto={null}
                >
                  <CandidateComponent />
                </CandidateRecordWrapper>
              </Flex>
            </motion.div>
          </AnimatePresence>
        </Suspense>
      ))}
    </Div>
  )
})

export default InterviewStageCandidates
