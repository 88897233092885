import React, { useCallback, useEffect } from "react"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { Flex, LargeText } from "@ikiru/talentis-fpc"
import { useAssignment } from "../../../../assignment-module/assignment-module.context"
import { useAISearch } from "views/search/components/ChatGPTSaerch/hooks/useAISearch"
import isEmpty from "lodash/isEmpty"
import { sessionStorageKeys } from "setup/storage/storage.definitions"
import { useNavigate } from "react-router-dom"
import AdvancedSearch from "assets/advanced_search.svg"
import AISearch from "assets/ai_search.svg"
import QuickSearch from "assets/quick_search.svg"
import MyDatabase from "assets/my-database.svg"
import { SearchCard } from "components/SearchCard"
import { ReactComponent as GlobalSearchIcon } from "assets/global-search2.svg"
import { ReactComponent as SearchCRMDatabaseIcon } from "assets/search_CRM_database.svg"

export const CandidatesEmptyList = React.memo(() => {
  const { assignmentId, assignmentDetails } = useAssignment()

  const { getAISearchCriteria, updateSearchCriteria, filtersAI } = useAISearch()

  const navigate = useNavigate()

  const searchAI = useCallback(async () => {
    const data = await getAISearchCriteria(assignmentDetails.jobTitle)

    updateSearchCriteria(data)
  }, [getAISearchCriteria, updateSearchCriteria, assignmentDetails.jobTitle])

  useEffect(() => {
    if (!isEmpty(filtersAI)) {
      const companyIndustries =
        assignmentDetails.companyIndustries?.map((item) => item.name) || []

      const keywordBundleFilter = {
        keywordBundle: [
          {
            keywords: [
              ...companyIndustries,
              ...(assignmentDetails.companySpecialities || [])
            ]
          }
        ]
      }
      const filtersJson = JSON.stringify({
        ...filtersAI,
        ...keywordBundleFilter
      })

      sessionStorage.setItem(sessionStorageKeys.aIFilters, filtersJson)
      navigate(RouterUrl.Search + `/${assignmentId}/assignment`)
    }
  }, [
    filtersAI,
    assignmentDetails.companyIndustries,
    assignmentDetails.companySpecialities,
    assignmentId,
    navigate
  ])

  const quickSearch = () => {
    navigate(`${RouterUrl.ProfileNewContact}?assignmentId=${assignmentId}`)
  }

  const advancedSearch = () => {
    navigate(RouterUrl.Search + `/${assignmentId}/assignment`)
  }

  const localSearch = () => {
    navigate(`${RouterUrl.InternalSearch}?assignmentId=${assignmentId}`)
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      maxWidth="100%"
      alignItems="center"
      m="auto"
      textAlign="center"
      py="xl"
    >
      <LargeText mt="none" mb="s" color="grey.dark" fontWeight={600}>
        {messages.assignment.details.candidates.noCandidates}
      </LargeText>
      <Flex flexWrap="wrap">
        <SearchCard
          cardImg={QuickSearch}
          title={messages.assignment.details.candidates.quickSearch}
          description={
            messages.assignment.details.candidates.quickSearchDescription
          }
          onSelectCard={quickSearch}
          icon={GlobalSearchIcon}
        />
        <SearchCard
          cardImg={AdvancedSearch}
          title={messages.assignment.details.candidates.advancedSearch}
          description={
            messages.assignment.details.candidates.advancedSearchDescription
          }
          onSelectCard={advancedSearch}
          icon={GlobalSearchIcon}
        />
        <SearchCard
          cardImg={AISearch}
          title={messages.assignment.details.candidates.searchAI}
          description={
            messages.assignment.details.candidates.searchAIDescription
          }
          onSelectCard={searchAI}
          isDisabled={!assignmentDetails.isCompanyLinkedToDataPool}
          icon={GlobalSearchIcon}
        />
        <SearchCard
          cardImg={MyDatabase}
          title={messages.assignment.details.candidates.myDataBaseSearch}
          description={
            messages.assignment.details.candidates.myDataBaseSearchDescription
          }
          onSelectCard={localSearch}
          icon={SearchCRMDatabaseIcon}
          isSmallIcon
        />
      </Flex>
    </Flex>
  )
})
