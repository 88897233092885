import React, { useEffect, useRef } from "react"
import { Overlay } from "@ikiru/talentis-fpc"
import { PersonOverlayContainer, PersonContent } from "./style"
import { useChangeItem } from "./hook/useChangeItem"
import PersonOverlay from "./index"
import OverlayNavigation from "./OverlayNavigation"
import { useSearch } from "../../../contacts/SearchModule/context"
import { usePerson } from "../../person-module/candiate-module.context"
import { ILocalPersonModel } from "../../../../models/LocalPerson/LocalPerson"
import { getUpdatedPhotoAndCallBack } from "./utils"
import { useModal } from "utils/hooks/use-modal"

const ContactsOverlay = (props: { onPageChange: (page: number) => void }) => {
  const { onPageChange } = props
  const {
    getProfileData,
    personOverlayUpdated,
    setPersonOverlayUpdated,
    profileDetails,
    person,
    personOverlayPhotoUpdated
  } = usePerson()
  const {
    setSelectedSearchPersonId,
    selectedSearchPersonId,
    paginationProps,
    persons,
    updatePerson,
    isLoading
  } = useSearch()

  const { directionNext, directionPrevious, isLastElement, isFirstElement } =
    useChangeItem(
      { handlePageChange: onPageChange },
      paginationProps.pageNumber,
      paginationProps.pageSize,
      paginationProps.totalItemCount,
      persons || [],
      selectedSearchPersonId,
      setSelectedSearchPersonId,
      paginationProps.pageCount
    )

  useEffect(() => {
    if (isLoading) return
    if (isLastElement) {
      setSelectedSearchPersonId(persons[0].id)
      getProfileData(persons[0].id)
    } else if (isFirstElement) {
      setSelectedSearchPersonId(persons[paginationProps.pageSize - 1].id)
      getProfileData(persons[paginationProps.pageSize - 1].id)
    }
  }, [
    persons,
    getProfileData,
    isFirstElement,
    isLastElement,
    selectedSearchPersonId,
    setSelectedSearchPersonId,
    paginationProps.pageSize,
    isLoading
  ])

  let overLayRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (personOverlayUpdated) {
      setPersonOverlayUpdated(false)

      getUpdatedPhotoAndCallBack(
        person?.personId || selectedSearchPersonId,
        personOverlayPhotoUpdated,
        (photoUrl) => {
          let updatedPerson = person!
          updatePerson({
            ...updatedPerson,
            ...(photoUrl && { photo: { url: photoUrl } })
          } as unknown as ILocalPersonModel)
          setPersonOverlayUpdated(false)
        }
      )
    }
  }, [
    person,
    personOverlayPhotoUpdated,
    personOverlayUpdated,
    persons,
    profileDetails,
    selectedSearchPersonId,
    setPersonOverlayUpdated,
    updatePerson
  ])

  const { isOpen } = useModal()

  const closeOverlay = (e: any) => {
    if (!overLayRef?.current?.contains(e.target) && !isOpen) {
      setSelectedSearchPersonId("")
    }
  }

  return (
    <PersonOverlayContainer onClick={closeOverlay}>
      <PersonContent ref={overLayRef}>
        <Overlay visible>
          <OverlayNavigation
            directionNext={directionNext}
            directionPrevious={directionPrevious}
            closeOverlay={() => setSelectedSearchPersonId("")}
            oneContent
          />
          <PersonOverlay personId={selectedSearchPersonId} project="contact" />
        </Overlay>
      </PersonContent>
    </PersonOverlayContainer>
  )
}

export default ContactsOverlay
