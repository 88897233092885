import { FormikHelpers } from "formik"
import { apiRequest } from "setup/api/api"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { callWithErrorMessages } from "utils/forms/forms"
import { PaginationProps } from "components/functional/pagination/Pagination"
import { Nullable } from "tsdef"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const onFetchPersons = async (searchString = "*", pageNumber = 1) => {
  const [error, response] = await apiRequest.get({
    endpoint: PersonsEndpoints.Search,
    config: {
      params: {
        searchString: searchString,
        page: pageNumber
      },
      headers: {
        ...skipErrorHeader
      }
    }
  })

  if (!error && response?.data) {
    const { persons, ...newPagination } = response?.data
    return { results: persons, pagination: newPagination }
  } else {
    return { results: [], pagination: null, error }
  }
}

export const onSearchPersonSubmit = async (
  values: { searchString: string },
  helpers: FormikHelpers<{ searchString: string }>,
  pagination: Nullable<PaginationProps>
) => {
  const { setFieldError, setSubmitting } = helpers

  setSubmitting(true)

  const {
    error,
    results,
    pagination: newPagination
  } = await onFetchPersons(values.searchString || "*", pagination?.pageNumber)

  setSubmitting(false)

  callWithErrorMessages(setFieldError, error)

  if (!error) {
    return { results, pagination: newPagination }
  } else {
    return { results: null, pagination: null }
  }
}
