import { isEqual } from "lodash"
import { Experience } from "views/persons/person.types"

export const getCompensation = (experience: Experience, values: any) => {
  const oldCompensation = {
    comment: experience.compensation?.comment ?? "",
    package: experience.compensation?.package ?? {
      value: 0,
      currency: values.package.currency
    },
    salary: experience.compensation?.salary ?? {
      value: 0,
      currency: values.salary.currency
    }
  }
  const newCompensation = {
    comment: values.comment,
    package: values.package,
    salary: values.salary
  }

  let compensationChanged = false
  if (
    !experience.compensation &&
    values.salary.value === 0 &&
    values.package.value === 0 &&
    !values.comment
  ) {
    return { compensationChanged, newCompensation: undefined }
  }
  if (!isEqual(newCompensation, oldCompensation)) {
    compensationChanged = true

    if (oldCompensation.comment?.trim() === newCompensation.comment.trim()) {
      delete newCompensation.comment
    }

    if (isEqual(newCompensation.salary, oldCompensation.salary)) {
      delete newCompensation.salary
    } else {
      if (
        String(oldCompensation.salary?.value).trim() ===
        String(newCompensation.salary?.value).trim()
      ) {
        if (
          !String(newCompensation.salary?.value).trim() ||
          newCompensation.salary?.value === 0
        ) {
          delete newCompensation.salary
        }
      } else {
        if (!String(newCompensation.salary?.value).trim()) {
          newCompensation.salary = null
        }
      }
    }

    if (isEqual(newCompensation.package, oldCompensation.package)) {
      delete newCompensation.package
    } else {
      if (
        String(oldCompensation.package?.value).trim() ===
        String(newCompensation.package?.value).trim()
      ) {
        if (
          !String(newCompensation.package?.value).trim() ||
          newCompensation.package?.value === 0
        ) {
          delete newCompensation.package
        }
      } else {
        if (!String(newCompensation.package?.value).trim()) {
          newCompensation.package = null
        }
      }
    }
  }

  return { compensationChanged, newCompensation }
}
