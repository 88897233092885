import React from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { FormikDatepicker } from "components/functional/formik/formik-datepicker/FormikDatepicker"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { EditPersonExperienceField } from "../../../person.types"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import CompanySearch from "../../../../assignments/components/assignment-company/CompanySearch"
import { CompanySelected } from "../../../../assignments/components/assignment-company/types"

type EditPersonExperienceFormLayoutProps = {
  companyValue: CompanySelected
  setCompanyValue?: React.Dispatch<React.SetStateAction<CompanySelected>>
  companyEditable?: boolean
}
export const EditPersonExperienceFormLayout = ({
  companyValue,
  setCompanyValue,
  companyEditable = false
}: EditPersonExperienceFormLayoutProps) => (
  <Div alignItems="center">
    <Div width="100%">
      <FormikInput
        id={EditPersonExperienceField.Title}
        name={EditPersonExperienceField.Title}
        label={messages.form.generic.jobTitle}
      />
    </Div>
    <Div width="100%">
      {companyEditable ? (
        <CompanySearch
          id={EditPersonExperienceField.Company}
          name={`${EditPersonExperienceField.Company}.value`}
          label={messages.form.generic.company}
          selectedCompany={setCompanyValue}
          isEdit
          initialSearchedWord={companyValue.name}
        />
      ) : (
        <FormikInput
          id={EditPersonExperienceField.Company}
          name={`${EditPersonExperienceField.Company}.value`}
          label={messages.form.generic.company}
          value={companyValue.name}
          disabled
        />
      )}
    </Div>
    <Flex mt="xs">
      <Div mr="xxs" width="50%">
        <FormikDatepicker
          id={EditPersonExperienceField.StartDate}
          name={EditPersonExperienceField.StartDate}
          label={messages.form.generic.startDate}
        />
      </Div>
      <Div mb="xxs" ml="xxs" width="50%">
        <FormikDatepicker
          id={EditPersonExperienceField.EndDate}
          name={EditPersonExperienceField.EndDate}
          label={messages.form.generic.endDate}
        />
      </Div>
    </Flex>
    <Div mt="xs" width="100%">
      <FormikTextarea
        id={EditPersonExperienceField.Description}
        name={EditPersonExperienceField.Description}
        label={messages.form.generic.description}
        rows={4}
      />
    </Div>
  </Div>
)
