import React from "react"
import { SearchDropdown, SearchDropdownItem } from "./style"

type ProjectListSearchProps = {
  projectNames: string[]
  onItemClicked: (projectName: string) => void
}

export const ProjectListSearch = ({
  projectNames,
  onItemClicked
}: ProjectListSearchProps) => {
  const selectSearchProject = (projectName: string) => {
    onItemClicked(projectName)
  }

  return (
    <SearchDropdown>
      {projectNames.map((projectName: string, index) => (
        <SearchDropdownItem
          key={projectName + index}
          onClick={() => selectSearchProject(projectName)}
        >
          {projectName}
        </SearchDropdownItem>
      ))}
    </SearchDropdown>
  )
}
