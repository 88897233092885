import styled from "styled-components"
import { NavLink } from "react-router-dom"

export const ActionsContainer = styled.div`
  display: flex;
  margin-left: ${({ theme }) => 0.75 * theme.space.xs}px;
  margin-top: ${({ theme }) => theme.space.xs}px;
`

export const ActionLink = styled(NavLink)`
  text-decoration: none !important;
`
