import { apiRequest } from "setup/api/api"
import { expandEndpointByParams } from "setup/api/api.helpers"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { callWithErrorMessages } from "utils/forms/forms"
import { GetOnAddNotesSubmitType, GetOnEditNotesSubmitType } from "./types"

export const getOnAddPersonNoteSubmit: GetOnAddNotesSubmitType =
  (personId, assignmentId, onSubmit) => async (values, helpers) => {
    const { setFieldError } = helpers

    const data = {
      noteTitle: values.noteTitle,
      noteDescription: values.noteDescription,
      assignmentId
    }

    const [error, response] = await apiRequest.post({
      endpointParams: { id: personId },
      endpoint: PersonsEndpoints.Notes,
      config: {
        ...expandEndpointByParams({
          expand: ["createdByUser", "assignment", "updatedByUser"],
          data: {
            skipError: true
          }
        })
      },
      data
    })

    if (error) {
      callWithErrorMessages(setFieldError, error)
      helpers.setSubmitting(false)
    } else {
      onSubmit(response?.data)
    }
  }

export const getOnEditPersonNoteSubmit: GetOnEditNotesSubmitType =
  (personId, noteId, onSubmit) => async (values, helpers) => {
    const { setFieldError } = helpers

    const data = {
      noteTitle: values.noteTitle,
      noteDescription: values.noteDescription,
      assignmentId: values.assignmentId || null
    }

    const [error, response] = await apiRequest.put({
      endpointParams: { id: personId, noteId: noteId },
      endpoint: PersonsEndpoints.Notes,
      config: expandEndpointByParams({
        expand: ["createdByUser", "assignment", "updatedByUser"],
        data: {
          skipError: true
        }
      }),
      data
    })

    if (error) {
      callWithErrorMessages(setFieldError, error)
      helpers.setSubmitting(false)
    } else {
      onSubmit(response?.data)
    }
  }
