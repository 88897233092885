import styled from "styled-components"

export const ExtensionPicture = styled.picture`
  display: flex;
  ${({ theme }) => `
  ${theme.mediaQueries.sm} {
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100%;
  }
`}
`

export const BrowserExtensionLinkWrapper = styled.a`
  color: inherit;
  text-decoration: inherit;
`
