import { messages } from "setup/messages/messages"
import { Currency } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"

export const currencyOptions = [
  {
    label: messages.subscription.currencies.usd,
    value: Currency.USD
  },
  {
    label: messages.subscription.currencies.aud,
    value: Currency.AUD
  },
  {
    label: messages.subscription.currencies.gbp,
    value: Currency.GBP
  },
  {
    label: messages.subscription.currencies.eur,
    value: Currency.EUR
  }
]
