import React from "react"
import {
  Div,
  Flex,
  Avatar,
  H4,
  H6,
  H5,
  BodyText,
  Link
} from "@ikiru/talentis-fpc"
import { ProjectTeam } from "components/ProjectTeamMembers/ProjectTeam"
import { RouterUrl } from "setup/router/routes"
import { formatDate } from "utils/format-date"
import { assignmentStatusMap } from "views/assignments/constants/assignment-statuses"
import {
  AvatarDiv,
  DateFlex,
  DateLabel,
  LinkStyled,
  MobileFlex,
  StatusDiv,
  StyledSectionBody
} from "views/assignments/pages/manage-assignment/style"
import { messages } from "setup/messages/messages"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import useSubmitAssignmentDetails from "views/assignments/hooks/useSubmitAssignmentDetails"
import AssignmentPopUpActions from "./AssignmentActions"

const AssignmentDetailView = () => {
  const { assignmentDetails } = useAssignment()
  const { assignmentOpenModal } = useSubmitAssignmentDetails()

  return (
    <StyledSectionBody>
      <Div width="100%" flexWrap="wrap" position="relative">
        <Div width="100%">
          <Flex pl={0}>
            <AvatarDiv>
              <Link
                target="_blank"
                href={`${RouterUrl.CompanyDetails}/${assignmentDetails.companyId}`}
              >
                <Avatar
                  name={assignmentDetails.name}
                  photo={{ url: assignmentDetails.companyLogo }}
                />
              </Link>
            </AvatarDiv>
            <Flex position="relative">
              <Div>
                <MobileFlex>
                  <LinkStyled
                    target="_blank"
                    href={`${RouterUrl.CompanyDetails}/${assignmentDetails.companyId}`}
                  >
                    <H4 color="green.standard" m="0">
                      {assignmentDetails.name}
                    </H4>
                  </LinkStyled>
                  <StatusDiv>
                    {assignmentDetails.status && (
                      <H6 color="grey.standard">
                        {assignmentStatusMap.get(assignmentDetails.status)}
                      </H6>
                    )}
                  </StatusDiv>
                </MobileFlex>
                <H5 my="xxs">{assignmentDetails.location}</H5>
                <ProjectTeam
                  title={messages.assignment.assignmentTeam}
                  projectTeamMembers={assignmentDetails.teamMembers}
                  useAddButton
                  showAddButton
                  onClickAddButton={assignmentOpenModal}
                />
              </Div>
            </Flex>

            <DateFlex>
              <Div>
                {assignmentDetails.startDate && (
                  <Flex justifyContent="flex-end">
                    <DateLabel>
                      {messages.assignment.assignmentProfileStartDate}:
                    </DateLabel>
                    &nbsp;
                    <BodyText my={0}>
                      {formatDate(assignmentDetails.startDate)}
                    </BodyText>
                  </Flex>
                )}
              </Div>
              <Div>
                {assignmentDetails.endDate && (
                  <Flex justifyContent="flex-end" mt="xs">
                    <DateLabel>
                      {messages.assignment.assignmentProfileEndDate}:
                    </DateLabel>
                    &nbsp;
                    <BodyText my={0}>
                      {formatDate(assignmentDetails.endDate)}
                    </BodyText>
                  </Flex>
                )}
              </Div>
              <Flex justifyContent="flex-end" mt="xs">
                <AssignmentPopUpActions />
              </Flex>
            </DateFlex>
          </Flex>
        </Div>
      </Div>
    </StyledSectionBody>
  )
}

export default AssignmentDetailView
