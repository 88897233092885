import styled from "styled-components"
import { Flex, Div } from "@ikiru/talentis-fpc"
import get from "lodash/get"
import { spanTagColor } from "./helpers"
import { hexToRGB } from "utils/hexToRGB"

export const ProjectFilterContainer = styled(Div)`
  height: 45px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;
  background-color: ${({ theme }) => hexToRGB(theme.colors.grey.light, 0.5)};
`

type StyledSpanProps = {
  background: string
}

export const FilterPopupContainer = styled(Flex)`
  flex-direction: column;
  height: calc(100% - 4px);
`

export const FilterPopupContent = styled(Flex)`
  flex-direction: column;
  padding: 10px;
  overflow: auto;
`

export const StyledSpan = styled.span<StyledSpanProps>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: -2px;
  background-color: ${({ theme, background }) =>
    get(theme.colors, spanTagColor(background))};
`
