import React from "react"
import get from "lodash/get"
import { Div, H4 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useSearch } from "views/search/SearchModule/context"
import {
  FilterType,
  CompanySize as CompanySizeValues
} from "views/search/SearchModule/types"
import { FormikToggle } from "components/functional/formik/formik-toggle/FormikToggle"
import { SearchCriteriaField } from "../definitions"
import { useCheckboxField } from "../hooks"
import { CurrentPastBothToggle } from "../components/CurrentPastBothToggle"

export const CompanySizeFilter = () => {
  const { filters } = useSearch()

  const getSize = (size: string) => {
    return get(filters, `${FilterType.CompanySize}.${size}`, false)
  }

  const onChangeSizeVerySmall = useCheckboxField(
    `${FilterType.CompanySize}.${CompanySizeValues.VERYSMALL}`
  )
  const onChangeSizeSmall = useCheckboxField(
    `${FilterType.CompanySize}.${CompanySizeValues.SMALL}`
  )
  const onChangeSizeMedium = useCheckboxField(
    `${FilterType.CompanySize}.${CompanySizeValues.MEDIUM}`
  )
  const onChangeSizeLarge = useCheckboxField(
    `${FilterType.CompanySize}.${CompanySizeValues.LARGE}`
  )
  const onChangeSizeVeryLarge = useCheckboxField(
    `${FilterType.CompanySize}.${CompanySizeValues.VERYLARGE}`
  )

  const sizeItems = [
    {
      value: getSize(CompanySizeValues.VERYSMALL),
      label: messages.talentGraphSearch.companySize.verySmall,
      onChange: onChangeSizeVerySmall.onChange
    },
    {
      value: getSize(CompanySizeValues.SMALL),
      label: messages.talentGraphSearch.companySize.small,
      onChange: onChangeSizeSmall.onChange
    },
    {
      value: getSize(CompanySizeValues.MEDIUM),
      label: messages.talentGraphSearch.companySize.medium,
      onChange: onChangeSizeMedium.onChange
    },
    {
      value: getSize(CompanySizeValues.LARGE),
      label: messages.talentGraphSearch.companySize.large,
      onChange: onChangeSizeLarge.onChange
    },
    {
      value: getSize(CompanySizeValues.VERYLARGE),
      label: messages.talentGraphSearch.companySize.veryLarge,
      onChange: onChangeSizeVeryLarge.onChange
    }
  ]

  return (
    <Div width="100%">
      <H4 mt="0px" mb="xxs" color="grey.dark">
        {messages.talentGraphSearch.filters.companySize}
      </H4>
      {sizeItems.map((item) => (
        <FormikToggle
          key={JSON.stringify(item)}
          small
          type="checkbox"
          hideUnderline
          notCheckedFade
          onChange={item.onChange}
          checked={item.value === true}
          name={FilterType.CompanySize}
          labelProps={{ ml: "xxs" }}
          label={item.label}
        />
      ))}
      <CurrentPastBothToggle
        fieldName={SearchCriteriaField.CompanySizeLogic}
        filterType={FilterType.CompanySizeLogic}
      />
    </Div>
  )
}
