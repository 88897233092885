import { loadAzureLocationsOptions } from "components/AzureLocation/helper"
import { FormikMultiselectOptionType } from "components/functional/formik/formik-multiselect/FormikMultiselect"
import { useCallback, useEffect, useState } from "react"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { AzureLocation } from "views/search/SearchModule/types"

const useAzureMapsTypeAhead = () => {
  const { assignmentDetails } = useAssignment()
  const [showAzureMapList, setShowAzureMapList] = useState<boolean>(false)
  const [locations, setLocations] = useState<
    FormikMultiselectOptionType<AzureLocation>[]
  >([])

  const [searchedWord, setSearchedWord] = useState<string>("")

  const findLocations = useCallback(
    (defaultSearch: string = "") => {
      loadAzureLocationsOptions(searchedWord || defaultSearch).then(
        (value: FormikMultiselectOptionType<AzureLocation>[]) =>
          setLocations(value)
      )
    },
    [searchedWord]
  )

  useEffect(() => {
    findLocations()
  }, [findLocations])

  const setDefaultValue = useCallback(() => {
    if (!assignmentDetails?.geoLocation) {
      findLocations(assignmentDetails?.location)
      setShowAzureMapList(true)
    } else {
      setSearchedWord(assignmentDetails?.location)
    }
  }, [findLocations, assignmentDetails])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setDefaultValue, [])

  return {
    setLocations,
    locations,
    showAzureMapList,
    searchedWord,
    setSearchedWord,
    setShowAzureMapList,

    findLocations
  }
}

export default useAzureMapsTypeAhead
