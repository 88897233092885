import React from "react"

type SubmitEditableSectionButtonProps = {
  innerRef: React.MutableRefObject<HTMLButtonElement>
} & Omit<React.HTMLProps<HTMLButtonElement>, "hidden" | "type" | "aria-label">

export const SubmitEditableSectionButton = (
  props: SubmitEditableSectionButtonProps
) => {
  const { innerRef, ...other } = props

  return <button ref={innerRef} hidden aria-label="submit-button" {...other} />
}
