import { isEmpty } from "lodash"
import { useMemo, useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { apiRequest } from "setup/api/api"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { RouterUrl } from "setup/router/routes"
import { sessionStorageKeys } from "setup/storage/storage.definitions"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { excludeArchive } from "views/assignments/utils"
import { Candidate } from "views/candidates/candidates.types"
import { useAISearch } from "views/search/components/ChatGPTSaerch/hooks/useAISearch"
import { getAppliedFiltersParams } from "../components/candidate-filters/helper"
import { useExportCandidates } from "./useExportCandidates"
import { SubscriptionType } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"
import { useAuth } from "setup/auth/module/auth.context"

const useAssignmentActions = () => {
  const {
    assignmentId,
    assignmentDetails,
    totalItems,
    filterTotalNumber,
    appliedFilters,
    selectedStage
  } = useAssignment()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { getAISearchCriteria, updateSearchCriteria, filtersAI } = useAISearch()

  const { isExporting, isError, exportCandidates, setIsExporting } =
    useExportCandidates({
      assignmentId,
      assignmentName: assignmentDetails?.name || "",
      totalItems
    })

  const isTrial = user?.planType?.toLocaleLowerCase() === SubscriptionType.Trial

  const getCandidatesIds = async (pageSize: number, isAll = false) => {
    setIsExporting(true)
    let params = new URLSearchParams()

    const filterStage = selectedStage !== "all" ? selectedStage : null

    if (!isAll) {
      params = getAppliedFiltersParams(appliedFilters)
      filterStage && params.append("stages", filterStage)
      params = excludeArchive(selectedStage, params, isAll)
    } else {
      params = excludeArchive(selectedStage, params, isAll)
    }

    params.append("assignmentId", assignmentId)
    params.append("expand", "person")
    params.append("pageSize", pageSize.toString())

    const [, response] = await apiRequest.get({
      endpoint: CandidatesEndpoints.Root,
      config: { params: params }
    })
    const candidates = response?.data?.candidates
    const candidateIds = (candidates || []).map((c: Candidate) => c.id)

    return candidateIds
  }

  const exportCandidatesWithOutArchive = async () => {
    // API should filter Arhieved by default
    exportCandidates()
  }

  const filterExists = appliedFilters.length !== 0

  const exportWithFilters = async () => {
    const candidateIds = await getCandidatesIds(filterTotalNumber)
    exportCandidates(candidateIds)
  }

  const filterExistAndNotAll = useMemo(
    () => filterExists || selectedStage !== "all",
    [filterExists, selectedStage]
  )

  const searchAI = useCallback(async () => {
    const data = await getAISearchCriteria(assignmentDetails.jobTitle)

    updateSearchCriteria(data)
  }, [getAISearchCriteria, updateSearchCriteria, assignmentDetails.jobTitle])

  useEffect(() => {
    if (!isEmpty(filtersAI)) {
      const companyIndustries =
        assignmentDetails.companyIndustries?.map((item) => item.name) || []

      const keywordBundleFilter = {
        keywordBundle: [
          {
            keywords: [
              ...companyIndustries,
              ...(assignmentDetails.companySpecialities || [])
            ]
          }
        ]
      }
      const filtersJson = JSON.stringify({
        ...filtersAI,
        ...keywordBundleFilter
      })
      sessionStorage.setItem(sessionStorageKeys.aIFilters, filtersJson)
      navigate(RouterUrl.Search + `/${assignmentId}/assignment`)
    }
  }, [
    filtersAI,
    assignmentDetails.companyIndustries,
    assignmentDetails.companySpecialities,
    assignmentId,
    navigate
  ])

  return {
    searchAI,
    filterExistAndNotAll,
    exportWithFilters,
    exportCandidatesWithOutArchive,
    isExporting,
    isError,
    isTrial
  }
}

export default useAssignmentActions
