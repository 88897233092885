import * as Yup from "yup"
import { PersonNoteField } from "./definitions"
import { messages } from "setup/messages/messages"
import {
  BiographyField,
  CommentaryField
} from "components/Notes/components/forms/definitions"

export const addNoteValidationSchema = Yup.object({
  [PersonNoteField.NoteTitle]: Yup.string()
    .max(100, messages.form.generic.errors.mustBeNoLongerThan.format(100))
    .required(messages.generic.required)
})

export const addCommentaryValidationSchema = Yup.object({
  [CommentaryField.CommentaryDescription]: Yup.string().required(
    messages.generic.required
  )
})

export const addBiographyValidationSchema = Yup.object({
  [BiographyField.BiographyDescription]: Yup.string().required(
    messages.generic.required
  )
})
