import React from "react"
import { Person } from "views/persons/person.types"
import { existingPersonsE2ETargets } from "views/persons/pages/create-person/create-person.definitions"
import { CreatePersonField } from "views/persons/components/person-details/form/constants/definitions"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { RouterLink } from "components/RouterLink/RouterLink"

type ExistingPersonsPops = {
  persons: Person[]
}

export const ExistingPersons = (props: ExistingPersonsPops) => {
  const { persons } = props
  return (
    <aside style={{ gridColumn: "6 / 8" }}>
      <p>{messages.createPerson.warning}</p>
      <ul data-e2e-target-name={existingPersonsE2ETargets.list}>
        {persons.map((user) => {
          return (
            <li key={user.id}>
              <div
                style={{
                  width: "300px",
                  border: "1px solid black",
                  borderRadius: "10px",
                  marginBottom: "10px",
                  padding: "10px"
                }}
              >
                <p
                  data-e2e-target-name={existingPersonsE2ETargets.name}
                  style={{ margin: 0 }}
                >
                  {user[CreatePersonField.Name]}
                </p>
                <p style={{ margin: 0 }}>
                  {user[CreatePersonField.EmailAddresses] ? (
                    <ul>
                      {user[CreatePersonField.EmailAddresses].map(
                        (taggedEmail) => (
                          <li data-e2e-target={existingPersonsE2ETargets.email}>
                            {taggedEmail.email}
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    "(no email)"
                  )}
                </p>
                <p style={{ margin: 0 }}>
                  {user[CreatePersonField.Company] || "(no company)"}
                </p>
                <RouterLink
                  data-e2e-target-name={existingPersonsE2ETargets.link}
                  to={`${RouterUrl.ProfileDetails}/${user.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {messages.createPerson.viewPerson}
                </RouterLink>
              </div>
            </li>
          )
        })}
      </ul>
    </aside>
  )
}
