import { Div, Flex } from "@ikiru/talentis-fpc"
import React from "react"
import { KeywordsList } from "./KeywordsList"
import PersonNoDataScreen from "../../person-no-data-message/PersonNoDataScreen"
import { messages } from "setup/messages/messages"
import { Keyword } from "views/persons/person-module/person-module.types"

type PersonKeywordViewProps = {
  keywords: Keyword[]
}
const PersonKeywordView = ({ keywords }: PersonKeywordViewProps) => {
  const isKeywords = keywords.length
  return (
    <Flex justifyContent="center" width="100%">
      {Boolean(isKeywords) ? (
        <Div width="100%" pt="xs">
          <KeywordsList keywords={keywords} />
        </Div>
      ) : (
        <PersonNoDataScreen
          message={messages.person.personTabSection.noDataTexts.noAttributes}
        />
      )}
    </Flex>
  )
}

export default PersonKeywordView
