import React from "react"
import {
  PeopleIcon,
  CompaniesIcon,
  PhoneIcon,
  Flex,
  EmailWhiteIcon as EmailIcon
} from "@ikiru/talentis-fpc"
import {
  EmailTypeEnums,
  PhoneTypeEnums
} from "../../person-details-tab/components/person-contact-info/form/constants/definitions"

type UsageIconType = {
  usageType: PhoneTypeEnums | EmailTypeEnums
  isEmail?: boolean
  viewBox?: string
  scaleUp?: number
}

const UsageIcon = (usageType: UsageIconType) => {
  const Icon = () => {
    switch (usageType.usageType) {
      case PhoneTypeEnums.Personal || EmailTypeEnums.Personal:
        return (
          <PeopleIcon
            style={{
              transform: `scale(${usageType.scaleUp ? usageType.scaleUp : 1})`
            }}
            width="18px"
            height="18px"
            viewBox={usageType.viewBox ? usageType.viewBox : "-7 -7 25 25"}
          />
        )
      case PhoneTypeEnums.BusinessDirect:
        return (
          <CompaniesIcon
            style={{
              transform: `scale(${usageType.scaleUp ? usageType.scaleUp : 1})`
            }}
            width="18px"
            height="18px"
            viewBox={usageType.viewBox ? usageType.viewBox : "-7 -7 25 25"}
          />
        )
      case PhoneTypeEnums.Business || EmailTypeEnums.Business:
        return (
          <CompaniesIcon
            style={{
              transform: `scale(${usageType.scaleUp ? usageType.scaleUp : 1})`
            }}
            width="18px"
            height="18px"
            viewBox={usageType.viewBox ? usageType.viewBox : "-7 -7 25 25"}
          />
        )

      case PhoneTypeEnums.Other || EmailTypeEnums.Other:
        return Boolean(usageType.isEmail) ? (
          <EmailIcon
            style={{
              transform: `scale(${usageType.scaleUp ? usageType.scaleUp : 1})`
            }}
            width="18px"
            height="18px"
            viewBox={usageType.viewBox ? usageType.viewBox : "-4 -7 25 25"}
          />
        ) : (
          <PhoneIcon
            style={{
              transform: `scale(${usageType.scaleUp ? usageType.scaleUp : 1})`
            }}
            width="18px"
            height="18px"
            viewBox={usageType.viewBox ? usageType.viewBox : "-11 -9 25 25"}
          />
        )

      default:
        return <></>
    }
  }

  return (
    <Flex width="20px" height="23px">
      {Icon()}
    </Flex>
  )
}

export default UsageIcon
