import styled from "styled-components"
import { Flex, colors, spacing } from "@ikiru/talentis-fpc"

export const ExistingDataColumnWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.grey.standard};
  width: 42.2%;
  height: 31px;
  color: ${colors.white.standard};
  padding-left: ${spacing.xxs}px;
  padding-right: ${spacing.xs}px;
`

export const ParsedDataWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.green.lightest};
  width: 42.35%;
  height: 31px;
  padding-left: ${spacing.xxs}px;
  padding-right: 6px;
`

export const OverviewSectionWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.green.lightest};
  height: 31px;
  padding-left: ${spacing.xxs}px;
  padding-right: 6px;
`
