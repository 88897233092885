import React from "react"
import { messages } from "setup/messages/messages"
import { Helmet } from "react-helmet"
import { InternalSearchModule } from "./InternalSearchModule"
import { InternalSearchPage } from "."
import { CustomSwitch } from "setup/router/CustomSwitch"
import { Route, useResolvedPath } from "react-router-dom"
import { RouterSubUrl, RouterUrl } from "setup/router/routes"
import { ModalOpener } from "setup/modal/ModalOpener"
import { ModalNames } from "setup/modal/modal.definitions"
import { ContactAddModal } from "views/persons/components/contact-add-modal"
import { H1 } from "@ikiru/talentis-fpc"

export const InternalSearch = () => {
  const path = useResolvedPath("").pathname
  return (
    <>
      <CustomSwitch>
        <Route path={path} element={<H1>{messages.person.title}</H1>} />

        <Route
          path={RouterSubUrl.InternalSearch}
          element={
            <>
              <Helmet>
                <title>
                  {messages.seo.title.format(messages.seo.internalSearch)}
                </title>
              </Helmet>

              <InternalSearchModule>
                <InternalSearchPage />
              </InternalSearchModule>
            </>
          }
        />

        <Route
          path={RouterSubUrl.InternalSearchNewContact}
          element={
            <>
              <Helmet>
                <title>
                  {messages.seo.title.format(messages.seo.addNewContact)}
                </title>
              </Helmet>
              <ModalOpener
                name={ModalNames.NewContact}
                modal={<ContactAddModal />}
                returnRoute={RouterUrl.InternalSearch}
              />
            </>
          }
        />
      </CustomSwitch>
    </>
  )
}
