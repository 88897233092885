import React, { useReducer, useCallback, useEffect } from "react"
import { PaymentIntent } from "@chargebee/chargebee-js-react-wrapper"
import { apiRequest } from "setup/api/api"
import { messages } from "setup/messages/messages"
import { useRefetchExtensionUser } from "setup/browser-extension/hooks"
import {
  SubscriptionEndpoints,
  UsersEndpoints
} from "setup/api/endpoints/endpoints"
import { centsToDollars } from "utils/format-price"
import {
  Currency,
  PaymentEstimateResponse,
  SubscriptionPlan,
  SubscriptionPlanDetails,
  SubscriptionPurchaseActionTypes,
  SubscriptionPurchaseStep,
  ChargebeePlan,
  PaymentEstimate
} from "./subscription-purchase-module.types"
import {
  initialSubscriptionPurchaseState,
  subscriptionReducer
} from "./subscription-purchase-module.reducer"
import { SubscriptionPurchaseContext } from "./subscription-purchase-module.context"
import { mapChargebeePlans } from "./plan-helpers"
import { autoAddedCoupons, Coupon } from "./coupons"
import { TeamMember } from "views/team/team/team-module.types"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { chain, get, sortBy } from "lodash"
import { sortTeamMembers } from "views/team/team/utils"
import { useAuth } from "setup/auth/module/auth.context"

export const SubscriptionPurchaseModule: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { user } = useAuth()

  const { tellExtensionToRefetchUser } = useRefetchExtensionUser()

  const [state, dispatch] = useReducer(
    subscriptionReducer,
    initialSubscriptionPurchaseState
  )

  const changeStep = useCallback(
    (step: SubscriptionPurchaseStep) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.ChangeStep,
        payload: { step }
      })
    },
    [dispatch]
  )

  const setStepTitle = useCallback(
    (stepTitle: string) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.ChangeStepTitle,
        payload: stepTitle
      })
    },
    [dispatch]
  )

  const selectPlan = useCallback(
    (plan: SubscriptionPlan) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.SelectSubscriptionPlan,
        payload: { plan }
      })
    },
    [dispatch]
  )

  const startFetchingPlans = useCallback(() => {
    dispatch({ type: SubscriptionPurchaseActionTypes.StartFetchingPlans })
  }, [dispatch])

  const stopFetchingPlans = useCallback(
    (plans: SubscriptionPlan[]) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.StopFetchingPlans,
        payload: { plans }
      })
    },
    [dispatch]
  )

  const setUsersCount = useCallback(
    (count: []) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.SetUsersCount,
        payload: count
      })
    },
    [dispatch]
  )

  const setPlanDetails = useCallback(
    (planDetails: SubscriptionPlanDetails) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.SetPlanDetails,
        payload: planDetails
      })
    },
    [dispatch]
  )

  const setPaymentComplete = useCallback(
    (complete: boolean) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.SetPaymentComplete,
        payload: complete
      })
    },
    [dispatch]
  )

  const addCoupon = useCallback(
    (...coupons: Coupon[]) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.AddCoupon,
        payload: coupons
      })
    },
    [dispatch]
  )

  const removeCoupon = useCallback(
    (...coupons: Coupon[]) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.RemoveCoupon,
        payload: coupons
      })
    },
    [dispatch]
  )

  const setIsLoading = useCallback(
    (isLoading: boolean, loadingMessage: string = "") => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.SetIsLoading,
        payload: { isLoading, loadingMessage }
      })
    },
    [dispatch]
  )

  const setPaymentEstimate = useCallback(
    (paymentEstimate: PaymentEstimate) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.SetPaymentEstimate,
        payload: paymentEstimate
      })
    },
    [dispatch]
  )

  const setPaymentIntent = useCallback(
    (paymentIntent: any) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.SetPaymentIntent,
        payload: paymentIntent
      })
    },
    [dispatch]
  )

  const setPaymentError = useCallback(
    (error: any) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.SetPaymentError,
        payload: error
      })
    },
    [dispatch]
  )

  const setBillingDetails = useCallback(
    (billingDetails: any) => {
      dispatch({
        type: SubscriptionPurchaseActionTypes.SetBillingDetails,
        payload: billingDetails
      })
    },
    [dispatch]
  )

  const fetchUsers = useCallback(async () => {
    const [, response] = await apiRequest.get({
      endpoint: UsersEndpoints.Root
    })

    const activeUsers =
      response?.data?.users?.filter(
        (user: TeamMember) =>
          user.isDisabled === false || user.status === "invited"
      ) || []

    const {
      complete = [],
      invited = [],
      invitedForNewSearchFirm = []
    } = chain(activeUsers).groupBy("status").value()

    const userId = get(user, "profile.UserId")

    let teamMembers: TeamMember[] = [...invitedForNewSearchFirm, ...complete]

    teamMembers = sortTeamMembers(teamMembers, userId)

    //@ts-ignore
    setUsersCount([...teamMembers, ...sortBy(invited, "emailAddress")])
  }, [setUsersCount, user])

  const addAutoApplicableCoupons = useCallback(() => {
    addCoupon(...autoAddedCoupons)
  }, [addCoupon])

  const fetchPlans = useCallback(
    async (currency?: Currency, coupons: string[] = []) =>
      apiRequest.post<ChargebeePlan[]>({
        endpoint: SubscriptionEndpoints.Plans,
        data: {
          coupons,
          ...(currency ? { currency } : {})
        }
      }),
    []
  )

  const fetchAvailablePlans = useCallback(
    async (currency?: Currency) => {
      startFetchingPlans()

      const [, response] = await fetchPlans(
        currency,
        autoAddedCoupons.map(({ code }) => code)
      )

      const plans = response?.data || []

      const transformedPlans = mapChargebeePlans(plans)

      stopFetchingPlans(transformedPlans)
    },
    [fetchPlans, startFetchingPlans, stopFetchingPlans]
  )

  const fetchPaymentIntent = useCallback(
    async (
      subscriptionPlanId: string,
      subscriptionStartDate: string,
      unitQuantity: number,
      couponids: string[],
      billingAddressEmail: string,
      billingAddressLine1: string,
      billingAddressCity: string,
      billingAddressCountryCode: string,
      billingAddressZipOrPostCode: string,
      customerVatNumber?: string
    ) => {
      const [, response] = await apiRequest.post<PaymentIntent>({
        endpoint: SubscriptionEndpoints.CreatePaymentIntent,
        data: {
          subscriptionPlanId,
          subscriptionStartDate,
          unitQuantity,
          couponids,
          billingAddressEmail,
          billingAddressLine1,
          billingAddressCity,
          billingAddressCountryCode,
          billingAddressZipOrPostCode,
          customerVatNumber
        }
      })

      setPaymentIntent(response?.data || null)

      return response?.data || null
    },
    [setPaymentIntent]
  )

  const createSubscription = useCallback(
    async (
      id: string,
      plan: string,
      couponCodes: string[],
      usersCount: number,
      users: string[]
    ) => {
      const [error, response] = await apiRequest.post<void>({
        endpoint: SubscriptionEndpoints.CreateSubscription,
        data: {
          paymentIntentId: id,
          subscriptionPlanId: plan,
          couponIds: couponCodes,
          unitQuantity: usersCount,
          users: users
        },
        config: {
          headers: { ...skipErrorHeader }
        }
      })

      if (error) {
        throw new Error(messages.generic.errorTryAgain)
      }

      return response?.data || null
    },
    []
  )

  const fetchEstimate = useCallback(
    async (
      subscriptionPlanId: string,
      subscriptionStartDate: string,
      unitQuantity: number,
      couponids: string[],
      billingAddressCountryCode: string,
      billingAddressZipOrPostCode: string,
      customerVatNumber?: string
    ) => {
      const [error, response] = await apiRequest.post<PaymentEstimateResponse>({
        endpoint: SubscriptionEndpoints.Estimate,
        data: {
          subscriptionPlanId,
          subscriptionStartDate,
          unitQuantity,
          couponids,
          billingAddressCountryCode,
          billingAddressZipOrPostCode,
          customerVatNumber
        },
        config: {
          headers: { ...skipErrorHeader }
        }
      })

      if (error) {
        // If the invalid promo code is a user added one, throw an error
        const autoAddedCouponCodes = autoAddedCoupons.map((c) => c.code)
        const invalidUserCoupon = error.data.invalidCoupons?.every(
          (c: string) => !autoAddedCouponCodes.includes(c)
        )

        if (invalidUserCoupon) {
          throw new Error(messages.subscription.promoCode.promoCodeError)
        }

        throw new Error(messages.generic.errorTryAgain)
      }

      if (!response) {
        throw new Error(messages.generic.errorTryAgain)
      }

      const { total, amount, discount, taxAmount } = response.data

      setPaymentEstimate({
        total: centsToDollars(total),
        amount: centsToDollars(amount),
        discount: centsToDollars(discount),
        taxAmount: centsToDollars(taxAmount)
      })

      return response.data || null
    },
    [setPaymentEstimate]
  )

  useEffect(() => {
    addAutoApplicableCoupons()
    fetchAvailablePlans()
    fetchUsers()
  }, [addAutoApplicableCoupons, fetchAvailablePlans, fetchUsers])

  useEffect(() => {
    if (state.paymentData.paymentComplete) {
      tellExtensionToRefetchUser()
    }
  }, [state.paymentData.paymentComplete, tellExtensionToRefetchUser])

  return (
    <SubscriptionPurchaseContext.Provider
      value={{
        ...state,
        changeStep,
        selectPlan,
        setPlanDetails,
        setPaymentComplete,
        addCoupon,
        removeCoupon,
        setIsLoading,
        setPaymentIntent,
        setPaymentEstimate,
        setPaymentError,
        fetchPaymentIntent,
        setBillingDetails,
        fetchEstimate,
        createSubscription,
        fetchPlans,
        setStepTitle
      }}
    >
      {children}
    </SubscriptionPurchaseContext.Provider>
  )
}
