import { Flex } from "@ikiru/talentis-fpc"
import React from "react"
import { messages } from "setup/messages/messages"
import { ErrorMessage, FourZeroFourMessage } from "./style"

const FourZeroFour = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
    >
      <FourZeroFourMessage>
        {messages.pageNotFound.fourZeroFour}
      </FourZeroFourMessage>
      <ErrorMessage>{messages.pageNotFound.pageNotFound}</ErrorMessage>
    </Flex>
  )
}

export default FourZeroFour
