import { apiRequest } from "setup/api/api"
import { SearchFirmEndpoints } from "setup/api/endpoints/endpoints"

export const getSavedSearchData = async (searchId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: SearchFirmEndpoints.Searches,
    endpointParams: searchId
  })

  const savedSearch = response?.data || []

  return savedSearch
}
