import React, { useEffect, useMemo, useState } from "react"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"
import { personProfileDetailsE2ETargets as e2eTargets } from "../definitions"
import { Button, H2, H5, Flex, Div, colors } from "@ikiru/talentis-fpc"
import { PersonAvatar } from "views/persons/components/person-avatar/PersonAvatar"
import ContactLookUp from "./ContactLookUp"
import { messages } from "setup/messages/messages"
import { ShowMore } from "components/ShowMore"
import { StyledH4, TeamContainer, WebsitesTeamContainer } from "./style"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"
import WebSiteList from "./WebSiteList"
import PersonPhoneList from "./PersonPhoneList"
import PersonEmailList from "./PersonEmailList"
import ProjectActionPopUp from "components/ProjectActions/ProjectActionPopUp"
import { HeaderOption } from "components/navigation/constants/navigation.definitions"
import { RouterUrl } from "setup/router/routes"
import { ProjectTeam } from "components/ProjectTeamMembers/ProjectTeam"
import { useSaveTeam } from "../../person-details-tab/components/person-team/hooks/SaveTeam"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { CVParsingModal } from "../../cv-parsing/CVParsingModal"
import { useOnDocumentsLoad } from "../../person-documnets/hooks/on-documents-load"
import { useLinkToProject } from "views/persons/hooks/useLinKToProject"
import { PersonAssignmentProps } from "../../person-assignments"
import { PersonCampaignProps } from "../../person-campaigns"
import GenerateBioModal from "./GenerateBio/GenerateBioModal"
import { useLocation } from "react-router-dom"
import { ParseText } from "../../person-experience/components/ParseText"

type ViewPersonDetailsProps = {
  personId: string
  profile: PersonProfileDetails
  personLinkAssignmentProps: PersonAssignmentProps
  personLinkCampaignProps: PersonCampaignProps
  project?: string
}

export const ViewPersonDetails = (props: ViewPersonDetailsProps) => {
  const [showTeaser, setShowTeaser] = useState(false)
  const {
    personId,
    profile,
    personLinkAssignmentProps,
    personLinkCampaignProps,
    project
  } = props

  const { editPersonTeam, personTeamMembers, sortPersonTeam } = useSaveTeam()
  const {
    name,
    location,
    phones,
    taggedEmails,
    webSites,
    linkedInProfileUrl,
    company,
    jobTitle,
    bio,
    companyId,
    dataPoolPersonId
  } = profile

  const { openInPortal, renderModalInPortal, open } = useModal()

  useOnDocumentsLoad()

  const showRocketReachPreview = () => setShowTeaser(true)

  const { linkAssignmentModal, linkCampaignModal } = useLinkToProject()

  let query = useLocation().search

  useEffect(() => {
    let params = new URLSearchParams(query)
    let showParsing = params.get("showParsing") // Assuming the URL is something like "/search?query=react"
    if (showParsing === "true") {
      openInPortal(ModalNames.CVParsing, <CVParsingModal />)
    }
  }, [openInPortal, query])

  const options: HeaderOption[] = useMemo(() => {
    let tempOptions: HeaderOption[] = [
      {
        label: messages.person.assignments.linkToAssignment,
        disabled: !Boolean(personLinkAssignmentProps.options.length),
        onClick: () =>
          linkAssignmentModal({
            ...personLinkAssignmentProps,
            project: project
          })
      },
      {
        label: messages.person.campaigns.linkToCampaign,
        disabled: !Boolean(personLinkCampaignProps.options.length),
        onClick: () =>
          linkCampaignModal({ ...personLinkCampaignProps, project: project })
      },
      {
        label: messages.assignment.similarPeople.findSimilarPeople,
        disabled: !dataPoolPersonId,
        onClick: (() => {
          window.open(
            `${window.location.protocol + "//" + window.location.host}${
              RouterUrl.Search
            }?personId=${dataPoolPersonId}&name=${name.value}`,
            "_blank"
          )
        }) as VoidFunction
      },
      {
        label: messages.person.cvparsing.menuCV,
        onClick: (() => {
          openInPortal(ModalNames.CVParsing, <CVParsingModal />)
        }) as VoidFunction
      },
      {
        label: messages.person.cvparsing.menuParseText,
        onClick: (() => {
          openInPortal(ModalNames.ParseText, <ParseText />)
        }) as VoidFunction
      },
      {
        label: messages.aiBio.generateWithAI,
        onClick: () => {
          open(ModalNames.GenerateBioAI, <GenerateBioModal />)
        }
      }
    ]
    return tempOptions
  }, [
    personLinkAssignmentProps,
    personLinkCampaignProps,
    dataPoolPersonId,
    linkAssignmentModal,
    linkCampaignModal,
    name.value,
    openInPortal,
    open,
    project
  ])

  return (
    <Flex width="100%" flexDirection="row" flexWrap={["wrap", "nowrap"]} my="0">
      <PersonAvatar />
      <Flex width="100%" flexDirection="column">
        <Flex
          width="100%"
          justifyContent="space-between"
          flexWrap={["wrap", "nowrap"]}
        >
          <Flex
            flexWrap="wrap"
            minHeight={60}
            alignContent="flex-start"
            flexDirection="column"
          >
            {Boolean(name?.value) && (
              <H2
                data-e2e-target-name={e2eTargets.name}
                color={colors.green.standard}
                m="0"
                mt="xxs"
                width="100%"
              >
                {name?.value}
              </H2>
            )}
            {jobTitle.value && (
              <Div>
                <StyledH4 data-e2e-target-name={e2eTargets.jobTitle}>
                  {jobTitle.value}{" "}
                </StyledH4>
                {company?.value && (
                  <>
                    @{" "}
                    <CompanyLink
                      fontSize="18px"
                      companyId={companyId.value}
                      companyName={company.value}
                    />
                  </>
                )}
              </Div>
            )}
            {Boolean(location?.value) && (
              <H5
                data-e2e-target-name={e2eTargets.location}
                color={colors.grey.dark}
                m="0"
                width="100%"
              >
                {location?.value}
              </H5>
            )}
            <WebsitesTeamContainer>
              <WebSiteList
                webSites={webSites}
                linkedInProfileUrl={linkedInProfileUrl}
              />
              <TeamContainer>
                <ProjectTeam
                  title={messages.person.personTeam.title}
                  projectTeamMembers={personTeamMembers}
                  useAddButton
                  onClickAddButton={editPersonTeam}
                  sortingFn={sortPersonTeam}
                />
              </TeamContainer>
            </WebsitesTeamContainer>
          </Flex>

          <Flex flexDirection="column" textAlign="right" alignItems="flex-end">
            <Flex
              mt="0"
              width="100%"
              minWidth={250}
              alignItems="flex-end"
              flexDirection="column"
              whiteSpace="nowrap"
            >
              <Flex flexDirection="column" alignItems="flex-end" pl="m">
                <ProjectActionPopUp options={options} />
              </Flex>
              <PersonPhoneList personId={personId} phoneNumbers={phones} />
              <PersonEmailList
                personId={personId}
                taggedEmails={taggedEmails}
              />
              {Boolean(linkedInProfileUrl?.value || company?.value) &&
                (!showTeaser ? (
                  <Button
                    mt="xs"
                    mode="standard-green"
                    size="small"
                    onClick={showRocketReachPreview}
                    mb="xs"
                  >
                    {messages.person.teasers.findContactDetailsButton}
                  </Button>
                ) : (
                  <ContactLookUp
                    personId={personId}
                    onClose={() => setShowTeaser(false)}
                  />
                ))}
            </Flex>
          </Flex>
        </Flex>
        {bio.value && (
          <ShowMore
            hasBackground
            title="Biography"
            visibleLines={1}
            description={bio.value}
          />
        )}
        {renderModalInPortal()}
      </Flex>
    </Flex>
  )
}
