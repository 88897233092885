import * as Yup from "yup"
import { messages } from "setup/messages/messages"
import { ClientPortalShareField } from "./client-portal-share-form.definitions"
import { Share } from "views/assignments/assignment.types"

export const getClientPortalShareValidationSchema = (shares: Share[]) =>
  Yup.object({
    [ClientPortalShareField.Email]: Yup.string()
      .email(messages.form.generic.errors.invalidEmail)
      .required(messages.generic.required)
      .test("test-name", messages.form.generic.errors.emailExists, (value) => {
        return !shares.length
          ? true
          : !shares.find((share) => share.email === value)
      })
  })
