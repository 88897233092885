import { FormikHelpers } from "formik"
import { callWithErrorMessages } from "utils/forms/forms"
import { commonFormStateKey, FormStates } from "utils/forms/form-state"
import { UsersEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { CreatePersonValues } from "views/team/team/components/TeamMembers/definitions"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const onInviteUserSubmit =
  (
    errorCallback?: () => void,
    successCallback?: (values: CreatePersonValues) => void
  ) =>
  async (
    values: CreatePersonValues,
    helpers: FormikHelpers<CreatePersonValues>
  ) => {
    const { setFieldError, setStatus, setSubmitting, resetForm } = helpers
    setSubmitting(true)
    setStatus({
      [commonFormStateKey]: FormStates.Pending
    })

    const [error, response] = await apiRequest.post({
      endpoint: UsersEndpoints.Invite,
      data: values,
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    setStatus({
      [commonFormStateKey]: FormStates.Static
    })

    setSubmitting(false)

    if (error) {
      if (error.data.errors["Licenses"]) {
        errorCallback?.()
      } else {
        callWithErrorMessages(setFieldError, error)
      }
    } else {
      successCallback?.(response?.data)
      resetForm()
      setStatus({
        [commonFormStateKey]: FormStates.Finished,
        userEmailAddress: response?.data?.userEmailAddress
      })
    }
  }
