import React, { useCallback, useState, useMemo } from "react"
import { Alert, AlertProps } from "@ikiru/talentis-fpc"
import { AlertContext } from "./Context"
import * as Styled from "./styles"
const generateUniqueId = () => Math.random().toString(36).substr(2, 9)

type AlertProviderProps = {
  children: React.ReactNode
}
type AlertState = AlertProps & {
  id: string
}

export type AlertOptions = Omit<AlertProps, "children"> & {
  timeout?: number
  closable?: boolean
}

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const [alerts, setAlerts] = useState<AlertState[]>([])

  const closeAll = useCallback(() => setAlerts([]), [])

  const close = useCallback(
    (alertId: string) =>
      setAlerts((alerts) => alerts.filter((alert) => alert.id !== alertId)),
    []
  )

  const show = useCallback(
    (message: React.ReactNode, options: AlertOptions): string => {
      const id = generateUniqueId()
      const newAlert = { children: message, onClose: () => {}, ...options, id }

      if (options.closable) {
        newAlert.onClose = () => {
          close(id)
          options?.onClose?.()
        }
      }

      setAlerts((alerts) => [...alerts, newAlert])

      if (options.timeout) setTimeout(() => close(id), options.timeout)

      return id
    },
    [close]
  )

  const contextValues = useMemo(
    () => ({ show, close, closeAll }),
    [close, show, closeAll]
  )

  return (
    <AlertContext.Provider value={contextValues}>
      <Styled.PageWrapper>
        <Styled.AlertsWrapper>
          {alerts.map(({ children, ...rest }, index) => (
            <Alert {...rest} key={index}>
              {children}
            </Alert>
          ))}
        </Styled.AlertsWrapper>
        <Styled.PageContent>{children}</Styled.PageContent>
      </Styled.PageWrapper>
    </AlertContext.Provider>
  )
}
