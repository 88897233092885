import React, { useState } from "react"
import { ErrorBox } from "@ikiru/talentis-fpc"
import { BodyText } from "@ikiru/talentis-fpc"
import { ErrorLink } from "./styles"
import { messages } from "setup/messages/messages"

type ErrorVerificationType = {
  onLinkClick: (callback: VoidFunction) => void
  isInitialUser: boolean
  isInvitedUser: boolean
}

export const ErrorVerification = ({
  onLinkClick,
  isInitialUser,
  isInvitedUser
}: ErrorVerificationType) => {
  const [isSend, setIsSend] = useState(false)

  return (
    <ErrorBox mb="s" textAlign="left">
      {!isSend ? (
        <>
          <BodyText color="inherit" m={0}>
            {isInitialUser && messages.signup.accountHasNotBeenVerified}
            {isInvitedUser &&
              messages.signup.emailAddressHasAlreadyBeenInvitedToATeam}
          </BodyText>
          <ErrorLink onClick={() => onLinkClick(() => setIsSend(true))}>
            {isInvitedUser
              ? messages.signup.resendInvitationEmail
              : messages.signup.resendVerificationEmail}
          </ErrorLink>
        </>
      ) : (
        <>
          <BodyText color="inherit" m={0}>
            {isInvitedUser
              ? messages.signup.invitationEmailHasBeenSent
              : messages.signup.verificationEmailHasBeenSent}
          </BodyText>
        </>
      )}
    </ErrorBox>
  )
}
