import styled, { css } from "styled-components"
import { Link, LinkProps, H4 } from "@ikiru/talentis-fpc"

const ellipsisStyles = css`
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CompanyLinkStyled = styled(Link)<LinkProps & { isDots?: boolean }>`
  font-family: canada-type-gibson;
  font-size: ${({ fontSize }) => {
    return Boolean(fontSize) ? (fontSize as number) : "20px"
  }};
  font-weight: 500;
  display: inline-block;

  ${({ isDots }) => isDots && ellipsisStyles}
`

export const StyledH4 = styled(H4)<{ isDots?: boolean }>`
  ${({ isDots }) => isDots && ellipsisStyles}
`
