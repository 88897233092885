import React, { useCallback, useMemo, useState } from "react"
import {
  CardCVV,
  CardExpiry,
  CardNumber,
  FieldEvent
} from "@chargebee/chargebee-js-react-wrapper"
import { getMinWidthMediaQuery, fontFamilies, Error } from "@ikiru/talentis-fpc"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { messages } from "setup/messages/messages"
import { ChargebeeInputWrapper, ChargebeeInputLabel } from "./style"
interface ChargebeeInputProps {
  label: string
  Component: typeof CardCVV | typeof CardExpiry | typeof CardNumber
}

export const ChargebeeInput = (props: ChargebeeInputProps) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const [hasValue, setHasValue] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [isComplete, setIsComplete] = useState<boolean>(false)

  const mediaQuery = getMinWidthMediaQuery("md")
  const isLarge = useMediaQuery(mediaQuery)

  const { Component, label } = props

  const onFocus = useCallback(() => {
    setHasFocus(true)
  }, [setHasFocus])

  const onBlur = useCallback(
    (event: FieldEvent) => {
      setHasFocus(false)

      if (event.empty) {
        setError(messages.generic.required)
      }
    },
    [setHasFocus]
  )

  const onChange = useCallback(
    (event: FieldEvent) => {
      setHasValue(!event.empty)
      setIsComplete(event.complete)
      setError(event.error?.message)
    },
    [setHasValue, setError, setIsComplete]
  )

  const shouldMinimizeLabel = useMemo(
    () => hasFocus || hasValue,
    [hasFocus, hasValue]
  )

  const fieldStatus = useMemo(() => {
    if (error) {
      return "invalid"
    }

    if (isComplete) {
      return "valid"
    }

    return "default"
  }, [error, isComplete])

  const chargebeeInputStyle = useMemo(
    () => ({
      fontSize: isLarge ? "20px" : "18px",
      fontFamily: `${fontFamilies.gibson}`,
      letterSpacing: "0.2px",
      lineHeight: isLarge ? "23px" : "21px",
      fontWeight: "500" as unknown as number,
      marginBottom: 10
    }),
    [isLarge]
  )

  return (
    <>
      <ChargebeeInputWrapper
        className={shouldMinimizeLabel ? "minimize-label" : undefined}
        hasFocus={hasFocus}
        status={fieldStatus}
      >
        <Component
          placeholder=" "
          onFocus={onFocus}
          onBlur={onBlur as any}
          onChange={onChange as any}
          styles={{ base: chargebeeInputStyle }}
        />
        <ChargebeeInputLabel
          className={shouldMinimizeLabel ? "minimize-label" : undefined}
        >
          {label}
        </ChargebeeInputLabel>
      </ChargebeeInputWrapper>
      {error && <Error>{error}</Error>}
    </>
  )
}
