import { Flex, fontFamilies, spacing } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const LinkIcon = styled.a`
  display: flex;
`
export const WebSitesList = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: ${({ theme }) => theme.space.xs}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;
  list-style-type: none;
  svg {
    &:hover {
      filter: brightness(0.9);
    }
  }
`
export const FormSubmitButtons = styled(Flex)`
  justify-content: flex-end;
  width: 100%;
  margin-bottom: ${spacing.xs}px;
  margin-top: ${spacing.s}px;
  position: absolute;
  top: 0;
  right: 10px;
`

export const StyledPopupTrigger = styled(Flex)`
  cursor: pointer;
  align-items: center;
`

export const AboutStyledSpan = styled.span`
  font-family: ${fontFamilies.gibson};
  color: ${({ theme }) => theme.colors.grey.darkest};
  font-weight: 500;
`

export const OffLimitsContainerStyled = styled(Flex)`
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -10px;
  padding-right: 30px;
`
