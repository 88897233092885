import { apiRequest } from "setup/api/api"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { callWithErrorMessages } from "utils/forms/forms"
import { GetOnAddNotesSubmitType } from "./types"

export const getOnAddPersonCommentarySubmit: GetOnAddNotesSubmitType =
  (candidateId, onSubmit) => async (values, helpers) => {
    const { setFieldError } = helpers
    const [error, response] = await apiRequest.patch({
      endpoint: CandidatesEndpoints.Root,
      endpointParams: candidateId,
      data: {
        commentary: {
          data: values.commentaryDescription,
          showToClient: values.commentaryShowToClient
        }
      }
    })

    if (error) {
      callWithErrorMessages(setFieldError, error)
      helpers.setSubmitting(false)
    } else {
      onSubmit(response?.data && { ...response?.data, id: candidateId })
    }
  }
