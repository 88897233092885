import React from "react"
import { CampaignModule } from "views/campaigns/campaign-module/CampaignModule"
import { CampaignDetails } from "./CampaignDetails"
import { PersonProfileWrapper } from "views/persons/pages/manage-person/PersonProfileWrapper"

export const CampaignDetailsWrapper = () => (
  <CampaignModule>
    <PersonProfileWrapper>
      <CampaignDetails />
    </PersonProfileWrapper>
  </CampaignModule>
)
