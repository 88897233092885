import styled from "styled-components"
import { H2, theme } from "@ikiru/talentis-fpc"
import { Flex } from "@ikiru/talentis-fpc"
import { scrollBarCvStyle } from "components/FPCComponents/DragAndDrop/Columns/style"

export const PossibleDuplicatesWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  padding-top: ${theme.space.m}px;
  justify-content: center;
`

export const DuplicatesListWrapper = styled.div`
  max-height: 375px;
  width: 100%;
  padding: ${({ theme }) => theme.space.m}px;
`

export const DuplicatesList = styled(Flex)`
  ${scrollBarCvStyle};
  flex-wrap: wrap;
  overflow-y: auto;
  height: 300px;
  justify-content: center;
  gap: 10px;
`

export const DuplicatesFoundLabel = styled(H2)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.space.s}px;
`

export const SaveRecordBox = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space.m}px;
  background-color: ${({ theme }) => theme.colors.grey.light};
  width: 100%;
`
