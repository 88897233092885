import {
  BodyText,
  Button,
  Flex,
  Input,
  PencilIcon,
  RemoveIcon,
  UndoIcon,
  colors,
  fontFamilies,
  spacing
} from "@ikiru/talentis-fpc"
import styled from "styled-components"
import TextareaAutosize from "react-textarea-autosize"
import { borderValue } from "../parsing/style"
interface InputProps {
  width?: string
  isEditing?: boolean
  isDisabled?: boolean
  isOverview?: boolean
}

export const CVInputWrapper = styled(Flex)<InputProps>`
  background-color: white;
  justify-content: space-between;
  align-items: baseline;
  padding-right: 5px;
  width: ${({ width }) => width};
  ${({ isEditing }) =>
    isEditing
      ? `outline: 2px solid ${colors.green.light} !important; outline-offset: -2px;  !important`
      : `border-right: 1px solid ${colors.grey.light}`};

  height: 100% !important;
  &:hover {
    ${({ isDisabled }) =>
      !isDisabled &&
      `outline: 2px solid ${colors.green.light} !important;  outline-offset: -2px; !important`};
  }
`

export const CVInputDatepickerWrapper = styled(Flex)<InputProps>`
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  width: ${({ width }) => width};
  ${({ isEditing }) =>
    isEditing
      ? `border: 2px solid ${colors.green.light}; `
      : `border-right: 1px solid ${colors.grey.light}`};

  min-height: 33px;
  &:hover {
    ${({ isDisabled }) =>
      !isDisabled && `border: 2px solid ${colors.green.light};`};
  }
`

export const PencilIconStyle = styled(PencilIcon)`
  cursor: pointer;
`

export const StyledTextareaAutosize = styled(TextareaAutosize)`
  width: 95%;
  min-height: 30px !important;
  padding: ${spacing.xxs}px;
  font-size: 16px;
  font-size: 16px;
  font-family: ${fontFamilies.sourceSans};
  color: ${colors.grey.darkest};
  border: none;
  &:focus {
    outline: none;
    border: none;
    color: ${colors.grey.standard};
  }
  &:disabled {
    background-color: white;
  }
  resize: none;
  overflow: hidden;
`

export const StyledInput = styled.input<InputProps>`
  width: 100%;
  font-size: 16px;
  font-size: 16px;
  font-family: ${fontFamilies.sourceSans};

  background-color: ${({ isEditing }) =>
    isEditing ? colors.grey.lightest : colors.white.standard};
  color: ${colors.grey.darkest};
  border: none;
  &:focus {
    outline: none;
    border: none;
    color: ${colors.grey.standard};
  }
`

export const CVCustomInputWrapper = styled(Flex)<InputProps>`
  background-color: ${({ isEditing }) =>
    isEditing ? colors.grey.lightest : colors.white.standard};
  justify-content: space-between;
  align-items: baseline;
  padding: ${spacing.xxs}px;
  flex-direction: ${({ isEditing }) => (isEditing ? "column" : "row")};
  ${({ isEditing }) =>
    isEditing
      ? `outline: 2px solid ${colors.green.light};  outline-offset: -2px; `
      : `border-right: 1px solid ${colors.grey.light}`};

  height: 100% !important;
  width: ${({ width }) => width};
  &:hover {
    ${({ isDisabled }) =>
      !isDisabled &&
      `outline: 2px solid ${colors.green.light}; outline-offset: -2px; `};
  }
`

export const ExistingInputWrapper = styled(Flex)<InputProps>`
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => width};
  margin: 0;
  padding: ${spacing.xxs}px;
  border-left: ${borderValue};
  border-right: ${borderValue};
  &:hover {
    ${({ isDisabled }) =>
      !isDisabled &&
      `
    outline: 2px solid ${colors.green.light};
    outline-offset: -2px;
  `};
  }
  ${({ isOverview }) =>
    isOverview && `background-color: ${colors.white.standard}`};
`

export const StyledBodyText = styled(BodyText)<{ isDeleted: boolean }>`
  margin: 0;
  ${({ isDeleted }) => isDeleted && `text-decoration: line-through;`};
`

export const StyledRemoveIcon = styled(RemoveIcon)<{ isHover: boolean }>`
  path {
    fill: ${({ theme }) => theme.colors.grey.standard};
  }

  ${({ isHover }) =>
    isHover &&
    `path {
      fill: ${colors.red.standard};
    }`};
`

export const StyledRemoveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.grey.light};
  &:hover {
    background-color: ${({ theme }) => theme.colors.red.lightest};
  }
`

export const StyledUndoIcon = styled(UndoIcon)<{ isHover: boolean }>`
  ${({ isHover }) =>
    isHover &&
    `path {
      fill: ${colors.white.standard};
    }`};
`

export const StyleInput = styled(Input)<InputProps>`
  border: none;
  font-size: 16px;
  font-size: 16px;
  font-family: ${fontFamilies.sourceSans};

  background-color: ${({ isEditing }) =>
    isEditing ? colors.grey.lightest : colors.white.standard};
  color: ${colors.grey.darkest};
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: ${spacing.xxs}px;
  padding-left: ${spacing.xxs}px;
`
