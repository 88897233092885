import React from "react"
import { Div, H6, Flex, Switch, LittleText } from "@ikiru/talentis-fpc"
import { SwitchLabel } from "components/ProjectActions/ProjectSwitch/style"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { GenerateAiBioFormFields, GenerateAiBioFormValues } from "./const"
import { messages } from "setup/messages/messages"
import { FormikProps, useField } from "formik"

const GenerateAiBioFormLayout = ({
  formik
}: {
  formik: FormikProps<GenerateAiBioFormValues>
}) => {
  const [field] = useField(GenerateAiBioFormFields.isShort)

  return (
    <>
      <Div width="100%">
        <FormikInput
          id={GenerateAiBioFormFields.Language}
          name={GenerateAiBioFormFields.Language}
          label={GenerateAiBioFormFields.Language}
        />
      </Div>
      <Div mt="xxs">
        <H6 mt="xs" mb="xxs" color="grey.dark">
          {messages.aiBio.approxLength}
        </H6>
        <Flex my={["xs"]}>
          <SwitchLabel ml="0" isSelected={field.value}>
            50-150 {messages.aiBio.words}
          </SwitchLabel>
          <Switch
            id={GenerateAiBioFormFields.isShort}
            name={GenerateAiBioFormFields.isShort}
            colorVariant="secondary"
            label=""
            checked={!field.value}
            onChange={() => {
              formik.setFieldValue(
                `${GenerateAiBioFormFields.isShort}`,
                !field.value
              )
            }}
          />
          <SwitchLabel ml="xxs" isSelected={!field.value}>
            100-300 {messages.aiBio.words}
          </SwitchLabel>
        </Flex>
      </Div>
      <LittleText mb="xs" display="inline-block">
        {messages.aiBio.byUsing}
      </LittleText>
    </>
  )
}

export default GenerateAiBioFormLayout
