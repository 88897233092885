import { useMemo } from "react"
import {
  InterviewProgressStatus,
  contactStatuses
} from "views/campaigns/components/contacts/components/contact-record/components/InterviewProgress/definitions"
import {
  ProjectRecordFilter,
  FilterArrayType
} from "components/ProjectRecordsFilters/types"
import { checkFirstSelected } from "components/ProjectRecordsFilters/helpers"

/**
 * Return a list of status filters, including only those which at least one record has assigned
 */
// should be change a bit, because for assignment we have other statuses
export const useInterviewProgressStatus = (
  statusFilter: InterviewProgressStatus[],
  allAvailableFilters: FilterArrayType,
  appliedFilters: ProjectRecordFilter[]
) => {
  const interviewProgressStatusFilters = useMemo<ProjectRecordFilter[]>(() => {
    const currentStatusArray = checkFirstSelected(
      "interviewProgressState.status",
      appliedFilters
    )
      ? statusFilter
      : allAvailableFilters.status || []

    const statuses: InterviewProgressStatus[] = currentStatusArray.map(
      (status: InterviewProgressStatus) =>
        status || InterviewProgressStatus.NoStatus
    )

    return contactStatuses.map((status) => {
      const filter = {
        ...status,
        recordProperty: "interviewProgressState.status",
        value: {
          eq: status.value
        },
        filterKey: `interviewStatus${status.value}`,
        disabled: !statuses?.includes(status.value)
      }
      return filter
    })
  }, [statusFilter, allAvailableFilters.status, appliedFilters])

  return {
    interviewProgressStatusFilters
  }
}
