import React from "react"
import { Flex, Badge, SmallText } from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { RouterLink } from "components/RouterLink/RouterLink"
import {
  ProjectRecordBox,
  ProjectDataBox,
  ProjectRecordFooter,
  ProjectStartDate
} from "components/styles/styles"
import { formatDate } from "utils/format-date"
import { CampaignRecord as CampaignRecordType } from "../type"

export const CampaignRecord = ({
  id,
  name,
  startDate,
  contactsCount
}: CampaignRecordType) => {
  return (
    <ProjectRecordBox href={`${RouterUrl.CampaignDetails}/${id}`}>
      <Flex
        py={["s", "", "none"]}
        px={["xs", "", "none"]}
        flexDirection={["column", "column", "row"]}
      >
        <ProjectDataBox>
          <RouterLink
            my={0}
            to={`${RouterUrl.CampaignDetails}/${id}`}
            fontWeight={600}
          >
            {name}
          </RouterLink>
        </ProjectDataBox>
      </Flex>
      <ProjectRecordFooter>
        <ProjectStartDate>
          {messages.campaign.details.started}: {formatDate(startDate)}
        </ProjectStartDate>
        <Flex
          justifyContent={["flex-start", "", "flex-end"]}
          alignItems="center"
          flexWrap="wrap"
          mb={["xs", "", "none"]}
          width={["100%", "", "60%"]}
        >
          <Flex my={2} mx="xxs" alignItems="center">
            <Badge bg="grey.standard" color="white.standard" height={20}>
              {contactsCount}
            </Badge>
            <SmallText ml="xxs" my={0}>
              {messages.campaign.details.contacts.title}
            </SmallText>
          </Flex>
        </Flex>
      </ProjectRecordFooter>
    </ProjectRecordBox>
  )
}
