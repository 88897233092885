import React from "react"
import { Flex, Textarea } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

type GeneratedBioOverviewProps = {
  newBio: string
  setNewBio: any
}

const GeneratedBioOverview = ({
  newBio,
  setNewBio
}: GeneratedBioOverviewProps) => {
  const onChange = (e: any) => {
    setNewBio(e.target.value)
  }
  return (
    <Flex width="100%" mt="s">
      <Textarea
        rows={12}
        label={messages.aiBio.generatedBio}
        value={newBio}
        onChange={onChange}
        style={{ width: "100%" }}
      />
    </Flex>
  )
}

export default GeneratedBioOverview
