import { useCallback, useEffect, useState } from "react"
import { apiRequest } from "setup/api/api"
import { ContactLookupTeaserRequest } from "../constants/ContactLookUpProps.definitions"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { getNormalizedPersonProfileDetails } from "../../../utils/get-person-details"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { TaggedEmail } from "views/persons/person.types"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

type ContactLookUpProps = {
  personId: string
  onClose: () => void
}
const useContactLookUp = ({ personId, onClose }: ContactLookUpProps) => {
  const { setProfileDetails } = usePerson()
  const [isFetching, setIsFetching] = useState(true)
  const [hasEmail, setHasEmail] = useState(false)
  const [hasPhoneNumbers, setHasPhoneNumbers] = useState(false)
  const [foundEmails, setFoundEmails] = useState<string[]>([])
  const [foundPhoneNumbers, setFoundPhoneNumbers] = useState<string[]>([])

  const fetchContactTeaser = useCallback(async () => {
    setIsFetching(true)

    const [, response] = await apiRequest.get<ContactLookupTeaserRequest>({
      endpoint: PersonsEndpoints.ContactLookUpV2,
      endpointParams: personId,
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    if (response?.data.status === "success") {
      setHasEmail(Boolean(response?.data.hasEmails))
      setHasPhoneNumbers(Boolean(response?.data.hasPhoneNumbers))
    }

    setIsFetching(false)
  }, [personId])

  const fetchContact = useCallback(async () => {
    setIsFetching(true)

    const [, response] = await apiRequest.get({
      endpoint: PersonsEndpoints.ContactLookupV2Details,
      endpointParams: personId
    })

    const { taggedEmails, phoneNumbers } = response?.data
    const emails = taggedEmails.map((email: TaggedEmail) => email.email)
    setFoundEmails(emails)
    setFoundPhoneNumbers(phoneNumbers)

    setIsFetching(false)

    const [, responsePerson] = await apiRequest.get({
      endpoint: PersonsEndpoints.Root,
      endpointParams: personId
    })

    const normalized = new LocalPersonModel(responsePerson?.data)

    const profileDetails = getNormalizedPersonProfileDetails(normalized)

    setProfileDetails(profileDetails)
    setTimeout(() => onClose(), 10000)
  }, [onClose, personId, setProfileDetails])

  useEffect(() => {
    fetchContactTeaser()
  }, [fetchContactTeaser])

  return {
    isFetching,
    hasEmail,
    hasPhoneNumbers,
    fetchContact,
    foundEmails,
    foundPhoneNumbers
  }
}

export default useContactLookUp
