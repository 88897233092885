import React, { useCallback, useState } from "react"
import {
  BodyText,
  Button,
  Div,
  Flex,
  H4,
  H5,
  Loader,
  colors
} from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import {
  OverviewCVModalWrapper,
  OverviewSectionContainer
} from "../parsing/style"
import { OverviewSection } from "../components/OverviewSection"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { ParsingResumeModal } from "../parsing/ParsingResumeModal"
import { OverviewPersonDetails } from "./OverviewPersonDetails"
import { OverviewContactInfo } from "./OverviewContactInfo"
import { OverviewProfessionalExperience } from "./OverviewProfessionalExperience"
import { OverviewSkills } from "./OverviewSkills"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { updateCVPerson } from "../helpers"
import { getNormalizedPersonProfileDetails } from "../../person-details/utils/get-person-details"
import {
  hasPersonDetailsTemp,
  hasTempValue,
  removeTempContactInfo,
  removeTempPersonDetails,
  updateContactInfo,
  updateExperience,
  updateKeywords,
  updatePersonDetails,
  updateSkills
} from "../parsing/helper"
import cloneDeep from "lodash/cloneDeep"
import { CVParsingAlertModal } from "../parsing/CVParsingAlertModal"
import { CVParsing } from "views/persons/person.types"
import { updateKeyword } from "../../person-keyword/form/actions"

const overviewList = [
  {
    title: messages.person.cvparsing.stepPersonDetails,
    content: <OverviewPersonDetails />,
    linkText: messages.person.cvparsing.overview.goBackPersonDetails
  },
  {
    title: messages.person.cvparsing.stepContactInfo,
    content: <OverviewContactInfo />,
    linkText: messages.person.cvparsing.overview.goBackContactInfo
  },
  {
    title: messages.person.cvparsing.stepProfessionalExperience,
    content: <OverviewProfessionalExperience />,
    linkText: messages.person.cvparsing.overview.goBackProfessional
  },
  {
    title: messages.person.cvparsing.stepSkills,
    content: <OverviewSkills />,
    linkText: messages.person.cvparsing.overview.goBackSkills
  }
]

export const OverviewCVModal = () => {
  const [isSaving, setIsSaving] = useState(false)
  const {
    setActiveTab,
    personId,
    setProfileDetails,
    setPersonOverlayUpdated,
    cvParsing,
    setCompletedCVSteps,
    setKeywords,
    setCVParsing,
    setPreviousButtonClicked
  } = usePerson()

  const { close, openInPortal, open } = useModal()

  const closeWithoutSaving = useCallback(() => {
    const tempCVParsing = cloneDeep(cvParsing)
    removeTempPersonDetails(tempCVParsing.personDetails)
    removeTempContactInfo(tempCVParsing.contactInfo)

    setCVParsing({ ...tempCVParsing })
  }, [setCVParsing, cvParsing])

  const checkUnsavedChanges = useCallback(() => {
    const unsavedChanges = hasPersonDetailsTemp(cvParsing.personDetails)

    const unsavedContactInfo = hasTempValue(cvParsing.contactInfo)

    return unsavedChanges || unsavedContactInfo
  }, [cvParsing.contactInfo, cvParsing.personDetails])

  const updatePersonRecord = useCallback(
    async (cvParsingNew: CVParsing) => {
      setIsSaving(true)
      const tempPersonDetails = updatePersonDetails(cvParsingNew.personDetails)
      const tempContactInfo = updateContactInfo(cvParsingNew.contactInfo)
      const tempExperience = updateExperience(
        cvParsingNew.professionalExperience
      )
      const tempSkills = updateSkills(cvParsingNew.skills)

      const newKeywords = updateKeywords(cvParsingNew.skills)

      const response = await updateCVPerson(personId, {
        ...tempPersonDetails,
        ...tempContactInfo,
        ...tempExperience,
        ...tempSkills
      })

      const resp = await updateKeyword(personId, newKeywords)

      if (response) {
        setIsSaving(false)
      }

      const mergedPersonData = new LocalPersonModel(response)

      const profileDetails = getNormalizedPersonProfileDetails(mergedPersonData)
      setKeywords(resp?.keywords)
      setProfileDetails(profileDetails)
      setActiveTab(0)
      setCompletedCVSteps({})
      close(ModalNames.OverviewCVModal)
      setPersonOverlayUpdated(true)
    },
    [
      personId,
      setKeywords,
      setProfileDetails,
      setActiveTab,
      setCompletedCVSteps,
      close,
      setPersonOverlayUpdated
    ]
  )

  const goBackToReview = useCallback(
    (activeTab: number) => {
      setPreviousButtonClicked(activeTab)
      const unsavedChanges = checkUnsavedChanges()
      if (unsavedChanges) {
        openInPortal(
          ModalNames.CVParsingAlertModal,
          <CVParsingAlertModal
            closeWithoutSaving={closeWithoutSaving}
            isFromReview
          />
        )
      } else {
        setActiveTab(activeTab)
        openInPortal(ModalNames.ParsingResume, <ParsingResumeModal />)
        close(ModalNames.OverviewCVModal)
      }
    },
    [
      setPreviousButtonClicked,
      openInPortal,
      close,
      setActiveTab,
      checkUnsavedChanges,
      closeWithoutSaving
    ]
  )

  const finishReview = useCallback(async () => {
    setPreviousButtonClicked("finish")
    const unsavedChanges = checkUnsavedChanges()
    if (unsavedChanges) {
      open(
        ModalNames.CVParsingAlertModal,
        <CVParsingAlertModal
          closeWithoutSaving={closeWithoutSaving}
          isFromReview
          updatePersonRecord={(data) => updatePersonRecord(data)}
        />
      )
    } else {
      updatePersonRecord(cvParsing)
    }
  }, [
    open,
    updatePersonRecord,
    setPreviousButtonClicked,
    closeWithoutSaving,
    checkUnsavedChanges,
    cvParsing
  ])

  return (
    <OverviewCVModalWrapper>
      <Flex justifyContent="space-between" width="100%">
        <H4 color={colors.grey.dark} mt="0">
          {messages.person.cvparsing.titleModal}
        </H4>

        {!isSaving && (
          <Button
            onClick={() => close(ModalNames.OverviewCVModal)}
            mode="standard-white"
            size="small"
          >
            {messages.generic.cancel}
          </Button>
        )}
      </Flex>
      {isSaving ? (
        <Flex flexDirection="column" m="auto" alignItems="center">
          <Loader />
          <H5>{messages.person.cvparsing.savingData}</H5>
        </Flex>
      ) : (
        <>
          <BodyText>{messages.person.cvparsing.overviewCV}</BodyText>
          <OverviewSectionContainer>
            {overviewList.map((list, index) => (
              <Div mb="l">
                <OverviewSection
                  key={index}
                  title={list.title}
                  linkText={list.linkText}
                  goBack={() => goBackToReview(index)}
                />
                {list.content}
              </Div>
            ))}
          </OverviewSectionContainer>

          <Flex justifyContent="flex-end">
            <Button
              onClick={finishReview}
              mode="primary"
              size="small"
              mr="xs"
              mt="m"
            >
              {messages.person.cvparsing.finishReview}
            </Button>
          </Flex>
        </>
      )}
    </OverviewCVModalWrapper>
  )
}
