import styled from "styled-components"
import { Div } from "@ikiru/talentis-fpc"

export const IndustryItem = styled(Div)`
  color: ${({ theme }) => theme.colors.grey.dark} !important;
  background-color: ${({ theme }) => theme.colors.grey.light};
  padding: ${({ theme }) => theme.space.xxs}px
    ${({ theme }) => theme.space.xs}px;
  width: max-content;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 600;
`

export const IndustryItemContainer = styled(Div)`
  width: 100%;
  background-color: ${({
    // @ts-ignore
    active,
    // @ts-ignore
    hover,
    theme
  }) =>
    active
      ? theme.colors.grey.lightTranslucent
      : hover
      ? theme.colors.grey.lightest
      : "white"};
  padding: 3px;
  cursor: pointer;
`

export const IndustryDropdown = styled(Div)`
  padding: 3px 0;
  width: 100%;
  height: max-content;
  max-height: 300px;
  overflow-y: auto;
  background: white;
  position: absolute;
`
