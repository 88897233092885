import React, { useEffect, useState } from "react"
import { CrossIcon, Div, Flex, H6 } from "@ikiru/talentis-fpc"
import {
  sectionConditionTemplateString,
  initialTemplateString,
  sectionTemplateString
} from "./consts"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { SearchField } from "../SearchField"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { getLocalSearchFields } from "views/internal-search/actions"
import {
  Conditions,
  Field,
  InternalFilterType
} from "views/internal-search/InternalSearchModule/types"
import { StyledRemoveButton } from "views/internal-search/InternalSearchModule/style"
import {
  useAddSectionBox,
  useAddSectionField
} from "views/internal-search/hooks"
import { get } from "lodash"
import { useFormikContext } from "formik"

const optionsCondition = [
  { value: "or", label: "ANY" },
  { value: "and", label: "ALL" }
]

type SearchSectionBoxProps = {
  index: number
  boxesCount: number
}

export const SearchSectionBox = ({
  index,
  boxesCount
}: SearchSectionBoxProps) => {
  const {
    sections,
    updateFilters,
    clearArrayBasedFilter,
    filters,
    isResetFilters,
    resetFilters
  } = useInternalSearch()

  const { removeBox } = useAddSectionBox(InternalFilterType.Sections)

  const { removeAllBoxes } = useAddSectionField(index)

  const [fields, setFields] = useState<Field[]>([])
  const initialFilterName = initialTemplateString.format(index)
  const sectionName = sectionTemplateString.format(index)
  const sectionCondition = sectionConditionTemplateString.format(index)

  const handleSelectChange = async (selectedOption: any) => {
    setFields([])
    removeAllBoxes()
    clearArrayBasedFilter(initialFilterName)

    updateFilters({
      [sectionName]: selectedOption.target.value
    })

    updateFilters({
      [sectionName]: selectedOption.target.value
    })

    if (selectedOption.target.value) {
      let fields = await getLocalSearchFields(selectedOption.target.value)
      setFields(fields.fields)
    }
  }

  const handleSectionCondition = (selectedOption: any) => {
    updateFilters({
      [sectionCondition]: selectedOption.target.value
    })
  }

  useEffect(() => {
    !filters?.sections?.[index]?.condition &&
      updateFilters({ [sectionCondition]: Conditions.OR })
  }, [updateFilters, filters, sectionCondition, index])

  const currentSectionValue = get(filters, sectionName)
  const currentSectionConditionValue = get(filters, sectionCondition)

  useEffect(() => {
    const fetchSearchFields = async () => {
      const fieldsTemp = await getLocalSearchFields(currentSectionValue)

      setFields(fieldsTemp.fields)
    }

    currentSectionValue && fields.length === 0 && fetchSearchFields()
  }, [currentSectionValue, fields])

  const { resetForm } = useFormikContext()

  useEffect(() => {
    if (isResetFilters) {
      resetForm()
      resetFilters()
    }
  }, [isResetFilters, resetForm, resetFilters])

  return (
    <Flex width="100%" justifyContent="space-between">
      <Flex flexDirection="column" width="100%">
        <Flex mb="10px">
          <Flex m="10px" flexDirection="column" width="250px">
            <FormikSelect
              label=" Select a section"
              options={sections}
              id={sectionName}
              name={sectionName}
              variant="middle"
              firstOption={{
                label: "Select a section",
                value: ""
              }}
              controlled
              onChange={handleSelectChange}
              value={currentSectionValue}
            />
          </Flex>
          <Flex alignItems="center" ml="30px">
            <H6 mt="30px" mr="5px" color="#4B6A88;">
              Include
            </H6>
            <Flex flexDirection="column" width="100px">
              <FormikSelect
                label="Condition"
                options={optionsCondition}
                id={sectionCondition}
                name={sectionCondition}
                variant="middle"
                onChange={handleSectionCondition}
                controlled
                value={currentSectionConditionValue}
              />
            </Flex>
            <H6 mt="30px" ml="5px" color="#4B6A88;">
              of these criteria
            </H6>
          </Flex>
        </Flex>
        {fields.length > 0 && (
          <SearchField indexSection={index} fields={fields} />
        )}
      </Flex>
      {boxesCount > 1 && (
        <Div mt="10px" mr="20px">
          <StyledRemoveButton
            size="action-small"
            mode="standard-white"
            onClick={() => {
              clearArrayBasedFilter(initialFilterName)
              removeBox(index)
            }}
          >
            <CrossIcon />
          </StyledRemoveButton>
        </Div>
      )}
    </Flex>
  )
}
