import React from "react"
import { SpaceProps } from "styled-system"
import { Note } from "@ikiru/talentis-fpc"
import { NoteFlexContainer } from "components/PersonRecords/Notes/styles"
import { usePersonRecordBiography } from "components/PersonRecords/Bio/hooks/usePersonRecordBiography"
import { Candidate } from "views/candidates/candidates.types"

type BioProps = {
  description: string
  candidate: Candidate
  noName?: boolean
} & SpaceProps

export const Bio = (props: BioProps) => {
  const { openBiographyModal } = usePersonRecordBiography(props.candidate)
  const { description } = props

  return (
    <NoteFlexContainer
      pl={["9px", "xs", "xxs", "xs"]}
      py={["9px", "xs", "xxs", "xs"]}
      pr="l"
      bg="grey.lightest"
    >
      <Note
        title=""
        description={description}
        createdDate=""
        formattedCreatedDate=""
        createdBy=""
        updatedBy=""
        updatedDate=""
        formattedUpdatedDate=""
        moreButtonLabel=""
        lessButtonLabel=""
        useArrow={true}
        isAlignItems
        editModalButton
        openModal={() =>
          openBiographyModal(
            props.noName
              ? ""
              : (props?.candidate?.linkPerson?.localPerson?.name as string)
          )()
        }
      />
    </NoteFlexContainer>
  )
}
