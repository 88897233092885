import { PersonProfileFields } from "views/persons/pages/manage-person/person-profile.types"

export const e2eTargets = {
  section: "person-details",
  addPhoneNumberButton: "phone-numbers-add",
  addWebSitesButton: "web-sites-add",
  addEmailAddressButton: "email-addresses-add"
}

export const personProfileDetailsE2ETargets = {
  [PersonProfileFields.Name]: "name",
  [PersonProfileFields.LinkedInProfileUrl]: "linked-in-url",
  [PersonProfileFields.Location]: "location",
  [PersonProfileFields.EmailAddresses]: "email-adresses",
  [PersonProfileFields.PhoneNumbers]: "phone-numbers",
  [PersonProfileFields.WebSites]: "web-sites",
  [PersonProfileFields.JobTitle]: "job-title"
}
