import React from "react"
import { components, MultiValueRemoveProps } from "react-select"
import { RemoveTagPiece } from "@ikiru/talentis-fpc"
import { CrossIcon } from "@ikiru/talentis-fpc"

export const MultiValueRemove = (props: MultiValueRemoveProps<any>) => {
  return (
    <components.MultiValueRemove {...props}>
      {/* @ts-ignore */}
      <RemoveTagPiece variant={props?.selectProps?.tagVariant || "grey"}>
        <CrossIcon fill="currentColor" width="12" height="12" />
      </RemoveTagPiece>
    </components.MultiValueRemove>
  )
}
