import React from "react"
import { Button, Flex, BodyText } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import ParseTextIssue from "assets/parse_text_issue.svg"

type ParseTextAIIssueProps = {
  closeModal: () => void
  handleTryAgain: () => void
}

export const ParseTextAIIssue = ({
  handleTryAgain,
  closeModal
}: ParseTextAIIssueProps) => {
  return (
    <Flex flexDirection="column" alignItems="center" mt="l">
      <img alt="CV parsing" width={250} src={ParseTextIssue} />

      <BodyText m="0" textAlign="center">
        {messages.person.experience.parseAIIssueOne}
      </BodyText>
      <BodyText m="0" textAlign="center">
        {messages.person.experience.parseAIIssueTwo}
      </BodyText>
      <Flex mt="m" justifyContent="flex-end">
        <Button
          onClick={handleTryAgain}
          mode="standard-green"
          size="small"
          mr="xxs"
        >
          {messages.person.cvparsing.tryAgain}
        </Button>
        <Button
          mode="standard-white"
          size="small"
          type="button"
          onClick={closeModal}
        >
          {messages.generic.cancel}
        </Button>
      </Flex>
    </Flex>
  )
}
