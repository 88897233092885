import React, { useMemo, useState } from "react"
import { Button, Div, Flex, Link } from "@ikiru/talentis-fpc"
import { PhoneDataType, TaggedEmail } from "views/persons/person.types"

type PersonContactsProps = {
  taggedEmails?: TaggedEmail[]
  phones?: PhoneDataType[]
}

export const PersonContacts = ({
  taggedEmails = [],
  phones = []
}: PersonContactsProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const isShowedButton = useMemo(
    () =>
      taggedEmails.length > 1 ||
      phones.length > 1 ||
      taggedEmails.length + phones.length > 2,
    [taggedEmails, phones]
  )

  const showedTaggedEmails = useMemo(
    () => (isExpanded ? [...taggedEmails] : taggedEmails.slice(0, 1)),
    [isExpanded, taggedEmails]
  )

  const showedPhones = useMemo(
    () => (isExpanded ? [...phones] : phones.slice(0, 1)),
    [isExpanded, phones]
  )

  return (
    <Flex flexDirection="column" ml="xxs" mt="xxs">
      {showedTaggedEmails.map((emailObj, index) => (
        <Div key={index}>
          <Link
            href={`mailto:${emailObj.email}`}
            size="small"
            standalone
            mb="0"
          >
            {emailObj.email}
          </Link>
        </Div>
      ))}

      {showedPhones.map((phoneObj, index) => (
        <Div key={index}>
          <Link
            href={`tel:${phoneObj.phoneNumber}`}
            size="small"
            standalone
            mb="0"
          >
            {phoneObj.phoneNumber}
          </Link>
        </Div>
      ))}
      {isShowedButton && (
        <Div pt="xxs" pb={isExpanded ? "xxs" : "0"}>
          <Button
            mode="standard-grey-light"
            size="extra-small"
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            {isExpanded ? "Hide all contacts" : "Show all contacts"}
          </Button>
        </Div>
      )}
    </Flex>
  )
}
