import { messages } from "setup/messages/messages"
import { StyledSystemProps } from "@ikiru/talentis-fpc"
import { PlanSelection } from "views/subscription/components/plan-selection"
import { BillingAddress } from "views/subscription/components/billing-address"
import { CardDetails } from "views/subscription/components/billing-card-details"
import { SubscriptionFAQ } from "views/subscription/components/subscription-faq"
import { SubscriptionPurchaseStep } from "views/subscription/subscription-purchase-module/subscription-purchase-module.types"
import { PlanDetails } from "views/subscription/components/plan-details"

type SubscriptionPurchaseStepProps = {
  title?: string
  previousStepLabel?: string
  component: React.ComponentType<React.PropsWithChildren<unknown>>
  footerComponent?: React.ComponentType<React.PropsWithChildren<unknown>>
  banners?: React.ComponentType<React.PropsWithChildren<StyledSystemProps>>[]
  legalSubText?: string
}

export const flowSteps: Record<
  SubscriptionPurchaseStep,
  SubscriptionPurchaseStepProps
> = {
  [SubscriptionPurchaseStep.PLAN_SELECTION]: {
    title: messages.subscription.purchase.selectYourPlan,
    component: PlanSelection,
    footerComponent: SubscriptionFAQ,
    banners: [],
    legalSubText: messages.subscription.purchase.legalSubtext
  },
  [SubscriptionPurchaseStep.PLAN_DETAILS]: {
    previousStepLabel: messages.subscription.purchase.selectPlan,
    title: messages.subscription.purchase.planDetails,
    component: PlanDetails,
    banners: [],
    legalSubText: messages.subscription.planDetails.legalSubtext
  },
  [SubscriptionPurchaseStep.BILLING_ADDRESS]: {
    previousStepLabel: messages.subscription.purchase.planDetails,
    title: messages.subscription.paymentDetails.billingInformation,
    component: BillingAddress,
    banners: [],
    legalSubText: messages.subscription.planDetails.legalSubtext
  },
  [SubscriptionPurchaseStep.CARD_DETAILS]: {
    previousStepLabel: messages.subscription.paymentDetails.billingInformation,
    title: messages.subscription.purchase.payByCard,
    component: CardDetails,
    banners: [],
    legalSubText: messages.subscription.paymentDetails.legalSubtext
  }
}

export const flowStepsArray = Object.keys(
  flowSteps
) as SubscriptionPurchaseStep[]
