import { AxiosResponse } from "axios"
import { messages } from "setup/messages/messages"

type AnyResponse = AxiosResponse<any>

export const getFirstResponseErrorMessage = (error: AnyResponse) => {
  const responseErrors = Object.values(error.data?.errors)[0] as string[]
  const errorMessage = responseErrors[0]
  return errorMessage
}

export const getResponseErrorMessageByName = (
  error: AnyResponse,
  name: string
) => {
  const responseErrors = error.data.errors[name]
  const errorMessage = responseErrors?.[0]
  return errorMessage
}

export const getErrorMessage = (error: AnyResponse, name?: string) => {
  return error.status === 400
    ? name
      ? getResponseErrorMessageByName(error, name)
      : getFirstResponseErrorMessage(error)
    : messages.generic.unexpectedError
}
