import React, { useCallback, useMemo } from "react"
import { Toggle, H6 } from "@ikiru/talentis-fpc"
import { StyledSpan } from "./style"
import { ProjectRecordFilter } from "./types"

interface IFilterToggleProps {
  filter: ProjectRecordFilter
  filterIsApplied: (recordFilter: ProjectRecordFilter) => boolean
  addFilter: (recordFilter: ProjectRecordFilter) => void
  removeFilter: (recordFilter: ProjectRecordFilter) => void
}

export const FilterToggle = ({
  filter,
  filterIsApplied,
  addFilter,
  removeFilter
}: IFilterToggleProps) => {
  const { disabled, span, value, label } = filter

  const checked = useMemo(
    () => !disabled && filterIsApplied(filter),
    [filterIsApplied, filter, disabled]
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        addFilter(filter)
      } else {
        removeFilter(filter)
      }
    },
    [removeFilter, addFilter, filter]
  )

  return (
    <Toggle
      hideUnderline
      small
      type="checkbox"
      label={
        <H6 m="0" color="grey.dark">
          {span && <StyledSpan background={span} />}
          {label}
        </H6>
      }
      value={JSON.stringify(value)}
      checked={checked}
      onChange={onChange}
      {...{ disabled }}
    />
  )
}
