import { ILocalPersonModel } from "../../../models/LocalPerson/LocalPerson"

export type SearchState = {
  areFiltersExpanded?: boolean
  paginationProps: {
    pageNumber: number
    firstItemOnPage?: number
    hasNextPage?: boolean
    hasPreviousPage?: boolean
    isFirstPage?: boolean
    isLastPage?: boolean
    lastItemOnPage?: number
    pageCount: number
    pageSize: number
    totalItemCount: number
  }
  isLoading: boolean
  persons: ILocalPersonModel[]
  selectedSearchPersonId: string
  setPersonsFound: (persons: ILocalPersonModel[]) => void
  updatePerson: (person: ILocalPersonModel) => void
  setIsLoading: (isLoading: boolean) => void
  setPagination: (paging: Pick<SearchState, "paginationProps">) => void
}

export enum SearchActionTypes {
  SetIsLoading = "setIsLoading",
  UpdateFilters = "updateFilters",
  SetPersons = "setPersons",
  SetRecordsCount = "setRecordsCount",
  SetPage = "setPage",
  UpdatePerson = "updatePerson",
  SetSelectedSearchPersonId = "setSelectedSearchPersonId",
  SetPagination = "setPagination"
}

export type SearchContextValues = SearchState & {
  onPageChange: (page: number) => void
  setSelectedSearchPersonId: (personId: string) => void
}

export type SetIsLoading = (data: boolean) => void
export type SetPagination = (
  paging: Pick<SearchState, "paginationProps">
) => void
export type SetPersons = (data: ILocalPersonModel[]) => void
export type SetPage = (data: number) => void
export type SetSelectedSearchPersonId = (personId: string) => void
export type UpdatePerson = (person: ILocalPersonModel) => void
