import React, { useState } from "react"
import { Button, colors, Flex, getItem, setItem } from "@ikiru/talentis-fpc"
import { Formik, FormikHelpers } from "formik"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { Location } from "views/assignments/assignment.types"
import { onCreateAssignmentSubmit } from "views/assignments/components/assignment-create-form/assignment-details-form.actions"
import {
  createAssignmentInitialValues,
  CreateAssignmentValues
} from "views/assignments/components/assignment-create-form/constants/assignment-details-form.definitions"
import { createAssignmentValidationSchema } from "views/assignments/components/assignment-create-form/constants/assignment-details-form.schema"
import { onCreateCampaignSubmit } from "views/campaigns/components/campaign-create-form/campaign-form.actions"
import { createCampaignValidationSchema } from "views/campaigns/components/campaign-create-form/constants/campaign-details-form.schema"
import AssignmentCreateModal from "./AssignmentCreateModal"
import CampaignCreateModal from "./CampaignCreateModal"
import {
  createCampaignInitialValues,
  CampaignPrimaryData
} from "views/campaigns/components/campaign-create-form/constants/campaign-details-form.definitions"
import { CompanySelected } from "views/assignments/components/assignment-company/types"
import ModalProjectContainer from "components/ProjectActions/ModelContainer"
import { AssignmentDetailsFields } from "views/assignments/pages/manage-assignment/assignment-details.types"
import { localStorageKeys } from "setup/storage/storage.definitions"
import { AssignmentStatus } from "views/assignments/constants/assignment-statuses"

type ModalTemplateType = {
  isAssignmentSelect: boolean | null
  fetchCampaigns: (isAdding: boolean) => void
  fetchAssignments: (isAdding: boolean) => void
}

const ModalTemplate = ({
  isAssignmentSelect,
  fetchAssignments,
  fetchCampaigns
}: ModalTemplateType) => {
  const { close } = useModal()

  const [locationValue, setLocationValue] = useState<Location>({
    label: createAssignmentInitialValues.location,
    value: createAssignmentInitialValues.geoLocation
  })

  const [companyValue, setCompanyValue] = useState<CompanySelected>({
    id: "",
    name: ""
  })

  const submitAssignment = async (
    values: CreateAssignmentValues,
    helpers: FormikHelpers<CreateAssignmentValues>
  ) => {
    setItem(localStorageKeys.assignmentStatusInput, values.status)
    close(ModalNames.AddNewCampaignOrAssignment)

    if (locationValue.label !== "") {
      values.location = locationValue.label.trim()
      values.geoLocation = locationValue?.value
    }
    if (companyValue.id) {
      values.companyId = companyValue.id
    } else {
      values.companyId = undefined
    }
    values.companyName = companyValue.name

    const assignmentValues = {
      ...values,
      name: `${values.jobTitle} @ ${values.companyName}`,
      startDate: new Date()
    }

    await onCreateAssignmentSubmit(assignmentValues, helpers).then(() =>
      fetchAssignments(true)
    )
    await fetchAssignments(true)
  }

  const submitCampaign = async (
    values: CampaignPrimaryData,
    helpers: FormikHelpers<CampaignPrimaryData>
  ) => {
    close(ModalNames.AddNewCampaignOrAssignment)
    await onCreateCampaignSubmit(values, helpers).then(() =>
      fetchCampaigns(true)
    )
  }
  const typeOfProject = isAssignmentSelect ? "assignment" : "campaign"

  return (
    <ModalProjectContainer
      typeOfProject={typeOfProject}
      modalName={ModalNames.AddNewCampaignOrAssignment}
    >
      {isAssignmentSelect ? (
        <Formik
          onSubmit={submitAssignment}
          initialValues={{
            ...createAssignmentInitialValues,
            [AssignmentDetailsFields.Status]:
              (getItem(
                localStorageKeys.assignmentStatusInput,
                false
              ) as AssignmentStatus) || AssignmentStatus.Active
          }}
          validationSchema={createAssignmentValidationSchema}
        >
          {(props) => {
            const { handleSubmit } = props
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <AssignmentCreateModal
                  {...props}
                  setLocationValue={setLocationValue}
                  setCompanyValue={setCompanyValue}
                />
                <Flex
                  height={60}
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor={colors.grey.light}
                >
                  <Button type="submit">{messages.generic.save}</Button>
                </Flex>
              </form>
            )
          }}
        </Formik>
      ) : (
        <Formik
          onSubmit={submitCampaign}
          initialValues={createCampaignInitialValues}
          validationSchema={createCampaignValidationSchema}
        >
          {(props) => {
            const { handleSubmit } = props
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <CampaignCreateModal {...props} />
                <Flex
                  height={60}
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor={colors.grey.light}
                >
                  <Button type="submit">{messages.generic.save}</Button>
                </Flex>
              </form>
            )
          }}
        </Formik>
      )}
    </ModalProjectContainer>
  )
}

export default ModalTemplate
