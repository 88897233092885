import { Nullable } from "tsdef"
import {
  Education,
  Employment,
  Experience,
  PersonTeamMember,
  PhoneDataType,
  TaggedEmail,
  WebSite
} from "views/persons/person.types"
import { LocalPerson, PersonValue } from "./localPerson.definitions"
import { sortWebsitesByKnownType } from "./localPerson.utils"
import { OffLimitsType } from "components/OffLimits/type"
import { PersonConsentsFormFields } from "views/persons/components/person-data-privacy/form/types"
import { GDPRLawfulBasis } from "views/persons/components/person-data-privacy/form/constants/gdpr-lawful-basis"
import { PersonConsentsField } from "views/persons/components/person-data-privacy/form/constants/definitions"

export interface ILocalPersonModel {
  id: string
  name: PersonValue<string>
  createdDate: string | Date
  jobTitle: PersonValue<string>
  linkedInProfileUrl: PersonValue<string>
  location: PersonValue<string>
  company: PersonValue<string>
  companyId: PersonValue<string>
  experience: PersonValue<Experience[]>
  bio: PersonValue<string | null>
  taggedEmails: PersonValue<TaggedEmail>[]
  phones: PersonValue<PhoneDataType>[]
  gdprLawfulBasisState: PersonConsentsFormFields
  webSites: PersonValue<WebSite>[]
  photo?: {
    url?: string
  }
  currentJob: Nullable<Employment>
  previousJobs: Nullable<Employment[]>
  rocketReachFetchedInformation?: boolean
  offlimits: Nullable<OffLimitsType>
  isOfflimits: boolean
  teamMembers: PersonTeamMember[]
  title: Nullable<string>
  firstName: Nullable<string>
  lastName: Nullable<string>
  middleName: Nullable<string>
  knownAs: Nullable<string>
  nationality: Nullable<string>
  dateOfBirth: Nullable<Date>
  addressLine1: Nullable<string>
  addressLine2: Nullable<string>
  addressLine3: Nullable<string>
  addressLine4: Nullable<string>
  city: Nullable<string>
  country: Nullable<string>
  postcode: Nullable<string>
  education: Education[]
}

export class LocalPersonModel implements ILocalPersonModel {
  public id: string
  public dataPoolPersonId: string
  public name: PersonValue<string>
  public createdDate: string | Date
  public jobTitle: PersonValue<string>
  public linkedInProfileUrl: PersonValue<string>
  public location: PersonValue<string>
  public company: PersonValue<string>
  public companyId: PersonValue<string>
  public experience: PersonValue<Experience[]>
  public bio: PersonValue<string>
  public taggedEmails: PersonValue<TaggedEmail>[]
  public phones: PersonValue<PhoneDataType>[]
  public gdprLawfulBasisState: PersonConsentsFormFields
  public webSites: PersonValue<WebSite>[]
  public photo?: {
    url?: string
  }
  public currentJob: Nullable<Employment>
  public previousJobs: Nullable<Employment[]>
  public rocketReachFetchedInformation?: boolean
  public offlimits: Nullable<OffLimitsType>
  public isOfflimits: boolean
  public teamMembers: PersonTeamMember[]
  public title: Nullable<string>
  public firstName: Nullable<string>
  public lastName: Nullable<string>
  public middleName: Nullable<string>
  public knownAs: Nullable<string>
  public nationality: Nullable<string>
  public dateOfBirth: Nullable<Date>
  public addressLine1: Nullable<string>
  public addressLine2: Nullable<string>
  public addressLine3: Nullable<string>
  public addressLine4: Nullable<string>
  public city: Nullable<string>
  public country: Nullable<string>
  public postcode: Nullable<string>
  public education: Education[]

  constructor(person: LocalPerson) {
    this.id = person.localPerson.id || ""

    this.dataPoolPersonId =
      person.dataPoolPerson?.dataPoolPersonId ||
      person.dataPoolPerson?.dataPoolId ||
      ""

    this.name = {
      value: person.localPerson?.name || person.dataPoolPerson?.name || "",
      isReadOnly: !!person.dataPoolPerson?.name
    }

    this.jobTitle = {
      value:
        person.localPerson?.jobTitle || person.dataPoolPerson?.jobTitle || "",
      isReadOnly:
        !person.localPerson?.jobTitle && !!person.dataPoolPerson?.jobTitle
    }

    this.companyId = {
      value:
        person.localPerson?.companyId ||
        person.localPerson?.experience?.[0]?.companyId ||
        "",
      isReadOnly:
        !person.localPerson?.companyId &&
        !!person.localPerson?.experience?.[0]?.companyId
    }

    this.experience = {
      value:
        person.localPerson?.experience ||
        person.dataPoolPerson?.experience ||
        [],
      isReadOnly:
        !person.localPerson?.experience && !!person.dataPoolPerson?.experience
    }

    this.company = {
      value:
        person.localPerson?.company ||
        person.localPerson?.experience?.[0]?.company ||
        "",
      isReadOnly:
        !person.localPerson?.company &&
        !!person.localPerson?.experience?.[0]?.company
    }

    this.bio = this.getBio(person)

    this.phones = this.getPhones(person)

    this.location = {
      value:
        person.localPerson?.location || person.dataPoolPerson?.location || "",
      isReadOnly:
        !person.localPerson?.location && !!person.dataPoolPerson?.location
    }

    this.linkedInProfileUrl = {
      value:
        person.localPerson?.linkedInProfileUrl ||
        person.dataPoolPerson?.linkedInProfileUrl ||
        "",
      isReadOnly:
        !!person.localPerson?.linkedInProfileUrl && !!person.dataPoolPerson
    }

    this.webSites = this.getWebsites(person)

    this.createdDate = person.localPerson.createdDate || ""

    this.taggedEmails = this.getTaggedEmails(person)

    this.gdprLawfulBasisState = person.localPerson.gdprLawfulBasisState || {
      [PersonConsentsField.LawfulBasis]: GDPRLawfulBasis.None,
      [PersonConsentsField.DataOrigin]: "",
      [PersonConsentsField.GdprExpiration]: null,
      [PersonConsentsField.GdprComment]: ""
    }

    this.photo = person.localPerson?.photo ||
      person.dataPoolPerson?.photo || { url: undefined }

    this.currentJob = person.dataPoolPerson?.currentJob || null
    this.previousJobs = person.dataPoolPerson?.previousJobs || []

    this.rocketReachFetchedInformation =
      person.localPerson?.rocketReachFetchedInformation

    this.offlimits = person.localPerson?.offlimits || null
    this.isOfflimits = person.localPerson?.isOfflimits || false

    this.teamMembers = person.localPerson.teamMembers || []

    this.title = person.localPerson.title || null

    this.firstName = person.localPerson.firstName || null

    this.lastName = person.localPerson.lastName || null

    this.middleName = person.localPerson.middleName || null

    this.knownAs = person.localPerson.knownAs || null

    this.nationality = person.localPerson.nationality || null

    this.dateOfBirth = person.localPerson.dateOfBirth || null

    this.addressLine1 = person.localPerson.addressLine1 || null

    this.addressLine2 = person.localPerson.addressLine2 || null

    this.addressLine3 = person.localPerson.addressLine3 || null

    this.addressLine4 = person.localPerson.addressLine4 || null

    this.city = person.localPerson.city || null

    this.country = person.localPerson.country || null

    this.postcode = person.localPerson.postcode || null

    this.education = person.localPerson.education || []
  }

  private getBio(person: LocalPerson) {
    const localBio = person?.localPerson?.bio
    const dataPoolBio = person?.dataPoolPerson?.bio || ""

    if (localBio || localBio?.trim() === "") {
      return { value: localBio, isReadOnly: false }
    } else {
      return { value: dataPoolBio, isReadOnly: true }
    }
  }

  private getWebsites(person: LocalPerson): PersonValue<WebSite>[] {
    const localWebSites = person?.localPerson?.webSites || []
    const dataPoolWebSites = person?.dataPoolPerson?.webSites || []

    const uniqueDataPoolWebSites =
      dataPoolWebSites.filter(
        (webSite) => this.linkedInProfileUrl.value !== webSite.url
      ) || []

    const uniqueLocalWebSites =
      localWebSites.filter(
        (webSite) =>
          !dataPoolWebSites.some(
            (dataPoolWebSite) =>
              dataPoolWebSite.url === webSite.url &&
              dataPoolWebSite.type === webSite.type
          )
      ) || []

    const websites = [
      ...uniqueDataPoolWebSites?.map((webSite) => ({
        value: webSite,
        isReadOnly: webSite.dpWebsite
      })),
      ...uniqueLocalWebSites.map((webSite) => ({
        value: webSite,
        isReadOnly: webSite.dpWebsite
      }))
    ]

    return sortWebsitesByKnownType(websites)
  }

  private getPhones(person: LocalPerson): PersonValue<PhoneDataType>[] {
    const localPhones = person?.localPerson?.phones || []
    const uniqueDataPoolPhoneNumbers =
      person.dataPoolPerson?.phones?.filter(
        (phone) =>
          !person.localPerson.phones?.some(
            (localPhone) => localPhone.phoneNumber === phone.phoneNumber
          )
      ) || []

    return [
      ...localPhones.map((phoneData) => ({
        value: phoneData,
        isReadOnly: false
      })),
      ...uniqueDataPoolPhoneNumbers.map((phone) => ({
        value: phone,
        isReadOnly: true
      }))
    ]
  }

  private getTaggedEmails(person: LocalPerson): PersonValue<TaggedEmail>[] {
    const uniqueDataPoolEmails =
      person.dataPoolPerson?.taggedEmails?.filter(
        (email) =>
          !person.localPerson.taggedEmails?.some(
            (localEmail) => localEmail.email === email.email
          )
      ) || []

    const localEmails = person.localPerson.taggedEmails || []

    return [
      ...localEmails.map((email) => ({
        value: email,
        isReadOnly: false
      })),
      ...uniqueDataPoolEmails?.map((email) => ({
        value: email,
        isReadOnly: true
      }))
    ]
  }

  public getPersonValues() {
    return {
      id: this.id,
      dataPoolPersonId: this.dataPoolPersonId,
      name: this.name.value,
      createdDate: this.createdDate,
      jobTitle: this.jobTitle.value,
      linkedInProfileUrl: this.linkedInProfileUrl.value,
      location: this.location.value,
      company: this.company.value,
      companyId: this.companyId.value,
      bio: this.bio.value,
      taggedEmails: this.taggedEmails.map((email) => email.value),
      phoneNumbers: this.phones.map((phone) => phone.value),
      gdprLawfulBasisState: this.gdprLawfulBasisState,
      webSites: this.webSites.map((webSite) => webSite.value),
      photo: this.photo,
      currentJob: this.currentJob,
      previousJobs: this.previousJobs,
      experience: this.experience.value,
      rocketReachFetchedInformation: this.rocketReachFetchedInformation,
      isOfflimits: this.isOfflimits,
      teamMembers: this.teamMembers
    }
  }

  public getTaskValues() {
    return {
      id: this.id,
      dataPoolPersonId: this.dataPoolPersonId,
      name: this.name.value,
      jobTitle: this.jobTitle.value,
      company: this.company.value,
      companyId: this.companyId.value,
      photo: this.photo,
      currentJob: this.currentJob
    }
  }

  public getReadOnlyKeys(): string[] {
    return [
      this.name.isReadOnly ? "name" : null,
      this.jobTitle.isReadOnly ? "jobTitle" : null,
      this.linkedInProfileUrl.isReadOnly ? "linkedInProfileUrl" : null,
      this.location.isReadOnly ? "location" : null,
      this.company.isReadOnly ? "company" : null,
      this.bio.isReadOnly ? "bio" : null,
      this.taggedEmails.some((email) => email.isReadOnly)
        ? "taggedEmails"
        : null,
      this.phones.some((phoneData) => phoneData.isReadOnly)
        ? "phoneNumbers"
        : null,
      this.webSites.some((site) => site.isReadOnly) ? "webSites" : null
    ].filter((key): key is string => !!key)
  }

  public getEditableKeys(): string[] {
    return [
      this.name.isReadOnly ? null : "name",
      this.jobTitle.isReadOnly ? null : "jobTitle",
      this.linkedInProfileUrl.isReadOnly ? null : "linkedInProfileUrl",
      this.location.isReadOnly ? null : "location",
      this.company.isReadOnly ? null : "company",
      this.bio.isReadOnly ? null : "bio",
      this.taggedEmails.some((email) => email.isReadOnly)
        ? null
        : "taggedEmails",
      this.phones.some((phoneData) => phoneData.isReadOnly)
        ? null
        : "phoneNumbers",
      this.webSites.some((site) => site.isReadOnly) ? null : "webSites"
    ].filter((key): key is string => !!key)
  }

  public isReadOnly(key: string) {
    const readOnlyKeys = this.getReadOnlyKeys()
    return readOnlyKeys.includes(key)
  }
}
