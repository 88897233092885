import React from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { Assignment } from "views/assignments/assignment.types"
import { AssignmentRecord } from "views/assignments/components/assignment-record"

export type StatusTabDetails = {
  data: string
  count: number
}
type AssignmentsGroupProps = {
  assignments: Assignment[]
}

export const AssignmentsGroup = React.memo(
  ({ assignments }: AssignmentsGroupProps) => {
    return (
      <Flex flexDirection="column" width="100%">
        <Div width="100%" mb="xs">
          {assignments.map((assignment) => (
            <Div key={assignment.id} mb="xs">
              <AssignmentRecord {...assignment} />
            </Div>
          ))}
        </Div>
      </Flex>
    )
  }
)
