import React, { ChangeEvent } from "react"
import { Div, Flex, Select } from "@ikiru/talentis-fpc"
import { formalList } from "./constants"
import { StyledInput } from "./style"
interface TemplateFormOptionsProps {
  setFormat: React.Dispatch<React.SetStateAction<string>>
  setLanguage: React.Dispatch<React.SetStateAction<string>>
}

const TemplateFormOptions = ({
  setFormat,
  setLanguage
}: TemplateFormOptionsProps) => {
  const defaultLanguage = localStorage.getItem("AIlanguage") || "English"

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLanguage(e.target.value)
    localStorage.setItem("AIlanguage", e.target.value)
  }

  const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.select()
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      mb="l"
      width="40%"
      minWidth="220px"
      mx="xl"
    >
      <Div width="100%" mr="xxs">
        <StyledInput
          variant="addIn"
          status="default"
          defaultValue={defaultLanguage}
          onBlur={handleInputChange}
          onFocus={handleFocus}
          spellCheck="false"
          autoCorrect="off"
          autoCapitalize="off"
        />
      </Div>
      <Div width="100%" ml="xxs">
        <Select
          variant="small"
          options={formalList}
          onClick={(e) => {
            //@ts-ignore
            setFormat(e.target.value)
          }}
          width="80%"
        />
      </Div>
    </Flex>
  )
}

export default TemplateFormOptions
