import styled from "styled-components"
import { Button, Div, Flex } from "@ikiru/talentis-fpc"

export const SearchUpHeader = styled(Flex)`
  background: ${({ theme }) => theme.colors.grey.dark};
  align-items: center;
  position: sticky;
  z-index: 3;
  top: 0;
  justify-content: space-between;
`

export const StyledRemoveButton = styled(Button)`
  background-color: transparent;
  border-color: transparent;
`

export const StyledCondition = styled(Div)`
  border: 1px solid #d5dee7;
  width: fit-content;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
`
