import React, { useCallback, useState } from "react"
import { Formik } from "formik"
import { Switch, colors, spacing, ExternalLinkIcon } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import * as Styled from "./GoogleSearch.styled"
import {
  prepareGoogleQuery,
  buildGoogleUrl
} from "utils/google-search/google-search"
import {
  GoogleSearchForm,
  GoogleSearchDefinition,
  initialValues,
  googleSearchValidationSchema,
  e2eTargets
} from "./constants/GoogleSearch.definitions"
import { useTelemetry } from "utils/hooks/use-telemetry"

export const GoogleSearch = React.memo(() => {
  const [isFemaled, setIsFemaled] = useState(false)

  const { trackSearchXray } = useTelemetry()

  const onSearch = useCallback(
    (data: GoogleSearchForm) => {
      trackSearchXray(isFemaled)

      const query = prepareGoogleQuery(
        data[GoogleSearchDefinition.Query],
        data[GoogleSearchDefinition.Type]
      )

      const genderQuery = isFemaled ? query + " female" : query
      const searchUrl = buildGoogleUrl(
        genderQuery,
        data[GoogleSearchDefinition.Query]
      )

      window.open(searchUrl, "_blank")
    },
    [isFemaled, trackSearchXray]
  )

  const formikContextValue = {
    initialValues,
    validationSchema: googleSearchValidationSchema,
    onSubmit: onSearch,
    isInitialValid: false
  }

  return (
    <Formik {...formikContextValue}>
      {({ isValid }) => (
        <Styled.GoogleSearchContainer>
          <Styled.InputWrapper>
            <FormikInput
              hideErrorMessage
              data-e2e-target-name={e2eTargets.queryInput}
              id={GoogleSearchDefinition.Query}
              name={GoogleSearchDefinition.Query}
              label={messages.googleSearch.label}
            />
          </Styled.InputWrapper>
          <Styled.TypeSelectorWrapper>
            <Switch
              data-e2e-target-name={e2eTargets.femaleSwitch}
              name={e2eTargets.femaleSwitch}
              onChange={() => setIsFemaled(!isFemaled)}
              checked={isFemaled}
              reverse
              label={messages.googleSearch.female}
            />
          </Styled.TypeSelectorWrapper>
          <Styled.GoogleSearchButton
            data-e2e-target-name={e2eTargets.searchButton}
            disabled={!isValid}
            mode="standard-green"
            suffix={
              <ExternalLinkIcon
                width={12}
                height={12}
                fill={colors.white.standard}
                style={{ marginLeft: spacing.xxs }}
              />
            }
          >
            {messages.googleSearch.searchOnGoogle}
          </Styled.GoogleSearchButton>
        </Styled.GoogleSearchContainer>
      )}
    </Formik>
  )
})
