import React from "react"

import { Flex, Div } from "@ikiru/talentis-fpc"
import { H1, LargeText } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

export const NotPermission = () => {
  return (
    <Flex height="100svh" justifyContent="center">
      <Div textAlign="center" maxWidth="370px">
        <H1 color="green.standard">{messages.notPermission.youDontHave}</H1>
        <LargeText color="grey.standard" px="xs">
          {messages.notPermission.toGainAccess}
        </LargeText>
      </Div>
    </Flex>
  )
}
