import React, { useEffect, useCallback } from "react"
import { BodyText, Flex, colors, theme } from "@ikiru/talentis-fpc"
import { DragAndDrop } from "components/FPCComponents/DragAndDrop"
import { DragType } from "../../constants/definition"
import { messages } from "setup/messages/messages"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { sortDragAndDropItems } from "../../../utils"
import { CVDataType, Education } from "views/persons/person.types"
import { CurrentItemType } from "components/FPCComponents/DragAndDrop/Items"
import { DeleteCVActionButton } from "../DragAndDropItems/ActionButton"
import EducationDragItem from "./DragItem"
import { createCvExistingColumn } from "views/persons/components/person-details/utils/get-cv-experience"
import { PersonEducationContainer } from "../../style"
import useHandleDndItem from "../../hooks/useHandleDndItem"
import { AddAllCVActionButton } from "../DragAndDropItems/ActionButton/AddAlllCVActionButton "

const ParseEducation = () => {
  const { cvParsing, person } = usePerson()
  const {
    setHasDeletedAll,
    hasDeletedAll,
    items,
    onAddAll,
    onDeleteAll,
    disableEditAll,
    setItems,
    undoAddAll,
    disableAddAll,
    updateTempPersonData,
    disableRestoreAll
  } = useHandleDndItem({ dragType: "education" })

  const getCvParsedEducation = useCallback(() => {
    const existingData = cvParsing.skills.existingEducation.map((edc) => ({
      id: edc.id,
      type: DragType.ExpItem,
      itemComponentProps: edc,
      columnId: edc.isMovedToParsed ? "2" : "1"
    }))
    const parsedData = cvParsing.skills.parsedEducation.map((edc) => ({
      id: edc.id,
      type: DragType.ExpItem,
      itemComponentProps: edc,
      columnId: edc.isMovedToExisting ? "1" : "2"
    }))
    return [...existingData, ...parsedData]
  }, [cvParsing.skills])

  useEffect(() => {
    const newState = getCvParsedEducation()
    const sortedItems = sortDragAndDropItems(newState)
    setItems(sortedItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeItemColumn = (currentItem: CurrentItemType, columnId: string) => {
    const newState = items.map((e) => {
      if (
        e.id === currentItem.itemId &&
        currentItem.currentColumnId !== columnId
      ) {
        if (e.itemComponentProps.type === CVDataType.Parsed) {
          e.itemComponentProps.isMovedToExisting =
            !e.itemComponentProps.isMovedToExisting
        } else {
          e.itemComponentProps.isMovedToParsed =
            !e.itemComponentProps.isMovedToParsed
        }
      }
      return {
        ...e,
        columnId: e.id === currentItem.itemId ? columnId : e.columnId
      }
    })
    const sortedItems = sortDragAndDropItems(newState)
    updateTempPersonData(sortedItems)
    setItems(sortedItems)
  }

  const onRestoreAll = () => {
    setHasDeletedAll(false)

    let existing = person?.education.map((educ: Education) =>
      createCvExistingColumn(educ, CVDataType.Parsed)
    )
    //needs to be edited when education is added
    const newState: any[] =
      existing?.map((edc, index) => {
        return {
          id: `${index}`,
          type: DragType.ExpItem,
          itemComponentProps: edc,
          columnId: edc.isMovedToExisting ? "1" : "2"
        }
      }) || []
    setItems([...items, ...newState])
  }

  return (
    <>
      <BodyText mt="0">{messages.person.cvparsing.dragAndDropContent}</BodyText>
      <Flex>
        <PersonEducationContainer>
          <DragAndDrop
            explainMessage={{
              explainMessage: messages.person.cvparsing.explainEducationDnD,
              hoverColor: colors.green.light,
              excludeList: ["2"]
            }}
            columns={[
              {
                id: "1",
                title: messages.person.cvparsing.experience.existingTitle,
                acceptType: DragType.ExpItem,
                DragComponent: EducationDragItem,
                titleColor: colors.white.standard,
                columnColor: colors.grey.standard,
                sideTitle: messages.person.cvparsing.education,
                titleLeftPadding: theme.space.xxs,

                minHeight: "30vh",
                maxHeight: "33vh",
                borderStyle: `1px solid ${colors.grey.light}`,
                actionComponent: () => (
                  <DeleteCVActionButton
                    onRestoreAll={onRestoreAll}
                    onEditAll={onDeleteAll}
                    disableEditAll={hasDeletedAll && disableEditAll}
                    editAllLabel={messages.person.cvparsing.deleteAll}
                    restoreAllLabel={messages.person.cvparsing.restoreAll}
                  />
                )
              },
              {
                id: "2",
                title: messages.person.cvparsing.experience.parsedTitle,
                acceptType: DragType.ExpItem,
                DragComponent: EducationDragItem,
                titleColor: colors.grey.darkest,
                minHeight: "30vh",
                maxHeight: "33vh",

                titleLeftPadding: theme.space.xxs,
                columnColor: colors.green.lightest,
                borderStyle: `1px solid ${colors.grey.light}`,
                actionComponent: () => (
                  <AddAllCVActionButton
                    onRestoreAll={undoAddAll}
                    onEditAll={onAddAll}
                    disableRestore={disableRestoreAll}
                    disableEditAll={disableAddAll}
                    editAllLabel={messages.person.cvparsing.addAll}
                    restoreAllLabel={messages.person.cvparsing.restoreAll}
                  />
                )
              }
            ]}
            itemsData={items}
            setItems={changeItemColumn}
          />
        </PersonEducationContainer>
      </Flex>
    </>
  )
}

export default ParseEducation
