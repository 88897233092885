import React, { useMemo } from "react"
import {
  Button,
  PencilIcon,
  colors,
  H4,
  Section,
  Div,
  BodyText,
  H5,
  Tag,
  Flex
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { CompanyDetailsContainer } from "./style"
import { useCompany } from "views/companies/company-module/company-module.context"
import { ProjectTeamMembers } from "components/ProjectTeamMembers/ProjectTeamMembers"
import useSubmitCompanyDetails from "views/companies/hooks/useSubmitCompanyDetails"

export const CompanyDetailsData = () => {
  const { companyDetails } = useCompany()

  const { name, industries, yearFounded, speciality, size } = companyDetails

  const specialtyList = useMemo(() => speciality?.split(","), [speciality])

  const { companyOpenModal } = useSubmitCompanyDetails()

  return (
    <Section
      mb="m"
      header={{
        titleProps: {
          fontWeight: 500,
          as: H4,
          fontSize: "18px !important",
          my: 0,
          p: 0,
          color: colors.grey.dark
        },
        // @ts-ignore
        height: "45px",
        title: messages.companies.companyDetails,
        actions: (
          <>
            <Button
              mode="standard-white"
              size="action-medium"
              onClick={companyOpenModal}
            >
              <PencilIcon />
            </Button>
          </>
        )
      }}
    >
      <Div py="m" width="100%">
        <CompanyDetailsContainer>
          <Div>
            <H5 my="0">{messages.companies.companyInfo.companyName}</H5>
            <BodyText my={0} whiteSpace={"nowrap"}>
              {name}
            </BodyText>
          </Div>
          <Div>
            <H5 my="0">{messages.companies.companyInfo.sector}</H5>
            <BodyText my={0}>{industries[0]?.name}</BodyText>
          </Div>
          <Div>
            <H5 my="0">{messages.companies.companyInfo.companySize}</H5>
            <BodyText my={0}>
              {messages.companies.companyInfo.size[size] ||
                messages.companies.companyInfo.size.unknown}
            </BodyText>
          </Div>
          <Div>
            <H5 my="0">{messages.companies.companyInfo.companyFound}</H5>
            <BodyText my={0}>{yearFounded}</BodyText>
          </Div>
        </CompanyDetailsContainer>
        {Boolean(speciality) && (
          <Flex mt="xs" alignItems={"baseline"}>
            <H5 color={colors.grey.darkest} m="0" mr="8px">
              {messages.companies.companyInfo.speciality}
            </H5>
            <Div>
              {specialtyList.map((specialityItem) => {
                return (
                  <Tag
                    m="0"
                    mr="xxs"
                    mt="xs"
                    height="25px"
                    variant="grey"
                    key={specialityItem}
                    disabled
                  >
                    {specialityItem}
                  </Tag>
                )
              })}
            </Div>
          </Flex>
        )}
        <ProjectTeamMembers teamMembers={companyDetails.teamMembers} />
      </Div>
    </Section>
  )
}
