import React from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import {
  CompanyDataField,
  companySize,
  SectorField
} from "../constants/definitions"

export const CompanyDataForm = () => {
  return (
    <Div alignItems="center" justifyContent="stretch" position="relative">
      <Flex flexDirection={["column", "column", "row"]} mb="xs">
        <Div width={["100%", "100%", "49%"]} mr="xs">
          <FormikInput
            id={CompanyDataField.Name}
            name={CompanyDataField.Name}
            label={messages.companies.companyInfo.companyName}
          />
        </Div>
        <Div width={["100%", "100%", "49%"]}>
          <FormikInput
            id={SectorField.Sector}
            name={SectorField.Sector}
            label={messages.companies.companyInfo.sector}
          />
        </Div>
      </Flex>
      <Flex
        flexDirection={["column", "column", "row"]}
        mb={["0", "0", "xs"]}
        justifyContent="space-between"
      >
        <Div width={["100%", "100%", "49%"]} mr="xs" mb={["xs", "xs", "0"]}>
          <FormikSelect
            options={companySize}
            id={CompanyDataField.Size}
            name={CompanyDataField.Size}
            label={messages.companies.companyInfo.companySize}
          />
        </Div>
        <Div width={["100%", "100%", "49%"]} mb={["xs", "xs", "0"]}>
          <FormikInput
            id={CompanyDataField.YearFounded}
            name={CompanyDataField.YearFounded}
            label={messages.companies.companyInfo.companyFound}
          />
        </Div>
      </Flex>
    </Div>
  )
}
