import React from "react"
import { Form, Formik, FormikHelpers } from "formik"
import { TemplateModalWrapper } from "./styles"
import { messages } from "setup/messages/messages"
import {
  Button,
  Div,
  ModalSectionHeader,
  Flex,
  CrossIcon
} from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"

export type ValuesType = {
  templateText: string
}

type TemplateEditModalProps = {
  title: string
  value: string
  dataKey: string
  onSave: (
    dataKey: string,
    values: ValuesType,
    action: FormikHelpers<ValuesType>
  ) => void
  lowerCaseTitle: boolean
}

const TemplateEditModal = ({
  title,
  value,
  dataKey,
  onSave,
  lowerCaseTitle = false
}: TemplateEditModalProps) => {
  const { close } = useModal()

  const onSubmit = (values: ValuesType, action: FormikHelpers<ValuesType>) => {
    onSave(dataKey, values, action)
  }

  return (
    <TemplateModalWrapper>
      <ModalSectionHeader
        title={
          messages.project.outReach.edit +
          " " +
          (lowerCaseTitle ? title.toLowerCase() : title)
        }
        size="xSmall"
        actions={
          <>
            <Button
              onClick={() => close(ModalNames.TemplateEditModal)}
              mode="standard-white"
              size="action-medium"
            >
              <CrossIcon fill="grey.dark" />
            </Button>
          </>
        }
      />
      <Formik initialValues={{ templateText: value }} onSubmit={onSubmit}>
        <Form>
          <Div mt="m" px="m">
            <FormikTextarea
              name={"templateText"}
              label={title}
              rows={12}
              resize="vertical"
            />
          </Div>
          <Flex
            justifyContent="center"
            alignItems="center"
            backgroundColor="grey.light"
            p="xs"
            mt="xl"
          >
            <Button type="submit" size="small">
              {messages.generic.save}
            </Button>
          </Flex>
        </Form>
      </Formik>
    </TemplateModalWrapper>
  )
}

export default TemplateEditModal
