import { useCallback } from "react"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { CandidateFilter } from "views/assignments/assignment-module/assignment-module.types"

const useEditCandidateFilter = () => {
  const { appliedFilters, updateFilters } = useAssignment()
  /**
   * Check if a given filter is already in our list of applied filters
   */
  const filterIsApplied = useCallback(
    (candidateFilter: CandidateFilter) => {
      return (
        appliedFilters.findIndex(
          (filter) =>
            filter.candidateProperty === candidateFilter?.candidateProperty &&
            filter.filterKey === candidateFilter.filterKey
        ) !== -1
      )
    },
    [appliedFilters]
  )

  /**
   * Remove a candidate filter
   */
  const removeFilter = useCallback(
    (candidateFilter: CandidateFilter) => {
      if (!filterIsApplied(candidateFilter)) {
        return
      }

      updateFilters(
        appliedFilters.filter(
          (filter) =>
            filter.value !== candidateFilter.value &&
            filter.label !== candidateFilter.label
        ),
        true
      )
    },
    [filterIsApplied, updateFilters, appliedFilters]
  )
  return { filterIsApplied, removeFilter }
}

export default useEditCandidateFilter
