import { useState, useEffect } from "react"
import { apiRequest } from "setup/api/api"
import {
  AssignmentsEndpoints,
  CandidatesEndpoints
} from "setup/api/endpoints/endpoints"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { PersonAssignmentOption } from "../types"
import { formatAssignmentToOption } from "views/persons/components/person-assignments/utils/format-assignment-to-option"
import { Assignment } from "views/assignments/assignment.types"
import { Candidate } from "views/candidates/candidates.types"
import { PersonState } from "views/persons/person-module/person-module.types"

export const useOnAssignmentLoad = () => {
  const { personId, setCandidates, candidates } = usePerson()
  const [allAssignments, setAllAssignments] = useState<Assignment[]>([])
  const [options, setOptions] = useState<PersonAssignmentOption[]>([])
  const [loading, setLoading] = useState({
    assignments: false,
    candidates: false
  })

  useEffect(() => {
    const setAvailableAssignments = async () => {
      const [, response] = await apiRequest.get({
        endpoint: AssignmentsEndpoints.SimpleList,
        config: { params: { totalItemCount: 300 } }
      })

      setLoading((loading) => ({ ...loading, assignments: false }))

      response && setAllAssignments(response.data.simpleActiveAssignments || [])
    }

    const setLinkedCandidates = async () => {
      if (personId) {
        const [, response] = await apiRequest.get({
          endpoint: CandidatesEndpoints.Root,
          config: { params: { personId, expand: "person, assignment" } }
        })

        setLoading((loading) => ({ ...loading, candidates: false }))

        if (response?.data?.candidates) {
          const mappedCandidates = response.data.candidates.reduce(
            (cache: PersonState["candidates"], candidate: Candidate) => ({
              ...cache,
              [candidate.id]: candidate
            }),
            {}
          )

          setCandidates(mappedCandidates)
        }
      } else {
        setLoading((loading) => ({ ...loading, candidates: false }))
        setCandidates({})
      }
    }

    setLoading({ assignments: true, candidates: true })

    setAvailableAssignments()
    setLinkedCandidates()
  }, [personId, setCandidates])

  useEffect(() => {
    const disabledAssignmentIds = Object.values(candidates).reduce(
      (cache, candidate) => {
        const id = candidate?.linkAssignment?.id
        return id ? [...cache, id] : cache
      },
      [] as string[]
    )

    const formatAssignmentsFunction = formatAssignmentToOption(
      disabledAssignmentIds
    )
    const formattedAssignmentList = allAssignments.map(
      formatAssignmentsFunction
    )
    setOptions(formattedAssignmentList)
  }, [allAssignments, candidates])

  return { options, loading }
}
