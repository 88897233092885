import React from "react"
import { Formik, Form, FormikProps } from "formik"
import { Button, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { messages } from "setup/messages/messages"
import { StyledDiv } from "../style"
import { EditOffLimitsValidationSchema } from "../constants/schema"
import { OffLimitsForm } from "../form/OffLimitsForm"
import {
  OffLimitsData,
  editOffLimitsFieldInitialValues
} from "../constants/definitions"

type EditOffLimitsDataProps = {
  onSubmit: (values: OffLimitsData) => void
  initialValues?: OffLimitsData
  formActionsComponent?: (
    formikProps: FormikProps<OffLimitsData>
  ) => JSX.Element
}

export const EditOffLimitsData = ({
  onSubmit,
  initialValues,
  formActionsComponent
}: EditOffLimitsDataProps) => {
  const { close } = useModal()

  const submitForm = (values: OffLimitsData) => {
    onSubmit(values)
    close(ModalNames.EditOffLimits)
  }

  return (
    <StyledDiv>
      <Formik
        initialValues={{
          ...editOffLimitsFieldInitialValues,
          ...initialValues
        }}
        validationSchema={EditOffLimitsValidationSchema}
        onSubmit={submitForm}
      >
        {(formik) => {
          const { values } = formik
          return (
            <Form>
              <ModalSectionHeader
                title={messages.offLimits.offLimitsEdit}
                size="xSmall"
                actions={
                  <>
                    <Button mx="xxs" my="0" type="submit" size="small">
                      {messages.form.generic.save}
                    </Button>
                    <Button
                      mode="standard-white"
                      mx="xxs"
                      my="0"
                      size="small"
                      onClick={() => close(ModalNames.EditOffLimits)}
                    >
                      {messages.generic.cancel}
                    </Button>
                  </>
                }
              />
              <OffLimitsForm isShowButton={!!values.endDate} />
              {formActionsComponent?.(formik)}
            </Form>
          )
        }}
      </Formik>
    </StyledDiv>
  )
}
