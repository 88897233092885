import React from "react"
import { Div, Flex, H5, SmallText } from "@ikiru/talentis-fpc"
import { ExistingExp, ParsedExp } from "views/persons/person.types"
import {
  getEndDate,
  getStartDate
} from "../../parsing/components/DragAndDropItems/utils"
import { ShowMore } from "components/ShowMore"
import { messages } from "setup/messages/messages"

type ExperienceDataProps = {
  salary?: string
} & (ExistingExp | ParsedExp)

const ExperienceData = ({
  company,
  title,
  salary,
  startDate,
  endDate,
  changedValues,
  description
}: ExperienceDataProps) => {
  return (
    <Div px="xs" pb="xs" pt="xxs">
      <Flex>
        <H5 m="0" lineHeight="15px">
          {title + messages.person.experience.at + company}
        </H5>
        <SmallText m="0">{salary && "Salary: " + salary}</SmallText>
      </Flex>
      <SmallText>
        {`${getStartDate(changedValues?.startDate || startDate)} - ${getEndDate(
          changedValues?.endDate || endDate,
          changedValues?.startDate || startDate
        )}`}
      </SmallText>
      <ShowMore
        visibleLines={1}
        description={changedValues?.description || description}
      />
    </Div>
  )
}

export default ExperienceData
