import { PhoneTypeEnums } from "views/persons/components/person-details-tab/components/person-contact-info/form/constants/definitions"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"

export enum CreatePersonField {
  Name = "name",
  JobTitle = "jobTitle",
  EmailAddresses = "taggedEmails",
  Company = "company",
  Bio = "bio",
  Location = "location",
  PhoneNumbers = "phones",
  WebSites = "webSites",
  FirstPhoneNumber = "phoneNumbers[0].value",
  FirstWebsite = "webSites[0].value.url",
  FirstEmailAddress = "taggedEmails[0].value.email",
  LinkedInUrl = "linkedInProfileUrl"
}

export const createPersonInitialValues: Omit<
  PersonProfileDetails,
  "company" | "jobTitle" | "companyId" | "experience"
> = {
  [CreatePersonField.Name]: { value: "", isReadOnly: false },
  [CreatePersonField.Bio]: { value: "", isReadOnly: false },
  [CreatePersonField.EmailAddresses]: [
    { value: { email: "", type: "", isPreferred: false }, isReadOnly: false }
  ],
  [CreatePersonField.Location]: { value: "", isReadOnly: false },
  [CreatePersonField.LinkedInUrl]: { value: "", isReadOnly: false },
  [CreatePersonField.PhoneNumbers]: [
    {
      value: {
        isPreferred: false,
        phoneDeviceType: "",
        phoneNumber: "",
        phoneUsageType: PhoneTypeEnums.Personal
      },
      isReadOnly: false
    }
  ],
  [CreatePersonField.WebSites]: [{ value: { url: "" }, isReadOnly: false }]
}

export type CreatePersonValues = typeof createPersonInitialValues

export const e2eTargets = {
  submitButton: "submit-form",
  phoneNumbers: "phone-numbers",
  webSites: "web-sites",
  taggedEmails: "email-addresses"
}
