import React, { useEffect, useMemo, useState } from "react"
import { useMedia } from "@ikiru/talentis-fpc"
import { get } from "lodash"
import { messages } from "setup/messages/messages"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { getAppliedFiltersParams } from "../candidate-filters/helper"
import { InterviewProgressStage } from "../candidate-record/components/InterviewProgress/definitions"
import TabCard from "components/HorizontalScroll/Tabs"

type StageCardTypes = {
  itemId: string
  stage: string
  key: string
}

const StageCard = ({ stage }: StageCardTypes) => {
  const [stageCount, setStageCount] = useState(0)

  const {
    setSelectedStage,
    getAssignmentCandidates,
    appliedFilters,
    selectedStage,
    onPageChange,
    activeStages,
    assignmentId
  } = useAssignment()

  const breakpoint = useMedia()

  const handleClick = () => {
    onPageChange(1)
    setSelectedStage(stage)
    const params = getAppliedFiltersParams(appliedFilters)
    params.append("page", "1")
    getAssignmentCandidates(assignmentId, stage, params)
  }

  useEffect(() => {
    setStageCount(activeStages[stage])
  }, [activeStages, stage])

  const label = useMemo(
    () => get(messages, `assignment.interviewStage.${stage}`),
    [stage]
  )

  const isSelected = selectedStage?.toLowerCase() === stage?.toLowerCase()

  const isNotArchive = useMemo(
    () => stage !== InterviewProgressStage.Archive,
    [stage]
  )
  return (
    <>
      <TabCard
        width={["xl", "lg"].includes(breakpoint) ? "200px" : "130px"}
        isSelected={isSelected}
        itemId={label}
        count={stageCount}
        label={label}
        key={stage}
        handleClick={handleClick}
        isRowTab={true}
        isNotArchive={isNotArchive}
      />
    </>
  )
}

export default StageCard
